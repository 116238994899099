export const getUIAgreementTypeValueFunction = (agreement_type) => {
  if (agreement_type === "Convertible_Note") {
    return "Convertible Note";
  } else if (agreement_type === "Subscription_Agreement") {
    return "Subscription Agreement";
  } else if (agreement_type === "SAFE") {
    return "SAFE Agreement";
  } else return agreement_type;
};

export const getUISecurityTypeValueFunction = (security_type) => {
  if (security_type === "Preferred_Equity") {
    return "Preferred Equity";
  } else if (security_type === "Common_Equity") {
    return "Common Equity";
  } else {
    return security_type;
  }
};

export const getUIInvestmentStatusValueFunction = (investmentStatus) => {
  if (investmentStatus === "Pending") {
    return "Pending Settlement";
  } else {
    return investmentStatus;
  }
};
