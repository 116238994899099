import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import Newsletter from "../newsletter/Newsletter";
import {
  aboutUsWebAppLink,
  invest,
  raiseCapital,
  faq,
  faqWebAppLink,
  newLoginSignUpLink,
  privacyPolicy,
  privacyPolicyWebAppLink,
  termsAndConditions,
  termsAndConditionsWebAppLink,
  knowBeforeYouInvest,
  knowBeforeYouInvestWebAppLink,
  knowledgeBase,
  contactUs,
  knowledgeBaseBlogDetail,
  lightPaperLink,
  knowledgeBaseWebAppLinkBlogDetail,
  career,
  aboutUs,
  mentor,
} from "./pageLinks";
import {
  investingInStartupsID,
  investing101ID,
} from "../../../web-app-store/actions/config";
import logo from "../../../assets/imgs/desktop/common/logo.png";
import darkLogo from "../../../assets/imgs/desktop/common/Purple-and-Silver-horizontal-logo.png";
import { Image } from "react-bootstrap";
const array1 = [
  {
    name: "Invest",
    link: invest,
  },
  {
    name: "Raise Capital",
    link: raiseCapital,
  },
  {
    name: "Knowledge Base",
    link: knowledgeBase,
  },
  {
    name: "FAQs",
    link: faq,
  },
];

// const array1new = [
//   {
//     name: "About Us",
//     link: aboutUs,
//   },
//   {
//     name: "Contact Us",
//     link: contactUs,
//   },
//   {
//     name: "Privacy Policy",
//     link: privacyPolicy,
//   },
//   {
//     name: "Terms of Use",
//     link: termsAndConditions,
//   },
//   // {
//   //   name: "Login / Sign Up",
//   //   link: newLoginSignUpLink,
//   // },
// ];

const webAppFooterMenu1 = [
  {
    name: "About Us",
    link: aboutUs,
  },
  {
    name: "Careers",
    link: career,
  },
  {
    name: "Investment Opportunities",
    link: "",
  },
  {
    name: "Download our Lightpaper",
    link: lightPaperLink,
  },
  {
    name: "Contact Us",
    link: contactUs,
  },
];
const webAppFooterMenu2 = [
  {
    name: "How It Works",
    link: invest,
  },
  {
    name: "Before You Invest",
    link: knowBeforeYouInvest,
  },
  {
    name: "Knowledge Base",
    link: knowledgeBase,
  },
  {
    name: "FAQs",
    link: faq,
  },
];
const webAppFooterMenu3 = [
  {
    name: "Raise Capital",
    link: raiseCapital,
  },
  {
    name: "Mentorship",
    link: mentor,
  },
  {
    name: "FAQs",
    link: faq,
  },
];
const array2WebApp = [
  {
    link: "https://www.linkedin.com/company/vedaslabs-inc/",
    name: "Linkedin",
    className: "icons8-linkedin-circled",
    extraClass: "icons-wrap",
  },
  {
    link: "https://twitter.com/VedasLabs_io",
    name: "Twitter ",
    className: "icons8-twitter",
    extraClass: "twitter_social_icon",
  },
  {
    link: "https://www.instagram.com/vedaslabs_io/",
    name: "Instagram",
    className: "icons8-instagram",
    extraClass: "icons-wrap",
  },
  {
    link: "https://www.facebook.com/VedasLabs",
    name: "Facebook",
    className: "icons8-facebook",
    extraClass: "icons-wrap",
  },
];
/**
 * Footer Component
 *
 * Overview:
 * The Footer component is responsible for rendering the footer section of the application. It provides information about the company, investment opportunities, and social media links.
 *
 * Details:
 * - The component accepts props:
 *   - webAppFooter: a boolean value indicating whether the footer is being rendered in the web app or not.
 *   - isRaiseCapital: a boolean value indicating whether the user is in the "Raise Capital" section or not.
 * - The component consists of multiple sections:
 *   - renderBlockOne: a function that renders the first block of the footer, including links to the knowledge base and important information.
 *   - handleInvestment: a function that handles the click event for the "Investment Opportunities" button.
 * - The component uses React Router for navigation and includes conditional rendering based on the props.
 * - The component relies on external images for logos and social media icons.
 *
 * */

export default function Footer({
  webAppFooter,
  isRaiseCapital,
  isMentorClass,
}) {
  const renderBlockOne = () => {
    return (
      <div
        className={`mx-0 footer-row-div ${isRaiseCapital ? "row" : "d-flex"}`}
      >
        <Link to={knowledgeBase} className="p-0">
          <div className="footer-knowledge-div">
            <h5 className="font-35-montserrat-bold">Knowledge Base &#62; </h5>
          </div>
        </Link>
        <div className="footer-top-right-div">
          <h5>
            All early stage investments entail risk and we are committed to
            helping you understand if investing is suitable for you. Please read
            our{" "}
            <Link to={knowBeforeYouInvest}>
              {" "}
              <u>Know Before You Invest</u>{" "}
            </Link>
            and{" "}
            <Link to={faq}>
              <u>FAQs</u>
            </Link>
            .
          </h5>
        </div>
      </div>
    );
  };

  const history = useHistory();

  const handleInvestment = () => {
    window.scroll(1800, 1800);
    history.push("/");
  };
  return (
    <>
      <div
        className={`${
          !webAppFooter ? "vl-footer" : "vl-footer vl-footer--webApp"
        } ${isMentorClass || ""} footer_wrap`}
      >
        {!webAppFooter && renderBlockOne()}
        <div className="vl-footer-bg">
          {/* <div className="maincontainer-custom"> */}
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3 footer-wrap">
              {webAppFooter ? (
                <div>
                  <Image
                    src={darkLogo}
                    className="footer-logo"
                    alt="VedasLabs-logo"
                  />
                </div>
              ) : (
                <div>
                  <Image
                    src={logo}
                    className="footer-logo"
                    alt="VedasLabs-logo"
                  />
                </div>
              )}
              <div className="footer-text">
                <p>
                  Providing next-generation tools and networks to accelerate how
                  capital is raised.
                </p>
              </div>
              <div className="d-flex footer-social-media-icon">
                {webAppFooter
                  ? array2WebApp.map((data, key) => (
                      <Fragment key={key}>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ft-menu"
                        >
                          <p
                            className={`socialIcons ${
                              data?.extraClass ? data?.extraClass : ""
                            }`}
                          >
                            <span className={data.className} />
                          </p>
                        </a>
                      </Fragment>
                    ))
                  : array2WebApp.map((data, key) => (
                      <Fragment key={key}>
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ft-menu"
                        >
                          <p
                            className={`socialIcons ${
                              data?.extraClass ? data?.extraClass : ""
                            }`}
                          >
                            <span className={data.className} />
                          </p>
                        </a>
                      </Fragment>
                    ))}
                <Newsletter className="ft-menu" />
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3 footer-company">
              <h4 className="footer-heading">Company</h4>
              {webAppFooter
                ? webAppFooterMenu1.map((data, key) => (
                    <Fragment key={key}>
                      {data.name === "Investment Opportunities" ? (
                        <button onClick={handleInvestment} className="ft-menu">
                          <h5>Investment Opportunities</h5>
                        </button>
                      ) : (
                        <Link to={data.link} className="ft-menu">
                          <h5 className="">{data.name}</h5>
                        </Link>
                      )}
                    </Fragment>
                  ))
                : webAppFooterMenu1.map((data, key) => (
                    <Fragment key={key}>
                      {data.name === "Investment Opportunities" ? (
                        <button onClick={handleInvestment} className="ft-menu">
                          <h5>Investment Opportunities</h5>
                        </button>
                      ) : (
                        <Link to={data.link} className="ft-menu">
                          <h5 className="">{data.name}</h5>
                        </Link>
                      )}
                    </Fragment>
                  ))}
              {/* {webAppFooterMenu1.map((data, key) => (
                <Fragment key={key}>
                  {data.name === "Investment Opportunities" ? (
                    <button onClick={handleInvestment} className="ft-menu">
                      <h5 className="text-light">Investment Opportunities</h5>
                    </button>
                  ) : (
                    <Link to={data.link} className="ft-menu">
                      <h5 className="">{data.name}</h5>
                    </Link>
                  )}
                </Fragment>
              ))} */}
              {/* {webAppFooter ? (
                webAppFooterMenu1.map((data, key) => (
                  <Fragment key={key}>
                    <Link to={data.link} className="ft-menu">
                      <h5 className="">{data.name}</h5>
                    </Link>
                  </Fragment>
                ))
              ) : (
                <>
                  {array1.map((data, key) => (
                    <Fragment key={key}>
                      <Link to={data.link} className="ft-menu">
                        <h5>{data.name}</h5>
                      </Link>
                    </Fragment>
                  ))}
                  <a
                    href={newLoginSignUpLink}
                    target="_self"
                    rel="noopener noreferrer"
                    className="ft-menu"
                  >
                    <h5>Login / Sign In</h5>
                  </a>
                </>
              )} */}
            </div>
            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12 mb-3 footer-company">
              <h4 className="footer-heading">Investors</h4>
              <h5 className="ft-menu">
                <a
                  href={newLoginSignUpLink}
                  target="_self"
                  rel="noopener noreferrer"
                  className="ft-menu"
                >
                  <h5>Login</h5>
                </a>
                {webAppFooterMenu2.map((data, key) => (
                  <Fragment key={key}>
                    <Link to={data.link} className="ft-menu">
                      <h5 className="">{data.name}</h5>
                    </Link>
                  </Fragment>
                ))}
              </h5>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12 mb-3 footer-company">
              <h4 className="footer-heading">Founders</h4>
              <h5 className="ft-menu">
                <a
                  href={newLoginSignUpLink}
                  target="_self"
                  rel="noopener noreferrer"
                  className="ft-menu"
                >
                  <h5>Login</h5>
                </a>
                {webAppFooterMenu3.map((data, key) => (
                  <Fragment key={key}>
                    <Link to={data.link} className="ft-menu">
                      <h5 className="">{data.name}</h5>
                    </Link>
                  </Fragment>
                ))}
              </h5>
            </div>
          </div>
          <h5 className="footer-para">
            This site is operated by VedasLabs Inc. ("VedasLabs"), which is a
            funding portal registered with the Securities and Exchange
            Commission and a member of the Financial Industry Regulatory
            Authority (
            <a
              href="https://www.finra.org/about/firms-we-regulate/funding-portals-we-regulate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="footer-font-bold-underline">FINRA</span>
            </a>
            ). VedasLabs is not a registered broker-dealer. VedasLabs does not
            give investment advice, endorsement, analysis or recommendations
            with respect to any securities. <br /> <br />
            All securities offerings on this website are conducted pursuant to
            Regulation Crowdfunding. Securities offered under Regulation
            Crowdfunding are considered highly speculative and carry a high
            degree of risk, including the potential for complete loss of
            investment, long holding periods, and lack of transferability or
            liquidity. Please read{" "}
            <Link
              to={
                webAppFooter
                  ? knowBeforeYouInvestWebAppLink
                  : knowBeforeYouInvest
              }
            >
              <u>Know Before You Invest</u>{" "}
            </Link>
            and our{" "}
            <Link to={webAppFooter ? faqWebAppLink : faq}>
              {" "}
              <u>FAQs</u>
            </Link>{" "}
            for more information on Regulation Crowdfunding and some of the
            risks involved in an investment in a crowdfunding offering.
            VedasLabs is located at 40 West 89th Street, Unit 4B, New York, NY
            10024. For more background information please visit{" "}
            <a
              href="https://www.finra.org/about/firms-we-regulate/funding-portals-we-regulate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="footer-font-bold-underline">
                FINRA – Funding Portals We Regulate
              </span>
            </a>
            .
            <br />
            <br />
            By accessing this site and any pages on this site, you agree to be
            bound by our{" "}
            <Link
              to={
                webAppFooter ? termsAndConditionsWebAppLink : termsAndConditions
              }
            >
              <u>Terms of Use</u>{" "}
            </Link>{" "}
            and{" "}
            <Link to={webAppFooter ? privacyPolicyWebAppLink : privacyPolicy}>
              <u>Privacy Policy</u>
            </Link>
            , as may be amended from time to time without notice or liability.
          </h5>
          {/* </div> */}
        </div>
      </div>
      {/* <Helmet> */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "VedasLabs",
            url: "https://vedaslabs.io/",
            logo: "https://vedaslabs.io/static/media/logo.929976cb.png",
            description:
              "VedasLabs is a social venture platform where you can learn how to invest in early-stage companies, connect with founders, and invest in high impact startups.",
            founder: "Tom Trevisani",
            foundingLocation: "New York",
            foundingDate: "July 10, 2018",
            address: {
              "@type": "PostalAddress",
              addressLocality: "New York",
              addressRegion: "New York",
              postalCode: "10024",
              streetAddress: "40 W 89th Street 4B",
            },
            telephone: "917-588-3622",
            eMail: "info@vedaslabs.io",
            sameAs: [
              "https://www.facebook.com/VedasLabs",
              "https://www.instagram.com/vedaslabs_io/",
              "https://www.linkedin.com/company/vedaslabs-inc/",
              "https://twitter.com/VedasLabs_io",
            ],
          }),
        }}
      ></script>
      {/* </Helmet> */}
    </>
  );
}
Footer.defaultProps = {
  webAppFooter: false,
};
