import { Link } from "react-router-dom";
import darkLogo from "../../../../assets/imgs/desktop/common/dark.png";
import { Image } from "react-bootstrap";
import "./_styles.scss";
import Footer from "../../header-footer/Footer";
import { exploreWebAppLink } from "../../header-footer/pageLinks";

const ProfileReviewComponent = () => {
  return (
    <div className="reviewed">
      <div className="reviewed-main">
        <div className="reviewed-wrap">
          <div className="reviewed-image-wrap">
            <Image src={darkLogo} className="reviewed-image" />
          </div>
          <h1 className="reviewed-image-title">Application Received!</h1>
          <div className="reviewed-content">
            Thank you for applying. We will notify you once your details have
            been reviewed.
          </div>
          <div className="reviewed-link-wrap">
            <Link to={exploreWebAppLink} className="reviewed-link">
              In the meantime, feel free to explore our listings.
            </Link>
          </div>
        </div>
      </div>
      <Footer webAppFooter={true} isMentorClass="footer-main" />
    </div>
  );
};

export default ProfileReviewComponent;
