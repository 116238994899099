import React from "react";
// import info from "../../../assets/imgs/desktop/icon/info.png";
import darrow from "../../../assets/imgs/desktop/icon/down-arrow.png";
import img1 from "../../../assets/imgs/desktop/invest/pc.png";
import img2 from "../../../assets/imgs/desktop/invest/pc_mobile.png";

// import { Link } from "react-router-dom";

import { newLoginSignUpLink } from "../header-footer/pageLinks";

export default function InvestFoldTwo() {
  const renderBlockOne = () => {
    return (
      <div className="invest-fold2-create-div">
        <h4>
          Create Investor Profile
        </h4>
        <h5>
          Creating a profile enables you to invest and  engage. During the
          signup process, you will be asked for information required to
          determine how much you can invest according to {" "}
          Regulation Crowdfunding.
        </h5>
        <h4>Review Opportunities</h4>
        <h5>
          Our platform allows you to review each company’s pitch, access
          information about the company including their management team,{" "}
          and historical financial statements.
        </h5>
        <h4>Invest</h4>
        <h5>
          Once you confirm your investment, your funds are held in escrow
          until the company’s funding goal has been met. You can cancel
          your investment up to 48 hours before a company’s pre-set
          deadline.
        </h5>
        <h4>Interact</h4>
        <h5>
          Once you invest, you can review offering details and ask
          questions directly to the company’s founders and other
          investors in the discussion room.
        </h5>
        {/* <Link to="/sign-up"> */}
        <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
          <div className="start-btn start-btn--invest-join">
            <span>JOIN FOR FREE &#62;</span>
          </div>
        </a>
        {/* </Link> */}
        <div className="invest-down-arrow">
          <img src={darrow} alt="downword arrow" className="d-arrow" />
        </div>
      </div>
    );
  };
  return (
    <div className="invest-fold-two-div">
      <div className="invest-fold-two-inner-div">
        <div className="maincontainer-custom">
          <div className="row">
            <div className="col-lg-7">
              <div className="investpage-inner">
                <h4>How It Works</h4>
                <h5 className="investpageinner-title">
                  A secure, interactive platform where
                  Investors connect directly with Founders
                </h5>
                <img src={img2} alt="invest how it works" className="investpc-mobile img-fluid" />

                {renderBlockOne()}
                {/* <img src={info} alt="info icon" className="invest-info-img" /> */}
              </div>
            </div>
            <div className="col-lg-3">
            </div>
          </div>
        </div>
        <img src={img1} alt="invest how it works" className="invest-pc-img" />
      </div>
    </div>
  );
}
