import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Line } from "rc-progress";

// api
import { getExploreTagsById } from "../../../web-app-store/actions/exploreAction";
import { useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import CardFollowButton from "./CardFollowButton";
import { startUpDetailsWebAppLink } from "../../../components/desktop/header-footer/pageLinks";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import { getUIAgreementTypeValueFunction } from "../../../common-website-and-app/getUIValueFunction";

const location = "/web-app-img/desktop/icons/location.png";

/**
 * ExploreCard Component
 *
 * Overview:
 * The ExploreCard component is responsible for rendering a card that displays information about a startup or organization. It includes features such as displaying tags, company name, location, funding progress, and funding details. This component is used in the Explore section of the application.
 *
 * Details:
 * - The component receives the `offeringsData` prop, which contains data about the startup or organization.
 * - The component makes use of Redux for state management and dispatches actions to fetch additional data.
 * - The component uses the `useState` and `useEffect` hooks for managing local state and performing side effects.
 * - The component includes rendering logic based on the state and props, such as displaying different elements based on hover state or availability of data.
 *
 * Dependencies:
 * - React: The component is built using React and JSX syntax.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - react-redux: The component uses the `useDispatch` hook from react-redux to dispatch actions.
 * - react-router-dom: The component uses the `Link` component from react-router-dom for navigation.
 * - react-chartjs-2: The component uses the `Line` component from react-chartjs-2 to render the funding progress bar.
 * - Other custom components and utility functions used within the component.
 */
export default function ExploreCard({ offeringsData }) {
  // api
  const dispatch = useDispatch();

  const [tagsById, setTagsById] = useState();

  let offeringDataById = offeringsData;
  let orgData = offeringsData.organization;

  useEffect(() => {
    const callBackTagsData = (data) => {
      setTagsById(data);
    };
    if (!isEmpty(orgData)) {
      dispatch(getExploreTagsById(orgData.id, callBackTagsData));
    }
  }, [dispatch, orgData]);

  let amountRaised =
    !isEmpty(offeringDataById) && offeringDataById.total_amount_raised;

  let amountReserves =
    !isEmpty(offeringDataById) && offeringDataById.total_amount_reserves;

  let amountVLReserves =
    !isEmpty(offeringDataById) && offeringDataById.total_VL_reserves;

  const [fundRaised, setFundRaised] = useState(0);
  useEffect(() => {
    if (!isEmpty(offeringDataById)) {
      const amountRaised = offeringDataById.total_amount_raised;
      const fundingGoal = offeringDataById.funding_goal;
      setFundRaised((amountRaised * 100) / fundingGoal);

      if (isNaN(fundRaised)) {
        setFundRaised(0);
      }
    }
  }, [fundRaised, offeringDataById]);

  const [isHover, setIsHover] = useState(false);
  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, " ");
  };

  let companyDetailsStr =
    !isEmpty(orgData.company_details) && orgData.company_details.length > 200
      ? `${removeTags(orgData.company_details).substring(0, 200)}`
      : removeTags(orgData.company_details);

  const parseTags = (tags) => {
    try {
      return tags.split(",");
    } catch (err) {
      return [];
    }
  };
  return (
    <>
      {/* {console.log(offeringDataById)} */}
      {/* {console.log(orgData)} */}
      {!isEmpty(offeringDataById) && !isEmpty(orgData) && (
        <div
          className={
            !isHover ? "explore-card" : "explore-card explore-card--hover"
          }
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {!isEmpty(offeringDataById.offering_image) ? (
            <div className="explore-card-bg-img">
              <img
                src={offeringDataById?.organization?.company_logo?.url}
                alt=""
              />
            </div>
          ) : !isEmpty(orgData.company_logo) ? (
            <div className="explore-card-bg-img">
              <img src={orgData.company_logo.url} alt="" />
            </div>
          ) : (
            <div className="explore-card-bg-img"></div>
          )}

          <div className="explore-card-text-div">
            <div className="explore-card-btn-div explore-card-btn-div--main-card align-items-start">
              {!isEmpty(tagsById) ? (
                parseTags(tagsById[0].market_tags).map(
                  (data, index) =>
                    index < 3 && (
                      <div key={index} className="tag-btn">
                        {data}
                      </div>
                    )
                )
              ) : (
                <div className="tag-btn bg-transparent"></div>
              )}
            </div>
            {/* text content */}
            <div className="mx-0 d-flex flex-nowrap justify-content-between align-items-start">
              <div>
                <h4 className="font-22-dm-serif-regular">
                  {/* Start Up Name */}
                  {orgData.company_name}
                </h4>
                <div className="mt-15 explorecard-location">
                  <div className="explorecard-inner">
                    <img
                      src={location}
                      alt="logo"
                      className="explore-card-location-img"
                    />
                    <h5 className="font-16-open-sans-regular">
                      {/* Location */}
                      {orgData.location}
                    </h5>
                  </div>
                </div>
              </div>
              <CardFollowButton
                orgId={orgData.id}
                classNameFollowingBtn="explore-card-follow-btn explore-card-follow-btn--following"
                classNameFollowBtn="explore-card-follow-btn"
              />
            </div>
            {!isHover && (
              <p
                className="explore-desc"
                // dangerouslySetInnerHTML={{ __html: companyDetailsStr }}
              >
                {companyDetailsStr === "" ? "No Destails" : companyDetailsStr}
              </p>
            )}
            <div className="explore-card-progress-outer-div">
              <Line
                percent={fundRaised}
                strokeWidth="4"
                strokeColor="#5A9BA5"
                trailWidth="4"
                trailColor="rgba(196, 196, 196, 0.16)"
              />
            </div>
            {isHover && (
              <>
                <div className="d-flex mx-0 flex-nowrap justify-content-between align-items-start pt-31 percentage-inner">
                  <div className="percentage-text-div">
                    <h4 className="percentage-text">
                      {/* <DisplayCurrencyFormatString value={fundRaised} />% */}
                      {fundRaised.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      })}
                      %
                    </h4>
                    <h4 className="funds-text">
                      {offeringsData.offering_status === "Accepting_Investment"
                        ? "Funds Raised"
                        : offeringsData.offering_status === "VL_Reserve"
                        ? "Funds Raised"
                        : "Funds reserved"}
                    </h4>
                  </div>
                  <div className="funds-amount-div">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span className="funds-amounts-text-2">
                              {offeringsData.offering_status ===
                              "Accepting_Investment"
                                ? "Amount Invested"
                                : offeringsData.offering_status ===
                                  "Offering_Posted"
                                ? "Amount Reserved"
                                : offeringsData.offering_status === "VL_Reserve"
                                ? "Amount Committed"
                                : "Amount Raised"}
                            </span>
                          </td>
                          <td className="text-right">
                            <span className="funds-amounts-no-2">
                              {offeringsData.offering_status ===
                              "Accepting_Investment" ? (
                                <DisplayCurrencyFormatString
                                  value={amountRaised}
                                />
                              ) : offeringsData.offering_status ===
                                "Offering_Posted" ? (
                                <DisplayCurrencyFormatString
                                  value={amountReserves}
                                />
                              ) : offeringsData.offering_status ===
                                "VL_Reserve" ? (
                                <DisplayCurrencyFormatString
                                  value={amountVLReserves}
                                />
                              ) : (
                                "N/A"
                              )}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="funds-amounts-text-2">
                              Funding Minimum
                            </span>
                          </td>
                          <td className="text-right">
                            <span className="funds-amounts-no-2">
                              <DisplayCurrencyFormatString
                                value={offeringDataById.funding_goal}
                              />
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="funds-amounts-text-2">
                              Funding Maximum
                            </span>
                          </td>
                          <td className="text-right">
                            <span className="funds-amounts-no-2">
                              <DisplayCurrencyFormatString
                                value={offeringDataById.max_funding_goal}
                              />
                            </span>
                          </td>
                        </tr>
                        {offeringsData.offering_status ===
                          "Accepting_Investment" ||
                        offeringsData.offering_status === "VL_Reserve" ? (
                          <tr>
                            <td>
                              <span className="explore-card-font-14-semibold">
                                Ending on
                              </span>
                            </td>
                            <td className="text-right">
                              <span className="explore-card-font-18-semibold">
                                {/* 23-Mar-2021 */}
                                {format(
                                  new Date(offeringDataById.fund_end_date),
                                  "DD-MMM-YYYY"
                                )}
                              </span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <table className="mx-auto mt-28 last-textlist">
                    <tbody>
                      <tr>
                        <td>
                          <span className="funds-amounts-text-1">
                            Valuation Cap
                          </span>
                        </td>
                        <td>
                          <span className="font-16-open-sans-regular">
                            <DisplayCurrencyFormatString
                              value={offeringDataById.valuation_cap}
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="funds-amounts-text-1">
                            Agreement Type
                          </span>
                        </td>
                        <td>
                          <span className="font-16-open-sans-regular">
                            {getUIAgreementTypeValueFunction(
                              offeringDataById.agreement_type
                            )}
                          </span>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <span className="funds-amounts-text-1">Round</span>
                        </td>
                        <td>
                          <span className="font-16-open-sans-regular">
                            {offeringDataById.investment_round}
                          </span>
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <span className="funds-amounts-text-1">
                            Round Type
                          </span>
                        </td>
                        <td>
                          <span className="font-16-open-sans-regular">
                            {offeringDataById.round_type}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {/* text content end */}

            <div
              className={
                isHover
                  ? "d-flex align-items-center mx-0 btn-explore justify-content-center view-btn"
                  : "d-flex align-items-center mx-0 btn-explore button-last justify-content-center view-btn"
              }
            >
              <Link
                to={{
                  pathname: `${startUpDetailsWebAppLink}/${offeringDataById.id}`,
                  state: { activeSidebarMenuCount: 1 },
                }}
              >
                <div className="explore-card-view-details-div">
                  <span className="explore-card-view-details-div__text">
                    View details
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
