/**
 * activeSidebarMenu Component
 *
 * Overview:
 * This component defines an array called activeSidebarMenu which represents the active menu items in the sidebar. It is used to determine which menu items should be highlighted or displayed as active in the UI.
 *
 * Details:
 * - The activeSidebarMenu array contains strings representing the menu items.
 * - The menu items include "home", "explore", "invest", "raise capital", "knowledge base", and "faqs".
 * - The component does not include any sub-components or rendering logic. It simply exports the activeSidebarMenu array.
 * - The component does not accept any props.
 *
 * Usage:
 * This component is imported and used in other components that need to display or manipulate the active menu items in the sidebar.
 *
 * Dependencies:
 * This component does not have any external dependencies.
 */
import apply from "../../../public/web-app-img/desktop/icons/apply.png";
import mentorDirectory from "../../../public/web-app-img/desktop/icons/backToMentorblack.svg";
import messageImage from "../../../public/web-app-img/desktop/icons/chat_1.svg";
export const activeSidebarMenu = [
  "home",
  "explore",
  "invest",
  "raise capital",
  // "mentor",
  "knowledge base",
  "faqs",
];

export const activeTopNavbarMenu = ["discuss", "notifications"];

export const homePageTitle = {
  icon: "/web-app-img/desktop/icons/home-blue-icon.svg",
  title: "Home",
};

export const explorePageTitle = {
  icon: "/web-app-img/desktop/icons/explore-blue-icon.svg",
  title: "Explore",
};

export const investPageTitle = {
  icon: "/web-app-img/desktop/icons/invest-blue-icon.svg",
  title: "Invest",
};

export const mentorApplyTitle = {
  icon: apply,
  title: "Apply",
};

export const mentorDirectoryTitle = {
  icon: mentorDirectory,
  title: "Mentor Directory",
};

export const message = {
  icon: messageImage,
  title: "Messages",
};
export const raiseCapitalPageTitle = {
  icon: "/web-app-img/desktop/icons/raise-capital-blue-icon.svg",
  title: "Raise Capital",
};

export const mentorPageTitle = {
  icon: "/web-app-img/desktop/icons/mentor-blue-icon.svg",
  title: "Mentor",
};

export const knowledgeBasePageTitle = {
  icon: "/web-app-img/desktop/icons/knowledge-base-blue-icon.svg",
  title: "Knowledge Base",
};

export const faqsPageTitle = {
  icon: "/web-app-img/desktop/icons/faqs-blue-icon.svg",
  title: "FAQs",
};

export const discussionPageTitle = {
  icon: "/web-app-img/desktop/icons/discussion-blue-icon.svg",
  title: "Discussions",
};

export const notificationsPageTitle = {
  icon: "/web-app-img/desktop/icons/bell-blue-icon.svg",
  title: "Notifications",
};
