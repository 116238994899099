import React, { Fragment } from "react";
// import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see &apos;Styles&apos; section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
// import BlogsCardsDisplayInBlogsDetails from "../knowledge-base/BlogsCardsDisplayInBlogsDetails";

const queArray = [
  {
    que: "Angel Investing",
  },
  {
    que: "Venture Capital",
  },
  {
    que: "Crowdfunding",
  },
];
const queArray2 = [
  {
    que: "SAFEs",
  },
  {
    que: "Convertible Notes",
  },
  {
    que: "Subscription",
  },
];
const queArray3 = [
  {
    que: "Preferred Stock",
  },
  {
    que: "Common Stock",
  },
];
const queArray4 = [
  {
    que: "Due Diligence",
  },
  {
    que: "Minimum Viable Product",
  },
  {
    que: "Pitch Deck",
  },
  {
    que: "Team",
  },
  {
    que: "Advisors",
  },
  {
    que: "Public Investing",
  },
  {
    que: "Private Investing",
  },
  {
    que: "Liquidity ",
  },
];
/**
 * GlossaryFoldOne Component
 * 
 * Overview:
 * The GlossaryFoldOne component is responsible for rendering the content for the first fold of the glossary section. It displays definitions and explanations for various terms related to startup funding and investment.
 * 
 * Details:
 * - The component consists of multiple renderAnswerFoldOneX functions, each responsible for rendering a specific definition.
 * - The renderAnswerFoldOneX functions return JSX elements that contain the definition content.
 * - The component accepts the foldOneHeight prop, which is used to calculate the scroll position when expanding accordion buttons.
 * 
 * Usage:
 * To use the GlossaryFoldOne component, import it and include it in the desired location within the application's UI. Pass the foldOneHeight prop to the component to ensure correct scrolling behavior.
 * 
 * Dependencies:
 * - None
 */
export default function GlossaryFoldOne({ foldOneHeight }) {
  const renderAnswerFoldOne0 = () => {
    return (
      <>
        <p>
          Is when an individual who provides capital for startups at the initial
          moments, in exchange for convertible debt or ownership equity. At the
          angel investor stage, the risk of failure is relatively high, but can
          also be very rewarding. Many angel investors have previous startup
          experience themselves and strive to continue to be involved in the
          entrepreneurial process by providing funding, guidance, and mentorship
          to first-time founders. 
        </p>
      </>
    );
  };
  const renderAnswerFoldOne1 = () => {
    return (
      <p>
        This has emerged as an alternative form of financing where companies can
        raise capital in smaller amounts from a large pool of investors online.
        In the US there are a handful of funding portals and VedasLabs is one of
        them.{" "}
        <a
          href="https://www.investopedia.com/terms/c/crowdfunding.asp"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="text-underline">Read more</span>
        </a>
      </p>
    );
  };
  const renderAnswerFoldOne2 = () => {
    return (
      <>
        <p>
          The first self-proclaimed venture capital firm was the American
          Research &amp; Development Corporation (ARD), founded in 1946 by the
          Harvard Business School professor{" "}
          <a
            href="https://www.investopedia.com/terms/v/venturecapitalist.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-underline">Georges Doriot</span>
          </a>
          —an emigrated Frenchman who is known as &ldquo;the father of venture
          capital.&rdquo;
          <br />
          <br />
          At a high level, Venture capital firms invest in early-stage companies
          in exchange for equity, or an ownership stake in the hopes that the
          companies they support will become successful. The typical venture
          capital investment occurs after an initial &ldquo;
          <a
            href="https://en.wikipedia.org/wiki/Seed_money"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-underline">seed funding</span>
          </a>
          &rdquo; round. The first round of institutional venture capital is
          called the{" "}
          <a
            href="https://en.wikipedia.org/wiki/Series_A_round"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="text-underline">Series A round</span>
          </a>
          .
        </p>
      </>
    );
  };

  const renderAnswerFoldTwo0 = () => {
    return (
      <p>
        A SAFE (simple agreement for future equity) is an agreement between an
        investor and a company that provides rights to the investor for future
        equity in the company similar to a warrant, except the price per share
        is determined at the next round of qualified financing.
      </p>
    );
  };
  const renderAnswerFoldTwo1 = () => {
    return (
      <>
        <p>
          A convertible note is a type of short-term debt that converts into
          equity, typically at the time of a future financing round. Investors
          are loaning money to a startup and instead of a return in the form of
          principal plus interest, the investor would receive equity in the
          company. The interest that accrues is added to the principal when
          calculating how much equity the investor receives.
        </p>
        <br />
        <br />
        <p>
          <strong className="font-semibold">Example:</strong> Startup A is
          raising $1M on a 3 year note with 5% interest and 15% discount at
          conversion and their valuation is at $9M pre-money. After they close
          the $1M (post-money) they are now worth $10M. Fast forward two years
          and the company secures a series A financing of $5M. The $1M under the
          note is now worth $1,102,500 including interest.
        </p>
      </>
    );
  };
  const renderAnswerFoldTwo2 = () => {
    return (
      <p>
        In a subscription agreement, the company agrees to sell a certain number
        of shares at a specific price, and in return, the investors buys the
        shares at the predetermined price.
      </p>
    );
  };

  const renderAnswerFoldThree0 = () => {
    return (
      <p>
        Represents ownership in a company but has{" "}
        <strong className="font-semibold">no voting rights</strong> when it
        comes to electing a board of directors or voting on the future of the
        company. In a liquidation, preferred stockholders have a{" "}
        <strong className="font-semibold">greater claim </strong>
        to a company’s assets and earnings over common stockholders.
      </p>
    );
  };
  const renderAnswerFoldThree1 = () => {
    return (
      <p>
        Represents ownership in a company{" "}
        <strong className="font-semibold">with voting rights</strong> when it
        comes to electing a board of directors or voting on the future of the
        company. In a liquidation, preferred stockholders have a greater claim
        to a company’s assets and earnings over common stockholders.
      </p>
    );
  };

  const renderAnswerFoldFour0 = () => {
    return (
      <p>
        Due diligence means the evaluation of an investment opportunity before
        committing funds. This process includes review of its management team,
        business conditions, projections, philosophy, and investment terms and
        conditions.
      </p>
    );
  };
  const renderAnswerFoldFour1 = () => {
    return (
      <p>
        A minimum viable product is a version of a product with just enough
        features to satisfy early customers and provide feedback for future
        product development.
      </p>
    );
  };
  const renderAnswerFoldFour2 = () => {
    return (
      <p>
        A brief presentation used to sell your idea or company that is usually
        targeting investors. This would include a description of the
        product/service, market analysis, competitive landscape, and high level
        financials.
      </p>
    );
  };
  const renderAnswerFoldFour3 = () => {
    return (
      <>
        <p>
          In the beginning days of a startup, the team consists of the core
          members that are needed to launch the company. Most startups will have
          1 to 2 founders and rarely have more than that. Some tips for building
          a tech startup team are:
        </p>
        <ul className="pt-20 pl-60 mb-0">
          <li>Find a great CTO</li>
          <li>Outsource until you can afford to hire</li>
          <li>Seek culture fit</li>
          <li>Offer favorable terms to employees</li>
          <li>Search for potential, not just experience</li>
        </ul>
      </>
    );
  };
  const renderAnswerFoldFour4 = () => {
    return (
      <p>
        When there&apos;s a gap to be filled in terms of experience on a
        founding team, this gap can be filled through advisory roles. Investors
        look to see if there is an advisory board that has the industry
        knowledge where the founding team may have limited experience. Startups
        will compensate these advisors with either equity or cash, sometimes
        both.
      </p>
    );
  };
  const renderAnswerFoldFour5 = () => {
    return (
      <p>
        Where shares of publicly traded companies are bought and sold by
        individuals and institutions. When a company goes &ldquo;public&rdquo;,
        this means it has sold some ownership stake on a public exchange such as
        the NYSE or NASDAQ.
      </p>
    );
  };
  const renderAnswerFoldFour6 = () => {
    return (
      <p>
        This is referring to investments that are not traded on a public
        exchange or market. In the past, these types of investments were
        considered difficult to access and lacked transparency for everyday
        investors. Today, because of new regulations in the US, the private
        markets are now becoming accessible to more people.
      </p>
    );
  };
  const renderAnswerFoldFour7 = () => {
    return (
      <p>
        The ease in which an asset or security can be converted to cash.
        Generally, if an asset is more liquid, its value will be greater.
      </p>
    );
  };

  const handleOnClickAccordionButtonFold1 = (key) => (e) => {
    let elementHeightVal1 = document.getElementById(
      `accordion-heading-fold1-id-0`
    ).offsetHeight;

    elementHeightVal1 = elementHeightVal1 * (key + 2);
    let scrollToVal = elementHeightVal1 + foldOneHeight;

    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };

  const handleOnClickAccordionButtonFold2 = (key) => (e) => {
    let elementHeightVal1 = document.getElementById(
      `accordion-heading-fold1-id-0`
    ).offsetHeight;

    let elementHeightVal2 = elementHeightVal1 * (key + 2);
    elementHeightVal1 = elementHeightVal1 * 10;
    let scrollToVal = elementHeightVal1 + elementHeightVal2 + foldOneHeight;

    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };

  const handleOnClickAccordionButtonFold3 = (key) => (e) => {
    let elementHeightVal1 = document.getElementById(
      `accordion-heading-fold1-id-0`
    ).offsetHeight;

    let elementHeightVal3 = elementHeightVal1 * (key + 2);
    elementHeightVal1 = elementHeightVal1 * 20;
    let scrollToVal = elementHeightVal1 + elementHeightVal3 + foldOneHeight;

    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };

  const handleOnClickAccordionButtonFold4 = (key) => (e) => {
    let elementHeightVal1 = document.getElementById(
      `accordion-heading-fold1-id-0`
    ).offsetHeight;

    let elementHeightVal4 = 0;
    if (key < 3) {
      elementHeightVal4 = elementHeightVal1 * (key + 2);
    } else {
      elementHeightVal4 = elementHeightVal1 * (key + 8);
    }
    elementHeightVal1 = elementHeightVal1 * 30;
    let scrollToVal = elementHeightVal1 + elementHeightVal4 + foldOneHeight;

    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };
  /*====================================
          render
  =====================================*/
  return (
    <>
      <div className="glossary-que-outer-div">
        <Accordion
          className="accordion"
          //allowMultipleExpanded={true}
          allowZeroExpanded={true}
        >
          {queArray.map((data, index) => (
            <Fragment key={index}>
              <AccordionItem>
                <AccordionItemHeading
                  id={`accordion-heading-fold1-id-${index}`}
                  onClick={handleOnClickAccordionButtonFold1(index)}
                >
                  <AccordionItemButton>{data.que}</AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  {index === 0
                    ? renderAnswerFoldOne0()
                    : index === 1
                    ? renderAnswerFoldOne2()
                    : index === 2 && renderAnswerFoldOne1()}
                </AccordionItemPanel>
              </AccordionItem>
            </Fragment>
          ))}
          <h5 className="glossary-font-40-bold pt-61">
            Types of Investment Agreements
          </h5>
          {queArray2.map((data, index) => (
            <Fragment key={index}>
              <AccordionItem>
                <AccordionItemHeading
                  onClick={handleOnClickAccordionButtonFold2(index)}
                >
                  <AccordionItemButton>{data.que}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {index === 0
                    ? renderAnswerFoldTwo0()
                    : index === 1
                    ? renderAnswerFoldTwo1()
                    : index === 2 && renderAnswerFoldTwo2()}
                </AccordionItemPanel>
              </AccordionItem>
            </Fragment>
          ))}
          <h5 className="glossary-font-40-bold pt-76">Types of Securities</h5>
          <p className="glossary-font-22-regular">
            On VedasLabs, you will find two types of securities: common equity
            and preferred equity. For both types of equity you own part of the
            company in which you’re investing. Note that your shares are subject
            to dilution should the issuer decide to issue more shares in a later
            round.
          </p>
          {queArray3.map((data, index) => (
            <Fragment key={index}>
              <AccordionItem>
                <AccordionItemHeading
                  onClick={handleOnClickAccordionButtonFold3(index)}
                >
                  <AccordionItemButton>{data.que}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {index === 0
                    ? renderAnswerFoldThree0()
                    : index === 1 && renderAnswerFoldThree1()}
                </AccordionItemPanel>
              </AccordionItem>
            </Fragment>
          ))}
          <h5 className="glossary-font-40-bold pt-61">Other terms</h5>
          {queArray4.map((data, index) => (
            <Fragment key={index}>
              <AccordionItem>
                <AccordionItemHeading
                  onClick={handleOnClickAccordionButtonFold4(index)}
                >
                  <AccordionItemButton>{data.que}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {index === 0
                    ? renderAnswerFoldFour0()
                    : index === 1
                    ? renderAnswerFoldFour1()
                    : index === 2
                    ? renderAnswerFoldFour2()
                    : index === 3
                    ? renderAnswerFoldFour3()
                    : index === 4
                    ? renderAnswerFoldFour4()
                    : index === 5
                    ? renderAnswerFoldFour5()
                    : index === 6
                    ? renderAnswerFoldFour6()
                    : index === 7 && renderAnswerFoldFour7()}
                </AccordionItemPanel>
              </AccordionItem>
            </Fragment>
          ))}
        </Accordion>
      </div>
      {/* <BlogsCardsDisplayInBlogsDetails blogId={null} /> */}
    </>
  );
}
