import React, { useEffect } from "react";
import "../light-paper/light-paper.scss";
import Picture1 from "../../../assets/imgs/desktop/light-paper/Picture1.png";
import Picture2 from "../../../assets/imgs/desktop/light-paper/Picture2.png";
import Picture3 from "../../../assets/imgs/desktop/light-paper/Picture3.png";
import Picture4 from "../../../assets/imgs/desktop/light-paper/Picture4.png";
import Picture5 from "../../../assets/imgs/desktop/light-paper/Picture5.png";
import Community from "../../../assets/imgs/desktop/light-paper/Community.png";
import flexbility from "../../../assets/imgs/desktop/light-paper/flexbility.png";
import confidance from "../../../assets/imgs/desktop/light-paper/confidance.png";
import Picture7 from "../../../assets/imgs/desktop/light-paper/Picture7.png";
import Picture8 from "../../../assets/imgs/desktop/light-paper/Picture8.png";
import Picture9 from "../../../assets/imgs/desktop/light-paper/Picture9.jpg";
import Picture11 from "../../../assets/imgs/desktop/light-paper/Picture11.png";
import Picture10 from "../../../assets/imgs/desktop/light-paper/Picture10.jpg";
import Picture12 from "../../../assets/imgs/desktop/light-paper/Picture12.jpg";
import Picture13 from "../../../assets/imgs/desktop/light-paper/Picture13.png";
import Picture14 from "../../../assets/imgs/desktop/light-paper/Picture14.png";
import Picture15 from "../../../assets/imgs/desktop/light-paper/Picture15.png";
import Picture16 from "../../../assets/imgs/desktop/light-paper/Picture16.jpg";
import Mobile1 from "../../../assets/imgs/desktop/light-paper/m1.jpg";
import Mobile2 from "../../../assets/imgs/desktop/light-paper/m2.png";
import Mobile3 from "../../../assets/imgs/desktop/light-paper/m3.png";
import Mobile4 from "../../../assets/imgs/desktop/light-paper/m4.png";
import flow1 from "../../../assets/imgs/desktop/light-paper/flow-1.png";
import Bgflow1 from "../../../assets/imgs/desktop/light-paper/bg-flow-1.png";
import textflow from "../../../assets/imgs/desktop/light-paper/text-flow.png";
import Architecture from "../../../assets/imgs/desktop/light-paper/Architecture.png";
import chart from "../../../assets/imgs/desktop/light-paper/chart.png";
import Team1 from "../../../assets/imgs/desktop/light-paper/our-team/Picture24.png";
import Team2 from "../../../assets/imgs/desktop/light-paper/our-team/Picture25.png";
import Team3 from "../../../assets/imgs/desktop/light-paper/our-team/Picture26.png";
import Team4 from "../../../assets/imgs/desktop/light-paper/our-team/Picture27.png";
import Team5 from "../../../assets/imgs/desktop/light-paper/our-team/Picture28.png";
import Team6 from "../../../assets/imgs/desktop/light-paper/our-team/Picture29.png";
import Team7 from "../../../assets/imgs/desktop/light-paper/our-team/Picture19.png";
import Team8 from "../../../assets/imgs/desktop/light-paper/our-team/Picture20.png";
import Team9 from "../../../assets/imgs/desktop/light-paper/our-team/Picture21.png";
import Team10 from "../../../assets/imgs/desktop/light-paper/our-team/Picture22.png";
import Team11 from "../../../assets/imgs/desktop/light-paper/our-team/Picture23.png";
import Team12 from "../../../assets/imgs/desktop/light-paper/our-team/Picture30.png";
import Team13 from "../../../assets/imgs/desktop/light-paper/our-team/Picture31.png";
import Team14 from "../../../assets/imgs/desktop/light-paper/our-team/Picture34.png";
import Partner1 from "../../../assets/imgs/desktop/light-paper/1.png";
import Partner2 from "../../../assets/imgs/desktop/light-paper/2.png";
import Partner3 from "../../../assets/imgs/desktop/light-paper/3.png";
import Partner4 from "../../../assets/imgs/desktop/light-paper/4.png";
import partners from "../../../assets/imgs/desktop/light-paper/1234.png.jpg";
import Black_page from "../../../assets/imgs/desktop/light-paper/A_black_image.jpeg";

/**
LightPaperDetails Component
Overview:
The LightPaperDetails component is responsible for displaying the details of a light paper in a desktop UI. It is a sub-component of the desktop UI and is used to render the details of a specific light paper, including its title, author, date, and content.

Details:
The LightPaperDetails component manages the state of the light paper details, including the current light paper being displayed.
It fetches the necessary data from an API or a local data source and updates the state accordingly.
The component is organized in a structured manner, with separate sections for the title, author, date, and content of the light paper.
The rendering logic of the component ensures that the details are displayed in a visually appealing and user-friendly manner.
The LightPaperDetails component accepts props such as the light paper ID or slug, which are used to fetch the specific light paper details.
*/


export default function LightPaperDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="lightpaper-wrapp">
      <div className="lightpaper_main_wrapp">
        <div className="container page-break-after p-0">
          <div className="text-center mb-5">
            <img
              src={Picture1}
              className="img-fluid VedasLabs-image"
              alt="first"
            />
            <p className="vedaslabs-main-content">Light Paper</p>
            <span className="VedasLabs-copyRight">© 2022 VedasLabs</span>
            <p className="vedaslabs-image-content">
              VedasLabs Inc. is relying upon exemptions from registration
              requirements of the Securities Act – particularly, Rule 506(c) of
              Regulation D. This Light Paper forms part of and should be read
              together, with the Offering Memorandum, dated July 5, 2022 (as
              amended and supplemented from time to time, the “Memorandum”),
              including, without limitation, the important information included
              in the Memorandum under the headings “Risk Factors” and “Use Of
              Proceeds”.
            </p>
          </div>
        </div>
        <div class="container page-break-after">
          <div class="pt-5 mb-5">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="text-center tital-wrap">
                  <h2 class="mt-5">
                    An equity crowdfunding and networking platform that hosts a
                    global community of Issuers, investors, and mentors.
                  </h2>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div className="content-main page-break-after">
                  <h2 class="main-wrap-title">Contents</h2>
                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index1">
                          The
                          Opportunity............................................
                          03
                        </a>
                      </b>
                    </p>
                    <p>
                      {" "}
                      <a href="#index1.1">
                        Challenges for Startups and Investors ............ 03
                      </a>
                    </p>
                    <p>
                      <a href="#index1.2">
                        Explosive Growth in Equity Crowdfunding ...... 04
                      </a>
                    </p>
                    <p>
                      <a href="#index1.3">
                        Web3.0 Is Disrupting the Financial World ....... 05
                      </a>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index2">
                          Introducing VedasLabs.................................
                          06
                        </a>
                      </b>
                    </p>
                    <p>
                      <a href="#index2.0">
                        Use Case Examples
                        ........................................ 07
                      </a>
                    </p>
                    <p>
                      <a href="#index2.1">
                        Why
                        VedasLabs?.............................................
                        08
                      </a>
                    </p>
                    <p>
                      <a href="#index2.2">
                        Our Values, Mission, and Vision...................... 09
                      </a>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index3">
                          Our Platform
                          ................................................. 10
                        </a>
                      </b>
                    </p>
                    <p>
                      <a href="#index3.1">
                        Overview
                        ........................................................
                        10
                      </a>
                    </p>
                    <p>
                      <a href="#index3.2">
                        Workflows and Screenshots: Desktop............ 12
                      </a>
                    </p>
                    <p>
                      <a href="#index3.3">
                        VedasLabs Mobile App
                        ..................................19
                      </a>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        {" "}
                        <a href="#index4">
                          Revenue
                          Model............................................ 23
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index5">
                          Venture
                          Exchange....................................... 24
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index6">
                          Architecture.................................................
                          25
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index7">
                          Milestones & Road Map ..............................
                          27
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index8">
                          Our Team
                          ......................................................
                          28
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index9">
                          Our
                          Advisors................................................
                          29
                        </a>
                      </b>
                    </p>
                  </div>

                  <div className="contents-wrap">
                    <p>
                      <b>
                        <a href="#index10">
                          Contact.........................................................32
                        </a>
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container page-break-after">
            <div class="opportunity-wrap" id="index1">
              <h1 class="opportunity-title">The Opportunity</h1>
              <div class="mb-3">
                <strong class="title-wrap" id="index1.1">
                  Challenges for Startups and Investors
                </strong>
              </div>
              <p class="text-justify opportunity-content">
                <b>
                  The 2020s, so far, have proven to be a tumultuous decade for
                  US-based startups and investors alike. Compounded by factors
                  such as COVID-19, geopolitical instability, and now the war in
                  Ukraine, a decline in early-stage investing has made it
                  difficult for startups to secure funding. Despite all of this,
                  socio-economic and technological developments have created
                  fertile conditions for investors and founders who are able to
                  take advantage of them.
                </b>
              </p>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex flex-column justify-content-between">
                <div className="content-main-wrap">
                  <div className="market-main">
                    <p class="text-justify market-content">
                      <b>Market Challenges:</b> According to data from PitchBook
                      and the National Venture Capital Association (NVCA), as a
                      percentage of total U.S. venture capital dollars invested,
                      angel/seed stage has reduced from 10.6% to 4.9% over the
                      last three years, early-stage has declined from 36.5% to
                      26.1% during the same period, while late-stage has
                      drastically increased from 52.9% to 69%, from new players
                      such as hedge funds and mutual funds<sup>1</sup>. NVCA
                      cited that the reason for these lower-risk investment
                      strategies was simply because of the entrance of larger
                      players in combination with the desire for companies that
                      have already built significant momentum.
                    </p>
                  </div>
                  <div>
                    <p class="text-justify vedaslabs-content">
                      <b>VedasLabs:</b> Our platform will alleviate both of
                      these problems <sup>2</sup> . First of all, we will appeal
                      to the 90% of investors that are locked out of these
                      opportunities with early-stage high-impact companies.
                      Secondly, we will add an element of mentorship built in so
                      that the early-stage founders and entrepreneurs on our
                      platform are building momentum by simply logging in.
                      Furthermore incentivizing participants through novel game
                      theory mechanisms and secondarymarketplace will ensure the
                      necessary liquidity.
                    </p>
                  </div>
                </div>
                <div class="page-footer">
                  Navin Chaddha, What is Happening to Risk-Taking in Venture
                  Capital?, TechCrunch, (Aug. 18, 2021),
                  https://techcrunch.com/2021/08/18/what-is-happening-to-
                  risk-taking-in-venture-capital/ 2 Id.
                </div>
              </div>
              <div class="col-md-6 d-flex flex-column justify-content-between">
                <div className="participants-content-main">
                  <div className="participants-main">
                    <p class="text-justify participants-content">
                      <b>Aiding market participants:</b> Early-stage startups
                      have found themselves with fewer funding opportunities and
                      bereft of valuable investor input and guidance. This
                      situation has been exacerbated by the negative market
                      impact of COVID-19 and recent global economic and
                      political instability.
                    </p>
                  </div>
                  <div>
                    <p class="text-justify participants-content">
                      <b>Timing: </b> A confluence of factors such as a
                      favorable debt cycle, lower interest rates, inflation, and
                      incremental income from government assistance has provided
                      both the motivation and means for many private individuals
                      to seek investment opportunities. These factors have been
                      a key driver of retail investment activity and growth in
                      the late 2010s and leading into the 2020s. However, many
                      would-be investors find themselves locked out of
                      opportunities as 90% of U.S. persons do not qualify as
                      accredited investors, thereby limiting their ability to
                      participate in capital markets 3 .
                    </p>
                  </div>
                </div>
                <div class="page-footer  pt-5">
                  <p className="page-footer-pt">
                    Peter Rex, Accredited Investor Rules are Perpetuating
                    Inequity, Fortune, (Dec. 20, 2021),
                    https://fortune.com/2021/12/20/accredited-investor-rules-
                    are-perpetuating-inequity-sec-crypto-investing-
                    crowdfunding-wealth-gap-peter-rex/
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container page-break-after">
            <div>
              <img alt="" className="img-fluid" src={Picture3} />
            </div>
            <div class="mt-3">
              <strong class="title-wrap" id="index1.2">
                Explosive Growth in Equity Crowdfunding
              </strong>
              <p class="text-justify mt-2 mb-3">
                <b class="text-justify opportunity-content">
                  While many industries were and continue to be severely
                  affected by the COVID-19 pandemic, the equity crowdfunding
                  market has boomed. Over recent years, equity crowdfunding has
                  become widely accepted, and even major names in the V.C. world
                  have joined the movement. Equity crowdfunding campaigns raised
                  over $74 million in 2018, over $100 million in 2019, and in
                  2020 over $211 million was raised in the increasingly-popular
                  industry 4 .
                </b>
              </p>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div>
                  <p class="text-justify vedaslabs-content">
                    From March 2021, equity crowdfunding received a boon when
                    the SEC harmonized and improved the exempt offering
                    framework by broadening the definition of an accredited
                    investor, increasing the limit that companies can raise from
                    $1.07 million to $5 million, and removing limits on how much
                    accredited investors can invest 5 .
                  </p>
                </div>

                <div>
                  <p class="text-justify vedaslabs-content">
                    These trends and developments led to the record-breaking
                    result for equity crowdfunding in 2021 that included banking
                    startup Mercury, with reserve commitments of $22.6 Million
                    from
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div>
                  <p class="text-justify vedaslabs-content">
                    over 3,000 individual investors and went on to raise capital
                    in a series B financing with notable VC funds Coatue and
                    Andreessen Horowitz. The equity crowdfunding platform
                    Wefunder's monthly investing volume exploding from $3-4
                    million dollars to $25 million; and the crowdfunding
                    platform StartEngine generated $13.3 million in the first
                    half of 2021, surpassing the total revenue it made over the
                    entirety of 2020 6 . Based on historical and recent growth,
                    the market for US-based equity crowdfunding can be
                    conservatively estimated to reach $28 billion by 2028 7
                  </p>
                </div>
              </div>
            </div>

            <div class="page-footer">
              <p>
                4
                https://www.forbes.com/sites/forbesagencycouncil/2021/12/20/the-meteoric-rise-of-equity-crowdfunding/
                5
                https://www.sec.gov/corpfin/facilitating-capital-formation-secg
                6
                https://www.businessinsider.com/raising-capital-through-equity-crowdfunding-investing-in-startups-2021-10
                7
                https://www.globenewswire.com/en/news-release/2022/04/12/2420860/0/en/Demand-for-Global-Crowdfunding-Market-Size-
                Share-to-Surpass-USD-28-2-Billion-by-2028-Exhibit-a-CAGR-of-11-8-Industry-Trends-Status-Revenue-Opportunities-
                Segmentation-Analysis-Forec.html
              </p>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container page-break-after">
            <div class="my-3">
              <strong class="title-wrap" id="index1.3">
                Web3.0 Is Disrupting the Financial World
              </strong>
              <p class="text-justify opportunity-content">
                <b>
                  The introduction of blockchain and associated web3.0
                  technologies heralded a new era in global finance. These
                  technologies bring a host of new benefits, including trustless
                  transactions, lower fees, security, anonymity, and an entirely
                  new global financial system.{" "}
                </b>
              </p>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="text-justify">
                  <p className="vedaslabs-content">
                    While volatility remains a perpetual issue, the
                    popularization of cryptocurrencies has created new incomes
                    streams and an entirely new wealth class: the
                    crypto-wealthy. While traditional financial institutions now
                    dominate aspects of the cryptocurrency industry, many
                    private individuals who were early to participate in
                    cryptocurrency speculation and investment have become
                    wealthy from relatively small initial investments. This new
                    crypto-wealth class is crypto-savvy, sometimes has radical
                    views on technology and finance, and has large amounts of
                    crypto assets at their disposal.
                  </p>
                </div>
              </div>

              <div class="col-md-6">
                <div class="text-justify">
                  <p className="vedaslabs-content">
                    While blockchain technology has entered mainstream
                    consciousness, been adopted by major corporations across
                    various industries, and now accounts for billions of dollars
                    of value, it is very much in the early growth phase.
                    Usability and accessibility are not mature enough for
                    mainstream adoption, but this is only a matter of time, and
                    the present-day may be considered the 'ground floor' for
                    innovative applications of the technologies.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-end">
              <img alt="" className="img-fluid" src={Picture4} />
            </div>
          </div>
        </div>

        <div class="my-5" id="index2">
          <div class="container">
            <div>
              <div class="my-3 text-center">
                <img alt="" class="img-fluid" src={Picture2} />
              </div>
            </div>
            <div class="row my-5">
              <div class="col-md-7">
                <div>
                  <h2 class="introducing-title">Introducing VedasLabs</h2>
                  <p class="text-justify vedaslabs-content">
                    VedasLabs was founded with the goal of disrupting and
                    completely revolutionizing the way that entities or projects
                    seeking funding in return for equity ('issuers') and
                    investors interact and create value for participants in
                    equity financing opportunities. Specifically, our goal is to
                    address funding and support issues that affect US-based
                    issuers and to provide both accredited and non-investors
                    instant and frictionless access to capital markets.
                  </p>
                  <p class="text-justify vedaslabs-content">
                    We aim to achieve this by leveraging the incredible
                    potential of blockchain and web3.0 technologies to build an
                    equity crowdfunding and networking platform that hosts a
                    global community of issuers, investors, and business
                    mentors. Our trusted and efficient platform will enable
                    these stakeholders to connect directly and leverage digital
                    asset issuance tools that lower the cost to issue, obtain,
                    and maintain immutable records of equity ownership.
                  </p>

                  <p class="text-justify vedaslabs-content">
                    We are building an ecosystem that is open to everyone and
                    enables and incentivizes our community members to
                    collaborate, innovate, and generate incredible value. Our
                    goal is to both capture share in the equity crowdfunding
                    market and to expand the market by providing a means of
                    participation for the many investors and issuers that will
                    otherwise struggle due to a lack of options from traditional
                    funding sources.
                  </p>
                </div>
              </div>

              <div class="col-md-5">
                <div class="community-main-wrap">
                  <div class="text-center community-wrap">
                    <div>
                      <p className="opportunity-title">
                        <b>Community Members</b>
                      </p>
                    </div>
                    <div>
                      <p className="vedaslabs-content">
                        <b>Investors</b>
                      </p>
                      <p className="community-members-content">
                        Individuals or institutions seeking investment
                        opportunities.
                      </p>
                    </div>
                    <div>
                      <p className="vedaslabs-content">
                        <b>Issuers</b>
                      </p>
                      <p className="community-members-content">
                        Founders, startups, or established in return for equity.
                      </p>
                    </div>
                    <div>
                      <p className="vedaslabs-content">
                        <b>Mentors</b>
                      </p>
                      <p className="community-members-content">
                        Industry veterans who wish to provide guidance and
                        support to issuers in return for equity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container page-break-after">
            <div id="index2.0">
              <strong class="title-wrap">Use Case Examples</strong>
            </div>
            <div className="use-case-main">
              <ul>
                <li>
                  A <b>pre-seed startup</b> lists its company goals and details
                  on VedasLabs and connects with a broad range of investors made
                  up of both accredited and non-accredited investors.
                </li>
                <li>
                  A <b>post-funding startup</b> accesses VedasLabs' network of
                  experienced mentors to access and leverage valuable hands-on
                  experience and knowledge to support their go-to-market
                  strategy.
                </li>
                <li>
                  An <b>institutional investor</b> uses VedasLabs to quickly
                  identify and participate in high-potential investment
                  opportunities that suit their individual investment goals.
                </li>
                <li>
                  An <b>accredited investor</b> uses the VedasLabs investor
                  community to build their professional network and join a team
                  of like-minded investors.
                </li>
                <li>
                  An <b>non-accredited retail investor</b> takes advantage of
                  group-based investing to use their limited resources and
                  invest in a promising startup in which they strongly believe.
                </li>
                <li>
                  An <b>business veteran</b> contributes their time and
                  experience to mentor and help a 3-person startup scale to 20
                  employees and receives equity stakes in the business in
                  return.
                </li>
              </ul>
            </div>
            <div>
              <img alt="" className="img-fluid" src={Picture5} />
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container page-break-after">
            <div class="pt-3" id="index2.1">
              <strong class="title-wrap">Why VedasLabs?</strong>
              <p className="vedaslabs-content">
                VedasLabs' leveraging of blockchain technology will provide key
                advantages and benefits over traditional funding methods.
                Moreover, the VedasLabs platform will deliver significantly
                higher user value than other blockchain launchpads in three
                major areas:
              </p>
            </div>

            <div class="row">
              <div class="col-md-4 text-center">
                <img className="img-fluid" src={Community} alt="Community" />
                <p class="my-2 community-members-content">
                  <b>Community</b>
                </p>
                <h3 className="community-members text-left">
                  Mentorship & Advisory Network
                </h3>
                <p class="text-left vedaslabs-content">
                  Our network of capable mentors will enable issuers to access
                  and leverage a pool of expert resources.
                </p>
                <h3 className="community-members text-left">
                  Lead Angel Investors
                </h3>
                <p class="text-left vedaslabs-content">
                  Retail investors around the world can follow and invest
                  alongside experienced angel investors.
                </p>
                <h3 className="community-members text-left">
                  Global Community
                </h3>
                <p class="text-left vedaslabs-content">
                  VedasLabs is home to a global community of investors, issuers,
                  and mentors.
                </p>
              </div>
              <div class="col-md-4 text-center">
                <img className="img-fluid" src={flexbility} alt="Community" />
                <p class="my-2 community-members-content">
                  <b>Flexibility</b>
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    Venture Exchange
                  </h3>
                  VedasLabs' secondary market will increase liquidity in
                  otherwise illiquid markets, reduce the time to exit for
                  investors, and reduce friction for buying or selling of
                  private shares.
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    No Long-term Lockups
                  </h3>
                  Investors are able to swap private assets to diversify and
                  reduce risk
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    Multi-cryptocurrency
                  </h3>
                  VedasLabs will accept payment in Bitcoin, Ethereum, and stable
                  coins.
                </p>
              </div>
              <div class="col-md-4 text-center">
                <img className="img-fluid" src={confidance} alt="Community" />
                <p class="my-2 community-members-content">
                  <b>Confidence</b>
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    Private Blockchain Technology
                  </h3>
                  VedasLabs is designed as a native, permission-based platform
                  that is secure, scalable, and easy to use.
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    Multi-layer Custodial System
                  </h3>
                  Multi-layer Custodial System Our multi-asset digital wallets
                  will be insured for up to $100 million for theft or lost keys.
                </p>
                <p class="text-left vedaslabs-content">
                  <h3 className="community-members text-left">
                    Staking of Shares
                  </h3>
                  Shares and digital assets held by investors or issuers may be
                  borrowed against or staked for APY.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container">
            <div class="our-visio-wrap" id="index2.2">
              <div class="text-center our-value">
                <h2>Our Values, Mission, & Vision</h2>

                <p class="mt-3 text-justify our-value-content">
                  VedasLabs’ core <b> VALUES </b> are based upon Aristotle’s
                  belief that every individual should have the right to property
                  and free trade so as to maximize one's human potential.
                  Happiness, welfare, human flourishing, and prosperity are key
                  themes that drive this belief.
                </p>

                <p class="mt-3 text-justify our-value-content">
                  Our <b>MISSION</b> is to provide a next-generation platform
                  and global community that will enable issuers, accredited and
                  non-accredited investors around the world to access and
                  participate in capital markets.
                </p>

                <p class="mt-3 text-justify our-value-content">
                  Our <b> VISION</b> is to enable a world where investment, the
                  exchange of knowledge, and collaboration between investors,
                  issuers, and industry experts is secure, instant, and
                  frictionless. We envisage a world free of opaque and
                  single-mindedly profit-driven corporations in which a global
                  community of investors, issuers, and mentors generate
                  incredible value and achieve great things.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container">
            <div id="index3">
              <h2 class="introducing-title">Our Platform</h2>
              <h5 class="title-wrap" id="index3.1">
                Overview
              </h5>
              <p className="our-platform-content">
                <b>
                  The VedasLabs platform, accessible by web browsers and smart
                  devices, provides a range of features and resources that
                  enable and facilitate interaction, collaboration, and
                  innovation between our global community of investors, issuers,
                  and mentors.
                </b>
              </p>
              <div class="text-center mt-4 mb-2">
                <h6>
                  <b className="our-platform-content">
                    Key Features and Resources
                  </b>
                </h6>
                <img alt="" className="img-fluid" src={Picture7} />
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b className="our-platform-issue">Issuers</b>
                  <p class="text-justify platform-issue-content">
                    Issuers create a profile and submit their application which
                    is screened and authenticated by VedasLabs staff. This
                    process includes business and legal due diligence and
                    evaluations of the team, core values, the problem they are
                    solving, market landscape, and financial performance or
                    projections. Once approved, VedasLabs handles regulatory
                    filings for each issuer before an offering goes live.
                    Issuers can monitor the progress of their offerings in
                    real-time through their dashboard and connect and engage
                    with other community members
                  </p>
                </div>
                <div class="col-md-6 mt-4">
                  <p class="text-justify platform-mentors-content">
                    Once an offering goes live, all invested funds are held in
                    escrow until the round closes. After the round closes, the
                    funds will be released to the founder's wallet in the form
                    of fiat USD or ERC20 compatible tokens.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b className="platform-issue-content">Investors</b>
                  <p class="text-justify platform-issue-content">
                    Investors may register and create a profile on the platform
                    free of charge. Upon approval and joining, investors can use
                    the dashboard to explore investment opportunities, learn
                    about trending topics, and connect with other investors or
                    issuers to ask questions or share insights to help their
                    investment decisions.{" "}
                  </p>
                </div>
                <div class="col-md-6 mt-4">
                  <p class="text-justify platform-mentors-content">
                    All invested funds are held in escrow until the round
                    closes. After the round closes, digital shares will be
                    distributed to investors' wallets. These wallets will be
                    insurable for up to $100 million for theft or lost keys.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b className="platform-issue-content">Mentors</b>
                  <p class="text-justify platform-issue-content">
                    Mentors submit an admission application that includes their
                    interests and experience and is evaluated by VedasLabs'
                    onboarding team. Once approved, mentors will be listed in
                    VedasLabs' mentor directory, which will provide relevant
                    details to issuers searching for mentor support. Conversely,
                    mentors will be able to explore issuer listings on the
                    VedasLabs platform to identify interesting opportunities and
                    reach out directly to issuers.
                  </p>
                </div>
                <div class="col-md-6 mt-4">
                  <p class="text-justify platform-mentors-content">
                    Mentors will receive incentives and rewards in the form of
                    equity from the companies that they support. The amount of
                    equity rewarded will be determined on a case-bycase basis
                    and depend upon factors such as the stage of the company,
                    the mentor's level of experience, the amount of time that
                    the mentor commits, and the value that the mentor provides.
                  </p>
                </div>
              </div>
              <div class="mt-5 text-center pt-5">
                <img alt="" className="img-fluid" src={Picture8} />
              </div>
            </div>
          </div>
        </div>

        <div class="my-5 page-break-after">
          <div class="container">
            <h3 class="title-wrap" id="index3.2">
              Workflows and Screenshots: Desktop
            </h3>
            <div className="page-break-after">
              <p className="workflows-content">
                The following page show screenshots from our browser-based
                commercial MVP, which is live at{" "}
                <a href="https://vedaslabs.io/">https://vedaslabs.io/</a> and
                provides an early iteration of our platform and resources for
                issuers and investors.
              </p>
              <div className="page-break-after">
                <div class="mt-5 pt-5">
                  <h5 class="desktop-title">Dashboard</h5>
                  <div class="text-center">
                    <img alt="" className="img-fluid" src={Picture9} />
                  </div>
                  <p class="page-footer">
                    The top page and user dashboard of the web-based client for
                    our commercial MVP. The page provides easy access to the key
                    platform features and functionality and provides investment
                    resources including educational materials and newsfeed
                    articles that are selected and tailored to each individual
                    user.
                  </p>
                </div>
                <div>
                  <h5 class="desktop-title">Issuer Workflow</h5>
                  <div class="text-center">
                    <img alt="" className="img-fluid" src={Picture11} />
                  </div>
                </div>
                <div>
                  <h5 class="desktop-title">Offering Page</h5>
                  <div class="text-center">
                    <img alt="" className="img-fluid" src={Picture10} />
                  </div>
                  <p class="page-footer">
                    The top page and user dashboard of the web-based client for
                    our commercial MVP. The page provides easy access to the key
                    platform features and functionality and provides investment
                    resources including educational materials and newsfeed
                    articles that are selected and tailored to each individual
                    user.
                  </p>
                </div>
              </div>
              <div className="page-break-after">
                <h5 class="desktop-title">Offering Page (Cont.)</h5>
                <div class="text-center">
                  <img alt="" className="img-fluid" src={Picture12} />
                </div>
              </div>
              <div className="page-break-after">
                <div>
                  <h5 class="desktop-title">Offering Page (Cont.)</h5>
                  <div class="text-center">
                    <img alt="" className="img-fluid" src={Picture13} />
                  </div>
                </div>
                <div>
                  <h5 class="desktop-title">Issuer Workflow</h5>
                  <div class="text-center">
                    <img alt="" className="img-fluid" src={Picture14} />
                  </div>
                </div>
              </div>
              <div className="page-break-after">
                <h5 class="desktop-title">Investor Profile</h5>
                <div class="text-center">
                  <img alt="" className="img-fluid" src={Picture15} />
                </div>
                <p class="page-footer">
                  Investor profile pages provide a biography, contact details,
                  areas of expertise, and investment preferences for each
                  Investor. An investment portfolio overview shows details that
                  include invested industries and the amount and number of
                  investments per year.
                </p>
              </div>
              <div className="page-break-after">
                <h5 class="desktop-title">Investor Portfolio</h5>
                <div class="text-center">
                  <img alt="" className="img-fluid " src={Picture16} />
                </div>
                <p class="page-footer">
                  Investor profile pages provide a biography, contact details,
                  areas of expertise, and investment preferences for each
                  Investor. An investment portfolio overview shows details that
                  include invested industries and the amount and number of
                  investments per year.
                </p>
              </div>
              <div className="page-break-after">
                <h5 class="desktop-title">Mentor Workflow</h5>
                <div class="text-center">
                  <img alt="" className="img-fluid" src={Picture16} />
                </div>
              </div>
              <div className="page-break-after">
                <h5 class="desktop-title">Mentor Portfolio</h5>
                <div class="text-center">
                  <img alt="" className="img-fluid" src={Picture16} />
                </div>
                <p class="page-footer">
                  On this page, mentors are able to see an overview of the
                  companies that they are mentoring and click through to details
                  of each, including equity allocations received in return for
                  mentorship contributions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container">
            <h2 class="title-wrap" id="index3.3">
              VedasLabs Mobile App
            </h2>
            <div>
              <h5 class="desktop-title">Investor Portfolio</h5>
              <div class="text-center">
                <img alt="" className="img-fluid" src={Mobile1} />
              </div>
            </div>
            <div className="">
              <h5 class="desktop-title">Startup Overview</h5>
              <div class="text-center">
                <img alt="" className="img-fluid" src={Mobile2} />
              </div>
            </div>
            <div className="">
              <h5 class="desktop-title">About Startup</h5>
              <div class="text-center">
                <img alt="" className="img-fluid" src={Mobile3} />
              </div>
            </div>
            <div className="">
              <h5 class="desktop-title">Startup Discussion Group</h5>
              <div class="text-center">
                <img alt="" className="img-fluid" src={Mobile4} />
              </div>
            </div>
          </div>
        </div>

        <div class="my-5">
          <div class="container">
            <div id="index4">
              <h2 class="main-wrap-title">Revenue Model</h2>
              <p className="revenue-model-content">
                VedasLabs derives revenue in the form of listing and transaction
                fees and a percentage of shares issued.
              </p>
              <p className="revenue-model-content">
                <b>Issuer Fees: </b>VedasLabs collects 6% of the capital raised
                by the issuer, a listing fee of $5,000, and 1-2% of the shares
                issued by the issuer
              </p>
              <p className="revenue-model-content">
                <b>Investor Fees: </b>Investors pay a transaction fee that
                varies depending on the method of payment. Accepted forms of
                payment are ACH, wires, and credit cards. Provisions for payment
                by Bitcoin, Ethereum, and stable coins are currently under
                development.
              </p>
            </div>
            <div class="text-center">
              <img alt="flow" className="img-fluid" src={flow1} />
            </div>
            <div class="text-left">
              <img alt="flow" className="img-fluid" src={Bgflow1} />
            </div>
          </div>
        </div>

        <div class="container page-break-after">
          <div id="index5">
            <h2 class="main-wrap-title">Venture Exchange</h2>
            <p class="text-justify exchange-content">
              <b>
                The Venture Exchange will comprise a secondary securities
                market, otherwise known as an Alternative Trading System (ATS),
                owned and hosted by the VedasLabs platform. The exchange will
                enable the listing, buying, and selling of shares in private
                companies registered with the VedasLabs platform by issuers and
                investors registered with the platform. The goal of the Venture
                Exchange is to provide a way for investors to divest, exchange,
                or profit from their held shares and as well as for issuers to
                raise additional funds by selling shares of their business.
              </b>
            </p>
            <div className="text-center">
              <img alt="" className="img-fluid" src={textflow} />
            </div>

            <div class="row">
              <div class="col-md-6">
                <p className="text-justify venture-content">
                  In order to operate a secondary marketplace for private
                  securities, VedasLabs will be seeking additional registration
                  with the SEC and FINRA to operate as a broker-dealer and
                  Alternative Trading System (ATS). VedasLabs' private
                  blockchain technology is designed to securely and
                  transparently conduct and record transactions and allow for
                  shares to be tokenized for significantly lower costs. Minting
                  fees are tiered according to the value of the offering.
                  <br />
                  <br />
                  We will are seeking to enable liquidity through a
                  Decentralized Exchange (DEX) and
                </p>
              </div>
              <div class="col-md-6">
                <p className="text-justify  venture-content">
                  Automated Market Maker (AMM) algorithm, and buyers and sellers
                  in the marketplace will pay a transaction fee, the amount of
                  which will be determined by whether they are market-makers or
                  takers. Marketmakers will pay lower fees (or receive a rebate)
                  as an incentive.
                  <br />
                  <br />
                  The objective is to achieve regulatory compliance in an
                  automated manner through the use of smart contracts throug
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container page-break-after">
          <div id="index6">
            <h2 class="main-wrap-title">Architecture</h2>
            <p class="text-justify architecture-content-main">
              <b>
                VedasLabs' proprietary technology goal is to deliver a solution
                that fills the gap between blockchain technology, as related to
                security tokens, and the needs of startups, mentors, investors,
                and regulators. Security tokens can alter the financial
                landscape, unlocking trillions of dollars in asset value and
                investment, programmable automating operations, and generating
                new paths to liquidity.
              </b>
            </p>
            <div class="row">
              <div class="col-md-6">
                <p class="text-justify architecture-content">
                  We will be leveraging a permissioned blockchain to create an
                  enterprise-grade distributed network built for security tokens
                  that startups, mentors, investors, and regulators require but
                  are missing from the marketplace today.
                </p>
                <p class="text-justify architecture-content">
                  The VedasLabs Security token issuance platform that we have
                  prototyped utilizes the Hyperledger Fabric that runs a smart
                  contract called "Chaincode" written in Go, node.js. Chaincode
                  runs in a secured Docker container isolated from the endorsing
                  peer process. Chaincode initializes and manages the ledger
                  state through transactions submitted by applications. This
                  allows for a private network where only authorized users can
                  execute, validate, and view transactions without exposing the
                  transaction information publicly. To enable this permissioned
                  network, a membership identity service utilizes managed user
                  I.D.s and authentication of all participants on the network.
                  An administrative access control list is built to provide
                  additional layers of permission through the authorization of
                  specific network operations. From a security perspective, the
                  integrity of this private network enables competing business
                  interests and any groups that require private, confidential
                  transactions to coexist on the same permissioned network.
                  Private channels are restricted messaging paths that provide
                  transaction privacy and confidentiality for specific subsets
                  of network members.
                </p>
                <p class="text-justify architecture-content">
                  {" "}
                  All data, including transaction, member, and channel
                  information, on a channel, are inaccessible to any network
                  members not explicitly granted access to that channel.
                </p>
                <p class="text-justify architecture-content">
                  Network roles are assigned by node type to provide concurrency
                  and parallelism to the network. Transaction execution is
                  separated from transaction ordering and commitment. Executing
                  transactions before ordering them enables each peer node to
                  process multiple transactions simultaneously. This concurrent
                  execution increases processing efficiency on each peer and
                  accelerates the delivery of transactions to the ordering
                  service.
                </p>
              </div>
              <div class="col-md-6">
                <p class="text-justify architecture-content">
                  In addition to enabling parallel processing, the division of
                  labor unburdens ordering nodes from the demands of transaction
                  execution and ledger maintenance. In contrast, peer nodes are
                  free from ordering (consensus) workloads. This bifurcation of
                  roles also limits the processing required for authorization
                  and authentication. All peer nodes do not have to trust all
                  ordering nodes and vice versa, such that processes on one can
                  run independently of verification by the other.
                </p>
                <p class="text-justify architecture-content">
                  Within the application is encoded logic for specific types of
                  transactions on a particular channel. A change of asset
                  ownership, for example, ensures that all trades that transfer
                  ownership are subject to the same rules and requirements for
                  endorsing and validating transactions.
                </p>
                <p class="text-justify architecture-content">
                  The platform is designed to provide low latency, stability,
                  and scalability. Core offchain functionality is built upon the
                  Amazon Virtual Private Cloud and extends via VPC Endpoint to
                  the Amazon Web Service Fabric Network for on-chain operations.
                  KYC and AML processing is carried out by a trusted third-party
                  service and communicates with the platform via API calls.{" "}
                </p>
                <p class="text-justify architecture-content">
                  The image below shows a high-level map of our core
                  architecture from the user-facing React.js-based frontend to
                  the Amazonmanaged blockchain.{" "}
                </p>
              </div>
            </div>
            <div class="text-center">
              <img
                alt="Architecture"
                className="img-fluid"
                src={Architecture}
              />
            </div>
          </div>
        </div>

        <div class="container page-break-after">
          <div id="index7">
            <h2 class="main-wrap-title">Milestones & Road Map</h2>
            <div>
              <h6 class="text-center title-wrap my-4">Progress to Date</h6>
              <p className="milestones-content">
                VedasLabs has a team of developers and experts in financial
                technology, blockchain, crypto, and decentralized finance
                fields. Our team and advisors are located around the US in a
                purposebuilt remote organization that operates flexibly and at a
                low cost and share the common vision of building the future of
                equity investment.
              </p>
              <p className="milestones-content">
                Since VedasLabs' commercial MVP launched in September 2021, we
                have been refining and adding features based on our community
                feedback. Presently, issuers complete profiles for themselves
                and their business, and investors can create investor profiles,
                browse, and invest in listed issuers. Our mobile app is in the
                final stage of development and will soon be released in the
                Apple Store and Google Play.
              </p>
            </div>
            <div>
              <h6 class="text-center title-wrap my-4">Our Path Ahead </h6>
              <p className="milestones-content">
                Funds from this capital raise will be used for onboarding users
                more rapidly, making key hires, advancing the development of our
                platform, including the release of our mobile app, strategic
                partnerships, and integrations, as well as additional licensing
                that, will allow for VedasLabs to facilitate larger capital
                raises under Reg D and Reg A as a registered broker-dealer.{" "}
              </p>
              <p className="milestones-content">
                The Company completed its pre-seed round of financing in 2021.
                Following the Offering with a maximum target of $2 million, we
                expect to have enough liquidity to execute our business plan for
                at least 18 months. Our significant challenges are developing
                and marketing a viable product in a competitive environment and
                sourcing quality deals.{" "}
              </p>
              <p className="milestones-content">
                We are targeting to have over 90,500 registered users and over
                45 issuers on our platform by Year 2 and deliver on our vision
                of becoming the all-in-one investment platform and global
                community of Investors, Issuers, and Mentors.
              </p>
            </div>
            <div>
              <h6 class="text-center title-wrap my-4 mt-4">
                Investment Terms and Use of Funds
              </h6>
              <div class="row chart-wrap">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <p className="investment-terms">
                        <b>Funding Round:</b>
                      </p>
                      <p className="investment-terms">
                        <b>Funding Max Goal:</b>
                      </p>
                      <p className="investment-terms">
                        <b>Agreement Type: </b>
                      </p>
                      <p className="investment-terms">
                        <b>Valuation Cap:</b>
                      </p>
                      <p className="investment-terms">
                        <b>Min Investment: </b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <p className="investment-funds">Seed</p>
                      <p className="investment-funds">$2M</p>
                      <p className="investment-funds">Class A Common Stock</p>
                      <p className="investment-funds">$15M</p>
                      <p className="investment-funds">$5K</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <img alt="chart" className="img-fluid" src={chart} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container mb-5 pb-5 page-break-after">
          <div class="mt-5 pt-5 mb-4" id="index8">
            <h2 class="main-wrap-title">Our Team</h2>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team1} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Tom Trevisani, CEO</h5>
              <p class="team-mamber-text">
                Prior to founding VedasLabs, Tom was a key member for generating
                new business in New York and Canada at the global leader in
                quantitative portfolio construction and risk analytics
                solutions, Axioma (acquired by Deutsche Börse), working with
                many of the worlds largest hedge funds and asset managers.
                Before Axioma, Tom was the Vice President, Finance & Business
                Intelligence for Recovco Mortgage Management and Senior
                Financial Analyst at Digital Risk (acquired by Mphasis and HP
                Company). He holds a BBA from Stetson University and an MBA from
                Rollins College. As a competitive athlete, Tom is continually
                driven towards personal and professional improvement while
                motivating others to success.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid rounded" src={Team14} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Seth Hill, Co-Founder </h5>
              <p class="team-mamber-text">
                Seth Hill is a data scientist with expertise in blockchain and
                AI / machine learning, and diverse experience that spans a
                variety of industries. A hands-on technologist, problem-solving
                enthusiast, and "knowledge seeker hungry for challenges", his
                development skills include Web3, Solidity, React, and Python.
                Seth has worked as a data engineer and data analyst at Urban
                SDK, Allstate Benefits, and C&C Technologies. He holds an MS in
                Materials Science and Engineering from the University of Central
                Florida and a BS in Physics from the University of Louisiana at
                Lafayette.
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team2} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Angelo Framularo, CCO</h5>
              <p class="team-mamber-text">
                Previously, Angelo was Director of Enterprise Risk at Laurel
                Road, based in Bridgeport, Connecticut. Prior to Laurel Road,
                Angelo managed the implementation and maintenance of Darien
                Rowayton Bank’s Enterprise Risk Management Program. Before
                joining Darien Rowayton Bank, Angelo was a Senior Manager in
                Global Risk & Compliance at American Express, in the U.S. Card
                Compliance Program Office, based in New York, NY. Angelo was
                also the Assistant Vice President of Compliance for RBS Card
                Services. He contributed to Deloitte Consulting on a global Know
                Your Customer/Anti-Money Laundering, Emerging Markets Trading
                for multiple international investment banks. Angelo is a
                licensed attorney in both New York and Connecticut.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team3} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Tom Trevisani, CEO</h5>
              <p class="team-mamber-text">
                Ryan Finnegan has 12+ years legal experience working in-house
                for private and public companies in the fields of construction,
                real estate development, estate planning, and investments. Ryan
                has also worked in law firms representing corporate and
                individual clients in litigated disputes, particularly in the
                fields of real estate, construction, financial instruments, and
                bankruptcy. Ryan received a J.D. and LL.M. (Taxation) from the
                University of Missouri - Kansas City School of Law. Prior to law
                school he attended the University of Florida and received a B.A.
                in Political Science. Ryan is licensed to practice law in
                Florida (2010) and Missouri (2007)
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team4} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Alison Stewart, Product Strategy</h5>
              <p class="team-mamber-text">
                Alison Stewart has 15+ years of experience within global
                financial technology companies in senior leadership roles. Most
                recently, she was the Senior Vice President, Product at
                Enfusion, a leading global provider of investment management
                software. Prior to Enfusion, Ali spent 14 years at FactSet, a
                globally trusted source for market, company and industry
                analysis. During her tenure at FactSet she served in senior
                roles across Product Development, Product Marketing, Sales and
                Product Strategy. Ali is an active member of Chief, a New York
                network built to drive more women into positions of power and
                keep them there. Ali is a CFA charter holder and graduated from
                the University of Richmond where she received a Bachelor of
                Science in Finance.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team5} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">
                Vipin Anand, Software Development Advisor
              </h5>
              <p class="team-mamber-text">
                Vipin is a Data Engineer at Axioma, a global leader in
                quantitative portfolio construction tools. Vipin was responsible
                for the development of the data layer access functionality
                within, AxiomaBlue - dynamic cloud based open environment
                designed to allow for interoperability across risk platforms.
                His weapons of choice are Python, MySQL, and MongoDB. Vipin was
                a co-founder of Metricle, which leverages machine learning,
                social network analysis, natural language processing and time
                series analysis to detect market moving events in real time.
                Vipin attended NYU where he received a Masters of Financial and
                Risk Engineering. Before that he also attained a Masters of
                Information and Operations Management.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team6} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Jaclyn Dong, Operations</h5>
              <p class="team-mamber-text">
                Jaclyn Dong has an extensive business and technical background.
                At VedasLabs, Jaclyn contributes to capital raising, sales, and
                product development. Prior to VedasLabs, she was a Sales
                Analytics Manager at Moody’s Analytics where she specialized in
                global incentive compensation. Before Moody’s, she was a
                business analyst at PopSugar, a millennial women’s lifestyle
                media company. Jaclyn holds an MBA and BA in International
                Relations from Rollins College.{" "}
              </p>
            </div>
          </div>

          <div class="mt-5 pt-5 mb-4 page-break-after" id="index9">
            <h2 class="main-wrap-title">Our Advisors</h2>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team7} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Moody Hadi, Innovation Advisor</h5>
              <p class="team-mamber-text">
                Moody is currently Group Manager of New Product Development &
                Financial Engineering at Standard & Poors and has repeated
                success in the rapid development and creation of quantitative
                financial risk solutions. Previously, Moody was Senior Director
                of Research and Development overseeing of OTC products. Most
                recently, he co-authored, "Credit Analysis A Perspective On
                Machine Learning In Credit Risk".{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team8} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Oleg Krook, Technical Advisor</h5>
              <p class="team-mamber-text">
                Oleg has 20+ years of engineering experience and 15+ leading
                tech teams. Oleg is Co-Founder and CEO of Remedy Point
                Solutions, a product development company that helps tech-enabled
                startups and growth stage companies build their products and
                strategies. In this capacity, Oleg led an integrated team at
                Classpass as Director of Engineering. He helped the company grow
                from Seed stage to Series B by solving key technical challenges
                including replacing their monolithic architecture with Rest API
                driven microservice architecture to support the scale. As CTO at
                Block Six Analytics, Oleg was responsible for building a SaaS
                sponsorship analytics platform driven by machine learning and
                business dashboards.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team9} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">
                Steven Hatzakis, Blockchain Research Advisor
              </h5>
              <p class="team-mamber-text">
                Steven is the Global Director of Online Broker Research at
                Forexbrokers.com (a Reink Media Brand), and is CEO of his
                NY-based consulting firm that advises blockchain and
                fintech-focused startups, and is a Director of BCA Ventures Inc.
                Steven first wrote about cryptocurrency market inefficiencies in
                2013 and has published over 1,000 articles and is quoted in
                Academic research papers and in the media. He contributes to
                open-source crypto tools, self-regulatory efforts such as The
                Brooklyn Project, and is head of the Blockchain Expert Committee
                (BEC) at Financial Commission. He has spoken at industry events
                around the world about cryptocurrency and financial technology.
                Steven holds a series III license and is a registered Commodity
                Trading Advisor (CTA) in the US.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team10} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">
                Patrick Williams, Public Relations Advisor
              </h5>
              <p class="team-mamber-text">
                Patrick has held Senior Management roles in several
                Entrepreneurial start-ups, including The Thursday Room, Vetr,
                and DailyWorth. Currently, he is Vice President of Global
                Marketing at Zeamo, a global gym aggregator. At DW he was the
                Publisher of Worth Magazine, contributing to its financial
                turnaround. The Brand’s leading wealth advisor section was
                launched under his Advisory and is now a core part of its
                business. Before joining Sandow, Patrick was a Publishing
                Executive at Conde Nast and was on the prelaunch and launch
                teams of the Conde Nast Portfolio. Patrick spent 14 years
                working for The Economist, and before that for The Telegraph and
                The Financial Times. He was Educated in England at Cherwell
                College and Ampleforth College.{" "}
              </p>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-2 mb-2">
              <img alt="team" className="img-fluid" src={Team11} />
            </div>
            <div class="col-md-10">
              <h5 class="team-name">Lucio Baise, Venture Capital Advisor</h5>
              <p class="team-mamber-text">
                Lucio is a Fin-tech entrepreneur who founded and successfully
                exited NetDelta, a patented solution and precursor to
                distributed ledger technology designed to address issues with
                credit derivatives market. Lucio went on to found and
                successfully exit LMRKTS. Initially receiving investment from
                the World Bank, Eurex and former Secretary of Treasury. LMRKTS
                remains the largest reducer of leverage and FX risk in the
                world. Lucio was a named scholar at Columbia University where he
                graduated pre-med with a degree in Environmental Biology and
                Economics.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div class="container">
            <div class="our-partner">
              <h2 class="py-4">Our Partners</h2>
              <img alt="" className="img-fluid our-partners" src={partners} />
              <div class="row partners-logo">
                <div class="col-md-6 my-4 text-center">
                  <img alt="" className="img-fluid" src={Partner1} />
                </div>
                <div class="col-md-6 my-4 text-center">
                  <img alt="" className="img-fluid" src={Partner2} />
                </div>
                <div class="col-md-6 my-4 text-center">
                  <img alt="" className="img-fluid " src={Partner3} />
                </div>
                <div class="col-md-6 my-4 text-center">
                  <img alt="" className="img-fluid" src={Partner4} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-break-after">
          <div class="container" id="index10">
            <div class="vedaslab-Footer py-5">
              <img alt="" className="img-fluid black-page" src={Black_page} />
              <div className="vedaslab-main">
                <div class="vedaslab-logo py-4 text-center">
                  <img alt="" className="img-fluid" src={Team12} />
                </div>
                <div class="vedaslab-logo py-4 text-center">
                  <img alt="" className="img-fluid px-5" src={Team13} />
                </div>
                <div class="contact-wrap">
                  <div class="contact-details">
                    <p>
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      tom@vedaslabs.io
                    </p>
                    <p>
                      <span>
                        <i class="fa fa-phone" aria-hidden="true"></i>
                      </span>
                      +1 (917) 588-3622
                    </p>
                    <p>
                      <span>
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                      </span>
                      linkedin.com/company/vedaslabs-inc
                    </p>
                    <p>
                      <span>
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </span>
                      twitter.com/VedasLabs_io
                    </p>
                    <p>
                      <span>
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </span>
                      facebook.com/VedasLabs
                    </p>
                    <p>
                      <span>
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </span>
                      instagram.com/vedaslabs_io
                    </p>
                  </div>
                </div>
                <div class="footer-2022 text-center pt-5">
                  <p>© 2022 VedasLabs, Inc. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-break-after">
          <div class="container">
            <div class="row">
              <div class="col-md-6 my-4">
                <p className="last-page-title">
                  <b>Disclaimer</b>
                </p>
                <p class="text-justify last-page">
                  The information provided in this document pertaining to
                  VedasLabs Inc. (the "Company"), its business assets, strategy,
                  and operations is for general informational purposes only and
                  is not a formal offer to sell, or a solicitation of an offer
                  to buy any securities, options, futures, or other derivatives
                  related to securities in any jurisdiction and its content is
                  not prescribed by securities laws. Information contained in
                  this document should not be relied upon as advice to buy or
                  sell or hold such securities or as an offer to sell such
                  securities. This document does not take into account nor does
                  it provide any tax, legal, or investment advice or opinion
                  regarding the specific investment objectives or financial
                  situation of any person. While the information in this
                  document is believed to be accurate and reliable, the Company
                  and its agents, advisors, directors, officers, employees, and
                  shareholders make no representation or warranties, expressed
                  or implied, as to the accuracy of such information and the
                  Company expressly disclaims any and all liability that may be
                  based on such information or errors or omissions thereof. The
                  Company reserves the right to amend or replace the information
                  contained herein, in part or entirely, at any time and
                  undertakes no obligation to provide the recipient with access
                  to the amended information or to notify the recipient thereof.
                  The information contained in this document is intended only
                  for the persons to whom it is transmitted for the purposes of
                  evaluating the Company. The information contained in this
                  document supersedes any prior presentation or conversation
                  concerning the Company. Any information, representations, or
                  statements not contained herein shall not be relied upon for
                  any purpose. Neither we nor any of our representatives shall
                  have any liability whatsoever, under contract, tort, trust, or
                  otherwise, to you or any person resulting from the use of the
                  information in this document by you or any of your
                  representatives or for omissions from the information in this
                  document. Additionally, the Company undertakes no obligation
                  to comment on the expectations of, or statements made by,
                  third parties
                </p>
                <p className="last-page-title">
                  <b>Confidentiality</b>
                </p>
                <p class="text-justify last-page">
                  This document is confidential and is intended, among other
                  things, to present a general outline of the Company. The
                  contents are not to be reproduced or distributed to the public
                  or the press. Each person who has received a copy of this
                  document (whether or not such person purchases any securities)
                  is deemed to have agreed: (i) not to reproduce or distribute
                  this document, in whole or in part, without the prior written
                  consent of the Company, other than to legal, tax, financial
                  and other advisors on a need to know basis, (ii) if such
                  person has not purchased securities, to return this document
                  to the Company upon its request, (iii) without the prior
                  written consent of the Company, not to disclose any
                  information contained in this document except to the extent
                  that such information was (a) previously known by such person
                  through a source (other than the Company) not bound by any
                  obligation to keep such information confidential, (b) in the
                  public domain through no fault of such person, or(c) lawfully
                  obtained at a later date by such person from sources (other
                  than the Company) not bound by any obligation to keep such
                  information confidential, and (iv) to be responsible for any
                  disclosure of this document, or the information contained
                  herein, by such person or any of its employees, agents, or
                  representatives.{" "}
                </p>
                <p className="last-page-title">
                  <b>Statements</b>
                </p>
                <p class="text-justify last-page">
                  Although we believe that our plans, intentions, and
                  expectations reflected in, or suggested by, such
                  forwardlooking statements are reasonable, and we can give no
                  assurance that such plans, intentions, or expectations will be
                  achieved. By their nature, forward-looking statements:(i)
                  speak only as of the date they are made, (ii) are not
                  guarantees of future performance or results, and (iii) are
                  subject to risks, uncertainties, and assumptions that are
                  difficult to predict or quantify. Therefore, actual results
                  could differ materially and adversely from those
                  forward-looking statements as a result of a variety of
                  factors. Such risks, uncertainties and other factors include,
                  but are not limited to, (1) changes in general and local
                  economic, industry and business conditions and impacts of a
                  sustained homebuilding downturn; (2) adverse{" "}
                </p>
              </div>
              <div class="col-md-6 my-5">
                <p class="text-justify weather-last-page">
                  weather and other environmental conditions and natural
                  disasters; (3) levels of indebtedness and restrictions on the
                  Company’s operations and activities imposed by the agreements
                  governing the Company’s outstanding indebtedness; (4) the
                  Company's sources of liquidity; (5) changes in credit ratings;
                  (6) changes in market conditions and seasonality of the
                  Company’s business; (7) client acquisition and client
                  deposits; (8) competition and competitors; (9) regional and
                  local economic factors, including dependency on certain
                  sectors of the economy, and employment levels affecting
                  offshore financial services; (10) fluctuations in interest
                  rates and the availability of financing; (11) changes in laws
                  and policy; (12) operations through joint ventures with third
                  parties; (13) government regulation, including the
                  discretionary powers of our regulators to remove our licenses;
                  (14) litigation and claims; (15) levels of competition; (16)
                  availability and terms of financing to the Company; (17)
                  successful identification and integration of acquisitions;
                  (18) significant influence of the Company’s controlling
                  stockholders; (19) availability of net operating loss
                  carryforwards; (20) availability of payment provider,
                  correspondent banks, de-risking and currency rate
                  fluctuations; (21) geopolitical risks, terrorist acts and
                  other acts of war; (22) withdraws and poor loans (23) loss of
                  key management personnel or failure to attract qualified
                  personnel; (24) information technology failures and data
                  security breaches; (25) legal claims brought against us and
                  not resolved in our favor; (26) risks associated with
                  cryptocurrencies; (27) reliance on third parties; and (28)
                  certain risks, uncertainties and other factors described in
                  detail in the which is expressly incorporated herein. Except
                  as otherwise required by applicable securities laws, we
                  undertake no obligation to publicly update or revise any
                  forward-looking statements, whether as a result of new
                  information, future events, changed circumstances, or any
                  other reason.
                </p>
              </div>
            </div>
          </div>
        </div>

        <a
          className="downloadBtn"
          href="https://strapi-upload-profile-images.s3.amazonaws.com/Vedas_Labs_Light_Paper_2022_a16z_for_submission_e5c2f0e48a.pdf"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  );
}
