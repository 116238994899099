import React, { Component } from "react";

/**
 * ErrorBoundary Component
 * 
 * Overview:
 * The ErrorBoundary component is used to catch and handle errors that occur within its child components. It provides a fallback UI to display when an error occurs.
 * 
 * Details:
 * - The ErrorBoundary component extends the React Component class.
 * - It has a state property called 'hasError' which is used to track whether an error has occurred.
 * - The static method 'getDerivedStateFromError' is used to update the state when an error occurs.
 * - The 'componentDidCatch' method is used to log the error.
 * - The render method checks the 'hasError' state and renders either the fallback UI or the child components.
 * 
 * Dependencies:
 * The ErrorBoundary component does not have any external dependencies.
 */
class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error
    // console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <h1 className="font-38-semiBold text-center mt-5 pt-3">
          Something went wrong.
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
