import isEmpty from "../is-empty";

/**
 * validateRaiseCapitalOrgDataNotEmptyMoreInfo Function
 *
 * Overview:
 * This function validates whether the required data for raising capital is not empty. It checks if the provided data object contains values for all the required fields.
 *
 * Details:
 * - The function takes a data object as a parameter and checks if the following fields are empty: company_logo, company_name, phone_number, website, business_address_line1, city, state, country, zip, industry, company_details, pitch_deck, product_overview, business_model.
 * - If any of these fields are empty, the function sets the 'errors' object with the 'e' property set to "error".
 * - The function returns an object with two properties: 'errors' and 'isValid'. The 'errors' property contains the 'e' property set to "error" if any of the required fields are empty. The 'isValid' property is set to true if the 'errors' object is empty, indicating that all the required fields have values.
 *
 * Dependencies:
 * This function does not have any external dependencies.
 */
export const validateRaiseCapitalOrgDataNotEmptyMoreInfo = (data) => {
  let errors = {};

  // console.log(data);
  console.log(data, "data");
  if (
    isEmpty(data.company_logo) ||
    isEmpty(data.company_name) ||
    isEmpty(data.phone_number) ||
    isEmpty(data.website) ||
    isEmpty(data.business_address_line1) ||
    // isEmpty(data.business_address_line2) ||
    isEmpty(data.city) ||
    isEmpty(data.state) ||
    isEmpty(data.country) ||
    isEmpty(data.zip) ||
    isEmpty(data.industry) ||
    isEmpty(data.company_details) ||
    isEmpty(data.pitch_deck)
    // isEmpty(data.pitch_video_url) ||
    // isEmpty(data.product_overview) ||
    // isEmpty(data.business_model)
  ) {
    errors.e = "error";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
