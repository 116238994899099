import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * Investor Calculator Validation
 * 
 * Overview:
 * This module provides a function to validate the input data for the investor calculator. It checks if the required fields are present and performs additional validation for specific fields.
 * 
 * Details:
 * - The function takes an object `data` as input and checks for the presence of required fields such as `yearlyIncome`, `networth`, `jobTitle`, `jobIndustry`, and `dateOfBirth`.
 * - If the `spouse_income_included` field is true, it also checks for the presence of `fname`, `lname`, and `email` fields, and validates the email format.
 * - If the `invested_outside_vlabs` field is true, it checks for the presence of `amountInvestedOutsideOfVL` field.
 * - The function returns an object with `errors` and `isValid` properties. The `errors` object contains error messages for the fields that failed validation, and the `isValid` property indicates if the input data is valid or not.
 * 
 * Dependencies:
 * - The function depends on the `isEmpty` function to check if a field is empty.
 * - It also uses the `Validator` object to validate the email format.
 */
export const validateInvestorCalculator = (data) => {
  let errors = {};

  //   console.log(data);

  if (isEmpty(data.yearlyIncome)) {
    errors.yearlyIncome = "Yearly income is required";
  }

  if (isEmpty(data.networth)) {
    errors.networth = "Net worth is required";
  }

  if (isEmpty(data.jobTitle)) {
    errors.jobTitle = "Job title is required";
  }

  if (isEmpty(data.jobIndustry)) {
    errors.jobIndustry = "Job industry is required";
  }

  if (data.dateOfBirth === null) {
    errors.dateOfBirth = "Date of birth is required";
  }

  if (data.spouse_income_included) {
    if (isEmpty(data.fname)) {
      errors.fname = "First name is required";
    }

    if (isEmpty(data.lname)) {
      errors.lname = "Last name is required";
    }

    if (isEmpty(data.email)) {
      errors.email = "Email is required";
    }

    if (!isEmpty(data.email) && !Validator.isEmail(data.email)) {
      errors.email = "Please enter valid email";
    }
  }

  if (data.invested_outside_vlabs) {
    if (isEmpty(data.amountInvestedOutsideOfVL)) {
      errors.amountInvestedOutsideOfVL =
        "Amount invested outside of VedasLabs is required";
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
