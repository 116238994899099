import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import InvestAfterProfileSetupProfileTabpanel from "./InvestAfterProfileSetupProfileTabpanel";
// import InvestCard from "./InvestCard";
import InvestAfterProfileSetupUpdates from "./InvestAfterProfileSetupUpdates";
import InvestAfterProfileSetupCalculator from "./InvestAfterProfileSetupCalculator";
import InvestAfterProfileSetupPortfolio from "./InvestAfterProfileSetupPortfolio";

/**
 * InvestAfterProfileSetup Component
 *
 * Overview:
 * The InvestAfterProfileSetup component is responsible for rendering the main page of the investment section after the user has completed their profile setup. It provides tabs for different sections of the investment page, such as updates, portfolio, calculator, and profile.
 *
 * Details:
 * - The component uses the useEffect hook to remove the activeVLInvestAfterProfileSetupPageTabIndex from localStorage when the component is unmounted.
 * - The component uses the useState hook to manage the activeTabIndexVal state, which determines the currently selected tab.
 * - The handleOnSelectTab function is called when a tab is selected and updates the activeTabIndexVal state and saves it to localStorage.
 * - The renderContent function renders the content of the component, including the Tabs component from the react-tabs library.
 * - The renderContent function conditionally renders different TabPanel components based on the activeTabIndexVal state.
 *
 * Dependencies:
 * - react: The component uses React to create and manage the UI elements.
 * - react-tabs: The component uses the Tabs, TabList, Tab, and TabPanel components from the react-tabs library to create the tabbed interface.
 * - InvestAfterProfileSetupUpdates: The component imports and renders the InvestAfterProfileSetupUpdates component in one of the TabPanel components.
 * - InvestAfterProfileSetupPortfolio: The component imports and renders the InvestAfterProfileSetupPortfolio component in one of the TabPanel components.
 * - InvestAfterProfileSetupCalculator: The component imports and renders the InvestAfterProfileSetupCalculator component in one of the TabPanel components.
 * - InvestAfterProfileSetupProfileTabpanel: The component imports and renders the InvestAfterProfileSetupProfileTabpanel component in one of the TabPanel components.
 */
export default function InvestAfterProfileSetup() {
  useEffect(() => {
    /* on component will unmount */
    return () => {
      /* remove activeVLInvestAfterProfileSetupPageTabIndex */
      localStorage.removeItem("activeVLInvestAfterProfileSetupPageTabIndex");
    };
  }, []);

  /*================================================================
        renderContent
  ================================================================*/
  const [activeTabIndexVal, setActiveTabIndexVal] = useState(
    isNaN(
      parseInt(
        localStorage.getItem("activeVLInvestAfterProfileSetupPageTabIndex")
      )
    )
      ? 0
      : parseInt(
          localStorage.getItem("activeVLInvestAfterProfileSetupPageTabIndex")
        )
  );

  const handleOnSelectTab = (val) => {
    setActiveTabIndexVal(val);
    localStorage.setItem("activeVLInvestAfterProfileSetupPageTabIndex", val);
  };

  // renderContent
  const renderContent = () => {
    return (
      <>
        <div className="vl-tabs-explore vl-tabs-explore--mentor-mainPage">
          <Tabs selectedIndex={activeTabIndexVal} onSelect={handleOnSelectTab}>
            <TabList>
              {/* <Tab>Companies invested in</Tab> */}
              <Tab>updates</Tab>
              <Tab>Portfolio</Tab>
              <Tab>calculator</Tab>
              {/* <Tab>Profile</Tab> */}
            </TabList>
            {/* <TabPanel>
              <div className="vl-invest-after-profile-setup-tabpanel-company">
                <InvestCard />
              </div>
            </TabPanel> */}
            <TabPanel>
              <InvestAfterProfileSetupUpdates />
            </TabPanel>
            <TabPanel>
              <InvestAfterProfileSetupPortfolio />
            </TabPanel>
            <TabPanel>
              <div className="vl-invest-after-profile-setup-tabpanel-company">
                <InvestAfterProfileSetupCalculator />
              </div>
            </TabPanel>
            {/* <TabPanel>
              <div className="vl-invest-after-profile-setup-tabpanel-profile">
                <InvestAfterProfileSetupProfileTabpanel />
              </div>
            </TabPanel> */}
          </Tabs>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="vl-invest-main-page">{renderContent()}</div>
    </>
  );
}
