import {
  SET_FOLLOWERS_BY_USER_ID,
  SET_ALL_FOLLOWERS_UPDATES,
  CLEAR_ALL_FOLLOWERS_UPDATES,
} from "./../types";

const initialState = {
  followersAllByUserId: {},
  followersAllUpdates: [],
};

/**
 * followersReducer
 * 
 * Overview:
 * This reducer handles the state related to followers in the application. It manages the followersAllByUserId and followersAllUpdates properties in the state.
 * 
 * Details:
 * - The followersAllByUserId property stores an array of followers for each user ID.
 * - The followersAllUpdates property stores an array of updates related to followers.
 * - The reducer handles actions related to setting followers by user ID, adding updates to the followersAllUpdates array, and clearing the followersAllUpdates array.
 * 
 * Usage:
 * This reducer is used in the Redux store to manage the followers state. It can be imported and combined with other reducers using the combineReducers function from Redux.
 * 
 * Dependencies:
 * - initialState: The initial state for the followers reducer.
 * - SET_FOLLOWERS_BY_USER_ID: The action type for setting followers by user ID.
 * - SET_ALL_FOLLOWERS_UPDATES: The action type for adding updates to the followersAllUpdates array.
 * - CLEAR_ALL_FOLLOWERS_UPDATES: The action type for clearing the followersAllUpdates array.
 */
export default function followersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FOLLOWERS_BY_USER_ID:
      return {
        ...state,
        followersAllByUserId: action.payload,
      };

    case SET_ALL_FOLLOWERS_UPDATES:
      return {
        ...state,
        followersAllUpdates: [...state.followersAllUpdates, action.payload],
      };

    case CLEAR_ALL_FOLLOWERS_UPDATES:
      return {
        ...state,
        followersAllUpdates: [],
      };

    default:
      return state;
  }
}
