/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getInvestorByUserId } from "../../../../../web-app-store/actions/investAction";
import isEmpty from "../../../../../web-app-store/validations/is-empty";
import { useDispatch, useSelector } from "react-redux";
import ChatBox from "../../ChatBox/ChatBox";
import { chatObj } from "../../../../../constant";
import { socket } from "../../../../../config/web-sockets";
import axios from "axios";
import { backendUrl } from "../../../../../web-app-store/actions/config";
import ChatMenu from "../../../Chat/chatMenu";
import "./style.scss";
import ChatSidebar from "../../../Chat/Sidebar/ChatSidebar";
import Lottie from "react-lottie-player";
import chatImage from "../../../../../web-app-assets/imgs/desktop/backgrounds/chat.json";
import Skeleton from "react-loading-skeleton";
import SideNavbar from "../../../../../web-app-components/desktop/header/SideNavbar";
import Footer from "../../../header-footer/Footer";
import PageTitle from "../../../../../web-app-components/desktop/header/PageTitle";
import { message } from "../../../../../web-app-components/desktop/header/activeMenu";

/**
 * MentorChatBox Component
 *
 * Overview:
 * The MentorChatBox component is responsible for displaying the chat interface for a mentor. It manages the state of the chat conversation, selected chat, and other related data. It also handles socket connections for real-time messaging.
 *
 * Details:
 * - The component uses React hooks to manage state, including useState and useEffect.
 * - It fetches data from an API endpoint using axios to retrieve the chat messages.
 * - The component renders a chat sidebar and a chat menu, which display the list of chats and conversation messages.
 * - It also renders a chat box for sending and receiving messages.
 * - The component uses socket.io for real-time messaging and emits events to join a chat and set up the socket connection.
 * - It uses Redux to retrieve the mentor's data and dispatch actions to get the investor data.
 *
 * Usage:
 * To use the MentorChatBox component, import it and include it in the desired location in your application. Make sure to provide the necessary props if required.
 *
 * Dependencies:
 * - React: The component is built using React and utilizes React hooks for state management.
 * - axios: The component uses axios to make HTTP requests to retrieve chat messages.
 * - socket.io-client: The component uses socket.io-client to establish a socket connection for real-time messaging.
 * - Redux: The component uses Redux to retrieve the mentor's data and dispatch actions to get the investor data.
 * - react-redux: The component uses react-redux to connect the Redux store to the component.
 */
export const MentorChatBox = () => {
  const [investorData, setInvestorData] = useState({
    profile_image: "",
    first_name: "",
    last_name: "",
  });
  const [conversation, setConversation] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [dataChatId, setDataChatId] = useState("");
  const [isDataBaseEmpty, setIsDataBaseEmpty] = useState(true);
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [isChatMenuShow, setIsChatMenuShow] = useState(true);
  const [newMsgReceived, setNewMsgReceived] = useState([]);
  const [isChatShow, setIsChatShow] = useState(false);
  const [newMsgToggle, setNewMsgToggle] = useState({});
  const [chatId, setChatId] = useState("");
  const [chatMenu, setChatMenu] = useState([]);
  const [myChatId, setMyChatId] = useState(chatId);
  const chatIdRef = useRef(chatId);
  const investor = useSelector((state) => state.invest.investor);
  const myData = JSON.parse(localStorage.getItem("userData"));
  const [clientSocketId, setClientSocketId] = useState("");
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (chatId && dataChatId) {
      try {
        let { data } = await axios.get(
          `${backendUrl}/message-data/${dataChatId}`
        );
        data?.messages ? setIsDataBaseEmpty(false) : setIsDataBaseEmpty(true);
        setConversation(data?.messages || []);
        setMyChatId(chatId);
      } catch (error) {}
    }
  }, [chatId, dataChatId]);

  useEffect(() => {
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, [socket]);

  useEffect(() => {
    dispatch(getInvestorByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (selectedChat) {
      try {
        localStorage.setItem("chatId", selectedChat?.ChatId);
        setIsChatShow(true);
        setChatId(selectedChat?.ChatId);
        socket.emit("join chat", selectedChat?.ChatId);
        setDataChatId(selectedChat?.MessageDataBaseId);
        socket.emit("setup", myData.socketId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedChat]);

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];
      setInvestorData({
        ...investorData,
        profile_image: isEmpty(data.profile_image)
          ? null
          : data.profile_image.url,
        first_name: data.first_name,
        last_name: data.last_name,
      });
    }
  }, [investor]);

  return (
    <React.Fragment>
      <SideNavbar />
      <PageTitle imgPath={message.icon} pageTitle={message.title} />
      <div className="mentor">
        <div className="card-main-wrap">
          <div className="row main-chat-box m-0 justify-content-center">
            <ChatMenu
              setSelectedChat={setSelectedChat}
              selectedChat={selectedChat}
              myData={myData}
              conversationLength={conversation?.length}
              isProfileShow={isProfileShow}
              isChatMenuShow={isChatMenuShow}
              newMsgReceived={newMsgReceived}
              setClientSocketId={setClientSocketId}
              setNewMsgToggle={setNewMsgToggle}
              newMsgToggle={newMsgToggle}
              chatMenu={chatMenu}
              setChatMenu={setChatMenu}
            />
            <div className="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 p-0">
              {isEmpty(chatMenu) && (
                <div className="start-conversation-msg main-start-conversation">
                  <Lottie
                    loop
                    animationData={chatImage}
                    play
                    className="conversation-image"
                  />
                  <p className="start-messaging-text">
                    Please add a mentor to start a conversation.
                  </p>
                </div>
              )}
              {isChatShow && (
                <ChatBox
                  className="chat-section-data"
                  myData={myData}
                  selectedChat={selectedChat}
                  conversation={conversation}
                  setConversation={setConversation}
                  setChatId={setChatId}
                  isDataBaseEmpty={isDataBaseEmpty}
                  chatId={chatId}
                  chatIdRef={chatIdRef}
                  setDataChatId={setDataChatId}
                  dataChatId={dataChatId}
                  myChatId={myChatId}
                  setIsChatShow={setIsChatShow}
                  isChatShow={isChatShow}
                  setNewMsgReceived={setNewMsgReceived}
                  newMsgReceived={newMsgReceived}
                  mentor
                  clientSocketId={clientSocketId}
                  setSelectedChat={setSelectedChat}
                  setNewMsgToggle={setNewMsgToggle}
                  newMsgToggle={newMsgToggle}
                  setChatMenu={setChatMenu}
                  chatMenu={chatMenu}
                />
              )}
            </div>
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </React.Fragment>
  );
};
