import { SET_CHAT_MENU_DATA } from "../types";

const initialState = {
  chatMenu: {},
};

/**
 * chatMenuReducer
 * 
 * Overview:
 * This reducer function handles the state for the chat menu in the application. It updates the chat menu data based on the dispatched actions.
 * 
 * Details:
 * - The reducer function takes the current state and an action as parameters and returns the updated state.
 * - The reducer uses a switch statement to handle different action types.
 * - When the action type is SET_CHAT_MENU_DATA, the reducer updates the chatMenu property of the state with the payload of the action.
 * - For any other action types, the reducer returns the current state unchanged.
 * 
 * Usage:
 * This reducer is typically used in conjunction with the Redux store and combined with other reducers to manage the application state.
 * 
 * Dependencies:
 * - The reducer relies on the SET_CHAT_MENU_DATA action type to update the chat menu data.
 * - The initialState variable is used to set the initial state of the reducer.
 */
export default function chatMenuReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CHAT_MENU_DATA:
      return {
        chatMenu: action.payload,
      };

    default:
      return state;
  }
}
