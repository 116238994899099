/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams, NavLink } from "react-router-dom";
import {
  getAllMentors,
  getChatMenuData,
} from "../../../../web-app-store/actions/mentorAction";
import MessageIcon from "../../../../assets/imgs/desktop/icon/messages.svg";
import AvatarProfile from "../../../../assets/imgs/desktop/common/user-image.png";
import ChatBox from "../ChatBox/ChatBox";
import { socket } from "../../../../config/web-sockets";
import axios from "axios";
import { backendUrl } from "../../../../web-app-store/actions/config";
import isEmpty from "../../../../web-app-store/validations/is-empty";
import BackArrow from "../../../../assets/imgs/desktop/icon/back-button.png";
import "./style.scss";
import {
  mentor,
  newLoginSignUpLink,
  userChatBox,
} from "../../header-footer/pageLinks";
import UserChatBox from "./UserChatBox/UserChatBox";
import { nanoid } from "nanoid";
import { MentorChatBox } from "../mentor-profile/mentor-chatbox/MentorChatBox";
import { Nav } from "react-bootstrap";
import store from "../../../../web-app-store/store";
import { SET_CREATE_USER } from "../../../../web-app-store/types";
import SideNavbar from "../../../../web-app-components/desktop/header/SideNavbar";
import Footer from "../../header-footer/Footer";
import linkedin from "../../../../assets/imgs/desktop/common/linkedin.svg";
import PageTitle from "../../../../web-app-components/desktop/header/PageTitle";

let isUser = JSON.parse(localStorage.getItem("isUser"));
if (isUser) {
  store.dispatch({
    type: SET_CREATE_USER,
    payload: isUser,
  });
}
/**
 * MentorDetails Component
 *
 * Overview:
 * The MentorDetails component displays the details of a mentor. It allows users to view the mentor's profile information and send messages to the mentor.
 *
 * Details:
 * - The component uses state hooks to manage various pieces of data, such as the mentor details, chat conversation, chat menu, and user chat menu.
 * - It makes API calls to fetch mentor data, chat menu data, and message data.
 * - The component uses socket.io for real-time communication with the server.
 * - It renders the mentor's profile image, name, job title, and a button to send a message to the mentor.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - react-redux: The component uses Redux for state management.
 * - react-router-dom: The component uses React Router for navigation.
 * - axios: The component uses Axios for making HTTP requests.
 * - socket.io-client: The component uses Socket.IO for real-time communication.
 * - nanoid: The component uses nanoid for generating unique IDs.
 * - useHistory: The component uses the useHistory hook from React Router for programmatic navigation.
 * - useDispatch: The component uses the useDispatch hook from react-redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from react-redux to access the Redux store.
 * - useParams: The component uses the useParams hook from react-router-dom to access URL parameters.
 */
const MentorDetails = () => {
  const [socketId, setSocketId] = useState(null);
  const [chatToggle, setChatToggle] = useState(false);
  const [mentorDetails, setMentorDetails] = useState([]);
  const [manageMentor, setManageMentor] = useState({});
  const [conversation, setConversation] = useState([]);
  const [chatId, setChatId] = useState("");
  const [isDataBaseEmpty, setIsDataBaseEmpty] = useState(true);
  const [messageInfoId, setMessageInfoId] = useState("");
  const [dataChatId, setDataChatId] = useState("");
  const [chatMenu, setChatMenu] = useState([]);
  const [userChatMenu, setUserChatMenu] = useState([]);
  const [newMsgReceived, setNewMsgReceived] = useState([]);
  const [isUser, setIsUser] = useState(true);
  const history = useHistory();
  const uniqueMsgId = nanoid();
  const uniqueChatId = nanoid();
  const uniqueMenuId = nanoid();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  const { id } = useParams();

  const mentorRecords = useSelector((state) => state.mentor.allMentors);
  const myData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    dispatch(getAllMentors());
  }, [dispatch]);

  useEffect(() => {
    setMentorDetails(mentorRecords);
  }, [mentorRecords]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (chatId && dataChatId) {
      try {
        let { data } = await axios.get(
          `${backendUrl}/message-data/${dataChatId}`
        );
        setMessageInfoId(data?.id);
        data?.messages ? setIsDataBaseEmpty(false) : setIsDataBaseEmpty(true);
        setConversation(data?.messages || []);
      } catch (error) {
        console.log("Talking First Time");
      }
    }
  }, [chatId, dataChatId]);

  useEffect(async () => {
    const { data: userMenuData } = await axios.get(
      `${backendUrl}/chat-menu/${myData?.chat_infoId}`
    );
    setUserChatMenu(userMenuData?.chat_menu);
  }, []);

  useEffect(async () => {
    if (!isEmpty(manageMentor?.chat_infoId)) {
      const { data: chatMenuData } = await axios.get(
        `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`
      );
      setChatMenu(chatMenuData?.chat_menu);
    }
  }, [manageMentor]);

  useEffect(() => {
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, [socket]);

  useEffect(() => {
    if (chatToggle && chatId) {
      socket.emit("setup", myData.socketId);
      socket.emit("join chat", chatId);
    }
  }, [chatToggle, chatId]);

  useEffect(() => {
    if (isEmpty(myData)) {
      if (isUser) {
        localStorage.setItem("isUser", isUser);
      }
    } else {
      setIsUser(false);
    }
  }, [isUser]);

  useEffect(() => {
    if (!isEmpty(mentorDetails)) {
      let mentorRecord = [...mentorDetails];
      let mentorData = mentorRecord.filter((item) => item.id.toString() === id);
      if (mentorData.length > 0) {
        setManageMentor(mentorData[0]);
        setSocketId(mentorData[0].socketId);
        localStorage.setItem("mentorData", JSON.stringify(mentorData[0]));
      }
    }
  }, [mentorDetails]);

  const handleToggle = async () => {
    if (myData) {
      setChatToggle(!chatToggle);
      let menuData = userChatMenu?.filter(
        (obj) => obj.username === manageMentor?.user?.username
      );
      if (isEmpty(menuData)) {
        let messages = {
          messages: [
            { message: "", username: myData?.username, messageId: uniqueMsgId },
          ],
        };

        const { data } = await axios.post(
          `${backendUrl}/message-data`,
          messages
        );

        if (isEmpty(chatMenu)) {
          let chatMenuData = {
            chat_menu: [
              {
                uid: uniqueMenuId,
                Name: myData?.firstname + " " + myData?.lastname,
                email: myData?.email,
                ChatId: uniqueChatId,
                username: myData?.username,
                lastMessage: null,
                profilePhoto: myData?.user_profile?.url,
                ClientSocketId: myData?.socketId,
                MessageDataBaseId: data?.id,
              },
            ],
          };
          await axios.put(
            `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`,
            chatMenuData
          );
          dispatch(getChatMenuData(manageMentor?.chat_infoId));
        } else {
          let chatMenuDetails = {
            uid: uniqueMenuId,
            Name: myData?.firstname + " " + myData?.lastname,
            email: myData?.email,
            ChatId: uniqueChatId,
            username: myData?.username,
            lastMessage: null,
            profilePhoto: myData?.user_profile?.url,
            ClientSocketId: myData?.socketId,
            MessageDataBaseId: data?.id,
          };
          chatMenu?.push(chatMenuDetails);
          let chatMenuData = {
            chat_menu: chatMenu,
          };
          const { data: menuData } = await axios.put(
            `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`,
            chatMenuData
          );
          setChatMenu(menuData?.chat_menu);
          dispatch(getChatMenuData(manageMentor?.chat_infoId));
        }

        if (isEmpty(userChatMenu)) {
          let chatUserMenuData = {
            chat_menu: [
              {
                uid: uniqueMenuId,
                Name: manageMentor?.first_name + " " + manageMentor?.last_name,
                email: manageMentor?.email,
                ChatId: uniqueChatId,
                username: manageMentor?.user?.username,
                lastMessage: null,
                profilePhoto: manageMentor?.profile_image?.url,
                ClientSocketId: manageMentor.socketId,
                MessageDataBaseId: data?.id,
              },
            ],
          };

          await axios.put(
            `${backendUrl}/chat-menu/${myData?.chat_infoId}`,
            chatUserMenuData
          );
          dispatch(getChatMenuData(myData?.chat_infoId));
        } else {
          let chatUserMenuData = {
            uid: uniqueMenuId,
            Name: manageMentor?.first_name + " " + manageMentor?.last_name,
            email: manageMentor?.email,
            ChatId: uniqueChatId,
            username: manageMentor?.user?.username,
            lastMessage: null,
            profilePhoto: manageMentor?.profile_image?.url,
            ClientSocketId: manageMentor.socketId,
            MessageDataBaseId: data?.id,
          };
          userChatMenu?.push(chatUserMenuData);
          let UserMenuData = {
            chat_menu: userChatMenu,
          };
          const { data: userMenu } = await axios.put(
            `${backendUrl}/chat-menu/${myData?.chat_infoId}`,
            UserMenuData
          );
          setUserChatMenu(userMenu);
          dispatch(getChatMenuData(myData?.chat_infoId));
        }
      }
    } else {
      setIsUser(true);
    }
  };

  const areaOfExpertise = useMemo(() => {
    return manageMentor?.sectors_of_interest?.map((ele) => {
      return ele?.areaOfExpertise;
    });
  }, [manageMentor]);

  return (
    <div>
      <PageTitle />
      <div className={`${userData ? "mentor-profile-login" : "mentor-profile-logout" } mentor-profile-flex`}>
        {userData ? <SideNavbar {...{ isMentor: true }} /> : ""}
        <div
          className={`mentor-profile-view-main ${
            userData ? "" : "mentor-profile-view-main-logout"
          }`}
        >
          <div className="mentor-profile container-fluid">
            <div className="mentor-view mentor-view text-center py-2">
              {!userData && (
                <Link to={mentor}>
                  {/* <img src={BackArrow} alt="backArrow" /> */}
                  <i class="fa fa-angle-left"></i>
                </Link>
              )}
              <label className="mentor-title">Profile</label>
            </div>
            <div className="mentor-wrap row">
              <div className="col-12 col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column justify-content-baseline align-items-center">
                  {!isEmpty(manageMentor?.profile_image?.url) ? (
                    <img
                      className="mentor-image img-fluid"
                      src={manageMentor?.profile_image?.url}
                      alt="mentor-img"
                    />
                  ) : (
                    <img
                      className="mentor-image img-fluid"
                      src={AvatarProfile}
                      alt="mentor-img"
                    />
                  )}
                  <div className="link-icon-main">
                    {myData ? (
                      <Link
                        className="chat-with-mentor-btn"
                        to={userChatBox}
                        onClick={() => handleToggle()}
                      >
                        Message
                      </Link>
                    ) : (
                      <a
                        className="chat-with-mentor-btn"
                        href={newLoginSignUpLink}
                      >
                        Message
                      </a>
                    )}
                    <div>
                      <div className="user-linkedin-profile d-flex">
                        <a
                          className="link-action"
                          rel="noreferrer"
                          href={`${manageMentor.linkedin_link}`}
                          target={"_blank"}
                        >
                          <img src={linkedin} alt="linkedin" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xxl-8   col-xl-12 col-lg-12 col-md-12 col-sm-12 mentor-info d-flex align-items-start justify-content-center flex-column mt-4 mt-lg-0">
                <div className="text-start container content-wrap">
                  <div className="row">
                    <div className="text-start px-2 col-12 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                      <div className="mb-4">
                        <label className="mentor-titles">Name</label>
                        <h5 className="mentor-name text-capitalize">
                          {manageMentor?.first_name} {manageMentor?.last_name}
                        </h5>
                      </div>
                      <div className="mb-4">
                        <label className="mentor-titles">
                          Areas of Expertise
                        </label>
                        <div className="areas-of-interest">
                          <p className="mentor-post mentor-profile-skill">
                            {areaOfExpertise?.join(",")}
                          </p>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="mentor-titles">Bio</label>
                        <p className="mentor-post">{manageMentor.bio}</p>
                      </div>
                    </div>
                    <div className="text-start px-2 col-12 col-xl-6 col-lg-6 col-md-4 col-sm-6">
                      <div className="mb-4">
                        <label className="mentor-titles">Job Title</label>
                        <p className="mentor-post">{manageMentor.job_title}</p>
                      </div>
                      <div className="mb-4">
                        <label className="mentor-titles">Company Name</label>
                        <p className="mentor-post">{manageMentor.company}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {chatToggle && (
            <>
              <UserChatBox
                manageMentor={manageMentor}
                userMenu={userChatMenu}
                id={id}
              />
            </>
          )}
          {userData ? <Footer webAppFooter={true} /> : ""}
        </div>
      </div>
    </div>
  );
};

export default MentorDetails;
