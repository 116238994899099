import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import linkedin from "../../../assets/imgs/desktop/icon/linkedin-svgrepo-com.svg";

/**
 * TeamTab Component
 * 
 * Overview:
 * The TeamTab component displays a list of team members for a startup. It is used in the Explore Start-up Details section of the UI. The component receives an array of team members as props and renders a card for each member.
 * 
 * Details:
 * - The component uses the useSelector hook from the Redux library to access the 'raiseCapitalTeam' state from the Redux store.
 * - The TeamCard component is a sub-component that renders the details of each team member.
 * - The team members are sorted based on their 'order' property and displayed in reverse order.
 * - The component also checks if the team member has any previous company experience and renders the logos of those companies.
 * - The component includes a link to the team member's LinkedIn profile if available.
 * */
const TeamTab = () => {
  let img2 = "/web-app-img/desktop/explore-startup-details/problem.png";
  let raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );
  const TeamCard = ({
    linkedIn,
    profile,
    name,
    details,
    desi,
    experience,
    order,
  }) => {
    return (
      <div className="col-xl-6 col-lg-12 ">
        <div className="team-list-inner">
          <div className="team-list-image">
            <img
              alt="img"
              src={profile}
              className="img-fluid team-list-profile"
            ></img>
            <div className="text-image-text">
              <h3>{name}</h3>
              <p>{desi}</p>
            </div>
          </div>
          <div className="team-list-text">
            <div className="team-list-text-top">
              <h2>Bio</h2>
              <p>{details}</p>
            </div>
            {experience.length > 0 && (
              <div className="team-list-text-end">
                <h1>Previous Company Exp.</h1>
                {experience.map((item) => (
                  <img
                    alt="img"
                    src={item?.logo[0]?.url}
                    className="img-fluid"
                  />
                ))}
                {/* <img alt = "img" src={apple} className="img-fluid" />
              <img alt = "img" src={microsoft} className="img-fluid" /> */}
              </div>
            )}
            {linkedIn && (
              <a href={linkedIn} className="linkdin_link">
                <img
                  alt="img"
                  height={20}
                  width={20}
                  src={linkedin}
                  className="img-fluid"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };

  raiseCapitalTeam = Object.assign([], raiseCapitalTeam);
  raiseCapitalTeam.reverse();
  raiseCapitalTeam = raiseCapitalTeam.sort(
    (a, b) => parseFloat(a.order) - parseFloat(b.order)
  );

  return (
    <div className="team-list">
      <h1 className="menu-head-title">
        <img alt="img" src={img2} className="img-fluid" /> Team
      </h1>
      <div className="row">
        {!isEmpty(raiseCapitalTeam) ? (
          raiseCapitalTeam.map((data, index) => (
            <TeamCard
              linkedIn={data?.linkedin_url}
              profile={data?.profile_image?.url}
              name={data?.full_name}
              details={data?.details}
              desi={data?.designation}
              order={data?.order}
              experience={data?.experiences || []}
            />

            // <div key={index} className="startup-about-teams-block-with-inner">
            //   <div className="startup-innermain">
            //     <div className="startup-details-about-team-memeber-img-block">
            //       {!isEmpty(data.profile_image) ? (
            //         <img alt = "img" src={data.profile_image.url} alt="team member" />
            //       ) : (
            //         <span className="font-24-roboto-medium">
            //           {!isEmpty(data.full_name) && data.full_name.charAt(0)}
            //         </span>
            //       )}
            //     </div>
            //     <div className="text-startup">
            //       <h5>
            //         {data.full_name}
            //         {data?.details?.length > 0 && (
            //           <span className="tooltip-hoverBlack">
            //             <i>i</i>
            //             <span className="tooltiptext">{data.details}</span>
            //           </span>
            //         )}
            //       </h5>
            //       <p>
            //         {/* {!isEmpty(data.designation) ? ( */}
            //         {data.designation}
            //         {/* ) : (
            //               <span className="opacity-0">lorem</span>
            //             )} */}
            //       </p>
            //     </div>
            //   </div>
            // </div>
          ))
        ) : (
          <p className="vl-disc-card-row__text3 font-18-roboto-medium">
            No Team found
          </p>
        )}
      </div>
    </div>
  );
};

export default TeamTab;
