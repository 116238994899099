import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RaiseCapitalProfile from "./RaiseCapitalProfile";
import RaiseCapitalAfterProfileSetup from "./RaiseCapitalAfterProfileSetup";
import SideNavbar from "../header/SideNavbar";
import { activeSidebarMenu, raiseCapitalPageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import {
  exploreWebAppLink,
  raiseCapitalWebAppLink,
  startUpDetailsWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";

// api
import { useEffect } from "react";
import {
  raiseCapitalSaveProfileSuccessAction,
  getRaiseCapitalTags,
  getRaiseCapitalOfferings,
  getRaiseCapitalOrganizations,
  getRaiseCapitalTeam,
} from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import {
  validateRaiseCapitalOrgDataNotEmpty,
  validateRaiseCapitalOrgDataFundraiserNotEmpty,
  validateRaiseCapitalOrgDataTeamNotEmpty,
} from "../../../web-app-store/validations/raise-capital/raiseCapitalOrganizationData";
import DisplayLoader from "../common/DisplayLoader";
import Footer from "../../../components/desktop/header-footer/Footer";
import ProfileReviewModalRaiseCapital from "../common/ProfileReviewModalRaiseCapital";

/**
 * MainRaiseCapitalRouting Component
 *
 * Overview:
 * The MainRaiseCapitalRouting component is responsible for rendering the main page of the Raise Capital feature in the application. It handles the API calls, state management, and conditional rendering based on the data received from the API.
 *
 * Details:
 * - The component uses Redux hooks (useDispatch, useSelector) to manage the state and dispatch actions to fetch data from the API.
 * - It uses React Router's useHistory hook to handle navigation.
 * - The component includes several useEffect hooks to perform side effects such as scrolling to the top of the page and fetching data based on certain conditions.
 * - It renders different components based on the state of isLoadingRaiseCapitalOrg, raiseCapitalSaveProfileSuccess, and raiseCapitalOrgAndTagsErrors.
 *
 * Dependencies:
 * - React (useState, useEffect)
 * - React Redux (useDispatch, useSelector)
 * - React Router (useHistory)
 * - Redux actions (getRaiseCapitalOrganizations, raiseCapitalSaveProfileSuccessAction, getRaiseCapitalOfferings, getRaiseCapitalTags)
 * - Validation functions (validateRaiseCapitalOrgDataNotEmpty, validateRaiseCapitalOrgDataFundraiserNotEmpty)
 * - External components (SideNavbar, PageTitle, DisplayLoader, ProfileReviewModalRaiseCapital, RaiseCapitalProfile, RaiseCapitalAfterProfileSetup, Footer)
 */
export default function MainRaiseCapitalRouting() {
  // api
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getRaiseCapitalOrganizations());
    dispatch(raiseCapitalSaveProfileSuccessAction(false));
  }, [dispatch]);

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const isLoadingRaiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.isLoadingRaiseCapitalOrg
  );

  const raiseCapitalSaveProfileSuccess = useSelector(
    (state) => state.raiseCapital.raiseCapitalSaveProfileSuccess
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [raiseCapitalSaveProfileSuccess]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0].id));
      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
      dispatch(getRaiseCapitalTeam(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const raiseCapitalTags = useSelector(
    (state) => state.raiseCapital.raiseCapitalTags
  );

  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );

  const [raiseCapitalOrgAndTagsErrors, setRaiseCapitalOrgAndTagsErrors] =
    useState("error");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!isEmpty(raiseCapitalOrg)) {
      if (!isEmpty(raiseCapitalTags)) {
        const { isValid } = validateRaiseCapitalOrgDataNotEmpty(
          raiseCapitalOrg[0]
        );
        if (isValid && !isEmpty(raiseCapitalTags[0].market_tags)) {
          if (!isEmpty(raiseCapitalOfferings)) {
            const { isValid } = validateRaiseCapitalOrgDataFundraiserNotEmpty(
              raiseCapitalOfferings[0]
            );
            if (isValid) {
              if (!isEmpty(raiseCapitalTeam)) {
                const { isValid } =
                  validateRaiseCapitalOrgDataTeamNotEmpty(raiseCapitalTeam);
                if (isValid) {
                  setRaiseCapitalOrgAndTagsErrors(null);
                }
              }
            }
          }
        }
      }
    }
  }, [
    raiseCapitalOrg,
    raiseCapitalTags,
    raiseCapitalOfferings,
    raiseCapitalTeam,
  ]);

  useEffect(() => {
    const interestedInStartUpId = localStorage.getItem("interestedInStartUpId");
    if (interestedInStartUpId != null) {
      localStorage.removeItem("interestedInStartUpId");
      history.push(`${startUpDetailsWebAppLink}/${interestedInStartUpId}`);
    }
  }, [history]);
  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[3]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={raiseCapitalPageTitle.icon}
            pageTitle={raiseCapitalPageTitle.title}
          />
          {isLoadingRaiseCapitalOrg ? (
            <div className="vl-raise-capital-main-page">
              <div className="text-center">
                <DisplayLoader />
              </div>
            </div>
          ) : raiseCapitalSaveProfileSuccess ? (
            <ProfileReviewModalRaiseCapital
              linkPath={exploreWebAppLink}
              raiseCapitalVal={true}
            />
          ) : !isEmpty(raiseCapitalOrgAndTagsErrors) ? (
            <RaiseCapitalProfile />
          ) : (
            <RaiseCapitalAfterProfileSetup />
          )}
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
