import React from "react";
import DisplayLoader from "./../common/DisplayLoader";

/**
 * BackButton Component
 * 
 * Overview:
 * The BackButton component is a reusable button component that represents a back navigation action. It is used to navigate back to the previous page or step in the application.
 * 
 * Details:
 * - The BackButton component accepts the following props:
 *   - extraClassName: A string representing additional CSS classes to be applied to the button.
 *   - handleOnClickBack: A function that is called when the button is clicked. It should handle the back navigation logic.
 *   - isDisabled: A boolean value indicating whether the button is disabled or not.
 *   - loading: A boolean value indicating whether the button is in a loading state or not.
 * - The button's appearance and behavior depend on the values of the props. If the loading prop is true, a loading spinner is displayed inside the button. Otherwise, an arrow icon and the text "Back" are displayed.
 * 
 * Dependencies:
 * The BackButton component does not have any external dependencies.
 */
export default function BackButton({
  extraClassName,
  handleOnClickBack,
  isDisabled,
  loading,
}) {
  return (
    <>
      <button
        className={`vl-common-save-next-btn mr-21 ${extraClassName}`}
        onClick={handleOnClickBack}
        disabled={isDisabled}
      >
        {" "}
        {loading === true ? (
          <DisplayLoader loaderColor="#ffffff" />
        ) : (
          <>
            {" "}
            <img
              src="/web-app-img/desktop/icons/back-arrow.png"
              alt="next arrow"
              className="vl-common-save-next-btn__arrow-img"
            />{" "}
            <span>Back</span>
          </>
        )}
      </button>
    </>
  );
}

BackButton.defaultProps = {
  extraClassName: "",
  loading: false,
};
