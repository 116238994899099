// import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateInvestorProceedToPayment Function
 * 
 * Overview:
 * This function is responsible for validating the data provided for the investor's proceed to payment process. It checks for various conditions and returns any errors encountered during the validation process.
 * 
 * Details:
 * - The function first initializes an empty object to store any errors encountered during the validation process.
 * - It retrieves the user data from the local storage.
 * - The function then performs several checks on the provided data and adds corresponding errors to the errors object if any conditions are not met.
 * - The function checks if the company name is empty and adds an error if it is.
 * - It also checks if the investment amount exceeds the remaining investment limit and adds an error if it does.
 * - The function checks if the investment amount is empty or less than the minimum investment amount and adds an error if it is.
 * - It checks if the signature box is not empty and if the signature does not match the user's name, and adds an error if either condition is not met.
 * - The function adds an error if the signature box is empty.
 * - It checks if the return of investment checkbox is not checked and adds an error if it is not.
 * - It checks if the resell CS checkbox is not checked and adds an error if it is not.
 * - It checks if the crowdfunding involves risk checkbox is not checked and adds an error if it is not.
 * - Finally, the function returns an object containing the errors and a boolean flag indicating if the data is valid (i.e., no errors encountered).
 * 
 * @param {Object} data - The data to be validated for the investor's proceed to payment process.
 * @returns {Object} - An object containing the errors encountered during the validation process and a flag indicating if the data is valid.
 */
export const validateInvestorProceedToPayment = (data) => {
  let errors = {};

  let userData = JSON.parse(localStorage.getItem("userData"));

  // console.log(data);

  if (isEmpty(data.companyName)) {
    errors.companyName = "Company name is required";
  }
  // console.log("----- data -----", data);
  // console.log("error doc ", data.investmentAmount, "docu", data.remainingInvestmentLimit);
  if (data.remainingInvestmentLimit === 0) {
  } else if (data.investmentAmount > data.remainingInvestmentLimit) {
    errors.investmentAmount =
      "This amount exceeds your investment limit. See below.";
  }

  if (
    isEmpty(data.investmentAmount) ||
    data.investmentAmount < data.min_investment
  ) {
    errors.investmentAmount =
      "Investment amount is required and must meet the minimum amount.";
  }

  if (!isEmpty(data.signatureBox)) {
    let finalSig = data.signatureBox.toLowerCase();
    if (
      !finalSig.includes(userData.firstname.toLowerCase()) ||
      !finalSig.includes(userData.lastname.toLowerCase())
    ) {
      errors.signatureBox = "The signature must match the name of the account";
    }
  }

  if (isEmpty(data.signatureBox)) {
    errors.signatureBox = "Signature box is required";
  }

  if (!data.returnOfInvestmentCheckbox) {
    errors.returnOfInvestmentCheckbox = "Required";
  }

  if (!data.resellCSCheckbox) {
    errors.resellCSCheckbox = "Required";
  }

  if (!data.crowdfundingInvolvesRiskCheckbox) {
    errors.crowdfundingInvolvesRiskCheckbox = "Required";
  }
  return {
    errors,
    isValid: isEmpty(errors),
  };
};
