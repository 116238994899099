import React from "react";
import Select from "react-select";

/**
 * DropdownSelectCommon Component
 *
 * Overview:
 * The DropdownSelectCommon component is a reusable component that renders a dropdown select input with a label, options, and error message. It is used to provide a user-friendly way to select a value from a list of options.
 *
 * Details:
 * - The component accepts props such as label, value, onChange, options, placeholder, isSearchable, isHideLabel, error, and defaultValue.
 * - The label prop is used to display a label for the dropdown select input.
 * - The value prop represents the currently selected value of the dropdown select input.
 * - The onChange prop is a callback function that is called when the selected value changes.
 * - The options prop is an array of objects representing the available options for the dropdown select input.
 * - The placeholder prop is used to display a placeholder text when no value is selected.
 * - The isSearchable prop determines whether the dropdown select input is searchable or not.
 * - The isHideLabel prop determines whether the label should be hidden or not.
 * - The error prop is used to display an error message below the dropdown select input.
 * - The defaultValue prop represents the default value of the dropdown select input.
 *
 * Dependencies:
 * - This component depends on the Select component from an external library.
 * - The Select component is used to render the actual dropdown select input.
 * - Make sure to import the Select component before using the DropdownSelectCommon component.
 */
function DropdownSelectCommon({
  label,
  value,
  onChange,
  options,
  placeholder,
  isSearchable,
  isHideLabel,
  error,
  defaultValue,
  isDisabled,
  extraClass,
}) {
  return (
    <div
      className={`vl-form-input vl-form-input--bordered-select-div vl-dropdown-common ${
        extraClass ? extraClass : ""
      }`}
    >
      {!isHideLabel && <label htmlFor="">{label}</label>}
      <Select
        value={value}
        onChange={onChange}
        options={options}
        className="vl-select-container"
        classNamePrefix="vl-select"
        placeholder={placeholder}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        isDisabled={isDisabled || false}
      />
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className="error-message opacity-0">error</p>
      )}
    </div>
  );
}

DropdownSelectCommon.defaultProps = {
  label: "",
  placeholder: "",
  isSearchable: false,
  isHideLabel: false,
  error: "",
};

export default DropdownSelectCommon;
