import { SET_ALL_MENTOR_Record, SET_MENTOR_BY_USER_ID } from "../types";

const initialState = {
  mentor: {},
  allMentors: {},
};

/**
 * mentorReducer
 * 
 * Overview:
 * The mentorReducer is a function that handles the state management for the mentor-related data in the application. It is responsible for updating the state based on the actions dispatched to it.
 * 
 * Details:
 * - The mentorReducer function takes in the current state and an action as parameters and returns the updated state.
 * - It uses a switch statement to handle different action types and update the state accordingly.
 * - When the action type is SET_MENTOR_BY_USER_ID, it updates the mentor property in the state with the payload value from the action.
 * - When the action type is SET_ALL_MENTOR_Record, it updates the allMentors property in the state with the payload value from the action.
 * - If the action type does not match any of the cases, it returns the current state unchanged.
 * 
 * Usage:
 * This reducer function is typically used in conjunction with the Redux store and combined with other reducers to manage the overall state of the application. It can be imported and used in any component that needs to access or update the mentor-related data.
 * 
 * Dependencies:
 * - The mentorReducer function does not have any external dependencies.
 */
export default function mentorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MENTOR_BY_USER_ID:
      return {
        ...state,
        mentor: action.payload,
      };

    case SET_ALL_MENTOR_Record:
      return {
        allMentors: action.payload,
      };

    default:
      return state;
  }
}
