import React, { useEffect } from "react";
import Footer from "../header-footer/Footer";
import AboutUsFoldOne from "./AboutUsFoldOne";
import AboutUsFoldThree from "./AboutUsFoldThree";
import AboutUsFoldTwo from "./AboutUsFoldTwo";

/**
 * AboutUs Component
 * 
 * Overview:
 * The AboutUs component is responsible for rendering the About Us page. It includes three sub-components: AboutUsFoldOne, AboutUsFoldTwo, and AboutUsFoldThree. The component is wrapped in a div with the class "bg-color" and also includes a Footer component at the bottom.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component does not accept any props.
 * 
 * Usage:
 * To use the AboutUs component, simply import it and place it in the desired location within your application. Make sure to import the required sub-components as well.
 * 
 * Dependencies:
 * - React: The AboutUs component is built using React.
 * - AboutUsFoldOne: A sub-component used to render the first fold of the About Us page.
 * - AboutUsFoldTwo: A sub-component used to render the second fold of the About Us page.
 * - AboutUsFoldThree: A sub-component used to render the third fold of the About Us page.
 * - Footer: A component used to render the footer section of the page.
 */
export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-color">
        <AboutUsFoldOne />
        <AboutUsFoldTwo />
        <AboutUsFoldThree />
      </div>
      <Footer />
    </>
  );
}
