import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const logo = "/web-app-img/desktop/all-logo/logo.png";

/**
 * StayTuned Component
 * 
 * Overview:
 * The StayTuned component is responsible for rendering a "Stay Tuned" message and providing navigation buttons based on the user's authentication status. It is used in the VedasLabs Community application to inform users about upcoming features and allow them to navigate to different sections of the app.
 * 
 * Details:
 * - The component uses Redux's useSelector hook to access the authentication state from the store.
 * - It uses React Router's useHistory hook to handle navigation.
 * - The component renders a background image, a VedasLabs logo, a welcome message, and a "Stay Tuned" message.
 * - It conditionally renders different sets of buttons based on the user's authentication status.
 * - The buttons trigger navigation to different sections of the app or perform logout actions.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the UI elements.
 * - React Redux: The component uses the useSelector hook from React Redux to access the authentication state.
 * - React Router: The component uses the useHistory hook from React Router to handle navigation.
 */
export default function StayTuned() {
  const allUsers = useSelector((state) => state.auth);
  const history = useHistory();

  const logoutHandler = () => {
    localStorage.clear();
    // window.location.href = "https://vedaslabs.io/";
    history.push("/");
  };
  const homepageHandler = () => {
    // window.location.href = "https://vedaslabs.io/";
    history.push("/home");
  };

  const homepageMentorHandler = () => {
    history.push("/mentorship");
  };

  const profileHandler = () => {
    history.push("/profile");
  };

  const connectMentorHandler = () => {
    history.push("/mentorship");
  };

  return (
    <div className="signup-bg">
      <div className="stay-tuned-div">
        <div className="vl-logo-div">
          <img
            src={logo}
            className="stay-tuned-vedaslabs-logo"
            alt="vedaslabs logo"
          />
        </div>
        <h3 className="st-font-36-bold-roboto">
          Welcome to the VedasLabs Community!
        </h3>
        {/* <h5 className="st-font-24-bold-roboto">
          Exciting things are coming soon!
        </h5> */}
        <div className="row mx-0 justify-content-center button-wrap">
          {allUsers.createMentor ? (
            <>
              {/* <button
                onClick={homepageMentorHandler}
                className="signup-submit-btn signup-submit-btn-st"
              >
                Go to home
              </button> */}
              <button
                onClick={profileHandler}
                className="signup-submit-btn signup-submit-btn-stay-white-btn"
              >
                Complete Profile
              </button>
              <button
                onClick={logoutHandler}
                className="signup-submit-btn signup-submit-btn-st"
              >
                Sign out
              </button>
            </>
          ) : allUsers.isUser ? (
            <>
              <button
                onClick={connectMentorHandler}
                className="signup-submit-btn signup-submit-btn-stay-white-btn"
              >
                Explore to Mentor
              </button>
              <button
                onClick={logoutHandler}
                className="signup-submit-btn signup-submit-btn-st"
              >
                Signout
              </button>
            </>
          ) : (
            <>
              <button
                onClick={homepageHandler}
                className="signup-submit-btn signup-submit-btn-stay-white-btn"
              >
                Home
              </button>
              <button
                onClick={logoutHandler}
                className="signup-submit-btn signup-submit-btn-st"
              >
                Signout
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
