/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserData,
  getAllUserData,
  updateUserData,
  updateUserInfoAction,
} from "./../../../web-app-store/actions/authAction";
import axios from "axios";
import Select from "react-select";
import Toast from "light-toast";
import { useHistory, useLocation } from "react-router-dom";
import { validateMoreDetails } from "./../../../web-app-store/validations/auth/MoreDetailsValidation";
import isEmpty from "../../../web-app-store/validations/is-empty";
import InputFieldPhoneCountryNumber from "../common/InputFieldPhoneCountryNumber";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import {
  interestOptions,
  mentorOptions,
  userOptions,
} from "../common/allDropdownOptions";
import {
  backendUrl,
  landingpageBackendUrl,
} from "../../../web-app-store/actions/config";
import { socket } from "../../../config/web-sockets";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import { industries } from "../../../components/desktop/mentor/dropdownList/industry";
import {
  createMentor,
  getMentorByUserId,
  updateMentor,
} from "../../../web-app-store/actions/mentorAction";
import DropdownMultiSelectCommon from "../common/DropdownMultiSelectCommon";
import DatepickerSingle from "../common/DatepickerSingle";
import format from "date-fns/format";

const logo = "/web-app-img/desktop/all-logo/logo.png";

/**
 * MoreDetails Component
 *
 * Overview:
 * The MoreDetails component is responsible for rendering a form that allows users to provide additional details about themselves. It is used in the login/signup flow of the application.
 *
 * Details:
 * - The component uses React hooks to manage state, including socketId, chatInfoId, userData, mentor, allUser, values, frontendErrors, allUserData, userId, filterData, and userDetails.
 * - The component makes use of Redux for state management, with the useDispatch and useSelector hooks.
 * - The component includes several useEffect hooks to handle side effects, such as fetching data from the backend and updating state based on changes in other components.
 * - The component includes event handlers for form input changes and form submission.
 * - The component renders several sub-components, such as InputFieldEmailTextPassword and InputFieldPhoneCountryNumber, to handle specific form inputs.
 *
 * Usage:
 * The MoreDetails component can be used within the login/signup flow of the application to collect additional details from users. It should be rendered within a parent component that handles the overall flow and navigation.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - React Router: The component may require React Router for navigation, depending on the parent component's implementation.
 * - Axios: The component uses Axios for making HTTP requests to the backend.
 * - Other custom components: The component uses several custom sub-components for rendering form inputs.
 */
export default function MoreDetails() {
  const [socketId, setSocketId] = useState("");
  const [chatInfoId, setChatInfoId] = useState("");
  let userData = JSON.parse(localStorage.getItem("userData"));
  const mentor = useSelector((state) => state.mentor.mentor);
  const dispatch = useDispatch();
  const allUser = useSelector((state) => state.auth);
  const history = useHistory();
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    pNumber: "1",
    location: "",
    selectedInterest: "",
    sectors_of_interest: "",
    DOB: "",
  });

  const [frontendErrors, setFrontendErrors] = useState({});
  const [allUserData, setAllUserData] = useState([]);
  const [country, setCountry] = useState();
  const [userId, setUserId] = useState("");
  const [filterData, setFilterData] = useState();
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    dispatch(getMentorByUserId());
  }, [dispatch]);

  useEffect(() => {
    if (allUser.createMentor) {
      let sectorsOfInterest =
        !isEmpty(mentor[0]?.sectors_of_interest) &&
        mentor[0]?.sectors_of_interest.split(",");
      let finalSectorsOfIntrest = [];
      if (!isEmpty(mentor[0]?.sectors_of_interest)) {
        sectorsOfInterest?.forEach((ele) => {
          finalSectorsOfIntrest.push({
            value: ele,
            label: ele,
          });
        });
      }
      setValues({
        ...values,
        fname: userData.firstname,
        lname: userData.lastname,
        pNumber: isEmpty(userData.phoneNumber) ? "1" : userData.phoneNumber,
        location: userData.Address,
        DOB: userData?.DOB === null ? null : new Date(userData?.DOB),
        selectedInterest: !isEmpty(userData.interest)
          ? interestOptions.filter((a) => a.value === userData.interest)
          : { value: "Mentoring", label: "Mentoring startups" },
        sectors_of_interest: !isEmpty(mentor) ? finalSectorsOfIntrest : "",
      });
    } else if (allUser.isUser) {
      setValues({
        ...values,
        fname: userData.firstname,
        lname: userData.lastname,
        pNumber: isEmpty(userData.phoneNumber) ? "1" : userData.phoneNumber,
        location: userData.Address,
        DOB: userData?.DOB === null ? null : new Date(userData?.DOB),
        selectedInterest: !isEmpty(userData.interest)
          ? interestOptions.filter((a) => a.value === userData.interest)
          : { value: "Others", label: "Others" },
      });
    } else {
      setValues({
        ...values,
        fname: userData.firstname || "",
        lname: userData.lastname || "",
        pNumber: isEmpty(userData.phoneNumber) ? "1" : userData.phoneNumber,
        location: userData.Address || "",
        DOB: userData?.DOB === null ? "" : new Date(userData?.DOB),
        selectedInterest: !isEmpty(userData.interest)
          ? interestOptions.filter((a) => a.value === userData.interest)
          : "",
      });
    }
  }, [mentor]);

  useEffect(() => {
    setAllUserData(allUser.allUser);
  }, [allUser]);

  useEffect(async () => {
    let { data } = await axios.get(`${backendUrl}/download-user`);
    if (data) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let userDetail = data.filter(
        (item) => item.username === userData.username
      );
      setUserDetails(userDetail[0]);
      setAllUserData(data);
    }
  }, []);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (!isEmpty(allUserData)) {
      let userDetailId = allUserData.findIndex(
        (item) => item.username === userData.username
      );
      let userDetail = allUserData.filter(
        (item) => item.username === userData.username
      );
      if (!isEmpty(userDetailId) && !isEmpty(userDetail)) {
        setUserId(userDetailId);
        setFilterData(userDetail);
      }
    }
  }, [allUserData]);

  const handleChange = (event) => {
    setFrontendErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneInput = (val, index, country) => {
    setFrontendErrors({});
    setCountry(country);
    setValues({
      ...values,
      pNumber: val,
    });
  };

  const handleChangeInterest = (selectedInterest) => {
    setFrontendErrors({});
    setValues({
      ...values,
      selectedInterest,
    });
  };

  useEffect(() => {
    socket.on("connect", () => {
      setSocketId(socket.id);
    });
  }, [socket]);

  useEffect(async () => {
    let { data } = await axios.get(`${backendUrl}/chat-info`);
    setChatInfoId(data[0]?.id);
  }, []);

  const handleIndustryChange = (industry) => {
    setFrontendErrors({});
    setValues({
      ...values,
      sectors_of_interest: industry,
    });
  };

  const callBack = (status) => {
    if (status === 200) {
      console.log(status);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valuesNew = {
      ...values,
      // selectedBirthDate: selectedBirthDate,
    };
    const { errors, isValid } = validateMoreDetails(
      valuesNew,
      allUser.createMentor,
      country
    );
    if (!isValid) {
      setFrontendErrors(errors);
      // Toast.fail("*All fields are required", 3000);
    } else if (isValid) {
      const formData = {
        DOB: format(values?.DOB, "YYYY-MM-DD"),
        firstname: values.fname,
        lastname: values.lname,
        phoneNumber: values.pNumber,
        Address: values.location,
        interest: values.selectedInterest.value,
        socketId: !isEmpty(userData.socketId) ? userData.socketId : socketId,
      };
      dispatch(updateUserInfoAction(userData.id, userData, formData, history));

      // const newData = {
      //   DOB: format(values?.DOB, "YYYY-MM-DD"),
      //   firstname: values.fname,
      //   lastname: values.lname,
      //   phoneNumber: values.pNumber,
      //   Address: values.location,
      //   interest: values.selectedInterest.value,
      //   DOB: userData.DOB,
      //   email: userData.email,
      //   username: userData?.username,
      // };

      // if (
      //   userDetails?.email === allUserData[userId]?.email &&
      //   !isEmpty(userDetails) &&
      //   !isEmpty(allUserData)
      // ) {
      //   dispatch(updateUserData(userDetails?.id, newData));
      // } else {
      //   dispatch(createUserData(newData));
      // }

      try {
        const res = await axios.post(
          `${landingpageBackendUrl}/api/v1/invite_email`,
          { first_name: values.fname, email: userData.email }
        );
        console.log("RES>>", res);
      } catch (e) {
        console.log(e);
      }
    }
  };

  function validateAge(birthDate) {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return birthDate < eighteenYearsAgo;
  }

  const renderFNameLName = () => {
    return (
      <div className="row mx-0 justify-content-between">
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input vl-form-input--getStarted first-line p-0"
          name="fname"
          value={values.fname}
          onChange={handleChange}
          type="text"
          placeholder="First Name"
          error={frontendErrors.fname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input vl-form-input--getStarted first-line p-0"
          name="lname"
          value={values.lname}
          onChange={handleChange}
          type="text"
          placeholder="Last Name"
          error={frontendErrors.lname}
        />
      </div>
    );
  };

  const renderDateOfBirth = () => {
    const handleChangeStart = (date) => {
      setFrontendErrors({});
      if (date === null) {
        setValues({
          ...values,
          DOB: null,
        });
      } else {
        setValues({
          ...values,
          DOB: new Date(date[0]),
        });
        let errors = { ...frontendErrors };
        if (validateAge(new Date(date[0]))) {
          errors = {};
        } else {
          errors = { DOB: "User should be 18+ years" };
        }
        setFrontendErrors(errors);
      }
    };
    return (
      <DatepickerSingle
        startDateValue={values.DOB}
        handleChangeStart={handleChangeStart}
        error={frontendErrors.DOB}
        className="funding-inputs "
        extraClass="funding-inputs sign_up_date_main"
        placeholderText="Date of Birth : mm/dd/yyyy"
        isHideLabel={true}
      />
    );
  };

  const renderPhone = () => {
    return (
      <>
        {/* <div className="signup-input-div">
            <input
              type="text"
              placeholder="Phone Number"
              value={values.pNumber}
              name="pNumber"
              onChange={handleChange}
              required
              pattern="[0-9]*"
              maxLength="10"
            />
          </div>
          {!isEmpty(frontendErrors.pNumber) ? (
            <p className="error-message error-message--get-started">
              {frontendErrors.pNumber}
            </p>
          ) : (
            <p className="error-message error-message--get-started opacity-0">error</p>
          )} */}

        <div className="get-started-phone-input-div">
          <InputFieldPhoneCountryNumber
            containerClassName="vl-form-input"
            name="phone"
            placeholder="Phone Number"
            phoneInput={values.pNumber}
            handleChangePhoneInput={handleChangePhoneInput}
            errorPhone={frontendErrors.pNumber}
          />
        </div>
      </>
    );
  };

  const renderLocation = () => {
    return (
      <>
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input vl-form-input--getStarted vl-form-input--getStarted-location"
          name="location"
          value={values.location}
          onChange={handleChange}
          type="text"
          placeholder="Location"
          error={frontendErrors.location}
        />
      </>
    );
  };

  const renderDropdown = () => {
    return (
      <>
        <div className="get-started-dropdown-div">
          <div className="vl-form-input vl-form-input--bordered-select-div">
            {allUser.createMentor ? (
              <>
                {/* <DropdownSelectCommon
                  label="Sectors Of Interest"
                  value={values.sectors_of_interest}
                  onChange={handleIndustryChange}
                  options={industries}
                  placeholder={"Select Industry"}
                  isSearchable={true}
                  error={frontendErrors.sectors_of_interest}
                /> */}
                <div className="vl-form-input  vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect">
                  <label htmlFor="">Area of interest</label>
                  <Select
                    value={values.sectors_of_interest}
                    onChange={handleIndustryChange}
                    options={industries}
                    className={`${
                      values?.sectors_of_interest?.length > 1
                        ? "vl-select-container select-industry-dropdown"
                        : "vl-select-container"
                    }`}
                    classNamePrefix="vl-select"
                    placeholder={"Select Industry"}
                    isSearchable={true}
                    error={frontendErrors.sectors_of_interest}
                    isMulti
                  />
                </div>
                <div className="mentor-dropdown">
                  <Select
                    value={values.selectedInterest}
                    onChange={handleChangeInterest}
                    options={mentorOptions}
                    className="vl-select-container"
                    classNamePrefix="vl-select"
                    placeholder={"I'm interested in"}
                    isSearchable={false}
                    defaultValue={{
                      value: "Mentoring",
                      label: "Mentoring startups",
                    }}
                  />
                </div>
              </>
            ) : allUser.isUser ? (
              <div className="other-drop-down">
                <Select
                  value={values.selectedInterest}
                  onChange={handleChangeInterest}
                  options={userOptions}
                  className="vl-select-container"
                  classNamePrefix="vl-select"
                  placeholder={"I'm interested in"}
                  isSearchable={false}
                  defaultValue={{ value: "Others", label: "Others" }}
                />
              </div>
            ) : (
              <div className="investor-dropdown">
                <Select
                  value={values.selectedInterest}
                  onChange={handleChangeInterest}
                  options={interestOptions}
                  className="vl-select-container"
                  classNamePrefix="vl-select"
                  placeholder={"I'm interested in"}
                  isSearchable={false}
                />
              </div>
            )}
            {/* <Select
              value={values.selectedInterest}
              onChange={handleChangeInterest}
              options={allUser.createMentor ? mentorOptions : interestOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={"I'm interested in"}
              isSearchable={false}
            /> */}
          </div>
          {!isEmpty(frontendErrors.selectedInterest) ? (
            <p className="error-message error-message--get-started">
              {frontendErrors.selectedInterest}
            </p>
          ) : (
            <p className="error-message error-message--get-started opacity-0">
              error
            </p>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="signup-bg signup-bg--more-details">
      <div className="details-card">
        <div className="get-started-logo-div">
          <img src={logo} alt="vedaslabs logo" />
        </div>
        <h4>Welcome!</h4>
        <h5>Please fill your basic details below to get started</h5>
        <form className="form-div getstart-form">
          {renderFNameLName()}
          {renderDateOfBirth()}
          {renderPhone()}
          {renderLocation()}

          {renderDropdown()}

          <div className="row mx-0 justify-content-center get-started-save-button-div">
            <button className="signup-submit-btn" onClick={handleSubmit}>
              Save &amp; Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
