import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * Newsletter Validation
 * 
 * Overview:
 * This module provides a function to validate newsletter data. It checks if the email, first name, and last name fields are present and valid.
 * 
 * Details:
 * - The validateNewsLetter function takes a data object as input and returns an object with errors and a boolean flag indicating if the data is valid.
 * - It uses the Validator library to perform the validation checks.
 * - If the email field is empty, an error message "Email is required" is added to the errors object.
 * - If the email field is not a valid email format, an error message "Please enter valid email" is added to the errors object.
 * - If the fname field is empty, an error message "First name is required" is added to the errors object.
 * - If the lname field is empty, an error message "Last name is required" is added to the errors object.
 * 
 * Dependencies:
 * - Validator library: This module uses the Validator library to perform email validation.
 *   The Validator library should be imported and available in the project for this module to work correctly.
 */
export const validateNewsLetter = (data) => {
  let errors = {};

  if (Validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = "Please enter valid email";
  }

  if (Validator.isEmpty(data.fname)) {
    errors.fname = "First name is required";
  }

  if (Validator.isEmpty(data.lname)) {
    errors.lname = "Last name is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
