import React from "react";
// import { Link } from "react-router-dom";
import Navbar from "../header-footer/Navbar";

import { newLoginSignUpLink } from "../header-footer/pageLinks";

/**
 * RaiseCapitalFoldOne Component
 * 
 * Overview:
 * The RaiseCapitalFoldOne component is a part of the Raise Capital feature in the application. It represents the first fold of the Raise Capital page and displays a platform where community, innovation, and knowledge converge. It also provides a button to apply for raising capital.
 * 
 * Details:
 * - The component renders a div with the class "raise-capital-fold-one-bg" as the background container.
 * - It includes a Navbar component for navigation.
 * - Inside the main container div with the class "maincontainer-custom", there is a div with the class "raise-capital-fold-one-text-div" that contains the text content.
 * - The text content includes a heading with the class "font-50-montserrat-bold" and a subheading with the class "font-30-montserrat-regular".
 * - The component also includes a button with the class "start-btn start-btn-raise-fold-one" that redirects to the newLoginSignUpLink when clicked.
 * 
 * Usage:
 * To use the RaiseCapitalFoldOne component, import it from the specified file path and include it in the desired location within the application's JSX code.
 * 
 * Dependencies:
 * - The component depends on the Navbar component for navigation.
 * - It also relies on the newLoginSignUpLink variable for the button link.
 */
export default function RaiseCapitalFoldOne() {
  return (
    <div className="raise-capital-fold-one-bg">
      <Navbar />
      <div className="maincontainer-custom">
        <div className="raise-capital-fold-one-text-div">
          <h2 className="font-50-montserrat-bold">
            A platform where community,
            innovation and knowledge converge.
          </h2>
          <h5 className="font-30-montserrat-regular pt-6">
            Searching for U.S. based startups that seek to change norms
          </h5>
          {/* <Link to="/sign-up"> */}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            <div className="start-btn start-btn-raise-fold-one">
              <span className="font-20-montserrat-bold">
                APPLY TO RAISE CAPITAL &#62;{" "}
              </span>
            </div>
          </a>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
}
