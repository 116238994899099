import axios from "axios";
import { backendUrl, backendCognitoUrl, cognitoLogoutUrl } from "./config";
import {
  SET_LOGIN,
  SET_LOGOUT,
  SET_ERRORS,
  SET_ALL_USER_DATA,
} from "./../types";
import setAuthToken from "./../utils/setAuthToken";
import Toast from "light-toast";
import isEmpty from "../validations/is-empty";
import { raiseCapitalWebAppLink } from "../../components/desktop/header-footer/pageLinks";

/*================================================================
                         Sign Up Action
=================================================================*/

/**
 * Signup Action
 *
 * Overview:
 * The Signup Action is responsible for handling the user registration process. It makes an asynchronous call to the backend API to register a new user with the provided form data. On successful registration, it displays a success message and redirects the user to the email verification page.
 *
 * Details:
 * - It makes an HTTP POST request to the backend API endpoint for user registration.
 * - Utilizes the `axios` library for making the HTTP request.
 * - Dispatches an error action with the error message if the registration fails.
 *
 * Usage:
 * This action is dispatched when a user fills out the signup form and submits it.
 * Example: `dispatch(signupAction(formData, history))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `Toast` from `light-toast` for displaying success or error messages.
 * - Dispatch function from Redux for managing application state.
 * - History object from React Router for navigation.
 */

export const signupAction = (formData, history) => async (dispatch) => {
  try {
    let { data } = await axios.post(
      `${backendUrl}/auth/local/register`,
      formData
    );
    if (data) {
      Toast.info("Signup Successfully", 3000);
      history.push("/email-verify");
    }
  } catch (err) {
    if (
      err.response.data.message[0].messages[0].message &&
      err.response.data.statusCode === 400
    ) {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.message[0].messages[0].message,
      });
    }
  }
};

/*================================================================
              Get Google Profile Photo Action
=================================================================*/
/**
 * Get Google Profile Photo Action
 *
 * Overview:
 * Fetches the profile photo of a user from their Google account using a Google OAuth token. On success, executes a callback function with the photo URL.
 *
 * Details:
 * - Makes an HTTP GET request to the Google UserInfo API.
 * - Requires a valid Google OAuth token passed as a Bearer token in the request headers.
 * - On successful retrieval, executes a callback function with the photo URL.
 *
 * Usage:
 * Dispatch this action when you need to obtain a user's Google profile photo after successful Google OAuth authentication.
 * Example: `dispatch(getGoogleProfilePhotoAction(token, callBack))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - Redux's dispatch function for state management.
 */
export const getGoogleProfilePhotoAction =
  (token, callBack) => async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let { data } = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json`,
        { headers: headers }
      );
      if (data) {
        // console.log(data);
        callBack(data.picture);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
              Get Facebook , cognito, google Profile Photo Action
=================================================================*/
/**
 * Get Token From Cognito Action
 *
 * Overview:
 * Retrieves tokens for different platforms (e.g., Google, Facebook) stored in AWS Cognito and triggers actions to fetch profile photos from these platforms.
 *
 * Details:
 * - Makes an HTTP GET request to a Cognito endpoint to retrieve platform-specific tokens.
 * - Upon success, dispatches other actions like `getGoogleProfilePhotoAction` and `getfacebookUidAction` with the retrieved tokens to fetch profile photos.
 *
 * Usage:
 * Use this action to fetch all related platform tokens from Cognito for a user and subsequently their profile photos from platforms like Google and Facebook.
 * Example: `dispatch(getTokenFromCognito(token, callBackProfileImage))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - Redux's dispatch for state management and action dispatching.
 */
export const getTokenFromCognito =
  (token, callBackProfileImgae) => async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let { data } = await axios.get(`${backendCognitoUrl}/oauth2/userInfo`, {
        headers: headers,
      });
      if (data) {
        dispatch(
          getGoogleProfilePhotoAction(
            data["custom:google_access_token"],
            callBackProfileImgae
          )
        );
        dispatch(
          getfacebookUidAction(
            data["custom:fb_access_token"],
            callBackProfileImgae
          )
        );
        // console.log(data);
        // console.log(data["custom:google_access_token"]);
      }
    } catch (err) {
      console.log(err);
    }
  };

export const getfacebookUidAction =
  (token, callBackProfileImgae) => async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let { data } = await axios.get(
        `https://graph.facebook.com/me?fields=id&access_token=${token}`,
        { headers: headers }
      );
      if (data) {
        // callBackUid(data.id);

        dispatch(
          getfacebookProfilePhotoAction(data.id, token, callBackProfileImgae)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

export const getfacebookProfilePhotoAction =
  (uId, token, callBackProfileImgae) => async (dispatch) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let { data } = await axios.get(
        `https://graph.facebook.com/v9.0/${uId}/picture?redirect=false`,
        { headers: headers }
      );
      if (data) {
        callBackProfileImgae(data.data.url);
        // console.log(data);

        // callBack(data.picture);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                         Login Action
=================================================================*/
/**
 * Login Action
 *
 * Overview:
 * Handles user login by making an HTTP POST request to the backend API with the login form data. On successful login, stores user data and JWT in localStorage, sets the authentication token for future requests, and navigates the user to an appropriate page based on their profile completeness.
 *
 * Details:
 * - Utilizes conditional logic to navigate users to different pages post-login based on their profile data (e.g., DOB and confirmation status).
 * - On login failure due to unconfirmed email, redirects to an email verification alert page.
 *
 * Usage:
 * Dispatch this action when a user submits the login form.
 * Example: `dispatch(loginAction(formData, history))`
 *
 * Dependencies:
 * - `axios`, `Toast`, `localStorage`, and `setAuthToken` for HTTP requests, messaging, storage, and token management respectively.
 * - Redux's dispatch function and `SET_LOGIN`, `SET_ERRORS` action types for state management.
 * - History object for navigation.
 */
export const loginAction = (formData, history) => async (dispatch) => {
  try {
    let { data } = await axios.post(`${backendUrl}/auth/local`, formData);
    if (data) {
      if (data.user.DOB === null && data.user.confirmed === true) {
        localStorage.setItem("jwt", JSON.stringify(data.jwt));
        localStorage.setItem("userData", JSON.stringify(data.user));
        setAuthToken(data.jwt);
        dispatch({
          type: SET_LOGIN,
          payload: data.user,
        });
        Toast.info("Login Sucessfully", 3000);
        history.push("/get-started");
      } else if (data.user.DOB !== null && data.user.confirmed === true) {
        localStorage.setItem("jwt", JSON.stringify(data.jwt));
        localStorage.setItem("userData", JSON.stringify(data.user));
        setAuthToken(data.jwt);
        dispatch({
          type: SET_LOGIN,
          payload: data.user,
        });
        Toast.info("Login Sucessfully", 3000);
        history.push(raiseCapitalWebAppLink);
      }
    }
  } catch (err) {
    if (
      err.response.data.message[0].messages[0].message ===
        "Your account email is not confirmed" &&
      err.response.data.statusCode === 400
    ) {
      history.push({
        pathname: "/email-verify-alert",

        state: { userEmail: formData.identifier },
      });
    } else if (
      err.response.data.message[0].messages[0].message &&
      err.response.data.statusCode === 400
    ) {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data.message[0].messages[0].message,
      });
    }
  }
};

/*================================================================
                         Logout Action
=================================================================*/
/**
 * Logout Action
 *
 * Overview:
 * Handles user logout by clearing the authentication token, user data from localStorage, dispatching the logout action to update the application state, and optionally closing a Cognito logout session tab.
 *
 * Details:
 * - Clears localStorage and resets authentication headers.
 * - Opens and closes a tab to handle Cognito logout, ensuring the user is logged out from all sessions.
 *
 * Usage:
 * This action is dispatched when a user clicks the logout button.
 * Example: `dispatch(logoutAction(history))`
 *
 * Dependencies:
 * - `setAuthToken` utility to manage authentication headers.
 * - Redux's dispatch function and `SET_LOGOUT` action type for state management.
 * - `Toast` for displaying messages.
 * - History object for navigation.
 */
export const logoutAction = (history) => async (dispatch) => {
  try {
    setAuthToken(false);
    localStorage.clear();
    dispatch({
      type: SET_LOGOUT,
      payload: {},
    });
    Toast.info("Logout successful", 3000);
    let myTab = window.open(
      cognitoLogoutUrl,
      "",
      "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none', ''"
    );
    if (myTab) {
      setTimeout(function () {
        myTab.close();
      }, 2000);
    }
    history.push("/");
  } catch (err) {
    console.log(err);
  }
};

/*================================================================
                Resend Varification Email Action
=================================================================*/
/**
 * Resend Verification Email Action
 *
 * Overview:
 * Allows users to request another verification email if the first one was not received. It sends a form data object containing the user's email to the backend, which triggers another verification email.
 *
 * Details:
 * - Makes an HTTP POST request to the backend API endpoint responsible for sending verification emails.
 * - On success, displays a message indicating that the email has been sent.
 *
 * Usage:
 * This action can be dispatched when a user clicks on a "Resend Email" button on the verification page.
 * Example: `dispatch(ResendVarificationEmail(formData, callBackSendMail))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - `Toast` for displaying feedback messages.
 * - Redux's dispatch function for state management.
 */
export const ResendVarificationEmail =
  (formData, callBackSendMail) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/auth/send-email-confirmation`,
        formData
      );
      if (data) {
        Toast.info("Mail Sent", 3000);
        callBackSendMail(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                      Update User Action
=================================================================*/
/**
 * Update User Info Action
 *
 * Overview:
 * Updates the user's information on the backend and in local storage. If the user's chat_infoId is missing, it first creates a new chat menu. Then it updates the user's information and synchronizes the local storage data with the updated information.
 *
 * Details:
 * - Checks and updates chat_infoId by making a POST request to the chat-menu endpoint if necessary.
 * - Updates user data with a PUT request to the users endpoint, using the provided userId and formData.
 * - Synchronizes the local storage userData with the updated information.
 * - Redirects to "/stay-tuned" upon successful update.
 *
 * Usage:
 * This action is dispatched when a user updates their profile information from a form in the application.
 * Example: `dispatch(updateUserInfoAction(userId, userData, formData, history))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `isEmpty` utility function to check for empty values.
 * - `Toast` for displaying success messages.
 * - Redux's dispatch function for state management.
 * - History object for navigation.
 */
export const updateUserInfoAction =
  (userId, userData, formData, history, callBackLocationStatus) =>
  async (dispatch) => {
    try {
      if (isEmpty(userData.chat_infoId)) {
        let data1 = await axios.post(`${backendUrl}/chat-menu`, []);
        formData.chat_infoId = data1.data.id;
      }
      let { data, status } = await axios.put(
        `${backendUrl}/users/${userId}`,
        formData
      );
      if (data) {
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.Address = data.Address;
        previousData.DOB = data.DOB;
        previousData.firstname = data.firstname;
        previousData.lastname = data.lastname;
        previousData.phoneNumber = data.phoneNumber;
        previousData.interest = data.interest;
        previousData.role = data.role;
        previousData.updated_at = data.updated_at;
        previousData.user_profile = null;
        previousData.user_profile_id = null;
        if (isEmpty(previousData.chat_infoId)) {
          previousData.chat_infoId = data.chat_infoId;
        }
        if (isEmpty(previousData.socketId) || previousData.socketId === "") {
          previousData.socketId = data.socketId;
        }
        localStorage.setItem("userData", JSON.stringify(previousData));

        Toast.info("Data updated", 3000);
        if (history) {
          history.push("/stay-tuned");
        } else {
          callBackLocationStatus(status);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Get All User Data Action
 *
 * Overview:
 * Fetches all user data from the backend. It's typically used to populate admin views or user listings within the application.
 *
 * Details:
 * - Makes a GET request to the backend to retrieve all user data.
 * - Dispatches the data to the redux store, setting it as the payload of the SET_ALL_USER_DATA action.
 *
 * Usage:
 * This action can be dispatched to retrieve all user data for administrative purposes or data management within the application.
 * Example: `dispatch(getAllUserData())`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - Redux's dispatch function for state management.
 */
export const getAllUserData = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/users`);

    if (data) {
      dispatch({
        type: SET_ALL_USER_DATA,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Create User Data Action
 *
 * Overview:
 * Creates a new user data entry in the backend. This action is useful for administrative purposes where bulk user data might need to be uploaded or modified.
 *
 * Details:
 * - Sends a POST request with the user data to the backend.
 * - Displays a success message upon completion.
 *
 * Usage:
 * Use this action when new user data needs to be programmatically added to the application.
 * Example: `dispatch(createUserData(allUserData))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `Toast` for displaying success messages.
 * - Redux's dispatch function for state management.
 */
export const createUserData = (allUserData) => async (dispatch) => {
  try {
    let { data } = await axios.post(`${backendUrl}/download-user`, allUserData);
    if (data) {
      Toast.info("Data saved", 3000);
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Update User Data Action
 *
 * Overview:
 * Updates specific user data in the backend based on the provided userId and formData. This action is primarily used for updating user details from administrative interfaces or user profile pages.
 *
 * Details:
 * - Makes a PUT request to the user-specific endpoint with the updated formData.
 * - Optionally executes a callback function upon successful update.
 *
 * Usage:
 * This action is dispatched when there's a need to update specific fields of a user's data.
 * Example: `updateUserData(userId, formData, callBack)`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 */
export const updateUserData = (userId, formData, callBack) => async () => {
  try {
    let { data } = await axios.put(
      `${backendUrl}/download-user/${userId}`,
      formData
    );
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update User Interest Action
 *
 * Overview:
 * Updates a user's interests in the backend and synchronizes these changes with the local storage. This action allows users to change their interests, which can be used for personalized content or recommendations.
 *
 * Details:
 * - Makes a PUT request to update the user's interests based on the provided formData.
 * - Updates the local storage with the new interest data.
 * - Optionally calls a callback function with the HTTP status code upon success.
 *
 * Usage:
 * Dispatched when a user updates their interests from their profile settings.
 * Example: `dispatch(updateUserInterestAction(userId, formData, callBackInterestStatus))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `localStorage` for client-side data persistence.
 * - Redux's dispatch function for state management.
 */
export const updateUserInterestAction =
  (userId, formData, callBackInterestStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/users/${userId}`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.interest = data.interest;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackInterestStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Update User Address Action
 *
 * Overview:
 * Updates the address information for a specific user in the backend and synchronizes this update with the local storage. It also provides a callback mechanism to notify the calling code of the update status.
 *
 * Details:
 * - Makes an HTTP PUT request to the backend endpoint for updating user data, specifically targeting the user's address information.
 * - Upon successful update, the local storage is updated with the new address data.
 * - A callback function is invoked with the status code of the request to notify of success or failure.
 *
 * Usage:
 * This action is typically dispatched when a user updates their address information through a form in the user profile section of the application.
 * Example: `dispatch(updateUserAddressAction(userId, formData, callBackLocationStatus))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - Redux's dispatch function for state management.
 * - `localStorage` for client-side data persistence.
 */
export const updateUserAddressAction =
  (userId, formData, callBackLocationStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/users/${userId}`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.Address = data.Address;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackLocationStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Update User Phone Number Action
 *
 * Overview:
 * Responsible for updating a user's phone number in the backend. Similar to other update actions, it ensures that the local storage is kept in sync with the backend updates.
 *
 * Details:
 * - Executes an HTTP PUT request with the new phone number data.
 * - Updates local storage with the new phone number upon successful update.
 * - A callback function can be used to react to the success of the operation.
 *
 * Usage:
 * Dispatched within user profile editing features where users have the option to update their contact information.
 * Example: `dispatch(updateUserPhoneNoAction(userId, formData, callBackPhoneNoStatus))`
 *
 * Dependencies:
 * The same as `Update User Address Action`.
 */
export const updateUserPhoneNoAction =
  (userId, formData, callBackPhoneNoStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/users/${userId}`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.phoneNumber = data.phoneNumber;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackPhoneNoStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Update User Bio Action
 *
 * Overview:
 * Allows for the updating of a user's biographical information. This action updates the user's bio in the backend and updates the local storage to reflect this change.
 *
 * Details:
 * - Sends an HTTP PUT request with the user's new bio.
 * - Upon successful backend update, the local storage is also updated.
 * - Optionally, a callback function is invoked with the status of the operation.
 *
 * Usage:
 * Useful for user profile edits where the user wishes to change their bio or personal description.
 * Example: `dispatch(updateUserBioAction(userId, formData, callBackBioStatus))`
 *
 * Dependencies:
 * The same as `Update User Address Action`.
 */
export const updateUserBioAction =
  (userId, formData, callBackBioStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/users/${userId}`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.bio = data.bio;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackBioStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Get User Profile Image Action
 *
 * Overview:
 * Fetches the profile image of the user from the backend and updates the local storage with the retrieved image. This ensures that the user's profile image is up to date across the application.
 *
 * Details:
 * - Makes a GET request to retrieve the user's profile image based on their user ID.
 * - Updates the local storage with the new profile image data upon success.
 *
 * Usage:
 * Can be dispatched during application startup or when accessing a user's profile to ensure the latest profile image is displayed.
 * Example: `dispatch(getUserProfileImageAction())`
 *
 * Dependencies:
 * The same as `Update User Address Action`, with an additional check for empty data using `isEmpty`.
 */
export const getUserProfileImageAction = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/user-profiles?user=${userData.id}`
    );
    if (data) {
      // console.log("data", data);
      //upadating localstorage
      if (!isEmpty(data)) {
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.user_profile = data[0].profile_image;
        previousData.user_profile_id = data[0].id;
        localStorage.setItem("userData", JSON.stringify(previousData));
      }
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Create User Profile Image Action
 *
 * Overview:
 * Handles the uploading of a new profile image for the user. This action uploads the image to the backend and updates the local storage with the new image details.
 *
 * Details:
 * - Sends a POST request with the image formData to the backend.
 * - Upon successful upload, the local storage is updated with the new image details.
 * - A callback function is invoked with the status of the upload operation.
 *
 * Usage:
 * Used in profile editing features allowing users to upload a new profile picture.
 * Example: `dispatch(createUserProfileImageAction(formData, callBackProfileImageStatus))`
 *
 * Dependencies:
 * The same as `Update User Address Action`.
 */
export const createUserProfileImageAction =
  (formData, callBackProfileImageStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/user-profiles`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.user_profile = data.profile_image;
        previousData.user_profile_id = data.id;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackProfileImageStatus(status);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * Update User Profile Image Action
 *
 * Overview:
 * Updates the existing profile image of the user with a new one. This action sends the new image data to the backend and updates the local storage to reflect the change.
 *
 * Details:
 * - Performs an HTTP PUT request with the new image formData to update the user's profile image.
 * - Updates the local storage with the new image details upon success.
 * - Invokes a callback function with the operation status.
 *
 * Usage:
 * Dispatched when users update their existing profile picture through the application's UI.
 * Example: `dispatch(updateUserProfileImageAction(formData, callBackProfileImageStatus))`
 *
 * Dependencies:
 * The same as `Update User Address Action`.
 */
export const updateUserProfileImageAction =
  (formData, callBackProfileImageStatus) => async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/user-profiles/${userData.user_profile_id}`,
        formData
      );
      if (data) {
        // console.log("data", data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.user_profile = data.profile_image;
        previousData.user_profile_id = data.id;
        localStorage.setItem("userData", JSON.stringify(previousData));

        callBackProfileImageStatus(status);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                      Update User Action
=================================================================*/
/**
 * Update Is Register Flag Action
 *
 * Overview:
 * Specifically targets the is_registered flag of a user's profile for updating. This is typically used to mark the completion of a user's registration process.
 *
 * Details:
 * - Sends a PUT request to update the is_registered flag for the specified user.
 * - Updates the local storage to reflect the change in registration status.
 *
 * Usage:
 * This action might be dispatched after a user completes a registration step or confirms their email address.
 * Example: `dispatch(updateIsRegisterFlagAction(userId, formData))`
 *
 * Dependencies:
 * The same as `Update User Address Action`.
 */
export const updateIsRegisterFlagAction =
  (userId, formData) => async (dispatch) => {
    try {
      let { data } = await axios.put(`${backendUrl}/users/${userId}`, formData);
      if (data) {
        // console.log(data);
        //upadating localstorage
        let previousData = JSON.parse(localStorage.getItem("userData"));
        previousData.is_registered = true;
        previousData.updated_at = data.updated_at;
        localStorage.setItem("userData", JSON.stringify(previousData));
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                         Forget Password Action
=================================================================*/
/**
 * Send Forget Password Link Action
 *
 * Overview:
 * Initiates the process for users to reset their password by sending them a password reset link to their email.
 *
 * Details:
 * - Makes a POST request to the backend to send a password reset link to the user's email address.
 * - On success, a toast message is shown to inform the user that the link has been sent.
 * - In case of failure, an error action is dispatched with the error message.
 *
 * Usage:
 * Dispatched from the forgot password page when a user requests to reset their password.
 * Example: `dispatch(sendForgetPasswordLinkAction(formData, callBackSend))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - `Toast` for feedback messages.
 * - Redux's dispatch function for state management and error handling.
 */
export const sendForgetPasswordLinkAction =
  (formData, callBackSend) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/auth/forgot-password`,
        formData
      );
      if (data) {
        Toast.info("Link Sent", 3000);
        callBackSend(status);
      }
    } catch (err) {
      // console.log(err);
      if (
        err.response.data.message[0].messages[0].message &&
        err.response.data.statusCode === 400
      ) {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data.message[0].messages[0].message,
        });
      }
    }
  };

/*================================================================
                         Reset Password Action
=================================================================*/
/**
 * Reset Password Action
 *
 * Overview:
 * Handles the password reset functionality by making a POST request to the backend with the new password and the password reset token. It updates the user's password upon successful validation of the provided token.
 *
 * Details:
 * - Sends a POST request to the auth/reset-password endpoint with the new password and reset token.
 * - Displays a success message upon successful password reset.
 *
 * Usage:
 * Dispatched when a user submits a new password on the password reset form.
 * Example: `dispatch(ResetPasswordAction(formData, callBackSetPassword))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `Toast` for displaying success messages.
 * - Redux's dispatch function for state management.
 */
export const ResetPasswordAction =
  (formData, callBackSetPassword) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/auth/reset-password`,
        formData
      );
      if (data) {
        Toast.info("Password reset successfully", 3000);
        callBackSetPassword(status);
      }
    } catch (err) {
      console.log(err);
    }
  };
