import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_UPDATES_EXPLORE_DETAILS,
  SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
} from "./../types";

import Toast from "light-toast";

/*===================================================================================
            get updates all 
====================================================================================*/
/**
 * GetUpdatesExploreDetails Action
 * 
 * Overview:
 * Retrieves all update posts from the backend. This action is pivotal for populating the updates section with the latest posts, ensuring that users have access to up-to-date information and announcements.
 * 
 * Details:
 * - Makes a GET request to `/updates` to fetch all updates.
 * - Dispatches an action with type `SET_UPDATES_EXPLORE_DETAILS` to update the Redux store with the fetched updates.
 * 
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getUpdatesExploreDetails = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/updates`);
    if (data) {
      dispatch({
        type: SET_UPDATES_EXPLORE_DETAILS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get updates all by organization id
====================================================================================*/
/**
 * GetUpdatesExploreDetailsByOrganizationId Action
 * 
 * Overview:
 * Fetches update posts associated with a specific organization ID from the backend. This action filters updates to those relevant to a given organization, enabling targeted content delivery within the application.
 * 
 * Details:
 * - Sends a GET request to `/updates?organization=${orgId}` to retrieve updates specific to an organization.
 * - Updates the Redux store by dispatching an action of type `SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID` with the data fetched.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for determining the backend endpoint.
 */
export const getUpdatesExploreDetailsByOrganizationId = (orgId) => async (
  dispatch
) => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/updates?organization=${orgId}`
    );
    if (data) {
      dispatch({
        type: SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create update post
====================================================================================*/
/**
 * PostRaiseCapitalUpdate Action
 * 
 * Overview:
 * Submits a new update post to the backend and refreshes the list of updates for the relevant organization upon success. This action supports dynamic content creation within the application, allowing users to share updates efficiently.
 * 
 * Details:
 * - Executes a POST request to `/updates` with the form data for the new update.
 * - Upon success, refreshes the updates list for the organization and calls a callback with the status.
 * - Displays a confirmation message using Toast.
 * 
 * Dependencies:
 * - axios, Toast for making requests and displaying feedback.
 * - backendUrl for the API endpoint.
 */
export const postRaiseCapitalUpdate = (
  formData,
  callBackPostUpdateStatus
) => async (dispatch) => {
  try {
    let { data, status } = await axios.post(`${backendUrl}/updates`, formData);
    if (data) {
      dispatch(getUpdatesExploreDetailsByOrganizationId(data.organization.id));
      callBackPostUpdateStatus(status);
      Toast.info("Update posted");
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             update update post
====================================================================================*/
/**
 * PutRaiseCapitalUpdate Action
 * 
 * Overview:
 * Updates an existing update post by sending the modified data to the backend. Upon successful update, it refreshes the list of updates for the relevant organization and informs the user with a Toast message.
 * 
 * Details:
 * - Sends a PUT request to `/updates/${postId}` with the updated data.
 * - Refreshes the organization's updates and notifies the user upon success.
 * 
 * Dependencies:
 * - axios, Toast for HTTP requests and user feedback.
 * - backendUrl for the API endpoint.
 */
export const putRaiseCapitalUpdate = (
  formData,
  postId,
  callBackPostUpdateStatus
) => async (dispatch) => {
  try {
    let { data, status } = await axios.put(
      `${backendUrl}/updates/${postId}`,
      formData
    );
    if (data) {
      dispatch(getUpdatesExploreDetailsByOrganizationId(data.organization.id));
      callBackPostUpdateStatus(status);
      Toast.info("Update posted");
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             like update 
====================================================================================*/
/**
 * PostUpdateLikeExploreDetails Action
 * 
 * Overview:
 * Handles the liking of an update post by submitting like data to the backend. This action facilitates the interactive aspect of updates, allowing users to express their approval or support for content.
 * 
 * Details:
 * - Sends a POST request to `/update-likes` with the like data.
 * - Uses a callback to convey the operation's status to the caller.
 * 
 * Dependencies:
 * - axios for making the post request.
 * - backendUrl for the API endpoint.
 */
export const postUpdateLikeExploreDetails = (
  formData,
  callBackPostUpdateLikeStatus
) => async () => {
  try {
    let { data, status } = await axios.post(
      `${backendUrl}/update-likes`,
      formData
    );
    if (data) {
      callBackPostUpdateLikeStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            discussion like update 
====================================================================================*/
/**
 * DiscussionLikeExploreDetails Action
 * 
 * Overview:
 * Manages likes for discussions associated with updates by sending like data to the backend. Similar to post likes, this action enhances user interaction within discussion threads.
 * 
 * Details:
 * - Executes a POST request to `/discussion-likes` with the discussion like data.
 * - Communicates the operation's status through a callback function.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for determining the backend endpoint.
 */
export const discussionLikeExploreDetails = (
  formData,
  callBackDiscussionLikeStatus
) => async () => {
  try {
    let { data, status } = await axios.post(
      `${backendUrl}/discussion-likes`,
      formData
    );
    if (data) {
      callBackDiscussionLikeStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
           get discussion like
====================================================================================*/
/**
 * GetDiscussionLikeExploreDetails Action
 * 
 * Overview:
 * Fetches like data for discussions from the backend. This action supports the display of engagement metrics on discussion threads, indicating the community's response to discussions.
 * 
 * Details:
 * - Makes a GET request to `/discussion-likes` with appropriate query parameters to retrieve likes.
 * - Utilizes a callback to return the fetched data to the caller.
 * 
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint configuration.
 */
export const getDiscussionLikeExploreDetails = (
  formData,
  callback
) => async () => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/discussion-likes`,
      formData
    );
    if (data) {
      callback(data);
    }
  } catch (err) {
    console.log(err);
  }
};
/*===================================================================================
             like update 
====================================================================================*/

// export const putUpdateLikeExploreDetails = (
//   formData,
//   callBackPutUpdateLikeStatus
// ) => async (dispatch) => {
//   try {
//     let { data, status } = await axios.put(
//       `${backendUrl}/update-likes`,
//       formData
//     );
//     if (data) {
//       callBackPutUpdateLikeStatus(status);
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

/*===================================================================================
             delete update
====================================================================================*/
/**
 * DeleteUpdateLikeExploreDetails Action
 * 
 * Overview:
 * Removes a like from an update post by sending a DELETE request to the backend. This action allows users to retract their likes, ensuring that engagement metrics accurately reflect current user preferences.
 * 
 * Details:
 * - Sends a DELETE request to `/update-likes/${updateId}` to remove a like.
 * - Notifies the operation's completion status through a callback.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const deleteUpdateLikeExploreDetails = (
  updateId,
  callBackDeleteUpdateLikeStatus
) => async (dispatch) => {
  try {
    let { data, status } = await axios.delete(
      `${backendUrl}/update-likes/${updateId}`
    );
    if (data) {
      callBackDeleteUpdateLikeStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             delete update
====================================================================================*/
/**
 * DeleteDiscussionLikeExploreDetails Action
 * 
 * Overview:
 * Handles the removal of likes from discussions by sending a DELETE request to the backend. This function provides flexibility in user interactions, allowing likes to be withdrawn from discussions.
 * 
 * Details:
 * - Executes a DELETE request to `/discussion-likes/${updateId}` for like removal.
 * - Uses a callback to inform the caller of the operation's success.
 * 
 * Dependencies:
 * - axios for making DELETE requests.
 * - backendUrl for the API endpoint.
 */
export const deleteDiscussionLikeExploreDetails = (
  updateId,
  callback
) => async (dispatch) => {
  try {
    let { data, status } = await axios.delete(
      `${backendUrl}/discussion-likes/${updateId}`
    );
    if (data) {
      callback(status);
    }
  } catch (err) {
    console.log(err);
  }
};