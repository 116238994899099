import React, { useEffect, useState } from "react";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { Button, Image } from "react-bootstrap";
import TextareaField from "../common/TextareaField";
import { getRaiseCapitalTeam } from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch } from "react-redux";
import "../../../web-app-assets/scss/desktop/raise-capital/new-raise-capital-profile.scss";

const NewRaiseCapitalProfilePage = ({
  raiseCapitalOrg,
  raiseCapitalTeam,
  handleClickEdit,
}) => {
  const [companyDetails, setCompanyDetails] = useState({});
  const [teamDetails, setTeamDetails] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg[0]))
      setCompanyDetails({
        ...companyDetails,
        companyName: raiseCapitalOrg?.[0]?.company_name,
        companyLogo: raiseCapitalOrg?.[0]?.company_logo?.url,
        city: raiseCapitalOrg?.[0]?.city,
        state: raiseCapitalOrg?.[0]?.state,
        companyBio: raiseCapitalOrg?.[0]?.company_details,
      });
    dispatch(getRaiseCapitalTeam(raiseCapitalOrg[0].id));
  }, [raiseCapitalOrg?.raiseCapitalOrg]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalTeam) && raiseCapitalTeam?.length > 0) {
      setTeamDetails(raiseCapitalTeam);
    }
  }, [raiseCapitalTeam]);

  return (
    <div className="company-profile-page-main">
      <div className="company-details-main">
        <div className="company-logo-main text-center">
          <Image
            src={companyDetails?.companyLogo}
            alt="company-logo"
            className="company-logo border-rounded rounded-circle img-fluid mx-auto"
          />
        </div>
        <p className="company-name text-center">
          {companyDetails?.companyName}
        </p>
        <p className="city-name text-center m-0">
          {companyDetails?.city}, {companyDetails?.state}
        </p>
      </div>
      <div className="company-bio-main">
        <h3 className="company-bio-text">Company Bio</h3>
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital company-bio-textarea"
          isHideLabel={true}
          labelClassName="raise-capital-label-company-info"
          name="companyBio"
          value={companyDetails.companyBio}
        />
      </div>
      <div className="team-details-main">
        <h3 className="team-title">Team</h3>

        <div className="row">
          {teamDetails?.length > 0 &&
            teamDetails?.map((ele) => {
              return (
                <div
                  key={ele?.id}
                  className="col-12 col-md-6 col-lg-3 gy-4 text-center"
                >
                  {/* <div className="background-img mx-auto"></div> */}
                  <div className="background_img_main">
                    <Image
                      src={ele?.profile_image?.url}
                      className="background-img mx-auto"
                    />
                  </div>
                  <div>
                    <p className="card-name">{ele?.full_name}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="edit-profile-btn-main text-end">
        <Button className="edit-profile-btn" onClick={handleClickEdit}>
          Edit Profile
        </Button>
      </div>
    </div>
  );
};

export default NewRaiseCapitalProfilePage;
