import React, { Fragment, useState } from "react";
import format from "date-fns/format";
// import { compareAsc } from "date-fns";

// api
import { useEffect } from "react";
import {
  getDiscussionById,
  createDiscussionComment,
  getDiscussionCommentsByDiscussionId,
} from "../../../web-app-store/actions/discussionsAction";
import {
  useDispatch,
  //  useSelector
} from "react-redux";

import backArrow from "../../../assets/imgs/desktop/icon/backArrow.svg";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../common/DisplayLoader";
import TextareaField from "../common/TextareaField";
import DiscussionsCardStartupName from "./DiscussionsCardStartupName";

/**
 * DiscussionsCardDetails Component
 *
 * Overview:
 * The DiscussionsCardDetails component is responsible for displaying the details of a discussion card. It retrieves the discussion data and comments from the server and renders them on the screen. Users can also post new comments to the discussion.
 *
 * Details:
 * - The component uses the discussionId prop to fetch the discussion data and comments from the server.
 * - It uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store and dispatch actions.
 * - The component manages state using the useState hook. It keeps track of the discussion data, comments data, user input for new comments, frontend errors, and loading status.
 * - The component uses the useEffect hook to fetch the discussion data and comments when the discussionId prop changes.
 * - It renders the discussion details, including the user profile image, user name, post details, and comments.
 * - Users can post new comments by entering their thoughts in the input field and clicking the "Post to thread / Reply" button.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses the useDispatch and useSelector hooks from the react-redux library.
 * - react-router-dom: The component uses react-router-dom for navigation.
 * - date-fns: The component uses the format function from the date-fns library to format dates.
 * - Other custom components and actions: The component relies on other custom components and actions to fetch data and handle user interactions.
 */
export default function DiscussionsCardDetails({
  discussionId,
  handleOnClickGoBack,
}) {
  let userData = JSON.parse(localStorage.getItem("userData"));

  // api
  const dispatch = useDispatch();

  // const auth = useSelector((state) => state.auth);

  const [discData, setDiscData] = useState();
  const [discCommentsData, setDiscCommentsData] = useState();

  useEffect(() => {
    // discussion

    const callBackDiscussionData = (data) => {
      setDiscData(data);
    };
    dispatch(getDiscussionById(discussionId, callBackDiscussionData));

    // comments
    const callBackDiscussionCommentsByDiscussionIdOne = (data) => {
      setDiscCommentsData(data);
    };
    dispatch(
      getDiscussionCommentsByDiscussionId(
        discussionId,
        callBackDiscussionCommentsByDiscussionIdOne
      )
    );
  }, [dispatch, discussionId]);

  useEffect(() => {
    // set latest comments data first
    if (!isEmpty(discCommentsData)) {
      setDiscCommentsData(discCommentsData);
    }
  }, [discCommentsData]);

  const [thoughts, setThoughts] = useState(null);

  const [frontendErrors, setFrontendErrors] = useState({});

  const handleChange = (event) => {
    setFrontendErrors({});
    setThoughts(event.target.value);
  };
  const [isLoading, setIsLoading] = useState(false);

  // const callBackDiscussionDataComments = (data) => {
  //   setDiscData(data);
  //   setIsLoading(false);
  // };

  const callBackDiscussionCommentsByDiscussionId = (data) => {
    setDiscCommentsData(data);
    setIsLoading(false);
  };

  const callBackDiscussionCommentStatus = (status) => {
    if (status === 200) {
      // dispatch(getDiscussionById(discussionId, callBackDiscussionDataComments));
      dispatch(
        getDiscussionCommentsByDiscussionId(
          discussionId,
          callBackDiscussionCommentsByDiscussionId
        )
      );
    }
  };

  const handleOnClickPostToThread = (e) => {
    e.preventDefault();

    if (!isEmpty(thoughts)) {
      setIsLoading(true);
      let formData = {
        discussion: {
          id: discussionId,
        },
        user: { id: userData.id },
        comment_details: thoughts,
      };

      dispatch(
        createDiscussionComment(formData, callBackDiscussionCommentStatus)
      );
      setThoughts(null);
    }
  };

  return (
    <div className="vl-disc-details-main-block">
      <button
        onClick={handleOnClickGoBack}
        className="vl-disc-details-go-back-btn"
      >
        <img src={backArrow} />
        Go back
      </button>

      {!isEmpty(discData) ? (
        <Fragment>
          <div className="vl-disc-card-row row mx-0 align-items-start">
            <div className="vl-disc-card-row__img-block-outer flex-shrink-0">
              <div className="vl-disc-card-row__img-block">
                {/* <img src="" alt=""/> */}
                {discData?.user_profile?.profile_image ? (
                  <img
                    src={discData?.user_profile?.profile_image?.url}
                    alt=""
                  />
                ) : (
                  !isEmpty(discData.user.firstname) && (
                    <span className="font-36-roboto-bold">
                      {discData.user.firstname.charAt(0)}
                    </span>
                  )
                )}
              </div>
            </div>

            <div className="w-100">
              <div className="vl-disc-card-row__text-content-div">
                <div className="vl-disc-card-row__text-top-div">
                  <span className="font-18-roboto-bold">
                    {/* User name */}
                    {!isEmpty(discData.user) &&
                      `${discData.user.firstname} ${discData.user.lastname}`}
                  </span>
                  {/* {!isEmpty(discData.user) && (
                    <DiscussionsCardStartupName userId={discData.user.id} />
                  )} */}
                </div>
                <span className="font-18-roboto-bold date-list">
                  {!isEmpty(discData.updated_at) &&
                    format(new Date(discData.updated_at), "DD MMM, YYYY")}
                </span>
                <div className="w-100">
                  {/* <p className="vl-disc-card-row__text1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </p> */}
                  <p className="vl-disc-card-row__text2 font-18-roboto-regular">
                    {discData.post_details}
                  </p>
                </div>
              </div>
              <div className="vl-disc-card-row__imgAndBtnDiv row mx-0 align-items-center justify-content-between">
                <div className="vl-disc-details-comments-overflow">
                  {!isEmpty(discCommentsData) &&
                    discCommentsData.map((commnetData, index) => (
                      <Fragment key={index}>
                        {console.log(commnetData)}
                        <div className="row mx-0 flex-nowrap align-items-start vl-disc-details-comments-overflow__row">
                          <div className="startup-team-img-div flex-shrink-0">
                            {commnetData?.user_profile?.profile_image ? (
                              <img
                                src={commnetData.user_profile.profile_image.url}
                                alt=""
                              />
                            ) : (
                              !isEmpty(commnetData.user.firstname) && (
                                <span className="font-36-roboto-bold">
                                  {commnetData.user.firstname.charAt(0)}
                                </span>
                              )
                            )}
                          </div>
                          <div className="vl-disc-details-comments-overflow__textColm">
                            <div className="row mx-0 vl-disc-card-row__text-top-div">
                              <span className="font-18-roboto-bold p-0">
                                {/* User name */}
                                {!isEmpty(commnetData.user) &&
                                  `${commnetData.user.firstname} ${commnetData.user.lastname}`}
                              </span>
                              {/* {!isEmpty(commnetData.user) && (
                                <DiscussionsCardStartupName
                                  userId={commnetData.user.id}
                                />
                              )} */}
                            </div>
                            <span className="vl-disc-date-line">
                              {/* Mar 28,2020 */}
                              {!isEmpty(commnetData.updated_at) &&
                                format(
                                  new Date(commnetData.updated_at),
                                  "DD MMM, YYYY"
                                )}
                            </span>

                            {/* <p className="font-18-roboto-bold vl-disc-details-comments-overflow__textColm-text1">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua
                            </p> */}
                            <p className="vl-disc-card-row__text2 font-18-roboto-regular">
                              {commnetData.comment_details}
                            </p>
                            {/* <button className="vl-disc-details-comments-overflow__reply font-18-roboto-bold">
                              Reply
                            </button> */}
                          </div>
                        </div>
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {userData?.id != undefined && (
            <form onSubmit={handleOnClickPostToThread}>
              <div className="desc-bottom-sec">
                <TextareaField
                  isHideLabel={true}
                  containerClassName="desc-inpute-inner"
                  name="thoughts"
                  value={thoughts}
                  onChange={handleChange}
                  type="text"
                  placeholder="Add your thoughts"
                  error={frontendErrors.thoughts}
                />

                <button
                  className="vl-disc-card-row__imgAndBtnDiv-btn"
                  onClick={handleOnClickPostToThread}
                >
                  {!isLoading ? (
                    "Post to thread / Reply"
                  ) : (
                    <DisplayLoader loaderColor="#ffffff" />
                  )}
                </button>
              </div>
            </form>
          )}
        </Fragment>
      ) : (
        <DisplayLoader />
      )}
    </div>
  );
}
