import React from "react";
import { Link } from "react-router-dom";

// api
import { useHistory, useParams } from "react-router";
import { useState, useEffect } from "react";
import { getWebsiteBlogById } from "../../../web-app-store/actions/websiteBlogsAction";
import { useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";
// import DOMPurify from "dompurify";
import decode from "decode-html";
import { knowBeforeYouInvest, faq } from "../header-footer/pageLinks";
import BlogsCardsDisplayInBlogsDetails from "./BlogsCardsDisplayInBlogsDetails";

import Footer from "../header-footer/Footer";

import logo from "../../../assets/imgs/desktop/common/vedaslabs-logo-blog-details.png";

// import Iframe from "react-iframe";

// import img1 from "../../../assets/imgs/desktop/knowledge-base/kb2.png";
// import img2 from "../../../assets/imgs/desktop/knowledge-base/kb4.png";
// import img3 from "../../../assets/imgs/desktop/knowledge-base/kb5.png";

// const blogDataArray = [
//   {
//     imgPath: img1,
//     name: "Investing for Beginners",
//     desc: "How to Invest in Private Companies",
//   },
//   {
//     imgPath: img2,
//     name: "Investing in Startups",
//     desc: "Starting your journey? This one is for you",
//   },
//   {
//     imgPath: img3,
//     name: "Angel Investing vs Crowdfunding",
//   },
//   {
//     imgPath: img1,
//     name: "Investing for Beginners",
//     desc: "How to Invest in Private Companies",
//   },
//   {
//     imgPath: img2,
//     name: "Investing in Startups",
//     desc: "Starting your journey? This one is for you",
//   },
//   {
//     imgPath: img3,
//     name: "Angel Investing vs Crowdfunding",
//   },
// ];

/**
 * KnowledgeBaseBlogDetail Component
 * 
 * Overview:
 * The KnowledgeBaseBlogDetail component is responsible for displaying the details of a specific blog post from the knowledge base. It fetches the blog data from an API endpoint based on the blog ID provided in the URL parameters. The component also handles rendering the blog content, including any embedded media, and provides navigation options to related blog posts.
 * 
 * Details:
 * - The component uses React hooks such as useState, useEffect, useDispatch, useHistory, and useParams for state management, side effects, and navigation.
 * - It fetches the blog data from the API endpoint using the dispatch function provided by the useDispatch hook.
 * - The blog ID is extracted from the URL parameters using the useParams hook.
 * - The component dynamically sets the API endpoint based on the blog ID and updates the blogData state with the fetched data.
 * - If the blog data is available, the component renders the blog title, author, banner image, and content using JSX.
 * - The component also renders a footer section with links to learn more and additional resources.
 * - The BlogsCardsDisplayInBlogsDetails and Footer components are imported and rendered within the main component.
 * 
 * Usage:
 * To use the KnowledgeBaseBlogDetail component, import it into the desired location within your application and render it as a JSX element. Make sure to provide the necessary dependencies and props, such as the blog ID.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - react-redux: The component uses the useDispatch hook to dispatch actions to the Redux store.
 * - react-router-dom: The component uses the useHistory and useParams hooks for navigation and URL parameter extraction.
 * - DOMPurify: The component uses the DOMPurify library to sanitize and render HTML content safely.
 * - Other custom components: The component imports and renders the BlogsCardsDisplayInBlogsDetails and Footer components.
 */
export default function KnowledgeBaseBlogDetail() {
  // api
  const dispatch = useDispatch();
  const history = useHistory();
  let blogId = useParams();
  useEffect(() => {
    document.querySelectorAll("oembed[url]").forEach((element) => {
      // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
      // to discover the media.
      const anchor = document.createElement("a");

      anchor.setAttribute("href", element.getAttribute("url"));
      anchor.className = "embedly-card";

      element.appendChild(anchor);
      // console.log(anchor);
    });
  });

  const [blogData, setBlogData] = useState();
  const [endpoint, setEndpoint] = useState("");

  useEffect(() => {
    if (blogId?.id === "Investing-for-beginners") {
      setEndpoint(9);
    } else if (blogId?.id === "investing-in-startups") {
      setEndpoint(6);
    } else if (blogId?.id === "investing-101") {
      setEndpoint(5);
    } else if (blogId?.id === "angel-investing-and-crowdfunding") {
      setEndpoint(8);
    } else if (blogId?.id === "venture-capital-guide-for-startups") {
      setEndpoint(11);
    } else {
      setEndpoint(blogId?.id);
    }
  }, [blogId]);
  useEffect(() => {
    const callBackWebsiteBlogData = (data) => {
      if ("status" in data) {
        if (data.status === 500) {
          history.push("/error/notfound");
        }
      }
      setBlogData(data);
      if (data.id === 6) {
        history.replace("investing-in-startups");
      } else if (data.id === 5) {
        history.replace("investing-101");
      } else if (data.id === 8) {
        history.replace("angel-investing-and-crowdfunding");
      } else if (data.id === 9) {
        history.replace(`Investing-for-beginners`);
      } else if (data.id === 11) {
        history.replace("venture-capital-guide-for-startups");
      }
      window.scrollTo(0, 0);
    };
    if (endpoint) {
      dispatch(getWebsiteBlogById(endpoint, callBackWebsiteBlogData));
    }
  }, [endpoint]);

  const renderBlock1 = () => {
    return (
      <div className="row mx-0 align-items-center knowledge-base-blog-footer-row-div">
        <div className="footer-knowledge-div">
          <h5 className="font-35-montserrat-bold">Learn More&#62; </h5>
        </div>
        <div className="footer-top-right-div">
          <h5 className="font-25-montserrat-regular">
            All early stage investments entail risk and we are committed to
            helping you understand if investing is suitable for you.{" "}
            <Link to={knowBeforeYouInvest}>
              {" "}
              <u>Know Before You Invest</u>{" "}
            </Link>
            or{" "}
            <Link to={faq}>
              <u>FAQs</u>
            </Link>
            .
          </h5>
        </div>
      </div>
    );
  };

  let htmlInput = !isEmpty(blogData) && decode(blogData.article_body);

  return (
    <article className="vl-blog-div">
      {!isEmpty(blogData) ? (
        <div className="Insights-main-wrap">
          <div className="blog-app">
            <button
              onClick={() => history.goBack()}
              className="investing-startups-arrow"
            >
              <i className="fa fa-chevron-left vl-topnavbar__title-back-arrow"></i>
            </button>
            <div className="vl-navbar row mx-0 align-items-center justify-content-between">
              <Link to="/">
                <img src={logo} alt="vedslabs logo" className="navbar-logo" />
              </Link>
            </div>
            <h1 className="vl-blog-title text-center">{blogData.title}</h1>
            <h2 className="vl-blog-author text-center">{blogData.author}</h2>
            <div className="text-center">
              {!isEmpty(blogData.banner_image) && (
                <img
                  src={blogData.banner_image.url}
                  alt="banner"
                  className="vl-blog-img"
                />
              )}
            </div>

            {/* {console.log(htmlInput)} */}
            {!isEmpty(htmlInput) && htmlInput !== false && (
              <div
                className="vl-website-blogs-details-content"
                dangerouslySetInnerHTML={{
                  // __html: DOMPurify.sanitize(htmlInput),
                  __html: htmlInput,
                }}
              ></div>
            )}
          </div>
          {renderBlock1()}
          <BlogsCardsDisplayInBlogsDetails blogId={blogId} />
          <Footer />
        </div>
      ) : (
        <div className="py-5">
          <DisplayLoader />
        </div>
      )}
    </article>
  );
}
