import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * ValidateLightPaper
 * 
 * Overview:
 * Performs validation on user input data for a form (such as a light paper submission form), ensuring that first name, last name, and email are not only provided but also that the email address adheres to a valid format.
 * 
 * Details:
 * - Validates that the `fname`, `lname`, and `email` fields are not empty, assigning error messages to each field if they are.
 * - Further validates the `email` field for format correctness using the `Validator.isEmail` method.
 * - Constructs an errors object that contains any validation error messages. The presence of any errors indicates validation failure.
 * - Returns an object containing the `errors` object and a `isValid` boolean flag, which is true if no errors were found.
 * 
 * Dependencies:
 * - Validator: A library used for string validations, including checking if a string complies with email format standards.
 * - isEmpty: A utility function for checking if a value is empty, enhancing the validation process by simplifying checks for non-presence.
 */
export const validateLightPaper = (data) => {
  let errors = {};

  //   console.log(data);

  if (isEmpty(data.fname)) {
    errors.fname = "First name is required";
  }

  if (isEmpty(data.lname)) {
    errors.lname = "Last name is required";
  }

  if (isEmpty(data.email)) {
    errors.email = "Email is required";
  }

  if (!isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errors.email = "Please enter valid email";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

/**
 * ValidateEmail
 * 
 * Overview:
 * Validates email input within a form, ensuring that the email field is not empty, is in a valid format, and does not match an already existing email in the database (or any other given condition).
 * 
 * Details:
 * - Checks the `email` field for emptiness and format validity using `Validator.isEmail`.
 * - Compares the input email against a provided `email` parameter to ensure uniqueness.
 * - Populates an `errors` object with appropriate error messages for each validation check that fails.
 * - Returns an object containing the `errors` object and a `isValid` flag indicating whether the validation passed (true) or failed (false).
 * 
 * Dependencies:
 * - Validator for format validation.
 * - isEmpty for checking the presence of input values.
 */
export const validateEmail = (data, email) => {
  let errors = {};

  //   console.log(data);
  if (isEmpty(data.email)) {
    errors.email = "Email is required";
  }

  if (!isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errors.email = "Please enter valid email";
  }

  if (!isEmpty(email) && data.email === email) {
    errors.email = "An account with this email already exists.";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
