import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * InputFieldPhoneCountryNumber Component
 *
 * Overview:
 * The InputFieldPhoneCountryNumber component is used to render a phone number input field with a country selector. It provides functionality for handling phone number input, validation, and error display.
 *
 * Details:
 * - The component accepts various props to customize its behavior and appearance, such as index, containerClassName, label, id, name, phoneInput, placeholder, handleChangePhoneInput, errorPhone, isDisabled, isReadOnly, isHideLabel, and textClassName.
 * - The component renders a container div with a label (if not hidden) and a PhoneInput component.
 * - The PhoneInput component is used to render the phone number input field with a country selector.
 * - The component handles disabled and read-only states, displaying the phone number input field accordingly.
 * - The component also handles error display, showing an error message if the phone number is invalid.
 *
 * Dependencies:
 * - The component depends on the PhoneInput component for rendering the phone number input field with a country selector.
 * - The component may also depend on other components or libraries used within the application.
 */
function InputFieldPhoneCountryNumber({
  index,
  containerClassName,
  label,
  id,
  name,
  phoneInput,
  placeholder,
  handleChangePhoneInput,
  errorPhone,
  isDisabled,
  isReadOnly,
  isHideLabel,
  textClassName,
  labelClass,
}) {
  return (
    <div className={`${containerClassName} `}>
      <div className={isHideLabel ? "d-none" : ""}>
        <label
          htmlFor={id ? id : name}
          className={labelClass ? labelClass : ""}
        >
          {label}
        </label>
      </div>

      <div
        className={`d-flex mx-0 flex-nowrap react-phone-input-block-row ${
          isHideLabel ? "" : "input-country-phone-number-container"
        }`}
      >
        {isDisabled || isReadOnly ? (
          <div
            className={`react-phone-input-block react-phone-input-block--disabled ${
              !isEmpty(textClassName) ? textClassName : ""
            }`}
          >
            <PhoneInput
              disabled={isDisabled}
              country={"us"}
              value={phoneInput}
            />
            {errorPhone ? (
              <p className="error-message">{errorPhone}</p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </div>
        ) : (
          <div className="react-phone-input-block">
            <PhoneInput
              country={"us"}
              value={phoneInput}
              onChange={(phoneInputVal, country) =>
                handleChangePhoneInput(phoneInputVal, index, country)
              }
              placeholder={placeholder}
            />
            {errorPhone ? (
              <p className="error-message">{errorPhone}</p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

InputFieldPhoneCountryNumber.defaultProps = {
  index: "",
  placeholder: "",
  maxLength: "",
  errorPhone: "",
  autoFocus: "",
  isDisabled: false,
  isReadOnly: false,
  isHideLabel: false,
};

export default InputFieldPhoneCountryNumber;
