import { CREATE_PARTY, SET_CREDIT_CARDS } from "./../types";
import isEmpty from "./../validations/is-empty";

const initialState = {
  create_party: { success: "", kyccontent: "", userId: "" },
  credit_cards: null,
};

/**
 * proceedPaymentReducer
 * 
 * Overview:
 * This reducer handles the state for the proceed payment feature in the web app store. It updates the state based on the dispatched actions related to creating a party and setting credit cards.
 * 
 * Details:
 * - The reducer uses the initialState object to initialize the state if it is not provided.
 * - The reducer handles two types of actions: CREATE_PARTY and SET_CREDIT_CARDS.
 * - When the CREATE_PARTY action is dispatched, the reducer updates the state by setting the create_party property to true if the action payload is not empty.
 * - When the SET_CREDIT_CARDS action is dispatched, the reducer updates the state by setting the credit_cards property to true if the action payload is not empty.
 * - If none of the above actions are dispatched, the reducer returns the current state.
 * 
 * Usage:
 * This reducer is typically used in conjunction with the Redux store and combined with other reducers to manage the state of the web app store. It can be imported and used in any component that needs to interact with the proceed payment feature.
 * 
 * Dependencies:
 * - The reducer depends on the initialState object, which should be defined before using this reducer.
 * - The reducer relies on the CREATE_PARTY and SET_CREDIT_CARDS action types, which should be defined in the application's action types file.
 * - The reducer uses the isEmpty function from the lodash library to check if the action payload is empty.
 */
export default function proceedPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PARTY:
      return {
        ...state,
        create_party: !isEmpty(action.payload),
      };
    case SET_CREDIT_CARDS:
      return {
        ...state,
        credit_cards: !isEmpty(action.payload),
      };
    default:
      return state;
  }
}
