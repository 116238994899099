import React, {
  // useState,
  useEffect,
} from "react";
import {
  Link,
  //  Redirect,
  useHistory,
} from "react-router-dom";
import TopNavbarSearch from "./TopNavbarSearch";
// import { activeTopNavbarMenu } from "./activeMenu";

import DropdownIcon from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import Menu, { Item as MenuItem, Divider } from "rc-menu";
// import TopNavbarNotificationModal from "./TopNavbarNotificationModal";
import { useDispatch } from "react-redux";
import { logoutAction } from "./../../../web-app-store/actions/authAction";

import { getUserProfileImageAction } from "./../../../web-app-store/actions/authAction";

import {
  mentorChatBox,
  profileWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";

import isEmpty from "../../../web-app-store/validations/is-empty";
import { cognitoResetPasswordUrl } from "../../../web-app-store/actions/config";

/**
 * TopNavbar Component
 * 
 * Overview:
 * The TopNavbar component represents the top navigation bar of the application. It provides features such as displaying search, user profile, and logout functionality.
 * 
 * Details:
 * - The component uses Redux to manage state and dispatch actions.
 * - It uses React Router to handle navigation.
 * - The component fetches the user profile image using the `getUserProfileImageAction` action.
 * - It renders a dropdown menu for the user profile, which includes options like My Profile, Message, Reset Password, and Logout.
 * - The component accepts the `isDisplaySearch` prop to determine whether to display the search feature.
 *  */
export default function TopNavbar({
  // activeMenu,
  isDisplaySearch,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserProfileImageAction());
  }, [dispatch]);

  const onSelect = (action) => {
    if (action === "logout") {
      // console.log("logout");
      dispatch(logoutAction(history));
    } else if (action === "reset") {
      window.location.assign(`${cognitoResetPasswordUrl}`);
    }
  };

  // const [isVisible, setIsVisible] = useState(false);
  const renderProfileBlock = () => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let username = userData?.username;

    // console.log(username.split("_")[0]);

    const menu = (
      <Menu>
        <MenuItem>
          <Link to={profileWebAppLink}>
            <div className="profile-link-div">My Profile</div>
          </Link>
        </MenuItem>

        <Divider />

        {userData.interest === "Mentoring" && (
          <>
            <MenuItem>
              <Link to={mentorChatBox}>
                <div className="profile-link-div">Message</div>
              </Link>
            </MenuItem>
            <Divider />
          </>
        )}

        {username.split("_")[0] !== "google" &&
        username.split("_")[0] !== "facebook" ? (
          <>
            <MenuItem onClick={() => onSelect("reset")}>
              Reset Password
            </MenuItem>
            <Divider />
          </>
        ) : (
          ""
        )}

        <MenuItem onClick={() => onSelect("logout")}>Logout</MenuItem>
      </Menu>
    );

    const onVisibleChange = (visible) => {
      // console.log(visible);
      // setIsVisible(visible);
    };

    return (
      <>
        <DropdownIcon
          overlayClassName="vl-topnavbar-profile-rc-dropdown 1234 profile_side_image_main"
          trigger={["click"]}
          overlay={menu}
          onVisibleChange={onVisibleChange}
        >
          {!isEmpty(userData.user_profile) ? (
            <div className="vl-topnavbar__profile-img-block ">
              <img src={userData.user_profile.url} alt="profile" />
            </div>
          ) : (
            !isEmpty(userData.firstname) && (
              <div className="vl-topnavbar__profile-img-block vl-topnavbar__profile-img-block--text">
                <span className="font-24-roboto-medium text-capitalize">
                  {userData.firstname.charAt(0)}
                </span>
              </div>
            )
          )}
        </DropdownIcon>
      </>
    );
  };

  return (
    <>
      {/* <div className="row mx-0 justify-content-between align-items-center vl-topnavbar"> */}
      <div>
        <div className="d-flex mx-0 align-items-center">
          {isDisplaySearch && <TopNavbarSearch />}
          {/*           
          <div
            className={
              activeTopNavbarMenu[0] === activeMenu
                ? "vl-topnavbar__discuss-icon-block vl-topnavbar__activeMenu"
                : "vl-topnavbar__discuss-icon-block"
            }
          >
            <Link to="/discussions">
              <img
                src="/web-app-img/desktop/icons/discussion-blue-icon.svg"
                alt="discussion"
                className="vl-topnavbar__discuss-icon"
              />
            </Link>
          </div>

          <TopNavbarNotificationModal activeMenu={activeMenu} /> */}

          {renderProfileBlock()}
        </div>
      </div>
    </>
  );
}

TopNavbar.defaultProps = {
  activeMenu: "",
  isDisplaySearch: false,
};
