import axios from "axios";
import { paymentBaseURL } from "./config";
import { CREATE_PARTY, SET_CREDIT_CARDS } from "../types";
import Toast from "light-toast";

/*===================================================================================
            get explore all organizations
====================================================================================*/
/**
 * CreateParty Action
 * 
 * Overview:
 * The CreateParty action is designed to facilitate the creation of a new party or group within the application, using data submitted through a form. This action sends the form data to a specific endpoint on the payment service backend and updates the application state based on the response.
 * 
 * Details:
 * - Initiates a PUT request to `/tapiv3/index.php/v3/createParty` with the form data for party creation.
 * - On successful creation, displays a toast message to inform the user of the successful action and updates the Redux store by dispatching an action of type `CREATE_PARTY` with the received data.
 * - In case of an error during the request, displays an error message via toast and dispatches a default action to handle the error scenario.
 * 
 * Dependencies:
 * - axios: For making HTTP requests.
 * - Toast from light-toast: For displaying feedback messages to the user.
 * - CREATE_PARTY: Redux action type for updating the state with the new party data.
 * - paymentBaseURL: Configuration value specifying the base URL for payment-related endpoints.
 */
export const createParty = (formData, history) => async (dispatch) => {
  try {
    await axios
      .put(`${paymentBaseURL}/tapiv3/index.php/v3/createParty`, formData)
      .then((res) => {
        let { data } = res;
        Toast.info("Part created successfully", 3000);
        dispatch({
          type: CREATE_PARTY,
          payload: data,
        });
        //history.push("/invest-payment");
      })
      .catch((err) => {
        Toast.fail(err.message, 3000);
        dispatch({
          type: CREATE_PARTY,
          payload: { success: "1", kyccontent: "invest", userId: "1002233" },
        });
        //history.push("/invest-payment");
      });
  } catch (err) {}
};

/**
 * GetUserCreditCardInfo Action
 * 
 * Overview:
 * Fetches credit card information associated with a user, utilizing form data to specify the user context. This action supports financial transactions or setups within the application by ensuring users can manage or use their stored credit card information.
 * 
 * Details:
 * - Makes a POST request to `/tapiv3/index.php/v3/getCreditCard` with form data to retrieve user-specific credit card information.
 * - Upon successful retrieval, dispatches an action of type `SET_CREDIT_CARDS` with the response data to update the Redux store, enabling the application to reflect the user's credit card details accurately.
 * - Handles errors by displaying a failure message using Toast and dispatching a default action to mitigate the error condition without interrupting the user experience drastically.
 * 
 * Dependencies:
 * - axios for sending the request.
 * - Toast for user notifications.
 * - SET_CREDIT_CARDS as the Redux action type for storing credit card information in the application state.
 * - paymentBaseURL for the API endpoint configuration.
 */
export const getUserCreditCardInfo = (formData) => async (dispatch) => {
  try {
    await axios
      .post(`${paymentBaseURL}/tapiv3/index.php/v3/getCreditCard`, formData)
      .then((res) => {
        let { data } = res;
        dispatch({
          type: SET_CREDIT_CARDS,
          payload: data,
        });
      })
      .catch((err) => {
        Toast.fail(err.message, 3000);
        dispatch({
          type: SET_CREDIT_CARDS,
          payload: { success: "1", kyccontent: "invest", userId: "1002233" },
        });
      });
  } catch (err) {}
};
