import React from "react";

// api
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowersByUserId,
  createFollowers,
  // updateFollowers,
  deleteFollowerByFollowerId,
} from "../../../web-app-store/actions/followersAction";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * CardFollowButton Component
 * 
 * Overview:
 * The CardFollowButton component is responsible for rendering a follow button that allows users to follow or unfollow an organization. It checks if the user is already following the organization and updates the button accordingly.
 * 
 * Details:
 * - The component uses Redux for state management and dispatches actions to interact with the API.
 * - It retrieves the user data from local storage and checks if the user is already following the organization.
 * - The component accepts the following props:
 *   - orgId: The ID of the organization to follow/unfollow.
 *   - classNameFollowingBtn: The CSS class name for the follow button when the user is already following the organization.
 *   - classNameFollowBtn: The CSS class name for the follow button when the user is not following the organization.
 * 
 * Dependencies:
 * The CardFollowButton component depends on the following:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook from Redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from Redux to access the state.
 * - getFollowersByUserId: The component dispatches this action to retrieve the followers of the user.
 * - createFollowers: The component dispatches this action to create a new follower for the user.
 * - deleteFollowerByFollowerId: The component dispatches this action to delete a follower.
 */
export default function CardFollowButton({
  orgId,
  classNameFollowingBtn,
  classNameFollowBtn,
}) {
  // api
  const dispatch = useDispatch();

  let userData = JSON.parse(localStorage.getItem("userData"));

  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    dispatch(getFollowersByUserId());
  }, [dispatch]);

  const followersAllByUserId = useSelector(
    (state) => state.followers.followersAllByUserId
  );

  useEffect(() => {
    if (!isEmpty(followersAllByUserId)) {
      let filterObj = followersAllByUserId.filter(
        (a) => !isEmpty(a.organizations) && a.organizations[0].id === orgId
      );
      if (!isEmpty(filterObj)) {
        setIsFollowing(true);
      } else {
        setIsFollowing(false);
      }
    } else {
      setIsFollowing(false);
    }
  }, [followersAllByUserId, orgId]);

  const callBackStatus = (status) => {
    if (status === 200) {
      setIsFollowing(true);
    }
  };

  const handleOnClickFollow = () => {
    let formData = {
      user: userData.id,
      organizations: [orgId],
      is_investor: false,
      is_mentor: false,
    };

    dispatch(createFollowers(formData, callBackStatus));
  };

  // const callBackUpdateFollowersStatus = (status) => {
  //   if (status === 200) {
  //     setIsFollowing(false);
  //   }
  // };

  // const updateFollowerFromFollowersList = () => {
  //   let allOrgId = [];

  //   if (!isEmpty(followersAllByUserId)) {
  //     followersAllByUserId.map(
  //       (data, index) =>
  //         !isEmpty(data.organizations[0]) &&
  //         allOrgId.push(data.organizations[0].id)
  //     );
  //   }

  //   if (!isEmpty(allOrgId)) {
  //     // filter current follower from list
  //     let senOrgIds = followersAllByUserId.filter(
  //       (a) => !isEmpty(a.organizations) && a.organizations[0].id !== orgId
  //     );

  //     let currentFollower = followersAllByUserId.filter(
  //       (a) => !isEmpty(a.organizations) && a.organizations[0].id === orgId
  //     );

  //     let formData = {
  //       user: userData.id,
  //       organizations: senOrgIds,
  //       is_investor: false,
  //       is_mentor: false,
  //     };
  //     dispatch(
  //       updateFollowers(
  //         formData,
  //         currentFollower[0].id,
  //         callBackUpdateFollowersStatus
  //       )
  //     );
  //   }
  // };

  const callBackDeleteFollowersStatus = (status) => {
    if (status === 200) {
      setIsFollowing(false);
    }
  };

  const deleteFollowerFromFollowersList = () => {
    let currentFollower = followersAllByUserId.filter(
      (a) => !isEmpty(a.organizations) && a.organizations[0].id === orgId
    );
    dispatch(
      deleteFollowerByFollowerId(
        currentFollower[0].id,
        callBackDeleteFollowersStatus
      )
    );
  };

  return (
    <>
      {isFollowing ? (
        <button
          className={classNameFollowingBtn}
          onClick={deleteFollowerFromFollowersList}
        >
          <i className="fa fa-star"></i>
          <span>Following</span>
        </button>
      ) : (
        <button className={classNameFollowBtn} onClick={handleOnClickFollow}>
          <i className="fa fa-star-o"></i>
          <span>Follow</span>
        </button>
      )}
    </>
  );
}
