import React from "react";
import InputFieldEmailTextPassword from "./InputFieldEmailTextPassword";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * Tags Component
 *
 * Overview:
 * The Tags component is used to display and manage a list of tags. It consists of an input field where users can enter tags, and a display area where the added tags are shown. Users can also remove tags from the list.
 *
 * Details:
 * - The component accepts several props including id, name, label, placeholder, onChange, onKeyDown, onClickArrow, value, tagsArray, handleRemoveTag, isDisabled, error, and softwareArray.
 * - The input field allows users to enter tags and triggers the onChange event when the value changes. The onKeyDown event is triggered when a key is pressed while the input field is focused.
 * - The onClickArrow event is triggered when the arrow button is clicked.
 * - The tagsArray prop is an array of tags that are displayed in the display area. Each tag can be removed by clicking the remove button.
 * - The softwareArray prop is another array of tags that are displayed in a separate section. The behavior is similar to the tagsArray.
 * - The isDisabled prop determines whether the input field and remove buttons are disabled or not.
 * - The error prop is used to display an error message below the input field.
 *
 * Dependencies:
 * - The Tags component depends on the InputFieldEmailTextPassword component, which is not included in this file. Make sure to import and include it in your project.
 * - The component may also depend on other components or libraries used in the project, but they are not specified in this file.
 */
export default function Tags({
  id,
  name,
  label,
  placeholder,
  onChange,
  onKeyDown,
  onClickArrow,
  value,
  tagsArray,
  handleRemoveTag,
  isDisabled,
  error,
  softwareArray,
}) {
  return (
    <>
      {/* input */}
      {!isDisabled && (
        <React.Fragment>
          <div className="d-flex mx-0 flex-nowrap align-items-center information-main information_details_main">
            <div className="vl-form-input--raise-capital">
              <InputFieldEmailTextPassword
                containerClassName="vl-form-input"
                label={label}
                id={id ? id : name}
                name={name}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                type="text"
                // error={error}
                isError={true}
              />
            </div>
            <button
              className="raise-capital-tags-arrow-btn flex-shrink-0 button_arrow"
              onClick={onClickArrow}
            >
              <i className="fa fa-arrow-right"></i>
            </button>
          </div>
          <span className="instruction_wrap">
            Instructions: Type a tag and click the arrow to add. Repeat to add
            more tags.
          </span>
          {error ? (
            <p className="error-message">{error}</p>
          ) : (
            <p className={`error-message opacity-0`}>error</p>
          )}
        </React.Fragment>
      )}

      {/* display all tags */}
      {!isEmpty(tagsArray) && (
        <>
          <p className="font-18-roboto-light vl-added-tags-text">Added tags</p>

          <div className="d-flex mx-0 align-items-center flex-wrap vl-all-tags-row">
            {tagsArray.map((tag, index) => (
              <div
                key={index}
                className="d-flex ml-0 flex-nowrap align-items-center justify-content-between vl-added-tag-block"
              >
                <p className="font-18-roboto-bold">{tag}</p>
                {!isDisabled && (
                  <button onClick={handleRemoveTag(tag)}>
                    <span>&times;</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        </>
      )}

      {!isEmpty(softwareArray) && (
        <>
          <p className="font-18-roboto-light vl-added-tags-text">Added tags</p>

          <div className="row mx-0 align-items-center flex-wrapmentor-header vl-all-tags-row">
            {softwareArray.map((tag, index) => (
              <div
                key={index}
                className="row ml-0 flex-nowrap align-items-center justify-content-between vl-added-tag-block"
              >
                <p className="font-18-roboto-bold">{tag}</p>
                {!isDisabled && (
                  <button onClick={handleRemoveTag(tag)}>
                    <span>&times;</span>
                  </button>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

Tags.defaultProps = {
  isDisabled: false,
};
