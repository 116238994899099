import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMentorByUserId } from "../web-app-store/actions/mentorAction";
import isEmpty from "../web-app-store/validations/is-empty";

/**
 * PrivateRouteMentor Component
 *
 * Overview:
 * The PrivateRouteMentor component is a higher-order component that renders a specified component only if the user is authenticated and confirmed. Otherwise, it redirects the user to the home page.
 *
 * Details:
 * - The PrivateRouteMentor component receives a component prop, which represents the component to be rendered if the user is authenticated and confirmed.
 * - The component uses the useSelector hook from the react-redux library to access the authentication state from the Redux store.
 * - The component checks if the user is authenticated and confirmed. If true, it renders the specified component with the provided props. Otherwise, it redirects the user to the home page.
 *
 * Dependencies:
 * - react-redux: The useSelector hook is used to access the authentication state from the Redux store.
 * - react-router-dom: The Route and Redirect components are used for routing and redirection.
 */
const PrivateRouteMentor = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const { mentor } = useSelector((state) => state.mentor);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMentorByUserId());
  }, []);

  useEffect(() => {
    if (!isEmpty(mentor?.[0])) {
      const timeoutId = setTimeout(() => {
        if (mentor?.[0]?.live_profile === "InActive Profile") {
          // Trigger logout after inactivity
          history.push("/");
          localStorage.clear();
          window.location.reload();
        }
      }, 1800000); // Logout after 30 minutes of inactivity

      return () => clearTimeout(timeoutId);
    }
  }, [mentor]);

  // console.log(auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true && auth.user.confirmed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRouteMentor;
