import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateRaiseCapitalBasic Function
 *
 * Overview:
 * This function is used to validate the basic information required for raising capital. It checks if the required fields are empty and if the entered data is valid.
 *
 * Details:
 * - The function takes an object 'data' as a parameter, which contains the information to be validated.
 * - It initializes an empty 'errors' object to store any validation errors.
 * - The function checks if the 'company' field is empty. If it is, it adds an error message to the 'errors' object.
 * - It checks if the 'altEmail' field is not empty and if it is a valid email address using the 'Validator.isEmail' function. If not, it adds an error message to the 'errors' object.
 * - Similarly, it checks for other required fields such as 'phone', 'website', 'basicAdress1', 'city', 'state', 'zipCode', 'imgUrl', 'companyInfo', 'tagsArray', 'selectedIndustry', and 'selectedSocialImpact'.
 * - Finally, it returns an object with the 'errors' and 'isValid' properties. 'errors' contains any validation errors, and 'isValid' is a boolean indicating if the data is valid (i.e., no errors).
 *
 * Dependencies:
 * - The function depends on the 'Validator' object, which provides the 'isEmail' function for email validation. The 'Validator' object is assumed to be imported or available in the current scope.
 */
export const validateRaiseCapitalBasic = (data) => {
  let errors = {};

  //   console.log(data);

  if (isEmpty(data.company)) {
    errors.company = "Company name is required";
  }

  // if (isEmpty(data.altEmail)) {
  //   errors.altEmail = "Alternative Email is required";
  // }

  if (!isEmpty(data.altEmail) && !Validator.isEmail(data.altEmail)) {
    errors.altEmail = "Please enter valid email";
  }

  if (isEmpty(data.phone)) {
    errors.phone = "Phone number is required";
  }

  if (isEmpty(data.website)) {
    errors.website = "Website is required";
  }

  if (isEmpty(data.basicAdress1)) {
    errors.basicAdress1 = "Business Address L1 is required";
  }

  // if (isEmpty(data.basicAdress2)) {
  //   errors.basicAdress2 = "Business Address L2 is required";
  // }

  if (isEmpty(data.city)) {
    errors.city = "City is required";
  }

  if (isEmpty(data.state)) {
    errors.state = "State is required";
  }

  // if (isEmpty(data.country)) {
  //   errors.country = "Country is required";
  // }

  if (isEmpty(data.zipCode)) {
    errors.zipCode = "Zip code is required";
  }

  // if (isEmpty(data.fileName)) {
  //   errors.fileName = "Company logo is required";
  // }

  if (isEmpty(data.imgUrl)) {
    errors.imgUrl = "Company logo is required";
  }

  if (isEmpty(data.companyInfo)) {
    errors.companyInfo = "Company information is required";
  }

  if (isEmpty(data.tagsArray)) {
    errors.tagsArray = "At least one tag is required";
  }

  if (isEmpty(data.selectedIndustry)) {
    errors.selectedIndustry = "Industry is required";
  }

  if (isEmpty(data.selectedSocialImpact)) {
    errors.selectedSocialImpact = "Social Impact Area is required";
  }

  if (Validator.isEmpty(data.linkedIn === null ? "" : data.linkedIn)) {
    errors.linkedIn = "LinkedIn account is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
