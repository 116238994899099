import React, { Fragment } from "react";

// api
import { useEffect } from "react";
import { getWebsiteAllBlogs } from "../../../web-app-store/actions/websiteBlogsAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import KnowledgeBaseCard from "./KnowledgeBaseCard";

/**
 * BlogsCardsDisplayInBlogsDetails Component
 * 
 * Overview:
 * This component displays a list of blog cards in the blog details page. It fetches the list of all blogs from the website's state and renders the cards accordingly. It also handles the logic for excluding the current blog from the list if the blogId prop is provided.
 * 
 * Details:
 * - The component uses Redux hooks (useDispatch and useSelector) to access the websiteAllBlogs state from the Redux store.
 * - It dispatches the getWebsiteAllBlogs action if the websiteAllBlogsOne state is empty.
 * - The component conditionally renders the blog cards based on the websiteAllBlogs state and the blogId prop.
 * - If websiteAllBlogs is not empty and blogId is not empty, it checks if there is only one blog in the list and if its id matches the provided blogId. If so, it renders nothing. Otherwise, it renders the blog cards excluding the current blog.
 * - If websiteAllBlogs is not empty and blogId is empty, it renders all the blog cards.
 * 
 * Usage:
 * <BlogsCardsDisplayInBlogsDetails blogId={123} />
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook from Redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from Redux to access the websiteAllBlogs state.
 * - getWebsiteAllBlogs: The component depends on the getWebsiteAllBlogs action to fetch the list of all blogs.
 * - isEmpty: The component uses the isEmpty function to check if the websiteAllBlogs state is empty.
 * - KnowledgeBaseCard: The component renders KnowledgeBaseCard sub-component for each blog card.
 */
export default function BlogsCardsDisplayInBlogsDetails({ blogId }) {
  // api
  const dispatch = useDispatch();

  const websiteAllBlogsOne = useSelector(
    (state) => state.websiteBlogs.websiteAllBlogs
  );

  useEffect(() => {
    if (isEmpty(websiteAllBlogsOne)) {
      dispatch(getWebsiteAllBlogs());
    }
  }, [dispatch, websiteAllBlogsOne]);

  const websiteAllBlogs = useSelector(
    (state) => state.websiteBlogs.websiteAllBlogs
  );

  return (
    <>
      {!isEmpty(websiteAllBlogs) && !isEmpty(blogId)
        ? websiteAllBlogs.length === 1 &&
          websiteAllBlogs[0].id === parseInt(blogId.id)
          ? null
          : websiteAllBlogs.length > 0 && (
              <div className="vl-blog-details-card-div">
                <div className="row mx-0 flex-nowrap align-items-start">
                  {websiteAllBlogs.map((data, index) => (
                    <Fragment key={index}>
                      {data.id !== parseInt(blogId.id) && (
                        <Fragment key={index}>
                          <KnowledgeBaseCard data={data} />
                        </Fragment>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            )
        : !isEmpty(websiteAllBlogs) && (
            <div className="vl-blog-details-card-div">
              <div className="row mx-0 flex-nowrap align-items-start">
                {websiteAllBlogs.map((data, index) => (
                  <Fragment key={index}>
                    <KnowledgeBaseCard data={data} />
                  </Fragment>
                ))}
              </div>
            </div>
          )}
    </>
  );
}
