import { CAREER_PAGE } from "./../types";
import axios from "axios";
import { backendUrl } from "./config";

/**
 * Career Action
 * 
 * Overview:
 * This file contains the action creator for fetching career data from the backend API. It dispatches an action with the fetched data to update the state.
 * 
 * Details:
 * - The getCareerData function is an asynchronous action creator that makes an HTTP GET request to the backend API to fetch career data.
 * - It uses the axios library to send the request.
 * - Upon receiving the response, it dispatches an action of type CAREER_PAGE with the fetched data as the payload.
 * - If an error occurs during the request, it logs the error to the console.
 * 
 * Usage:
 * To fetch career data, call the getCareerData function and pass it to the Redux dispatch function.
 * 
 * Dependencies:
 * - axios: This library is used to make HTTP requests.
 * - backendUrl: This variable should be defined and contain the URL of the backend API.
 * - CAREER_PAGE: This constant should be defined and represent the action type for updating the career page data in the Redux store.
 */
export const getCareerData = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/career`);
    if (data) {
      dispatch({
        type: CAREER_PAGE,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
