import React, { useEffect } from "react";
import SideNavbar from "../header/SideNavbar";
import TopNavbar from "../header/TopNavbar";
import PageTitle from "../header/PageTitle";
import Footer from "../../../components/desktop/header-footer/Footer";
import AboutUsFoldTwo from "../../../components/desktop/about-us/AboutUsFoldTwo";
import AboutUsFoldThree from "../../../components/desktop/about-us/AboutUsFoldThree";

/**
 * AboutUsWebApp Component
 * 
 * Overview:
 * The AboutUsWebApp component is responsible for rendering the About Us page of the web application. It includes a side navigation bar, a page title, and two sub-components: AboutUsFoldTwo and AboutUsFoldThree. The component ensures that the page is scrolled to the top when it is mounted.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when it is mounted.
 * - The component structure consists of a div with the class "vl-main-page-div" that contains the main content of the page.
 * - Inside the "vl-main-page-div", there is a div with the class "vl-main-page-div__content" that contains the page title and the sub-components.
 * - The page title is rendered using the PageTitle component, with the pageTitle prop set to "About Us" and the isDisplayImage prop set to false.
 * - The sub-components, AboutUsFoldTwo and AboutUsFoldThree, are rendered inside a div with the class "vl-about-us-web".
 * - The component also includes a Footer component with the webAppFooter prop set to true.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the UI elements.
 * - SideNavbar: The component depends on the SideNavbar component to render the side navigation bar.
 * - PageTitle: The component depends on the PageTitle component to render the page title.
 * - AboutUsFoldTwo: The component depends on the AboutUsFoldTwo component to render the second fold of the About Us page.
 * - AboutUsFoldThree: The component depends on the AboutUsFoldThree component to render the third fold of the About Us page.
 * - Footer: The component depends on the Footer component to render the footer of the web application.
 */
export default function AboutUsWebApp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle isDisplayImage={false} pageTitle="About Us" />
          <div className="vl-about-us-web">
            <AboutUsFoldTwo />
            <AboutUsFoldThree />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
