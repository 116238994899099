import isEmpty from "../is-empty";
import Validator from "validator";

/**
 * validateMentorRecord Function
 * 
 * Overview:
 * This function is used to validate the mentor record data. It checks if the required fields are empty and validates the email format.
 * 
 * Details:
 * - The function takes a data object as a parameter, which contains the mentor record data.
 * - It initializes an empty object called errors to store any validation errors.
 * - It checks if the first_name field is empty. If it is, it adds an error message to the errors object.
 * - It checks if the last_name field is empty. If it is, it adds an error message to the errors object.
 * - It checks if the linkedin_link field is empty. If it is, it adds an error message to the errors object.
 * - It checks if the email field is empty. If it is, it adds an error message to the errors object.
 * - It checks if the email field is not empty and if it is a valid email format using the Validator.isEmail() function. If it is not a valid email format, it adds an error message to the errors object.
 * - Finally, it returns an object containing the errors and a boolean value indicating if the data is valid or not.
 * 
 * Dependencies:
 * - The function depends on the isEmpty() function to check if a field is empty.
 * - The function depends on the Validator.isEmail() function to validate the email format.
 */
export const validateMentorRecord = (data) => {
  let errors = {};

  if (isEmpty(data.first_name)) {
    errors.first_name = "first name is required";
  }

  if (isEmpty(data.last_name)) {
    errors.last_name = "last name is required";
  }

  if (isEmpty(data.linkedin_link)) {
    errors.linkedin_link = "linkedin profile is required";
  }

  if (isEmpty(data.email)) {
    errors.email = "email is required";
  }

  if (!isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errors.email = "please enter valid email";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
