import React from "react";

/**
 * InputFieldNumber Component
 * 
 * Overview:
 * The InputFieldNumber component is a reusable input field component specifically designed for numeric input. It provides a text input field with optional label, placeholder, and error message. It can be used to capture numeric values in forms or data entry fields.
 * 
 * Details:
 * - The component accepts various props to customize its behavior and appearance, such as containerClassName, label, id, name, placeholder, onChange, value, maxLength, autoFocus, error, isDisabled, isReadOnly, and isHideLabel.
 * - The component renders a div container with an optional label, an input field, and an optional error message.
 * - The label is rendered as a <label> element with a "for" attribute pointing to the input field's id or name.
 * - The input field is rendered as an <input> element with type="text" and pattern="[0-9]*" to enforce numeric input.
 * - The input field's value is controlled by the "value" prop, and any changes are propagated through the "onChange" prop.
 * - The input field supports optional features such as maxLength, autoComplete, autoFocus, isDisabled, and isReadOnly.
 * - The error message is rendered conditionally based on the "error" prop.
 * 
 * Dependencies:
 * The InputFieldNumber component does not have any external dependencies.
 */
function InputFieldNumber({
  containerClassName,
  label,
  id,
  name,
  placeholder,
  onChange,
  value,
  maxLength,
  autoFocus,
  error,
  isDisabled,
  isReadOnly,
  isHideLabel,
}) {
  return (
    <div className={containerClassName}>
      <div className={isHideLabel ? "d-none" : ""}>
        <label htmlFor={id ? id : name}>{label}</label>
      </div>
      <div
        className={
          isDisabled || isReadOnly
            ? "input-border-div input-border-div--disabled"
            : "input-border-div"
        }
      >
        <input
          type="text"
          pattern="[0-9]*"
          id={id ? id : name}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value === null ? "" : value}
          maxLength={maxLength}
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={isDisabled}
          readOnly={isReadOnly}
        />
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className="error-message opacity-0">error</p>
      )}
    </div>
  );
}

InputFieldNumber.defaultProps = {
  label: "",
  placeholder: "",
  maxLength: "",
  error: "",
  autoFocus: "",
  isDisabled: false,
  isReadOnly: false,
  isHideLabel: false,
};

export default InputFieldNumber;
