//Auth types

export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_ALL_USER_DATA = "SET_ALL_USER_DATA";
export const SET_CREATE_MENTOR = "SET_CREATE_MENTOR";
export const SET_CREATE_USER = "SET_CREATE_USER";

// backend validation errors
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// raise capital
export const SET_RAISE_CAPITAL_PROFILE_SUCCESS =
  "SET_RAISE_CAPITAL_PROFILE_SUCCESS";
export const SET_RAISE_CAPITAL_PROFILE_ACTIVATED =
  "SET_RAISE_CAPITAL_PROFILE_ACTIVATED";
export const SET_RAISE_CAPITAL_ORGANIZATIONS =
  "SET_RAISE_CAPITAL_ORGANIZATIONS";
export const RESET_RAISE_CAPITAL_ORGANIZATIONS =
  "RESET_RAISE_CAPITAL_ORGANIZATIONS";
export const SET_RAISE_CAPITAL_OFFERINGS = "SET_RAISE_CAPITAL_OFFERINGS";
export const RESET_RAISE_CAPITAL_OFFERINGS = "RESET_RAISE_CAPITAL_OFFERINGS";
export const SET_RAISE_CAPITAL_TEAM = "SET_RAISE_CAPITAL_TEAM";
export const RESET_RAISE_CAPITAL_TEAM = "RESET_RAISE_CAPITAL_TEAM";
export const SET_RAISE_CAPITAL_TAGS = "SET_RAISE_CAPITAL_TAGS";
export const RESET_RAISE_CAPITAL_TAGS = "RESET_RAISE_CAPITAL_TAGS";
export const SET_RAISE_CAPITAL_INDUSTRIES = "SET_RAISE_CAPITAL_INDUSTRIES";
export const SET_SOCIAL_IMAPCT_OPTIONS = "SET_SOCIAL_IMAPCT_OPTIONS";
export const SET_RAISE_CAPITAL_ALL_INVESTORS =
  "SET_RAISE_CAPITAL_ALL_INVESTORS";
export const RESET_RAISE_CAPITAL_ALL_INVESTORS =
  "RESET_RAISE_CAPITAL_ALL_INVESTORS";

export const SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED =
  "SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED";

// explore
export const SET_EXPLORE_ALL_ORGANIZATIONS = "SET_EXPLORE_ALL_ORGANIZATIONS";
export const SET_EXPLORE_ALL_OFFERINGS = "SET_EXPLORE_ALL_OFFERINGS";
export const SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT =
  "SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT";
export const SET_EXPLORE_OFFERINGS = "SET_EXPLORE_OFFERINGS";
export const RESET_EXPLORE_OFFERINGS = "RESET_EXPLORE_OFFERINGS";
export const RESERVE_AMOUNT = "RESERVE AMOUNT";

// website blogs
export const SET_WEBSITE_ALL_BLOGS = "SET_WEBSITE_ALL_BLOGS";

// discussions
export const SET_DISCUSSIONS_ALL = "SET_DISCUSSIONS_ALL";
export const SET_DISCUSSIONS_ALL_BY_OFFERING_ID =
  "SET_DISCUSSIONS_ALL_BY_OFFERING_ID";
export const RESET_DISCUSSIONS_ALL = "RESET_DISCUSSIONS_ALL";
export const RESET_DISCUSSIONS_ALL_BY_OFFERING_ID =
  "RESET_DISCUSSIONS_ALL_BY_OFFERING_ID";

// updates explore details
export const SET_UPDATES_EXPLORE_DETAILS = "SET_UPDATES_EXPLORE_DETAILS";
export const SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID =
  "SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID";
export const RESET_UPDATES_EXPLORE_DETAILS = "RESET_UPDATES_EXPLORE_DETAILS";
export const RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID =
  "RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID";

// invest
export const SET_INVEST_PROFILE_SUCCESS = "SET_INVEST_PROFILE_SUCCESS";
export const SET_INVEST_BY_USER_ID = "SET_INVEST_BY_USER_ID";
export const SET_INVEST_PORTFOLIO = "SET_INVEST_PORTFOLIO";

// follow
export const SET_FOLLOWERS_BY_USER_ID = "SET_FOLLOWERS_BY_USER_ID";
export const SET_ALL_FOLLOWERS_UPDATES = "SET_ALL_FOLLOWERS_UPDATES";
export const CLEAR_ALL_FOLLOWERS_UPDATES = "CLEAR_ALL_FOLLOWERS_UPDATES";

// home
export const SET_HOME_ALL_ARTICLES = "SET_HOME_ALL_ARTICLES";
export const SET_HOME_ALL_FEEDS = "SET_HOME_ALL_FEEDS";

// startup details
export const RESET_STARTUP_DETAILS = "RESET_STARTUP_DETAILS";

//proceed payment
export const CREATE_PARTY = "CREATE_PARTY";
export const SET_CREDIT_CARDS = "GET_CREDIT_CARDS";

//career page
export const CAREER_PAGE = "CAREER_PAGE";
//mentor
export const SET_MENTOR_BY_USER_ID = "SET_MENTOR_BY_USER_ID";
export const SET_ALL_MENTOR_Record = "SET_ALL_MENTOR_Record";
export const SET_CHAT_MENU_DATA = "SET_CHAT_MENU_DATA";

//news-letter
export const SET_NEWS_LETTER = "SET_NEWS_LETTER";
