import {
  SET_HOME_ALL_ARTICLES,
  SET_HOME_ALL_FEEDS,
  SET_NEWS_LETTER,
} from "./../types";

const initialState = {
  homeAllArticles: {},
  homeAllFeeds: {},
  homeAllNewsLetter: {},
};

/**
 * homeReducer
 * 
 * Overview:
 * This reducer handles the state for the home page of the web application. It manages the state related to articles, feeds, and newsletters displayed on the home page.
 * 
 * Details:
 * - The reducer handles actions of type SET_HOME_ALL_ARTICLES, SET_HOME_ALL_FEEDS, and SET_NEWS_LETTER.
 * - When SET_HOME_ALL_ARTICLES action is dispatched, it updates the homeAllArticles property of the state with the payload.
 * - When SET_HOME_ALL_FEEDS action is dispatched, it updates the homeAllFeeds property of the state with the payload.
 * - When SET_NEWS_LETTER action is dispatched, it updates the homeAllNewsLetter property of the state with the payload.
 * - If none of the above actions are dispatched, it returns the current state.
 * 
 * Usage:
 * This reducer is used in the web application to manage the state of the home page. It can be imported and used in the Redux store to handle actions related to the home page.
 * 
 * Dependencies:
 * - initialState: The initial state of the homeReducer.
 * - action: The action object dispatched to the reducer.
 */
export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_ALL_ARTICLES:
      return {
        ...state,
        homeAllArticles: action.payload,
      };

    case SET_HOME_ALL_FEEDS:
      return {
        ...state,
        homeAllFeeds: action.payload,
      };

    case SET_NEWS_LETTER:
      return {
        ...state,
        homeAllNewsLetter: action.payload,
      };

    default:
      return state;
  }
}
