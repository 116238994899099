import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * Contact Validation
 * 
 * Overview:
 * This module provides a function to validate contact form data. It checks if the email is valid, first name and last name are not empty, inquiry type is selected, and message is not empty.
 * 
 * Details:
 * - The function takes an object containing contact form data as input.
 * - It uses the Validator library to perform the validation checks.
 * - If any validation errors are found, they are stored in the 'errors' object.
 * - The function returns an object with two properties: 'errors' and 'isValid'.
 *   - 'errors' contains the validation errors, if any.
 *   - 'isValid' is a boolean indicating whether the form data is valid or not.
 * 
 * Dependencies:
 * - Validator library (imported externally)
 * - isEmpty function (assumed to be defined elsewhere)
 */
export const validateContact = (data) => {
  let errors = {};

  console.log(data);

  if (!Validator.isEmail(data.email)) {
    errors.email = "Please enter valid  email";
  }

  if (Validator.isEmpty(data.fname)) {
    errors.fname = "First name is required";
  }

  if (Validator.isEmpty(data.lname)) {
    errors.lname = "Last name is required";
  }

  //if (Validator.isEmpty(data.cname)) {
  //  errors.cname = "Company name is required";
  //}

  if (isEmpty(data.inquiry)) {
    errors.inquiry = "Inquiry type is required";
  }

  if (Validator.isEmpty(data.message)) {
    errors.message = "Message is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
