import React, { useState } from "react";
import SaveButton from "../common/SaveButton";
import TextareaField from "../common/TextareaField";
import RaiseCapitalDisplayUpdates from "./RaiseCapitalDisplayUpdates";

// api
import { useEffect } from "react";
import { postRaiseCapitalUpdate } from "../../../web-app-store/actions/updatesAction";
import { getRaiseCapitalOfferings } from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * RaiseCapitalAfterProfileSetupUpdatesTabpanel Component
 * 
 * Overview:
 * The RaiseCapitalAfterProfileSetupUpdatesTabpanel component is responsible for rendering a tab panel that allows users to post updates and display existing updates for a specific organization in the raise capital feature. It is a part of the larger UI-2 application.
 * 
 * Details:
 * - The component uses Redux for state management and dispatches actions to update the state.
 * - It uses the useDispatch and useSelector hooks from the react-redux library to access the Redux store.
 * - The component maintains local state using the useState hook to manage the input values and loading state.
 * - It includes a useEffect hook to fetch raise capital offerings when the raiseCapitalOrg state is updated.
 * - The handleChange function is used to update the input values in the local state.
 * - The handleSave function is triggered when the user clicks the "Post" button. It validates the input and dispatches an action to post the update.
 * - The component conditionally renders the input field and the RaiseCapitalDisplayUpdates component based on the raiseCapitalOfferings and raiseCapitalOrg states.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses the useDispatch and useSelector hooks from the react-redux library.
 * - useState: The component uses the useState hook to manage local state.
 * - useEffect: The component uses the useEffect hook to fetch raise capital offerings.
 * - isEmpty: The component uses the isEmpty function to check if the raiseCapitalOrg and values.updateDetails are empty.
 * - useDispatch: The component uses the useDispatch hook to dispatch actions.
 * - useSelector: The component uses the useSelector hook to access the Redux store.
 * - getRaiseCapitalOfferings: The component dispatches the getRaiseCapitalOfferings action to fetch raise capital offerings.
 * - postRaiseCapitalUpdate: The component dispatches the postRaiseCapitalUpdate action to post an update.
 * - TextareaField: The component uses the TextareaField component to render the input field.
 * - SaveButton: The component uses the SaveButton component to render the "Post" button.
 * - RaiseCapitalDisplayUpdates: The component renders the RaiseCapitalDisplayUpdates component to display existing updates.
 */
export default function RaiseCapitalAfterProfileSetupUpdatesTabpanel() {
  // api
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    updateDetails: "",
  });

  const [loading, setLoading] = useState(false);

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const callBackPostUpdateStatus = (status) => {
    if (status === 200) {
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (!isEmpty(values.updateDetails)) {
      if (!isEmpty(raiseCapitalOrg)) {
        setLoading(true);

        let userData = JSON.parse(localStorage.getItem("userData"));
        let formData = {
          user: { id: userData.id },
          organization: { id: raiseCapitalOrg[0].id },
          update_details: values.updateDetails,
        };

        dispatch(postRaiseCapitalUpdate(formData, callBackPostUpdateStatus));

        setValues({ updateDetails: "" });
      }
    }
  };

  return (
    <>
      {/* <div className="vl-mentor-updates-tabpanel-content">
      <div className="vl-mentor-updates-tabpanel-content__desc">
        <span className="font-20-roboto-bold vl-mentor-updates-tabpanel-content__title">
          Start Up Name
        </span>
        <span className="font-16-roboto-light vl-mentor-updates-tabpanel-content__text1">
          Invested amount
        </span>
      </div>
      <div>
        <span className="font-16-roboto-light vl-mentor-updates-tabpanel-content__text1">
          Lorem ipsum dolor sit amet, consectetur adipisci malesuada tellus.
          Lorem ipsum dolor sit amet, consectetur adipiscing
        </span>
      </div>
      <div className="vl-mentor-updates-tabpanel-content__dateTime">
        <span className="font-14-roboto-medium vl-mentor-updates-tabpanel-content__text2">
          Date
        </span>
        <span className="font-14-roboto-medium vl-mentor-updates-tabpanel-content__text2">
          .
        </span>
        <span className="font-14-roboto-medium vl-mentor-updates-tabpanel-content__text2">
          Time
        </span>
      </div>
      <hr className="w-100" />
    </div> */}
      {!isEmpty(raiseCapitalOfferings) &&
      (raiseCapitalOfferings[0].offering_status === "Offering_Posted" ||
        raiseCapitalOfferings[0].offering_status === "Accepting_Investment") ? (
        <>
          <div className="row mx-0 align-items-start vl-raise-capital-updates-post-details">
            <TextareaField
              isHideLabel={true}
              containerClassName="vl-form-input"
              labelClassName="raise-capital-label-company-info"
              name="updateDetails"
              placeholder="Post an update to share with your investors."
              value={values.updateDetails}
              onChange={handleChange}
            />

            <div className="vl-raise-capital-updates-post-details__post-btn-div">
              <SaveButton
                text="Post"
                handleOnClickSaveButton={handleSave}
                loading={loading}
              />
            </div>
          </div>
          {/* {console.log(raiseCapitalOrg)} */}
          {!isEmpty(raiseCapitalOrg) && (
            <RaiseCapitalDisplayUpdates orgId={raiseCapitalOrg[0].id} />
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
}
