export const chartColors = [
  "rgba(90,155,165,0.65)",
  "rgba(254,89,0,0.85)",
  "rgba(60,92,138,1)",
  // "#FE5900",
  // "#FFD500",
  // "#CC9933",
  // "rgba(90,155,165,0.65)",
  // "rgba(254,89,0,0.85)",
  // "rgba(60,92,138,1)",
  // "#FE5900",
  // "#FFD500",
  // "#CC9933",
  // "rgba(90,155,165,0.65)",
  // "rgba(254,89,0,0.85)",
  // "rgba(60,92,138,1)",
  // "#FE5900",
  // "#FFD500",
  // "#CC9933",
  // "rgba(90,155,165,0.65)",
  // "rgba(254,89,0,0.85)",
  // "rgba(60,92,138,1)",
  // "#FE5900",
  // "#FFD500",
  // "#CC9933",
  // "rgba(90,155,165,0.65)",
  // "rgba(254,89,0,0.85)",
  // "rgba(60,92,138,1)",
  // "#FE5900",
  // "#FFD500",
  // "#CC9933",
];
