import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import RaiseCapitalAfterProfileSetupUpdatesTabpanel from "./RaiseCapitalAfterProfileSetupUpdatesTabpanel";
import RaiseCapitalAfterProfileSetupProfileTabpanel from "./RaiseCapitalAfterProfileSetupProfileTabpanel";
import RaiseCapitalAfterProfileSetupInvestors from "./RaiseCapitalAfterProfileSetupInvestors";

/**
 * RaiseCapitalAfterProfileSetup Component
 *
 * Overview:
 * The RaiseCapitalAfterProfileSetup component is responsible for rendering the main page of the Raise Capital feature after the user has completed their profile setup. It includes tabs for Updates, Investors, and Profile, allowing the user to navigate between different sections of the feature.
 *
 * Details:
 * - The component uses the useEffect hook to remove the activeVLRaiseCapitalAfterProfileSetupPageTabIndex from localStorage when the component is unmounted.
 * - The component maintains the activeTabIndexVal state variable to keep track of the currently active tab index. It retrieves the initial value from localStorage and updates it when the user selects a different tab.
 * - The handleOnSelectTab function is called when the user selects a tab. It updates the activeTabIndexVal state variable and stores the new value in localStorage.
 * - The renderContent function renders the content of the component, including the tabs and their corresponding panels. It uses the react-tabs library to handle tab navigation.
 *
 * Usage:
 * To use the RaiseCapitalAfterProfileSetup component, import it from the specified file path and include it in the desired location within your application. Make sure to provide the necessary dependencies and props, if any.
 *
 * Dependencies:
 * - react: The component uses React to create and manage the UI elements.
 * - react-tabs: The component relies on the react-tabs library to handle tab navigation.
 * - RaiseCapitalAfterProfileSetupUpdatesTabpanel: This component is rendered within the Updates tab panel.
 * - RaiseCapitalAfterProfileSetupInvestors: This component is rendered within the Investors tab panel.
 * - RaiseCapitalAfterProfileSetupProfileTabpanel: This component is rendered within the Profile tab panel.
 */
export default function RaiseCapitalAfterProfileSetup() {
  useEffect(() => {
    /* on component will unmount */
    return () => {
      /* remove activeVLRaiseCapitalAfterProfileSetupPageTabIndex */
      localStorage.removeItem(
        "activeVLRaiseCapitalAfterProfileSetupPageTabIndex"
      );
    };
  }, []);

  /*================================================================
        renderContent
  ================================================================*/
  const [activeTabIndexVal, setActiveTabIndexVal] = useState(
    isNaN(
      parseInt(
        localStorage.getItem(
          "activeVLRaiseCapitalAfterProfileSetupPageTabIndex"
        )
      )
    )
      ? 0
      : parseInt(
          localStorage.getItem(
            "activeVLRaiseCapitalAfterProfileSetupPageTabIndex"
          )
        )
  );

  const handleOnSelectTab = (val) => {
    setActiveTabIndexVal(val);
    localStorage.setItem(
      "activeVLRaiseCapitalAfterProfileSetupPageTabIndex",
      val
    );
  };

  // renderContent
  const renderContent = () => {
    return (
      <>
        <div className="vl-tabs-explore vl-tabs-explore--mentor-mainPage raise_capital_page_wrap">
          <Tabs selectedIndex={activeTabIndexVal} onSelect={handleOnSelectTab}>
            <TabList>
              <Tab>Profile</Tab>
              <Tab>Updates</Tab>
              <Tab>INVESTORS</Tab>
            </TabList>
            <TabPanel>
              <div className="vl-mentor-mainPage-profile-tabpanel-content raise_capital_background">
                <RaiseCapitalAfterProfileSetupProfileTabpanel />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="vl-mentor-mainPage-updates-tabpanel-content">
                <RaiseCapitalAfterProfileSetupUpdatesTabpanel />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="vl-mentor-mainPage-profile-tabpanel-content">
                <RaiseCapitalAfterProfileSetupInvestors />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="vl-raise-capital-main-page">{renderContent()}</div>
    </>
  );
}
