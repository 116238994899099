import axios from "axios";
import {
  SET_ALL_MENTOR_Record,
  SET_CHAT_MENU_DATA,
  SET_MENTOR_BY_USER_ID,
} from "../types";
import { backendUrl } from "./config";
import Toast from "light-toast";

/**
 * GetMentorByUserId Action
 *
 * Overview:
 * Retrieves the mentor details associated with a specific user ID from the backend. It makes a GET request to the `/mentors?user=${userId}` endpoint, utilizing the user ID stored in localStorage. This action is crucial for fetching and displaying mentor information relevant to the current user within the application.
 *
 * Details:
 * - Fetches the mentor data by making an HTTP GET request to the backend with the user's ID.
 * - Dispatches an action to the Redux store with the type `SET_MENTOR_BY_USER_ID` and the retrieved data as payload, updating the application state.
 * - Implements basic error handling by logging any errors to the console.
 *
 * Dependencies:
 * - axios: Used for making the HTTP request.
 * - SET_MENTOR_BY_USER_ID: Redux action type used to update the store.
 * - backendUrl: Application's configuration value used to set the request endpoint.
 */
export const getMentorByUserId = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(`${backendUrl}/mentor?user=${userData?.id}`);
    if (data) {
      dispatch({
        type: SET_MENTOR_BY_USER_ID,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * GetAllMentors Action
 *
 * Overview:
 * Fetches a list of all mentors from the backend and updates the Redux store with this data. This action facilitates the display of mentor information across the application, enabling users to browse through available mentors.
 *
 * Details:
 * - Executes a GET request to the `/mentors` endpoint to retrieve all mentor records.
 * - Upon successful retrieval, dispatches an action to the Redux store with the type `SET_ALL_MENTOR_Record` and the data as payload, thus updating the state with the list of mentors.
 * - Incorporates error handling by logging exceptions to the console.
 *
 * Dependencies:
 * - axios: For executing HTTP requests.
 * - SET_ALL_MENTOR_Record: Action type for Redux store updates.
 * - backendUrl: Config value determining the backend endpoint.
 */
export const getAllMentors = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/mentor`);

    if (data) {
      dispatch({
        type: SET_ALL_MENTOR_Record,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * CreateMentor Action
 *
 * Overview:
 * Handles the creation of a new mentor record by sending the provided mentor data to the backend. Upon successful creation, it displays a confirmation message and triggers a state update by re-fetching the current user's mentor information.
 *
 * Details:
 * - Makes an HTTP POST request to the `/mentors` endpoint with the mentor data.
 * - Utilizes light-toast to display a "Information saved" message upon success.
 * - Refreshes the mentor information for the user by dispatching the `getMentorByUserId` action, ensuring the UI reflects the latest data.
 * - Calls a provided callback function with the HTTP status code, indicating the operation's result.
 * - Implements error handling through console logging.
 *
 * Dependencies:
 * - axios and light-toast for requests and user feedback.
 * - backendUrl for determining the endpoint.
 */
export const createMentor =
  (mentorData, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/mentor`,
        mentorData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getMentorByUserId());
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

export const uploadImage = (image, callBackStatus) => async (dispatch) => {
  try {
    let { data, status } = await axios.post(`${backendUrl}/upload`, image);
    return data;
    // if (data) {
    //   Toast.info("Information saved", 3000);
    //   dispatch(getMentorByUserId());
    //   callBackStatus(status);
    // }
  } catch (err) {
    console.log(err);
  }
};

/**
 * UpdateMentor Action
 *
 * Overview:
 * Updates the details of an existing mentor record identified by the mentor ID. It sends updated information to the backend and, upon success, notifies the user and refreshes the mentor data associated with the current user.
 *
 * Details:
 * - Executes an HTTP PUT request to `/mentors/${mentorId}` with the updated form data.
 * - Displays a success message using light-toast upon completion.
 * - Initiates a refresh of the user's mentor information by dispatching the `getMentorByUserId` action.
 * - Utilizes a callback to convey the operation's HTTP status to the caller.
 * - Includes error handling by logging any issues to the console.
 *
 * Dependencies:
 * - axios for making HTTP requests, light-toast for user feedback, and backendUrl for endpoint configuration.
 */
export const updateMentor =
  (formData, mentorId, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/mentor/${mentorId}`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getMentorByUserId());
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * GetChatMenuData Action
 *
 * Overview:
 * Fetches chat menu data for a given ID from the backend, updating the Redux store with this information. This action is key for populating chat-related UI components with data specific to the user's interactions or requirements.
 *
 * Details:
 * - Makes a GET request to `/chat-menu/${id}` to obtain chat menu data.
 * - Dispatches an action to update the Redux store with the type `SET_CHAT_MENU_DATA` and the retrieved data as payload.
 * - Implements basic error handling by logging errors to the console, ensuring that any retrieval issues are noted.
 *
 * Dependencies:
 * - axios for HTTP requests.
 * - SET_CHAT_MENU_DATA as the Redux action type.
 * - backendUrl for backend endpoint configuration.
 */
export const getChatMenuData = (id) => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/chat-menu/${id}`);
    if (data) {
      dispatch({
        type: SET_CHAT_MENU_DATA,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
