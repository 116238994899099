import React, { Fragment, useEffect, useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import SaveAndNextButton from "../common/SaveAndNextButton";
import BackButton from "../common/BackButton";
import SkipButton from "../common/SkipButton";
// import TextareaField from "../common/TextareaField";

// api
import {
  getRaiseCapitalTeam,
  createRaiseCapitalTeam,
  updateRaiseCapitalTeam,
  deleteTeamMemberById,
  raiseCapitalSaveProfileSuccessAction,
  getRaiseCapitalTags,
  getRaiseCapitalOfferings,
  createRaiseCapitalOrganization,
  updateRaiseCapitalOrganization,
} from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { validateRaiseCapitalTeam } from "./../../../web-app-store/validations/raise-capital/raiseCapitalTeam";
import SaveButton from "../common/SaveButton";
import { validateRaiseCapitalOrgDataFundraiserNotEmpty } from "../../../web-app-store/validations/raise-capital/raiseCapitalOrganizationData";
import { validateRaiseCapitalOrgDataNotEmptyMoreInfo } from "../../../web-app-store/validations/raise-capital/raiseCapitalOrgDataNotEmptyMoreInfo";
import ImageCropModal from "../common/ImageCropModal";
import raiseCapitalTeamDefault from "../../../assets/imgs/desktop/common/raise-capital-team-default.png";

/**
 * RaiseCapitalTeam Component
 *
 * Overview:
 * The RaiseCapitalTeam component is responsible for managing the team details in the Raise Capital feature. It allows users to add, edit, and remove team members. The component communicates with the backend API to save and retrieve team data.
 *
 * Details:
 * - The component uses Redux for state management and dispatches actions to interact with the backend API.
 * - It includes form validation to ensure that the required fields are filled out correctly.
 * - The component renders a list of member inputs, where users can enter the member's name and email address.
 * - Users can add more members by clicking the "Add More Members" button and remove members by clicking the "Remove" button next to each member.
 * - The component also includes a "Save and Next" button to save the team details and proceed to the next step.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component connects to the Redux store.
 * - useDispatch: The component uses the useDispatch hook to dispatch actions.
 * - useSelector: The component uses the useSelector hook to access the Redux store.
 * - useState: The component uses the useState hook to manage component state.
 * - useEffect: The component uses the useEffect hook to perform side effects.
 * - isEmpty: The component uses the isEmpty function to check if an object is empty.
 * - validateRaiseCapitalTeam: The component uses the validateRaiseCapitalTeam function for form validation.
 * - createRaiseCapitalTeam: The component uses the createRaiseCapitalTeam action to create a new team.
 * - updateRaiseCapitalTeam: The component uses the updateRaiseCapitalTeam action to update an existing team.
 * - deleteTeamMemberById: The component uses the deleteTeamMemberById action to delete a team member.
 */
export default function RaiseCapitalTeam({ handleOnSelectTab }) {
  // api
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [memberArray, setMemberArray] = useState([
    {
      organization: {
        id: "",
      },
      user: { id: "" },
      memberName: "",
      email: "",
      discordId: "",
      twitterId: "",
      gitHubId: "",
      // designation: "",
      // bio: "",
      memberId: "",
      fileData: {},
      imgUrl: "",
      cropImgBlob: {},
    },
  ]);

  const [isOpenModals, setIsOpenModals] = useState(
    new Array(memberArray?.length).fill(false)
  );
  const [clickIndex, setClickIndex] = useState();

  const newObjStructure = {
    organization: {
      id: "",
    },
    user: { id: "" },
    memberName: "",
    email: "",
    // designation: "",
    // bio: "",
    memberId: "",
    discordId: "",
    twitterId: "",
    gitHubId: "",
  };

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  const raiseCapitalTags = useSelector(
    (state) => state.raiseCapital.raiseCapitalTags
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalTeam(raiseCapitalOrg[0].id));
      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );

  useEffect(() => {
    // console.log(raiseCapitalOrg, raiseCapitalTeam);

    if (!isEmpty(raiseCapitalTeam)) {
      const newObjStructure = {
        organization: {
          id: "",
        },
        user: { id: "" },
        memberName: "",
        email: "",
        // designation: "",
        // bio: "",
        memberId: "",
      };

      let newMemberArray = [...raiseCapitalTeam];

      let arrayLengthDiff = raiseCapitalTeam.length - newMemberArray.length;

      while (arrayLengthDiff > 0) {
        newMemberArray.push(newObjStructure);
        arrayLengthDiff = arrayLengthDiff - 1;
      }

      raiseCapitalTeam.map((data, index) => {
        newMemberArray[index].memberId = data.id;
        newMemberArray[index].memberName = data.full_name;
        newMemberArray[index].email = data.email;
        newMemberArray[index].fileData = data.profile_image;
        newMemberArray[index].imgUrl = data.profile_image?.url || "";
        newMemberArray[index].cropImgBlob = {};
        newMemberArray[index].discordId = data.discordId;
        newMemberArray[index].twitterId = data.twitterId;
        newMemberArray[index].gitHubId = data.gitHubId;
        // newMemberArray[index].designation = data.designation;
        // newMemberArray[index].bio = data.details;
        return newMemberArray;
      });

      setMemberArray(newMemberArray);
    }
  }, [raiseCapitalTeam]);

  /*==========================
             handler
      ===========================*/

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      if (!isEmpty(raiseCapitalOrg)) {
        if (!isEmpty(raiseCapitalTags)) {
          const { isValid } = validateRaiseCapitalOrgDataNotEmptyMoreInfo(
            raiseCapitalOrg[0]
          );
          if (
            isValid &&
            !isEmpty(raiseCapitalTags[0].market_tags) &&
            !raiseCapitalOrg[0].company_status
          ) {
            if (!isEmpty(raiseCapitalOfferings)) {
              const { isValid } = validateRaiseCapitalOrgDataFundraiserNotEmpty(
                raiseCapitalOfferings[0]
              );

              if (isValid) {
                dispatch(raiseCapitalSaveProfileSuccessAction(true));
              }
            }
          }
        }
      }
    } else {
      dispatch(raiseCapitalSaveProfileSuccessAction(false));
    }
  };

  const [frontEndErrors, setFrontEndErrors] = useState({});

  const handleOnClickSaveAndNext = async () => {
    const { errors, isValid } = validateRaiseCapitalTeam(memberArray);

    if (!isValid) {
      setFrontEndErrors(errors);
      // console.log(errors);
    }

    if (isValid) {
      setFrontEndErrors({});
      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userData"));
      let formDataNew = new FormData();

      let orgId = raiseCapitalOrg[0].id;
      let userId = userData.id;

      let newRaiseCapitalTeam = [...raiseCapitalTeam];

      let arrayLengthDiff = memberArray.length - raiseCapitalTeam.length;

      while (arrayLengthDiff > 0) {
        newRaiseCapitalTeam.push(newObjStructure);
        arrayLengthDiff = arrayLengthDiff - 1;
      }

      memberArray.map((data, index) => {
        newRaiseCapitalTeam[index].full_name = data.memberName;
        newRaiseCapitalTeam[index].email = data.email;
        newRaiseCapitalTeam[index].discordId = data.discordId;
        newRaiseCapitalTeam[index].twitterId = data.twitterId;
        newRaiseCapitalTeam[index].gitHubId = data.gitHubId;
        // newRaiseCapitalTeam[index].designation = data.designation;
        // newRaiseCapitalTeam[index].details = data.bio;
        return newRaiseCapitalTeam;
      });

      let finalArray = [];

      memberArray.forEach((element) => {
        // console.log(element);
        finalArray.push({
          full_name: element.memberName,
          email: element.email,
          // designation: element.designation,
          // details: element.bio,
          organization: { id: orgId },
          user: { id: userId },
          memberId: element.memberId,
          discordId: element.discordId,
          twitterId: element.twitterId,
          gitHubId: element.gitHubId,
          fileData: element?.fileData,
          cropImgBlob: element?.cropImgBlob,
        });
        // if (isEmpty(element?.cropImgBlob)) {
        //   formDataNew.append("files.profile_image", element?.fileData);
        // } else {
        //   formDataNew.append("files.profile_image", element?.cropImgBlob);
        // }
      });
      let finalArray1 = [];

      memberArray.forEach((element) => {
        // console.log(element);
        finalArray1.push({
          full_name: element.memberName,
          email: element.email,
          // designation: element.designation,
          // details: element.bio,
          organization: { id: orgId },
          user: { id: userId },
          memberId: element.memberId,
          fileData: element.fileData,
          cropImgBlob: element.cropImgBlob,
          discordId: element.discordId,
          twitterId: element.twitterId,
          gitHubId: element.gitHubId,
        });
      });

      // console.log(finalArray);

      // if (!isEmpty(raiseCapitalOrg) && isEmpty(raiseCapitalTeam)) {
      //   finalArray.forEach(async (ele) => {
      //     if (isEmpty(ele?.cropImgBlob)) {
      //       formDataNew.append("files.profile_image", ele?.fileData);
      //     } else {
      //       formDataNew.append("files.profile_image", ele?.cropImgBlob);
      //     }
      //     formDataNew.append("data", JSON.stringify(ele));
      //     await dispatch(createRaiseCapitalTeam(formDataNew, orgId));
      //   });
      // } else {
      // }
      // console.log(finalArray);

      finalArray1.forEach((ele) => {
        let formDataNew = new FormData();
        let formData = {
          full_name: ele.full_name,
          email: ele.email,
          discordId: ele.discordId,
          twitterId: ele.twitterId,
          gitHubId: ele.gitHubId,
          // designation: ele.designation,
          // details: ele.details,
          organization: { id: orgId },
          user: { id: userId },
        };
        if (isEmpty(ele?.cropImgBlob)) {
          formDataNew.append("files.profile_image", ele?.fileData);
        } else {
          formDataNew.append("files.profile_image", ele?.cropImgBlob);
        }

        formDataNew.append("data", JSON.stringify(formData));
        // console.log(formDataNew);

        if (ele.memberId === "") {
          // if (isEmpty(ele?.cropImgBlob)) {
          //   formDataNew.append(
          //     `files.${ele.fileData?.name.replace(/\.[^.]+$/, "")}`,
          //     ele?.fileData,
          //     ele?.fileData?.name
          //   );
          // } else {
          //   formDataNew.append(
          //     `files.${ele?.cropImgBlob?.name.replace(/\.[^.]+$/, "")}`,
          //     ele?.cropImgBlob,
          //     ele?.cropImgBlob?.name
          //   );
          // }
          dispatch(createRaiseCapitalTeam(formDataNew, orgId));
        } else {
          dispatch(updateRaiseCapitalTeam(formDataNew, ele.memberId));
        }
      });

      // console.log("update team");

      let newData = {
        user: { id: userData.id },
      };
      let formData = new FormData();
      formData.append("data", JSON.stringify(newData));
      if (isEmpty(raiseCapitalOrg)) {
        await dispatch(
          createRaiseCapitalOrganization(formData, null, null, callBackStatus)
        );
      } else {
        await dispatch(
          updateRaiseCapitalOrganization(
            formData,
            raiseCapitalOrg[0].id,
            null,
            null,
            callBackStatus
          )
        );
      }
    }
  };

  const handleOnClickSaveCropImg = (val, imgUrl) => (e) => {
    setFrontEndErrors({});
    e.preventDefault();
    let memberArrayNew = [...memberArray];
    memberArrayNew[clickIndex].fileData = val;
    memberArrayNew[clickIndex].imgUrl = imgUrl;
    setMemberArray(memberArrayNew);
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[clickIndex] = false;
    setIsOpenModals(newIsOpenModals);
  };

  const getBlobDataFromImageCrop = (data) => {
    let memberArrayNew = [...memberArray];
    memberArrayNew[clickIndex].cropImgBlob = data;
    setMemberArray(memberArrayNew);
  };

  const handleChangeMemberName = (index) => (e) => {
    setFrontEndErrors({});
    let memberArrayNew = [...memberArray];
    memberArrayNew[index].memberName = e.target.value;
    setMemberArray(memberArrayNew);
    //console.log(memberArrayNew[index].memberName, "member name");
  };

  const handleOpenModal = (index) => {
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[index] = true;
    setIsOpenModals(newIsOpenModals);
    setClickIndex(index);
  };

  const handleCloseModal = (index) => {
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[index] = false;
    setIsOpenModals(newIsOpenModals);
    setClickIndex(index);
  };

  const handleChangeEmail = (index) => (e) => {
    setFrontEndErrors({});
    // console.log(email, index);
    let memberArrayNew = [...memberArray];
    memberArrayNew[index].email = e.target.value;
    setMemberArray(memberArrayNew);
    //console.log(memberArrayNew[index].email, "email");
  };
  const handleChangeDiscordId = (index) => (e) => {
    setFrontEndErrors({});
    let memberArrayNew = [...memberArray];
    memberArrayNew[index].discordId = e.target.value;
    setMemberArray(memberArrayNew);
  };
  const handleChangeTwitterId = (index) => (e) => {
    setFrontEndErrors({});
    let memberArrayNew = [...memberArray];
    memberArrayNew[index].twitterId = e.target.value;
    setMemberArray(memberArrayNew);
  };
  const handleChangeGitHubId = (index) => (e) => {
    setFrontEndErrors({});
    let memberArrayNew = [...memberArray];
    memberArrayNew[index].gitHubId = e.target.value;
    setMemberArray(memberArrayNew);
  };

  // const handleChangeDesignation = (index) => (e) => {
  //   let memberArrayNew = [...memberArray];
  //   memberArrayNew[index].designation = e.target.value;
  //   setMemberArray(memberArrayNew);
  // };

  // const handleChangeBio = (index) => (e) => {
  //   let memberArrayNew = [...memberArray];
  //   memberArrayNew[index].bio = e.target.value;
  //   setMemberArray(memberArrayNew);
  // };

  const handleOnClickAddMoreMembers = () => {
    let memberArrayNew = [...memberArray];

    // console.log(memberArrayNew);

    memberArrayNew.push(newObjStructure);
    // console.log(memberArrayNew);
    setMemberArray(memberArrayNew);
  };

  const handleOnClickRemoveMembers = (index, memberId) => (e) => {
    setFrontEndErrors({});
    // console.log(memberId);
    let memberArrayNew = [...memberArray];
    memberArrayNew.splice(index, 1);

    // memberArrayNew.map(
    //   (data, index) =>
    //     (memberArrayNew[index].memberName = `Member ${index + 1}`)
    // );
    setMemberArray(memberArrayNew);

    if (memberId !== undefined) {
      dispatch(deleteTeamMemberById(memberId));
    }
  };

  /*==========================================
                    render
     ==========================================*/

  // console.log(frontEndErrors);
  return (
    <div className="raise-capital-more-info-div common-input-capital raise-capital-more-info-div-team raise_cap_team_wrap">
      <h5 className="raise-capital-more-info-label ">Add Your Team</h5>

      <div className="vl-mentor-areas-fields-main-block team-edit-profile-main">
        {memberArray.map((data, index) => (
          <Fragment key={index}>
            <div className="mx-0 justify-content-between align-items-center pt-32 edit-team-main">
              <div className="">
                <ImageCropModal
                  containerClassName="upload-img__mainBlock upload-img__mainBlock--investor upload-image-profile"
                  buttonName="Upload Photo"
                  error={
                    !isEmpty(frontEndErrors[index]) &&
                    frontEndErrors[index].imgUrl
                  }
                  imgClass="upload-profile-img"
                  fileData={data.imgUrl}
                  handleOnClickSaveCropImg={handleOnClickSaveCropImg}
                  getBlobDataFromImageCrop={getBlobDataFromImageCrop}
                  isOpenModal={isOpenModals[index]}
                  index={index}
                  handleOpenModal={() => handleOpenModal(index)}
                  handleCloseModal={() => handleCloseModal(index)}
                  btnClass="upload-button"
                  isHideButton={true}
                  defaultImage={raiseCapitalTeamDefault}
                  defaultClass=""
                  // isError={true}
                />
              </div>
              <div className="edit_inputs_wrap">
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="Member Name"
                    id={`memberName${index}`}
                    name="memberName"
                    value={data.memberName || ""}
                    onChange={handleChangeMemberName(index)}
                    type="text"
                    error={
                      !isEmpty(frontEndErrors[index]) &&
                      frontEndErrors[index].memberName
                    }
                    // isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="Email Address"
                    id={`email${index}`}
                    name="email"
                    value={data.email || ""}
                    onChange={handleChangeEmail(index)}
                    type="email"
                    error={
                      !isEmpty(frontEndErrors[index]) &&
                      frontEndErrors[index].email
                    }
                    // isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    placeholder="Enter Discord ID (e.g username#1234)"
                    label="Discord ID"
                    id={`discordId${index}`}
                    name="discordId"
                    value={data.discordId || ""}
                    onChange={handleChangeDiscordId(index)}
                    type="text"
                    // isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="X"
                    placeholder="Enter Twitter handle (e.g @username or URL)"
                    id={`twitterId${index}`}
                    name="twitterId"
                    value={data.twitterId || ""}
                    onChange={handleChangeTwitterId(index)}
                    type="text"
                    // isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="GitHub ID"
                    placeholder="Enter GitHub username or URL"
                    id={`gitHubId${index}`}
                    name="text"
                    value={data.gitHubId || ""}
                    onChange={handleChangeGitHubId(index)}
                    type="text"
                    // isError={true}
                  />
                </div>
              </div>
            </div>
            {index >= 1 && (
              <div className="row mx-0 vl-mentor-areas-fields-row__colm3">
                <button
                  className="vl-mentor-remove-area-field-button"
                  onClick={handleOnClickRemoveMembers(index, data.id)}
                >
                  Remove
                </button>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <div className="row mx-0 pt-15 pb-20">
        <button
          className="vl-mentor-add-area-field-button"
          onClick={handleOnClickAddMoreMembers}
        >
          + Add more members
        </button>
      </div>
      <div className="d-flex mx-0 justify-content-end">
        <BackButton handleOnClickBack={() => handleOnSelectTab(2)} />

        {/* <SkipButton handleOnClickSkipButton={handleOnClickSkip} /> */}
        {/* {isEmpty(raiseCapitalOrg) ? (
          <SaveAndNextButton
            isDisabled={true}
            handleOnClickSaveAndNext={handleOnClickSaveAndNext}
          />
        ) : (
          <SaveAndNextButton
            handleOnClickSaveAndNext={handleOnClickSaveAndNext}
            loading={loading}
          />
        )} */}
        <SaveButton
          handleOnClickSaveButton={handleOnClickSaveAndNext}
          loading={loading}
          extraClassName="save-button"
        />
      </div>
    </div>
  );
}
