import axios from "axios";
import { backendUrl, landingpageBackendUrl } from "./config";
import Toast from "light-toast";

/*=============================================
             Contact Us Action
==============================================*/
/**
 * contactUsAction Function
 * 
 * Overview:
 * Sends user's contact form data to a specified backend endpoint using a POST request. Upon successful data submission, displays a confirmation toast to the user and executes a callback function with the response status code. This function is integral for enabling users to reach out via the application's contact form.
 * 
 * Details:
 * - Makes an HTTP POST request to the `/api/v1/contact_us` endpoint of the landing page backend, sending the form data received as an argument.
 * - Utilizes `light-toast` to provide immediate feedback to the user, indicating that their message has been received.
 * - Executes a provided callback function with the HTTP status code upon success, allowing for further action or handling within the caller context.
 * - Error handling is implemented by logging the error to the console if the request fails.
 * 
 * Dependencies:
 * - axios for making HTTP requests.
 * - light-toast for displaying feedback messages.
 * - landingpageBackendUrl from the application's configuration for determining the endpoint.
 */
// https://cors-anywhere.herokuapp.com

export const contactUsAction = (formData, callBack) => async (dispatch) => {
  try {
    let data = await axios.post(
      `${landingpageBackendUrl}/api/v1/contact_us`,
      formData
    );
    if (data) {
      Toast.info("Thanks, we will get in touch soon", 3000);
      callBack(data.status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*=============================================
             News Letter Action
==============================================*/
/**
 * NewsLetterAction Component
 * 
 * Overview:
 * The NewsLetterAction component is responsible for managing the subscription process of users to the newsletter. It sends the user's subscription details to a specified backend endpoint via an HTTP POST request. Upon successful subscription, it acknowledges the action with a success message using light-toast. This component enhances user engagement by enabling easy subscription to updates or newsletters.
 * 
 * Details:
 * - Executes an HTTP POST request to the `/subscribe-newsletters` endpoint with the subscription data.
 * - Displays a "Subscribed successfully" message using light-toast upon successful operation.
 * - Calls a provided callback function with the response status code on success, facilitating further actions like navigation or confirmation messages.
 * - Implements error handling by executing an error callback with the HTTP status code, allowing for customized error responses.
 * - Relies on the `backendUrl` from the application's configuration for determining the subscription endpoint, showing dynamic endpoint usage based on the application structure.
 * 
 * Dependencies:
 * - axios: Utilized for HTTP requests to the backend.
 * - light-toast: Used to provide user feedback through toast messages.
 * - backendUrl: Config value used to define the backend endpoint for newsletter subscriptions.
 */
export const newsLetterAction = (formData, callBack, errorCallback) => async (
  dispatch
) => {
  try {
    let data = await axios.post(
      // `${landingpageBackendUrl}/api/v1/subscribe_newsletter`,
      `${backendUrl}/subscribe-newsletters`,
      formData
    );
    if (data) {
      Toast.info("Subscribed successfully", 3000);
      callBack(data.status);
    }
  } catch (err) {
    // console.log(err.response.status);
    errorCallback(err.response.status);
  }
};
