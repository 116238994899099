import isEmpty from "../is-empty";

/**
 * validateInvestDataNotEmptyPortfolio Function
 * 
 * Overview:
 * This function is used to validate the data object for the invest form in the portfolio section. It checks if the required fields in the data object are empty or not.
 * 
 * Details:
 * - The function takes a data object as input and checks if the following fields are empty:
 *   - investor_type
 *   - revenue_stage
 *   - company_investment_stage
 *   - part_of_investment_group
 *   - avg_amt_inv_last_5
 *   - number_private_investments
 * - If any of these fields are empty, it sets the "errors.e" property to "error four".
 * - The function returns an object with the following properties:
 *   - errors: an object containing the error message, if any.
 *   - isValid: a boolean value indicating if the data object is valid or not.
 * 
 * Dependencies:
 * This function does not have any external dependencies.
 */
export const validateInvestDataNotEmptyPortfolio = (data) => {
  let errors = {};

  // console.log(data);

  if (
    isEmpty(data.investor_type) ||
    isEmpty(data.revenue_stage) ||
    isEmpty(data.company_investment_stage) ||
    isEmpty(data.part_of_investment_group) ||
    isEmpty(data.avg_amt_inv_last_5) ||
    isEmpty(data.number_private_investments)
  ) {
    errors.e = "error four";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
