import { Button, Image } from "react-bootstrap";
import DropdownSelectCommon from "../../../../../web-app-components/desktop/common/DropdownSelectCommon";
import InputFieldEmailTextPassword from "../../../../../web-app-components/desktop/common/InputFieldEmailTextPassword";
import SaveButton from "../../../../../web-app-components/desktop/common/SaveButton";
import TextareaField from "../../../../../web-app-components/desktop/common/TextareaField";
import {
  liveProfileOptions,
  yearsDropDown,
} from "../../../../../web-app-components/desktop/common/allDropdownOptions";
import Select from "react-select";
import "./style.scss";
import Footer from "../../../header-footer/Footer";
import isEmpty from "../../../../../web-app-store/validations/is-empty";
import ImageCropModal from "../../../../../web-app-components/desktop/common/ImageCropModal";

const MentorProfileInput = (props) => {
  const {
    isEdit,
    isDisabled,
    areaOfExpertiseArray,
    frontEndErrors,
    values,
    isOpenModals,
    handleIndustryChange,
    handleChange,
    handleChangeLiveProfile,
    handleSave,
    handleAddMoreAreas,
    handleRemoveAreaOfExpertise,
    handleEdit,
    handleOnClickSaveCropImg,
    getBlobDataFromImageCrop,
    handleCloseModal,
    handleOpenModal,
    loading,
  } = props;
  return (
    <div
      className={`mentor-details-main ${
        isEdit ? "mentor-details-none" : "mentor-details-margin"
      }`}
    >
      <div className="mentor-details-wrap mentor_inputs_wrap">
        <h5 className="details-title">Mentor Bio</h5>
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital about-text-area"
          name="bio"
          value={values.bio}
          onChange={handleChange}
          placeholder="Enter a brief bio about yourself"
          error={frontEndErrors.bio}
          isHideLabel={true}
          isDisabled={isDisabled}
        />
        <h5 className="details-title">Professional Details</h5>
        <div className="row gx-3">
          <div className="col-12 col-md-4">
            <div className=" profession-input-main profession_input_main">
              <InputFieldEmailTextPassword
                containerClassName="vl-form-input profession-input"
                label="Job Title"
                name="job_title"
                value={values.job_title}
                onChange={handleChange}
                type="text"
                placeholder="e.g: Senior Software Engineer"
                error={frontEndErrors.job_title}
                isDisabled={isDisabled}
              />
            </div>
          </div>
          <div className="col-12 col-md-4 ">
            <div className="profession-input-main profession_input_main ">
              <InputFieldEmailTextPassword
                containerClassName="vl-form-input profession-input"
                label="Company"
                name="company"
                value={values.company}
                onChange={handleChange}
                type="text"
                placeholder="e.g: Tech Crop"
                error={frontEndErrors.company}
                isDisabled={isDisabled}
              />
            </div>
          </div>

          <div className=" col-12 col-md-4 ">
            <div className="profession-input-main profession_input_main">
              <InputFieldEmailTextPassword
                containerClassName="vl-form-input profession-input"
                label="Linkedin Profile"
                name="linkedin_link"
                value={values.linkedin_link}
                onChange={handleChange}
                type="text"
                placeholder="Enter Linkedin URL"
                error={frontEndErrors.linkedin_link}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
        <div className="mentor-profile-inputs mentor_wrap_profile">
          <h5 className="details-title">Areas of Expertise</h5>
          <div
            className={`${
              values?.sectors_of_interest?.length > 1
                ? "vl-form-input vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect"
                : "vl-form-input vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect"
            }`}
          >
            {areaOfExpertiseArray?.map((ele, i) => {
              const {
                name,
                type,
                options,
                isSearchable,
                placeholder,
                label,
                id,
              } = ele;
              return (
                <div className="mentor-profile-inputs-row mentor_profile_wrap">
                  {/* <div className="">
                    {isDisabled ? (
                      <Image
                        src={values.sectors_of_interest[i]?.imgUrl}
                        alt={values.sectors_of_interest[i]?.areaOfExpertise}
                        className="mentor_area_main"
                      />
                    ) : (
                      <ImageCropModal
                        containerClassName="upload-img__mainBlock upload-img__mainBlock--investor upload-image-profile upload_images_cloud_main"
                        buttonName="Upload Photo"
                        error={
                          !isEmpty(frontEndErrors.sectors_of_interest) &&
                          !isEmpty(
                            frontEndErrors?.sectors_of_interest[i]?.imgUrl
                          ) &&
                          frontEndErrors?.sectors_of_interest[i].imgUrl
                        }
                        imgClass="upload-profile-img "
                        fileData={values.sectors_of_interest[i]?.imgUrl}
                        handleOnClickSaveCropImg={handleOnClickSaveCropImg}
                        getBlobDataFromImageCrop={getBlobDataFromImageCrop}
                        isOpenModal={isOpenModals[i]}
                        index={i}
                        handleOpenModal={() => handleOpenModal(i)}
                        handleCloseModal={() => handleCloseModal(i)}
                        btnClass="upload-button"
                        isHideButton={true}
                      />
                    )}
                  </div> */}
                  <div className="mentor_profile_input_main">
                    <div className="mentor-profile-inputs-col mentor_profiles_inputs">
                      <DropdownSelectCommon
                        value={values.sectors_of_interest[i]?.areaOfExpertise}
                        onChange={(e) =>
                          handleIndustryChange(e, i, "areaOfExpertise")
                        }
                        options={options}
                        className={`${
                          values?.sectors_of_interest?.length > 1
                            ? "vl-select-container select_input_dropdown  select-industry-dropdown "
                            : "vl-select-container select_input_dropdown mentor-profile-dropdown"
                        }`}
                        classNamePrefix="vl-select"
                        placeholder={placeholder}
                        isSearchable={isSearchable}
                        // label={`${label} ${id}`}
                        error={
                          !isEmpty(frontEndErrors.sectors_of_interest) &&
                          !isEmpty(
                            frontEndErrors?.sectors_of_interest[i]
                              ?.areaOfExpertise
                          )
                            ? frontEndErrors?.sectors_of_interest[i]
                                ?.areaOfExpertise
                            : ""
                        }
                        isDisabled={isDisabled}
                      />
                    </div>
                    <div className="mentor-profile-inputs-col mentor_profiles_inputs">
                      <DropdownSelectCommon
                        value={values?.sectors_of_interest[i]?.yearsOfExpertise}
                        onChange={(e) =>
                          handleIndustryChange(e, i, "yearsOfExpertise")
                        }
                        options={yearsDropDown}
                        className={`${
                          values?.sectors_of_interest?.length > 1
                            ? "vl-select-container select-industry-dropdown "
                            : "vl-select-container mentor-profile-dropdown"
                        }`}
                        classNamePrefix="vl-select"
                        placeholder={"Years"}
                        label={"Years of Experience"}
                        error={
                          !isEmpty(frontEndErrors.sectors_of_interest) &&
                          !isEmpty(
                            frontEndErrors?.sectors_of_interest[i]
                              ?.yearsOfExpertise
                          )
                            ? frontEndErrors?.sectors_of_interest[i]
                                ?.yearsOfExpertise
                            : ""
                        }
                        isDisabled={isDisabled}
                      />
                    </div>
                  </div>
                  <div className="mentor-profile-inputs-col mentor_cancel_icon">
                    {areaOfExpertiseArray?.length > 1 && (
                      <Button
                        onClick={() => handleRemoveAreaOfExpertise(id, i)}
                        className="minus-main"
                        disabled={isDisabled}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}

            <Button
              onClick={handleAddMoreAreas}
              className="add-more-button"
              disabled={isDisabled}
            >
              + Add more Areas{" "}
            </Button>
          </div>
          <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
            <Select
              value={values.live_profile}
              onChange={handleChangeLiveProfile}
              options={liveProfileOptions}
              className="vl-select-container d-none"
              classNamePrefix="vl-select"
              placeholder={"I'm interested in"}
              isSearchable={false}
              defaultValue={{
                value: "InActive Profile",
                label: "InActive Profile",
              }}
            />
          </div>

          <div className="save-btn-main">
            {isDisabled ? (
              <SaveButton
                handleOnClickSaveButton={handleEdit}
                extraClassName="save-btn"
                text="Edit"
              ></SaveButton>
            ) : (
              <SaveButton
                handleOnClickSaveButton={handleSave}
                extraClassName="save-btn"
                loading={loading}
              ></SaveButton>
            )}
          </div>
        </div>
      </div>
      {!isEdit && <Footer webAppFooter={true} />}
    </div>
  );
};

export default MentorProfileInput;
