import React, { useState } from "react";
import SaveButton from "../common/SaveButton";
import TextareaField from "../common/TextareaField";
import { validateCreateNewDiscussion } from "./../../../web-app-store/validations/discussions/createNewDiscussion";

// api
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { createDiscussion } from "./../../../web-app-store/actions/discussionsAction";
import { getExploreOfferingsById } from "../../../web-app-store/actions/exploreAction";
import {
  useDispatch,
  //  useSelector
} from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * DiscussionCreateNewForm Component
 * 
 * Overview:
 * The DiscussionCreateNewForm component is responsible for rendering a form to create a new discussion. It allows users to enter a description for the discussion and save it. The component interacts with an API to fetch data and create the discussion.
 * 
 * Details:
 * - The component uses the useState hook to manage the loading state, form values, and frontend errors.
 * - It uses the useDispatch hook to get access to the Redux dispatch function.
 * - The component fetches data from the API using the useEffect hook and dispatches actions to update the Redux store.
 * - The handleChange function is used to update the form values when the user enters data.
 * - The handleSave function validates the form values and creates a new discussion if the form is valid.
 * - The component renders a TextareaField component for the description input and a SaveButton component to save the discussion.
 * 
 * Dependencies:
 * - React: The component uses React to define the functional component and manage the component's state.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library to get access to the Redux dispatch function.
 * - useState: The component uses the useState hook from React to manage the component's state.
 * - useEffect: The component uses the useEffect hook from React to fetch data from the API and update the component's state.
 * - useParams: The component uses the useParams hook from the react-router-dom library to get the parameters from the URL.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library to get access to the Redux dispatch function.
 * - useSelector: The component uses the useSelector hook from the react-redux library to get data from the Redux store.
 * - getExploreOfferingsById: The component uses the getExploreOfferingsById action creator to fetch data from the API.
 * - validateCreateNewDiscussion: The component uses the validateCreateNewDiscussion function to validate the form values.
 * - createDiscussion: The component uses the createDiscussion action creator to create a new discussion.
 * - TextareaField: The component renders the TextareaField component to display the description input.
 * - SaveButton: The component renders the SaveButton component to save the discussion.
 */
export default function DiscussionCreateNewForm({
  handleOnClickStartDiscussion,
  onClose
}) {
  // api
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    description: "",
  });

  const [frontEndErrors, setFrontEndErrors] = useState({ description: "" });

  // const raiseCapitalOfferings = useSelector(
  //   (state) => state.raiseCapital.raiseCapitalOfferings
  // );

  const [raiseCapitalOfferings, setRaiseCapitalOfferings] = useState();

  const params = useParams();
  useEffect(() => {
    const callBackOfferingData = (data) => {
      if (!isEmpty(data)) {
        setRaiseCapitalOfferings(data);
      }
    };
    dispatch(getExploreOfferingsById(params.id, callBackOfferingData));
  }, [dispatch, params]);

  const handleChange = (event) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const callBackCreateDiscussionStatus = (status) => {
    if (status === 200) {
      setLoading(false);
      onClose()
      handleOnClickStartDiscussion();
    }
  };

  const handleSave = () => {
    const { isValid, errors } = validateCreateNewDiscussion(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    }
    if (isValid) {
      setFrontEndErrors({});
    }
    if (isValid && !isEmpty(raiseCapitalOfferings)) {
      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userData"));
      let formData = {
        user: { id: userData.id },
        offering: {
          id: raiseCapitalOfferings.id,
        },
        post_details: values.description,
      };
      dispatch(createDiscussion(formData, callBackCreateDiscussionStatus));
    }
  };

  return (
    <>
      <div className="popup-new-discussion">
        {/* <button
            className="vl-create-new-discussion-back-arrow-btn"
            onClick={handleOnClickStartDiscussion}
          >
            <img
              src="/web-app-img/desktop/icons/back-arrow-black-circle-icon.svg"
              alt="back arrow"
            />
          </button>{" "} */}
        <h3 className="popup-new-discussion-title">
            Create a New Discussion
          </h3>

        <div className="popup-inner-list">
          <TextareaField
            label="Description"
            labelClassName="raise-capital-more-info-label pt-32 pb-22"
            containerClassName="description-input vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
            name="description"
            value={values.description}
            onChange={handleChange}
            error={frontEndErrors.description}
          />

          <div className="text-right">
            <SaveButton
              handleOnClickSaveButton={handleSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
