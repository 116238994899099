/**
 * Configurations for the web app store actions
 * 
 * Overview:
 * This file exports an object containing various configuration properties for the web app store actions. These properties include backend URLs, Cognito URLs, and payment base URL.
 * 
 * Details:
 * - The `backendUrl` property represents the URL of the backend server.
 * - The `landingpageBackendUrl` property represents the URL of the landing page backend server.
 * - The `backendCognitoUrl` property represents the URL of the Cognito backend server.
 * - The `cognitoLogoutUrl` property represents the URL for logging out of Cognito.
 * - The `cognitoResetPasswordUrl` property represents the URL for resetting the Cognito password.
 * - The `investingInStartupsID` property represents the ID for the investing in startups URL.
 * - The `investing101ID` property represents the ID for the investing 101 URL.
 * - The `paymentBaseURL` property represents the base URL for payment.
 * 
 * Usage:
 * const config = require('./actions/config');
 * console.log(config.backendUrl); // Output: The URL of the backend server
 * 
 * Dependencies:
 * - This file depends on the `process.env` object to access environment variables.
 */
module.exports = {
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  landingpageBackendUrl: process.env.REACT_APP_LANDING_BACKEND_URL,
  backendCognitoUrl: process.env.REACT_APP_COGNITO_URL,
  cognitoLogoutUrl: process.env.REACT_APP_COGNITO_LOGOUT_URL,
  cognitoResetPasswordUrl:process.env.REACT_APP_COGNITO_RESET_PASS_URL,
  investingInStartupsID:process.env.REACT_APP_INVESTING_IN_STARTUP_URL_ID,
  investing101ID: process.env.REACT_APP_INVESTING_101_URL_ID,
  paymentBaseURL:process.env.REACT_APP_PAYMENT_BASE_URL
  // fundAmerica: "https://sandbox.fundamerica.com/api",
};
