import React, { Fragment } from "react";
// import { useState } from "react";
// api
import { useEffect } from "react";
import {
  // getExploreAllOrganizations,
  getExploreAllOfferingsActiveInvestment,
} from "../../../web-app-store/actions/exploreAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";
import WebsiteInvestCardSingle from "./WebsiteInvestCardSingle";
import { newLoginSignUpLink } from "../header-footer/pageLinks";

/**
 * WebsiteInvestCards Component
 * 
 * Overview:
 * The WebsiteInvestCards component is responsible for displaying a list of active investment listings on the website. It allows users to explore and invest in organizations that have active offerings. This component is typically used on the home page, but can also be used on other pages as well.
 * 
 * Details:
 * - The component fetches data from the Redux store using the useSelector hook to get the exploreAllOfferingsActiveInvestment state.
 * - If the exploreAllOfferingsActiveInvestment state is empty, the component dispatches the getExploreAllOfferingsActiveInvestment action to fetch the data from the API.
 * - The component renders a heading and a list of WebsiteInvestCardSingle components, passing the orgDataMap prop to each component.
 * - The orgDataMap prop contains the data for each organization's active offering.
 * - The component also renders a "Join For Free" button that redirects the user to the newLoginSignUpLink.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook to dispatch actions.
 * - useSelector: The component uses the useSelector hook to access the Redux store.
 * - getExploreAllOfferingsActiveInvestment: The component dispatches this action to fetch the exploreAllOfferingsActiveInvestment data from the API.
 * - WebsiteInvestCardSingle: The component renders multiple instances of the WebsiteInvestCardSingle component.
 * - newLoginSignUpLink: The component uses this link for the "Join For Free" button.
 */
export default function WebsiteInvestCards({ displayOnPage }) {
  // api
  const dispatch = useDispatch();

  // const exploreAllOrgOne = useSelector((state) => state.explore.exploreAllOrg);

  // useEffect(() => {
  //   if (isEmpty(exploreAllOrgOne)) {
  //     dispatch(getExploreAllOrganizations());
  //   }
  // }, [dispatch]);

  // const exploreAllOrg = useSelector((state) => state.explore.exploreAllOrg);

  const exploreAllOfferingsActiveInvestmentOne = useSelector(
    (state) => state.explore.exploreAllOfferingsActiveInvestment
  );

  useEffect(() => {
    if (isEmpty(exploreAllOfferingsActiveInvestmentOne)) {
      dispatch(getExploreAllOfferingsActiveInvestment());
    }
  }, [dispatch, exploreAllOfferingsActiveInvestmentOne]);

  const exploreAllOfferingsActiveInvestment = useSelector(
    (state) => state.explore.exploreAllOfferingsActiveInvestment
  );

  return (
    <>
      {/* <div className="d-none"> */}
      <div className="invest-active">
        {!isEmpty(exploreAllOfferingsActiveInvestment) && (
          <>
            {displayOnPage === "home" ? (
              <div className="exploer_head">
                <h5 className="head-main font-25-montserrat-regular">
                  Invest &amp; Make an Impact{" "}
                </h5>
                <h5 className="head-sub font-40-montserrat-semiBold">
                  {" "}
                  Invest in Active Listings &#62;
                </h5>
              </div>
            ) : (
              <h5 className="invest-title">Invest in Active Listings &#62;</h5>
            )}
            <div
              className={displayOnPage === "home" ? "pt-28" : "pt-28 pb-100"}
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-out"
              data-aos-duration="1100"
              data-aos-once="true"
            >
              <div className="row mx-0">
                {/* {console.log(exploreAllOfferingsActiveInvestment)} */}
                {
                  <>
                    {exploreAllOfferingsActiveInvestment.map(
                      (orgDataMap, index) => (
                        <Fragment key={index}>
                          <WebsiteInvestCardSingle orgDataMap={orgDataMap} />
                        </Fragment>
                      )
                    )}
                  </>
                }
              </div>
            </div>
            <div className="mobile-btn">
              <a
                href={newLoginSignUpLink}
                target="_self"
                rel="noopener noreferrer"
                className="desk_joinBtn"
              >
                <button>Join For Free &#62; </button>
              </a>
              {/* <p>HOW IT WORKS</p> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

WebsiteInvestCards.defaultProps = {
  displayOnPage: "",
};
