import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

/**
 * DisplayLoader Component
 * 
 * Overview:
 * The DisplayLoader component is responsible for rendering a loading spinner on the screen. It is used to indicate that a process is in progress and to provide visual feedback to the user.
 * 
 * Details:
 * - The component renders a div element with the class "vl-display-loader-div" that contains a Loader component from an external library.
 * - The Loader component accepts a "type" prop, which specifies the type of spinner to display, and a "color" prop, which specifies the color of the spinner.
 * - The "loaderColor" prop is passed to the color prop of the Loader component.
 * 
 * Dependencies:
 * - The DisplayLoader component depends on an external library that provides the Loader component. Make sure to install the library and import the Loader component properly.
 */
export default function DisplayLoader({ loaderColor }) {
  return (
    <div className="vl-display-loader-div">
      <Loader type="TailSpin" color={loaderColor} />
    </div>
  );
}

DisplayLoader.defaultProps = {
  loaderColor: "#292950",
};
