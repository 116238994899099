import { SET_WEBSITE_ALL_BLOGS } from "./../types";

const initialState = {
  websiteAllBlogs: {},
};

/**
 * websiteBlogsReducer
 * 
 * Overview:
 * This reducer function handles the state management for website blogs in the application. It is responsible for updating the state with the website's list of blogs.
 * 
 * Details:
 * - The reducer function takes the current state and an action as parameters and returns the updated state.
 * - The reducer uses a switch statement to handle different action types. Currently, it only handles the SET_WEBSITE_ALL_BLOGS action type.
 * - When the SET_WEBSITE_ALL_BLOGS action is dispatched, the reducer updates the state by merging the current state with the payload of the action, which contains the website's list of blogs.
 * 
 * Usage:
 * This reducer is typically used in conjunction with the Redux store and combined with other reducers to manage the overall state of the application. It can be imported and used in any component that needs to access or update the website blogs state.
 * 
 * Dependencies:
 * - The reducer relies on the SET_WEBSITE_ALL_BLOGS action type, which should be defined in a separate file.
 * - The reducer assumes the existence of an initialState object, which should be defined elsewhere in the application.
 */
export default function websiteBlogsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WEBSITE_ALL_BLOGS:
      return {
        ...state,
        websiteAllBlogs: action.payload,
      };

    default:
      return state;
  }
}
