import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  exploreWebAppLink,
  faqWebAppLink,
  homeWebAppLink,
  investWebAppLink,
  knowledgeBaseWebAppLink,
  raiseCapitalWebAppLink,
  profileWebAppLink,
  mentorChatBox,
  userChatBox,
  mentor,
  mentorWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";
import { activeSidebarMenu } from "./activeMenu";
import TopNavbar from "./TopNavbar";
import apply from "../../../public/web-app-img/desktop/icons/apply.svg";

const menuList = [
  {
    link: exploreWebAppLink,
    imgPath: "/web-app-img/desktop/icons/explore-icon.svg",
    title: "Explore",
  },
  {
    link: investWebAppLink,
    imgPath: "/web-app-img/desktop/icons/invest-icon.svg",
    title: "Invest",
  },
  {
    link: raiseCapitalWebAppLink,
    imgPath: "/web-app-img/desktop/icons/raise-capital-icon.svg",
    title: "Raise Capital",
  },
  {
    link: knowledgeBaseWebAppLink,
    imgPath: "/web-app-img/desktop/icons/knowledge-base-icon.svg",
    title: "Knowledge Base",
  },
  {
    link: faqWebAppLink,
    imgPath: "/web-app-img/desktop/icons/faqs-icon.svg",
    title: "FAQs",
  },
];

const userData = JSON.parse(localStorage.getItem("userData"));
const mentorMenuList = [
  {
    link: mentorWebAppLink,
    imgPath: apply,
    title: "Apply",
  },
  {
    link: mentor,
    imgPath: "/web-app-img/desktop/icons/backToMentor.svg",
    title: "Mentor Directory",
  },
  {
    link: userData?.interest === "Mentoring" ? mentorChatBox : userChatBox,
    imgPath: "/web-app-img/desktop/icons/chat.png",
    title: "Messages",
  },
];

/**
 * SideNavbar Component
 *
 * Overview:
 * The SideNavbar component is responsible for rendering a side navigation bar in the UI. It provides a menu with various options and allows the user to navigate through different sections of the application.
 *
 * Details:
 * - The component includes a closeNav function that is triggered when the user clicks on the close button. It hides the side navigation bar.
 * - The component renders a logo, a top navigation bar, and a list of menu items.
 * - The menu items are organized in an accordion component, which allows for collapsible sections.
 * - The component accepts the activeMenu prop, which determines the currently active menu item.
 *
 * Usage:
 * To use the SideNavbar component, import it and include it in the desired location within the application's UI. Pass the activeMenu prop to specify the currently active menu item.
 *
 * Dependencies:
 * - React: The SideNavbar component is built using React.
 * - React Router: The component uses React Router's Link component to handle navigation.
 * - Bootstrap: The component utilizes Bootstrap classes for styling.
 * - Font Awesome: The component includes Font Awesome icons for visual elements.
 * - Accordion: The component uses the Accordion component for organizing menu items in collapsible sections.
 */
export default function SideNavbar({ activeMenu, isMentor }) {
  function closeNav() {
    document.getElementsByClassName(
      "vl-side-navbar-main-div"
    )[0].style.display = "none";
  }
  return (
    <div
      className={`vl-side-navbar-main-div ${
        isMentor ? "mentor-page-sidebar" : ""
      }`}
    >
      <div className="top-vl-side-navbar sidebar_wrap">
        <div className="sidebar_nav">
          <button className="mobileclose-btn" onClick={closeNav}>
            <i className="fa fa-close close-icon" />
          </button>
          <div className="mobilehead-menu mob_head_main">
            <Link to="/">
              <div className="vl-side-navbar-main-div__logo-block logo_wrap">
                <img
                  src="/web-app-img/desktop/all-logo/vedaslabs-logo-main-page.png"
                  alt="vedaslabs logo"
                  className="img-fluid"
                />
              </div>
            </Link>
            <TopNavbar />
          </div>
          <nav className="vl-side-navbar-main-div__nav">
            <ul className="p-0">
              <li className="vl-side-navbar-main-div__nav-active-link">
                <Link to={homeWebAppLink}>
                  <img
                    src="/web-app-img/desktop/icons/home-icon.svg"
                    alt={"Home"}
                  />
                  {"Home"}
                </Link>
              </li>
              <Accordion className="accordion-sidebar">
                <Accordion.Item
                  eventKey="0"
                  className="accordion-item-wrap accordion_wrap"
                >
                  <Accordion.Header className="vl-side-navbar-main-div__nav-active-link p-0">
                    <div className="d-flex align-items-center justify-content-between mb-4 ml-2 w-100">
                      <div>
                        <img
                          src="/web-app-img/desktop/icons/mentor-icon.svg"
                          className="mentorship-icon"
                        />
                        <span>Mentorship</span>
                      </div>
                      {/* <div>
                      <i class="fas fa-angle-down"></i>
                    </div> */}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="ml-5">
                    {mentorMenuList.map((data, index) => (
                      <li
                        key={index}
                        className={
                          activeSidebarMenu[index] === activeMenu
                            ? "vl-side-navbar-main-div__nav-active-link"
                            : ""
                        }
                      >
                        <Link to={data.link} className="sidebar-nav-link">
                          <img src={data.imgPath} alt={data.title} />
                          {data.title}
                        </Link>
                      </li>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {menuList.map((data, index) => (
                <li
                  key={index}
                  className={
                    activeSidebarMenu[index] === activeMenu
                      ? "vl-side-navbar-main-div__nav-active-link"
                      : ""
                  }
                >
                  <Link to={data.link}>
                    <img src={data.imgPath} alt={data.title} />
                    {data.title}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

SideNavbar.defaultProps = {
  activeMenu: "",
};
