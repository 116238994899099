import React from "react";
import { Image, ListGroup } from "react-bootstrap";
import isEmpty from "../../../web-app-store/validations/is-empty";

const ResponsiveChatMenu = (props) => {
  const { chatMenu, selectedChat, handleSelectChat, handleNoNewMsgArr } = props;
  return (
    <div className="responsive_chat_wrap">
      <ListGroup variant="flush" className="d-flex flex-row">
        {chatMenu?.map((obj, i) => {
          return i === 0 ? (
            <ListGroup.Item
              className={`d-flex flex-column align-items-center list-group-item px-3`}
              key={obj.ChatId + i}
              onLoad={() => handleSelectChat(obj)}
              onClick={() => handleNoNewMsgArr(obj)}
            >
              {!isEmpty(obj?.profilePhoto) ? (
                <Image
                  src={obj?.profilePhoto}
                  className={`mentor-image rounded-circle ${
                    selectedChat?.Name === obj?.Name ? "active-chat px-3" : ""
                  }`}
                  width={20}
                  height={20}
                />
              ) : (
                <div
                  className={`mentor-image rounded-circle fl-name text-uppercase mentor_image_text ${
                    selectedChat?.Name === obj?.Name ? "active-chat px-3" : ""
                  }`}
                >
                  {obj?.Name.split(" ")[0].charAt(0) +
                    obj?.Name.split(" ")[1].charAt(0)}
                </div>
              )}
              <div className="data">
                <div className="tooltip_wrap">
                  {obj?.Name?.split(" ")?.[0]}

                  <span className="tooltip_text">{obj?.Name}</span>
                </div>
                {/* <p className="mentor-msg">{obj?.lastMessage}</p> */}
              </div>
              <span className={obj?.lastMessage && "notification"}></span>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item
              className={`d-flex flex-column align-items-center list-group-item px-3`}
              key={obj.ChatId + i}
              onClick={() => handleNoNewMsgArr(obj)}
            >
              {!isEmpty(obj?.profilePhoto) ? (
                <Image
                  src={obj?.profilePhoto}
                  className={`mentor-image rounded-circle  mentor_images ${
                    selectedChat?.Name === obj?.Name ? "active-chat px-3" : ""
                  }`}
                />
              ) : (
                <div
                  className={`mentor-image rounded-circle fl-name text-uppercase mentor_image_text ${
                    selectedChat?.Name === obj?.Name ? "active-chat px-3" : ""
                  }`}
                >
                  {obj?.Name.split(" ")[0].charAt(0) +
                    obj?.Name.split(" ")[1].charAt(0)}
                </div>
              )}
              <div className="data">
                <div className="tooltip_wrap">
                  {obj?.Name?.split(" ")?.[0]}
                  <span className="tooltip_text">{obj?.Name}</span>
                </div>
                {/* <p className="mentor-msg">{obj?.lastMessage}</p> */}
              </div>
              <span className={obj?.lastMessage && "notification"}></span>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default ResponsiveChatMenu;
