import {
  SET_RAISE_CAPITAL_PROFILE_SUCCESS,
  SET_RAISE_CAPITAL_PROFILE_ACTIVATED,
  SET_RAISE_CAPITAL_ORGANIZATIONS,
  RESET_RAISE_CAPITAL_ORGANIZATIONS,
  SET_RAISE_CAPITAL_OFFERINGS,
  RESET_RAISE_CAPITAL_OFFERINGS,
  SET_RAISE_CAPITAL_TEAM,
  RESET_RAISE_CAPITAL_TEAM,
  SET_RAISE_CAPITAL_TAGS,
  RESET_RAISE_CAPITAL_TAGS,
  SET_RAISE_CAPITAL_INDUSTRIES,
  SET_SOCIAL_IMAPCT_OPTIONS,
  SET_RAISE_CAPITAL_ALL_INVESTORS,
  RESET_RAISE_CAPITAL_ALL_INVESTORS,
  SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED,
} from "./../types";

const initialState = {
  raiseCapitalSaveProfileSuccess: false,
  raiseCapitalProfileActivated: null,
  raiseCapitalOrg: {},
  isLoadingRaiseCapitalOrg: true,
  raiseCapitalOfferings: {},
  raiseCapitalTeam: {},
  raiseCapitalTags: {},
  raiseCapitalIndustries: [],
  raiseCapitalSocialImpact: [],
  raiseCapitalInvestors: [],
  raiseCapitalInvestorsStatusInvested: [],
};

/**
 * raiseCapitalReducer
 * 
 * Overview:
 * This reducer handles the state management for the raise capital feature in the application. It manages the state related to the user's profile, organizations, offerings, team, tags, industries, social impact options, and investors.
 * 
 * Details:
 * - The reducer uses a switch statement to handle different action types and update the state accordingly.
 * - The state is updated using the spread operator to create a new state object with the updated values.
 * - The initialState is used as the default value for the state parameter.
 * - Each case in the switch statement corresponds to a specific action type and updates the relevant state properties.
 * - The reducer returns the updated state object.
 * 
 * Usage:
 * This reducer is typically used in conjunction with the Redux store and actions to manage the state of the raise capital feature in the application.
 * 
 * Dependencies:
 * - initialState: The initial state object for the raise capital feature.
 * - action: The action object that contains the type and payload for the state update.
 * - SET_RAISE_CAPITAL_PROFILE_SUCCESS: Action type for updating the raiseCapitalSaveProfileSuccess property in the state.
 * - SET_RAISE_CAPITAL_PROFILE_ACTIVATED: Action type for updating the raiseCapitalProfileActivated property in the state.
 * - SET_RAISE_CAPITAL_ORGANIZATIONS: Action type for updating the raiseCapitalOrg property in the state.
 * - RESET_RAISE_CAPITAL_ORGANIZATIONS: Action type for resetting the raiseCapitalOrg property in the state.
 * - SET_RAISE_CAPITAL_OFFERINGS: Action type for updating the raiseCapitalOfferings property in the state.
 * - RESET_RAISE_CAPITAL_OFFERINGS: Action type for resetting the raiseCapitalOfferings property in the state.
 * - SET_RAISE_CAPITAL_TEAM: Action type for updating the raiseCapitalTeam property in the state.
 * - RESET_RAISE_CAPITAL_TEAM: Action type for resetting the raiseCapitalTeam property in the state.
 * - SET_RAISE_CAPITAL_TAGS: Action type for updating the raiseCapitalTags property in the state.
 * - RESET_RAISE_CAPITAL_TAGS: Action type for resetting the raiseCapitalTags property in the state.
 * - SET_RAISE_CAPITAL_INDUSTRIES: Action type for updating the raiseCapitalIndustries property in the state.
 * - SET_SOCIAL_IMAPCT_OPTIONS: Action type for updating the raiseCapitalSocialImpact property in the state.
 * - SET_RAISE_CAPITAL_ALL_INVESTORS: Action type for updating the raiseCapitalInvestors property in the state.
 * - RESET_RAISE_CAPITAL_ALL_INVESTORS: Action type for resetting the raiseCapitalInvestors property in the state.
 * - SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED: Action type for updating the raiseCapitalInvestorsStatusInvested property in the state.
 */
export default function raiseCapitalReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RAISE_CAPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        raiseCapitalSaveProfileSuccess: action.payload,
      };

    case SET_RAISE_CAPITAL_PROFILE_ACTIVATED:
      return {
        ...state,
        raiseCapitalProfileActivated: action.payload,
      };

    case SET_RAISE_CAPITAL_ORGANIZATIONS:
      return {
        ...state,
        raiseCapitalOrg: action.payload,
        isLoadingRaiseCapitalOrg: false,
      };

    case RESET_RAISE_CAPITAL_ORGANIZATIONS:
      return {
        ...state,
        raiseCapitalOrg: {},
        isLoadingRaiseCapitalOrg: true,
      };

    case SET_RAISE_CAPITAL_OFFERINGS:
      return {
        ...state,
        raiseCapitalOfferings: action.payload,
      };

    case RESET_RAISE_CAPITAL_OFFERINGS:
      return {
        ...state,
        raiseCapitalOfferings: {},
      };

    case SET_RAISE_CAPITAL_TEAM:
      return {
        ...state,
        raiseCapitalTeam: action.payload,
      };

    case RESET_RAISE_CAPITAL_TEAM:
      return {
        ...state,
        raiseCapitalTeam: {},
      };

    case SET_RAISE_CAPITAL_TAGS:
      return {
        ...state,
        raiseCapitalTags: action.payload,
      };

    case RESET_RAISE_CAPITAL_TAGS:
      return {
        ...state,
        raiseCapitalTags: {},
      };

    case SET_RAISE_CAPITAL_INDUSTRIES:
      return {
        ...state,
        raiseCapitalIndustries: action.payload,
      };
    case SET_SOCIAL_IMAPCT_OPTIONS:
      return {
        ...state,
        raiseCapitalSocialImpact: action.payload,
      };

    case SET_RAISE_CAPITAL_ALL_INVESTORS:
      return {
        ...state,
        raiseCapitalInvestors: action.payload,
      };

    case RESET_RAISE_CAPITAL_ALL_INVESTORS:
      return {
        ...state,
        raiseCapitalInvestors: {},
      };

    case SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED:
      return {
        ...state,
        raiseCapitalInvestorsStatusInvested: action.payload,
      };

    default:
      return state;
  }
}
