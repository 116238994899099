import React, { useEffect, useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";

// api
import { getRaiseCapitalTeam } from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { Image } from "react-bootstrap";

/**
 * RaiseCapitalTeamDisplay Component
 *
 * Overview:
 * The RaiseCapitalTeamDisplay component is responsible for displaying the team details for the Raise Capital feature. It fetches the team data from the API and renders the member information in a list format.
 *
 * Details:
 * - The component uses Redux to manage state and dispatch actions to fetch the team data.
 * - It uses the useEffect hook to scroll to the top of the page when the component mounts.
 * - The memberArray state variable holds an array of member objects, each representing a team member. The initial state contains a single empty member object.
 * - The raiseCapitalOrg and raiseCapitalTeam variables are obtained from the Redux store using the useSelector hook.
 * - The component updates the memberArray state variable whenever the raiseCapitalTeam data changes. It maps the raiseCapitalTeam data to the memberArray, populating the member details.
 * - The rendered JSX includes a list of InputFieldEmailTextPassword components for each member, displaying their name and email address.
 *
 * Usage:
 * To use the RaiseCapitalTeamDisplay component, import it into the desired file and include it in the JSX code where the team details should be displayed.
 *
 * Dependencies:
 * - React: The component relies on React to function.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - react-redux: The component uses the useSelector and useDispatch hooks from react-redux to access the Redux store.
 * - isEmpty: The component uses the isEmpty function to check if the raiseCapitalOrg and raiseCapitalTeam variables are empty.
 * - getRaiseCapitalTeam: The component dispatches the getRaiseCapitalTeam action to fetch the team data from the API.
 * - InputFieldEmailTextPassword: The component uses the InputFieldEmailTextPassword component to render the member details.
 * - TextareaField: The component uses the TextareaField component to render the member bio (commented out in the code).
 */
export default function RaiseCapitalTeamDisplay() {
  // api
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [memberArray, setMemberArray] = useState([
    {
      organization: {
        id: "",
      },
      user: { id: "" },
      memberName: "",
      email: "",
      // designation: "",
      // bio: "",
      memberId: "",
      fileData: {},
      imgUrl: {},
    },
  ]);

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalTeam(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );

  useEffect(() => {
    // console.log(raiseCapitalOrg, raiseCapitalTeam);
    if (!isEmpty(raiseCapitalTeam)) {
      const newObjStructure = {
        organization: {
          id: "",
        },
        user: { id: "" },
        memberName: "",
        email: "",
        // designation: "",
        // bio: "",
        memberId: "",
      };

      let newMemberArray = [...raiseCapitalTeam];

      let arrayLengthDiff = raiseCapitalTeam.length - newMemberArray.length;

      while (arrayLengthDiff > 0) {
        newMemberArray.push(newObjStructure);
        arrayLengthDiff = arrayLengthDiff - 1;
      }

      raiseCapitalTeam.map((data, index) => {
        newMemberArray[index].memberId = data.id;
        newMemberArray[index].memberName = data.full_name;
        newMemberArray[index].email = data.email;
        newMemberArray[index].fileData = data.profile_image;
        newMemberArray[index].imgUrl = data.profile_image?.url || "";
        // newMemberArray[index].designation = data.designation;
        // newMemberArray[index].bio = data.details;
        return newMemberArray;
      });

      setMemberArray(newMemberArray);
    }
  }, [raiseCapitalTeam]);

  /*==========================================
                    render
     ==========================================*/

  return (
    <div className="raise-capital-more-info-div common-input-capital team_disable_wrap raise-capital-more-info-div-team more-team-profile-main">
      <h5 className="raise-capital-more-info-label ">Team details</h5>

      <div className="vl-mentor-areas-fields-main-block">
        {memberArray.map((data, index) => (
          <div
            key={index}
            //className="row mx-0 flex-nowrap align-items-start vl-mentor-areas-fields-row"
          >
            <div className="mx-0 justify-content-between align-items-center pt-32 edit-team-main team-profile-main">
              <div className="team-profile-img-main">
                <Image
                  src={data?.imgUrl}
                  alt={data?.memberName}
                  height={70}
                  width={70}
                  className="team-profile-img"
                />
              </div>
              <div className="teams_disable_input">
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="Member Name"
                    id={`memberName${index}`}
                    name="memberName"
                    value={data.memberName || ""}
                    isDisabled={true}
                    type="text"
                    isError={true}
                  />
                </div>
                <div className="edit-profile-input-main d-flex justify-content-end">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="Email Address"
                    id={`email${index}`}
                    name="email"
                    value={data.email || ""}
                    isDisabled={true}
                    type="email"
                    isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    placeholder="Enter Discord ID (e.g username#1234)"
                    label="Discord ID"
                    id={`discordId${index}`}
                    name="discordId"
                    value={data.discordId || ""}
                    isDisabled={true}
                    type="email"
                    isError={true}
                  />
                </div>
                <div className="edit-profile-input-main d-flex justify-content-end">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="X"
                    placeholder="Enter Twitter handle (e.g @username or URL)"
                    id={`twitterId${index}`}
                    name="twitterId"
                    value={data.twitterId || ""}
                    isDisabled={true}
                    type="email"
                    isError={true}
                  />
                </div>
                <div className="edit-profile-input-main">
                  <InputFieldEmailTextPassword
                    containerClassName="vl-form-input edit-profile-input"
                    label="GitHub ID"
                    placeholder="Enter GitHub username or URL"
                    id={`gitHubId${index}`}
                    name="text"
                    value={data.gitHubId || ""}
                    isDisabled={true}
                    type="email"
                    isError={true}
                  />
                </div>
              </div>
            </div>
            {/* <div className="pt-32">
              <InputFieldEmailTextPassword
                containerClassName="vl-form-input"
                label="Designation"
                id={`designation${index}`}
                name="designation"
                value={data.designation || ""}
                isDisabled={true}
                type="text"
              />
            </div> */}

            {/* <div className="raise-capital-team-textarea-div">
              <TextareaField
                containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-team"
                id={`bio${index}`}
                label="Bio"
                labelClassName="raise-capital-more-info-label-2"
                name="bio"
                value={data.bio || ""}
                isDisabled={true}
              />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}
