import React, { useEffect, useState } from "react";
import Footer from "../header-footer/Footer";
import FaqFoldOne from "./FaqFoldOne";
import FaqFoldTwo from "./FaqFoldTwo";

/**
 * Faq Component
 * 
 * Overview:
 * The Faq component is responsible for rendering a frequently asked questions section in the UI. It consists of two folds: FaqFoldOne and FaqFoldTwo. FaqFoldOne contains the initial set of questions, while FaqFoldTwo is dynamically rendered based on the height of FaqFoldOne. This component is used to provide information and answers to common questions about the application.
 * 
 * Details:
 * - The component uses the useState hook to manage the foldOneHeight state, which represents the height of FaqFoldOne.
 * - The useEffect hook is used to scroll to the top of the page and calculate the height of FaqFoldOne when the component mounts.
 * - The component renders a div with the class "bg-color" as the container for the FaqFoldOne and FaqFoldTwo components.
 * - FaqFoldOne is wrapped in a div with the id "vl-faq-fold-one-div-id" to enable height calculation.
 * - FaqFoldTwo is rendered with the foldOneHeight prop, which is passed from the Faq component.
 * - The component also renders a Footer component at the end.
 * 
 * Dependencies:
 * - React: The component relies on React to define and render the UI components.
 * - useState: The component uses the useState hook to manage the foldOneHeight state.
 * - useEffect: The component uses the useEffect hook to perform side effects such as scrolling and height calculation.
 * - FaqFoldOne: The component depends on the FaqFoldOne sub-component to render the initial set of questions.
 * - FaqFoldTwo: The component depends on the FaqFoldTwo sub-component to render the dynamically generated questions based on the height of FaqFoldOne.
 * - Footer: The component depends on the Footer component to render the footer section of the page.
 */
export default function Faq() {
  const [foldOneHeight, setFoldOneHeight] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-faq-fold-one-div-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-faq-fold-one-div-id").offsetHeight
      );
    }
  }, []);

  return (
    <>
      <div className="bg-color">
        <div id="vl-faq-fold-one-div-id">
          <FaqFoldOne />
        </div>
        <FaqFoldTwo foldOneHeight={foldOneHeight} />
      </div>
      <Footer />
    </>
  );
}
