import React, { useEffect } from "react";
import format from "date-fns/format";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";

// api
import { getHomeAllFeeds } from "../../../web-app-store/actions/homeAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

// const dummyData = [
//   {
//     title: "Hannah Kuchler / Financial Times:",
//     published: "26 minutes ago",
//     desc:
//       "Insitro, which uses machine learning to speed-up drug discovery, raises $400M, led by Canada Pension Plan; other investors include SoftBank, Temasek, a16z",
//   },
//   {
//     title: "Kyle Wiggers / VentureBeat:",
//     published: "3 hours ago",
//     desc:
//       "Centrical, a startup developing an employee engagement and performance management service, raises $32M led by Intel Capital and JVP",
//   },
//   {
//     title: "Clare Duffy / CNN:",
//     published: "5 hours ago",
//     desc:
//       "Recent FCC auction results: Verizon bid ~$53B to more than double its mid-band spectrum holdings and boost 5G; AT&T bid ~$27B and T-Mobile bid ~$9B on mid-range",
//   },
//   {
//     title: "Connie Loizos / TechCrunch:",
//     published: "7 hours ago",
//     desc:
//       "NYU professor Scott Galloway's Section4, which offers two to three week business education online courses, raises $30M Series A led by General Catalyst",
//   },
//   {
//     title: "Financial Times:",
//     published: "9 hours ago",
//     desc:
//       "Stripe raises $600M from Sequoia Capital and others at a valuation of $95B, becoming the most valuable private company in Silicon Valley",
//   },
// ];

/**
 * NewHomeCard Component
 * 
 * Overview:
 * The NewHomeCard component is responsible for rendering a card that displays news feed content. It fetches the news feed data from the Redux store and renders the title, content, and publication date of each news feed item. It also provides a "Read more" link that opens the news feed item in a new tab.
 * 
 * Details:
 * - The component uses Redux hooks (useDispatch and useSelector) to access the homeAllFeeds data from the Redux store.
 * - It uses the useEffect hook to dispatch the getHomeAllFeeds action if the homeAllFeedsOne data is empty.
 * - The component defines helper functions (renderNewTitle, renderNewContent, renderTimeDifference) to handle rendering logic for the title, content, and publication date.
 * - The renderTimeDifference function calculates the time difference between the current date and the publication date and formats it accordingly.
 * - The component maps over the homeAllFeeds data and renders a card for each news feed item.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the UI.
 * - Redux: The component uses Redux for state management and to access the homeAllFeeds data from the Redux store.
 * - react-redux: The component uses the useDispatch and useSelector hooks from react-redux to interact with the Redux store.
 * - date-fns: The component uses the differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, and format functions from the date-fns library to calculate and format the time difference.
 */
export default function NewHomeCard() {
  // api
  const dispatch = useDispatch();

  const homeAllFeedsOne = useSelector((state) => state.home.homeAllFeeds);

  useEffect(() => {
    if (isEmpty(homeAllFeedsOne)) {
      dispatch(getHomeAllFeeds());
    }
  }, [dispatch, homeAllFeedsOne]);

  const homeAllFeeds = useSelector((state) => state.home.homeAllFeeds);

  const renderNewTitle = (title) => {
    return !isEmpty(title.length) && title.length > 70
      ? `${title.substring(0, 70)}...`
      : title;
  };

  const renderNewContent = (content) => {
    return !isEmpty(content) && content.length > 200
      ? `${content.substring(0, 200)}...`
      : content;
  };

  const renderTimeDifference = (data) => {
    if (!isEmpty(data)) {
      let seconds = differenceInSeconds(new Date(), new Date(data));
      let minutes = differenceInMinutes(new Date(), new Date(data));
      let hours = differenceInHours(new Date(), new Date(data));
      let days = differenceInDays(new Date(), new Date(data));
      // console.log(seconds, minutes, hours, days);
      if (seconds <= 1) {
        return `a second ago`;
      } else if (seconds < 60) {
        return `${seconds} seconds ago`;
      } else if (minutes === 1) {
        return `${seconds} minute ago`;
      } else if (minutes < 60) {
        return `${seconds} minutes ago`;
      } else if (hours === 1) {
        return `${hours} hour ago`;
      } else if (hours < 24) {
        return `${hours} hours ago`;
      } else if (days === 1) {
        return `${days} day ago`;
      } else if (days > 1) {
        return format(new Date(data), "DD MMM, YYYY");
      }
    } else {
      return null;
    }
  };

  return (
    <div className="new-home-card-block">
      <div className="new-home-inner-card-block">
        {!isEmpty(homeAllFeeds) &&
          homeAllFeeds.map((data, index) => (
            <div className="new-home-card-content-block" key={index}>
              <h3 className="font-18-roboto-bold">
                {renderNewTitle(data.title)}
              </h3>
              <span className="new-home-card-content-text-italic">
                {/* {data.published} */}
                {renderTimeDifference(data.created_at)}
              </span>
              <p className="new-home-card-content-para-text pb-10">
                {renderNewContent(data.content)}
              </p>
              <a href={data.url} target="_blank" rel="noopener noreferrer">
                <span className="new-home-card-content-read-more-text">
                  Read more
                </span>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
