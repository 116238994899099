/**
 * Industry Dropdown List Component
 * 
 * Overview:
 * The Industry Dropdown List component provides a list of industries for selection in a dropdown format. It is used in the mentor section of the application to allow users to select their industry of interest.
 * 
 * Details:
 * - The component exports an array of objects representing industries, each containing a value and a label.
 * - The value represents the industry's unique identifier, while the label is the display name of the industry.
 * - The component does not include any sub-components and does not have any specific rendering logic.
 * - The component does not accept any props.
 * 
 * Dependencies:
 * None
 */
export const industries = [
  { value: "Advertising", label: "Advertising" },
  { value: "AI", label: "AI" },
  { value: "Analytics", label: "Analytics" },
  { value: "AgTech", label: "AgTech" },
  { value: "AudioTech", label: "AudioTech" },
  { value: "AR/VR", label: "AR/VR" },
  { value: "AutoTech", label: "AutoTech" },
  { value: "BioTech", label: "BioTech" },
  { value: "Chemicals", label: "Chemicals" },
  { value: "ClimateTech/CleanTech", label: "ClimateTech/CleanTech" },
  { value: "Cloud Infrastructure", label: "Cloud Infrastructure" },
  { value: "ConstructionTech", label: "ConstructionTech" },
  { value: "Consumer Health", label: "Consumer Health" },
  { value: "Consumer Internet", label: "Consumer Internet" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Creator/Passion Economy", label: "Creator/Passion Economy" },
  { value: "Crypto/Web3", label: "Crypto/Web3" },
  { value: "Cybersecurity", label: "Cybersecurity" },
  { value: "Data Services", label: "Data Services" },
  { value: "DeepTech", label: "DeepTech" },
  { value: "Developer Tools", label: "Developer Tools" },
  { value: "Diagnostics", label: "Diagnostics" },
  { value: "Digital Health", label: "Digital Health" },
  { value: "Direct-to-Consumer (DTC)", label: "Direct-to-Consumer (DTC)" },
  { value: "Drug Delivery", label: "Drug Delivery" },
  { value: " E-commerce", label: " E-commerce" },
  { value: "Education", label: "Education" },
  { value: "EnergyTech", label: "EnergyTech" },
  { value: "Enterprise", label: "Enterprise" },
  { value: "Entertainment & Sports", label: "Entertainment & Sports" },
  { value: "Enterprise Applications", label: "Enterprise Applications" },
  { value: "Enterprise Infrastructure", label: "Enterprise Infrastructure" },
  { value: "Fashion", label: "Fashion" },
  { value: "FinTech", label: "FinTech" },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Future of Work", label: "Future of Work" },
  { value: "Games", label: "Games" },
  { value: "Gaming/eSports", label: "Gaming/eSports" },
  { value: "Generative Tech/AI", label: "Generative Tech/AI" },
  { value: "Gig Economy", label: "Gig Economy" },
  { value: "GovTech", label: "GovTech" },
  { value: "Impact", label: "Impact" },
  { value: "Hardware", label: "Hardware" },
  { value: "Health & Hospital Services", label: "Health & Hospital Services" },
  { value: "Health IT", label: "Health IT" },
  { value: "Human Capital/HRTech", label: "Human Capital/HRTech" },
  { value: "Insurance", label: "Insurance" },
  { value: "IoT", label: "IoT" },
  { value: "LegalTech", label: "LegalTech" },
  { value: "Local Services", label: "Local Services" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Lodging/Hospitality", label: "Lodging/Hospitality" },
  { value: "Logistics", label: "Logistics" },
  { value: "MarketingTech", label: "MarketingTech" },
  { value: "Medical Devices", label: "Medical Devices" },
  { value: "Marketplaces", label: "Marketplaces" },
  { value: "Material Science", label: "Material Science" },
  { value: "Media/Content", label: "Media/Content" },
  { value: "Wellness & Fitness", label: "Wellness & Fitness" },
  { value: "Web3/Blockchain", label: "Web3/Blockchain" },
  { value: "Travel", label: "Travel" },
  { value: "Therapeutics", label: "Therapeutics" },
  { value: "TransportationTech", label: "TransportationTech" },
  { value: "Space", label: "Space" },
  { value: "Supply Chain Tech", label: "Supply Chain Tech" },
  { value: "Social Networks", label: "Social Networks" },
  { value: "SMB Software", label: "SMB Software" },
  { value: "Social Commerce", label: "Social Commerce" },
  { value: "Smart Cities/UrbanTech", label: "Smart Cities/UrbanTech" },
  { value: "Semiconductors", label: "Semiconductors" },
  { value: "Security", label: "Security" },
  { value: "Sales & CRM", label: "Sales & CRM" },
  { value: "SaaS", label: "SaaS" },
  { value: "Retail", label: "Retail" },
  { value: "Robotics", label: "Robotics" },
  { value: "Real Estate/PropTech", label: "Real Estate/PropTech" },
  { value: "Pharmaceuticals", label: "Pharmaceuticals" },
  { value: "Payments", label: "Payments" },
  { value: "Parenting/Families", label: "Parenting/Families" },
  { value: "Mobility", label: "Mobility" },
  { value: "Messaging", label: "Messaging" },
];
