import { Link } from "react-router-dom";
import { mentorWebAppLink } from "../../header-footer/pageLinks";
import mentorYoungStartUp from "../../../../assets/imgs/desktop/common/mentor-young-startup.png";
import "./_styles.scss";
import { Image } from "react-bootstrap";
import Footer from "../../header-footer/Footer";

const MentorYoungStartUp = ({ setActiveProfileStage }) => {
  return (
    <div className="mentor-young-startups">
      <div className="mentor-young-startups-main">
        <div className="mentor-young-startups-wrap">
          <div className="mentor-young-startups-image-wrap">
            <Image
              src={mentorYoungStartUp}
              className="mentor-young-startups-image"
            />
          </div>
          <h1 className="mentor-young-startups-title">Mentor Young Startups</h1>
          <div className="mentor-young-startups-content">
          Begin your journey to influence the next generation of pioneers by imparting wisdom and experience
          </div>
          <div className="mentor-young-startups-link-wrap">
            <div
              onClick={() => setActiveProfileStage(2)}
              className="mentor-young-startups-link"
            >
              Apply to Start Mentoring
            </div>
          </div>
        </div>
      </div>
      <Footer webAppFooter={true} isMentorClass="footer-main" />
    </div>
  );
};
export default MentorYoungStartUp;
