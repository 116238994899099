import { SET_ERRORS, CLEAR_ERRORS } from "./../types";

const initialState = {
  errors: {},
};

/**
 * errorReducer
 * 
 * Overview:
 * This reducer handles the state related to errors in the application. It updates the errors property in the state based on the dispatched actions.
 * 
 * Details:
 * - The reducer function takes two parameters: state and action. The state parameter represents the current state of the errorReducer, and the action parameter represents the dispatched action.
 * - The reducer uses a switch statement to handle different action types. When the action type is SET_ERRORS, it updates the errors property in the state with the payload of the action. When the action type is CLEAR_ERRORS, it clears the errors property in the state.
 * - The reducer returns the updated state after handling the action.
 * 
 * Usage:
 * This reducer is typically used in conjunction with other reducers to manage the overall state of the application. It can be imported and combined with other reducers using a state management library like Redux.
 * 
 * Dependencies:
 * This reducer does not have any external dependencies.
 */
export default function foo(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
      };

    default:
      return state;
  }
}
