import React, { useState, useEffect } from "react";

import moment from "moment-timezone";
import SideNavbar from "../header/SideNavbar";

import { activeSidebarMenu, investPageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import Footer from "../../../components/desktop/header-footer/Footer";
import { calcTotalInvestmentLimit } from "./calcTotalInvestmentLimit";
import { investWebAppLink } from "../../../components/desktop/header-footer/pageLinks";

import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import CurrencyInputCommon from "../common/CurrencyInputCommon";

// api
import { useDispatch, useSelector } from "react-redux";
import {
  postFundAmericaProceedPaymentInvestor,
  createInvestor,
  updateInvestor,
  getInvestorByUserId,
} from "../../../web-app-store/actions/investAction";
import { getInvestorPortfolioList } from "../../../web-app-store/actions/investorPortfolioAction";
import { validateInvestorCalculator } from "../../../web-app-store/validations/invest/investorCalculator";
import { validateInvestorProceedToPayment } from "../../../web-app-store/validations/invest/investorProceedToPayment";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { jobIndustryOptionArray } from "../common/allDropdownOptions";
import { useLocation } from "react-router-dom";
import NetWorthCalcNeedHelp from "./NetWorthCalcNeedHelp";

/**
 * ProceedPayment Component
 * 
 * Overview:
 * The ProceedPayment component is responsible for handling the payment process for an investment. It includes form fields for collecting user input, calculates the remaining investment limit based on user data, and integrates with the Fund America API for processing the payment.
 * 
 * Details:
 * - The component uses Redux for state management, with the useDispatch and useSelector hooks to interact with the Redux store.
 * - It utilizes the react-router-dom package to access the current location and retrieve data from the state.
 * - The component includes multiple useEffect hooks to handle side effects such as API calls, scrolling, and script injection.
 * - It uses useState hooks to manage form input values, loading state, front-end errors, and remaining investment limit.
 * - The component renders a form with various input fields, checkboxes, and buttons for user interaction.
 * - It includes validation logic to check the validity of the form inputs and display front-end errors if necessary.
 * - The component dynamically injects a script from the Fund America API to enable the payment process.
 * - It listens for events triggered by the Fund America API and performs actions accordingly, such as autofilling form fields and redirecting after successful payment.
 * 
 * Usage:
 * The ProceedPayment component can be used within the investment process of the application. It should be rendered within a parent component that provides the necessary Redux store and react-router-dom functionality.
 * 
 * Dependencies:
 * - React
 * - Redux
 * - react-redux
 * - react-router-dom
 * - Fund America API
 * - moment.js
 */
export default function ProceedPayment() {
  // api
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [frontEndErrors, setFrontEndErrors] = useState({});
  let userData = JSON.parse(localStorage.getItem("userData"));

  const [valuesRowOne, setValuesRowOne] = useState({
    companyName: "",
    investmentAmount: "",
    signatureBox: "",
    returnOfInvestmentCheckbox: false,
    resellCSCheckbox: false,
    crowdfundingInvolvesRiskCheckbox: false,
    min_investment: 0,
  });

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setValuesRowOne({
        ...valuesRowOne,
        companyName: location.state.detail.company_name,
        min_investment: location.state.min_investment,
      });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getInvestorByUserId());
  }, [dispatch]);

  const investor = useSelector((state) => state.invest.investor);

  const [values, setValues] = useState({
    yearlyIncome: "",
    networth: "",
    jobTitle: "",
    jobIndustry: jobIndustryOptionArray[0],
    spouse_income_included: false,
    invested_outside_vlabs: false,
    spouse_acc_vedaslab: false,
    fname: "",
    lname: "",
    email: "",
    amountInvestedOutsideOfVL: "",
    amountInvestedOnVL: "",
  });

  const [isProceedPaymentFormValid, setIsProceedPaymentFormValid] =
    useState(false);

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];

      setValues({
        ...values,
        yearlyIncome: !isEmpty(data.income) ? data.income : "",
        networth: !isEmpty(data.networth) ? data.networth : "",
        amountInvestedOutsideOfVL: !isEmpty(data.amount_invested_outside)
          ? data.amount_invested_outside
          : "",
        amountInvestedOnVL: !isEmpty(data.amount_invested_vlabs)
          ? data.amount_invested_vlabs
          : "",
        jobTitle: data.job_title,
        jobIndustry: !isEmpty(data.job_industry)
          ? {
              value: data.job_industry,
              label: data.job_industry,
            }
          : jobIndustryOptionArray[0],
        spouse_income_included: data.spouse_income_included,
        invested_outside_vlabs: data.invested_outside_vlabs,
        spouse_acc_vedaslab: data.spouse_acc_vedaslab,
        fname: data.spouse_first_name,
        lname: data.spouse_last_name,
        email: data.spouse_email,
      });
    }
  }, [investor]);

  const [remainingInvestmentLimit, setRemainingInvestmentLimit] = useState();
  useEffect(() => {
    if (!isEmpty(investor)) {
      let remainingInvestmentLimit = 0;

      // console.log("--- data ---", investor[0]);
      // console.log("in case1", investor[0].income >= 200000 && (!investor[0].spouseIncomeIncluded));
      // console.log("in case2", investor[0].income >= 300000 && (investor[0].spouseIncomeIncluded));

      if (
        (investor[0].income >= 200000 && !investor[0].spouseIncomeIncluded) ||
        (investor[0].income >= 300000 && investor[0].spouseIncomeIncluded) ||
        investor[0].net_worth >= 1000000
      ) {
        // console.log("in case3");
      } else {
        let totalInvestmentLimit = calcTotalInvestmentLimit(
          investor[0].networth,
          investor[0].income
        );
        // console.log("net worth", investor[0].networth);
        // console.log("annual income", investor[0].income);
        // console.log("in case4", totalInvestmentLimit);
        remainingInvestmentLimit =
          totalInvestmentLimit -
          investor[0].amount_invested_outside -
          investor[0].amount_invested_vlabs;

        remainingInvestmentLimit = Math.round(remainingInvestmentLimit);
      }
      setRemainingInvestmentLimit(remainingInvestmentLimit);
      let formObj = {
        ...valuesRowOne,
        remainingInvestmentLimit: remainingInvestmentLimit,
      };

      const { isValid, errors } = validateInvestorProceedToPayment(formObj);
      if (isValid) {
        setFrontEndErrors({});
        setIsProceedPaymentFormValid(true);
      } else {
        setFrontEndErrors(errors);
        setIsProceedPaymentFormValid(false);
      }
    }
  }, [investor, valuesRowOne.investmentAmount]);

  const handleChangeInvestmentAmount = (element) => {
    // setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      investmentAmount:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });

    let formObj = {
      ...valuesRowOne,
      investmentAmount:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };

    const { isValid, errors } = validateInvestorProceedToPayment(formObj);
    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }
  };

  useEffect(() => {
    // fund america
    const script = document.createElement("script");
    script.src = "https://my-sandbox.fundamerica.com/widgets/invest_now.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(getInvestorPortfolioList());
  }, [dispatch]);

  const investorPortfolio = useSelector(
    (state) => state.invest.investorPortfolio
  );

  // USEEFFECT FOR CHECKING ALL INVESTOR AND CALLING FUNDRAISER EVENTS
  useEffect(() => {
    if (!isEmpty(investorPortfolio)) {
      let currentInvestor = investorPortfolio.filter(
        (a) => a.offering_id === location.state.offeringId
      );

      if (!isEmpty(currentInvestor)) {
        // set investors
        if (currentInvestor[0].investor_id) {
          let setInvestors = document.createEvent("Event");
          setInvestors.initEvent("fa.investnow.setInvestors", true, false);
          setInvestors.ids = currentInvestor[0].investor_id;
          document.body.dispatchEvent(setInvestors);
        }

        // set authorization
        if (currentInvestor[0].ach_authorization_id) {
          let setAchAuthorizations = document.createEvent("Event");
          setAchAuthorizations.initEvent(
            "fa.investnow.setAchAuthorizations",
            true,
            false
          );
          setAchAuthorizations.ids = currentInvestor[0].ach_authorization_id;
          document.body.dispatchEvent(setAchAuthorizations);
        }
      }
    }

    if (!isEmpty(investor)) {
      let investorData = investor[0];

      document.addEventListener("fa.investnow.open", function (e) {
        let strAmount = valuesRowOne.investmentAmount.toString();
        let strAnnualIncome = values.yearlyIncome.toString();
        let strNetWorth = values.networth.toString();
        console.log("Investor birthday", investorData.birth_date);
        function format(inputDate) {
          var date = new Date(inputDate);
          if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return (
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear()
            );
          }
        }
        let dob = format(investorData.birth_date);
        console.log("FA dob format", dob);
        let autofillEvent = document.createEvent("Event");
        autofillEvent.initEvent("fa.investnow.autofill", true, false);
        autofillEvent.investor = {
          type: "person",
          payment_method: "ach",
          name: `${investorData?.first_name || ""} ${
            investorData?.last_name || ""
          }`,
          city: investorData.location,
          region: investorData.location,
          email: investorData.email,
          phone: investorData.phone_number,
          // passing dob as 1996-03-05 mm/dd/yyyy
          date_of_birth: moment(userData.DOB, "YYYY-MM-DD")
            .format("YYYY-MM-DD")
            .toString(),
          contact_name: `${investorData?.first_name || ""} ${
            investorData?.last_name || ""
          }`,
          amount: strAmount,
          annual_income: strAnnualIncome,
          net_worth: strNetWorth,
          // tax_id_number: "999999999",
          // street_address_1: "800 Third Avenue",
          // street_address_2: "22nd Floor",
          // postal_code: "10022",
          // region_formed_in: investorData.nationality,
          // financial_adviser_name: "Jimmy Johnson",
        };
        document.body.dispatchEvent(autofillEvent);
      });
    }
  }, [
    investor,
    isProceedPaymentFormValid,
    valuesRowOne.investmentAmount,
    investorPortfolio,
  ]);

  useEffect(() => {
    document.addEventListener("fa.investnow.close", function (e) {
      let clearEvent = document.createEvent("Event");
      clearEvent.initEvent("fa.investnow.clear", true, false);
      document.body.dispatchEvent(clearEvent);
    });
  }, []);

  const [prevInvestmentId, setPrevInvestmentId] = useState();

  useEffect(() => {
    const callBackFundAmericaProceedPaymentInvestor = (status) => {
      if (status === 200) {
        document.addEventListener("fa.investnow.close", function (e) {
          window.location.assign(`${investWebAppLink}`);
          localStorage.setItem(
            "activeVLInvestAfterProfileSetupPageTabIndex",
            1
          );
        });
      }
    };

    // investor SUCCESS evnt -> -> will trigger when invest now popup sucess
    document.addEventListener("fa.investnow.success", function (e) {
      let investorInvestmentID = e.investment_id;
      setPrevInvestmentId(investorInvestmentID);

      let investNowSandBox = JSON.parse(
        localStorage.getItem("ls.invest_now.sandbox")
      );

      if (investorInvestmentID !== prevInvestmentId) {
        let formData = {
          user: userData.id,
          investment_id: investorInvestmentID,
          offering_id: location.state.offeringId,

          ach_authorization_id:
            investNowSandBox.invest_now_investment.ach_authorization_id,
          investor_id: investNowSandBox.invest_now_investment.investor_id,
          amount: investNowSandBox.invest_now_investment.amount,

          // amount_received
        };

        // console.log("dispatch");
        dispatch(
          postFundAmericaProceedPaymentInvestor(
            formData,
            callBackFundAmericaProceedPaymentInvestor
          )
        );
      }
    });
    return () => {
      localStorage.removeItem("ls.invest_now.sandbox");
    };
  }, [dispatch]);

  /*============================================================
                           handler
  ============================================================*/

  const handleChangeSignatureBox = (e) => {
    // setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      signatureBox: e.target.value,
    });

    let formObj = {
      ...valuesRowOne,
      signatureBox: e.target.value,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };

    const { errors, isValid } = validateInvestorProceedToPayment(formObj);

    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }
  };

  const handleChangeRowOne = (e) => {
    setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeJobIndustry = (jobIndustry) => {
    setValues({
      ...values,
      jobIndustry,
    });
  };

  const handleCheckboxChange = (e) => {
    setFrontEndErrors({});
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        fname: "",
        lname: "",
        email: "",
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleCheckboxChangeCrowdFunding = (e) => {
    setFrontEndErrors({});
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        amountInvestedOutsideOfVL: "",
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleChangeYearlyIncome = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      yearlyIncome:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeNetworth = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      networth:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeAmountInvestedOutsideOfVL = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      amountInvestedOutsideOfVL:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const [isClickEditForm, setIsClickEditForm] = useState(false);
  const handleOnClickEditFormButton = () => {
    setIsClickEditForm(true);
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      setIsClickEditForm(false);
    }
  };

  const handleOnClickSaveAndNext = () => {
    const { errors, isValid } = validateInvestorCalculator(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    } else if (isValid) {
      setFrontEndErrors({});
      setLoading(true);

      let formData = {
        user: { id: userData.id },
        income: !isEmpty(values.yearlyIncome) ? values.yearlyIncome : null,
        networth: !isEmpty(values.networth) ? values.networth : null,
        amount_invested_outside: !isEmpty(values.amountInvestedOutsideOfVL)
          ? values.amountInvestedOutsideOfVL
          : null,
        job_title: values.jobTitle,
        job_industry: values.jobIndustry.value,
        spouse_income_included: values.spouse_income_included,
        invested_outside_vlabs: values.invested_outside_vlabs,
        spouse_acc_vedaslab: values.spouse_acc_vedaslab,
        spouse_first_name: values.fname,
        spouse_last_name: values.lname,
        spouse_email: values.email,
      };

      if (isEmpty(investor)) {
        dispatch(createInvestor(formData, callBackStatus));
      } else {
        dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
      }
    }
  };

  const handleCheckboxChangeCrowdFundingProceedPayment = (e) => {
    setValuesRowOne({
      ...valuesRowOne,
      [e.target.name]: e.target.checked,
    });

    let formObj = {
      ...valuesRowOne,
      [e.target.name]: e.target.checked,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };
    const { isValid, errors } = validateInvestorProceedToPayment(formObj);
    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }
  };

  const renderSpouseInfo = () => {
    return (
      <>
        <h3 className="invest-calculator-font-24-medium">Spouse Information</h3>
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="First Name"
          name="fname"
          value={values.fname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.fname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Last Name"
          name="lname"
          value={values.lname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.lname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Email Address"
          name="email"
          value={values.email}
          onChange={handleChange}
          type="email"
          error={frontEndErrors.email}
        />
      </>
    );
  };

  // renderCalculator
  const renderCalculator = () => {
    return (
      <>
        <div className="row mx-0 px-0 pt-32 flex-wrap invest-personal-tab-panel invest-personal-tab-panel--calculator">
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Yearly Income ($)"
              name="yearlyIncome"
              value={values.yearlyIncome}
              onChange={handleChangeYearlyIncome}
              error={frontEndErrors.yearlyIncome}
            />

            <div className="vl-net-worth-with-note-block">
              <CurrencyInputCommon
                containerClassName="vl-form-input"
                label="Net Worth ($)"
                name="networth"
                value={values.networth}
                onChange={handleChangeNetworth}
                error={frontEndErrors.networth}
              />
              <NetWorthCalcNeedHelp />
            </div>

            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Job Title"
              name="jobTitle"
              value={values.jobTitle}
              onChange={handleChange}
              type="text"
              error={frontEndErrors.jobTitle}
            />
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Job Industry</label>
              <Select
                value={values.jobIndustry}
                onChange={handleChangeJobIndustry}
                options={jobIndustryOptionArray}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
            </div>
            {values.spouse_income_included && renderSpouseInfo()}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-45">
              <Checkbox
                onChange={handleCheckboxChangeCrowdFunding}
                value={values.invested_outside_vlabs}
                checked={values.invested_outside_vlabs}
                defaultChecked={false}
                name="invested_outside_vlabs"
                id="invested_outside_vlabs"
              />
              <label htmlFor="invested_outside_vlabs">
                I have invested in crowdfunding in the past 12 months.
              </label>
            </div>

            {values.invested_outside_vlabs && (
              <div className="mt-45 pt-15">
                <CurrencyInputCommon
                  containerClassName="vl-form-input"
                  label="Amount Invested Outside of VedasLabs ($)"
                  name="amountInvestedOutsideOfVL"
                  value={values.amountInvestedOutsideOfVL}
                  onChange={handleChangeAmountInvestedOutsideOfVL}
                  error={frontEndErrors.amountInvestedOutsideOfVL}
                />
              </div>
            )}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-38">
              <Checkbox
                onChange={handleCheckboxChange}
                value={values.spouse_acc_vedaslab}
                checked={values.spouse_acc_vedaslab}
                defaultChecked={false}
                name="spouse_acc_vedaslab"
                id="spouse_acc_vedaslab"
              />
              <label htmlFor="spouse_acc_vedaslab">
                My spouse has an account with VedasLabs.
              </label>
            </div>
          </div>

          <div className="col-md-1 px-0"></div>
        </div>
      </>
    );
  };

  // renderDisplayCalculator
  const renderDisplayCalculator = () => {
    return (
      <>
        <div className="row mx-0 px-0 pt-32 flex-wrap invest-personal-tab-panel invest-personal-tab-panel--calculator">
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="First Name"
              name="companyName"
              value={valuesRowOne.companyName || "Vishal"}
              onChange={handleChangeRowOne}
              type="text"
              isReadOnly={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Middle Name"
              name="middle_name"
              value={values.first_name || "vishal"}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Last Name"
              name="last_name"
              value={values.first_name || "pedhadiya"}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Birth Date"
              name="birth_date"
              value="28-12-1996"
              isDisabled={true}
              type="text"
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Select Citizenship</label>
              <Select
                value={values.jobIndustry}
                options={[
                  { value: "Employed", label: "Us Citizen" },
                  {
                    value: "U.S. Resident, non-U.S. Citizen",
                    label: "U.S. Resident, non-U.S. Citizen",
                  },
                  {
                    value:
                      "No, I am neither a U.S. citizen nor a U.S. resident",
                    label:
                      "No, I am neither a U.S. citizen nor a U.S. resident",
                  },
                ]}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
            </div>
            {values.invested_outside_vlabs && (
              <div className="mt-45 pt-15">
                <CurrencyInputCommon
                  containerClassName="vl-form-input"
                  label="Amount Invested Outside of VedasLabs ($)"
                  name="amountInvestedOutsideOfVL"
                  value={values.amountInvestedOutsideOfVL}
                  isDisabled={true}
                />
              </div>
            )}
          </div>
          <div className="col-md-6 col-12 px-0">
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Country</label>
              <Select
                value={values.jobIndustry}
                options={[
                  { value: "Employed", label: "India" },
                  { value: "Not Employed", label: "USA" },
                  { value: "Retired", label: "Japan" },
                  { value: "Student", label: "Pakistan" },
                ]}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
            </div>
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Mobile Number"
              name="mobile_number"
              value="+918141820040"
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Email"
              name="email"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Address 1"
              name="address1"
              value="Surat"
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Address 2"
              name="address2"
              value="Surat"
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="City"
              name="city"
              value="Surat"
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Pin code"
              name="pin_code"
              value="395004"
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Current Annual Income"
              name="currentAnnIncome"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Average Income (Past 2 Years)"
              name="avgAnnIncome"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Household Annual Income"
              name="currentHouseholdIncome"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Household Average Income (Past 2 Years)"
              name="avgHouseholdIncome"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Household Networth (Excluding Primary Residence)"
              name="householdNetworth"
              value={values.networth}
              isDisabled={true}
            />
          </div>
          <div className="col-md-6 col-12 px-0">
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Employment Status</label>
              <Select
                value={values.jobIndustry}
                options={[
                  { value: "Employed", label: "Employed" },
                  { value: "Not Employed", label: "Not Employed" },
                  { value: "Retired", label: "Retired" },
                  { value: "Student", label: "Student" },
                ]}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
              {/* <Select                
                // options={[
                //   { value: 'Employed', label: 'Employed' },
                //   { value: 'Not Employed', label: 'Not Employed' },
                //   { value: 'Retired', label: 'Retired' },
                //   { value: 'Student', label: 'Student' }
                // ]}                
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              /> */}
            </div>
          </div>
          <div className="col-1 px-0"></div>
        </div>
      </>
    );
  };

  /*============================================================
                           render
  ============================================================*/
  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[2]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={investPageTitle.icon}
            pageTitle={investPageTitle.title}
          />

          <div className="vl-invest-payment-page-div">
            <h3 className="vl-invest-payment-text-1">Your Investment</h3>
            <div className="row mx-0 align-items-start pt-32">
              <div className="col-md-6 col-12 px-0">
                <InputFieldEmailTextPassword
                  containerClassName="vl-form-input"
                  label="Company Name"
                  name="companyName"
                  value="vedaslab.io"
                  onChange={handleChangeRowOne}
                  type="text"
                  // error={frontEndErrors.companyName}
                  isReadOnly={true}
                />
              </div>
              <div className="col-md-6 col-12 px-0">
                <CurrencyInputCommon
                  containerClassName={
                    isEmpty(frontEndErrors.investmentAmount)
                      ? "vl-form-input"
                      : "vl-form-input vl-form-input--errorColor"
                  }
                  label="Investment Amount ($)"
                  name="investmentAmount"
                  value={valuesRowOne.investmentAmount}
                  onChange={handleChangeInvestmentAmount}
                  error={frontEndErrors.investmentAmount}
                  placeholder={
                    "Investment Minimum is $" + valuesRowOne.min_investment
                  }
                />
              </div>
            </div>

            {isClickEditForm ? renderCalculator() : renderDisplayCalculator()}

            <div className="proceed-to-payment-btn-div row mx-0 justify-content-end">
              <div className="proceed-btn">
                <>
                  <button
                    className={`vl-common-save-btn`}
                    disabled={isProceedPaymentFormValid ? false : true}
                  >
                    <span>Verify Identity</span>
                  </button>
                </>
                <p className="vl-payment-font-button-note">
                  All payments are processed by Prime Trust.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
