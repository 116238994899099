import React, { useEffect } from "react";
import Footer from "./Footer";
import MentorFoldOne from "./MentorFoldOne";
import MentorFoldTwo from "./MentorFoldTwo";
import MentorNavbar from "./MentorNavbar";
import "./_styles.scss";
import SideNavbar from "../../../web-app-components/desktop/header/SideNavbar";
import { mentorDirectoryTitle } from "../../../web-app-components/desktop/header/activeMenu";
import PageTitle from "../../../web-app-components/desktop/header/PageTitle";

/**
 * Mentor Component
 *
 * Overview:
 * The Mentor component is responsible for rendering the mentor section of the application. It displays the mentor background, mentor navbar, mentor fold one, mentor fold two, and the footer. The component checks if there is user data available in the local storage and renders the appropriate UI based on that.
 *
 * Details:
 * - The component uses the `localStorage` API to retrieve the user data.
 * - The component uses the `useEffect` hook to scroll the window to the top when the component mounts.
 * - The component renders the mentor background, mentor navbar, mentor fold one, mentor fold two, and the footer components.
 * - The component conditionally renders the UI based on the availability of user data.
 *
 * Dependencies:
 * - React: The Mentor component is built using React.
 * - localStorage: The Mentor component uses the `localStorage` API to retrieve user data.
 * - MentorNavbar: The Mentor component depends on the MentorNavbar component.
 * - MentorFoldOne: The Mentor component depends on the MentorFoldOne component.
 * - MentorFoldTwo: The Mentor component depends on the MentorFoldTwo component.
 * - Footer: The Mentor component depends on the Footer component.
 */
const Mentor = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      {userData && (
        <PageTitle
          imgPath={mentorDirectoryTitle.icon}
          pageTitle={mentorDirectoryTitle.title}
        />
      )}
      <div className="mentor-bg">
        <div className={`${userData ? "mentor-flex" : ""}`}>
          {!userData ? (
            <div
              className={`background-image-mentor ${
                userData ? "without-btn" : ""
              }`}
            >
              <MentorNavbar />
              <MentorFoldOne />
            </div>
          ) : (
            <SideNavbar {...{ isMentor: true }} />
          )}
          <MentorFoldTwo />
        </div>
        {!userData ? (
          <div className="footer-wrap-mentor">
            <Footer />
          </div>
        ) : null}
      </div>
      {/* )} */}
    </>
  );
};

export default Mentor;
