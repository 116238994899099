import React, { Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { Link } from "react-router-dom";

import { faq, faqWebApp } from "../header-footer/pageLinks";

const dataArray = [
  {
    title: "1. Know the Risks",
  },
  {
    title: "2. Limited transfer",
  },
  {
    title: "3. Diversification",
  },
  {
    title: "4. Cancellation",
  },
  {
    title: "5. Investing Limits",
  },
  {
    title: "6. Research",
  },
  {
    title: "7. Compensation and Types of Securities",
  },
  {
    title: "8. Disclosures to VedasLabs",
  },
  {
    title: "9. Ongoing Disclosures to Investors",
  },
  {
    title: "10. Changing of Deal Terms",
  },
];

const knowTheRisk = [
  {
    title: "Important Risk Factors",
  },
  {
    title: "Speculative",
  },
  {
    title: "Illiquidity",
  },
  {
    title: "No Voting Rights and Dilution",
  },
  {
    title: "Investment in new concepts and technologies",
  },
  {
    title: "Changing economic conditions",
  },
  {
    title: "Future and past performance",
  },
  {
    title: "Difficulty in valuing startup investments",
  },
  {
    title: "Minority investments",
  },
  {
    title: "Lack of information for monitoring and valuing startups",
  },
  {
    title: "No assurance of additional capital for startups",
  },
  {
    title: "Absence of liquidity and public markets",
  },
  {
    title: "Legal and regulatory risks associated with crowdfunding",
  },
  {
    title: "Tax risks",
  },
  {
    title: "Withholding and other taxes",
  },
  {
    title: "Limited operating history of startups",
  },
  {
    title: "Diverse investors",
  },
  {
    title: "Lack of investor control",
  },
  {
    title: "Confidential information",
  },
  {
    title: "Forward-looking statements",
  },
];
/*
KnowBeforeYouInvestFoldOneNew.js
Overview:
This file contains the implementation of the "Know Before You Invest Fold One" component. It is a part of the larger UI structure for the "Know Before You Invest" feature.

Details:
The component is responsible for rendering the first fold of the "Know Before You Invest" section.
It includes sub-components such as Title, Description, and Image.
The component accepts props such as title, description, and imageSrc to customize its content.
The component handles the rendering logic for displaying the title, description, and image in a visually appealing manner.
It may have additional behavior related to event handling or state management, depending on the specific requirements of the application.

*/
export default function KnowBeforeYouInvestFoldOneNew({
  foldOneHeight,
  displayOnWebApp,
}) {
  const renderBlockKnowTheRisks0 = () => {
    return (
      <>
        <p className="kn-font-22-medium-montserrat">
          Every investor (&ldquo;Investor&rdquo;) should be aware that an
          investment in a single company or multiple companies on VedasLabs
          platform (each, a &ldquo;Issuer&rdquo; or &ldquo;Startup&rdquo;)
          involves a high degree of risk, regardless of any assurance provided
          by the Issuer. There can be no assurance that (i) any information or
          projection by the Issuer has been validated or is reliable, (ii) an
          Issuer will achieve its business plan, or (iii) an Investor will
          receive a return of any part of its investment. The following
          considerations, among others, should be carefully evaluated before
          making an investment in an Issuer through its offering on VedasLabs.
        </p>
        <p className="kn-font-22-semibold-montserrat pt-26">
          Risk inherent in startup investments:{" "}
          <span className="kn-font-22-medium-montserrat ">
            An investment in a startup company involves a high degree of risk.
            An investor should be prepared for the possibility of losing all of
            its investment.
          </span>
        </p>
      </>
    );
  };

  const renderBlockKnowTheRisks1 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        Investments in startups involve a high degree of risk. Financial and
        operating risks confronting startups are significant. While targeted
        returns should reflect the perceived level of risk in any investment
        situation, such returns may never be realized and/or may not be adequate
        to compensate an Investor for risks taken. Loss of an Investor’s entire
        investment is possible. Moreover, the timing of any return on investment
        is highly uncertain. The startup market is highly competitive and the
        percentage of companies that survive and prosper is small. Startup
        investments often experience unexpected problems in the areas of product
        development, manufacturing, marketing, financing, and general
        management, among others, which frequently cannot be solved. In
        addition, startups may require substantial amounts of financing, which
        may not be available through institutional private placements, the
        public markets or otherwise.
      </p>
    );
  };
  const renderBlockKnowTheRisks2 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        Your ability to resell your investment in the first year will be
        restricted with narrow exceptions. You may need to hold your investment
        for an indefinite period of time. Unlike investing in companies listed
        on a stock exchange, where you can quickly and easily trade securities,
        you may have to locate an interested private buyer to resell your
        crowdfunded investment. To learn more about the restriction on the
        reselling of private securities please read our
        <Link to={displayOnWebApp ? faqWebApp : faq}>
          <span className="kn-font-22-semibold-montserrat">
            <u> FAQs</u>{" "}
          </span>
        </Link>
        and the{" "}
        <a
          href="https://www.sec.gov/info/smallbus/secg/rccomplianceguide-051316.htm#5"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="kn-font-22-semibold-montserrat">
            {" "}
            <u>SEC Restrictions on Reselling.</u>
          </span>
        </a>
      </p>
    );
  };
  const renderBlockKnowTheRisks3 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        Investors may not receive voting rights if they are receiving Preferred
        Equity. Further, it is likely that any voting rights a crowdfunding
        investor receives will be diluted when a company raises additional
        funds.
      </p>
    );
  };
  const renderBlockKnowTheRisks4 = () => {
    return (
      <>
        <p className="kn-font-22-medium-montserrat">
          The value of an Investor’s investment in startups may be susceptible
          to factors affecting the relevant industry and/or to greater risk than
          an investment in a vehicle that invests in a broader range of
          securities. Some of the many specific risks faced by such startups
          include:
        </p>
        <ul className="kn-font-22-medium-montserrat pl-30 pt-40">
          <li>Rapidly changing technologies;</li>
          <li>Products or technologies that may quickly become obsolete;</li>
          <li>
            Scarcity of management, technical, scientific, research and
            marketing personnel with appropriate training;
          </li>
          <li>
            {" "}
            The possibility of lawsuits related to patents and intellectual
            property;
          </li>
        </ul>
        <p className="kn-font-22-medium-montserrat pt-20">
          Rapidly changing investor sentiments and preferences with regard to
          technology sector investments (which are generally perceived as
          risky); and Exposure to government regulation, making these companies
          susceptible to changes in government policy and delays or failures in
          securing regulatory approvals.
        </p>
      </>
    );
  };
  const renderBlockKnowTheRisks5 = () => {
    return (
      <>
        <p className="kn-font-22-medium-montserrat">
          The success of any investment activity is determined to some degree by
          general economic conditions. The availability, unavailability, or
          hindered operation of external credit markets, equity markets and
          other economic systems which an individual startup may depend upon to
          achieve its objectives may have a significant negative impact on a
          startup’s operations and profitability. The stability and
          sustainability of growth in global economies may be impacted by
          terrorism, acts of war or a variety of other unpredictable events.
          There can be no assurance that such markets and economic systems will
          be available or will be available as anticipated or needed for an
          investment in a startup to be successful.
        </p>
      </>
    );
  };
  const renderBlockKnowTheRisks6 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        The past performance of a startup or its management is not predictive of
        a startup’s future results. There can be no assurance that targeted
        results will be achieved. Loss of principal is possible, and even
        likely, on any given investment.
      </p>
    );
  };
  const renderBlockKnowTheRisks7 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        It is enormously difficult to determine objective values for any
        startup. In addition to the difficulty of determining the magnitude of
        the risks applicable to a given startup and the likelihood that a given
        startup’s business will be a success, there generally will be no readily
        available market for a startup’s equity securities, and hence, an
        Investor’s investments will be difficult to value.
      </p>
    );
  };
  const renderBlockKnowTheRisks8 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        A significant portion of an Investor’s investments will represent
        minority stakes in privately held companies. An Investor’s shares in a
        startup may be non-voting shares. Even with voting shares, as is the
        case with minority holdings in general, such minority stakes will have
        neither the control characteristics of majority stakes nor the valuation
        premiums accorded majority or controlling stakes. Investors will be
        reliant on the existing management and board of directors of such
        companies, which may include representatives of other financial
        investors with whom the Investor is not affiliated and whose interests
        may conflict with the interests of the Investor.
      </p>
    );
  };
  const renderBlockKnowTheRisks9 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        The Investor may not be able to obtain all information it would want
        regarding a particular startup, on a timely basis or at all. It is
        possible that the Investor may not be aware on a timely basis of
        material adverse changes that have occurred with respect to certain of
        its investments. As a result of these difficulties, as well as other
        uncertainties, an Investor may not have accurate information about a
        startup’s current value.
      </p>
    );
  };
  const renderBlockKnowTheRisks10 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        After an Investor has invested in a startup, continued development and
        marketing of the startup’s products or services, or administrative,
        legal, regulatory or other needs, may require that it obtain additional
        financing. In particular, startups generally have substantial capital
        needs that are typically funded over several stages of investment. Such
        additional financing may not be available on favorable terms, or at all.
      </p>
    );
  };
  const renderBlockKnowTheRisks11 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        An Investor’s investments will generally be private, illiquid holdings.
        As such, there will be no public markets for the securities held by the
        Investor, and no readily available liquidity mechanism at any particular
        time for any of the investments.
      </p>
    );
  };
  const renderBlockKnowTheRisks12 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        There is no assurance that a startup will comply with all requirements
        mandated by federal laws permitting private companies to fundraise from
        retail investors on a Title III crowdfunding portal such as VedasLabs,
        whether before, during or after its offering on VedasLabs.
      </p>
    );
  };
  const renderBlockKnowTheRisks13 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        There are many tax risks relating to investments in startups that are
        difficult to address and complicated. You should consult your tax
        advisor for information about the tax consequences of purchasing equity
        securities of a startup.
      </p>
    );
  };
  const renderBlockKnowTheRisks14 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        The structure of any investment in a startup may not be tax efficient
        for any particular Investor, and no startup guarantees that any
        particular tax result will be achieved. In addition, tax reporting
        requirements may be imposed on Investors under the laws of the
        jurisdictions in which Investors are liable for taxation. Investors
        should consult their own professional advisors with respect to the tax
        consequences to them of an investment in a startup under the laws of the
        jurisdictions in which the Investors and/or the startup are liable for
        taxation.
      </p>
    );
  };
  const renderBlockKnowTheRisks15 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        A startup may be a newly formed entity with little or no operating
        history. Each offering should be evaluated on the basis that the
        startup’s business plan and projections may not prove accurate and that
        the startup will not achieve its objective. Past performance of a
        startup or its team is not predictive of future results.
      </p>
    );
  };
  const renderBlockKnowTheRisks16 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        Investors and employees in a startup may have conflicting investment,
        tax, and other interests with respect to startup ownership, which may
        arise from the structuring of the startup or the timing of a sale of the
        startup or other factors. As a consequence, decisions made by the
        startup management on such matters may be more beneficial for some
        Investors than for others. Investors should be aware that startup
        management tends to consider the investment and tax objective of its
        shareholders as a whole when making decisions on investment structure or
        timing of sale, and not the circumstances of any Investor individually.
      </p>
    );
  };
  const renderBlockKnowTheRisks17 = () => {
    return (
      <p className="kn-font-22-medium-montserrat ">
        Investors in a startup will not make decisions with respect to the
        startup’s business and affairs.
      </p>
    );
  };
  const renderBlockKnowTheRisks18 = () => {
    return (
      <p className="kn-font-22-medium-montserrat">
        Certain information regarding the startups will be highly confidential.
        Competitors may benefit from such information if it is ever made public,
        and that could result in adverse economic consequences to the Investors.
      </p>
    );
  };
  const renderBlockKnowTheRisks19 = () => {
    return (
      <>
        <p className="kn-font-22-medium-montserrat">
          The information a startup makes available to Investors may contain
          &ldquo;forward-looking statements&rdquo; within the meaning of the
          Private Securities Litigation Reform Act of 1995. These statements can
          be identified by the fact that they do not relate strictly to
          historical or current facts. Forward-looking statements often include
          words such as &ldquo;anticipates,&rdquo; &ldquo;estimates,&rdquo;
          &ldquo;expects,&rdquo; &ldquo;projects,&rdquo; &ldquo;intends,&rdquo;
          &ldquo;plans,&rdquo; &ldquo;believes&rdquo; and words and terms of
          similar substance in connection with discussions of future operating
          or financial performance. Examples of forward-looking statements
          include, but are not limited to, statements regarding: (i) the
          adequacy of a startup’s funding to meet its future needs, (ii) the
          revenue and expenses expected over the life of the startup, (iii) the
          market for a startup’s goods or services, or (iv) other similar
          matters. Each startup’s forward-looking statements are based on
          management’s current expectations and assumptions regarding the
          startup’s business and performance, the economy and other future
          conditions and forecasts of future events, circumstances and results.
          As with any projection or forecast, forward-looking statements are
          inherently susceptible to uncertainty and changes in circumstances.
          The startup’s actual results may vary materially from those expressed
          or implied in its forward-looking statements. Important factors that
          could cause the startup’s actual results to differ materially from
          those in its forward-looking statements include government regulation,
          economic, strategic, political and social conditions and the following
          factors:
        </p>
        <ul className="pl-30 pt-50 kn-font-22-medium-montserrat">
          <li>
            recent and future changes in technology, services and standards;
          </li>
          <li>changes in consumer behavior;</li>
          <li>
            changes in a startup’s plans, initiatives and strategies, and
            consumer acceptance thereof;
          </li>
          <li>
            changes in the plans, initiatives and strategies of the third
            parties that are necessary or important to the startup’s success;
          </li>
          <li>
            competitive pressures, including as a result of changes in
            technology;
          </li>
          <li>
            the startup’s ability to deal effectively with economic slowdowns or
            other economic or market difficulties;
          </li>
          <li>
            {" "}
            increased volatility or decreased liquidity in the capital markets,
            including any limitation on the startup’s ability to access the
            capital markets for debt securities, refinance its outstanding
            indebtedness or obtain equity, debt or bank financings on acceptable
            terms;
          </li>
          <li>the failure to meet earnings expectations;</li>
          <li>the adequacy of the startup’s risk management framework;</li>
          <li>changes in U.S. GAAP or other applicable accounting policies;</li>
          <li>
            the impact of terrorist acts, hostilities, natural disasters
            (including extreme weather) and pandemic viruses;
          </li>
          <li>
            a disruption or failure of the startup’s or its vendors’ network and
            information systems or other technology on which the Company’s
            businesses rely;{" "}
          </li>
          <li>
            {" "}
            changes in tax, federal communication and other laws and
            regulations;
          </li>
          <li>
            changes in foreign exchange rates and in the stability and existence
            of foreign currencies; and &#60;
          </li>
          <li>
            {" "}
            other risks and uncertainties which may or may not be specifically
            discussed in materials provided to Investors.
          </li>
        </ul>
        <p className="kn-font-22-medium-montserrat pt-61">
          Any forward-looking statement made by a startup speaks only as of the
          date on which it is made. Startups are under no obligation to, and
          generally expressly disclaim any obligation to, update or alter their
          forward-looking statements, whether as a result of new information,
          subsequent events or otherwise. The foregoing risks do not purport to
          be a complete explanation of all the risks involved in acquiring
          equity or debt securities in a startup. Each Investor is urged to seek
          its own independent legal and tax advice and read the relevant
          investment documents before making a determination whether to invest
          in a startup through VedasLabs.
        </p>
      </>
    );
  };

  const handleOnClickAccordionButtonKTR = (key) => (e) => {
    let elementHeightVal = document.getElementById(`accordion-heading-ktr-id-0`)
      .offsetHeight;

    let keyVal = key + 2.5;

    elementHeightVal = elementHeightVal * keyVal;

    let scrollToVal = elementHeightVal + foldOneHeight;
    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };

  const renderBlock1 = () => {
    return (
      <>
        <div className="know-before-you-invest-text-block-desc-div">
          <p className="kn-font-24-regular-montserrat">
            Investing in startups is very risky. You should consider the risks
            involved in investing in crowdfunding to determine whether investing
            in crowdfunding securities is right for you and you should only
            invest an amount you can afford to lose completely without changing
            your lifestyle.
          </p>
        </div>
        <div className="faq-second-div faq-second-div--inner-accordion">
          <Accordion className="accordion" allowZeroExpanded={true}>
            {knowTheRisk.map((data, index) => (
              <Fragment key={index}>
                <AccordionItem key={data.title}>
                  <AccordionItemHeading
                    id={`accordion-heading-ktr-id-${index}`}
                    onClick={handleOnClickAccordionButtonKTR(index)}
                  >
                    <AccordionItemButton>{data.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {index === 0 ? (
                      <>{renderBlockKnowTheRisks0()}</>
                    ) : index === 1 ? (
                      <>{renderBlockKnowTheRisks1()} </>
                    ) : index === 2 ? (
                      <>{renderBlockKnowTheRisks2()}</>
                    ) : index === 3 ? (
                      <>{renderBlockKnowTheRisks3()} </>
                    ) : index === 4 ? (
                      <>{renderBlockKnowTheRisks4()}</>
                    ) : index === 5 ? (
                      <>{renderBlockKnowTheRisks5()} </>
                    ) : index === 6 ? (
                      <>{renderBlockKnowTheRisks6()}</>
                    ) : index === 7 ? (
                      <>{renderBlockKnowTheRisks7()} </>
                    ) : index === 8 ? (
                      <>{renderBlockKnowTheRisks8()} </>
                    ) : index === 9 ? (
                      <>{renderBlockKnowTheRisks9()} </>
                    ) : index === 10 ? (
                      <>{renderBlockKnowTheRisks10()} </>
                    ) : index === 11 ? (
                      <>{renderBlockKnowTheRisks11()} </>
                    ) : index === 12 ? (
                      <>{renderBlockKnowTheRisks12()} </>
                    ) : index === 13 ? (
                      <>{renderBlockKnowTheRisks13()} </>
                    ) : index === 14 ? (
                      <>{renderBlockKnowTheRisks14()} </>
                    ) : index === 15 ? (
                      <>{renderBlockKnowTheRisks15()} </>
                    ) : index === 16 ? (
                      <>{renderBlockKnowTheRisks16()} </>
                    ) : index === 17 ? (
                      <>{renderBlockKnowTheRisks17()} </>
                    ) : index === 18 ? (
                      <>{renderBlockKnowTheRisks18()} </>
                    ) : (
                      index === 19 && <>{renderBlockKnowTheRisks19()} </>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </Fragment>
            ))}
          </Accordion>
        </div>
      </>
    );
  };

  const renderBlock2 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          It may be difficult to transfer the securities acquired on VedasLabs.
          In addition, there are restrictions on selling, trading, or
          transferring the securities for the first 12 months.{" "}
          <a
            href="https://www.sec.gov/info/smallbus/secg/rccomplianceguide-051316.htm#5"
            rel="noopener noreferrer"
            target="_blank"
          >
            <u> Learn more</u>
          </a>
        </p>
        <p className="kn-font-24-regular-montserrat pt-30">
          Can I sell securities acquired on VedasLabs?
        </p>
        <p className="kn-font-24-regular-montserrat">
          Because the startup company issuing the securities is private, you
          cannot sell your securities on the public market, making it
          potentially difficult to find a buyer. In fact, you are restricted
          from reselling your securities in the first 12 months post closing of
          the offering, unless the shares are transferred:
        </p>
        <ul className="pl-30 kn-font-24-regular-montserrat">
          <li> to the company that issued the securities</li>
          <li> to an accredited investor</li>
          <li>
            to a nuclear family member: a child, stepchild, grandchild, parent,
            stepparent, grandparent, spouse or spousal equivalent, sibling,
            mother-in-law, father-in-law, son-in-law, daughter-in-law,
            brother-in-law, or sister-in-law, including adoptive relationships.
          </li>
          <li>
            in connection with your death, divorce, or other similar
            circumstance
          </li>
          <li>
            to a trust controlled by you or a trust created for the benefit of a
            family member (defined as a child, sibling or parent of you or your
            spouse) or
          </li>
          <li>as part of a later offering registered with the SEC.</li>
        </ul>
        <p className="kn-font-24-regular-montserrat pt-26">
          Any transfer during this period is still subject to state and foreign
          laws. You should know that there may be no market for the securities
          after the initial 12 month restricted period. Once the 12 month
          restricted period ends, any sale or disposition of the securities you
          hold must comply with applicable federal, state and foreign laws. It
          is important that you only invest capital with the expectation of
          holding your investment for an indefinite period of time, and with the
          real risk of a total loss of your investment in mind. Only invest an
          amount you can afford to lose without changing your lifestyle.
        </p>
      </div>
    );
  };

  const renderBlock3 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          Crowdfunding investing is highly speculative and every investment may
          result in a loss. By investing small amounts across multiple deals,
          you can reduce your risk compared to a large investment in a single
          company.
        </p>
      </div>
    );
  };

  const renderBlock4 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          Investments can be cancelled up to 48 hours before the company’s
          pre-set funding deadline. After that, your investment will be final,
          and you will not be able to get your money back.
        </p>
        <p className="kn-font-24-regular-montserrat pt-26">
          Companies reserve the right to reject, in whole or in part, any
          investment commitment at any time before the proceeds are drawn from
          the escrow account. Any rejected investments will be returned to the
          investor in full.
        </p>
      </div>
    );
  };

  const renderBlock5 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
        <p className="kn-font-24-regular-montserrat">
          The limitation on how much you can invest depends on your net worth and annual income.
        </p>
        <p className="kn-font-24-regular-montserrat">
        If either your annual income or your net worth is less than $107,000, then during
        any 12-month period, you can invest up to the greater of either $2,200 or 5% of the
        greater of your annual income or net worth.
        </p>
        If both your annual income and your net worth are equal to or more than $107,000,
        then during any 12-month period, you can invest up to 10% of annual income or net worth,
        whichever is greater, but not to exceed $107,000.
        <br />
        If you are an accredited investor, as defined under Rule 501 of Regulation D of the Securities Act,
        then there are no limits to the amount you may invest under Regulation Crowdfunding.
        For more information on the definition of an accredited investor please refer to {" "}

          <a
            href="https://www.sec.gov/oiea/investor-alerts-bulletins/ib_crowdfunding-.html"
            rel="noopener noreferrer"
            target="_blank"
          >
            <u>
              {" "}
              SEC Investor Alerts and Bulletin for Regulation Crowdfunding.
            </u>
          </a>
        </p>
      </div>
    );
  };

  const renderBlock6 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          Do your own research. Read the documents provided by each company. Get
          independent legal, accounting, financial advice. If you have any
          questions or need more information, ask the company in the discussion
          section.
        </p>
      </div>
    );
  };

  const renderBlock7 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-semibold-montserrat">VedasLabs Compensation</p>
        <p className="kn-font-24-regular-montserrat">
          {/* VedasLabs is compensated 6% of the total amount raised by the issuer
      along with an administrative fee that ranges from $2,500 to $7,500. */}
          VedasLabs is compensated 6% of the total capital raised by the issuer
          under Regulation Crowdfunding along with an administrative fee of
          $5,000 that is deferred and collected when the offering is complete.
        </p>
        <p className="kn-font-24-semibold-montserrat pt-20">
          Types of Securities
        </p>
        <p className="kn-font-24-regular-montserrat">
          On VedasLabs, you will find two types of securities: common equity and
          preferred equity. For both types of equity you own part of the company
          in which you’re investing. Note that your shares are subject to
          dilution should the issuer decide to issue more shares in a later
          round.
        </p>
        <p className="kn-font-24-italic-montserrat pt-20">
          Understanding Preferred Equity vs. Common Equity
        </p>
        <p className="kn-font-24-semibold-montserrat pt-20">
          Preferred Equity –{" "}
          <span className="kn-font-24-regular-montserrat">
            Represents ownership in a company but has no voting rights when it
            comes to electing a board of directors or voting on the future of
            the company. In a liquidation, preferred stockholders have a greater
            claim to a company’s assets and earnings over common stock holders.
          </span>
        </p>
        <p className="kn-font-24-semibold-montserrat pt-20">
          Common Equity –{" "}
          <span className="kn-font-24-regular-montserrat">
            Represents ownership in a company with voting rights when it comes
            to electing a board of directors or voting on the future of the
            company. In a liquidation, preferred stockholders have a greater
            claim to a company’s assets and earnings over common stock holders.
          </span>
        </p>
      </div>
    );
  };

  const renderBlock8 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          The company must disclose information about itself, its business plan,
          the offering, and its anticipated use of proceeds, among other things.
          The type of financial information disclosed may vary based on the
          amount being raised or whether the company has previously conducted a
          crowdfunding offering.
        </p>
        <p className="kn-font-24-regular-montserrat">
          It’s important to note that an early-stage company may only be able to
          provide limited information about its business plan and operations
          because it is still developing its operations.
        </p>
        <p className="kn-font-24-regular-montserrat">
          Prior to being listed on VedasLabs, companies are required to disclose
          the following information:
        </p>
        <ul className="pt-26 pl-30 kn-font-24-regular-montserrat">
          <li> Pitch Deck (if available)</li>
          <li>Business Description</li>
          <li>Funding Goal</li>
          <li>Funding Deadline</li>
          <li>Use of Funds</li>
          <li>Information on the Officers and Directors</li>
          <li>Financial Information</li>
          <li>Specific risks to the Company</li>
          <li>Current Traction</li>
        </ul>
      </div>
    );
  };

  const renderBlock9 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          The company must disclose information about itself, its business plan,
          the offering, and its anticipated use of proceeds, among other things.
          The type of financial information disclosed may vary based on the
          amount being raised or whether the company has previously conducted a
          crowdfunding offering.
        </p>
        <p className="kn-font-24-regular-montserrat">
          $107,000 or less – financial statements and certain specific line
          items from income tax returns are required, both of which are
          certified by the principal executive officer of the company.
        </p>
        <p className="kn-font-24-regular-montserrat">
          $107,000.01 to $535,000 – financial statements are reviewed by an
          independent public accountant and the accountant’s review report is
          provided as well as certification by the principal executive officer
          of the company. A review is some level of scrutiny of the financials
          by a CPA.
        </p>
        <p className="kn-font-24-regular-montserrat">
          $535,000.01 to $1.07 million – if first time crowdfunding, then
          financial statements reviewed by an independent public accountant and
          the accountant’s review report if available are disclosed: otherwise
          financial statements audited by an independent public accountant and
          the accountant’s audit report must be prepared and disclosed. An audit
          provides a higher level of scrutiny by the accountant than a review as
          well as some verification by the accountant.
        </p>
        <p className="kn-font-24-regular-montserrat">
          It’s important to note that an early-stage company may only be able to
          provide limited information about its business plan and operations
          because it is still developing its operations.
        </p>
        <p className="kn-font-24-regular-montserrat">
          Prior to being listed on VedasLabs, companies are required to disclose
          the following information:
        </p>
        <ul className="pt-30 pl-30 kn-font-24-regular-montserrat">
          <li>Pitch Deck (if available)</li>
          <li>Business Description</li>
          <li>Funding Goal</li>
          <li>Funding Deadline</li>
          <li>Use of Funds</li>
          <li>Information on the Officers and Directors</li>
          <li>Financial Information</li>
          <li>Specific risks to the Company</li>
          <li>Current Traction</li>
        </ul>
        <p className="kn-font-24-regular-montserrat pt-20">
          Once an offering has closed, the company shall provide updates to
          investors on the results of its operations and financial progress
          through its website on an annual basis. These updates are often less
          regular and less detailed than those provided by public companies to
          their shareholders. Under certain circumstances the company may cease
          to publish annual reports and investors may have no information rights
          and there is no guarantee the company will retain any relationship
          with VedasLabs following the end of their round.
        </p>
      </div>
    );
  };

  const renderBlock10 = () => {
    return (
      <div className="know-before-you-invest-text-block-desc-div">
        <p className="kn-font-24-regular-montserrat">
          If there are any material changes to the offering information while
          the campaign is running, they are required to notify investors
          reconfirm their investment. Investors will have 5 business days after
          the confirmation request is sent to respond.
        </p>
        <p className="kn-font-24-regular-montserrat pt-30">
          Investments that are not confirmed after 5 business days will be
          cancelled and refunded. Any future funding towards the campaign will
          be considered a new investment.
        </p>
      </div>
    );
  };

  const handleOnClickAccordionButton = (key) => (e) => {
    let elementHeightVal = document.getElementById(`accordion-heading-id-0`)
      .offsetHeight;

    let keyVal = key + 0.5;

    elementHeightVal = elementHeightVal * keyVal;

    let scrollToVal = elementHeightVal + foldOneHeight;
    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };

  return (
    <>
      <div className="faq-second-div">
        <div className="know-before-you-invest-text-block know-before-you-invest-text-block--new">
          <Accordion
            className="accordion"
            //allowMultipleExpanded={true}
            allowZeroExpanded={true}
          >
            {dataArray.map((data, key) => (
              <Fragment key={key}>
                <AccordionItem key={data.title}>
                  <AccordionItemHeading
                    id={`accordion-heading-id-${key}`}
                    onClick={handleOnClickAccordionButton(key)}
                  >
                    <AccordionItemButton>
                      {data.title}
                      {/* <AccordionItemState>{"expanded"}</AccordionItemState> */}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {key === 0 ? (
                      <>{renderBlock1()}</>
                    ) : key === 1 ? (
                      <>{renderBlock2()} </>
                    ) : key === 2 ? (
                      <>{renderBlock3()}</>
                    ) : key === 3 ? (
                      <>{renderBlock4()} </>
                    ) : key === 4 ? (
                      <>{renderBlock5()}</>
                    ) : key === 5 ? (
                      <>{renderBlock6()} </>
                    ) : key === 6 ? (
                      <>{renderBlock7()}</>
                    ) : key === 7 ? (
                      <>{renderBlock8()} </>
                    ) : key === 8 ? (
                      <>{renderBlock9()} </>
                    ) : (
                      <>{renderBlock10()}</>
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </Fragment>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
}
