import React, { useEffect } from "react";
import Footer from "../header-footer/Footer";
import NewNavbar from "../home/NewNavbar";

/**
 * CareerDetails Component
 * 
 * Overview:
 * The CareerDetails component displays the details of a specific career page. It includes information such as the job title, location, and job description. Users can also apply for the job by clicking on the "Apply" button.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - It includes a sub-component called SectionA, which renders the job description using the dangerouslySetInnerHTML prop.
 * - The removeTags function is used to remove HTML tags from the job description.
 * - The handleMail function opens the default email client with a pre-filled email body and subject.
 * - The handleClick function is called when the back button is clicked and sets the "show" state to false.
 * 
 * Usage:
 * To use the CareerDetails component, pass the careerPageDetails and setShow props to it. The careerPageDetails prop should contain the details of the career page to be displayed. The setShow prop is used to control the visibility of the component.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - NewNavbar: The component uses the NewNavbar component for the navigation bar.
 * - Footer: The component includes the Footer component at the bottom.
 */
const CareerDetails = (props) => {
  const { careerPageDetails, setShow } = props;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const SectionA = ({ dec }) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: dec }} className="discription" />
    );
  };

  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();
    return str.replace(/&nbsp;/gi, " ");
  };

  const handleMail = (item) => {
    window.open(`mailto:info@vedaslabs.io?body=Hi&subject=${item.title}`);
  };

  const handleClick = () => {
    setShow(false);
  };

  return (
    <div className="career-main">
      <NewNavbar />
      <div className="career-page-wrapper">
        <button className="career-back-btn" onClick={() => handleClick()}>
          <i class="fa fa-angle-left"></i>
        </button>
        <div className="container career-profile-details">
          <div className="row card-details">
            {/* <div className="col-12 col-sm-6 col-md-6 col-lg-6 text-center">
              <img
                src={careerPageDetails?.image.url}
                alt="banner"
                className="career-image"
              />
            </div> */}
            <div className="col-12 mb-5 mt-4">
              <p className="career-title">Jobs</p>
              <div className="user-name">
                <p className="mb-3 main-title">{careerPageDetails?.title}</p>
                <div className="d-flex flex-wrap mt-2 align-items-baseline">
                  <p className="education-title m-0">Location : </p>
                  <p className="education-subtitle">
                    {" "}
                    {careerPageDetails?.location}
                  </p>
                </div>
                <div className="d-flex flex-wrap mt-2 align-items-baseline">
                  <p className="education-title m-0">Job Description : </p>
                  <SectionA dec={removeTags(careerPageDetails?.discription)} />
                </div>
              </div>
              <button
                className="submit-data text-uppercase "
                onClick={() => handleMail(careerPageDetails)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CareerDetails;
