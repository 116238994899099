import { CAREER_PAGE } from "./../types"; 

const initialState = {
  careerAllFeeds: {},
};

/**
 * careerReducer
 * 
 * Overview:
 * This reducer function handles the state updates for the career page in the web app store. It is responsible for updating the careerAllFeeds property in the state based on the dispatched actions.
 * 
 * Details:
 * - The reducer function takes two parameters: state and action.
 * - The state parameter represents the current state of the career page.
 * - The action parameter represents the dispatched action object.
 * - The reducer function uses a switch statement to handle different action types.
 * - When the action type is CAREER_PAGE, the reducer returns a new state object with the careerAllFeeds property updated with the payload from the action.
 * - For any other action type, the reducer returns the current state without any modifications.
 * 
 * Usage:
 * This reducer function is typically used in conjunction with the Redux store and the combineReducers function to manage the state of the web app store. It can be imported and used in other parts of the application where state updates for the career page are required.
 * 
 * Dependencies:
 * This reducer function does not have any external dependencies.
 */
export default function careerReducer(state = initialState, action) {
  switch (action.type) {
    case CAREER_PAGE:
      return {
        ...state,
        careerAllFeeds: action.payload,
      };

    default:
      return state;
  }
}
