import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import InvestPersonalInfo from "./InvestPersonalInfo";
import InvestPreferences from "./InvestPreferences";
import InvestCalculator from "./InvestCalculator";
import InvestInvestmentPortfolio from "./InvestInvestmentPortfolio";

// api
import isEmpty from "../../../web-app-store/validations/is-empty";
import { useSelector } from "react-redux";

/**
 * InvestProfile Component
 *
 * Overview:
 * The InvestProfile component is responsible for rendering the investor profile setup pages. It allows users to create their investor profile and provides a tabbed interface for completing the profile setup process.
 *
 * Details:
 * - The component uses the useEffect hook to scroll to the top of the page when it mounts.
 * - It also uses the useEffect hook to check if the investor object is not empty, and if so, it automatically navigates to the second profile setup page.
 * - The component maintains the profileSetUpPageCount state to determine which profile setup page to render.
 * - The handleOnClickCreateProfile function is called when the user clicks the "Create your investor profile" button, which updates the profileSetUpPageCount state and stores the activeVLInvestSetupPageIndex in the localStorage.
 * - The component renders two profile setup pages: renderProfileSetupPage1 and renderProfileSetupPage2.
 * - renderProfileSetupPage1 displays an image, a heading, and a button to create the investor profile.
 * - renderProfileSetupPage2 displays a tabbed interface using the react-tabs library. It includes four tabs: Personal Info, Calculator, Preferences, and Additional Information. Each tab is rendered using a separate component.
 * - The component uses the activeTabIndexVal state to keep track of the active tab and stores it in the localStorage.
 *
 * Dependencies:
 * - react: The component uses React to define and render the InvestProfile component.
 * - react-dom: The component uses ReactDOM to render the InvestProfile component.
 * - react-redux: The component uses react-redux to connect to the Redux store and access the investor state.
 * - react-tabs: The component uses react-tabs to render the tabbed interface for the profile setup pages.
 * - Other components: The component depends on several other components such as InvestPersonalInfo, InvestCalculator, InvestPreferences, and InvestInvestmentPortfolio, which are responsible for rendering the content of each tab in the profile setup pages.
 */
export default function InvestProfile() {
  useEffect(() => {
    window.scrollTo(0, 0);

    /* on component will unmount */
    return () => {
      /* remove activeVLInvestTabIndex */
      localStorage.removeItem("activeVLInvestTabIndex");
      /* remove activeVLInvestSetupPageIndex */
      localStorage.removeItem("activeVLInvestSetupPageIndex");
    };
  }, []);

  const [profileSetUpPageCount, setProfileSetUpPageCount] = useState(
    isNaN(parseInt(localStorage.getItem("activeVLInvestSetupPageIndex")))
      ? 1
      : parseInt(localStorage.getItem("activeVLInvestSetupPageIndex"))
  );

  const handleOnClickCreateProfile = () => {
    setProfileSetUpPageCount(2);
    localStorage.setItem("activeVLInvestSetupPageIndex", 2);
  };

  const investor = useSelector((state) => state.invest.investor);

  useEffect(() => {
    if (!isEmpty(investor)) {
      handleOnClickCreateProfile();
    }
  }, [investor]);

  /*================================================================
        renderProfileSetupPage1
  ================================================================*/
  const renderProfileSetupPage1 = () => {
    return (
      <div className="row mx-0 align-items-center justify-content-center vl-mentor-page-one-main-block">
        <div className="vl-mentor-page-one-main-block__div">
          <div className="vl-invest-profile-page-setup-one-img-block">
            <img
              src="/web-app-img/desktop/startup-illustrations/invest-startup.svg"
              alt=""
            />
          </div>
          <h2 className="raise-capital-font-42-montserrat-bold text-lins">
            Invest in High Impact Startups
          </h2>
          {/* <h5 className="raise-capital-font-20-montserrat-medium">
            Listed from different markets as per their potential
          </h5> */}
          <button
            className="raise-capital-startup-btn raise_capital_button_wrap mt-28"
            onClick={handleOnClickCreateProfile}
          >
            <span>Provide a little more information to Start Investing</span>
          </button>
        </div>
      </div>
    );
  };

  /*================================================================
        renderProfileSetupPage2
  ================================================================*/
  const [activeTabIndexVal, setActiveTabIndexVal] = useState(
    isNaN(parseInt(localStorage.getItem("activeVLInvestTabIndex")))
      ? 0
      : parseInt(localStorage.getItem("activeVLInvestTabIndex"))
  );

  const handleOnSelectTab = (val) => {
    setActiveTabIndexVal(val);
    localStorage.setItem("activeVLInvestTabIndex", val);
  };

  // renderProfileSetupPage2
  const renderProfileSetupPage2 = () => {
    return (
      <>
        <div className="vl-mentor-form-tabs-title-div">
          <h3 className="raise-capital-font-20-roboto-bold mb-0">
            Complete your profile to get started
          </h3>
        </div>
        <div className="vl-tabs-explore vl-tabs-explore--mentor investor_details_wrap">
          <Tabs selectedIndex={activeTabIndexVal} onSelect={handleOnSelectTab}>
            <TabList>
              {/* <Tab disabled={activeTabIndexVal !== 0 ? true : false}>
                <span>1.</span> Personal Info
              </Tab> */}
              <Tab disabled={activeTabIndexVal !== 0 ? true : false}>
                <span>1.</span> Calculator
              </Tab>
              <Tab disabled={activeTabIndexVal !== 1 ? true : false}>
                <span>2.</span> Interests
              </Tab>
              <Tab disabled={activeTabIndexVal !== 2 ? true : false}>
                <span>3.</span> Experience
              </Tab>
            </TabList>
            {/* <TabPanel>
              <InvestPersonalInfo handleOnSelectTab={handleOnSelectTab} />
            </TabPanel> */}
            <TabPanel>
              <InvestCalculator handleOnSelectTab={handleOnSelectTab} />
            </TabPanel>
            <TabPanel>
              <InvestPreferences handleOnSelectTab={handleOnSelectTab} />
            </TabPanel>
            <TabPanel>
              <InvestInvestmentPortfolio
                handleOnSelectTab={handleOnSelectTab}
              />
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  };

  /*================================================================
        main
  ================================================================*/
  return (
    <>
      {profileSetUpPageCount === 1
        ? renderProfileSetupPage1()
        : profileSetUpPageCount === 2 && renderProfileSetupPage2()}
    </>
  );
}
