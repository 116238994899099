import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RaiseCapitalBasic from "./RaiseCapitalBasic";
import RaiseCapitalBasicDisplay from "./RaiseCapitalBasicDisplay";
import RaiseCapitalMoreInfo from "./RaiseCapitalMoreInfo";
import RaiseCapitalMoreInfoDisplay from "./RaiseCapitalMoreInfoDisplay";
import RaiseCapitalPitchAndProduct from "./RaiseCapitalPitchAndProduct";
import RaiseCapitalPitchAndProductDisplay from "./RaiseCapitalPitchAndProductDisplay";
import RaiseCapitalTeam from "./RaiseCapitalTeam";
import RaiseCapitalTeamDisplay from "./RaiseCapitalTeamDisplay";
import RaiseSetUpFundraiser from "./RaiseSetUpFundraiser";
import RaiseSetUpFundraiserDisplay from "./RaiseSetUpFundraiserDisplay";
import NewRaiseCapitalProfilePage from "./NewRaiseCapitalProfilePage";

/**
 * RaiseCapitalAfterProfileSetupProfileTabpanel Component
 *
 * Overview:
 * This component represents the profile tab panel for the Raise Capital feature after the profile setup. It allows the user to navigate between different tabs and view/edit the details related to raising capital.
 *
 * Details:
 * - The component uses the `useSelector` hook from Redux to access the `raiseCapital` state.
 * - It uses the `useEffect` hook to handle component unmount and remove a specific item from the local storage.
 * - The component maintains the active tab index using the `useState` hook and stores it in the local storage.
 * - It also manages a boolean state `isDoubleClick` to handle double-click behavior.
 * - The component renders a set of tabs using the `Tabs`, `TabList`, and `TabPanel` components from the `react-tabs` library.
 * - Depending on the `isDoubleClick` state, it renders different sub-components for each tab.
 *
 * Dependencies:
 * - React
 * - Redux
 * - react-redux
 * - react-tabs
 */
export default function RaiseCapitalAfterProfileSetupProfileTabpanel() {
  const raiseCapital = useSelector((state) => state.raiseCapital);
  const [isEdit, setIsEdit] = useState(false);

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );

  useEffect(() => {
    /* on component will unmount */
    return () => {
      /* remove activeVLRaiseCapitalAfterProfileSetupPageProfileTabIndex */
      localStorage.removeItem(
        "activeVLRaiseCapitalAfterProfileSetupPageProfileTabIndex"
      );
    };
  }, []);

  /*================================================================
            renderContent
      ================================================================*/
  const [activeTabIndexVal, setActiveTabIndexVal] = useState(
    isNaN(
      parseInt(
        localStorage.getItem(
          "activeVLRaiseCapitalAfterProfileSetupPageProfileTabIndex"
        )
      )
    )
      ? 0
      : parseInt(
          localStorage.getItem(
            "activeVLRaiseCapitalAfterProfileSetupPageProfileTabIndex"
          )
        )
  );

  const handleOnSelectTab = (val) => {
    setActiveTabIndexVal(val);
    localStorage.setItem(
      "activeVLRaiseCapitalAfterProfileSetupPageProfileTabIndex",
      val
    );
  };

  const [isDoubleClick, setIsDobleClick] = useState(false);

  const handleOnDoubleClick = () => {
    const offeringStatus =
      raiseCapital.raiseCapitalOfferings[0].offering_status;
    if (
      offeringStatus !== "Offering_Preparation" &&
      offeringStatus !== "Offering_Filed" &&
      offeringStatus !== "Offering_Posted" &&
      offeringStatus !== "Accepting_Investment" &&
      offeringStatus !== "Offering_Closed" &&
      offeringStatus !== "VL_Reserve"
    ) {
      setIsDobleClick(!isDoubleClick);
    }
    console.log("You cannot change the fields", offeringStatus);
  };

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      <div
        className="vl-tabs-explore--main-mentor-page-profile-tabs vl-tabs-explore--main-mentor-page-profile-tabs--raiseCapital"
        onDoubleClick={handleOnDoubleClick}
      >
        {isEdit ? (
          <Tabs selectedIndex={activeTabIndexVal} onSelect={handleOnSelectTab}>
            <TabList className="tab_list_wrap">
              <Tab>Basics</Tab>
              <Tab>Pitch &amp; Product</Tab>
              <Tab>Funding Details</Tab>
              <Tab>Team</Tab>
              {/* <Tab>More Info</Tab> */}
            </TabList>
            <TabPanel>
              {isDoubleClick ? (
                <RaiseCapitalBasic handleOnSelectTab={handleOnSelectTab} />
              ) : (
                <RaiseCapitalBasicDisplay />
              )}
            </TabPanel>
            <TabPanel>
              {isDoubleClick ? (
                <RaiseCapitalPitchAndProduct
                  handleOnSelectTab={handleOnSelectTab}
                />
              ) : (
                <RaiseCapitalPitchAndProductDisplay />
              )}
            </TabPanel>
            <TabPanel>
              {isDoubleClick ? (
                <RaiseSetUpFundraiser handleOnSelectTab={handleOnSelectTab} />
              ) : (
                <RaiseSetUpFundraiserDisplay />
              )}
            </TabPanel>
            <TabPanel>
              {isDoubleClick ? (
                <RaiseCapitalTeam handleOnSelectTab={handleOnSelectTab} />
              ) : (
                <RaiseCapitalTeamDisplay />
              )}
            </TabPanel>
            {/* <TabPanel>
          {isDoubleClick ? (
            <RaiseCapitalMoreInfo />
          ) : (
            <RaiseCapitalMoreInfoDisplay />
          )}
        </TabPanel> */}
          </Tabs>
        ) : (
          <NewRaiseCapitalProfilePage
            {...{ raiseCapitalOrg, raiseCapitalTeam, handleClickEdit }}
          />
        )}
      </div>
    </>
  );
}
