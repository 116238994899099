import React, { PureComponent } from "react";
import Modal from "react-responsive-modal";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import SaveButton from "./SaveButton";

import isEmpty from "../../../web-app-store/validations/is-empty";
import profileImage from "../../../assets/imgs/desktop/common/no-user-profile.png";
import profileImage1 from "../../../assets/imgs/desktop/common/profile.png";
import profileImage2 from "../../../assets/imgs/desktop/common/profile1.png";
import uploadImage from "../../../assets/imgs/desktop/common/upload_image.png";
/**
 * ImageCropModal Component
 *
 * Overview:
 * The ImageCropModal component is responsible for displaying an image cropping modal. It allows the user to select an image, crop it, and preview the cropped image before saving it.
 *
 * Details:
 * - The component uses ReactCrop library to handle image cropping functionality.
 * - It manages the state of the selected image, crop dimensions, and the cropped image URL.
 * - The component accepts various props including containerClassName, buttonName, error, fileData, isOpenModal, body, handleOpenModal, handleCloseModal, handleCloseImage, handleOnClickSaveCropImg, displayDefaultPlaceholderImg, and gender.
 *
 * Dependencies:
 * - ReactCrop: The component uses the ReactCrop library to handle image cropping functionality.
 * - Modal: The component uses the Modal component to display the image cropping modal.
 * - FileReader: The component uses the FileReader API to read the selected image file.
 * - Canvas: The component uses the Canvas API to draw the cropped image.
 */
class ImageCropModal extends PureComponent {
  state = {
    // open: false,
    src: null,
    crop: {
      unit: "%",
      aspect: 1 / 1,
    },
    isImageCropped: false,
    fileData: {},
  };

  // handleOpenModal = () => {
  //   this.setState({
  //     open: !this.state.open,
  //   });
  // };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        fileData: e.target.files[0],
      });
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl: croppedImageUrl, isImageCropped: true });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const { getBlobDataFromImageCrop, index } = this.props;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));|
          // console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        getBlobDataFromImageCrop(blob, index);
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleClearModel = () => {
    this.props.handleOpenModal();
    this.setState({
      src: null,
      crop: {
        unit: "%",
        aspect: 1 / 1,
      },
      isImageCropped: false,
      fileData: {},
      croppedImageUrl: "",
    });
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    const {
      containerClassName,
      buttonName,
      error,
      fileData,
      isOpenModal,
      body,
    } = this.props;

    // console.log(this.state.fileData);

    return (
      <>
        <div className={containerClassName}>
          <div
            className="upload-img__img-block upload_image_wrap"
            onClick={this.props.isHideButton ? this.handleClearModel : () => {}}
          >
            {/* {console.log(fileData)} */}
            {!isEmpty(fileData) ? (
              <>
                {this.props.isInvestor && (
                  <button
                    className="close-profile"
                    onClick={this.props.handleCloseImage}
                  >
                    <i class="fa fa-close investor-close"></i>
                  </button>
                )}
                <img
                  src={fileData}
                  alt=""
                  className={this.props.imgClass ? this.props.imgClass : ""}
                />
                {this.props.isHideButton && (
                  <div className="overlay">
                    <div className="upload_icon">
                      <img
                        src={uploadImage}
                        alt="upload-icon"
                        className="img-fluid upload_image_icon"
                      />
                    </div>
                  </div>
                )}
              </>
            ) : this.props.displayDefaultPlaceholderImg ? (
              <div
                className={`user-profile-picture d-flex ${
                  this.props.defaultClass || ""
                }`}
              >
                <img
                  src={
                    this.props.gender?.toLowerCase() == "female"
                      ? profileImage2
                      : this.props.defaultImage
                      ? this.props.defaultImage
                      : profileImage1
                  }
                  alt=""
                />
                {this.props.isHideButton && (
                  <div className="overlay">
                    <div className="upload_icon">
                      <img
                        src={uploadImage}
                        alt="upload-icon"
                        className="img-fluid upload_image_icon"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {body}
          </div>

          {!this.props.isHideButton && (
            <button
              className={`upload-img__btn-input-block ${this.props.btnClass}`}
              onClick={this.handleClearModel}
            >
              <span className="upload-img__btn-input-block-text">
                {buttonName}
              </span>
            </button>
          )}
          {error ? (
            <p className={`error-message ${this.props.errorClass || ""}`}>
              {error}
            </p>
          ) : (
            <p className="error-message d-none">error</p>
          )}
          {this.props.dimensionText && (
            <p className="logo-dimension-text mb-0">
              {this.props.dimensionText}
            </p>
          )}
          {this.props.sizeRequirement && (
            <p className="logo-dimension-text mt-1">
              {this.props.sizeRequirement}
            </p>
          )}
        </div>

        <Modal
          open={isOpenModal}
          onClose={this.handleClearModel}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          center
          classNames={{
            overlay: "customOverlay",
            modal: "customModal customModal--imageCrop",
            closeButton: "customCloseButton close_icon_modal",
          }}
        >
          <div className="vl-img-crop-modal-content-div crop_modal_main">
            <span
              className="closeIconInModal"
              onClick={this.props.handleCloseModal}
            />

            <>
              <div
                className={`${containerClassName} ReactCrop-modal-upload-img-button-block`}
              >
                <h1 className="vl-img-crop-modal-title">Upload Image</h1>

                <div className="row mx-0 vl-img-crop-modal-text-button-row">
                  <div className="upload-image-wrap">
                    <div className="uploaded-image">
                      <p className="vl-img-crop-modal-text1">
                        Choose Your Image
                      </p>
                      <p className="font-16-roboto-light vl-image-crop-modal-note-1 crop_image_modal_text">
                        Supported Formats: Only PNG and JPG files.
                      </p>
                    </div>
                    <div>
                      <p className="vl-img-crop-modal-text1">
                        Image Guidelines
                      </p>
                      <p className="font-16-roboto-light vl-image-crop-modal-note-1 crop_image_modal_text mb-0">
                        Minimum size: 600 X 600 pixels
                      </p>
                      <p className="font-16-roboto-light vl-image-crop-modal-note-1 crop_image_modal_text">
                        Maximum size: 2048 X 2048 pixels
                      </p>
                    </div>
                  </div>

                  <label
                    htmlFor="uploadFileInputName"
                    className="upload-img__btn-input-block upload-img__btn-input-block--imgCropModal"
                  >
                    <span className="upload-img__btn-input-block-text">
                      Select Image
                    </span>
                    <input
                      name="uploadFileInputName"
                      type="file"
                      accept="image/jpeg,image/png"
                      title=""
                      onChange={this.onSelectFile}
                    />
                  </label>
                </div>

                {/* {error ? (
                <p className="error-message">{error}</p>
              ) : (
                <p className="error-message opacity-0">error</p>
              )} */}
              </div>
            </>

            <div className="row mx-0 align-items-star uplodeimage-two">
              <div className="col-md-6 col-12 px-0">
                <p className="font-18-roboto-medium vl-image-crop-modal-note-2">
                  Image Uploaded
                </p>
                <div className="vl-ReactCrop-block">
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>
                <p className="vl-image-crop-modal-note-3">
                  To Edit: Click on the uploaded image if you need to crop it.
                </p>
              </div>

              <div className="col-md-6 col-12 px-0">
                <p className="font-18-roboto-medium vl-image-crop-modal-note-2">
                  Cropped Image Preview
                </p>
                <div className="ReactCrop-display-cropped-img">
                  {croppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={croppedImageUrl}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="vl-img-crop-modal-finish-btn-block text-center">
              <SaveButton
                text="Finish"
                handleOnClickSaveButton={
                  !isEmpty(croppedImageUrl)
                    ? this.props.handleOnClickSaveCropImg(
                        "",
                        this.state.croppedImageUrl
                        // this.props.index
                      )
                    : this.props.handleOnClickSaveCropImg(
                        this.state.fileData,
                        this.state.src
                        // this.props.index
                      )
                }
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

ImageCropModal.defaultProps = { displayDefaultPlaceholderImg: true };

export default ImageCropModal;
