import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import store from "../../../web-app-store/store";
import { SET_LOGIN } from "./../../../web-app-store/types";
import setAuthToken from "./../../../web-app-store/utils/setAuthToken";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import { Link } from "react-router-dom";
import isEmpty from "../../../web-app-store/validations/is-empty";
import Toast from "light-toast";
import { useDispatch } from "react-redux";
import {
  updateIsRegisterFlagAction,
  getTokenFromCognito,
} from "./../../../web-app-store/actions/authAction";
import {
  raiseCapitalWebAppLink,
  faq,
  termsAndConditions,
  privacyPolicy,
  knowBeforeYouInvest,
} from "../../../components/desktop/header-footer/pageLinks";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const logo = "/web-app-img/desktop/all-logo/logo.png";
const back = "/web-app-img/desktop/icons/back-arrow.png";

const noProfile = "/web-app-img/desktop/icons/no-user-profile.png";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

/**
 * LoginRedirect Component
 *
 * Overview:
 * The LoginRedirect component is responsible for handling the redirect after successful login with Google or Facebook authentication. It interacts with the backend API to log in with Strapi and save the JWT token for future authenticated requests. It also handles the rendering of the login form and checkbox form.
 *
 * Details:
 * - The component uses React hooks such as useState, useEffect, useDispatch, useLocation, useParams, and useHistory for state management, side effects, and navigation.
 * - It maintains state variables for form values, frontend errors, Google/Facebook response, registration status, and profile image.
 * - The component includes functions for handling checkbox changes, finishing the login process, and opening external links.
 * - It makes API calls to the backend for authentication and handles the response accordingly.
 *
 * Usage:
 * The LoginRedirect component can be used in the login/signup flow of a web application. It should be rendered after successful authentication with Google or Facebook. The component requires the following props:
 * - None
 *
 * Dependencies:
 * - React: The component is built using React and utilizes React hooks for state management.
 * - Redux: The component uses Redux for dispatching actions and managing global state.
 * - react-router-dom: The component relies on react-router-dom for navigation and accessing URL parameters.
 * - axios: The component uses axios for making HTTP requests to the backend API.
 * - Loader: The component uses the Loader component for displaying a loading spinner during the login process.
 * - Checkbox: The component uses the Checkbox component for rendering the checkbox form.
 * - Toast: The component uses the Toast component for displaying success messages.
 * - backendUrl: The component relies on the backendUrl variable for the URL of the backend API.
 * - landingpageBackendUrl: The component relies on the landingpageBackendUrl variable for the URL of the landing page backend API.
 * - raiseCapitalWebAppLink: The component relies on the raiseCapitalWebAppLink variable for the URL of the raise capital web application.
 * - termsAndConditions: The component relies on the termsAndConditions variable for the URL of the terms and conditions page.
 * - faq: The component relies on the faq variable for the URL of the FAQ page.
 * - privacyPolicy: The component relies on the privacyPolicy variable for the URL of the privacy policy page.
 * - knowBeforeYouInvest: The component relies on the knowBeforeYouInvest variable for the URL of the "know before you invest" page.
 * - isEmpty: The component relies on the isEmpty function for checking if a value is empty.
 * - setAuthToken: The component relies on the setAuthToken function for setting the JWT token in the local storage.
 * - store: The component relies on the store object for dispatching actions and accessing the global state.
 * - updateIsRegisterFlagAction: The component relies on the updateIsRegisterFlagAction function for updating the registration flag in the backend.
 * - updateUserInfoAction: The component relies on the updateUserInfoAction function for updating user information in the backend.
 * - getTokenFromCognito: The component relies on the getTokenFromCognito function for getting the JWT token from Cognito.
 * - getGoogleProfilePhotoAction: The component relies on the getGoogleProfilePhotoAction function for getting the Google profile photo.
 * - getfacebookUidAction: The component relies on the getfacebookUidAction function for getting the Facebook UID.
 * - getfacebookProfilePhotoAction: The component relies on the getfacebookProfilePhotoAction function for getting the Facebook profile photo.
 * - SET_LOGIN: The component relies on the SET_LOGIN action type for setting the login state in the global state.
 *
 * Note: This is a generated documentation for the given code snippet. It may not be complete or accurate. Please review and update it as needed.
 */
const LoginRedirect = (props) => {
  const dispatch = useDispatch();
  // const [text, setText] = useState("Loading...");
  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const [values, setValues] = useState({
    electronic: false,
    faq: false,
    newsletter: false,
  });

  const [frontEndError, setFrontEndError] = useState({});

  const [googleFacebookResponse, setGoogleFacebookResponse] = useState({});
  const [alreadyRegistered, setalreadyRegistered] = useState(true);
  const [profileImage, setProfileImgae] = useState("");

  /*=======================================================
  // GET PROFILE IMAGES OF BOTH FACEBOOK AND GMAIL
=========================================================*/

  const callBackProfileImgae = (picture) => {
    // console.log(picture);
    setProfileImgae(picture);
  };

  // const callBackFbProfilepic = (uid) => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   // const myParam = urlParams.get("access_token");

  //   // console.log(uid);

  //   // dispatch(getfacebookProfilePhotoAction(uid, myParam, callBackProfileImgae));
  // };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("access_token");
    // let buttonClicked = localStorage.getItem("socialButtonClicked");
    // console.log(buttonClicked);

    console.log(myParam);

    if (!isEmpty(myParam)) {
      dispatch(getTokenFromCognito(myParam, callBackProfileImgae));
      // if (
      //   buttonClicked === "googlesignup" ||
      //   buttonClicked === "googlesignin"
      // ) {
      // dispatch(getGoogleProfilePhotoAction(myParam, callBackProfileImgae));
      // } else {
      // console.log("call facebook api");
      // dispatch(getfacebookUidAction(myParam, callBackUid));
      // }
    }
  }, [dispatch]);

  /*=======================================================
  // Call google facebook authentication backend api
=========================================================*/
  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    console.log(location.search, params);
    fetch(`${backendUrl}/auth/cognito/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        setGoogleFacebookResponse(res);
        setalreadyRegistered(res.user.is_registered === true ? true : false);

        // redirected to already registerd from social page
        // let socialButtonClicked = localStorage.getItem("socialButtonClicked");
        // if (
        //   (socialButtonClicked === "googlesignup" ||
        //     socialButtonClicked === "facebooksignup") &&
        //   res.user.is_registered === true
        // ) {
        //   history.push({
        //     pathname: "/already-registered",
        //     state: { socialResData: res.user },
        //   });
        // }

        // redirected to home page
        if (
          (res.user.is_registered === true) &
          (res.user.firstname !== null)
          // &&
          // (socialButtonClicked === "googlesignin" ||
          //   socialButtonClicked === "facebooksignin")
        ) {
          localStorage.setItem("jwt", JSON.stringify(res.jwt));
          localStorage.setItem("userData", JSON.stringify(res.user));
          setAuthToken(res.jwt);
          store.dispatch({
            type: SET_LOGIN,
            payload: res.user,
          });
          history.push({
            pathname: raiseCapitalWebAppLink,
            // state: { socialResData: res.user },
          });
        } else if (
          (res.user.is_registered === true) &
          (res.user.firstname === null)
        ) {
          localStorage.setItem("jwt", JSON.stringify(res.jwt));
          localStorage.setItem("userData", JSON.stringify(res.user));
          setAuthToken(res.jwt);
          store.dispatch({
            type: SET_LOGIN,
            payload: res.user,
          });
          history.push({
            pathname: "/get-started",
            // state: { socialResData: res.user },
          });
        }

        // setText(
        //   "You have been successfully logged in. You will be redirected in a few seconds..."
        // );
      })
      .catch((err) => {
        // console.log(err);
        // if (err) {
        //   history.push("/already-register");
        // }
        // setText("An error occurred, please see the developer console.");
      });
  }, [history, location.search, params.providerName]);

  const handleCheckboxChange = (e) => {
    // console.log(e.target.checked);
    setFrontEndError("");
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const finishHandler = async (e) => {
    // console.log(values);
    if (
      (values.electronic === false || values.faq === false) &&
      alreadyRegistered !== true
      // values.newsletter === false
    ) {
      setFrontEndError("Please agree at least first two documents");
      // console.log("show error");
    } else {
      if (!isEmpty(googleFacebookResponse)) {
        localStorage.setItem("jwt", JSON.stringify(googleFacebookResponse.jwt));
        localStorage.setItem(
          "userData",
          JSON.stringify(googleFacebookResponse.user)
        );
        setAuthToken(googleFacebookResponse.jwt);
        store.dispatch({
          type: SET_LOGIN,
          payload: googleFacebookResponse.user,
        });
        const formData = {
          is_registered: true,
        };
        dispatch(
          updateIsRegisterFlagAction(googleFacebookResponse.user.id, formData)
        );
        if (googleFacebookResponse.user.firstname === null) {
          Toast.info("Login Successfully", 3000);
          // history.push("/get-started");
        } else {
          Toast.info("Login Successfully", 3000);
          // history.push("/");
        }
        // if (googleFacebookResponse.user.firstname === null) {
        //   Toast.info("Login Successfully", 3000);
        //   const formData = {
        //     // DOB: format(selectedBirthDate, "YYYY-MM-DD"),
        //     firstname: "firstname",
        //     lastname: "lastname",
        //     phoneNumber: "1234789652",
        //     Address: "Address",
        //     interest: "RaisingCapital",
        //   };

        //   let userData = JSON.parse(localStorage.getItem("userData"));

        //   dispatch(updateUserInfoAction(userData.id, formData, history));

        //   try {
        //     const res = await axios.post(`${landingpageBackendUrl}/api/v1/invite_email`, { first_name:userData.email, email: userData.email });
        //     console.log("RES>>", res);
        //   } catch (e) {
        //     console.log(e);
        //   }

        //   // history.push("/get-started");
        //   history.push("/explore");
        // } else {
        //   Toast.info("Login Successfully", 3000);
        //   history.push("/");
        // }
      }
    }
  };

  const openLinkHandler = (text) => (e) => {
    e.preventDefault();
    // console.log(text);
    if (text === "term&condition") {
      window.open(`https://vedaslabs.io${termsAndConditions}`);
    } else if (text === "faq") {
      window.open(`https://vedaslabs.io${faq}`);
    } else if (text === "privacypolicy") {
      window.open(`https://vedaslabs.io${privacyPolicy}`);
    } else if (text === "knowbeforeyouinvest") {
      window.open(`https://vedaslabs.io${knowBeforeYouInvest}`);
    }
  };

  // console.log(alreadyRegistered);

  if (alreadyRegistered === true) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="w-100 text-center"
      >
        <Loader type="TailSpin" color={"#ffffff"} height={30} width={30} />
      </div>
    );
  } else if (alreadyRegistered === false) {
    return (
      <div className="signup-bg">
        <div className="vl-logo-div">
          <img src={logo} alt="vedaslabs logo" />
        </div>
        <div
          className={
            alreadyRegistered !== true
              ? "google-signup-div"
              : "google-signup-div-alredy-signup"
          }
        >
          <div className="d-flex mx-0 align-items-center">
            <img
              src={!isEmpty(profileImage) ? profileImage : noProfile}
              alt="profile"
              className="profile-img"
            />
            <h5 className="email-text">
              {!isEmpty(googleFacebookResponse) &&
                googleFacebookResponse.user.email}
            </h5>
          </div>

          {alreadyRegistered !== true && (
            <form className="checkbox-form">
              <div className="vl-checkbox">
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={values.electronic}
                  checked={values.electronic}
                  defaultChecked={false}
                  name="electronic"
                  id="electronic"
                />
                <label htmlFor="electronic" className="checkbox-textlist">
                  I agree to the Site’s {/* <Link to={termsAndConditions}> */}{" "}
                  <u
                    className="checkbox-text"
                    onClick={openLinkHandler("term&condition")}
                  >
                    Terms of Use
                  </u>{" "}
                  and{" "}
                  <u
                    className="checkbox-text"
                    onClick={openLinkHandler("privacypolicy")}
                  >
                    Privacy Policy
                  </u>
                  {/* </Link> */}
                </label>
              </div>
              <div className="vl-checkbox mt-15 ">
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={values.faq}
                  checked={values.faq}
                  defaultChecked={false}
                  name="faq"
                  id="faq"
                />
                <label htmlFor="faq" className="checkbox-textlist">
                  I have read the site’s
                  {/* <Link to={faq}> */}{" "}
                  <u
                    className="checkbox-text"
                    onClick={openLinkHandler("knowbeforeyouinvest")}
                  >
                    Educational Materials
                  </u>{" "}
                  and{" "}
                  <u className="checkbox-text" onClick={openLinkHandler("faq")}>
                    FAQs
                  </u>
                  .{/* </Link> */}
                </label>
              </div>
              <div className="vl-checkbox  mt-15 ">
                <Checkbox
                  onChange={handleCheckboxChange}
                  value={values.newsletter}
                  checked={values.newsletter}
                  defaultChecked={false}
                  name="newsletter"
                  id="newsletter"
                />
                <label htmlFor="newsletter" className="checkbox-textlist">
                  I would like to receive the VedasLabs.io newsletter and <br />{" "}
                  investment opportunities that are relevant to me.
                </label>
              </div>
            </form>
          )}
          {!isEmpty(frontEndError) && alreadyRegistered !== true && (
            <p className="validation-errors-login">{frontEndError}</p>
          )}

          <div className="d-flex mx-0 align-items-center gmail-signup-btn-div">
            <Link to="/">
              <button className="sign-up-back-btn">
                <img src={back} alt="back arrow" className="back-img" />
                <span className="font-18-montserrat-semibold">Previous</span>
              </button>
            </Link>
            {/* <Link to="/email-verify"> */}
            <div>
              <a
                className="signup-submit-btn"
                href={`${
                  googleFacebookResponse.user.firstname === null
                    ? "/get-started"
                    : "/home"
                }`}
                onClick={finishHandler}
              >
                Next
              </a>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
    );
  }
};

export default LoginRedirect;
