import React, { useEffect } from "react";
import Footer from "../header-footer/Footer";
import KnowledgeBaseFoldOne from "./KnowledgeBaseFoldOne";
import KnowledgeBaseFoldTwo from "./KnowledgeBaseFoldTwo";

/**
 * KnowledgeBase Component
 * 
 * Overview:
 * The KnowledgeBase component is responsible for rendering the knowledge base section of the UI. It includes two folds of knowledge base content and a footer.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when it mounts.
 * - The component renders a div with the class "kn-base-bg" as the root element.
 * - It includes two sub-components, KnowledgeBaseFoldOne and KnowledgeBaseFoldTwo, which render the content for the two folds of the knowledge base.
 * - The component also includes a Footer component.
 * 
 * Usage:
 * To use the KnowledgeBase component, simply import it and place it in the desired location within your application's UI.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the UI elements.
 * - KnowledgeBaseFoldOne: A sub-component that renders the content for the first fold of the knowledge base.
 * - KnowledgeBaseFoldTwo: A sub-component that renders the content for the second fold of the knowledge base.
 * - Footer: A sub-component that renders the footer of the UI.
 */
export default function KnowledgeBase() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="kn-base-bg">
      <KnowledgeBaseFoldOne />
      <KnowledgeBaseFoldTwo />
      <Footer />
    </div>
  );
}
