/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MentorProfile from "../../../components/desktop/mentor/mentor-profile/MentorProfile";
import GeneralProfile from "./GeneralProfile";

/**
 * Profile Component
 *
 * Overview:
 * The Profile component is responsible for rendering the user's profile based on their interests. It determines whether to display the MentorProfile or GeneralProfile component based on the user's selected interest. This component is used to manage the user's profile information.
 *
 * Details:
 * - The component uses the useState hook to manage the profileManage state, which represents the user's selected interest.
 * - The component uses the useEffect hook to retrieve the user's profile data from the localStorage and set the profileManage state accordingly.
 * - The component renders either the MentorProfile or GeneralProfile component based on the value of the profileManage state.
 *
 * Dependencies:
 * - useState: Used to manage the profileManage state.
 * - useEffect: Used to retrieve the user's profile data from the localStorage and set the profileManage state.
 * - MentorProfile: The component to render when the user's interest is "Mentoring".
 * - GeneralProfile: The component to render when the user's interest is not "Mentoring".
 */
const Profile = () => {
  const [profileManage, setProfileManage] = useState();

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    setProfileManage(userData.interest);
  }, []);

  return (
    <div>
      <MentorProfile />
    </div>
  );
};

export default Profile;
