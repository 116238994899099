import React, { useEffect } from "react";
import SideNavbar from "../header/SideNavbar";
import PageTitle from "../header/PageTitle";

import Footer from "../../../components/desktop/header-footer/Footer";
import TermsAndConditionsNewContent from "../../../components/desktop/terms-conditions/TermsAndConditionsNewContent";

/**
 * TermsOfUseWebApp Component
 * 
 * Overview:
 * The TermsOfUseWebApp component is responsible for rendering the terms of use page in the web application. It includes a side navigation bar, a main content section, and a footer. The component ensures that the page is scrolled to the top when it is mounted.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when it is mounted.
 * - The component renders a SideNavbar component, a main content section with a PageTitle component, and a TermsAndConditionsNewContent component.
 * - The TermsAndConditionsNewContent component is passed a prop displayOnWebApp set to true, indicating that it should be displayed in the web application.
 * - The component also renders a Footer component with the prop webAppFooter set to true.
 * 
 * Usage:
 * To use the TermsOfUseWebApp component, import it from the specified file path and include it in the desired location within the web application. Make sure to provide any necessary props to the component.
 * 
 * Dependencies:
 * - SideNavbar: This component is imported and rendered within the TermsOfUseWebApp component. It provides the side navigation bar functionality.
 * - PageTitle: This component is imported and rendered within the TermsOfUseWebApp component. It displays the page title.
 * - TermsAndConditionsNewContent: This component is imported and rendered within the TermsOfUseWebApp component. It displays the terms and conditions content.
 * - Footer: This component is imported and rendered within the TermsOfUseWebApp component. It displays the footer.
 */
export default function TermsOfUseWebApp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle isDisplayImage={false} pageTitle="Terms of Use" />

          <div className="vl-terms-of-use-web">
            <TermsAndConditionsNewContent displayOnWebApp={true} />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
