import axios from "axios";
import isEmpty from "../../web-app-store/validations/is-empty";
import { backendUrl } from "./config";
import {
  SET_RAISE_CAPITAL_PROFILE_SUCCESS,
  // SET_RAISE_CAPITAL_PROFILE_ACTIVATED,
  SET_RAISE_CAPITAL_ORGANIZATIONS,
  SET_RAISE_CAPITAL_OFFERINGS,
  SET_RAISE_CAPITAL_TEAM,
  SET_RAISE_CAPITAL_TAGS,
  SET_RAISE_CAPITAL_INDUSTRIES,
  SET_SOCIAL_IMAPCT_OPTIONS,
} from "./../types";
import Toast from "light-toast";

/*===================================================================================
             get raise capital profile success
====================================================================================*/
/**
 * RaiseCapitalSaveProfileSuccessAction
 *
 * Overview:
 * Saves the success status of a user's raise capital profile operation in the application state. This action primarily updates the Redux store with the provided data without making an external API call.
 *
 * Details:
 * - Dispatches an action to the Redux store with the type `SET_RAISE_CAPITAL_PROFILE_SUCCESS` and the passed data as payload.
 *
 * Dependencies:
 * - No external dependencies.
 */
export const raiseCapitalSaveProfileSuccessAction =
  (data) => async (dispatch) => {
    try {
      dispatch({
        type: SET_RAISE_CAPITAL_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             get raise capital profile success
====================================================================================*/

// export const raiseCapitalDispalyProfileReviewModalAction = (data) => async (
//   dispatch
// ) => {
//   try {
//     if (data) {
//       dispatch({
//         type: SET_RAISE_CAPITAL_PROFILE_ACTIVATED,
//         payload: data,
//       });
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

/*===================================================================================
             get raise capital organizations
====================================================================================*/
/**
 * GetRaiseCapitalOrganizations
 *
 * Overview:
 * Fetches the list of organizations associated with the raise capital profile of the logged-in user from the backend. It updates the Redux store with the list of organizations.
 *
 * Details:
 * - Makes a GET request to `/organizations?user=${userId}` endpoint to retrieve user-specific organizations.
 * - Dispatches an action to the Redux store with type `SET_RAISE_CAPITAL_ORGANIZATIONS` and the retrieved data as payload.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getRaiseCapitalOrganizations = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/organizations?user=${userData.id}`
    );
    if (data) {
      dispatch({
        type: SET_RAISE_CAPITAL_ORGANIZATIONS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create raise capital organization
====================================================================================*/
/**
 * CreateRaiseCapitalOrganization
 *
 * Overview:
 * Creates a new organization associated with the raise capital profile by sending the provided data to the backend. It updates the list of organizations in the Redux store upon success.
 *
 * Details:
 * - Sends a POST request with the form data to create a new organization.
 * - Displays a success message using Toast.
 * - Refreshes the list of organizations by dispatching `getRaiseCapitalOrganizations`.
 * - Optionally creates raise capital tags if provided.
 *
 * Dependencies:
 * - axios, Toast, and isEmpty utility function.
 * - backendUrl for the API endpoint.
 */
export const createRaiseCapitalOrganization =
  (formData, sendTagsArray, raiseCapitalTags, callBackStatus) =>
  async (dispatch) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/organizations`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getRaiseCapitalOrganizations());

        if (!isEmpty(sendTagsArray)) {
          if (isEmpty(raiseCapitalTags) && !isEmpty(data)) {
            let sendTagsArrayNew = {};

            sendTagsArrayNew = {
              organization: {
                id: data.id,
              },
              user: { id: userData.id },
              market_tags: sendTagsArray,
            };
            dispatch(createRaiseCapitalTags(sendTagsArrayNew));
          }
        }

        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             update raise capital organization
====================================================================================*/
/**
 * UpdateRaiseCapitalOrganization
 *
 * Overview:
 * Updates an existing organization within the raise capital profile by sending the updated data to the backend. It refreshes the organizations list in the Redux store on success.
 *
 * Details:
 * - Sends a PUT request with the updated data for a specific organization.
 * - Displays a success message using Toast.
 * - Refreshes the list of organizations and tags if applicable.
 *
 * Dependencies:
 * - axios, Toast, and isEmpty utility function.
 * - backendUrl for the API endpoint.
 */
export const updateRaiseCapitalOrganization =
  (formData, orgId, sendTagsArray, raiseCapitalTags, callBackStatus) =>
  async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/organizations/${orgId}`,
        formData
      );

      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getRaiseCapitalOrganizations(status));
        if (!isEmpty(sendTagsArray)) {
          if (isEmpty(raiseCapitalTags)) {
            dispatch(createRaiseCapitalTags(sendTagsArray));
          } else {
            dispatch(
              updateRaiseCapitalTags(sendTagsArray, raiseCapitalTags[0].id)
            );
          }
        }
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get raise capital tags
====================================================================================*/
/**
 * GetRaiseCapitalTags
 *
 * Overview:
 * Fetches tags associated with a specific organization within the raise capital profile from the backend. It updates the Redux store with these tags.
 *
 * Details:
 * - Makes a GET request to `/tags?user=${userId}&organization=${orgId}` to retrieve tags.
 * - Dispatches an action with the retrieved data to update the store.
 *
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getRaiseCapitalTags = (orgId) => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/tags?user=${userData.id}&organization=${orgId}`
    );
    if (data) {
      dispatch({
        type: SET_RAISE_CAPITAL_TAGS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create raise capital tags
====================================================================================*/
/**
 * CreateRaiseCapitalTags
 *
 * Overview:
 * Creates tags for an organization within the raise capital profile by sending the provided data to the backend. It refreshes the organization and tags in the Redux store upon success.
 *
 * Details:
 * - Sends a POST request with the tag data to create new tags.
 * - Refreshes the organizations list and the tags for the relevant organization.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const createRaiseCapitalTags = (formData) => async (dispatch) => {
  try {
    let { data } = await axios.post(`${backendUrl}/tags`, formData);
    if (data) {
      dispatch(getRaiseCapitalOrganizations());
      dispatch(getRaiseCapitalTags(formData.organization.id));
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             update raise capital tags
====================================================================================*/
/**
 * UpdateRaiseCapitalTags
 *
 * Overview:
 * Updates existing tags for an organization within the raise capital profile by sending the updated data to the backend. It refreshes the organization and tags in the Redux store on success.
 *
 * Details:
 * - Sends a PUT request with the updated tag data for a specific tag id.
 * - Refreshes the list of organizations and tags for the relevant organization.
 *
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const updateRaiseCapitalTags =
  (formData, tagsId) => async (dispatch) => {
    try {
      let { data } = await axios.put(`${backendUrl}/tags/${tagsId}`, formData);

      if (data) {
        dispatch(getRaiseCapitalOrganizations());
        dispatch(getRaiseCapitalTags(formData.organization.id));
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get raise capital offerings / fundraiser 
====================================================================================*/
/**
 * GetRaiseCapitalOfferings
 *
 * Overview:
 * Retrieves offerings (or fundraisers) for a specific organization within the raise capital profile from the backend. It updates the Redux store with these offerings.
 *
 * Details:
 * - Makes a GET request to `/offerings?organization=${orgId}` to retrieve offerings.
 * - Dispatches an action with the retrieved data to update the store.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getRaiseCapitalOfferings = (orgId) => async (dispatch) => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/offerings?organization=${orgId}`
    );
    if (data) {
      dispatch({
        type: SET_RAISE_CAPITAL_OFFERINGS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create raise capital offerings / fundraiser 
====================================================================================*/
/**
 * CreateRaiseCapitalOfferings
 *
 * Overview:
 * Creates a new offering (or fundraiser) for an organization within the raise capital profile by sending the provided data to the backend. It refreshes the offerings in the Redux store upon success.
 *
 * Details:
 * - Sends a POST request with the offering data to create a new offering.
 * - Displays a success message using Toast.
 * - Refreshes the list of offerings for the relevant organization.
 *
 * Dependencies:
 * - axios, Toast for making HTTP requests and displaying messages.
 * - backendUrl for the API endpoint.
 */
export const createRaiseCapitalOfferings =
  (formData, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/offerings`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        callBackStatus(status);
        dispatch(getRaiseCapitalOfferings(formData.organization.id));
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             update raise capital offerings / fundraiser 
====================================================================================*/
/**
 * UpdateRaiseCapitalOfferings
 *
 * Overview:
 * Updates an existing offering (or fundraiser) for an organization within the raise capital profile by sending the updated data to the backend. It confirms the success of the operation with a Toast message.
 *
 * Details:
 * - Sends a PUT request with the updated offering data for a specific offering id.
 * - Displays a success message using Toast.
 *
 * Dependencies:
 * - axios, Toast for making HTTP requests and displaying messages.
 * - backendUrl for the API endpoint.
 */
export const updateRaiseCapitalOfferings =
  (formData, offeringId, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/offerings/${offeringId}`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        callBackStatus(status);
        // console.log(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get raise capital team
====================================================================================*/
/**
 * GetRaiseCapitalTeam
 *
 * Overview:
 * Fetches team details for a specific organization within the raise capital profile from the backend. It updates the Redux store with the team details.
 *
 * Details:
 * - Makes a GET request to `/team-details?organization=${orgId}` to retrieve team details.
 * - Dispatches an action with the retrieved data to update the store.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getRaiseCapitalTeam = (orgId) => async (dispatch) => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/team-details?organization=${orgId}`
    );
    if (data) {
      dispatch({
        type: SET_RAISE_CAPITAL_TEAM,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
                       create raise capital team
====================================================================================*/
/**
 * CreateRaiseCapitalTeam
 *
 * Overview:
 * Adds a new team member to an organization within the raise capital profile by sending the provided data to the backend. It refreshes the team details in the Redux store upon success.
 *
 * Details:
 * - Sends a POST request with the team member data to add a new member.
 * - Displays a success message using Toast.
 * - Refreshes the list of team members for the organization.
 *
 * Dependencies:
 * - axios, Toast for making HTTP requests and displaying messages.
 * - backendUrl for the API endpoint.
 */
export const createRaiseCapitalTeam =
  (formData, orgId, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/team-details`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getRaiseCapitalTeam(orgId));
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
                           Delete Team Member
====================================================================================*/
/**
 * DeleteTeamMemberById
 *
 * Overview:
 * Removes a specific team member from an organization within the raise capital profile by sending a DELETE request to the backend. It confirms the removal with a Toast message.
 *
 * Details:
 * - Sends a DELETE request for a specific team member id.
 * - Displays a success message using Toast.
 *
 * Dependencies:
 * - axios, Toast for making HTTP requests and displaying messages.
 * - backendUrl for the API endpoint.
 */
export const deleteTeamMemberById = (memberId) => async (dispatch) => {
  try {
    let { data } = await axios.delete(`${backendUrl}/team-details/${memberId}`);
    if (data) {
      Toast.info("Information saved", 3000);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             update raise capital team
====================================================================================*/
/**
 * UpdateRaiseCapitalTeam
 *
 * Overview:
 * Updates details of a specific team member within an organization's raise capital profile by sending the updated data to the backend. It displays a success message upon completion.
 *
 * Details:
 * - Sends a PUT request with the updated team member data for a specific id.
 * - Displays a success message using Toast.
 *
 * Dependencies:
 * - axios, Toast for HTTP requests and user feedback.
 * - backendUrl for the API endpoint.
 */
export const updateRaiseCapitalTeam =
  (formData, memberId, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/team-details/${memberId}`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
                      GET ALL INDUSTRIES
====================================================================================*/
/**
 * GetAllIndustries
 *
 * Overview:
 * Fetches a list of all possible industries from the backend to support industry selection within the raise capital profile. It updates the Redux store with the list of industries.
 *
 * Details:
 * - Makes a GET request to `/industries` to retrieve all industries.
 * - Dispatches an action with the retrieved data to update the store.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getAllIndustries = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/industries`);
    if (data) {
      dispatch({
        type: SET_RAISE_CAPITAL_INDUSTRIES,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
                      GET ALL SOCIAL IMPACT
====================================================================================*/
/**
 * GetSocialImpactOptions
 *
 * Overview:
 * Retrieves a list of social impact options from the backend, useful for categorizing organizations or offerings within the raise capital profile. It updates the Redux store with these options.
 *
 * Details:
 * - Makes a GET request to `/socials` to retrieve social impact options.
 * - Dispatches an action with the retrieved data to update the store.
 *
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getSocialImpactOptions = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/socials`);
    if (data) {
      dispatch({
        type: SET_SOCIAL_IMAPCT_OPTIONS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};
