/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import MentorCard from "./MentorCard";
import DropdownList from "./common/DropdownList";
import { getAllMentors } from "../../../web-app-store/actions/mentorAction";
import { useDispatch, useSelector } from "react-redux";
import LandingPageBtn from "./buttonComponent/LandingPageBtn";
import Select from "react-select";
import { industries } from "./dropdownList/industry";
import isEmpty from "../../../web-app-store/validations/is-empty";
import Footer from "../header-footer/Footer";

/**
 * MentorFoldTwo Component
 *
 * Overview:
 * The MentorFoldTwo component is responsible for managing the dropdown state and handling dropdown selection for various details related to a mentor. It is used within the larger application to provide a user interface for selecting and managing mentor details.
 *
 * Details:
 * - The component uses Redux hooks (useDispatch and useSelector) to access the mentor data from the Redux store.
 * - It maintains a state (manageDropdown) to keep track of the dropdown button state and selected dropdown items for different details such as skill, sector of interest, location, etc.
 * - The component also maintains a state (mentorDetails) to store the selected mentor details.
 * - The handleDropdown function is used to handle the dropdown button click event and update the manageDropdown state accordingly.
 * - The handleAutoClose function is used to close all dropdowns when a click event occurs outside of the dropdown.
 * - The handleDropDownSetList function is used to populate the dropdown lists for different details based on the mentor records.
 *
 * Dependencies:
 * - React: The component is built using React library.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: A Redux hook used to dispatch actions.
 * - useSelector: A Redux hook used to select data from the Redux store.
 */
const MentorFoldTwo = () => {
  const dispatch = useDispatch();
  let mentorRecords = useSelector((state) => state.mentor.allMentors);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [manageDropdown, setManageDropdown] = useState({
    skillDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    sectorOfInterest: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    locationDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    softwareDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    priceDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    timeAvailableDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    daysAvailableDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    languageDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    countryDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
    companyDetails: {
      dropdownButton: false,
      dropdownSelectedList: [],
      dropdownList: [],
      searchList: [],
    },
  });
  const [mentorDetails, setMentorDetails] = useState([]);

  const handleDropdown = (key, name, value) => {
    let dropdownData = { ...manageDropdown };
    if (key === "skillDetails") {
      dropdownData.skillDetails.dropdownButton = value;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "softwareDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = value;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "sectorOfInterest") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = value;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "priceDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = value;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "timeAvailableDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = value;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "daysAvailableDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = value;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "languageDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = value;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "countryDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = value;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "companyDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = value;
      dropdownData.locationDetails.dropdownButton = false;
    } else if (key === "locationDetails") {
      dropdownData.skillDetails.dropdownButton = false;
      dropdownData.softwareDetails.dropdownButton = false;
      dropdownData.sectorOfInterest.dropdownButton = false;
      dropdownData.priceDetails.dropdownButton = false;
      dropdownData.timeAvailableDetails.dropdownButton = false;
      dropdownData.daysAvailableDetails.dropdownButton = false;
      dropdownData.languageDetails.dropdownButton = false;
      dropdownData.countryDetails.dropdownButton = false;
      dropdownData.companyDetails.dropdownButton = false;
      dropdownData.locationDetails.dropdownButton = value;
    }
    setManageDropdown(dropdownData);
  };
  const handleAutoClose = (e) => {
    let dropdownData = { ...manageDropdown };
    dropdownData.skillDetails.dropdownButton = false;
    dropdownData.softwareDetails.dropdownButton = false;
    dropdownData.sectorOfInterest.dropdownButton = false;
    dropdownData.priceDetails.dropdownButton = false;
    dropdownData.timeAvailableDetails.dropdownButton = false;
    dropdownData.daysAvailableDetails.dropdownButton = false;
    dropdownData.languageDetails.dropdownButton = false;
    dropdownData.countryDetails.dropdownButton = false;
    dropdownData.companyDetails.dropdownButton = false;
    dropdownData.locationDetails.dropdownButton = false;
    setManageDropdown(dropdownData);
  };
  const handleDropDownSetList = () => {
    let skillDetailsList = [];
    let IndustryDetailsList = [];
    let companyDetailsList = [];
    let locationDetailsList = [];
    let softwareDetailsList = [];
    let priceDetailsList = [];
    let timeAvailableDetailsList = [];
    let daysAvailableDetailsList = [];
    let languageDetailsList = [];
    let countryDetailsList = [];
    mentorRecords?.length > 0 &&
      mentorRecords.filter((val) => {
        for (let i = 0; i < val?.skills?.length; i++) {
          const element = val?.skills[i];
          skillDetailsList.push(element);
        }
        for (let i = 0; i < val?.software?.length; i++) {
          const element = val?.software[i];
          softwareDetailsList.push(element);
        }
        //  sectors_of_interest bind data start
        let sectors_of_interest_Details = val?.area_of_interest?.split(",");
        let sectors_of_interest_Data;
        if (!isEmpty(sectors_of_interest_Details)) {
          if (sectors_of_interest_Details?.length === 1) {
            sectors_of_interest_Data = {
              label: sectors_of_interest_Details[0],
              value: sectors_of_interest_Details[0],
            };
            IndustryDetailsList.push(sectors_of_interest_Data);
          } else {
            sectors_of_interest_Data = sectors_of_interest_Details?.map(
              (item) => {
                return {
                  label: item,
                  value: item,
                };
              }
            );
            IndustryDetailsList = IndustryDetailsList.concat(
              sectors_of_interest_Data
            );
          }
        }
        //  sectors_of_interest bind data end
        if (!isEmpty(val?.location)) {
          let location_Data = {
            label: val?.location,
            value: val?.location,
          };
          locationDetailsList.push(location_Data);
        }

        priceDetailsList.push(val?.price);
        timeAvailableDetailsList.push(val?.time_available);
        daysAvailableDetailsList.push(val?.days_available);
        languageDetailsList.push(val?.language);
        countryDetailsList.push(val?.country);
        if (!isEmpty(val?.company)) {
          let companyData = {
            label: val?.company,
            value: val?.company,
          };
          companyDetailsList.push(companyData);
        }
      });
    skillDetailsList = [...new Set(skillDetailsList)];
    softwareDetailsList = [...new Set(softwareDetailsList)];

    IndustryDetailsList = [
      ...new Map(
        IndustryDetailsList.map((item) => [item["label"], item])
      ).values(),
    ];

    locationDetailsList = [
      ...new Map(
        locationDetailsList.map((item) => [item["label"], item])
      ).values(),
    ];
    companyDetailsList = [
      ...new Map(
        companyDetailsList.map((item) => [item["label"], item])
      ).values(),
    ];

    priceDetailsList = [...new Set(priceDetailsList)];
    timeAvailableDetailsList = [...new Set(timeAvailableDetailsList)];
    daysAvailableDetailsList = [...new Set(daysAvailableDetailsList)];
    languageDetailsList = [...new Set(languageDetailsList)];
    countryDetailsList = [...new Set(countryDetailsList)];
    if (mentorRecords?.length > 0) {
      setManageDropdown({
        ...manageDropdown,
        skillDetails: {
          ...manageDropdown.skillDetails,
          dropdownList: skillDetailsList,
        },
        softwareDetails: {
          ...manageDropdown.softwareDetails,
          dropdownList: softwareDetailsList,
        },
        sectorOfInterest: {
          ...manageDropdown.sectorOfInterest,
          dropdownList: IndustryDetailsList,
        },
        priceDetails: {
          ...manageDropdown.priceDetails,
          dropdownList: priceDetailsList,
        },
        timeAvailableDetails: {
          ...manageDropdown.timeAvailableDetails,
          dropdownList: timeAvailableDetailsList,
        },
        daysAvailableDetails: {
          ...manageDropdown.daysAvailableDetails,
          dropdownList: daysAvailableDetailsList,
        },
        languageDetails: {
          ...manageDropdown.languageDetails,
          dropdownList: languageDetailsList,
        },
        countryDetails: {
          ...manageDropdown.countryDetails,
          dropdownList: countryDetailsList,
        },
        companyDetails: {
          ...manageDropdown.companyDetails,
          dropdownList: companyDetailsList,
        },
        locationDetails: {
          ...manageDropdown.locationDetails,
          dropdownList: locationDetailsList,
        },
      });
    }
  };
  const handleChangeSearch = (searchVal) => {
    let mentorRecord = [...mentorRecords];
    mentorRecord = mentorRecord.filter((value) => {
      let mentorName = value?.first_name + " " + value?.last_name;
      if (searchVal === "") {
        return value;
      } else {
        return mentorName?.toLowerCase()?.includes(searchVal);
      }
    });
    setMentorDetails(mentorRecord);
  };
  const handleCheckBoxFilter = (value, key) => {
    let mentorRecord = [...mentorRecords];
    let filterSearch = [];
    for (let i = 0; i < value?.length; i++) {
      const element = value[i];
      let filterRecords = mentorRecord?.filter((data) => {
        if (key !== "sectors_of_interest") {
          return data[key]?.toString().includes(element);
        } else {
          return data[key].some((sector) =>
            sector.areaOfExpertise
              ?.toLowerCase()
              .includes(element.toLowerCase())
          );
        }
      });
      if (filterRecords?.length > 0) {
        filterSearch.push(...filterRecords);
      }
    }
    const uniqueDetails = [
      ...new Map(filterSearch.map((item) => [item["id"], item])).values(),
    ];
    if (value?.length > 0) {
      setMentorDetails(uniqueDetails);
    } else {
      setMentorDetails(mentorRecords);
    }
  };
  const handleDropDownSearch = (key, value) => {
    let searchVal = value.toLowerCase();
    let detailsList = [...manageDropdown[key].dropdownList];
    let allDetails = { ...manageDropdown[key] };
    if (value !== "") {
      let searchList = detailsList.filter((val, index) =>
        val.toLowerCase().includes(searchVal)
      );
      allDetails.dropdownButton = true;
      allDetails.searchList = searchList;
    } else {
      allDetails.searchList = [];
    }
    setManageDropdown({
      ...manageDropdown,
      [key]: allDetails,
    });
  };
  const handleChangeCheckbox = (key, name, value, info) => {
    let allDetails = { ...manageDropdown[key] };
    let dropdownData = { ...manageDropdown };
    let keyName = "";
    if (key === "skillDetails") {
      keyName = "skills";
    } else if (key === "softwareDetails") {
      keyName = "software";
    } else if (key === "sectorOfInterest") {
      keyName = "sectors_of_interest";
    } else if (key === "priceDetails") {
      keyName = "price";
    } else if (key === "timeAvailableDetails") {
      keyName = "time_available";
    } else if (key === "daysAvailableDetails") {
      keyName = "days_available";
    } else if (key === "languageDetails") {
      keyName = "language";
    } else if (key === "countryDetails") {
      keyName = "country";
    } else if (key === "companyDetails") {
      keyName = "company";
    } else if (key === "locationDetails") {
      keyName = "location";
    }
    if (value === true) {
      allDetails.dropdownSelectedList.push(name);
      handleCheckBoxFilter(allDetails.dropdownSelectedList, keyName);
    } else {
      allDetails.dropdownSelectedList = allDetails.dropdownSelectedList.filter(
        (item) => item !== name
      );
      handleCheckBoxFilter(allDetails.dropdownSelectedList, keyName);
    }
    allDetails.searchList = info;
    setManageDropdown({
      ...manageDropdown,
      [key]: allDetails,
    });
  };
  const handelFocusDropDownClose = () => {
    setManageDropdown({
      ...manageDropdown,
      skillDetails: { ...manageDropdown.skillDetails, dropdownButton: false },
      softwareDetails: {
        ...manageDropdown.softwareDetails,
        dropdownButton: false,
      },
      sectorOfInterest: {
        ...manageDropdown.sectorOfInterest,
        dropdownButton: false,
      },
      priceDetails: { ...manageDropdown.priceDetails, dropdownButton: false },
      timeAvailableDetails: {
        ...manageDropdown.timeAvailableDetails,
        dropdownButton: false,
      },
      daysAvailableDetails: {
        ...manageDropdown.daysAvailableDetails,
        dropdownButton: false,
      },
      languageDetails: {
        ...manageDropdown.languageDetails,
        dropdownButton: false,
      },
      countryDetails: {
        ...manageDropdown.countryDetails,
        dropdownButton: false,
      },
      companyDetails: {
        ...manageDropdown.companyDetails,
        dropdownButton: false,
      },
      locationDetails: {
        ...manageDropdown.locationDetails,
        dropdownButton: false,
      },
    });
  };
  useEffect(() => {
    dispatch(getAllMentors());
  }, []);

  useEffect(() => {
    handleDropDownSetList();
    setMentorDetails(mentorRecords);
  }, [mentorRecords]);

  const handleChangeDropDown = (item1, item2, name) => {
    let info = [...manageDropdown[name].searchList];
    if (item2?.action === "select-option") {
      info.push({
        value: item2.option.value,
        label: item2.option.value,
      });
      handleChangeCheckbox(name, item2.option.value, true, info);
    } else if (item2?.action === "remove-value") {
      info = info?.filter((item) => item.value !== item2.removedValue?.value);
      handleChangeCheckbox(name, item2.removedValue?.value, false, info);
    } else if (item2?.action === "clear") {
      info = [];
      handleChangeCheckbox(name, null, false);
      setMentorDetails(mentorRecords);
    }
    // setManageDropdown({
    //   ...manageDropdown,
    //   [name]: {
    //     ...manageDropdown[name],
    //     searchList: info,
    //   },
    // });
  };

  return (
    <div
      className={`mentor-section-wrapper container-fluid ${
        userData ? "mentor-card-main" : ""
      }`}
    >
      <div className="mentor-section-main">
        <div className="mentor-section">
          <h5 className="mentor-title">Mentors</h5>
          <form className="mentor-searchBar d-flex align-items-center">
            <i className="fa fa-search"></i>
            <input
              className="input-searchBar w-100"
              type="text"
              name="search"
              placeholder="Search by name or keyword"
              onChange={(e) => handleChangeSearch(e.target.value)}
              onFocus={handelFocusDropDownClose}
            />
          </form>
          <div className="mentorship-wrap">
            <LandingPageBtn
              handleDropdown={handleDropdown}
              manageDropdown={manageDropdown}
            />

            {manageDropdown.sectorOfInterest.dropdownButton && (
              <div className="vl-form-input vl-mentorship-select vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect">
                <label htmlFor="">Area of interest</label>
                <Select
                  value={manageDropdown.sectorOfInterest.searchList}
                  onChange={(e1, e2) =>
                    handleChangeDropDown(e1, e2, "sectorOfInterest")
                  }
                  options={industries}
                  classNamePrefix="vl-select"
                  placeholder={"Select Area of Interest"}
                  isSearchable={true}
                  isMulti
                />
              </div>
            )}
            {manageDropdown.locationDetails.dropdownButton && (
              <div className="vl-form-input vl-mentorship-select vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect">
                <label htmlFor="">Location</label>
                <Select
                  value={manageDropdown.locationDetails.searchList}
                  onChange={(e1, e2) =>
                    handleChangeDropDown(e1, e2, "locationDetails")
                  }
                  options={manageDropdown.locationDetails.dropdownList}
                  classNamePrefix="vl-select"
                  placeholder={"Select Location"}
                  isSearchable={true}
                  isMulti
                />
              </div>
            )}
            {manageDropdown.companyDetails.dropdownButton && (
              <div className="vl-form-input vl-mentorship-select vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect">
                <label htmlFor="">Company</label>
                <Select
                  value={manageDropdown.companyDetails.searchList}
                  onChange={(e1, e2) =>
                    handleChangeDropDown(e1, e2, "companyDetails")
                  }
                  options={manageDropdown.companyDetails.dropdownList}
                  classNamePrefix="vl-select"
                  placeholder={"Select Company"}
                  isSearchable={true}
                  isMulti
                />
              </div>
            )}
          </div>
        </div>
        <div className="mentor-main">
          <MentorCard mentorRecords={mentorDetails} />
        </div>
      </div>
      {userData ? (
        <Footer webAppFooter={true} isMentorClass={"mentor_apply_footer"} />
      ) : null}
    </div>
  );
};

export default MentorFoldTwo;
