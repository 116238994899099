import React from "react";
import dropDownIcon from "../../../../assets/imgs/desktop/icon/dropdown.svg";
import upIcon from "../../../../assets/imgs/desktop/icon/up-arrow.svg";

/**
 * LandingPageBtn Component
 * 
 * Overview:
 * The LandingPageBtn component is responsible for rendering a set of buttons that represent different areas of interest, locations, companies, and more. It allows the user to toggle a dropdown menu for each button to select specific options.
 * 
 * Details:
 * - The component receives props that include a handleDropdown function, manageDropdown object, and setManageDropdown function.
 * - The handleDropdown function is used to toggle the dropdown menu for each button.
 * - The manageDropdown object holds the state of each dropdown button, indicating whether it is currently open or closed.
 * - The setManageDropdown function is used to update the state of the manageDropdown object.
 * - The component renders a set of buttons, each representing a different category (area of interest, location, company, etc.).
 * - Each button has an associated dropdown menu that can be toggled by clicking the button.
 * - The dropdown menu displays an icon that indicates whether it is open or closed.
 * 
 * Dependencies:
 * - This component requires the following dependencies:
 *   - React: The component is built using React.
 *   - dropDownIcon: An image file representing a dropdown icon.
 *   - upIcon: An image file representing an upward arrow icon.
 */
const LandingPageBtn = (props) => {
  const { handleDropdown, manageDropdown, setManageDropdown } = props;

  LandingPageBtn.handleClickOutSide = () => {
    setManageDropdown({
      ...manageDropdown,
      skillDetails: { dropdownButton: false },
    });
  };

  return (
    <div className="mentor-section-wrapper container-fluid p-0">
      <div className="mentor-section">
        {/* <div className="mentor-section"> */}
        {/* <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "skillDetails",
                "dropdownButton",
                !manageDropdown.skillDetails.dropdownButton
              )
            }
          >
            Area of interest
            <img
              className="dropdown-icon"
              alt="img"
              src={
                !manageDropdown.skillDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button> */}
        <button
          className="skills-btn text-center"
          onClick={() =>
            handleDropdown(
              "sectorOfInterest",
              "dropdownButton",
              !manageDropdown.sectorOfInterest.dropdownButton
            )
          }
        >
          Area of interest
          <img
            className="dropdown-icon"
            alt="img"
            src={
              !manageDropdown.sectorOfInterest.dropdownButton
                ? dropDownIcon
                : upIcon
            }
          />
        </button>

        <button
          className="skills-btn text-center"
          onClick={() =>
            handleDropdown(
              "locationDetails",
              "dropdownButton",
              !manageDropdown.locationDetails.dropdownButton
            )
          }
        >
          Location
          <img
            alt="img"
            className="dropdown-icon"
            src={
              !manageDropdown.locationDetails.dropdownButton
                ? dropDownIcon
                : upIcon
            }
          />
        </button>

        <button
          className="skills-btn text-center"
          onClick={() =>
            handleDropdown(
              "companyDetails",
              "dropdownButton",
              !manageDropdown.companyDetails.dropdownButton
            )
          }
        >
          Company
          <img
            alt="img"
            className="dropdown-icon"
            src={
              !manageDropdown.companyDetails.dropdownButton
                ? dropDownIcon
                : upIcon
            }
          />
        </button>

        {/* <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "softwareDetails",
                "dropdownButton",
                !manageDropdown.softwareDetails.dropdownButton
              )
            }
          >
            Software
            <img
              className="dropdown-icon"
              alt="img"
              src={
                !manageDropdown.softwareDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>

          <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "priceDetails",
                "dropdownButton",
                !manageDropdown.priceDetails.dropdownButton
              )
            }
          >
            Price
            <img
              className="dropdown-icon"
              alt="img"
              src={
                !manageDropdown.priceDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>

          <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "timeAvailableDetails",
                "dropdownButton",
                !manageDropdown.timeAvailableDetails.dropdownButton
              )
            }
          >
            Time Available
            <img
              alt="img"
              className="dropdown-icon"
              src={
                !manageDropdown.timeAvailableDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>

          <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "daysAvailableDetails",
                "dropdownButton",
                !manageDropdown.daysAvailableDetails.dropdownButton
              )
            }
          >
            Day Available
            <img
              alt="img"
              className="dropdown-icon"
              src={
                !manageDropdown.daysAvailableDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>

          <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "languageDetails",
                "dropdownButton",
                !manageDropdown.languageDetails.dropdownButton
              )
            }
          >
            Language
            <img
              alt="img"
              className="dropdown-icon"
              src={
                !manageDropdown.languageDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>

          <button
            className="skills-btn text-center"
            onClick={() =>
              handleDropdown(
                "countryDetails",
                "dropdownButton",
                !manageDropdown.countryDetails.dropdownButton
              )
            }
          >
            Country
            <img
              alt="img"
              className="dropdown-icon"
              src={
                !manageDropdown.countryDetails.dropdownButton
                  ? dropDownIcon
                  : upIcon
              }
            />
          </button>     */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default LandingPageBtn;
