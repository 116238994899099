import React, { useEffect } from "react";
import SideNavbar from "../header/SideNavbar";
import PageTitle from "../header/PageTitle";

import Footer from "../../../components/desktop/header-footer/Footer";
import PrivacyPolicyNewContent from "../../../components/desktop/privacy-policy/PrivacyPolicyNewContent";

/**
 * PrivacyPolicyWebApp Component
 * 
 * Overview:
 * The PrivacyPolicyWebApp component is responsible for rendering the privacy policy page of the web application. It includes a side navigation bar, a page title, and the content of the privacy policy.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component structure consists of a side navigation bar, a main page div, and a footer.
 * - The side navigation bar is rendered using the SideNavbar component.
 * - The main page div contains the page content and is styled using the "vl-main-page-div" class.
 * - The page title is rendered using the PageTitle component with the pageTitle prop set to "Privacy Policy".
 * - The privacy policy content is rendered using the PrivacyPolicyNewContent component.
 * - The footer is rendered using the Footer component with the webAppFooter prop set to true.
 * 
 * Dependencies:
 * - SideNavbar: The SideNavbar component is a dependency of the PrivacyPolicyWebApp component and is responsible for rendering the side navigation bar.
 * - PageTitle: The PageTitle component is a dependency of the PrivacyPolicyWebApp component and is responsible for rendering the page title.
 * - PrivacyPolicyNewContent: The PrivacyPolicyNewContent component is a dependency of the PrivacyPolicyWebApp component and is responsible for rendering the privacy policy content.
 * - Footer: The Footer component is a dependency of the PrivacyPolicyWebApp component and is responsible for rendering the footer.
 */
export default function PrivacyPolicyWebApp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle isDisplayImage={false} pageTitle="Privacy Policy" />
          <div className="vl-privacy-policy-web">
            <PrivacyPolicyNewContent />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
