import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import InputFieldEmailTextPassword from "./InputFieldEmailTextPassword";
import CurrencyInputCommon from "./CurrencyInputCommon";
import DiscussionCreateNewForm from "../discussions/DiscussionCreateNewForm";
/**
 * CreateDiscussionModal Component
 *
 * Overview:
 * The CreateDiscussionModal component is responsible for rendering a modal that allows users to create a new discussion. It is used in the desktop version of the application and is part of the common components library.
 *
 * Details:
 * - The component renders a Modal component from a third-party library, which provides the modal functionality.
 * - The modal is displayed when the 'open' prop is set to true and is closed when the 'onClose' prop is called.
 * - The modal does not close when the escape key is pressed or when the overlay is clicked.
 * - The modal is centered on the screen.
 * - The component applies custom CSS classes to the overlay and modal elements.
 * - The component includes a DiscussionCreateNewForm sub-component, which is rendered inside the modal.
 *
 * Props:
 * - open: A boolean value indicating whether the modal should be open or closed.
 * - onClose: A function that is called when the modal is closed.
 *
 * Dependencies:
 * - Modal: A third-party library for rendering modals.
 * - DiscussionCreateNewForm: A sub-component that is rendered inside the modal.
 */
export default function CreateDiscussionModal({ open, onClose }) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal reserveModal--profile-review reservemodal-popup discussion-model",
          // closeButton: "customCloseButton",
        }}
      >
        <DiscussionCreateNewForm onClose={onClose} />
      </Modal>
    </>
  );
}
