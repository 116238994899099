/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import logo from "../../../assets/imgs/desktop/common/logo.png";
import darkLogo from "../../../assets/imgs/desktop/common/vedaslabs-logo-blog-details.png";
import session from "../../../assets/imgs/desktop/icon/Group.svg";
import helpLine from "../../../assets/imgs/desktop/icon/clarity-help-line.svg";
import library from "../../../assets/imgs/desktop/icon/library.svg";
import community from "../../../assets/imgs/desktop/icon/community.svg";
import {
  aboutUs,
  career,
  contactUs,
  home,
  invest,
  knowledgeBase,
  newLoginSignUpLink,
  profileWebAppLink,
  raiseCapital,
  mentor,
  newLoginSignUpLinkSignUp,
} from "../header-footer/pageLinks.js";
import store from "../../../web-app-store/store";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../web-app-store/actions/authAction";
import { SET_CREATE_USER } from "../../../web-app-store/types";

let isUser = JSON.parse(localStorage.getItem("isUser"));
if (isUser) {
  store.dispatch({
    type: SET_CREATE_USER,
    payload: isUser,
  });
}

/**
 * MentorNavbar Component
 *
 * Overview:
 * The MentorNavbar component represents the navigation bar for the mentor section of the application. It provides navigation links and user profile functionality.
 *
 * Details:
 * - The component uses the useState and useEffect hooks from React for managing state and side effects.
 * - It retrieves user data from the local storage and uses the useDispatch hook from Redux for dispatching actions.
 * - The component includes a toggle button for expanding and collapsing the navigation menu.
 * - It renders different UI elements based on the user's authentication status and profile data.
 *
 * Usage:
 * To use the MentorNavbar component, import it into the desired file and include it in the JSX code. Make sure to provide the necessary dependencies and props, if any.
 *
 * Dependencies:
 * - React: The component uses React hooks for state management and side effects.
 * - Redux: The component uses the useDispatch hook for dispatching actions.
 * - React Router: The component uses the useHistory hook for programmatic navigation.
 * - Bootstrap: The component uses Bootstrap classes for styling the navigation bar.
 * - Font Awesome: The component uses Font Awesome icons for the user profile and sign out buttons.
 * - LocalStorage: The component retrieves user data from the local storage.
 * - Other components and images: The component uses other components and images for rendering the UI elements.
 */
const MentorNavbar = () => {
  const [changeClass, setChangeClass] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(false);
  const history = useHistory();

  const onSelect = (action) => {
    if (action === "logout") {
      dispatch(logoutAction(history));
    }
  };
  const handleJoinAsUser = () => {
    setIsUser(true);
  };

  useEffect(() => {
    if (isUser) {
      localStorage.setItem("isUser", isUser);
    } else {
      return;
    }
  }, [history, isUser]);

  return (
    <>
      <div>
        <Navbar
          expand="lg"
          className={`navbar-expand-lg px-3 py-0 ${
            changeClass ? "nav-bar-expand navbar-light" : "nav-bar navbar-dark"
          }`}
        >
          <Container fluid className="navbar-main-wrap py-3">
            <div className="d-flex justify-content-between align-items-center navbar-main">
              {changeClass ? (
                <Navbar.Brand href="/">
                  <img src={darkLogo} alt="vedslabs logo" className="header" />
                </Navbar.Brand>
              ) : (
                <Navbar.Brand href="/">
                  <img src={logo} alt="vedslabs logo" className="header" />
                </Navbar.Brand>
              )}
              <Navbar.Collapse id="navbarScroll" className="mentor-header">
                <Nav
                  className={`my-lg-0 home-page-navbar justify-content-center w-100 ${
                    changeClass ? "align-items-start" : "align-items-center"
                  }`}
                >
                  <Nav.Link href={invest}>Invest</Nav.Link>
                  <Nav.Link href={mentor}>Mentorship</Nav.Link>
                  <Nav.Link href={knowledgeBase}>knowledge Base</Nav.Link>
                  <Nav.Link href={raiseCapital}>Raise Capital</Nav.Link>
                  {/* <Nav.Link href={home} className="">
                    Home
                  </Nav.Link>
                  <Nav.Link href={aboutUs} className="">
                    About Us
                  </Nav.Link>
                  <Nav.Link href={knowledgeBase} className="">
                    knowledge Base
                  </Nav.Link>
                  <Nav.Link href={career} className="">
                    Career
                  </Nav.Link>
                  <Nav.Link href={contactUs} className="">
                    Contact Us
                  </Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
              {userData ? (
                <div className="login-profile">
                  {userData?.user_profile?.url ? (
                    <Image
                      src={userData?.user_profile?.url}
                      alt="mentor-profile-image"
                      className="mentor-profile-picture"
                      onClick={
                        isLogOut
                          ? () => setIsLogOut(false)
                          : () => setIsLogOut(true)
                      }
                    />
                  ) : (
                    <>
                      <span
                        className="mentor-profile-picture mentor-profile-picture--text"
                        onClick={
                          isLogOut
                            ? () => setIsLogOut(false)
                            : () => setIsLogOut(true)
                        }
                      >
                        {userData.firstname.charAt(0)}
                      </span>
                    </>
                  )}
                  {isLogOut ? (
                    <div className="sign-out-btn">
                      <Link to={profileWebAppLink} className="user-profile">
                        <i className="far fa-user"></i>
                        My Profile
                      </Link>
                      <button
                        className="log-out"
                        onClick={() => onSelect("logout")}
                      >
                        <i className="fas fa-sign-out-alt"></i>
                        Sign out
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <Nav
                  className="me-auto my-2 my-lg-0 d-flex justify-content-end"
                  navbarScroll
                >
                  <Nav.Link
                    className="log-in-btn-main d-flex justify-content-center align-items-center"
                    href={newLoginSignUpLink}
                    onClick={handleJoinAsUser}
                  >
                    LOGIN
                  </Nav.Link>
                  <Nav.Link
                    className="sign-up-btn-main d-flex justify-content-center align-items-center"
                    // href={newLoginSignUpLink}
                    href={newLoginSignUpLinkSignUp}
                    onClick={handleJoinAsUser}
                  >
                    SIGN UP
                  </Nav.Link>
                </Nav>
              )}
            </div>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              onClick={
                changeClass
                  ? () => setChangeClass(false)
                  : () => setChangeClass(true)
              }
              // className={`${changeClass ? "" : ""}`}
            />
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default MentorNavbar;
