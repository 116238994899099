import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import store from "../../../web-app-store/store";
import {
  SET_CREATE_MENTOR,
  SET_CREATE_USER,
} from "../../../web-app-store/types";
import { newLoginSignUpLink } from "../header-footer/pageLinks";

let isMentor = JSON.parse(localStorage.getItem("isMentor"));
let isUser = JSON.parse(localStorage.getItem("isUser"));
if (isMentor) {
  store.dispatch({
    type: SET_CREATE_MENTOR,
    payload: isMentor,
  });
}
if (isUser) {
  store.dispatch({
    type: SET_CREATE_USER,
    payload: isUser,
  });
}
/**
 * MentorFoldOne Component
 * 
 * Overview:
 * The MentorFoldOne component is responsible for displaying a section on the UI where users can join as mentors or users. It provides a form for users to apply as mentors and a button for users to sign up and access mentors. This component is part of the larger application structure and is used to facilitate mentorship and guidance for startup growth.
 * 
 * Details:
 * - The component manages state using the useState hook to track whether the user wants to join as a mentor or user.
 * - It uses the useHistory hook from react-router-dom to handle navigation.
 * - The component uses the useEffect hook to update the local storage based on the user's selection.
 * - The component renders a title and description for the mentorship section.
 * - If the user is not logged in, it renders buttons for applying as a mentor and signing up to access mentors.
 * 
 * Usage:
 * To use the MentorFoldOne component, include it in the desired location within your application's UI. Make sure to import any necessary dependencies, such as react-router-dom for navigation. You can customize the content of the mentorship section by modifying the title and description.
 * 
 * Dependencies:
 * - react: The component uses React to create and manage the UI elements.
 * - react-router-dom: The component uses the useHistory hook from react-router-dom for navigation.
 * - useState: The component uses the useState hook to manage state.
 * - useEffect: The component uses the useEffect hook to update the local storage.
 * - Nav: The component uses the Nav component from react-bootstrap for rendering buttons.
 * - localStorage: The component uses the localStorage API to store user data.
 * - JSON: The component uses the JSON object to parse and stringify data.
 */
const MentorFoldOne = () => {
  const [createMentor, setCreateMentor] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isUser, setIsUser] = useState(false);
  const history = useHistory();

  const handleJoinAsMentor = () => {
    setCreateMentor(true);
  };

  const handleJoinAsUser = () => {
    setIsUser(true);
  };

  useEffect(() => {
    if (createMentor) {
      localStorage.setItem("isMentor", createMentor);
    } else if (isUser) {
      localStorage.setItem("isUser", isUser);
    } else {
      return;
    }
  }, [history, createMentor, isUser]);

  return (
    <>
      <div className="container text-center mentor-main w-100">
        <div className="row justify-content-center">
          <div className="mentor-sub-title">
            <h1 className="mentor-title">
              Access Experienced Mentors to Accelerate Your Startup Growth
            </h1>
            <p className="mentor-sub">
              Talk to proven professionals who genuinely care about your success
              and receive personalized guidance and support on growth
              strategies, marketing, product development and more
            </p>
          </div>
          {!userData && (
            <>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-0 my-1 d-flex justify-content-center align-item-center">
                <Nav.Link
                  className="sign-in-btn"
                  href={newLoginSignUpLink}
                  onClick={handleJoinAsMentor}
                >
                  Apply To Be Mentor
                </Nav.Link>
              </div>
              <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 p-0 my-1 d-flex justify-content-center align-item-center">
                <Nav.Link
                  className="demo-btn"
                  href={newLoginSignUpLink}
                  onClick={handleJoinAsUser}
                >
                  Sign up to access mentors
                </Nav.Link>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MentorFoldOne;
