import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Link,
  // Link,
  useHistory,
  useParams,
} from "react-router-dom";
import Tooltip from "react-power-tooltip";
import format from "date-fns/format";
import SideNavbar from "../header/SideNavbar";

import { activeSidebarMenu, activeTopNavbarMenu } from "../header/activeMenu";
import PageTitleStartUpDetails from "../header/PageTitleStartUpDetails";
import AmountRaisedCard from "./AmountRaisedCard";

import Footer from "../../../components/desktop/header-footer/Footer";

import Share from "../../../assets/imgs/desktop/icon/Share.svg";
import arrow from "../../../assets/imgs/desktop/icon/arrow.svg";

import carbon_document from "../../../assets/imgs/desktop/icon/carbon_document.svg";
import play from "../../../assets/imgs/desktop/icon/play.svg";
import playblue from "../../../assets/imgs/desktop/icon/playblue.svg";

import { Circle } from "rc-progress";
// import { raiseCapitalWebAppLink } from "../../../components/desktop/header-footer/pageLinks";
// api
import {
  getExploreTagsById,
  getExploreOfferingsById,
} from "../../../web-app-store/actions/exploreAction";
import {
  getUIAgreementTypeValueFunction,
  getUISecurityTypeValueFunction,
} from "../../../common-website-and-app/getUIValueFunction";
import { getRaiseCapitalAllInvestors } from "../../../web-app-store/actions/raiseCapitalInvestorAction";
import { getInvestorByUserId } from "../../../web-app-store/actions/investAction";
import { getRaiseCapitalTeam } from "../../../web-app-store/actions/raiseCapitalAction";
import { clearDataOnStartUpDetailsUnmount } from "../../../web-app-store/actions/startupDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../common/DisplayLoader";
import Navbar from "../../../components/desktop/header-footer/Navbar";
import StartUpDetailsViewAllInvestors from "./StartUpDetailsViewAllInvestors";

import StartUpDetailsDisplayPdf from "./StartUpDetailsDisplayPdf";
import StartUpDetailsWatchVideoModal from "./StartUpDetailsWatchVideoModal";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import { calcDaysRemaining } from "../common/calcDaysRemainingFunction";
import AboutTab from "./AboutTab";
import TeamTab from "./TeamTab";
import Discussion from "./Discussion";

import PageTitle from "../header/PageTitle";
import StartUpDetailsTabPanelUpdates from "./StartUpDetailsTabPanelUpdates";
import ScrollTopArrow from "../common/ScrollTopArrow";
import SocialShare from "./SocialShare";

import { Page, pdfjs } from "react-pdf";
import DefaultComponent from "../../DefaultComponent";

// let img1 = "/web-app-img/desktop/explore/team1.png";
// let img2 = "/web-app-img/desktop/explore/team2.png";
// let img3 = "/web-app-img/desktop/explore/team3.png";

// const dummyData = [img1, img3, img2];

/**
StartUpDetails Component
Overview:
The StartUpDetails component is responsible for displaying detailed information about a startup. It is used in the explore section of the web application to provide users with more information about a specific startup they are interested in.

Details:
The StartUpDetails component manages the state of the startup details, such as the name, description, and funding information.
It includes sub-components for displaying different sections of the startup details, such as the basic information, team members, and funding rounds.
The component fetches the startup details from an API and updates the state accordingly.
It handles user interactions, such as clicking on team members to view their profiles or clicking on funding rounds to view more details.

*/

export default function StartUpDetails() {
  // api
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  // const auth = useSelector((state) => state.auth);
  const [openShare, setOpenShare] = useState(false);

  const OFFERING_STATUS = [
    "Offering_Posted",
    "Accepting_Investment",
    "Offering_Closed",
    "VL_Reserve",
  ];

  const onOpenShareModal = () => setOpenShare(true);
  const onCloseShareModal = () => setOpenShare(false);
  const params = useParams();
  const [isTooltip, setTooltip] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPitch, setOpenPitch] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [displayOnWebsite, setDisplayOnWebsite] = useState(true);
  useEffect(() => {
    if (!history.location.pathname.includes("start-up-details-page")) {
      setDisplayOnWebsite(false);
    }
  }, [history.location.pathname]);

  const [totalPdfScreens, setTotalNumOfScreens] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [managePopup, setMangePopup] = useState(false);
  const handelPdfModal = () => {
    setMangePopup(!managePopup);
  };

  // const handle = useFullScreenHandle();
  // const handleSetScreen = (e) => {
  //   setIsFullScreen(e);
  // };

  const onModalDocumentLoadSuccess = (totalPdfScreens) => {
    setIsPdfLoading(false);
    setTotalNumOfScreens(totalPdfScreens._pdfInfo.numPages);
  };

  const handleOnClickPrev = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentPageNumber > 1) {
      setCurrentPageNumber(currentPageNumber - 1);
      // console.log(totalPdfScreens, currentPageNumber);
    }
  };

  const handleOnClickNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (totalPdfScreens > currentPageNumber) {
      setCurrentPageNumber(currentPageNumber + 1);
      // console.log(totalPdfScreens, currentPageNumber);
    }
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const [orgDataById, setOrgDataById] = useState({
    company_logo: { url: null },
    website: null,
    location: null,
    company_details: null,
    company_name: null,
    mission: null,
    customers: null,
    value_proposition: null,
    business_model: null,
    competition: null,
    problem: null,
    solution: null,
  });

  const [offeringDataById, setOfferingDataById] = useState();
  const [offeringData, setOfferingData] = useState();
  const [tagsById, setTagsById] = useState();
  let daysRemaining = calcDaysRemaining(
    offeringDataById?.fund_start_date,
    offeringDataById?.fund_end_date
  );
  useEffect(() => {
    refreshData();
  }, [dispatch, params]);

  const callBackTagsData = (data) => {
    setTagsById(data);
  };
  const callBackOfferingData = (data) => {
    // set offering data
    setOfferingDataById(data);
    // investors
    dispatch(getRaiseCapitalAllInvestors(data.offering_id));

    if (!isEmpty(data.organization)) {
      // set org data
      setOfferingData(data);
      setOrgDataById(data.organization);
      // tags
      dispatch(getExploreTagsById(data.organization.id, callBackTagsData));
    }
  };

  const refreshData = () => {
    dispatch(getExploreOfferingsById(params.id, callBackOfferingData));
  };
  const raiseCapitalTeamOne = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );

  const raiseCapitalInvestors = useSelector(
    (state) => state.raiseCapital.raiseCapitalInvestors
  );
  const showTooltip = (bool) => {
    setTooltip(bool);
  };
  useEffect(() => {
    if (!isEmpty(offeringDataById) && isEmpty(raiseCapitalTeamOne)) {
      // teams
      dispatch(getRaiseCapitalTeam(offeringDataById.organization.id));
    }
  }, [dispatch, offeringDataById]);

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );
  // alert(JSON.stringify(offeringData))
  const openLink = (url) => window.open(url, "_blank")?.focus();
  useEffect(() => {
    return () => {
      // reset startup details page data on unmount
      dispatch(clearDataOnStartUpDetailsUnmount());
      setOfferingDataById();
      setOrgDataById({
        company_logo: { url: null },
        website: null,
        location: null,
        company_details: null,
        company_name: null,
        mission: null,
        customers: null,
        value_proposition: null,
        business_model: null,
        competition: null,
        problem: null,
        solution: null,
      });
      setTagsById();
    };
  }, [dispatch]);

  // view full team
  const [isClickedViewFullTeam, setIsClickedViewFullTeam] = useState(false);
  const handleOnClickViewFullTeamButton = () => {
    // let elementHeightVal =
    //   document.getElementById(`startup-div-id`).offsetHeight;

    // let scrollToVal = elementHeightVal / 1.1;
    // window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
    // localStorage.setItem("activeVLExploreCardDetailsTabIndex", 0);
    // setIsClickedViewFullTeam(true);
    executeScroll();
    setSelectedTab(1);
  };

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  let amountRaised =
    !isEmpty(offeringDataById) && offeringDataById.total_amount_raised;
  let amountReserved =
    !isEmpty(offeringDataById) && offeringDataById.total_amount_reserves;

  let amountCommited =
    !isEmpty(offeringDataById) && offeringDataById.total_VL_reserves;

  let fundingGoal = !isEmpty(offeringDataById) && offeringDataById.funding_goal;

  const [fundRaised, setFundRaised] = useState(0);
  useEffect(() => {
    let amount = 0;
    if (
      offeringDataById?.offering_status.toLocaleLowerCase() ===
      "Accepting_Investment".toLocaleLowerCase()
    ) {
      amount = offeringDataById.total_amount_raised;
    } else if (
      offeringDataById?.offering_status.toLocaleLowerCase() ===
        "Offering_Posted".toLocaleLowerCase() ||
      offeringDataById?.offering_status.toLocaleLowerCase() === "Reserved"
    ) {
      amount = offeringDataById.total_amount_reserves;
    } else if (
      offeringDataById?.offering_status.toLocaleLowerCase() ===
      "VL_Reserve".toLocaleLowerCase()
    ) {
      amount = offeringDataById.total_VL_reserves;
    } else {
      amount = 0;
    }

    if (!isEmpty(offeringDataById)) {
      const fundingGoal = offeringDataById.funding_goal;

      let calcFundRaisedVal = (amount * 100) / fundingGoal;

      if (isNaN(calcFundRaisedVal)) {
        setFundRaised(0);
      } else {
        setFundRaised(calcFundRaisedVal);
      }
    }
  }, [offeringDataById]);

  /*============================================
          render top left
  ============================================*/

  const [displayTooltip, setDisplayTooltip] = useState(false);

  const renderTopLeft = () => {
    return (
      <div className="render-top-left-div">
        {/* {console.log(orgDataById, tagsById, offeringDataById)} */}
        <div className="row">
          <div className="col-xl-2 col-lg-12  col-12">
            <div className="startup-profile-img-div">
              {!isEmpty(orgDataById.company_logo) ? (
                <img src={orgDataById.company_logo.url} alt="logo" />
              ) : (
                <img src={offeringData.offering_image.url} alt="logo" />
              )}
            </div>
          </div>
          <div className="col-xl-10 col-lg-12 col-12">
            <div className="render-inner">
              <div className="render-top-comman render-top-left-div-col-2">
                <h4 className="startup-font-14-roboto-medium pt-12">Website</h4>
                {/* Lorem Ipsum */}

                {isEmpty(orgDataById.website) ? (
                  <h5 className="startup-font-18-roboto-bold pt-15">
                    <span className="opacity-0">lorem</span>
                  </h5>
                ) : orgDataById.website.includes("http") ? (
                  <a
                    className="startup-font-18-roboto-bold startup-font-18-roboto-bold-website"
                    href={`${orgDataById.website}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {orgDataById.website}
                  </a>
                ) : (
                  <a
                    className="startup-font-18-roboto-bold startup-font-18-roboto-bold-website"
                    href={`https://${orgDataById.website}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {orgDataById.website}
                  </a>
                )}
              </div>
              <div className="render-top-comman render-top-left-div-col-3">
                <h4 className="startup-font-14-roboto-medium">Location</h4>
                <h5 className="startup-font-18-roboto-bold pt-15">
                  {isEmpty(orgDataById.location) ? (
                    <span className="opacity-0">Lorem Ipsum</span>
                  ) : (
                    orgDataById.location
                  )}
                </h5>
              </div>
            </div>

            <div className="render-inner">
              <div className="render-top-comman render-top-left-div-col-2">
                <div className="row mx-0 flex-nowrap">
                  <h4 className="startup-font-14-roboto-medium">
                    Total Funding
                  </h4>
                  <div className="vl-start-up-details-question-mark-main-div">
                    <button
                      onMouseOver={() => setDisplayTooltip(true)}
                      onMouseLeave={() => setDisplayTooltip(false)}
                      className="vl-start-up-details-question-mark"
                    >
                      ?
                    </button>
                    {displayTooltip && (
                      <p className="vl-start-up-details-question-mark-main-div__tooltip">
                        Amount raised prior to this round on VedasLabs
                      </p>
                    )}
                  </div>
                </div>

                <p className="startup-font-18-roboto-bold pt-15">
                  <DisplayCurrencyFormatString
                    value={orgDataById.total_funding}
                  />
                </p>
              </div>

              <div className="render-top-comman render-top-left-div-col-3">
                <h4 className="startup-font-14-roboto-medium">Round Type</h4>
                <p className="startup-font-18-roboto-bold pt-15">
                  {isEmpty(offeringDataById.round_type) ? (
                    <span className="opacity-0">Lorem Ipsum</span>
                  ) : (
                    offeringDataById.round_type
                  )}
                </p>
                {/* <div className="row mx-0 align-items-center pt-15 pl-0 vl-disc-card-row__imgAndBtnDiv-img-row">
                  {!isEmpty(raiseCapitalTeam) ? (
                    <>
                      {raiseCapitalTeam.map((data, index) => (
                        <Fragment key={index}>
                          {index < 3 && (
                            <div className="startup-team-img-div">
                              {!isEmpty(data.profile_image) ? (
                                <img
                                  src={data.profile_image.url}
                                  alt="team member"
                                />
                              ) : (
                                <span className="font-24-roboto-medium">
                                  {!isEmpty(data.full_name) &&
                                    data.full_name.charAt(0)}
                                </span>
                              )}
                            </div>
                          )}
                        </Fragment>
                      ))}
                      <button
                        className="startup-font-18-roboto-medium"
                        onClick={handleOnClickViewFullTeamButton}
                      >
                        View Full Team
                      </button>
                    </>
                  ) : (
                    <div className="startup-team-img-div opacity-0">lorem</div>
                  )}
                </div> */}
              </div>
            </div>
            <div className="render-inner">
              <div className="render-top-comman render-top-left-div-col-2">
                <h4 className="startup-font-14-roboto-medium">Team</h4>
                <div className="d-flex mx-0 align-items-center  pl-0 vl-disc-card-row__imgAndBtnDiv-img-row">
                  {!isEmpty(raiseCapitalTeam) ? (
                    <>
                      {raiseCapitalTeam.map((data, index) => (
                        <Fragment key={index}>
                          {index < 3 && (
                            <div className="startup-team-img-div">
                              {!isEmpty(data.profile_image) ? (
                                <img
                                  src={data.profile_image.url}
                                  alt="team member"
                                  className="img-fluid"
                                />
                              ) : (
                                <span className="font-24-roboto-medium">
                                  {!isEmpty(data.full_name) &&
                                    data.full_name.charAt(0)}
                                </span>
                              )}
                            </div>
                          )}
                        </Fragment>
                      ))}
                      <button
                        className="startup-font-18-roboto-medium"
                        onClick={handleOnClickViewFullTeamButton}
                      >
                        View Full Team
                      </button>
                    </>
                  ) : (
                    <div className="startup-team-img-div opacity-0">lorem</div>
                  )}
                </div>
                {/* <p className="startup-font-18-roboto-bold pt-15">
                  {isEmpty(offeringDataById.round_type) ? (
                    <span className="opacity-0">Lorem Ipsum</span>
                  ) : (
                    offeringDataById.round_type
                  )}
                </p> */}
              </div>
              <div className="render-top-comman render-top-left-div-col-3">
                <h4 className="startup-font-14-roboto-medium">Tags</h4>
                <div className="explore-card-btn-div">
                  {!isEmpty(tagsById) &&
                    parseTags(tagsById[0].market_tags).map((data, index) => (
                      <div key={index} className="tag-btn">
                        {data}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*============================================
          render top right
  ============================================*/

  useEffect(() => {
    dispatch(getInvestorByUserId());
  }, [dispatch]);

  const investor = useSelector((state) => state.invest.investor);

  const checkInvestorDataCreated = () => {
    if (!isEmpty(investor[0])) {
      // console.log(investor[0]);
      if (
        !isEmpty(investor[0].income) &&
        !isEmpty(investor[0].networth) &&
        !isEmpty(investor[0].job_title) &&
        !isEmpty(investor[0].job_industry)
      ) {
        return true;
      }
    } else return false;
  };

  const parseTags = (tags) => {
    try {
      return tags.split(",");
    } catch (err) {
      return [];
    }
  };
  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, " ");
  };
  const extractlist = (rawString) => {
    var regex = /<li>(.*?)<\/li>/gi,
      result,
      indices = [];
    while ((result = regex.exec(rawString))) {
      // alert(result)
      indices.push(removeTags(result[1].replace("&nbsp;", "")));
    }
    return indices;
  };
  const RenderInvestBtn = () => {
    return (
      <div>
        <AmountRaisedCard
          offering_status={offeringDataById.offering_status}
          isInvestorCreated={checkInvestorDataCreated()}
          companyData={orgDataById}
          amountRaised={amountRaised}
          valuationCap={offeringDataById.valuation_cap}
          fundingGoal={fundingGoal}
          min={offeringDataById.funding_goal}
          max={offeringDataById.max_funding_goal}
          fundRaised={fundRaised}
          startDate={offeringDataById.fund_start_date}
          endDate={offeringDataById.fund_end_date}
          minInvestment={offeringDataById.min_investment}
          offering_id={offeringDataById.offering_id}
          id={offeringDataById.id}
          organizationId={offeringDataById.organization.id}
          invest_now_url={offeringDataById.invest_now_url}
          issuers_form_c={offeringDataById.issuers_form_c}
          min_investment={offeringDataById.min_investment}
          funding_goal={offeringDataById.funding_goal}
          refreshData={refreshData}
        />
      </div>
    );
  };

  const DealHighlights = ({ item }) => {
    const [isOpen, setOpen] = useState(false);
    return (
      <React.Fragment>
        <li onClick={() => setOpen(!isOpen)}>
          {item.title}
          {isOpen && item?.description?.length > 0 && <p>{item.description}</p>}
        </li>
      </React.Fragment>
    );
  };

  /*============================================
          render bottom left
  ============================================*/

  const renderBottomLeft = () => {
    return (
      <>
        <div className="render-bottom-left-div">
          <div className="row">
            <div className="col-md-6">
              <p className="click-inside">
                Click Inside to Open the Pitch Deck
              </p>
              <StartUpDetailsDisplayPdf
                pitchDeck={
                  !isEmpty(offeringDataById.organization)
                    ? offeringDataById.organization.pitch_deck?.url
                    : null
                }
              />

              <div className="vl-render-bottom-left-div-video-url-block">
                {!isEmpty(offeringDataById.organization.pitch_video_url) && (
                  <>
                    {/* <a
                      href={
                        offeringDataById.organization.pitch_video_url.includes(
                          "http"
                        )
                          ? offeringDataById.organization.pitch_video_url
                          : `https://${offeringDataById.organization.pitch_video_url}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="font-18-roboto-medium vl-render-bottom-left-div-video-url">
                        Watch Video Pitch Here
                      </span>
                    </a> */}
                    <StartUpDetailsWatchVideoModal
                      videoLink={offeringDataById.organization.pitch_video_url}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="startup-rightside">
                <h4 className="startup-font-18-roboto-bold startup-font-18-roboto-bold--about">
                  About
                </h4>
                <div
                  className="render-bottom-left-para-div"
                  dangerouslySetInnerHTML={{
                    __html: orgDataById.company_details,
                  }}
                ></div>
                <h4 className="startup-fonttext startup-font-18-roboto-bold startup-font-18-roboto-bold--deal">
                  Deal Highlights
                </h4>
                <div
                  className="render-bottom-left-para-div"
                  dangerouslySetInnerHTML={{
                    __html: offeringDataById.deal_highlights,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="investors-main">
            {/* <div>
              <h5 className="startup-font-18-roboto-bold startup-font-18-roboto-bold--mentors">
                Mentors
              </h5>
              <div className="row flex-norap mx-0 align-items-center pt-25 pl-30">
                {dummyData.map((data, key) => (
                  <div className="startup-investors-img-div" key={key}>
                    <img src={data} alt="mentors memeber" />
                  </div>
                ))}
                <h5 className="startup-font-18-roboto-medium">
                  View All 34 Mentors
                </h5>
              </div>
            </div> */}
            <div className="bottom-line">
              <h5>Investors</h5>

              {!isEmpty(raiseCapitalInvestors) && (
                <div className="row mx-0 align-items-center pt-25 pl-30 startup-investors-main-div">
                  {raiseCapitalInvestors.map((data, index) => (
                    <Fragment key={index}>
                      {index < 3 && !isEmpty(data.user) && (
                        <StartUpDetailsViewAllInvestors userId={data.user.id} />
                      )}
                    </Fragment>
                  ))}
                  {/* <Link to={raiseCapitalWebAppLink}> */}
                  <span
                    className="startup-font-18-roboto-medium"
                    onClick={() =>
                      localStorage.setItem(
                        "activeVLRaiseCapitalAfterProfileSetupPageTabIndex",
                        1
                      )
                    }
                  >
                    {/* View All */}
                    {raiseCapitalInvestors.length}{" "}
                    {raiseCapitalInvestors.length > 1
                      ? "Investors"
                      : "Investor"}
                  </span>
                  {/* </Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
  const teamsSheetRenderBlock = () => {
    return (
      <div className="menudata-head">
        <h1>Term Sheet</h1>{" "}
        <div className="vl-tearm-table">
          <table className="vl-table-teams table-striped w-100">
            <tbody>
              <tr>
                <td>
                  <span className="tablefirst-line">Round Type:</span>
                </td>
                <td>
                  <span className="">{offeringDataById.round_type}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Minimum Investment:</span>
                </td>
                <td>
                  <span className="">
                    <DisplayCurrencyFormatString
                      value={offeringDataById.min_investment}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Funding Minimum:</span>
                </td>
                <td>
                  <span className="">
                    <DisplayCurrencyFormatString
                      value={offeringDataById.funding_goal}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Funding Maximum:</span>
                </td>
                <td>
                  <span className="">
                    <DisplayCurrencyFormatString
                      value={offeringDataById.max_funding_goal}
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Agreement Type</span>
                </td>
                <td>
                  <span className="">
                    {getUIAgreementTypeValueFunction(
                      offeringDataById.agreement_type
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Security Type:</span>
                </td>
                <td>
                  <span className="">
                    {getUISecurityTypeValueFunction(
                      offeringDataById.security_type
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">Share Price:</span>
                </td>
                <td>
                  <span className="">
                    <DisplayCurrencyFormatString
                      value={
                        offeringDataById.share_price
                          ? parseFloat(offeringDataById.share_price).toFixed(2)
                          : "0.00"
                      }
                    />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="tablefirst-line">
                    {offeringDataById.agreement_type ===
                    "Subscription_Agreement"
                      ? "Valuation"
                      : "Valuation Cap"}
                    :
                  </span>
                </td>
                <td>
                  <span className="">
                    <span className="">
                      <DisplayCurrencyFormatString
                        value={offeringDataById.valuation_cap}
                      />
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  /*============================================
          render
  ============================================*/
  return (
    <>
      {!isEmpty(offeringDataById?.offering_status) &&
      OFFERING_STATUS?.includes(offeringDataById?.offering_status) ? (
        <>
          <div className="start-up-details-page">
            {!displayOnWebsite ? (
              <>
                {history.location.state !== undefined ? (
                  <SideNavbar
                    activeMenu={
                      activeSidebarMenu[
                        history.location.state.activeSidebarMenuCount
                      ]
                    }
                  />
                ) : (
                  <SideNavbar />
                )}
              </>
            ) : (
              <Navbar />
            )}
          </div>
          <div
            className={
              !displayOnWebsite
                ? "vl-main-page-div"
                : "vl-main-page-div vl-main-page-div--website-page"
            }
          >
            {/* <div className="container-fluid"> */}

            {/* {!displayOnWebsite ? (
        <>
          {history.location.state !== undefined ? (
            <TopNavbar
              activeMenu={
                activeTopNavbarMenu[
                  history.location.state.activeTopnavbarMenuCount
                ]
              }
            />
          ) : (
            <TopNavbar />
          )}
        </>
      ) : (
        ""
      )} */}

            <div className="vl-main-page-div__content">
              {!isEmpty(offeringDataById) && !isEmpty(orgDataById) ? (
                <>
                  {auth.isAuthenticated && <PageTitle isDisplayImage={false} />}
                  {/* <div className="container-fluid">
              <div className="startup-div" id="startup-div-id">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <div className="startup-div-row__colm1">
                      {renderTopLeft()}
                      {renderBottomLeft()}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12">
                    {renderTopRight()}  
                  </div>
                </div>
                <StartUpDetailsTabs
                  orgId={offeringDataById.organization.id}
                  isClickedViewFullTeam={isClickedViewFullTeam}
                  setIsClickedViewFullTeam={setIsClickedViewFullTeam}
                />
              </div>
            </div> */}
                  <div className="details-page-main-wrap">
                    <div className="details-page-main">
                      <div className="container-fluid">
                        <div
                          className={`${
                            !isEmpty(offeringDataById?.offering_image?.url)
                              ? "startup-div"
                              : "startup-no-image"
                          } ${auth.isAuthenticated ? "rmMargin" : "addMargin"}`}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="background-image">
                                <img
                                  className="img-fluid"
                                  src={offeringDataById?.offering_image?.url}
                                />
                              </div>
                              <div className="row align-items-center">
                                <div className="col-7">
                                  <div className="profile-left">
                                    <div className="profile-img">
                                      <img
                                        className="img-fluid profile-inner"
                                        src={
                                          offeringDataById?.organization
                                            ?.company_logo?.url
                                        }
                                      />
                                      {!isEmpty(
                                        offeringDataById.organization
                                          .pitch_video_url
                                      ) && (
                                        <div className="playblue">
                                          <button
                                            onClick={() =>
                                              setOpenPitch(!openPitch)
                                            }
                                          >
                                            <img
                                              className="img-fluid"
                                              src={playblue}
                                            />
                                          </button>
                                          {!isEmpty(
                                            offeringDataById.organization
                                              .pitch_video_url
                                          ) && (
                                            <>
                                              <StartUpDetailsWatchVideoModal
                                                videoLink={
                                                  offeringDataById.organization
                                                    .pitch_video_url
                                                }
                                                open={openPitch}
                                                setOpen={setOpenPitch}
                                              />
                                            </>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    <div className="profile-name">
                                      <h2>{orgDataById.company_name}</h2>
                                      {/* <img src={Verified} className="img-fluid" /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div className="follow-btn">
                                    {/* <button>
                              <img src={Star} className="img-fluid" />
                              Follow
                            </button> */}
                                    <PageTitleStartUpDetails
                                      pageTitle={orgDataById.company_name}
                                      orgId={offeringDataById.organization.id}
                                    />
                                    <button onClick={onOpenShareModal}>
                                      <img src={Share} className="img-fluid" />
                                      Share
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="raise-amount-secttion">
                          <div className="row">
                            <div className="col-xl-5 col-lg-12">
                              <div className="raise-amount-inner">
                                <ul>
                                  <li>
                                    <p>Website</p>{" "}
                                    <Link
                                      to={{
                                        pathname: `${orgDataById.website}`,
                                      }}
                                      target="_blank"
                                    >
                                      <span>{orgDataById.website}</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <p>Location</p>{" "}
                                    <span>{orgDataById.location}</span>
                                  </li>
                                  <li>
                                    <p>Total Funding</p>{" "}
                                    <span>
                                      <DisplayCurrencyFormatString
                                        // value={amountRaised}
                                        value={orgDataById.total_funding}
                                      />
                                      <div className="tooltip-funding">
                                        <i className="fa fa-info-circle ml-2" />
                                        <div className="tooltip-text">
                                          The total amount raised prior to
                                          listing on VedasLabs.
                                        </div>
                                      </div>
                                    </span>
                                  </li>
                                  <li>
                                    <p>Round Type</p>{" "}
                                    <span>{offeringDataById.round_type}</span>
                                  </li>
                                  <li>
                                    <p>Team</p>
                                    <div className="d-flex mx-0 align-items-center  pl-0 vl-disc-card-row__imgAndBtnDiv-img-row">
                                      {!isEmpty(raiseCapitalTeam) ? (
                                        <>
                                          {raiseCapitalTeam.map(
                                            (data, index) => (
                                              <Fragment key={index}>
                                                {index < 3 && (
                                                  <div className="startup-team-img-div">
                                                    {!isEmpty(
                                                      data.profile_image
                                                    ) ? (
                                                      <img
                                                        src={
                                                          data.profile_image.url
                                                        }
                                                        alt="team member"
                                                        className="img-fluid"
                                                      />
                                                    ) : (
                                                      <span className="font-24-roboto-medium">
                                                        {!isEmpty(
                                                          data.full_name
                                                        ) &&
                                                          data.full_name.charAt(
                                                            0
                                                          )}
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                              </Fragment>
                                            )
                                          )}
                                          <button
                                            className="startup-font-18-roboto-medium startup-details"
                                            onClick={
                                              handleOnClickViewFullTeamButton
                                            }
                                          >
                                            View Full Team
                                          </button>
                                        </>
                                      ) : (
                                        <div className="startup-team-img-div opacity-0">
                                          lorem
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                  <li>
                                    <p>Tags</p>
                                    <div className="teammain-btn">
                                      <div className="team-btn teamline-one">
                                        {!isEmpty(tagsById) &&
                                          parseTags(
                                            tagsById[0].market_tags
                                          ).map((data, index) => (
                                            <button>{data}</button>
                                          ))}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-12">
                              <div className="max-target-main">
                                <div className="max-target">
                                  {offeringDataById.offering_status ==
                                  "Accepting_Investment" ? (
                                    <p className="remaining">
                                      <span>
                                        {daysRemaining < 0 ? (
                                          "Funding end date is past due"
                                        ) : daysRemaining === 0 ? (
                                          "Today is the last day"
                                        ) : daysRemaining === 1 ? (
                                          "1 Day Remaining"
                                        ) : (
                                          <span>
                                            {`${daysRemaining} `}
                                            <span className="remaining-days">
                                              days remaining
                                            </span>
                                          </span>
                                        )}
                                      </span>
                                    </p>
                                  ) : offeringDataById.offering_status ===
                                    "VL_Reserve" ? (
                                    <p className="remaining">
                                      Accredited Investors Only <br /> Reg D
                                      506(c)
                                    </p>
                                  ) : (
                                    <p className="reserved-list-text">
                                      Join the reserve list for a chance to
                                      invest if the offering becomes available.
                                      {/* <Tooltip show={true} animation="bounce">
                                <span>Some text</span>
                              </Tooltip> */}
                                      <span
                                        className="tooltip-reserved"
                                        style={{ position: "relative" }}
                                        onMouseOver={() => showTooltip(true)}
                                        onMouseLeave={() => showTooltip(false)}
                                      >
                                        {/* <i className="fa fa-info-circle" style="font-size:36px"></i> */}
                                        <i className="fa fa-info-circle"></i>
                                        <Tooltip
                                          show={isTooltip}
                                          arrowAlign="end"
                                          position="top center"
                                          textBoxWidth="270px"
                                        >
                                          <span className="info-text">{`Reserving gives you a priority allocation if the deal launches.`}</span>
                                          <span className="info-text">{`A “reservation” involves no obligation or commitment to invest by you or the company.`}</span>
                                          <span className="info-text">{`No money, investment commitment, or other consideration, is being solicited and will not be accepted.`}</span>
                                          <span className="info-text">{`If and when the offering is qualified and ${orgDataById.company_name}. starts accepting investments through VedasLabs, the reservation holders will be invited to invest.`}</span>
                                        </Tooltip>
                                      </span>
                                      {/* <span className="tooltip-reserved">
                              <i>i</i>
                              <span className="tooltiptext-reserved">hello</span>
                              </span> */}
                                    </p>
                                  )}
                                  <div className="amount-raised-card-div-progress-bar">
                                    <Circle
                                      percent={fundRaised}
                                      strokeWidth="7"
                                      strokeColor="#323277"
                                      trailWidth="7"
                                      trailColor="rgba(50, 50, 119, 0.2)"
                                    />
                                    <div className="amount-raised-card-div-progress-bar-text-div">
                                      <h5 className="amount-raised-card-font-24-roboto-bold text-center">
                                        {fundRaised.toLocaleString("en-US", {
                                          maximumFractionDigits: 2,
                                          minimumFractionDigits: 0,
                                        })}
                                        %
                                      </h5>
                                      {/* <h5 className="amount-raised-card-font-24-roboto-medium">
                                {offeringDataById.offering_status ==
                                "Offering_Posted"
                                  ? "Reserved"
                                  : "Funded"}  
                                <div className="vl-start-up-details-question-mark-main-div">
                                  <button
                                    onMouseOver={() =>
                                      setDisplayTooltip(true)
                                    }
                                    onMouseLeave={() =>
                                      setDisplayTooltip(false)
                                    }
                                    className="vl-start-up-details-question-mark"
                                  >
                                    i
                                  </button>
                                  {displayTooltip && (
                                    <p className="vl-start-up-details-question-mark-main-div__tooltip">
                                      The percentage reserved of the funding
                                      Minimum.
                                    </p>
                                  )}
                                </div>
                              </h5> */}
                                      <h5 className="amount-raised-card-font-24-roboto-medium">
                                        {offeringDataById.offering_status ===
                                        "Offering_Posted" ? (
                                          daysRemaining >= 0 ? (
                                            "Reserved"
                                          ) : (
                                            "Reserve closed"
                                          )
                                        ) : offeringDataById.offering_status ===
                                          "VL_Reserve" ? (
                                          <div className="d-flex justify-content-center">
                                            <h5 className="amount-raised-card-font-24-roboto-medium">
                                              Funded
                                            </h5>
                                            <div className="vl-start-up-details-question-mark-main-div">
                                              <button
                                                onMouseOver={() =>
                                                  setDisplayTooltip(true)
                                                }
                                                onMouseLeave={() =>
                                                  setDisplayTooltip(false)
                                                }
                                                className="vl-start-up-details-question-mark"
                                              >
                                                i
                                              </button>
                                              {displayTooltip && (
                                                <p className="vl-start-up-details-question-mark-main-div__tooltip">
                                                  The percentage reserved of the
                                                  funding Minimum.
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="d-flex justify-content-center">
                                            <h5 className="amount-raised-card-font-24-roboto-medium">
                                              Funded
                                            </h5>
                                            <div className="vl-start-up-details-question-mark-main-div">
                                              <button
                                                onMouseOver={() =>
                                                  setDisplayTooltip(true)
                                                }
                                                onMouseLeave={() =>
                                                  setDisplayTooltip(false)
                                                }
                                                className="vl-start-up-details-question-mark"
                                              >
                                                i
                                              </button>
                                              {displayTooltip && (
                                                <p className="vl-start-up-details-question-mark-main-div__tooltip">
                                                  The percentage raised of the
                                                  funding minimum.
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </h5>
                                    </div>
                                  </div>
                                  {RenderInvestBtn()}
                                </div>
                                <div className="target-tex">
                                  <h3>
                                    {offeringDataById.offering_status ===
                                    "Accepting_Investment"
                                      ? "Raised"
                                      : offeringDataById.offering_status ===
                                        "Offering_Posted"
                                      ? "Reserved"
                                      : "Amount Commited"}
                                  </h3>
                                  <h1>
                                    {offeringDataById.offering_status.toLocaleLowerCase() ===
                                    "Accepting_Investment".toLocaleLowerCase() ? (
                                      <DisplayCurrencyFormatString
                                        value={amountRaised}
                                      />
                                    ) : offeringDataById.offering_status.toLocaleLowerCase() ===
                                      "VL_Reserve".toLocaleLowerCase() ? (
                                      <DisplayCurrencyFormatString
                                        value={amountCommited}
                                      />
                                    ) : offeringDataById.offering_status.toLocaleLowerCase() ===
                                      "Offering_Posted".toLocaleLowerCase() ? (
                                      <DisplayCurrencyFormatString
                                        value={amountReserved}
                                      />
                                    ) : (
                                      offeringDataById.offering_status.toLocaleLowerCase() ===
                                        "Reserved" && (
                                        <DisplayCurrencyFormatString
                                          value={amountReserved}
                                        />
                                      )
                                    )}
                                  </h1>
                                  <ul>
                                    <li>
                                      {" "}
                                      <p>Valuation Cap:</p>{" "}
                                      <p className="fund_details">
                                        <DisplayCurrencyFormatString
                                          value={offeringDataById.valuation_cap}
                                        />
                                      </p>
                                    </li>
                                    <li>
                                      {" "}
                                      <p>Funding Goal:</p>{" "}
                                      <p className="fund_details">
                                        <DisplayCurrencyFormatString
                                          value={offeringDataById.funding_goal}
                                        />
                                      </p>
                                    </li>
                                    <li>
                                      {" "}
                                      <p>Minimum Investment:</p>{" "}
                                      <p className="fund_details">
                                        <DisplayCurrencyFormatString
                                          value={
                                            offeringDataById.min_investment
                                          }
                                        />
                                      </p>
                                    </li>
                                    {/* <li>
                              {" "}
                              <p>Number of investors:</p>
                              <p className="fund_details">50</p>
                            </li> */}
                                  </ul>
                                  {offeringDataById.offering_status !==
                                    "Offering_Posted" && (
                                    <div className="closing-text">
                                      <p>
                                        Closing on{" "}
                                        <span>
                                          {!isEmpty(
                                            offeringDataById.fund_end_date
                                          ) &&
                                            format(
                                              new Date(
                                                offeringDataById.fund_end_date
                                              ),
                                              "DD-MMM-YYYY"
                                            )}
                                        </span>{" "}
                                      </p>
                                    </div>
                                  )}
                                  {offeringDataById.offering_status !==
                                  "Offering_Posted"
                                    ? !(
                                        <a
                                          href={offeringDataById.issuers_form_c}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <div className="issuers-btn">
                                            <button>
                                              Issuers Form C filed with the SEC{" "}
                                              <img
                                                src={arrow}
                                                className="img-fluid"
                                              />{" "}
                                            </button>
                                          </div>
                                        </a>
                                      )
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="highlights-documents">
                          <div className="row">
                            <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6">
                              <h1>Deal Highlights</h1>
                              <div className="highlight-innet">
                                <ul>
                                  {offeringDataById.deal_highlights.map(
                                    (item) => (
                                      <DealHighlights item={item} />
                                    )
                                  )}
                                </ul>
                                {offeringDataById.deal_highlights.length ===
                                  0 && (
                                  <p className="vl-disc-card-row__text3 font-18-roboto-medium">
                                    No Highlights
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6">
                              <h1>Documents</h1>
                              {/* <button onClick={handle.enter}>FullScreen</button> */}
                              <div className="documents-inner">
                                {/* <p>
                            {offeringDataById.organization.company_name} 
                            VedasLabs (CRD #304199) is hosting this offering.
                            View the official SEC filings and all updates:
                          </p> */}
                                {/* <div className="document-dow">
                            <img className="img-fluid" src={sec_logo} />
                            <p>Form C</p>
                            <span>SEC.gov</span>
                          </div> */}
                                <div className="company-documents">
                                  <h3>Company documents</h3>
                                  <ul>
                                    {offeringDataById.organization.documents.map(
                                      (item) => (
                                        <li>
                                          {" "}
                                          <button
                                            onClick={() => openLink(item.url)}
                                          >
                                            <img
                                              className="img-fluid"
                                              src={carbon_document}
                                            />
                                            {item.name}
                                          </button>
                                        </li>
                                      )
                                    )}
                                    <li>
                                      <button onClick={() => handelPdfModal()}>
                                        <img
                                          className="img-fluid click-play-btn"
                                          src={play}
                                        />{" "}
                                        Click Inside to Open the Pitch Deck
                                      </button>
                                      <StartUpDetailsDisplayPdf
                                        managePopup={managePopup}
                                        handelPdfModal={() => handelPdfModal()}
                                        // handle={handle}
                                        // handelPdfScreen={handelPdfScreen}
                                        pitchDeck={
                                          !isEmpty(
                                            offeringDataById.organization
                                          )
                                            ? offeringDataById.organization
                                                .pitch_deck?.url
                                            : null
                                        }
                                      />
                                      {/* <FullScreen
                                  handle={handle}
                                  onChange={(e) => handleSetScreen(e)}
                                >
                                  <div
                                    className={`${
                                      !isFullScreen && "fullScreenOff"
                                    }`}
                                  >
                                    <div className="full-vl-pitch-deck-file-modal-content-div font-16-roboto-regular">
                                      <Document
                                        file={
                                          !isEmpty(
                                            offeringDataById.organization
                                          )
                                            ? offeringDataById.organization
                                                .pitch_deck?.url
                                            : null
                                        }
                                        onLoadSuccess={
                                          onModalDocumentLoadSuccess
                                        }
                                      >
                                        <Page
                                          pageNumber={currentPageNumber}
                                        />
                                      </Document>
                                    </div>
                                    {!isPdfLoading ? (
                                      <div className="row mx-0 align-items-center justify-content-center pagination-model-number">
                                        <div className="pdf-pagenation">
                                          <button
                                            className="vl-pdf-screen-prev-btn"
                                            onClick={handleOnClickPrev}
                                            disabled={
                                              currentPageNumber > 1
                                                ? false
                                                : true
                                            }
                                          >
                                            <i className="fa fa-angle-double-left next"></i>
                                          </button>
                                          <span className="vl-pdf-screen-text">
                                            <p className="page-number">
                                              {currentPageNumber}{" "}
                                            </p>
                                            <p>/</p>
                                            <p>{totalPdfScreens}</p>
                                          </span>
                                          <button
                                            className="vl-pdf-screen-next-btn"
                                            onClick={handleOnClickNext}
                                            disabled={
                                              totalPdfScreens >
                                              currentPageNumber
                                                ? false
                                                : true
                                            }
                                          >
                                            <i className="fa fa-angle-double-right next"></i>
                                          </button>
                                        </div>
                                        <button
                                          className="top-right-close"
                                          onClick={handle.exit}
                                        >
                                          <i class="fa fa-close modal-content-close"></i>
                                          Close
                                        </button>
                                      </div>
                                    ) : (
                                      <button className="vl-pdf-screen-prev-btn opacity-0">
                                        <i className="fa fa-caret-left"></i>
                                      </button>
                                    )}
                                  </div>
                                </FullScreen> */}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6">
                              {teamsSheetRenderBlock()}
                            </div>
                          </div>
                        </div>
                        <div className="menu-line">
                          <ul ref={myRef}>
                            <li
                              className={
                                selectedTab === 0
                                  ? "active-menu"
                                  : "inActive-menu"
                              }
                            >
                              <button onClick={() => setSelectedTab(0)}>
                                About
                              </button>
                            </li>
                            <li
                              className={
                                selectedTab === 1
                                  ? "active-menu"
                                  : "inActive-menu"
                              }
                            >
                              <button onClick={() => setSelectedTab(1)}>
                                {" "}
                                Team
                              </button>
                            </li>
                            <li
                              className={
                                selectedTab === 2
                                  ? "active-menu"
                                  : "inActive-menu"
                              }
                            >
                              <button onClick={() => setSelectedTab(2)}>
                                Discussion
                              </button>
                            </li>
                            <li
                              className={
                                selectedTab === 3
                                  ? "active-menu"
                                  : "inActive-menu"
                              }
                            >
                              <button onClick={() => setSelectedTab(3)}>
                                Updates
                              </button>
                            </li>
                          </ul>
                          {/* <button
                    onClick={() => {
                      myRef.current.scrollLeft += 70;
                    }}
                  >{`->`}</button> */}
                        </div>
                        {selectedTab === 0 ? (
                          <AboutTab />
                        ) : selectedTab === 1 ? (
                          <TeamTab />
                        ) : selectedTab === 2 ? (
                          <Discussion />
                        ) : selectedTab === 3 ? (
                          <StartUpDetailsTabPanelUpdates
                            orgId={offeringDataById.organization.id}
                          />
                        ) : null}
                        {/* <StartUpDetailsLightPaper /> */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="w-100 pt-5 text-center">
                  <DisplayLoader />
                </div>
              )}
            </div>
            {/* <div className="text-center">
      <img src={QR} alt="Qr" className="img-fluid" />
    </div> */}
            {!displayOnWebsite && <Footer webAppFooter={true} />}
            {/* </div> */}
          </div>
          <SocialShare
            open={openShare}
            onOpenModal={onOpenShareModal}
            onCloseModal={onCloseShareModal}
          />

          {displayOnWebsite && <Footer />}
          <ScrollTopArrow />
        </>
      ) : (
        !isEmpty(offeringDataById?.offering_status) && <DefaultComponent />
      )}
    </>
  );
}
