import isEmpty from "../is-empty";

/**
 * validateMentorProfile Function
 *
 * Overview:
 * This function is used to validate the mentor profile data. It checks if the required fields are empty and returns any errors found.
 *
 * Details:
 * - The function takes in a data object as a parameter, which contains the mentor profile information.
 * - It initializes an empty errors object to store any validation errors.
 * - The function checks if the sectors_of_interest, company, job_title, and linkedin_link fields are empty. If any of these fields are empty, an error message is added to the errors object.
 * - The function then returns an object containing the errors and a boolean value indicating if the data is valid (isEmpty(errors) returns true if there are no errors).
 *
 * Dependencies:
 * - The function depends on the isEmpty function, which is not defined in this file. It is assumed that the isEmpty function is imported or defined elsewhere.
 */
export const validateMentorProfile = (data, frontEndErrors) => {
  let errors = {};
  let errorIndustry = [];
  let isValid = true;

  if (isEmpty(data.company)) {
    errors.company = "Company is required";
  }

  if (isEmpty(data.job_title)) {
    errors.job_title = "Job title is required";
  }

  if (isEmpty(data.linkedin_link)) {
    errors.linkedin_link = "Linkedin URL is required";
  }

  if (isEmpty(data.bio)) {
    errors.bio = "Mentor bio is required";
  }

  data.sectors_of_interest?.map((ele, i) => {
    let errorObj = {};
    if (isEmpty(ele.areaOfExpertise)) {
      errorObj.areaOfExpertise = "Area of expertise is required";
    }
    if (isEmpty(ele?.yearsOfExpertise)) {
      errorObj.yearsOfExpertise = "Years of experience is required";
    }
    // if (isEmpty(ele?.imgUrl)) {
    //   errorObj.imgUrl = "Image is required";
    // }
    errorIndustry[i] = errorObj;
    if (!isEmpty(errorIndustry[i])) {
      errors.sectors_of_interest = errorIndustry;
    }
  });
  if (!isEmpty(errors)) {
    isValid = false;
  }
  return {
    errors,
    isValid: isValid,
  };
};
