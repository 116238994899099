import React, {
  useEffect,
  // useEffect,
  useState,
} from "react";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { Document } from "react-pdf/dist/esm/entry.webpack";
import { Page, pdfjs } from "react-pdf";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Modal from "react-responsive-modal";
// import Modal from "react-bootstrap/Modal";

/**
 * StartUpDetailsDisplayPdf Component
 * 
 * Overview:
 * The StartUpDetailsDisplayPdf component is responsible for displaying a PDF document within a modal. It allows the user to navigate through the pages of the PDF, go to the previous or next page, and view the current page number. The component also provides a full-screen mode option.
 * 
 * Details:
 * - The component receives the following props:
 *   - pitchDeck: The URL of the PDF document to be displayed.
 *   - handle: A function to handle events related to the component.
 *   - managePopup: A boolean value indicating whether the modal is open or closed.
 *   - handelPdfModal: A function to handle the closing of the modal.
 * - The component uses the useState hook to manage the following states:
 *   - totalPdfScreens: The total number of pages in the PDF document.
 *   - currentPageNumber: The current page number being displayed.
 *   - isPdfLoading: A boolean value indicating whether the PDF document is still loading.
 * - The component uses the useEffect hook to set up the PDF.js library and initialize the current page number.
 * - The component renders a modal with navigation buttons, page number display, and a close button.
 * - When the PDF document is loaded successfully, the total number of pages is set and the loading state is updated.
 * - The component uses the Document and Page components from the react-pdf library to render the PDF document.
 * 
 * Dependencies:
 * - react: The React library for building user interfaces.
 * - react-dom: The React library for rendering components.
 * - react-pdf: A library for rendering PDF documents in React applications.
 * - react-modal: A library for creating accessible modal dialogs in React applications.
 * - pdfjs-dist: A library for working with PDF documents in JavaScript.
 */
export default function StartUpDetailsDisplayPdf({
  pitchDeck,
  handle,
  managePopup,
  handelPdfModal,
  // handelPdfScreen,
}) {
  const [totalPdfScreens, setTotalNumOfScreens] = useState(null);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [isPdfLoading, setIsPdfLoading] = useState(true);

  const onModalDocumentLoadSuccess = (totalPdfScreens) => {
    setIsPdfLoading(false);
    setTotalNumOfScreens(totalPdfScreens._pdfInfo.numPages);
  };

  const handleOnClickPrev = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentPageNumber > 1) {
      setCurrentPageNumber(currentPageNumber - 1);
    }
  };

  const handleOnClickNext = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (totalPdfScreens > currentPageNumber) {
      setCurrentPageNumber(currentPageNumber + 1);
    }
  };

  const handelPdfScreen = () => {
    window.open(pitchDeck, "_blank");
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setCurrentPageNumber(1);
  }, []);

  return (
    <>
      {/* <Modal
        show={managePopup}
        onHide={handelPdfModal}
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Body>
          {!isPdfLoading ? (
            <div className="row mx-0 align-items-center">
              <div className="d-flex align-items-center model-pagination">
                <div className="model-prev-next-btn">
                  <div className="full-screen-btn">
                    <button className="full-screen-main" onClick={handle.enter}>
                      <i class="fa fa-arrows-alt expand"></i>
                    </button>
                  </div>
                  <button
                    className="vl-pdf-screen-prev-btn"
                    onClick={handleOnClickPrev}
                    disabled={currentPageNumber > 1 ? false : true}
                  >
                    <i className="fa fa-angle-double-left model-back"></i>
                  </button>
                  <div className="vl-pdf-screen-text">
                    <p className="page-number">{currentPageNumber} </p>
                    <p>/</p>
                    <p>{totalPdfScreens}</p>
                  </div>
                  <button
                    className="vl-pdf-screen-next-btn"
                    onClick={handleOnClickNext}
                    disabled={
                      totalPdfScreens > currentPageNumber ? false : true
                    }
                  >
                    <i className="fa fa-angle-double-right model-next"></i>
                  </button>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={handelPdfModal}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button className="vl-pdf-screen-prev-btn opacity-0">
              <i className="fa fa-caret-left"></i>
            </button>
          )}
          <div className="vl-pitch-deck-file-modal-content-div font-16-roboto-regular">
            <Document
              file={pitchDeck}
              onLoadSuccess={onModalDocumentLoadSuccess}
            >
              <Page pageNumber={currentPageNumber} />
            </Document>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal
        open={managePopup}
        onClose={handelPdfModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal customModal--pitch-deck-pdf",
          closeButton: "customCloseButton",
        }}
      >
        {!isPdfLoading ? (
          <div className="row mx-0 align-items-center">
            <div className="d-flex align-items-center model-pagination">
              <div className="model-prev-next-btn">
                <div className="full-screen-btn">
                  <button
                    className="full-screen-main"
                    onClick={handelPdfScreen}
                  >
                    <i class="fa fa-arrows-alt expand"></i>
                  </button>
                </div>
                <button
                  className="vl-pdf-screen-prev-btn"
                  onClick={handleOnClickPrev}
                  disabled={currentPageNumber > 1 ? false : true}
                >
                  <i className="fa fa-angle-double-left model-back"></i>
                </button>
                <div className="vl-pdf-screen-text">
                  <p className="page-number">{currentPageNumber} </p>
                  <p>/</p>
                  <p>{totalPdfScreens}</p>
                </div>
                <button
                  className="vl-pdf-screen-next-btn"
                  onClick={handleOnClickNext}
                  disabled={totalPdfScreens > currentPageNumber ? false : true}
                >
                  <i className="fa fa-angle-double-right model-next"></i>
                </button>
                <div className="d-flex">
                  <span className="closeIconInModal" onClick={handelPdfModal} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <button className="vl-pdf-screen-prev-btn opacity-0">
            <i className="fa fa-caret-left"></i>
          </button>
        )}
        <div className="vl-pitch-deck-file-modal-content-div font-16-roboto-regular">
          <Document file={pitchDeck} onLoadSuccess={onModalDocumentLoadSuccess}>
            <Page pageNumber={currentPageNumber} />
          </Document>
        </div>
      </Modal>
    </>
  );
}
