/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Rectangle from "../../../assets/imgs/desktop/icon/rectangle.svg";
import isEmpty from "../../../web-app-store/validations/is-empty";
import {
  mentorDetails,
  mentorDetailsWebAppLink,
} from "../header-footer/pageLinks";
import AvatarProfile from "../../../assets/imgs/desktop/common/user-image.png";

/**
 * MentorCard Component
 *
 * Overview:
 * The MentorCard component displays a list of mentors and provides sorting functionality. It is used to render mentor cards in a grid layout and allows users to sort the mentors by newest or oldest. The component also includes a "View More" button that loads additional mentors when clicked.
 *
 * Details:
 * - The component uses the useHistory hook from react-router-dom to handle navigation.
 * - It receives the mentorRecords prop, which is an array of mentor data.
 * - The component uses the useState hook to manage the state of the displayed mentors and the sorting details.
 * - The useEffect hook is used to filter and update the displayed mentors based on changes in the mentorRecords prop.
 * - The handleManageSorting function is responsible for sorting the mentors based on the selected sort key.
 * - The handleViewMore function updates the index state to load more mentors when the "View More" button is clicked.
 *
 * Usage:
 * To use the MentorCard component, pass the mentorRecords prop with an array of mentor data. The component will render the mentor cards and provide sorting functionality.
 *
 * Dependencies:
 * - react-router-dom: Used to handle navigation with the useHistory hook.
 * - isEmpty: A utility function used to check if an object or array is empty.
 * - Rectangle: An image used as a visual element in the component.
 * - AvatarProfile: An image used as a default profile image for mentors.
 * - mentorDetails: A variable representing the URL path for mentor details.
 */
const MentorCard = (props) => {
  const history = useHistory();
  const { mentorRecords } = props;
  const [manageMentor, setManageMentor] = useState({
    displayMentor: [],
    index: 12,
    isComplete: true,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let mentorRecord;
    if (!isEmpty(mentorRecords)) {
      let mentorData = [...mentorRecords];
      mentorRecord = mentorData.filter(
        (item) =>
          item.live_profile !== "InActive Profile" && item.live_profile !== null
      );
    }
    setManageMentor({
      ...manageMentor,
      displayMentor: mentorRecord,
    });
  }, [mentorRecords]);

  const handleManageSorting = (sortKey) => {
    let sortDetails;
    if (!isEmpty(manageMentor?.displayMentor)) {
      if (sortKey.toLowerCase() === "Oldest".toLowerCase()) {
        sortDetails = manageMentor?.displayMentor?.sort(
          (a, b) => parseFloat(a.id) - parseFloat(b.id)
        );
      } else if (sortKey.toLowerCase() === "Newest".toLowerCase()) {
        sortDetails = manageMentor?.displayMentor?.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
      }
      console.log(sortDetails);
      setManageMentor({
        ...manageMentor,
        displayMentor: sortDetails,
      });
    }
  };

  useEffect(() => {
    let isCompleteDetails;

    if (!isEmpty(manageMentor?.displayMentor)) {
      if (manageMentor?.index > manageMentor?.displayMentor?.length) {
        isCompleteDetails = true;
      } else {
        isCompleteDetails = false;
      }

      setManageMentor({
        ...manageMentor,
        isComplete: isCompleteDetails,
      });
    }
  }, [manageMentor.displayMentor, manageMentor.index]);

  const handleViewMore = () => {
    setManageMentor({
      ...manageMentor,
      index: manageMentor.index + 4,
    });
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="mentors-number">
            {isEmpty(manageMentor.displayMentor?.length) ||
            manageMentor.displayMentor?.length <= 0
              ? ""
              : manageMentor.displayMentor?.length + " " + "Mentors Found"}
          </p>
        </div>
        <div className="filter-card">
          <div className="d-flex align-items-center py-1">
            <p className="filter-name px-3">Sort By</p>
            <img
              src={Rectangle}
              alt=""
              className="rectangle-img"
              style={{ width: "10px" }}
            />
            <form className="filter-name px-3">
              <select
                className="border-0 filter-select"
                onChange={(e) => handleManageSorting(e.target.value)}
              >
                <option value={"Newest"}>Newest</option>
                <option value={"Oldest"}>Oldest</option>
              </select>
            </form>
          </div>
        </div>
      </div>
      <div className="mentor-card">
        <div className="row m-0 g-3">
          {manageMentor?.displayMentor?.length > 0 ? (
            manageMentor?.displayMentor
              .slice(0, manageMentor.index)
              ?.map((item, index) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3"
                    key={index}
                  >
                    <div className="card">
                      <a
                        href={`${
                          userData ? mentorDetailsWebAppLink : mentorDetails
                        }/${item.id}`}
                      >
                        <div className="card-wrap">
                          {!isEmpty(item?.profile_image?.url) ? (
                            <img
                              className="mentor-image"
                              src={item?.profile_image?.url}
                              alt="mentor-img"
                            />
                          ) : (
                            <img
                              className="mentor-image"
                              src={AvatarProfile}
                              alt="mentor-img"
                            />
                          )}

                          <div className="mentorSub text-center">
                            <h5 className="mentor-name">
                              {item.first_name} {item.last_name}
                            </h5>
                            <p className="mentor-post">{item.job_title}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="no-record-found">No Record Found</div>
          )}
        </div>
        <div className="w-100 text-center py-5">
          <button
            className={
              manageMentor.isComplete ||
              manageMentor?.displayMentor?.length == 0
                ? "view-more-btn-none"
                : "view-more-btn"
            }
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      </div>
    </>
  );
};

export default MentorCard;
