import React, { useEffect, useState } from "react";
import SideNavbar from "../header/SideNavbar";
import TopNavbar from "../header/TopNavbar";
import PageTitle from "../header/PageTitle";

import Footer from "../../../components/desktop/header-footer/Footer";
import KnowBeforeYouInvestFoldOneNew from "../../../components/desktop/know-before-you-invest/KnowBeforeYouInvestFoldOneNew";

/**
 * KnowBeforeYouInvestWeb Component
 * 
 * Overview:
 * The KnowBeforeYouInvestWeb component is responsible for rendering the "Know Before You Invest" page on the web application. It displays information and resources to help users make informed investment decisions.
 * 
 * Details:
 * - The component uses state and effect hooks to manage the foldOneHeight state, which represents the height of the first fold of the page.
 * - The component scrolls to the top of the page when it mounts.
 * - The component retrieves the height of the "vl-main-page-div__page-title-id" element and sets it as the foldOneHeight state.
 * - The component renders a SideNavbar, TopNavbar, PageTitle, KnowBeforeYouInvestFoldOneNew, and Footer components.
 * - The SideNavbar component provides navigation links for the page.
 * - The TopNavbar component displays the site's navigation and branding.
 * - The PageTitle component displays the title of the page.
 * - The KnowBeforeYouInvestFoldOneNew component displays the first fold of the "Know Before You Invest" content.
 * - The Footer component displays the site's footer.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - useState: The component uses the useState hook to manage state.
 * - useEffect: The component uses the useEffect hook to perform side effects.
 * - SideNavbar: The component depends on the SideNavbar component for navigation links.
 * - TopNavbar: The component depends on the TopNavbar component for site navigation and branding.
 * - PageTitle: The component depends on the PageTitle component to display the page title.
 * - KnowBeforeYouInvestFoldOneNew: The component depends on the KnowBeforeYouInvestFoldOneNew component to display the first fold of the "Know Before You Invest" content.
 * - Footer: The component depends on the Footer component to display the site's footer.
 */
export default function KnowBeforeYouInvestWeb() {
  const [foldOneHeight, setFoldOneHeight] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-main-page-div__page-title-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-main-page-div__page-title-id").offsetHeight
      );
    }
  }, []);

  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          <div id="vl-main-page-div__page-title-id">
            <TopNavbar />
            <PageTitle
              isDisplayImage={false}
              pageTitle="Know Before You Invest"
            />
          </div>

          <div className="vl-know-before-you-invest-web">
            <KnowBeforeYouInvestFoldOneNew
              displayOnWebApp={true}
              foldOneHeight={foldOneHeight}
            />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
