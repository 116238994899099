import React from "react";
import img1 from "../../../assets/imgs/desktop/about/about7.png";
import img2 from "../../../assets/imgs/desktop/about/about8.png";
import img3 from "../../../assets/imgs/desktop/about/about9.png";
import img4 from "../../../assets/imgs/desktop/about/about10.png";
import img5 from "../../../assets/imgs/desktop/about/about11.png";
// import img6 from "../../../assets/imgs/desktop/about/about12.png";

import img5Team from "../../../assets/imgs/desktop/about/about5.png";

const teamData = [
  {
    imgPath: img1,
    imgClassName: "our-team-img7",
    name: "Moody Hadi",
    extraclass: "card7-width",
    post: "Innovation",
    marginClass: "mr-95",
    desc:
      "Moody is currently Group Manager of New Product Development & Financial Engineering at Standard & Poors.",
  },
  {
    imgPath: img5Team,
    imgClassName: "our-team-img5",
    name: "Vipin Anand",
    extraclass: "card5-width",
    post: "Backend Engineering",
    //post: "Software Development",
    marginClass: "mr-82",
    desc:
      "Vipin is a Data Engineer at Axioma and was a co-founder of Metricle, which leverages machine learning and analytics to detect market moving events in real time.",
  },
  {
    imgPath: img2,
    imgClassName: "our-team-img8",
    extraclass: "card8-width",
    name: "Oleg Krook",
    post: "Technical",
    marginClass: "mr-72",
    desc:
      "Oleg has 20+ years of engineering experience and 15+ leading tech teams, which included an integrated team at ClassPass that helped the company grow from Seed stage to Series B.",
  },
];
const teamData2 = [
  {
    imgPath: img3,
    imgClassName: "our-team-img9",
    extraclass: "card9-width",
    name: "Steven Hatzakis",
    post: "Blockchain Research",
    marginClass: "mr-67",
    desc:
      "Steven is the Global Head of Blockchain and Cryptocurrency Research at Forexbrokers.com, CEO of his NY-based consulting firm, and is a Director of BCA Ventures Inc. ",
  },
  {
    imgPath: img4,
    imgClassName: "our-team-img9",
    extraclass: "card9-width",
    name: "Patrick Williams",
    post: "Public Relations",
    marginClass: "mr-67",
    desc:
      "Patrick has held Senior Management roles in a number of Entrepreneurial startups, including The Thursday Room, Vetr, and DailyWorth.",
  },
  {
    imgPath: img5,
    imgClassName: "our-team-img11",
    extraclass: "card11-width",
    name: "Lucio Biase",
    post: "Venture Capital",
    marginClass: "mr-67",
    desc:
      "Lucio is a Fin-tech entrepreneur that has two successful exits with NetDelta and LMRKTS.",
  },
  // {
  //   imgPath: img6,
  //   imgClassName: "our-team-img12",
  //   name: "Sandeep Gummadi",
  //   extraclass: "card12-width",
  //   post: "ESG",
  //   desc:
  //     "Sandeep has over 8 years of experience in ESG Investing and is the winner of the MIT COVID-19 “Beat the Pandemic-II” Hackathon in 2020.",
  // },
];

/**
 * AboutUsFoldThree Component
 * 
 * Overview:
 * The AboutUsFoldThree component is responsible for rendering the "Our Advisors" section of the About Us page. It displays information about the advisors in two blocks, each containing a list of advisors with their names, positions, and profile images.
 * 
 * Details:
 * - The component consists of two rendering functions, renderBlockOne and renderBlockTwo, which generate the HTML structure for each block of advisors.
 * - Each rendering function maps over an array of teamData or teamData2, which contains information about each advisor, such as their name, position, profile image path, and additional CSS classes for styling.
 * - The renderBlockOne and renderBlockTwo functions generate a card for each advisor, displaying their profile image, name, position, and a hover card with additional information.
 * - The component accepts no props.
 * 
 * Usage:
 * To use the AboutUsFoldThree component, simply import it and include it in the desired location within the About Us page.
 * 
 * Dependencies:
 * - The component relies on the teamData and teamData2 arrays, which should be defined and populated with the necessary information about the advisors.
 * - The component requires the appropriate CSS classes and styles to be defined for proper rendering.
 */
export default function AboutUsFoldThree() {
  const renderBlockOne = () => {
    return (
      <div className="commonlist-main ouradvisors-one">
        <div className="maincontainer-custom">
          <div className="our-advisor-right-div">
            <div className="row">
              {teamData.map((data, key) => (
                <div className="col-md-4 col-12">
                  <div key={key} className="about-card">
                    {/* <div className={data.marginClass}> */}
                    <div className="about-img-div">
                      <div className="aboutimg-inner">
                        <img
                          src={data.imgPath}
                          alt={data.name}
                          className={data.imgClassName + " img-fluid "}
                        />
                        <div className={`about-hover-card ${data.extraclass}`}>
                          <p>{data.desc}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-text">
                      <h4>
                        {data.name}
                      </h4>
                      <h5>
                        {data.post}
                      </h5>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderBlockTwo = () => {
    return (
      <div className="ouradvisors-two">
        <div className="maincontainer-custom">
          <div className="our-advisor-left-div">
            <div className="row">
              {teamData2.map((data, key) => (
                <div className="col-md-4 col-12">
                  <div key={key} className="about-card about-card--3">
                    {/* <div className={data.marginClass}> */}
                    <div className="about-img-div">
                      <div className="aboutimg-inner">
                        <img
                          src={data.imgPath}
                          alt={data.name}
                          className={data.imgClassName}
                        />
                        <div className={`about-hover-card ${data.extraclass}`}>
                          <p>{data.desc}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card-text">
                      <h4>
                        {data.name}
                      </h4>
                      <h5>
                        {data.post}
                      </h5>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="about-us-fold-three">
      <h2>Our Advisors</h2>
      {renderBlockOne()}
      {renderBlockTwo()}
    </div>
  );
}
