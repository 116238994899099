import axios from "axios";
import { backendUrl } from "./config";
import { SET_WEBSITE_ALL_BLOGS } from "./../types";

/*===================================================================================
            get explore all organizations
====================================================================================*/
/**
 * GetWebsiteAllBlogs Action
 * 
 * Overview:
 * Fetches all blogs from the backend to populate the website's blog section. This action is essential for providing users with access to a wide range of informative and engaging content.
 * 
 * Details:
 * - Makes a GET request to `/blogs` to retrieve all blog posts.
 * - Dispatches an action with type `SET_WEBSITE_ALL_BLOGS` to update the Redux store with the fetched blog data.
 * 
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getWebsiteAllBlogs = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/blogs`);
    if (data) {
      dispatch({
        type: SET_WEBSITE_ALL_BLOGS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get explore offerings / fundraiser by id
====================================================================================*/
/**
 * GetWebsiteBlogById Action
 * 
 * Overview:
 * Retrieves a specific blog post by its ID from the backend. This action enables the detailed viewing of blog content, allowing users to engage with individual posts.
 * 
 * Details:
 * - Executes a GET request to `/blogs/${blogId}` to fetch a specific blog post.
 * - Uses a callback function to provide the retrieved blog data to the caller, facilitating further processing or display of the blog post.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for determining the backend endpoint.
 */
export const getWebsiteBlogById = (
  blogId,
  callBackWebsiteBlogData
) => async () => {
  try {
    let res = await axios.get(`${backendUrl}/blogs/${blogId}`);
    let { data } = res;
    if (data) {
      callBackWebsiteBlogData(data);
    }
  } catch (err) {
    if (err.response.status === 500) {
      callBackWebsiteBlogData(err.response);
    }
  }
};
