import React, { Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { Link } from "react-router-dom";
import { knowBeforeYouInvest, faq } from "../header-footer/pageLinks";
import { contactUs, newLoginSignUpLink } from "../header-footer/pageLinks";

const faqarray = [
  {
    que: "What is crowdfunding?",
  },
  {
    que: "How does it work for Investors?",
  },
  {
    que: "How does it work for Companies?",
  },
  {
    que: "Who can invest and how much?",
  },
  {
    que: "Will I make a return?",
  },
  {
    que: "Can I resell my securities acquired on VedasLabs?",
  },
  {
    que: "What is the minimum investment amount?",
  },
  {
    que: "What are the acceptable payment methods?",
  },
  {
    que: "What is the cancellation policy?",
  },
  {
    que: "Do I have to be a U.S. citizen?",
  },
];
/**
 * FaqFoldTwo Component
 * 
 * Overview:
 * The FaqFoldTwo component is responsible for rendering the second fold of the FAQ section. It provides answers to frequently asked questions related to crowdfunding, investment eligibility, risks, and the process of investing on the VedasLabs platform.
 * 
 * Details:
 * - The component consists of multiple renderAnswerBlock functions, each responsible for rendering a specific answer block.
 * - The renderAnswerBlock functions return JSX elements containing the answers to different questions.
 * - The component accepts the foldOneHeight prop, which determines the height of the first fold in the FAQ section.
 * 
 * Usage:
 * To use the FaqFoldTwo component, import it from the specified file path and include it in the desired location within the application's code. Pass the foldOneHeight prop to customize the height of the first fold in the FAQ section.
 * 
 * Dependencies:
 * - React: The FaqFoldTwo component relies on React to create and manage its UI elements.
 * - JSX: The component uses JSX syntax to define its UI structure.
 * - CSS: The component may require CSS styles to be applied for proper rendering.
 */
export default function FaqFoldTwo({ foldOneHeight }) {
  /*====================================
        renderAnswerBlock
  =====================================*/
  const renderAnswerBlock1 = () => {
    return (
      <>
        <p>
          Thanks to the{" "}
          <a
            href="https://www.sec.gov/spotlight/crowdfunding.shtml"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u> JOBS Act,</u>
          </a>{" "}
          crowdfunding, with its roots as a donation and rewards platform, has
          grown to become an equity-investment platform for startups or
          early-stage companies to raise capital. This new form of private
          investing has opened up sources of new capital for businesses.{" "}
          <a
            href="https://www.investopedia.com/terms/c/crowdfunding.asp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>Read more</u>
          </a>{" "}
          {/* what is “Equity Crowdfunding”? | CNBC */}
        </p>
      </>
    );
  };

  const renderAnswerBlock2 = () => {
    return (
      <>
        <p>
          You must be 18 years or older to invest in a securities-based
          crowdfunding offering.
          <br />
          <br />
          Investments in Title III offerings, such as those pursuant to
          Regulation Crowdfunding, are subject to certain constraints. The
          amount you can invest in a 12-month period is based on your net worth
          (less the value of your primary residence if you own a home) and
          annual income.
          <br />
          <br />
          If either your annual income or your net worth is less than $107,000,
          then during any 12-month period, you can invest up to the greater of
          either $2,200 or 5% of the greater of your annual income or net worth.
          <br />
          <br />
          If both your annual income and your net worth are equal to or more than $107,000,
          then during any 12-month period, you can invest up to 10% of annual income or net worth,
          whichever is greater, but not to exceed $107,000.
          <br />
          <br />
          If you are an accredited investor, as defined under Rule 501 of Regulation D of the Securities Act,
          then there are no limits to the amount you may invest under Regulation Crowdfunding. For more information
          on the definition of an accredited investor please refer to{" "}
          <a
            href="https://www.sec.gov/oiea/investor-alerts-bulletins/ib_crowdfunding-.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>Accredited Investors - Updated Investor Bulletin.</u>{" "}
          </a>
          {/* Accredited Investors - Updated Investor Bulletin.{" "} */}
        </p>
      </>
    );
  };

  const renderAnswerBlock3 = () => {
    return (
      <>
        <p>
          Start-up investing is risky and there is no guarantee that you will
          make a return. You’ll make a return only if the company is acquired or
          if it goes public for a price that is higher than what you paid.
        </p>
      </>
    );
  };

  const renderAnswerBlock4 = () => {
    return (
      <>
        <p>
          <span className="font-semibold">1. Create a Profile</span>
          <br />
          <br />
          Sign up{" "}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            {" "}
            <u> here</u>
          </a>{" "}
          if you haven’t already. We will need some additional information,
          including your name, address and date of birth. We will also ask for
          your annual income and net worth to determine how much you can invest
          under Regulation Crowdfunding. Any individual 18 years or older can
          invest up to $2,200 in a 12-month period, and that limit can increase
          up to $107,000 in a 12-month period depending on your income and net
          worth.
          <br />
          <br />
          <span className="font-semibold">2. Review Opportunities</span>
          <br />
          <br />
          On our website, you will be able to review each company’s pitch as
          well as other information about the company, including their products
          and services, management team, and historical financial statements.
          <br />
          <br />
          Ask companies questions in our discussion room.
          <br />
          <br />
          <span className="font-semibold">3. Invest &amp; Confirm</span>
          <br />
          <br />
          After you click invest and submit payment, you will receive an email
          to confirm your investment. This email will prompt you to
          electronically sign an agreement between you and the company for the
          purchase of equity. Once you confirm your investment, your funds are
          held in escrow by North Capital Trust until the company’s funding goal
          has been met. After a company’s funding goal is met, funds are
          released from the escrow account to the company. You can cancel your
          investment up to 48 hours before a company’s pre-set deadline. After
          that, your investment will be final.
          <br />
          <br />
          Please read{" "}
          <Link to={knowBeforeYouInvest}>
            <u>Know Before You Invest</u>
          </Link>{" "}
          and our{" "}
          <Link to={faq}>
            <span>FAQs</span>
          </Link>{" "}
          for more information on Regulation Crowdfunding and some of the risks
          involved in an investment in a crowdfunding offering.
        </p>
      </>
    );
  };

  const renderAnswerBlock5 = () => {
    return (
      <>
        <p>
          {/*We look for Mission driven US companies that are looking to disrupt an
          industry or create a new one can apply to be listed on the VedasLabs
          platform.*/}
          We encourage mission-driven U.S. companies that are looking to disrupt
          an industry or create a new one to apply to be listed on the VedasLabs
          platform.
          <br />
          <br />
          <span className="font-semibold">1. Apply to Raise</span>
          <br />
          <br />
          {/*Companies can apply to be listed on the VedasLabs platform{" "}
          <a
            href="mailto:TEAM@VEDASLABS.IO"            
            rel="noopener noreferrer"
          >
            <u>here</u>{" "}
          </a>
          . Our team will contact you to gather further information and discuss
          next steps.*/}{" "}
          Companies can apply to be listed on the VedasLabs platform by sending
          a message via our{" "}
          <Link to={contactUs}>
            <u>Contact Us</u>
          </Link>{" "}
          page with an inquiry type of Issuer. Our team will contact you to
          gather further information and discuss next steps.
          <br />
          <br />
          <span className="font-semibold">2. VedasLabs Review</span>
          <br />
          <br />
          We conduct a review of each company’s application where we look at the
          market, technology, founders, team expertise, the problem the company
          solves, and current traction. Prior to being listed on our website,
          companies are required to disclose the following information:
        </p>
        <ul className="pl-28 pt-20">
          <li> Pitch Deck (if available)</li>
          <li> Business Description</li>
          <li> Funding Goal</li>
          <li> Funding Deadline</li>
          <li> Use of Funds</li>
          <li> Information on the Officers and Directors</li>
          <li> Financial Information</li>
          <li> Specific Risks to the Company</li>
          <li> Current Traction</li>
        </ul>
        <br />
        <p>
          <span className="font-semibold">3. Final Decision</span>
          <br />
          <br />
          {/*After we finish our review, we will decide if the company will be
          listed on the platform. VedasLabs then initiates the offering document
          preparation then the startup will be launched on the VedasLabs
          platform. This is where Companies can monitor progress, easily share
          the listing, and interact directly with investors in the discussion
          room to answer questions and share updates.*/}
          After we finish our review, we will decide if the company will be
          listed on the platform. If approved, VedasLabs initiates the offering
          document preparation and then the startup’s offering will be launched
          on the VedasLabs platform. Once the offering is Iive, companies can
          monitor progress, share the listing, and interact directly with
          investors in the discussion room to answer questions and share
          updates.
        </p>
      </>
    );
  };

  const renderAnswerBlock6 = () => {
    return (
      <>
        <p>
          The answer is simply no. Anyone who is over 18 can invest on VedasLabs
          and they don’t have to be located in the United 
          States.
        </p>
      </>
    );
  };

  const renderAnswerBlock7 = () => {
    return (
      <>
        {/*<p>The VedasLabs investment minimum is $10.</p>*/}
        <p>
          The minimum investment amount will vary based on each offering but can
          be as low as $10.
        </p>
      </>
    );
  };

  const renderAnswerBlock8 = () => {
    return (
      <>
        <p>
          VedasLabs accepts credit cards and ACH transfers from US bank
          accounts.
        </p>
      </>
    );
  };

  const renderAnswerBlock9 = () => {
    return (
      <>
        <p>
          Investments can be cancelled up to 48 hours before the company’s
          pre-set funding deadline. After that, your investment will be final,
          and you will not be able to get your money back. <br />
          <br /> Companies reserve the right to reject, in whole or in part, any
          investment commitment at any time before the proceeds are drawn from
          the escrow account. Any rejected investments will be returned to the
          investor in full.
        </p>
      </>
    );
  };

  const renderAnswerBlock10 = () => {
    return (
      <>
        <p>
          Because the startup company issuing the securities is private, you
          cannot sell your securities on the public market, making it
          potentially difficult to find a buyer.
          <br />
          <br />
          In fact, you are restricted from reselling your securities in the
          first 12 months post closing of the offering, unless the shares are
          transferred:
          <br />
          <br />
        </p>
        <ul className="pl-60 mb-0">
          <li> to the company that issued the securities</li>
          <li> to an accredited investor</li>
          <li>
            {" "}
            to a nuclear family member: a child, stepchild, grandchild, parent,
            stepparent, grandparent, spouse or spousal equivalent, sibling,
            mother-in-law, father-in-law, son-in-law, daughter-in-law,
            brother-in-law, or sister-in-law, including adoptive relationships.{" "}
          </li>
          <li>
            {" "}
            In connection with your death, divorce, or other similar
            circumstance
          </li>
          <li>
            {" "}
            to a trust controlled by you or a trust created for the benefit of a
            family member (defined as a child, sibling or parent of you or your
            spouse) or
          </li>
          <li> as part of a later offering registered with the SEC.</li>
        </ul>
        <br /> <br />
        <p>
          Any transfer during this period is still subject to state and foreign
          laws.
          <br />
          <br /> You should know that there may be no market for the securities
          after the initial 12 month restricted period. Once the 12 month
          restricted period ends, any sale or disposition of the securities you
          hold must comply with applicable federal, state and foreign laws.
          <br />
          <br />
          It is important that you only invest capital with the expectation of
          holding your investment for an indefinite period of time, and with the
          real risk of a total loss of your investment in mind. Only invest an
          amount you can afford to lose without changing your lifestyle. You can
          read more about the limitations on selling and transferring private
          securities{" "}
          <a
            href="https://www.sec.gov/info/smallbus/secg/rccomplianceguide-051316.htm#5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u>here</u>
          </a>
          .
        </p>
      </>
    );
  };

  const handleOnClickAccordionButton = (key) => (e) => {
    let elementHeightVal = document.getElementById(`accordion-heading-id-0`)
      .offsetHeight;

    let keyVal = key + 0.5;

    elementHeightVal = elementHeightVal * keyVal;

    let scrollToVal = elementHeightVal + foldOneHeight;
    window.scrollTo({ left: 0, top: scrollToVal, behaviour: "smooth" });
  };
  /*====================================
          render
  =====================================*/
  return (
    <div className="faq-second-div">
      <Accordion
        className="accordion"
        //allowMultipleExpanded={true}
        allowZeroExpanded={true}
      >
        {faqarray.map((data, key) => (
          <Fragment key={key}>
            <AccordionItem>
              <AccordionItemHeading
                id={`accordion-heading-id-${key}`}
                onClick={handleOnClickAccordionButton(key)}
              >
                <AccordionItemButton>{data.que}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {key === 1 ? (
                  <>{renderAnswerBlock4()}</>
                ) : key === 2 ? (
                  <>{renderAnswerBlock5()} </>
                ) : key === 3 ? (
                  <>{renderAnswerBlock2()}</>
                ) : key === 4 ? (
                  <>{renderAnswerBlock3()} </>
                ) : key === 5 ? (
                  <>{renderAnswerBlock10()}</>
                ) : key === 6 ? (
                  <>{renderAnswerBlock7()} </>
                ) : key === 7 ? (
                  <>{renderAnswerBlock8()}</>
                ) : key === 8 ? (
                  <>{renderAnswerBlock9()} </>
                ) : key === 9 ? (
                  <>{renderAnswerBlock6()} </>
                ) : (
                  <>{renderAnswerBlock1()}</>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </Fragment>
        ))}
      </Accordion>
    </div>
  );
}
