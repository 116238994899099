import React from "react";

import MainHeaderMenu from "./MainHeaderMenu";

/**
 * PageTitle Component
 * 
 * Overview:
 * The PageTitle component displays the title of a page in the application's top navigation bar. It can optionally display an image alongside the title. This component is used to provide a consistent and visually appealing title for each page.
 * 
 * Details:
 * - The component accepts three props: isDisplayImage, imgPath, and pageTitle.
 * - If isDisplayImage is true, the component will render the pageTitle along with an image specified by the imgPath prop.
 * - If isDisplayImage is false, the component will only render the pageTitle.
 * - The component is structured as a div container with the class "vl-topnavbar-page-title". Inside this container, there is a div with the class "vl-topnavbar-inner" that contains the title and image elements.
 * - If isDisplayImage is true, the title and image elements are wrapped inside a div with the class "vl-topnavbar__title-text". The image is rendered using an img tag with the src attribute set to the value of imgPath prop, and the alt attribute set to the value of pageTitle prop. The title is rendered using an h1 tag with the class "font-36-roboto-bold vl-topnavbar__title".
 * - If isDisplayImage is false, the title is rendered using an h1 tag with the class "font-36-roboto-bold vl-topnavbar__title vl-topnavbar__noImageTitle".
 * - The component also includes a MainHeaderMenu component, which is rendered after the title and image elements.
 * 
 * Dependencies:
 * - React: The component is built using React and requires it to be installed in your project.
 * - MainHeaderMenu: The component includes a MainHeaderMenu component, which should be imported and available in your project.
 */
export default function PageTitle({ isDisplayImage, imgPath, pageTitle }) {
  return (
    <div className="row mx-0 flex-nowrap align-items-center vl-topnavbar-page-title">
      <div className="vl-topnavbar-inner">
      {isDisplayImage ? (
        <div className="vl-topnavbar__title-text">
          <div className="vl-topnavbar__title-icon-block">
            <img src={imgPath} alt={pageTitle} />
          </div>
          <h1 className="font-36-roboto-bold vl-topnavbar__title">
            {pageTitle}
          </h1>
        </div>
      ) : (
        <h1 className="font-36-roboto-bold vl-topnavbar__title vl-topnavbar__noImageTitle">
          {pageTitle}
        </h1>
      )}
      <MainHeaderMenu />
    </div>
    </div>
  );
}

PageTitle.defaultProps = {
  isDisplayImage: true,
};
