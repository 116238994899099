import React, { Fragment, useState } from "react";
import { Circle } from "rc-progress";
import format from "date-fns/format";
// import differenceInCalendarDays from "date-fns/difference_in_days";

// api
import { useEffect } from "react";
import {
  getRaiseCapitalAllInvestors,
  // getRaiseCapitalAllInvestorsStatusInvested,
} from "../../../web-app-store/actions/raiseCapitalInvestorAction";
import { getRaiseCapitalOfferings } from "../../../web-app-store/actions/raiseCapitalAction";
// import { getInvestorPortfolioOfferingsById } from "../../../web-app-store/actions/investorPortfolioAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../common/DisplayLoader";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import { calcDaysRemaining } from "../common/calcDaysRemainingFunction";
import { getUIInvestmentStatusValueFunction } from "../../../common-website-and-app/getUIValueFunction";

// const dummyData = [
//   {
//     approvedData: "Approved",
//     amountInvested: "$500",
//     investmentDate: "Jan. 1 2021",
//   },
//   {
//     approvedData: "Not Approved",
//     amountInvested: "$1,000",
//     investmentDate: "Jan. 3 2021",
//   },
//   {
//     approvedData: "Approved",
//     amountInvested: "$750",
//     investmentDate: "Jan. 7 2021",
//   },
//   {
//     approvedData: "Approved",
//     amountInvested: "$525",
//     investmentDate: "Jan. 7 2021",
//   },
//   {
//     approvedData: "Approved",
//     amountInvested: "$2,000",
//     investmentDate: "Jan. 12 2021",
//   },
//   {
//     approvedData: "Approved",
//     amountInvested: "$1,000",
//     investmentDate: "Jan. 20 2021",
//   },
// ];

/**
 * RaiseCapitalAfterProfileSetupInvestors Component
 * 
 * Overview:
 * The RaiseCapitalAfterProfileSetupInvestors component is responsible for displaying information related to investors in the raise capital feature after the user has set up their profile. It calculates and displays the total amount raised, funding goal, number of investors, and remaining days for funding. It also renders a table listing the investors and their investment details.
 * 
 * Details:
 * - The component uses Redux to fetch data from the store using the useSelector hook and dispatch actions using the useDispatch hook.
 * - It uses useEffect hook to fetch data from the server and update the component state.
 * - The component uses useState hook to manage local state variables.
 * - It uses the Circle component from an external library to render a progress bar indicating the percentage of funding goal achieved.
 * - The component includes helper functions to calculate the percentage, remaining days, and format currency values.
 * - The renderCard function renders the progress bar and displays the total amount raised, funding goal, number of investors, and remaining days.
 * - The renderTable function renders a table listing the investors and their investment details.
 * 
 * Dependencies:
 * - React: The component is built using React library.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses react-redux library to connect Redux store to the component.
 * - react-router-dom: The component uses react-router-dom library for routing.
 * - react-circular-progressbar: The component uses react-circular-progressbar library to render the progress bar.
 * - date-fns: The component uses date-fns library for date formatting.
 * - Other custom components and hooks used in the component.
 */
export default function RaiseCapitalAfterProfileSetupInvestors() {
  // api
  const dispatch = useDispatch();

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );
  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOfferings)) {
      dispatch(
        getRaiseCapitalAllInvestors(raiseCapitalOfferings[0].offering_id)
      );
    }
  }, [dispatch, raiseCapitalOfferings]);

  const raiseCapitalInvestors = useSelector(
    (state) => state.raiseCapital.raiseCapitalInvestors
  );

  const [totalAmt, setTotalAmt] = useState();
  const [totalFundingGoal, setTotalFundingGoal] = useState();
  const [totalInvestors, setTotalInvestors] = useState();
  const [startEndDate, setStartEndDate] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (!isEmpty(raiseCapitalOfferings)) {
      // dispatch(
      //   getRaiseCapitalAllInvestorsStatusInvested(
      //     raiseCapitalOfferings[0].offering_id
      //   )
      // );

      setTotalFundingGoal(raiseCapitalOfferings[0].funding_goal);

      let newDateObj = {
        startDate: raiseCapitalOfferings[0].fund_start_date,
        endDate: raiseCapitalOfferings[0].fund_end_date,
      };

      setStartEndDate(newDateObj);
    }
  }, [dispatch, raiseCapitalOfferings]);

  // const raiseCapitalInvestorsStatusInvested = useSelector(
  //   (state) => state.raiseCapital.raiseCapitalInvestorsStatusInvested
  // );

  // const [
  //   raiseCapitalInvestorsPortfolioReceivedArray,
  //   setRaiseCapitalInvestorsPortfolioReceivedArray,
  // ] = useState();
  // useEffect(() => {
  //   if (!isEmpty(raiseCapitalInvestorsStatusInvested)) {
  //     setRaiseCapitalInvestorsPortfolioReceivedArray(
  //       raiseCapitalInvestorsStatusInvested
  //     );
  //   }
  // }, [raiseCapitalInvestorsStatusInvested]);

  const [
    raiseCapitalInvestorsPortfolioReceivedArray,
    setRaiseCapitalInvestorsPortfolioReceivedArray,
  ] = useState();
  useEffect(() => {
    if (!isEmpty(raiseCapitalInvestors)) {
      let statusReceivedArray = raiseCapitalInvestors.filter(
        (a) =>
          a.status === "Pending" ||
          a.status === "Invested" ||
          a.status === "Received"
      );
      setRaiseCapitalInvestorsPortfolioReceivedArray(statusReceivedArray);
    }
  }, [raiseCapitalInvestors]);

  useEffect(() => {
    let total = 0;
    if (!isEmpty(raiseCapitalInvestorsPortfolioReceivedArray)) {
      raiseCapitalInvestorsPortfolioReceivedArray.map(
        (data, index) => (total = total + data.amount_received)
      );
    }
    setTotalAmt(total);
  }, [raiseCapitalInvestorsPortfolioReceivedArray]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalInvestorsPortfolioReceivedArray)) {
      setTotalInvestors(raiseCapitalInvestorsPortfolioReceivedArray.length);
    }
  }, [raiseCapitalInvestorsPortfolioReceivedArray]);

  const calcPercent = () => {
    let percent = (totalAmt * 100) / totalFundingGoal;
    percent = percent.toFixed(2);
    return percent;
  };
  const percentCalculated = calcPercent();

  let daysRemaining = calcDaysRemaining(
    startEndDate.startDate,
    startEndDate.endDate
  );

  const renderCard = () => {
    return (
      <div className="vl-raise-capital-investors-card-div row mx-0 align-items-center flex-nowrap">
        <div className="vl-raise-capital-investors-card-progresbar">
          <Circle
            percent={percentCalculated}
            strokeWidth="4"
            strokeColor=" #6DBBC7"
            trailWidth="2"
            trailColor=" #FFFFFF"
          />
          <div className="vl-raise-capital-investors-card-progresbar-text-div">
            <div className=".vl-raise-capital-investors-card-progresbar-text-div__content">
              <h4>
                {/* $196,000 */}
                <DisplayCurrencyFormatString value={totalAmt} />
              </h4>
              <h5>
                {/* 28% */}
                {percentCalculated}% Raised
              </h5>
            </div>
          </div>
        </div>
        <div className="vl-raise-capital-investors-card-right-text-div">
          <h4 className="vl-raise-capital-investors-card-right-text1">
            {/* $390,000 */}

            <DisplayCurrencyFormatString value={totalFundingGoal} />
          </h4>
          <h5 className="vl-raise-capital-investors-card-right-text2">
            Funding Goal
          </h5>
          <h4 className="vl-raise-capital-investors-card-right-text1">
            {/* 34 */}
            {totalInvestors}
          </h4>
          <h5 className="vl-raise-capital-investors-card-right-text2">
            Investors
          </h5>
          {daysRemaining > 0 ? (
            <h4 className="vl-raise-capital-investors-card-right-text1">
              {daysRemaining}
            </h4>
          ) : (
            <h4 className="vl-raise-capital-investors-card-right-text1 opacity-0">
              0
            </h4>
          )}
          <h5 className="vl-raise-capital-investors-card-right-text2">
            {daysRemaining < 0
              ? "Funding end date is past due"
              : daysRemaining === 0
              ? "Today is the last day"
              : daysRemaining === 1
              ? "Day Remaining"
              : `${daysRemaining} days remaining`}
          </h5>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div className="vl-raise-capital-investor-content">
        <h2 className="font-20-roboto-medium">LIST OF INVESTORS</h2>
        <div className="vl-raise-capital-investor-table-heading pt-40">
          <table>
            <thead>
              <tr>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>KYC</span>
                </th>
                <th>
                  <span>Investment Status</span>
                </th>
                <th>
                  <span>Amount Invested</span>
                </th>
                <th>
                  <span>Investment Date</span>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="vl-raise-capital-investor-table">
          <table>
            <tbody>
              {raiseCapitalInvestors.map((data, index) => (
                <Fragment key={index}>
                  <tr>
                    <td>
                      <span>
                        {!isEmpty(data.user) &&
                          data.user.firstname + " " + data.user.lastname}
                      </span>
                    </td>
                    <td>
                      <span>
                        {/* {data.kyc} */}
                        Not Approved
                      </span>
                    </td>
                    <td>
                      <span>
                        {getUIInvestmentStatusValueFunction(data.status)}
                      </span>
                    </td>
                    <td>
                      <span>
                        <DisplayCurrencyFormatString
                          value={data.amount_received}
                        />
                      </span>
                    </td>
                    <td>
                      <span>
                        {!isEmpty(data.created_at) &&
                          format(new Date(data.created_at), "MMM. DD YYYY")}
                      </span>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  return (
    <>
      {!isEmpty(raiseCapitalInvestorsPortfolioReceivedArray) && renderCard()}
      {!isEmpty(raiseCapitalInvestors) && renderTable()}
    </>
  );
}
