import React, { Fragment, useState } from "react";
// import { Link } from "react-router-dom";
import { compareAsc } from "date-fns";

// api
import { useEffect } from "react";
import { getWebsiteAllBlogs } from "../../../web-app-store/actions/websiteBlogsAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";

// import { glossary } from "../header-footer/pageLinks";

import {
  // knowledgeBaseBlogDetail,
  newLoginSignUpLink,
} from "../header-footer/pageLinks";

import search from "../../../assets/imgs/desktop/icon/search-icon.png";
// import glossaryCardImg from "../../..//assets/imgs/desktop/knowledge-base/glossary-card.png";
import KnowledgeBaseCard from "./KnowledgeBaseCard";
import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";

// import img1 from "../../../assets/imgs/desktop/knowledge-base/kb1.png";
// import img2 from "../../../assets/imgs/desktop/knowledge-base/kb2.png";
// import img3 from "../../../assets/imgs/desktop/knowledge-base/kb3.png";
// //import img4 from "../../../assets/imgs/desktop/knowledge-base/kb4.png";
// import img5 from "../../../assets/imgs/desktop/knowledge-base/kb5.png";
// import img6 from "../../../assets/imgs/desktop/knowledge-base/kb6.png";

// const kbData = [
//   {
//     imgPath: img1,
//     name: "Glossary of Terms",
//     desc: "All the investment terms you need to know",
//     textClass: "font-30-montserrat-bold",
//   },
//   {
//     imgPath: img2,
//     name: "Investing for Beginners",
//     desc: "How to Invest in Private Companies",
//     textClass: "font-30-montserrat-bold",
//   },
//   {
//     imgPath: img3,
//     name: "Investing 101",
//     desc: "Starting your journey? This one is for you",
//     textClass: "font-30-montserrat-bold",
//   },
//   //{
//   //  imgPath: img4,
//   //  name: "Investing Tips",
//   //  desc: "Starting your journey? This one is for you",
//   //  textClass: "font-25-montserrat-bold",
//   //},
//   {
//     imgPath: img5,
//     name: "Investing in Startups",
//     //desc: "All the investment terms you need to know",
//     textClass: "",
//   },
//   {
//     imgPath: img6,
//     name: "Angel Investing vs Crowdfunding",
//     //desc: "How to Invest in Private Companies",
//     textClass: "font-22-montserrat-bold",
//   },
// ];
/**
 * KnowledgeBaseFoldTwo Component
 *
 * Overview:
 * The KnowledgeBaseFoldTwo component is responsible for displaying a fold in the knowledge base section of the website. It allows users to search for articles and view them in a card format. The component also includes a "Start Investing" button for users who are on the website.
 *
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage state and side effects.
 * - It uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store.
 * - The component includes a search input field that allows users to search for articles based on their title, body, or description.
 * - The search results are displayed in a card format using the KnowledgeBaseCard component.
 * - The component accepts a prop called "displayOnWebsite" which determines whether the component should be displayed on the website or not.
 *
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - react-redux: The component uses the useDispatch and useSelector hooks to interact with the Redux store.
 * - KnowledgeBaseCard: The component uses the KnowledgeBaseCard component to display search results in a card format.
 */
export default function KnowledgeBaseFoldTwo({ displayOnWebsite }) {
  const [values, setValues] = useState({ search: "" });
  const [isLoading, setIsLoading] = useState(false);

  // api
  const dispatch = useDispatch();

  const websiteAllBlogsOne = useSelector(
    (state) => state.websiteBlogs.websiteAllBlogs
  );

  useEffect(() => {
    if (isEmpty(websiteAllBlogsOne)) {
      const handleFetchData = async () => {
        setIsLoading(true);
        await dispatch(getWebsiteAllBlogs());
        setIsLoading(false);
      };
      handleFetchData();
    }
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const websiteAllBlogs = useSelector(
    (state) => state.websiteBlogs.websiteAllBlogs
  );

  const [searchResult, setSearchResult] = useState(websiteAllBlogs);

  useEffect(() => {
    if (!isEmpty(websiteAllBlogs)) {
      let newLatestBlogs = setLatestBlogsFirst(websiteAllBlogs);
      setSearchResult(newLatestBlogs);
    }
  }, [websiteAllBlogs]);

  /*================================
            handler
  ==============================*/

  const setLatestBlogsFirst = (result) => {
    // descendign order as per updated date
    let newLatestBlogsArray = result.sort((a, b) =>
      compareAsc(new Date(b.updated_at), new Date(a.updated_at))
    );

    // find glossary blog card index
    let glossaryIndex = newLatestBlogsArray.findIndex(
      (a) => a.title === "Glossary of Terms"
    );

    if (glossaryIndex > 0) {
      // remove glossary blog element
      let glossaryElement = newLatestBlogsArray.splice(glossaryIndex, 1)[0];
      // add glossary blog elemet to fisrt place
      newLatestBlogsArray.splice(0, 0, glossaryElement);
    }

    return newLatestBlogsArray;
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: [e.target.value],
    });
    let searchStr = e.target.value.toLowerCase();

    if (!isEmpty(searchStr)) {
      let newLatestBlogs = setLatestBlogsFirst(
        websiteAllBlogs.filter(
          (a) =>
            (!isEmpty(a.article_body) &&
              a.article_body.toLowerCase().match(searchStr)) ||
            (!isEmpty(a.title) && a.title.toLowerCase().match(searchStr)) ||
            (!isEmpty(a.blog_description) &&
              a.blog_description.toLowerCase().match(searchStr))
        )
      );
      setSearchResult(newLatestBlogs);
    } else {
      let newLatestBlogs = setLatestBlogsFirst(websiteAllBlogs);
      setSearchResult(newLatestBlogs);
    }
  };

  const handleSubmit = () => {
    // console.log("values", values);
  };

  /*================================
            main
  ==============================*/
  return (
    <div
      className={
        displayOnWebsite ? "kn-fold2-div" : "kn-fold2-div kn-fold2-div--webApp"
      }
    >
      {isLoading ? (
        <DisplayLoader />
      ) : (
        <React.Fragment>
          {!isEmpty(websiteAllBlogs) && (
            <div className="d-flex mx-0 kn-fold2-div__search-row">
              <div className="kb-input-div">
                <input
                  autoComplete="off"
                  type="text"
                  placeholder="Search the Knowledge Base"
                  name="search"
                  value={values.search}
                  onChange={handleChange}
                />
                <div className="search-btn" onClick={handleSubmit}>
                  {/*<i className="fa fa-search" />*/}
                  <img src={search} alt="search" className="search-img" />
                </div>
              </div>
            </div>
          )}
          <div className="row mx-0 align-items-start kb-card-div">
            {/* <div className="kb-card">
          <Link to={glossary}>
            <div className="kb-card-img-block">
              <img src={glossaryCardImg} alt={"Glossary of Terms"} />
            </div>
          </Link>
          <div className="kb-card-text-div">
            <h5 className="font-25-montserrat-bold">Glossary of Terms</h5>
            <h5 className="font-15-montserrat-regular pt-6">
              All the investment terms you need to know
            </h5>
          </div>
        </div> */}
            {!isEmpty(searchResult) ? (
              searchResult.map((data, index) => (
                <Fragment key={index}>
                  <KnowledgeBaseCard
                    data={data}
                    displayOnWebsite={displayOnWebsite}
                  />
                </Fragment>
              ))
            ) : isLoading ? (
              <DisplayLoader />
            ) : (
              <p className="no-data-found">No Results Found</p>
            )}
          </div>
        </React.Fragment>
      )}
      {displayOnWebsite && (
        <div className="d-flex mx-0 justify-content-center start-btn-div">
          {/* <Link to="/sign-up"> */}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            <div className="start-btn">
              <span className="font-30-montserrat-bold">
                START INVESTING &#62;
              </span>
            </div>
          </a>
          {/* </Link> */}
        </div>
      )}
    </div>
  );
}

KnowledgeBaseFoldTwo.defaultProps = {
  displayOnWebsite: true,
};
