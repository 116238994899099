import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import { getUIAgreementTypeValueFunction } from "../../../common-website-and-app/getUIValueFunction";

/**
 * WebsiteInvestCardsHoverInfo Component
 * 
 * Overview:
 * The WebsiteInvestCardsHoverInfo component is responsible for rendering the hover information for the website investment cards. It displays details about the investment opportunity, such as valuation, target funding goal, investment type, and round type. It also provides a link to view more details about the investment.
 * 
 * Details:
 * - The component receives the offeringDataById prop, which contains the data for the investment opportunity.
 * - The component uses the useSelector hook from the react-redux library to access the auth state from the Redux store.
 * - The renderLinkText function renders the link text for viewing more details.
 * - The renderField function renders a single field with a title and value.
 * - The component conditionally renders a loading spinner if the offeringDataById is empty, otherwise it renders the investment details.
 * - The investment details include the valuation, max target, investment type, and round type.
 * - The component uses the Link component from the react-router-dom library to create a link to the startup details page.
 * 
 * Dependencies:
 * - react
 * - react-redux
 * - react-router-dom
 * - DisplayCurrencyFormatString (custom component)
 * - DisplayLoader (custom component)
 * - isEmpty (custom utility function)
 * - getUIAgreementTypeValueFunction (custom utility function)
 */
export default function WebsiteInvestCardsHoverInfo({
  // orgId,
  offeringDataById,
}) {
  const auth = useSelector((state) => state.auth);

  const renderLinkText = () => {
    return (
      <div
        style={{ marginLeft: 0 }}
        className="explore-card-view-details-div explore-card-view-details-div--home"
      >
        <span className="font-25-montserrat-bold">View details</span>
      </div>
    );
  };

  const renderField = (title, value) => {
    return (
      <div
        className="invest-card__hover-block-text"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
        }}
      >
        <span className="explore-heading-font">{title}</span>
        <span className="explore-subheading-font" style={{ textAlign: "end" }}>
          <DisplayCurrencyFormatString value={value} />
        </span>
      </div>
    );
  };

  return (
    <>
      {/* {console.log(offeringDataById)} */}
      {isEmpty(offeringDataById) ? (
        <div className="w-100 pt-20">
          <DisplayLoader />
        </div>
      ) : (
        <div className="invest-card__hover-block">
          <div>
            {renderField("Valuation:", offeringDataById.valuation_cap)}
            {renderField("Max Target:", offeringDataById.max_funding_goal)}
            {renderField(
              "Investment Type:",
              !isEmpty(offeringDataById)
                ? getUIAgreementTypeValueFunction(
                    offeringDataById.agreement_type
                  )
                : "..."
            )}
            {/* {renderField('Round:', !isEmpty(offeringDataById)
              ? offeringDataById.investment_round
              : "...")} */}

            {renderField(
              "Round Type:",
              !isEmpty(offeringDataById) ? offeringDataById.round_type : "..."
            )}
          </div>
          <div
            className="details-btn"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {auth.isAuthenticated === true ? (
              <Link
                to={{
                  pathname: `/start-up-details/${offeringDataById.id}`,
                }}
              >
                {renderLinkText()}
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/start-up-details-page/${offeringDataById.id}`,
                }}
              >
                {renderLinkText()}
              </Link>
            )}
            {/* ) : (
              <a
                href={newLoginSignUpLink}
                target="_self"
                rel="noopener noreferrer"
              >
                {renderLinkText()}
              </a>
            )} */}
          </div>
        </div>
      )}
    </>
  );
}
