/* eslint-disable array-callback-return */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  MessageSeparator,
  InfoButton,
  Avatar,
} from "@chatscope/chat-ui-kit-react";
import Skeleton from "react-loading-skeleton";
// eslint-disable-next-line no-unused-vars
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { socket } from "../../../../config/web-sockets";
import axios from "axios";
import {
  backendUrl,
  landingpageBackendUrl,
} from "../../../../web-app-store/actions/config";
import isEmpty from "../../../../web-app-store/validations/is-empty";
import AvatarProfile from "../../../../assets/imgs/desktop/common/user-image.png";
import { nanoid } from "nanoid";
import Lottie from "react-lottie-player";
import chatImage from "../../../../web-app-assets/imgs/desktop/backgrounds/chat.json";

/**
 * ChatBox Component
 *
 * Overview:
 * The ChatBox component is responsible for displaying a chat interface between a mentor and a user. It manages the state of the conversation, allows sending and receiving messages, and handles the display of chat menus.
 *
 * Details:
 * - The component receives several props including socketId, selectedChat, mentor, user, myData, manageMentor, setConversation, conversation, chatId, isDataBaseEmpty, setChatId, setDataChatId, dataChatId, setIsChatShow, setChatToggle, chatToggle, isChatShow, conversationLength, setNewMsgReceived, newMsgReceived, clientSocketId, setSelectedChat, setNewMsgToggle, newMsgToggle, chatMenu, setChatMenu, setUserChatMenu, and userChatMenu.
 * - The component uses the useState and useEffect hooks to manage state and handle side effects.
 * - The component makes HTTP requests using the axios library to fetch chat information and update chat menus.
 * - The component uses the socket.io library to send and receive messages in real-time.
 *
 * Dependencies:
 * - React (useState, useEffect)
 * - axios
 * - socket.io
 */
const ChatBox = ({
  socketId,
  selectedChat,
  mentor,
  user,
  myData,
  manageMentor,
  setConversation,
  conversation,
  chatId,
  isDataBaseEmpty,
  setChatId,
  setDataChatId,
  dataChatId,
  setIsChatShow,
  setChatToggle,
  chatToggle,
  isChatShow,
  conversationLength,
  setNewMsgReceived,
  newMsgReceived,
  clientSocketId,
  setSelectedChat,
  setNewMsgToggle,
  newMsgToggle,
  chatMenu,
  setChatMenu,
  setUserChatMenu,
  userChatMenu,
}) => {
  const [message, setMessage] = useState("");
  const [chatInfoObj, setChatInfoObj] = useState("");
  const [chatInfoId, setChatInfoId] = useState("");
  const [myChatInfo, setMyChatInfo] = useState("");
  const [newMsgObj, setNewMsgObj] = useState();
  const uniqueMsgId = nanoid();
  const uniqueChatId = nanoid();
  const uniqueMenuId = nanoid();

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mentor) {
      selectedChat?.ChatId &&
        localStorage.setItem("chatId", selectedChat?.ChatId);
      setChatId(selectedChat?.ChatId);
    }
  }, [selectedChat]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const { data } = await axios.get(`${backendUrl}/chat-info/${chatInfoId}`);
      if (!isEmpty(manageMentor?.chat_infoId) && isEmpty(user)) {
        const { data: menuData } = await axios.get(
          `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`
        );
        setChatMenu(menuData?.chat_menu);
      }
      const { data: userMenuData } = await axios.get(
        `${backendUrl}/chat-menu/${myData?.chat_infoId}`
      );
      setChatInfoId(data[0]?.id);
      user && setUserChatMenu(userMenuData?.chat_menu);
      setChatInfoObj(data[0]?.chat_info);
      let ChatInfo;
      if (mentor) {
        ChatInfo = data[0]?.chat_info?.find(
          (obj) =>
            (obj.user1 === selectedChat?.username &&
              obj.user2 === myData?.username) ||
            (obj.user2 === selectedChat?.username &&
              obj.user1 === myData?.username)
        );
      } else {
        ChatInfo = data[0]?.chat_info?.find(
          (obj) =>
            (obj?.user1 === manageMentor?.user?.username &&
              obj?.user2 === myData?.username) ||
            (obj?.user2 === manageMentor?.user?.username &&
              obj?.user1 === myData?.username)
        );
      }
      setMyChatInfo(ChatInfo);
      ChatInfo?.userMessageId && setDataChatId(ChatInfo?.userMessageId);
      ChatInfo?.chatId && localStorage.setItem("chatId", ChatInfo?.chatId);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    socket.on("getMessage", (message, messageId, username, receivingChatId) => {
      const chatId = localStorage.getItem("chatId");
      if (receivingChatId === chatId && chatId && receivingChatId) {
        setConversation((prevState) => [
          ...prevState,
          {
            message: message,
            username,
            messageId,
          },
        ]);
      } else {
        chatMenu?.map((obj) => {
          if (obj.username === username) {
            obj.username = obj.username;
            obj.lastMessage = true;
            obj.uid = obj.uid;
            obj.ClientSocketId = obj.ClientSocketId;
            obj.profilePhoto = obj.profilePhoto;
            obj.Name = obj.Name;
            obj.MessageDataBaseId = obj.MessageDataBaseId;
            obj.profilePhoto = obj.profilePhoto;
          }
        });
        userChatMenu?.map((obj) => {
          if (obj?.username === username) {
            obj.username = obj.username;
            obj.lastMessage = true;
            obj.uid = obj.uid;
            obj.ClientSocketId = obj.ClientSocketId;
            obj.profilePhoto = obj.profilePhoto;
            obj.Name = obj.Name;
            obj.MessageDataBaseId = obj.MessageDataBaseId;
            obj.profilePhoto = obj.profilePhoto;
          }
        });
        setNewMsgObj({ username, status: true });
        setNewMsgToggle({ ...newMsgToggle });
      }
    });
  }, []);

  useEffect(() => {
    setNewMsgReceived([...newMsgReceived, newMsgObj]);
  }, [newMsgObj]);

  const handleSend = async () => {
    let userMessageId;
    if (isDataBaseEmpty) {
      let messages = {
        messages: [
          { message, username: myData.username, messageId: uniqueMsgId },
        ],
      };

      const { data } = await axios.post(`${backendUrl}/message-data`, messages);

      if (isEmpty(chatMenu)) {
        let chatMenuData = {
          chat_menu: [
            {
              uid: uniqueMenuId,
              Name: myData?.firstname + " " + myData?.lastname,
              email: myData?.email,
              ChatId: uniqueChatId,
              username: myData?.username,
              lastMessage: null,
              profilePhoto: myData?.user_profile?.url,
              ClientSocketId: myData.socketId,
              MessageDataBaseId: data?.id,
            },
          ],
        };
        await axios.put(
          `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`,
          chatMenuData
        );
      } else {
        let chatMenuDetails = {
          uid: uniqueMenuId,
          Name: myData?.firstname + " " + myData?.lastname,
          email: myData?.email,
          ChatId: uniqueChatId,
          username: myData?.username,
          lastMessage: null,
          profilePhoto: myData?.user_profile?.url,
          ClientSocketId: myData.socketId,
          MessageDataBaseId: data?.id,
        };
        chatMenu.push(chatMenuDetails);
        let chatMenuData = {
          chat_menu: chatMenu,
        };
        await axios.put(
          `${backendUrl}/chat-menu/${manageMentor?.chat_infoId}`,
          chatMenuData
        );
      }
      if (isEmpty(userChatMenu)) {
        let chatUserMenuData = {
          chat_menu: [
            {
              uid: uniqueMenuId,
              Name: manageMentor?.first_name + " " + manageMentor?.last_name,
              email: manageMentor?.email,
              ChatId: uniqueChatId,
              username: manageMentor?.user?.username,
              lastMessage: null,
              profilePhoto: manageMentor?.profile_image?.url,
              ClientSocketId: manageMentor.socketId,
              MessageDataBaseId: data?.id,
            },
          ],
        };

        await axios.put(
          `${backendUrl}/chat-menu/${myData?.chat_infoId}`,
          chatUserMenuData
        );
      } else {
        let chatUserMenuData = {
          uid: uniqueMenuId,
          Name: manageMentor?.first_name + " " + manageMentor?.last_name,
          email: manageMentor?.email,
          ChatId: uniqueChatId,
          username: manageMentor?.user?.username,
          lastMessage: null,
          profilePhoto: manageMentor?.profile_image?.url,
          ClientSocketId: manageMentor.socketId,
          MessageDataBaseId: data?.id,
        };
        userChatMenu.push(chatUserMenuData);
        let UserMenuData = {
          chat_menu: userChatMenu,
        };
        await axios.put(
          `${backendUrl}/chat-menu/${myData?.chat_infoId}`,
          UserMenuData
        );
      }

      userMessageId = data?.id;
      setDataChatId(data?.id);
    } else {
      const newMessage = {
        message,
        username: myData.username,
        messageId: uniqueMsgId,
      };
      conversation.push(newMessage);
      const messageDetails = {
        messages: conversation,
      };

      await axios.put(
        `${backendUrl}/message-data/${dataChatId}`,
        messageDetails
      );
    }

    try {
      const res = await axios.post(
        `${landingpageBackendUrl}/api/v1/new_message`,
        {
          first_name: selectedChat?.Name,
          email: selectedChat?.email,
          message: message,
        }
      );
      console.log("RES>>", res);
    } catch (e) {
      console.log(e);
    }
    if (isEmpty(conversation)) {
      if (isEmpty(chatInfoObj)) {
        let chatInfo = {
          chat_info: [
            {
              user1: myData.username,
              user2: manageMentor.user.username,
              chatId: uniqueChatId,
              userMessageId,
            },
          ],
        };
        localStorage.setItem("chatId", uniqueChatId);
        setChatId(uniqueChatId);
        const { data } = await axios.post(`${backendUrl}/chat-info/`, chatInfo);
      } else if (myChatInfo?.chatId) {
        localStorage.setItem("chatId", myChatInfo.chatId);
        setChatId(myChatInfo?.chatId);
      } else {
        let chatInfo = {
          user1: myData.username,
          user2: manageMentor?.user?.username,
          chatId: uniqueChatId,
          userMessageId,
        };
        chatInfoObj.push(chatInfo);
        const chatDetail = {
          chat_info: chatInfoObj,
        };
        localStorage.setItem("chatId", uniqueChatId);
        setChatId(uniqueChatId);
        await axios.put(`${backendUrl}/chat-info/${chatInfoId}`, chatDetail);
      }
    }

    socket.emit("sendMessage", {
      message,
      clientSocketId,
      messageId: uniqueMsgId,
      username: myData.username,
      chatId,
    });

    if (isEmpty(conversation)) {
      setConversation([
        {
          message: message,
          username: myData.username,
          messageId: uniqueMsgId,
        },
      ]);
    } else {
      setConversation((prevState) => prevState);
    }

    setMessage("");
  };

  const handleToggleValue = () => {
    if (isChatShow) {
      setIsChatShow(false);
      setSelectedChat(null);
    }
    if (chatToggle) {
      setChatToggle(!chatToggle);
    }
  };

  return (
    <MainContainer responsive>
      <ChatContainer>
        <ConversationHeader>
          <ConversationHeader.Back onClick={() => handleToggleValue()} />
          {!isEmpty(selectedChat?.profilePhoto) ||
          !isEmpty(manageMentor?.profile_image?.url) ? (
            <Avatar
              src={
                selectedChat?.profilePhoto
                  ? selectedChat?.profilePhoto
                  : manageMentor?.profile_image?.url
              }
              name={selectedChat?.Name}
            />
          ) : (
            <Avatar className="profile">
              <div className="fl-name text-uppercase">
                <span>
                  {selectedChat?.Name ? (
                    selectedChat?.Name.split(" ")[0].charAt(0) +
                    selectedChat?.Name.split(" ")[1].charAt(0)
                  ) : (
                    <Avatar
                      src={AvatarProfile}
                      name={manageMentor?.first_name}
                    />
                  )}
                </span>
              </div>
            </Avatar>
          )}
          <ConversationHeader.Content
            userName={
              selectedChat?.Name
                ? selectedChat?.Name
                : manageMentor?.first_name + " " + manageMentor?.last_name
            }
          />
          {/* <ConversationHeader.Actions>
            <VoiceCallButton />
            <VideoCallButton />
            <InfoButton />
          </ConversationHeader.Actions> */}
        </ConversationHeader>
        <MessageList>
          <MessageSeparator />
          {conversation?.length > 1 ? (
            conversation?.map((obj, i) => (
              <Message
                key={obj.message + i}
                className={`${
                  obj?.username === myData?.username
                    ? "d-flex justify-content-end right-section"
                    : "d-flex left-section"
                } ${
                  obj.message === ""
                    ? "cs-message__content_with-d-none d-none"
                    : ""
                }`}
                model={{ message: obj.message }}
              />
            ))
          ) : (
            <div className="start-conversation-msg col-12 col-xl-12 col-lg-12 col-md-12 col-sm-9">
              <Lottie
                loop
                animationData={chatImage}
                play
                className="conversation-image"
              />
              <p className="start-messaging-text">
                This conversation is just between you and your mentor.
              </p>
            </div>
          )}
        </MessageList>
        <MessageInput
          placeholder="Enter Message..."
          value={message}
          onChange={(value) => setMessage(value)}
          onSend={handleSend}
          attachButton={false}
        />
      </ChatContainer>
    </MainContainer>
  );
};

export default ChatBox;
