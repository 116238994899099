import React from "react";
import isEmpty from "../../../web-app-store/validations/is-empty";
import profileImage from "../../../assets/imgs/desktop/common/no-user-profile.png";
import profileImage1 from "../../../../src/assets/imgs/desktop/common/profile.png";
import profileImage2 from "../../../../src/assets/imgs/desktop/common/profile1.png";

/**
 * UploadImage Component
 * 
 * Overview:
 * The UploadImage component is responsible for displaying an image upload functionality. It allows users to select an image file and preview it. If no image file is selected, it displays a default image based on the gender prop value.
 * 
 * Details:
 * - The component accepts the following props:
 *   - containerClassName: The CSS class name for the container div.
 *   - buttonName: The text to be displayed on the upload button.
 *   - fileNameValue: The URL of the selected image file.
 *   - acceptType: The accepted file types for the file input.
 *   - onChange: The event handler function for the file input change event.
 *   - error: The error message to be displayed.
 *   - isDisabled: A boolean value indicating whether the component is disabled or not.
 *   - gender: The gender value used to determine the default image to be displayed.
 * - The component renders a container div with the specified containerClassName.
 * - Inside the container div, it renders an image block div that displays the selected image file or the default image.
 * - It also renders a hidden file input element and a label element that acts as the upload button.
 * - If the component is not disabled, it renders the upload button and the file input element.
 * - If there is an error, it renders an error message.
 * 
 * Dependencies:
 * The UploadImage component does not have any external dependencies.
 */
function UploadImage({
  containerClassName,
  buttonName,
  fileNameValue,
  acceptType,
  onChange,
  error,
  isDisabled,
  gender,
}) {
  return (
    <>
      {/* display logo */}
      <div className={containerClassName}>
        <div className="upload-img__img-block">
          {!isEmpty(fileNameValue) ? (
            <img src={fileNameValue} alt="" />
          ) : (
            <img
              src={
                gender?.value?.toLowerCase() == "female"
                  ? profileImage2
                  : profileImage1
              }
              alt=""
            />
          )}
        </div>
        <label
          htmlFor="uploadFileInputName"
          className="upload-img__btn-input-block opacity-0"
        >
          <span className="upload-img__btn-input-block-text">{buttonName}</span>
        </label>
        {!isDisabled && (
          <>
            <label
              htmlFor="uploadFileInputName"
              className="upload-img__btn-input-block"
            >
              <span className="upload-img__btn-input-block-text">
                {buttonName}
              </span>
              <input
                name="uploadFileInputName"
                type="file"
                accept={acceptType}
                title=""
                onChange={onChange}
              />
            </label>
            {error ? (
              <p className="error-message">{error}</p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </>
        )}
      </div>
    </>
  );
}

UploadImage.defaultProps = {
  error: "",
  isDisabled: false,
};

export default UploadImage;
