import React, { useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import UploadImage from "../common/UploadImage";
import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";

import TextareaField from "../common/TextareaField";
import Tags from "../common/Tags";

// api
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { getRaiseCapitalTags } from "../../../web-app-store/actions/raiseCapitalAction";
import InputFieldPhoneCountryNumber from "../common/InputFieldPhoneCountryNumber";
import InputFieldNumber from "../common/InputFieldNumber";

// import { socialOptions } from "../common/allDropdownOptions";

// const industryOptionArray = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

/**
 * RaiseCapitalBasicDisplay Component
 *
 * Overview:
 * The RaiseCapitalBasicDisplay component is responsible for displaying the basic information of a company for the "Raise Capital" feature. It renders a form with input fields and dropdowns to show and edit the company's details such as name, email, address, website, industry, and social impact area.
 *
 * Details:
 * - The component uses React hooks such as useState, useEffect, and useDispatch to manage state and perform side effects.
 * - It utilizes Redux to access the raiseCapitalOrg and raiseCapitalTags data from the store.
 * - The component includes sub-components such as InputFieldEmailTextPassword, InputFieldPhoneCountryNumber, UploadImage, InputFieldNumber, TextareaField, Tags, and Select to render the form elements.
 * - The component fetches data from the API using the dispatch function and updates the state accordingly.
 * - The renderMarketTags function renders the Tags component with the market tags data.
 *
 * Usage:
 * To use the RaiseCapitalBasicDisplay component, import it into the desired file and render it within the parent component. Make sure to provide the necessary props if required.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - React-Redux: The component uses React-Redux to connect with the Redux store.
 * - React-Flags-Select: The component uses React-Flags-Select to render the country select dropdown.
 * - Other custom components: The component uses various custom components such as InputFieldEmailTextPassword, InputFieldPhoneCountryNumber, UploadImage, InputFieldNumber, TextareaField, Tags, and Select.
 */
export default function RaiseCapitalBasicDisplay() {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    company: "",
    primaryEmail: "",
    altEmail: "",
    phone: "1",
    fileName: "",
    fileData: {},

    basicAdress1: "",
    basicAdress2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",

    website: "",
    industry: [],
    social: [],
    marketTags: "",

    companyInfo: "",
    facebook: "",
    linkedIn: "",
    instagram: "",
    twitter: "",
  });

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      let data = raiseCapitalOrg[0];
      // set default dropdown option
      setValues({
        ...values,
        company: data.company_name,
        primaryEmail: data.primary_email,
        altEmail: data.alternate_email,
        phone: data.phone_number,
        fileName: isEmpty(data.company_logo) ? null : data.company_logo.url,
        fileData: data.company_logo,

        basicAdress1: data.business_address_line1,
        basicAdress2: data.business_address_line2,
        city: data.city,
        state: data.state,
        country: data.country,
        zipCode: data.zip,

        website: data.website,
        industry: {
          value: data.industry,
          label: data.industry,
        },
        social: {
          value: data.social_impact_area,
          label: data.social_impact_area,
        },

        companyInfo: data.company_details,
        facebook: data.facebook_link,
        linkedIn: data.linkedin_link,
        instagram: data.instagram_link,
        twitter: data.twitter_link,
      });

      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const [tagsArray, setTagsArray] = useState([]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  // api
  const raiseCapitalTags = useSelector(
    (state) => state.raiseCapital.raiseCapitalTags
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalTags)) {
      let data = raiseCapitalTags[0];
      // let newTagsArray=[...tagsArray];
      setTagsArray(JSON.parse(data.market_tags));
    }
  }, [raiseCapitalTags]);

  // renderMarketTags
  const renderMarketTags = () => {
    return (
      <>
        <Tags name="tagsInputValue" tagsArray={tagsArray} isDisabled={true} />
      </>
    );
  };

  /*==========================================
                render
 ==========================================*/
  return (
    <div className="raise-capital-basic-div">
      <div className="row mx-0 justify-content-between align-items-center">
        <div className="col-md-6">
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Company Name"
            name="company"
            value={values.company}
            isDisabled={true}
            type="text"
          />

          <p className="raise-capital-form-basic-tab-text">
            Communication Methods
          </p>

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Primary Email"
            name="primaryEmail"
            value={values.primaryEmail}
            isDisabled={true}
            type="email"
          />

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Alternative Email"
            name="altEmail"
            value={values.altEmail}
            isDisabled={true}
            type="email"
          />

          <InputFieldPhoneCountryNumber
            containerClassName="vl-form-input input-country-phone-number-container--disabled"
            label="Phone Number"
            name="phone"
            phoneInput={values.phone}
            isDisabled={true}
          />
        </div>
        <div className="col-md-6">
          <UploadImage
            containerClassName="upload-img__mainBlock"
            buttonName="Upload Company Logo"
            fileNameValue={values.fileName}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="vl-form-input--raise-capital col-md-6 col-sm-12 px-0">
          <p className="raise-capital-form-basic-tab-text">Corporate Address</p>

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Business Address L1"
            name="basicAdress1"
            value={values.basicAdress1}
            isDisabled={true}
            type="text"
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Business Address L2"
            name="basicAdress2"
            value={values.basicAdress2}
            isDisabled={true}
            type="text"
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="City"
            name="city"
            value={values.city}
            isDisabled={true}
            type="text"
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="State"
            name="state"
            value={values.state}
            isDisabled={true}
            type="text"
          />
          {/* <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Country"
            name="country"
            value={values.country}
            isDisabled={true}
            type="text"
          /> */}
          <div className="vl-form-input vl-form-input--bordered-country-flag vl-form-input--bordered-country-flag--disabled">
            <label htmlFor="">Country</label>
            <ReactFlagsSelect
              selected={values.country}
              // onSelect={(code) => setSelectedCountry(code)}
              // searchable={true}
              disabled={true}
            />
          </div>

          <InputFieldNumber
            containerClassName="vl-form-input"
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            isDisabled={true}
          />
        </div>
        <div className="vl-form-input--raise-capital-select col-md-6 col-sm px-0">
          <p className="raise-capital-form-basic-tab-text">Other Information</p>
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Website"
            name="website"
            value={values.website}
            isDisabled={true}
            type="text"
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Facebook"
            name="facebook"
            value={values.facebook}
            type="text"
            isDisabled={true}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="LinkedIn"
            name="linkedIn"
            value={values.linkedIn}
            type="text"
            isDisabled={true}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Twitter"
            name="twitter"
            value={values.twitter}
            type="text"
            isDisabled={true}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Instagram"
            name="instagram"
            value={values.instagram}
            type="text"
            isDisabled={true}
          />
          <div className="vl-form-input vl-form-input--bordered-select-div">
            <label htmlFor="">Industry</label>
            <Select
              value={values.industry}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={"Select Industry"}
              isDisabled={true}
            />
          </div>

          <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Social Impact Area</label>
            <Select
              value={values.social}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.social}
              isDisabled={true}
            />
          </div>

          <div className="pt-50">{renderMarketTags()}</div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-md-12 px-0">
          <TextareaField
            containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital basic_raise_capital_textarea"
            label="Tell Us About Your Company"
            labelClassName="raise-capital-label-company-info"
            name="companyInfo"
            value={values.companyInfo}
            isDisabled={true}
          />
        </div>
      </div>
    </div>
  );
}
