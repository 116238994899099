// import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateInvestorPreferences Function
 *
 * Overview:
 * This function is responsible for validating the investor preferences form. It checks if the selected industry areas and social impact areas are empty and returns any validation errors.
 *
 * Details:
 * - The function takes two parameters: valuesIndustry and socialValues, which represent the selected industry areas and social impact areas respectively.
 * - It initializes an empty object called errors to store any validation errors.
 * - It checks if the valuesIndustry.industryAreaCategory1 is empty. If it is, it adds an error message to the errors object.
 * - It checks if the socialValues.socialImpactCategory1 is empty. If it is, it adds an error message to the errors object.
 * - Finally, it returns an object containing the errors and a boolean value indicating if the form is valid (isEmpty(errors)).
 *
 * Dependencies:
 * This function does not have any external dependencies.
 */
export const validateInvestorPreferences = (valuesIndustry, socialValues) => {
  let errors = {};

  // console.log(data);

  if (isEmpty(valuesIndustry.industryAreaCategory1)) {
    errors.industryAreaCategory1 =
      "Please seletct at least one category of industry areas";
  }

  if (isEmpty(socialValues.socialImpactCategory1)) {
    errors.socialImpactCategory1 =
      "Please seletct at least one category of social impact areas";
  }

  if (isEmpty(socialValues.revenue_stage)) {
    errors.revenue_stage = "Revenue stage is required";
  }

  if (isEmpty(socialValues.companyInvestmentStage)) {
    errors.companyInvestmentStage = "Company investment stage is required";
  }

  //   if (!isEmpty(data.altEmail) && !Validator.isEmail(data.altEmail)) {
  //     errors.altEmail = "Please enter valid email";
  //   }

  //   if (isEmpty(data.phone)) {
  //     errors.phone = "Phone number is required";
  //   }

  //   if (isEmpty(data.website)) {
  //     errors.website = "Website is required";
  //   }

  //   if (isEmpty(data.basicAdress1)) {
  //     errors.basicAdress1 = "Business Address L1 is required";
  //   }

  //   // if (isEmpty(data.basicAdress2)) {
  //   //   errors.basicAdress2 = "Business Address L2 is required";
  //   // }

  //   if (isEmpty(data.city)) {
  //     errors.city = "City is required";
  //   }

  //   if (isEmpty(data.state)) {
  //     errors.state = "State is required";
  //   }

  //   // if (isEmpty(data.country)) {
  //   //   errors.country = "Country is required";
  //   // }

  //   if (isEmpty(data.zipCode)) {
  //     errors.zipCode = "Zip code is required";
  //   }

  //   // if (isEmpty(data.fileName)) {
  //   //   errors.fileName = "Company logo is required";
  //   // }

  //   if (isEmpty(data.imgUrl)) {
  //     errors.imgUrl = "Company logo is required";
  //   }

  //   if (isEmpty(data.companyInfo)) {
  //     errors.companyInfo = "Company information is required";
  //   }

  //   if (isEmpty(data.tagsArray)) {
  //     errors.tagsArray = "At least one tag is required";
  //   }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
