import React, { Fragment, useState } from "react";
import InvestAfterProfileSetupPortfolioChart from "./InvestAfterProfileSetupPortfolioChart";
import InvestCard from "./InvestCard";
import { chartColors } from "./bgColor";

// api
import { useEffect } from "react";
import { getInvestorPortfolioList } from "../../../web-app-store/actions/investorPortfolioAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../common/DisplayLoader";
// import { compareAsc } from "date-fns";

// const dummyData = [1, 2, 3];

/**
 * InvestAfterProfileSetupPortfolio Component
 * 
 * Overview:
 * The InvestAfterProfileSetupPortfolio component is responsible for displaying the portfolio information of an investor after profile setup. It fetches the investor's portfolio data from the API and renders a chart and a list of investment cards based on the received data.
 * 
 * Details:
 * - The component uses Redux to manage state and dispatch actions to fetch the investor's portfolio data.
 * - It uses the useSelector hook to retrieve the investorPortfolio state from the Redux store.
 * - The component uses the useState hook to manage the labelsDataArr, backgroundColorArr, amountDataArr, and investorPortfolioReceivedArray states.
 * - The useEffect hook is used to dispatch the getInvestorPortfolioList action when the component mounts and to update the state based on changes in the investorPortfolio state.
 * - The component calculates the pending and invested amounts from the investorPortfolio data and updates the amountDataArr state accordingly.
 * - It also sets the labelsDataArr and backgroundColorArr states based on the investorPortfolioReceivedArray state.
 * - The component conditionally renders the InvestAfterProfileSetupPortfolioChart component and the InvestCard components based on the availability of data.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library to dispatch actions.
 * - useSelector: The component uses the useSelector hook from the react-redux library to access the Redux store.
 * - getInvestorPortfolioList: The component depends on the getInvestorPortfolioList action to fetch the investor's portfolio data.
 * - InvestAfterProfileSetupPortfolioChart: The component renders the InvestAfterProfileSetupPortfolioChart component to display the portfolio chart.
 * - InvestCard: The component renders the InvestCard component to display the investment cards.
 */
export default function InvestAfterProfileSetupPortfolio() {
  // api
  const dispatch = useDispatch();

  const investorPortfolio = useSelector(
    (state) => state.invest.investorPortfolio
  );

  const [labelsDataArr, setLabelsDataArr] = useState();

  const [backgroundColorArr, setBackgroundColorArr] = useState();

  useEffect(() => {
    dispatch(getInvestorPortfolioList());
  }, [dispatch]);

  const [amountDataArr, setAmountDataArr] = useState();
  const [
    investorPortfolioReceivedArray,
    setInvestorPortfolioReceivedArray,
  ] = useState();
  useEffect(() => {
    if (!isEmpty(investorPortfolio)) {
      let statusPendingArray = investorPortfolio.filter(
        (a) => a.status === "Pending"
      );

      let ammountDataArrayNew = [];

      let statusInvestedArray = investorPortfolio.filter(
        (a) => a.status === "Invested"
      );

      let pendingAndInvestedArray = [];

      let pendingAmountAddition = 0;
      if (!isEmpty(statusPendingArray)) {
        pendingAndInvestedArray.push(statusPendingArray[0]);

        statusPendingArray.map(
          (data, index) =>
            (pendingAmountAddition =
              pendingAmountAddition + data.amount_received)
        );
      } else {
        pendingAndInvestedArray.push(0);
      }

      let investedAmountAddition = 0;
      if (!isEmpty(statusInvestedArray)) {
        pendingAndInvestedArray.push(statusInvestedArray[0]);

        statusInvestedArray.map(
          (data, index) =>
            (investedAmountAddition =
              investedAmountAddition + data.amount_received)
        );
      } else {
        pendingAndInvestedArray.push(0);
      }

      pendingAndInvestedArray.map((data, index) =>
        index === 0
          ? data !== 0 && (ammountDataArrayNew[0] = pendingAmountAddition)
          : index === 1 && pendingAndInvestedArray[0] === 0
          ? (ammountDataArrayNew[0] = investedAmountAddition)
          : index === 1 &&
            data !== 0 &&
            (ammountDataArrayNew[1] = investedAmountAddition)
      );

      setAmountDataArr(ammountDataArrayNew);

      setInvestorPortfolioReceivedArray(pendingAndInvestedArray);

      // let sortedArray = investorPortfolio.sort((a, b) =>
      //   compareAsc(new Date(b.updated_at), new Date(a.updated_at))
      // );
      // setInvestorPortfolioReceivedArray(sortedArray);
    }
  }, [investorPortfolio]);

  useEffect(() => {
    if (!isEmpty(investorPortfolioReceivedArray)) {
      // set amount data
      // let newData = [];
      // investorPortfolioReceivedArray.map((data, index) =>
      //   newData.push(data.amount_received)
      // );
      // setAmountDataArr(newData);

      // set label data
      let newDataLabel = [];
      investorPortfolioReceivedArray.map((data, index) =>
        data !== 0 && data.status === "Pending"
          ? newDataLabel.push("Pending Settlement")
          : data !== 0 &&
            data.status === "Invested" &&
            newDataLabel.push("Invested")
      );
      setLabelsDataArr(newDataLabel);

      // set colors
      let newDataColors = [];
      // investorPortfolioReceivedArray.map((data, index) =>
      //   newDataColors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`)
      // );

      investorPortfolioReceivedArray.map((data, index) =>
        index === 0
          ? data !== 0 && (newDataColors[0] = chartColors[0])
          : index === 1 && investorPortfolioReceivedArray[0] === 0
          ? (newDataColors[0] = chartColors[1])
          : index === 1 && data !== 0 && (newDataColors[1] = chartColors[1])
      );
      setBackgroundColorArr(newDataColors);
    }
  }, [investorPortfolioReceivedArray]);

  return (
    <>
      {!isEmpty(investorPortfolioReceivedArray) &&
        !isEmpty(backgroundColorArr) &&
        !isEmpty(labelsDataArr) &&
        !isEmpty(amountDataArr) && (
          <div className="invest-after-profile-setup-portfolio-div">
            <div className="invest-after-profile-setup-portfolio-div-row-2-col2">
              {!isEmpty(amountDataArr) && (
                <InvestAfterProfileSetupPortfolioChart
                  labelsData={labelsDataArr}
                  amountData={amountDataArr}
                  backgroundColorArr={backgroundColorArr}
                />
              )}
            </div>

            <div className="vl-invest-after-setup-portfolio-invest-card-row">
              {investorPortfolioReceivedArray.map((data, index) => (
                <Fragment key={index}>
                  <>
                    {data !== 0 && (
                      <>
                        {/* {console.log(data)} */}
                        <InvestCard
                          offeringId={data.offering_id}
                          status={data.status}
                          amountArray={
                            index === 0
                              ? data !== 0 && amountDataArr[0]
                              : index === 1 &&
                                investorPortfolioReceivedArray[0] === 0
                              ? amountDataArr[0]
                              : index === 1 && data !== 0 && amountDataArr[1]
                          }
                          backgroundColor={
                            index === 0
                              ? data !== 0 && chartColors[0]
                              : index === 1 &&
                                investorPortfolioReceivedArray[0] === 0
                              ? chartColors[1]
                              : index === 1 && data !== 0 && chartColors[1]
                          }
                        />
                      </>
                    )}
                  </>
                </Fragment>
              ))}
            </div>
          </div>
        )}
    </>
  );
}
