import React, { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Footer from "../header-footer/Footer";
import HomeFoldOne from "./HomeFoldOne";
import HomeFoldThree from "./HomeFoldThree";
import HomeFoldTwo from "./HomeFoldTwo";
import LightPaper from "./LightPaper";
import NewHomeFoldOne from "./NewHomeFoldOne";
import NewHomeFoldThree from "./NewHomeFoldThree";
import NewHomeFoldTwo from "./NewHomeFoldTwo";
import NewNavbar from "./NewNavbar";

/**
 * Home Component
 * 
 * Overview:
 * The Home component is responsible for rendering the home page of the application. It includes various sub-components that represent different sections of the home page.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component renders a header section, followed by three different fold sections.
 * - The component accepts no props.
 * 
 * Dependencies:
 * - NewNavbar: A sub-component that renders the header section.
 * - NewHomeFoldOne: A sub-component that renders the first fold section.
 * - NewHomeFoldTwo: A sub-component that renders the second fold section.
 * - NewHomeFoldThree: A sub-component that renders the third fold section.
 * - Footer: A sub-component that renders the footer section.
 */
export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <div class="wrapper"> */}
      {/* <div className="scrollbar scrollbar-primary">
        <div className="force-overflow"> */}
      <div className="home-bg-color">
        <div className="header-s">
          {/* <LightPaper /> */}
          <NewNavbar />
        </div>
        <div>
          <NewHomeFoldOne />
          <NewHomeFoldTwo />
          <NewHomeFoldThree />
          {/* <HomeFoldOne /> */}
          {/* <HomeFoldTwo /> */}
          {/* <HomeFoldThree /> */}
        </div>
      </div>
      <Footer />
      {/* </div>
      </div> */}
      {/* </div> */}
    </>
  );
}
