import React from "react";

/**
 * InputFieldEmailTextPassword Component
 *
 * Overview:
 * The InputFieldEmailTextPassword component is a reusable component that renders an input field for email, text, or password input. It provides functionality for handling user input, displaying errors, and managing the input field's state.
 *
 * Details:
 * - The component accepts various props to customize its behavior and appearance, such as containerClassName, label, type, id, name, placeholder, onChange, onKeyDown, value, maxLength, autoFocus, error, isDisabled, isReadOnly, and isHideLabel.
 * - The component renders a div container with an optional label, an input field, and an error message.
 * - The label is hidden if the isHideLabel prop is set to true.
 * - The input field's type, id, name, placeholder, onChange, onKeyDown, value, maxLength, autoComplete, autoFocus, disabled, and readOnly attributes are set based on the provided props.
 * - The error message is displayed if the error prop is truthy.
 *
 * Dependencies:
 * - React: The component is built using React and requires it to be installed in the project.
 * - CSS: The component relies on CSS classes for styling, which should be defined in the project's CSS files.
 */
function InputFieldEmailTextPassword({
  containerClassName,
  label,
  type,
  id,
  name,
  placeholder,
  onChange,
  onKeyDown,
  value,
  maxLength,
  autoFocus,
  error,
  isDisabled,
  isReadOnly,
  isHideLabel,
  isError,
}) {
  return (
    <div className={containerClassName}>
      <div className={isHideLabel ? "d-none" : ""}>
        <label htmlFor={id ? id : name}>{label}</label>
      </div>
      <div
        className={
          isDisabled || isReadOnly
            ? "input-border-div input-border-div--disabled"
            : "input-border-div"
        }
      >
        <input
          type={type}
          id={id ? id : name}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value === null ? "" : value}
          maxLength={maxLength}
          autoComplete="off"
          autoFocus={autoFocus}
          disabled={isDisabled}
          readOnly={isReadOnly}
        />
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className={`error-message opacity-0 ${isError ? "d-none" : ""}`}>
          error
        </p>
      )}
    </div>
  );
}

InputFieldEmailTextPassword.defaultProps = {
  label: "",
  placeholder: "",
  maxLength: "",
  error: "",
  autoFocus: "",
  isDisabled: false,
  isReadOnly: false,
  isHideLabel: false,
};

export default InputFieldEmailTextPassword;
