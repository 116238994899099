import React from "react";
import WebsiteInvestCards from "./WebsiteInvestCards";
import doteImage from "../../../assets/imgs/desktop/background/dote-image.svg";

/**
 * NewHomeFoldThree Component
 * 
 * Overview:
 * The NewHomeFoldThree component represents a section of the home page that displays information about how the website works. It includes three cards that explain the steps involved in using the website. This component is part of the larger UI-2 application.
 * 
 * Details:
 * - The component renders a container div with the class "home-fold-3-div maincontainer-custom".
 * - Inside the container, it renders the WebsiteInvestCards component, which displays investment cards based on the displayOnPage prop.
 * - Below the container, there is another div with the class "container-fluid text-dark section-background-color py-5 icee-background".
 * - Inside this div, there is a "HOW IT WORKS" title and a row of three cards.
 * - Each card has a number, a title, and a description.
 * - The component uses SVGs and lines to create a visual effect.
 * 
 * Dependencies:
 * - React: The component uses React to render the UI elements.
 * - WebsiteInvestCards: The component depends on the WebsiteInvestCards component to display investment cards.
 * - AOS: The component uses the AOS library for scroll animations.
 */
const NewHomeFoldThree = () => {
  return (
    <>
      <div className="home-fold-3-div maincontainer-custom">
        <WebsiteInvestCards displayOnPage="home" />
      </div>
      <div
        data-aos="zoom-out"
        data-aos-offset="300"
        data-aos-easing="ease-in-out"
        data-aos-duration="1500"
        data-aos-once="true"
      >
        <div className="container-fluid text-dark section-background-color py-5 icee-background">
          <div className="browes-main">
            <h2 className="browes-title">HOW IT WORKS</h2>
            <div
              className="row"
              // data-aos="fade-right"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-out"
              // data-aos-duration="1500"
            >
              <div className="col-lg-4 col-md-6 col-sm-12 my-3 px-4">
                <div className="card-main">
                  <div className="d-flex align-items-center">
                    <p className="card-number">01</p>
                    <svg
                      className="d-none d-md-block d-lg-block d-xl-block"
                      width="100%"
                      height="2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        y1="0.5"
                        x2="100%"
                        y2="0.5"
                        stroke="#ffffff"
                        stroke-linecap="round"
                        stroke-dasharray="0.5 8"
                      ></line>
                    </svg>
                    {/* <img src={doteImage} /> */}
                  </div>
                  <h3 className="user-sign-up">Sign Up</h3>
                  <h5 className="user-profile">
                    Create a profile tailored to your interests. Once you've
                    signed up, you will gain access to connect with the other
                    members of the community.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 my-3 px-4">
                <div className="card-main">
                  <div className="d-flex align-items-center">
                    <p className="card-number">02</p>
                    <svg
                      className="d-none d-md-block d-lg-block d-xl-block"
                      width="100%"
                      height="2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        y1="0.5"
                        x2="100%"
                        y2="0.5"
                        stroke="#ffffff"
                        stroke-linecap="round"
                        stroke-dasharray="0.5 8"
                      ></line>
                    </svg>
                    {/* <img src={doteImage} /> */}
                  </div>
                  <h3 className="user-sign-up">Review Offerings</h3>
                  <h5 className="user-profile">
                    Dive deeper into offerings and gain further insights by
                    engaging directly with founders before making an investment.
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 my-3 px-4">
                <div className="card-main">
                  <div className="d-flex align-items-center">
                    <p className="card-number">03</p>
                    <svg
                      className="d-none d-md-block d-lg-block d-xl-block"
                      width="100%"
                      height="2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        x1="0.5"
                        y1="0.5"
                        x2="100%"
                        y2="0.5"
                        stroke="#ffffff"
                        stroke-linecap="round"
                        stroke-dasharray="0.5 8"
                      ></line>
                    </svg>
                  </div>
                  <h3 className="user-sign-up">Invest</h3>
                  <h5 className="user-profile">
                    Build and manage a diversified portfolio of private
                    companies.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="know-more-section w-100 text-center"
          // data-aos="fade-up"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-out"
          // data-aos-duration="1500"
        >
          <h4 className="category-sub">Community. Innovation. Knowledge.</h4>
          <h5 className="know-more-sub text-center m-auto">
            The more we know, the more we can achieve. As the foundational
            belief of our company, we were named after the Vedas - the ancient
            texts that help people uncover the truth and wisdom to maximize
            human potential.
          </h5>
        </div>
      </div>
    </>
  );
};

export default NewHomeFoldThree;
