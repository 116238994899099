import React, {
  useState,
  //  useEffect
} from "react";
import Modal from "react-responsive-modal";
import { Link, useHistory } from "react-router-dom";
import { exploreWebAppLink } from "../../../components/desktop/header-footer/pageLinks";
import darkLogo from "../../../assets/imgs/desktop/common/Vedalabs-logo-purple-silver.png";

// api
import { investSaveProfileSuccessAction } from "../../../web-app-store/actions/investAction";

import { useDispatch } from "react-redux";
import { Button, Image } from "react-bootstrap";

let logo = "/web-app-img/desktop/all-logo/vl-sm-logo.png";

/**
 * ProfileReviewModalInvest Component
 *
 * Overview:
 * The ProfileReviewModalInvest component is responsible for rendering a modal that displays a profile review message to the user. It is typically used after the user completes their investor profile. The component includes an image, a heading, and two buttons for exploring listings and viewing the user's profile.
 *
 * Details:
 * - The component uses the Modal component from a library to render the modal.
 * - The open state is managed using the useState hook.
 * - The handleOpenModal function is responsible for toggling the open state and dispatching an action if the investVal prop is truthy.
 * - The component uses CSS classNames to customize the styling of the modal and its elements.
 *
 * Props:
 * - linkPath: A string representing the path to the user's profile page.
 * - investVal: A boolean indicating whether the user has invested.
 *
 * Dependencies:
 * - React: The component uses React to define and render the component.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library to dispatch actions.
 * - useState: The component uses the useState hook from React to manage the open state.
 * - Modal: The component uses the Modal component from a library to render the modal.
 * - Link: The component uses the Link component from the react-router-dom library to create links.
 * - logo: The component uses the logo image.
 */
export default function ProfileReviewModalInvest({ linkPath, investVal }) {
  // api
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const history = useHistory();

  /*==========================
             handler
 ===========================*/
  const handleOpenModal = (e) => {
    history?.push(exploreWebAppLink);
    if (investVal) {
      dispatch(investSaveProfileSuccessAction(false));
    }
    setOpen(!open);
  };

  return (
    <>
      {/* <Modal
        open={open}
        onClose={handleOpenModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal customModal--profile-review",
          closeButton: "customCloseButton",
        }}
      >
        <div className="profile-review-div">
          <div className="profile-review-img-div">
            <img src={logo} alt="vedslab logo" className="profile-review-img" />
          </div>
          <h2 className="pr-roboto-font-40-medium pr-roboto-font-40-medium--invest">
            You&rsquo;ve completed your <br /> investor profile!
          </h2>
          <div className="row mx-0 justify-content-center">
            <Link to={exploreWebAppLink}>
              <div className="profile-review-btn" onClick={handleOpenModal}>
                <span>Explore Listings</span>
              </div>
            </Link>
          </div>
          <Link to={linkPath}>
            <div onClick={handleOpenModal}>
              <span className="pr-do-it-text">View Profile</span>
            </div>
          </Link>
        </div>
      </Modal> */}
      <div className="invester-review-page-main">
        <Image src={darkLogo} className="review-page-image-logo" />
        <p className="review-page-text">You can now start investing!</p>
        <div className="review-explore-btn-main">
          <Button onClick={handleOpenModal} className="">
            Explore Listings
          </Button>
        </div>
      </div>
    </>
  );
}
