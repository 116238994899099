import React from "react";
import Navbar from "../header-footer/Navbar";
import logo from "../../../assets/imgs/desktop/common/logo.png";

/**
 * KnowledgeBaseFoldOne Component
 * 
 * Overview:
 * The KnowledgeBaseFoldOne component is responsible for rendering the first fold of the Knowledge Base section. It displays the logo, title, and a brief description of the knowledge base.
 * 
 * Details:
 * - The component includes a Navbar component for navigation.
 * - The logo is displayed using an img tag with the source provided by the 'logo' variable.
 * - The title is rendered using an h5 tag with the class 'font-55-montserrat-bold'.
 * - The description is rendered using an h5 tag with the class 'font-25-montserrat-regular'.
 * - The component also includes two additional divs for styling purposes.
 * 
 * Usage:
 * To use the KnowledgeBaseFoldOne component, import it from the specified file and render it within the desired parent component.
 * 
 * Dependencies:
 * - The component depends on the Navbar component for navigation.
 * - The 'logo' variable is expected to be defined and contain the source of the logo image.
 */
export default function KnowledgeBaseFoldOne() {
  return (
    <div className="kn-base-fold-one-bg">
      <Navbar />
      <div className="kb-fold-one-text-div">
        <img src={logo} alt="vedaslabs logo" className="kb-logo" />
        <h5 className="font-55-montserrat-bold kn-title">Knowledge Base</h5>
      </div>
      <div className="d-flex mx-0 justify-content-end kn-fold1-bottom-div">
        <div className="about-fold1-experience-div about-fold1-experience-div--kn">
          <h5 className="font-35-montserrat-bold">Wisdom</h5>
        </div>
        <div className="about-fold1-down-right-div about-fold1-down-right-div-kn">
          <h5 className="font-25-montserrat-regular">
            A suite of tools to learn how to invest in early stage companies
          </h5>
        </div>
      </div>
    </div>
  );
}
