import React from "react";

import img1 from "../../../assets/imgs/desktop/about/about1-new.png";
import img2 from "../../../assets/imgs/desktop/about/about2.png";
import img3 from "../../../assets/imgs/desktop/about/about3.png";
import img4 from "../../../assets/imgs/desktop/about/about4-new.png";
import img6 from "../../../assets/imgs/desktop/about/about6.png";
import img7 from "../../../assets/imgs/desktop/about/about13.jpg";

const teamData = [
  {
    imgPath: img1,
    imgClassName: "our-team-img1",
    name: "Tom Trevisani",
    post: "Co-Founder & CEO",
    marginClass: "mr-93",
    extraclass: "card1-width",
    desc: "Before VedasLabs, Tom was pivotal in driving new business growth at Axioma Inc., a global leader in quantitative risk analytics solutions for some of the world's largest and most sophisticated hedge funds, asset managers, and pension funds.",
  },
  {
    imgPath: img7,
    imgClassName: "our-team-img1",
    name: "Seth Hill",
    post: "Co-Founder & CTO",
    marginClass: "mr-93",
    extraclass: "card1-width",
    desc: "Seth, a passionate data scientist, specializes in blockchain, machine learning, and AI. His development expertise includes Web3, Solidity, React, and Python. He holds degrees in Materials Science & Engineering, and Physics.",
  },
  {
    imgPath: img4,
    imgClassName: "our-team-img4",
    name: "Angelo Framularo",
    extraclass: "card4-width",
    post: "Chief Compliance Officer",
    marginClass: "mr-77",
    desc: "Angelo has held Enterprise Risk roles at Laurel Road, Darien Rowayton Bank, and American Express. Angelo is a licensed attorney in New York and Connecticut.",
  },
  {
    imgPath: img3,
    imgClassName: "our-team-img3",
    extraclass: "card3-width",
    name: "Ryan Finnegan",
    marginClass: "mr-77",
    post: "Interim Chief Financial Officer",
    desc: "Ryan is a licensed attorney with an LLM in tax. He brings to VedasLabs over a decade of legal experience to private and public companies in construction, real estate development, estate planning, and investments.",
  },
  {
    imgPath: img2,
    imgClassName: "our-team-img2",
    extraclass: "card2-width",
    name: "Ali Stewart",
    post: "Product Strategy",
    //marginClass: "mr-59",
    desc: "Alison Stewart has 15+ years of experience within global financial technology companies, which includes senior roles at Factset and Enfusion Fund Analytics.",
  },

  {
    imgPath: img6,
    imgClassName: "our-team-img6",
    extraclass: "card6-width",
    name: "Jaclyn Dong",
    marginClass: "mr-77",
    post: "Operations Lead",
    desc: "Jaclyn has an extensive business and technical background. Before VedasLabs, she was a Sales Analytics Manager at Moody’s Analytics.",
  },
];

/**
 * AboutUsFoldTwo Component
 * 
 * Overview:
 * The AboutUsFoldTwo component displays a list of team members in a card format. It is used to showcase the team members of the organization.
 * 
 * Details:
 * - The component renders a list of team members using the `teamData` array.
 * - Each team member is displayed in a card format, showing their image, name, and post.
 * - The component uses the `renderBlockOne` function to render the team members.
 * - The `renderBlockOne` function maps over the `teamData` array and generates the card for each team member.
 * - The component also includes a commented out `renderBlockTwo` function which can be used to render an additional list of team members.
 * - The component accepts no props.
 * 
 * Usage:
 * To use the AboutUsFoldTwo component, include it in the desired location within your application's JSX code.
 * 
 * Dependencies:
 * - React: The component relies on React to render JSX elements.
 * - `teamData`: The component expects an array of team member data in the `teamData` variable.
 */
export default function AboutUsFoldTwo() {
  const renderBlockOne = () => {
    return (
      <div className="commonlist-main ourteam-list">
        <div className="maincontainer-custom">
          <div className="our-team-right-div">
            <div className="row">
              {teamData.map((data, key) => (
                <div className="col-md-4 col-12">
                  <div key={key} className="about-card mt-5">
                    <div className="about-wrap">
                      {/* <div className={data.marginClass}> */}
                      <div className="about-img-div">
                        <div className="aboutimg-inner">
                          <img
                            src={data.imgPath}
                            alt={data.name}
                            className={data.imgClassName + " img-fluid "}
                          />
                          <div
                            className={`about-hover-card ${data.extraclass}`}
                          >
                            <p>{data.desc}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card-text">
                        <h4>{data.name}</h4>
                        <h5>{data.post}</h5>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderBlockTwo = () => {
  //   return (
  //     <div className="our-teamleft">
  //       <div className="maincontainer-custom">
  //         <div className="our-team-left-div">
  //           <div className="row">
  //             {teamData2.map((data, key) => (
  //               <div className="col-md-4 col-12">
  //                 <div key={key} className="about-card">
  //                   {/* <div className={data.marginClass}> */}
  //                   <div className="about-img-div">
  //                     <div className="aboutimg-inner">
  //                       <img
  //                         src={data.imgPath}
  //                         alt={data.name}
  //                         className={data.imgClassName + " img-fluid "}
  //                       />
  //                       <div className={`about-hover-card ${data.extraclass}`}>
  //                         <p>{data.desc}</p>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="card-text">
  //                     <h4>{data.name}</h4>
  //                     <h5>{data.post}</h5>
  //                   </div>
  //                   {/* </div> */}
  //                 </div>
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="about-us-fold-two">
      <h2>Our Team</h2>
      {renderBlockOne()}
      {/* {renderBlockTwo()} */}
    </div>
  );
}
