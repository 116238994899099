import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_DISCUSSIONS_ALL,
  SET_DISCUSSIONS_ALL_BY_OFFERING_ID,
} from "./../types";

import { compareAsc } from "date-fns";
import Toast from "light-toast";

/*===================================================================================
             create discussion
====================================================================================*/
/**
 * Create Discussion Action
 *
 * Overview:
 * Creates a new discussion thread in the backend by submitting form data. Upon successful creation, it triggers a callback function and possibly refreshes discussion lists relevant to the created discussion.
 *
 * Details:
 * - Sends a POST request to the backend's `/discussions` endpoint with the provided form data.
 * - Optionally refreshes discussions list by offering ID after creation.
 * - Utilizes a callback function to indicate the operation's success status and displays a success message.
 *
 * Usage:
 * This action is dispatched when users submit a form to create a new discussion thread in a community or forum section of the application.
 * Example: `dispatch(createDiscussion(formData, callBackCreateDiscussionStatus))`
 *
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `Toast` for displaying feedback messages.
 * - Redux's dispatch function for state management.
 */
export const createDiscussion =
  (formData, callBackCreateDiscussionStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/discussions`,
        formData
      );
      if (data) {
        // dispatch(getDiscussionsAll());
        dispatch(getDiscussionsAllByOfferingId(formData.offering.id));
        callBackCreateDiscussionStatus(status);
        Toast.info("Discussion created");
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get discussions all 
====================================================================================*/
/**
 * Get Discussions All Action
 *
 * Overview:
 * Fetches all discussions from the backend and dispatches an action to store them in the application's state. This allows for the centralization and easy access of discussion data within the application.
 *
 * Details:
 * - Makes a GET request to the backend's `/discussions` endpoint to retrieve all discussions.
 * - Dispatches an action to store the retrieved discussions in the redux state.
 *
 * Usage:
 * Can be dispatched during application startup or when accessing a community/forum section to ensure the latest discussions are displayed.
 * Example: `dispatch(getDiscussionsAll())`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 * - Redux's dispatch function for state management.
 */
export const getDiscussionsAll = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/discussions`);
    if (data) {
      dispatch({
        type: SET_DISCUSSIONS_ALL,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get discussions all by offering id
====================================================================================*/
/**
 * Get Discussions All By Offering ID Action
 *
 * Overview:
 * Retrieves all discussions associated with a specific offering ID from the backend, facilitating targeted discussion fetches based on context or specific offerings.
 *
 * Details:
 * - Performs a GET request filtering discussions by offering ID.
 * - Dispatches an action with the filtered discussions to update the application state.
 *
 * Usage:
 * Useful in contexts where discussions need to be filtered based on the offering they are associated with, such as product discussions in an e-commerce setting.
 * Example: `dispatch(getDiscussionsAllByOfferingId(offeringId))`
 *
 * Dependencies:
 * The same as `Get Discussions All Action`.
 */
export const getDiscussionsAllByOfferingId =
  (offeringId) => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/discussions?offering=${offeringId}`
      );
      if (data) {
        dispatch({
          type: SET_DISCUSSIONS_ALL_BY_OFFERING_ID,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get discussions by id
====================================================================================*/
/**
 * Get Discussion By ID Action
 *
 * Overview:
 * Fetches a single discussion by its ID and executes a callback function with the fetched data. This action is used to retrieve detailed information about a specific discussion.
 *
 * Details:
 * - Sends a GET request to the backend to retrieve details of a discussion identified by its ID.
 * - The retrieved data is passed to a callback function for further processing or display.
 *
 * Usage:
 * This action is dispatched when detailed information about a specific discussion is required, such as when viewing a full discussion thread.
 * Example: `dispatch(getDiscussionById(discussionId, callBackDiscussionData))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 */
export const getDiscussionById =
  (discussionId, callBackDiscussionData) => async () => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/discussions/${discussionId}`
      );
      if (data) {
        callBackDiscussionData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             create discussion comment
====================================================================================*/
/**
 * Create Discussion Comment Action
 *
 * Overview:
 * Submits a new comment for a discussion to the backend. Upon successful creation, it notifies the user with a message and optionally executes a callback function.
 *
 * Details:
 * - Sends a POST request with the comment form data to the backend's `/comments` endpoint.
 * - Displays a success message and invokes a callback function to handle the successful comment creation.
 *
 * Usage:
 * Dispatched when a user submits a comment on a discussion thread.
 * Example: `dispatch(createDiscussionComment(formData, callBackDiscussionCommentStatus))`
 *
 * Dependencies:
 * The same as `Create Discussion Action`, excluding the Redux dispatch function.
 */
export const createDiscussionComment =
  (formData, callBackDiscussionCommentStatus) => async () => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/comments`,
        formData
      );
      if (data) {
        Toast.info("Replied");
        callBackDiscussionCommentStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get discussions by id
====================================================================================*/
/**
 * Get Discussion Comments By Discussion ID Action
 *
 * Overview:
 * Retrieves all comments for a given discussion ID from the backend, sorts them by creation date, and passes the sorted comments to a callback function.
 *
 * Details:
 * - Makes a GET request to fetch comments associated with a specific discussion ID.
 * - Sorts the comments by their creation date using `compareAsc` from `date-fns`.
 * - Passes the sorted comments to a callback function for further processing or display.
 *
 * Usage:
 * This action is useful for loading and displaying comments in a discussion thread, ensuring the latest comments are shown first.
 * Example: `dispatch(getDiscussionCommentsByDiscussionId(discussionId, callBackDiscussionCommentsData))`
 *
 * Dependencies:
 * - `axios` and `date-fns` for fetching and sorting data.
 */
export const getDiscussionCommentsByDiscussionId =
  (discussionId, callBackDiscussionCommentsData) => async () => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/comments?discussion=${discussionId}`
      );
      console.log(data);
      if (data) {
        let latestDataFirst = data.sort((a, b) =>
          compareAsc(new Date(b.created_at), new Date(a.created_at))
        );
        callBackDiscussionCommentsData(latestDataFirst);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get discussions by id
====================================================================================*/
/**
 * Get Discussion Organization By User ID Action
 *
 * Overview:
 * Attempts to fetch an organization associated with a user ID. If no organization is found, it tries to fetch team details based on the user's email. The result is passed to a callback function.
 *
 * Details:
 * - Initiates a GET request to find an organization by user ID.
 * - If no organization data is found, it makes another GET request to find team details by the user's email.
 * - Uses a callback function to pass either the organization or team details data.
 *
 * Usage:
 * This action is dispatched to fetch organization or team details related to a user, typically for profile or administration purposes.
 * Example: `dispatch(getDiscussionOrganizationByUserId(userId, payload, callBackDiscussionOrganizationByUserId))`
 *
 * Dependencies:
 * - `axios` for HTTP requests.
 */
export const getDiscussionOrganizationByUserId =
  (userId, payload, callBackDiscussionOrganizationByUserId) => async () => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/organizations?user=${userId}`
      );

      if (data.length) {
        callBackDiscussionOrganizationByUserId(data);
      } else {
        const { data } = await axios.get(
          `${backendUrl}/team-details?email=${payload.user.email}`
        );
        callBackDiscussionOrganizationByUserId(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
