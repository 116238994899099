import React from "react";
import Select from "react-select";

/**
 * DropdownMultiSelectCommon Component
 * 
 * Overview:
 * The DropdownMultiSelectCommon component is a reusable component that provides a dropdown multi-select functionality. It is used to select multiple options from a list of options.
 * 
 * Details:
 * - The component renders a div container with a labeled Select component inside it.
 * - The Select component is a third-party library component that handles the dropdown functionality.
 * - The component accepts props such as label, value, onChange, options, placeholder, isSearchable, isHideLabel, and error.
 * - The label prop is used to display a label for the dropdown.
 * - The value prop is used to set the selected value(s) of the dropdown.
 * - The onChange prop is a callback function that is called when the selected value(s) of the dropdown change.
 * - The options prop is an array of options that are displayed in the dropdown.
 * - The placeholder prop is used to display a placeholder text in the dropdown when no option is selected.
 * - The isSearchable prop determines whether the dropdown should have a search functionality.
 * - The isHideLabel prop determines whether the label should be hidden.
 * - The error prop is used to display an error message below the dropdown if there is an error.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Select: The component uses the Select component from a third-party library to handle the dropdown functionality.
 */
function DropdownMultiSelectCommon({
  label,
  value,
  onChange,
  options,
  placeholder,
  isSearchable,
  isHideLabel,
  error,
  mainDiv,
  disabled,
}) {
  return (
    <div
      className={`vl-form-input  vl-form-input--bordered-select-div vl-form-input--bordered-select-div--multiSelect ${
        mainDiv ? mainDiv : ""
      }`}
    >
      {!isHideLabel && <label htmlFor="">{label}</label>}
      <Select
        value={value}
        onChange={onChange}
        options={options}
        className="vl-select-container"
        classNamePrefix="vl-select"
        placeholder={placeholder}
        isSearchable={isSearchable}
        isMulti
        isDisabled={disabled || false}
      />
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className="error-message opacity-0">error</p>
      )}
    </div>
  );
}

DropdownMultiSelectCommon.defaultProps = {
  label: "",
  placeholder: "",
  isSearchable: false,
  isHideLabel: false,
  error: "",
};

export default DropdownMultiSelectCommon;
