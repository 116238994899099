import React, { useEffect } from "react";
import RaiseCapitalFoldOne from "./RaiseCapitalFoldOne";
import RaiseCapitalFoldThree from "./RaiseCapitalFoldThree";
import RaiseCapitalFoldTwo from "./RaiseCapitalFoldTwo";
import Footer from "../header-footer/Footer";

/**
 * RaiseCapital Component
 * 
 * Overview:
 * The RaiseCapital component is responsible for rendering the UI for the "Raise Capital" page. It provides a layout for different sections of the page and includes a footer component.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component renders a div with the class "bg-color bg-color--raise-capital" as the main container.
 * - It includes three sub-components: RaiseCapitalFoldOne, RaiseCapitalFoldTwo, and RaiseCapitalFoldThree, which represent different sections of the page.
 * - The component also includes a Footer component with the prop "isRaiseCapital" set to true.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the UI.
 * - RaiseCapitalFoldOne, RaiseCapitalFoldTwo, RaiseCapitalFoldThree: These are sub-components used to render different sections of the page.
 * - Footer: This is a component used to render the footer section of the page.
 */
export default function RaiseCapital() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-color bg-color--raise-capital">
      <RaiseCapitalFoldOne />
      <RaiseCapitalFoldTwo />
      <RaiseCapitalFoldThree />
      <Footer isRaiseCapital={true} />
    </div>
  );
}
