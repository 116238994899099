import React from "react";
import DisplayLoader from "./../common/DisplayLoader";

/**
 * SaveButton Component
 * 
 * Overview:
 * The SaveButton component is a button that triggers a save action. It can be used to save data or perform any other action that requires user interaction. This component is commonly used in forms or data entry screens.
 * 
 * Details:
 * - The SaveButton component accepts the following props:
 *   - text: The text to be displayed on the button.
 *   - extraClassName: An optional additional CSS class name to be applied to the button.
 *   - handleOnClickSaveButton: The event handler function to be called when the button is clicked.
 *   - loading: A boolean flag indicating whether the button is in a loading state. If true, a loading spinner will be displayed instead of the text.
 * - The SaveButton component renders a button element with the specified text. If the loading prop is true, a loading spinner component (DisplayLoader) is rendered instead of the text.
 *  */
export default function SaveButton({
  text,
  extraClassName,
  handleOnClickSaveButton,
  loading,
}) {
  return (
    <>
      <button
        className={`vl-common-save-btn ${extraClassName}`}
        onClick={handleOnClickSaveButton}
      >
        {loading === true ? (
          <DisplayLoader loaderColor="#ffffff" />
        ) : (
          <span>{text}</span>
        )}
      </button>
    </>
  );
}

SaveButton.defaultProps = {
  text: "Save",
  extraClassName: "",
  loading: false,
};
