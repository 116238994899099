import React, { useEffect, useState } from "react";
import format from "date-fns/format";
// import Select from "react-select";

// import InputFieldNumber from "../common/InputFieldNumber";
import TextareaField from "../common/TextareaField";
import CustomRadioButton from "../common/CustomRadioButton";
import SaveAndNextButton from "../common/SaveAndNextButton";
import BackButton from "../common/BackButton";
// import SkipButton from "../common/SkipButton";
import DatepickerSingle from "../common/DatepickerSingle";

// api
import {
  getRaiseCapitalOfferings,
  createRaiseCapitalOfferings,
  updateRaiseCapitalOfferings,
} from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { validateFundraiser } from "./../../../web-app-store/validations/raise-capital/raiseCapitalFundraiser";

import {
  investmentStageOptions,
  investmentStageRaiseCapitalOptions,
  revenueModelOptions,
  tractionOptions,
} from "../common/allDropdownOptions";
import CurrencyInputCommon from "../common/CurrencyInputCommon";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import DropdownMultiSelectCommon from "../common/DropdownMultiSelectCommon";
/**
 * RaiseSetUpFundraiser Component
 *
 * Overview:
 * The RaiseSetUpFundraiser component is responsible for setting up a fundraiser. It allows the user to enter information such as funding goal, minimum investment, valuation cap, start date, investment stage, and revenue stage. It also handles validation and saving of the entered data.
 *
 * Details:
 * - The component uses Redux to manage state and dispatch actions to interact with the API.
 * - It includes several useEffect hooks to handle side effects such as scrolling to the top of the page and fetching data from the API.
 * - The component uses useState hooks to manage form values and frontend errors.
 * - It contains various event handlers to update the form values based on user input.
 * - The component renders input fields, dropdown selects, and radio buttons to capture user input.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses react-redux to connect Redux store to the component.
 * - CurrencyInputCommon: The component uses CurrencyInputCommon component for currency input.
 * - DropdownSelectCommon: The component uses DropdownSelectCommon component for dropdown select.
 * - DatepickerSingle: The component uses DatepickerSingle component for date input.
 * - CustomRadioButton: The component uses CustomRadioButton component for radio buttons.
 */
export default function RaiseSetUpFundraiser({ handleOnSelectTab }) {
  // api
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    previousOfferingId: "",
    fundingGoal: "",
    // minimumInvestment: "",
    valuationCap: "",
    startDate: null,
    useofFunds: "",
    // market_landscape: "",
    current_traction: "",
    revenue_model: " ",
    selectedRadioOption: "radioTypeRevenue",
    investmentStage: "",
  });

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );
  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0]?.id));
    }
  }, [dispatch, raiseCapitalOrg]);

  useEffect(() => {
    // console.log(raiseCapitalOfferings);
    if (!isEmpty(raiseCapitalOfferings)) {
      let data = raiseCapitalOfferings[0];
      let revenueModel = [];
      let transactionModel = [];
      if (!isEmpty(data?.revenue_model)) {
        data?.revenue_model?.split(",")?.forEach((ele) => {
          revenueModel.push({
            value: ele,
            label: ele,
          });
        });
      }

      if (!isEmpty(data?.current_traction)) {
        data?.current_traction?.split(",")?.forEach((ele) => {
          transactionModel.push({
            value: ele,
            label: ele,
          });
        });
      }
      let valuesObj = {
        previousOfferingId: data.id,
        fundingGoal: data.funding_goal,
        // minimumInvestment: data.max_funding_goal,
        valuationCap: data.valuation_cap,
        startDate:
          data.fund_start_date === null ? null : new Date(data.fund_start_date),
        // data."offering_status",
        // data."invest_now_url",
        useofFunds: data.use_of_funds,
        // market_landscape: data.market_landscape,
        current_traction: transactionModel,
        revenue_model: revenueModel,
        selectedRadioOption: data.revenue_stage,
        investmentStage: !isEmpty(data.investment_stage)
          ? {
              value: data.investment_stage,
              label: data.investment_stage,
            }
          : "",
      };
      setValues({ ...valuesObj });
    }
  }, [raiseCapitalOfferings]);

  //frontend errors
  const [frontEndErrors, setFrontEndErrors] = useState({});

  /*==========================
             handler
      ===========================*/
  const handleChange = (event) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  // const handleChangeNumber = (e) => {
  //   setFrontEndErrors({});
  //   setValues({
  //     ...values,
  //     [e.target.name]: e.target.validity.valid ? e.target.value : "",
  //   });
  // };

  const handleChangeFundingGoal = (element) => {
    // console.log(element);
    setFrontEndErrors({});
    setValues({
      ...values,
      fundingGoal:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };
  const handleChangeMinimumInvestment = (element) => {
    // console.log(element);
    setFrontEndErrors({});
    setValues({
      ...values,
      minimumInvestment:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };
  const handleChangeValuationCap = (element) => {
    // console.log(element);
    setFrontEndErrors({});
    setValues({
      ...values,
      valuationCap:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeStart = (date) => {
    setFrontEndErrors({});
    if (date === null) {
      setValues({
        ...values,
        startDate: null,
      });
    } else {
      setValues({
        ...values,
        startDate: new Date(date[0]),
      });
    }
  };

  const handleChangeInvestmentStage = (investmentStage) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      investmentStage,
    });
  };

  const handleChangeDropDown = (e, name) => {
    setValues({
      ...values,
      [name]: e,
    });
  };

  // const handleOnClickSkip = () => {
  //   handleOnSelectTab(4);
  // };
  const handleOnClickRadioOption = (e) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      selectedRadioOption: e.target.value,
    });
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      handleOnSelectTab(3);
    } else {
      handleOnSelectTab(2);
    }
  };

  const handleOnClickSaveAndNext = () => {
    const { isValid, errors } = validateFundraiser(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    }

    if (isValid && !isEmpty(raiseCapitalOrg)) {
      setFrontEndErrors({});
      setLoading(true);
      let newData = {};
      let revenueModel = [];
      let transactionModel = [];
      if (!isEmpty(values?.revenue_model)) {
        values?.revenue_model?.forEach((ele) => {
          revenueModel.push(ele?.value);
        });
      }

      if (!isEmpty(values?.current_traction)) {
        values?.current_traction?.forEach((ele) => {
          transactionModel.push(ele?.value);
        });
      }
      newData = {
        organization: { id: raiseCapitalOrg[0].id },
        funding_goal: values.fundingGoal,
        // max_funding_goal: values.minimumInvestment,
        valuation_cap: values.valuationCap,
        fund_start_date:
          values.startDate === null
            ? null
            : format(values.startDate, "YYYY-MM-DD"),
        // offering_status: values.,
        // invest_now_url: values.,
        use_of_funds: values.useofFunds,
        // market_landscape: values.market_landscape,
        current_traction: transactionModel.join(","),
        revenue_model: revenueModel.join(","),
        revenue_stage: values.selectedRadioOption,
        investment_stage: values.investmentStage.value,
      };

      if (isEmpty(raiseCapitalOfferings)) {
        dispatch(createRaiseCapitalOfferings(newData, callBackStatus));
      } else {
        // console.log("update offering");
        // console.log("new data", newData);
        // console.log("previous data", values.offeringData);
        dispatch(
          updateRaiseCapitalOfferings(
            newData,
            values.previousOfferingId,
            callBackStatus
          )
        );
        // dispatch(
        //   updateRaiseCapitalOfferings(
        //     newData,
        //     raiseCapitalOrg[0].id,
        //     callBackStatus
        //   )
        // );
      }
    }
  };

  /*==========================================
                    render
     ==========================================*/
  return (
    <div className="raise-capital-more-info-div p-0">
      <div className="row pt-20">
        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Funding Goal ($)"
          name="fundingGoal"
          value={values.fundingGoal}
          onChange={handleChangeNumber}
          error={frontEndErrors.fundingGoal}
        /> */}
        <CurrencyInputCommon
          containerClassName="col-12 col-md-6 vl-form-input funding-details-input-main funding_left_add"
          label="Funding Goal ($)"
          name="fundingGoal"
          value={values.fundingGoal}
          onChange={handleChangeFundingGoal}
          error={frontEndErrors.fundingGoal}
          placeholder="Enter your funding goal in USD"
        />
        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Maximum Funding Goal ($)"
          name="minimumInvestment"
          value={values.minimumInvestment}
          onChange={handleChangeNumber}
          error={frontEndErrors.minimumInvestment}
        /> */}
        {/* <CurrencyInputCommon
          containerClassName="vl-form-input px-0 col-md-6"
          label="Maximum Funding Goal ($)"
          name="minimumInvestment"
          value={values.minimumInvestment}
          onChange={handleChangeMinimumInvestment}
          error={frontEndErrors.minimumInvestment}
        /> */}
        <CurrencyInputCommon
          containerClassName="col-12 col-md-6 vl-form-input funding-details-input-main funding_left_add "
          label="Estimated Valuation ($)"
          name="valuationCap"
          value={values.valuationCap}
          onChange={handleChangeValuationCap}
          error={frontEndErrors.valuationCap}
          placeholder="Enter your estimated valuation in USD"
        />
      </div>
      <div className="row align-items-start pt-0">
        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Valuation Cap ($)"
          name="valuationCap"
          value={values.valuationCap}
          onChange={handleChangeNumber}
        /> */}

        <div className="col-12 col-md-6 vl-form-input dropdown_edits_input funding-details-input-main ">
          <DropdownSelectCommon
            label="Investment Stage"
            value={values.investmentStage}
            onChange={handleChangeInvestmentStage}
            options={investmentStageRaiseCapitalOptions}
            placeholder={values.investmentStage}
            isSearchable={true}
            error={frontEndErrors.investmentStage}
            className="funding-inputs"
            extraClass="funding-inputs"
          />
        </div>
        <div className="col-12 col-md-6 funding-details-input-main funding-date-picker">
          <DatepickerSingle
            startDateLabel="Targeted Launch Date"
            startDateValue={values.startDate}
            handleChangeStart={handleChangeStart}
            error={frontEndErrors.startDate}
            className="funding-inputs"
            extraClass="funding-inputs"
          />
        </div>
      </div>

      <div className="d-flex justify-content-center mx-0 align-items-center revenue-stage revenue_space">
        <h5 className="raise-capital-more-info-label raise_capital_title_main">
          Revenue Stage
        </h5>

        <div className="d-flex mx-0 flex-shrink-0 flex-wrap align-items-center custom_dropdown_wrap">
          <CustomRadioButton
            label="Revenue"
            name="radioTypeRevenue"
            selectedRadioOption={values.selectedRadioOption}
            handleOnClickRadioOption={handleOnClickRadioOption}
          />

          <CustomRadioButton
            label="Pre-Revenue"
            name="radioTypePreRevenue"
            selectedRadioOption={values.selectedRadioOption}
            handleOnClickRadioOption={handleOnClickRadioOption}
          />

          <CustomRadioButton
            label="Stealth Mode"
            name="radioTypeStealthMode"
            selectedRadioOption={values.selectedRadioOption}
            handleOnClickRadioOption={handleOnClickRadioOption}
          />
        </div>
      </div>

      <div className="row mx-0 align-items-start pt-20 raise_cap_main">
        <div className="col-12 col-md-6 column_one">
          <DropdownMultiSelectCommon
            label="Traction"
            value={values.current_traction}
            onChange={(e) => handleChangeDropDown(e, "current_traction")}
            options={tractionOptions}
            placeholder={values.current_traction}
            error={frontEndErrors.current_traction}
            className="funding-inputs"
            extraClass="funding-inputs"
            mainDiv={`funding-details-page-main ${
              values.current_traction?.length > 0 ? "" : "traction-input-main"
            }`}
            isMulti
          />
        </div>
        <div className="col-12 col-md-6 column_one">
          <DropdownMultiSelectCommon
            label="Revenue Model"
            value={values.revenue_model}
            onChange={(e) => handleChangeDropDown(e, "revenue_model")}
            options={revenueModelOptions}
            placeholder={values.revenue_model}
            isSearchable={true}
            error={frontEndErrors.revenue_model}
            className="funding-inputs"
            extraClass="funding-inputs"
            mainDiv="funding-details-page-main"
            isMulti
          />
        </div>
      </div>

      <TextareaField
        label="Use of Funds"
        labelClassName="raise-capital-more-info-label pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_capital_text_area"
        name="useofFunds"
        value={values.useofFunds}
        onChange={handleChange}
        error={frontEndErrors.useofFunds}
        placeholder="Describe how the funds raised will be used"
      />

      {/* <TextareaField
        label="Market Landscape"
        labelClassName="raise-capital-more-info-label pt-65 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
        name="market_landscape"
        value={values.market_landscape}
        onChange={handleChange}
        error={frontEndErrors.market_landscape}
      /> */}

      <div className="d-flex mx-0 justify-content-end mt-5">
        <BackButton handleOnClickBack={() => handleOnSelectTab(1)} />
        {/* <SkipButton handleOnClickSkipButton={handleOnClickSkip} /> */}

        {isEmpty(raiseCapitalOrg) ? (
          <SaveAndNextButton
            isDisabled={true}
            handleOnClickSaveAndNext={handleOnClickSaveAndNext}
          />
        ) : (
          <SaveAndNextButton
            handleOnClickSaveAndNext={handleOnClickSaveAndNext}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}
