import React, { useEffect } from "react";
import Footer from "../header-footer/Footer";
import Navbar from "../header-footer/Navbar";
import PrivacyPolicyKnowledgeBase from "../privacy-policy/PrivacyPolicyKnowledgeBase";
import TermsAndConditionsNewContent from "./TermsAndConditionsNewContent";

/**
 * TermsAndConditionsNew Component
 * 
 * Overview:
 * This component renders the Terms and Conditions page of the application. It includes a navbar, a page title block, a knowledge base section, the content of the terms and conditions, and a footer.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component structure consists of a main div with the class "privacy-policy-main-div". Inside this div, there is a Navbar component, a div with the class "privacy-policy-page-title-block" containing an h1 element for the page title, a PrivacyPolicyKnowledgeBase component, a TermsAndConditionsNewContent component, and a Footer component.
 * - The TermsAndConditionsNewContent component accepts a prop named "displayOnWebApp" which is set to false.
 * 
 * Usage:
 * To use the TermsAndConditionsNew component, import it from the specified file path and render it within the application. Make sure to provide the necessary props if required.
 * 
 * Dependencies:
 * - React: This component is built using React.
 * - Navbar: This component is imported and used within the TermsAndConditionsNew component.
 * - PrivacyPolicyKnowledgeBase: This component is imported and used within the TermsAndConditionsNew component.
 * - TermsAndConditionsNewContent: This component is imported and used within the TermsAndConditionsNew component.
 * - Footer: This component is imported and used within the TermsAndConditionsNew component.
 */
export default function TermsAndConditionsNew() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-main-div">
      <Navbar />
      <div className="privacy-policy-page-title-block">
        <h1>Terms of Use</h1>
      </div>
      <PrivacyPolicyKnowledgeBase />
      <TermsAndConditionsNewContent displayOnWebApp={false} />
      <Footer />
    </div>
  );
}
