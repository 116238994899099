import React from "react";
import { Doughnut } from "react-chartjs-2";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";

// api
// import isEmpty from "../../../web-app-store/validations/is-empty";

const options = {
  cutoutPercentage: 65,
  legend: {
    display: false,
    position: "right",
  },
  tooltips: {
    style: {
      zIndex: 5,
    },
  },
  elements: {
    arc: {
      borderWidth: 1,
    },
  },
};

/**
 * InvestAfterProfileSetupPortfolioChart Component
 * 
 * Overview:
 * The InvestAfterProfileSetupPortfolioChart component displays a doughnut chart representing the portfolio distribution after profile setup. It shows the total amount invested and the number of assets in the portfolio.
 * 
 * Details:
 * - The component accepts the following props:
 *   - labelsData: An array of labels for the chart segments.
 *   - amountData: An array of amounts corresponding to each label.
 *   - backgroundColorArr: An array of background colors for each segment.
 * - The component calculates the total amount invested by summing up the values in the amountData array.
 * - The component renders a Doughnut chart using the Chart.js library, with the provided data and options.
 * - The component also displays the total amount invested and the number of assets in the portfolio.
 * 
 * Dependencies:
 * - Chart.js library: The component uses the Doughnut chart type from the Chart.js library to render the chart.
 * - DisplayCurrencyFormatString component: The component uses the DisplayCurrencyFormatString component to format the total amount invested.
 */
export default function InvestAfterProfileSetupPortfolioChart({
  labelsData,
  amountData,
  backgroundColorArr,
}) {
  // console.log(labelsData, amountData, backgroundColorArr);
  const data = () => {
    return {
      maintainAspectRatio: false,
      responsive: false,
      labels: labelsData,
      datasets: [
        {
          data: amountData,
          backgroundColor: backgroundColorArr,
        },
      ],
    };
  };

  let amtAdd = 0;
  const calcTotalAmount = () => {
    amountData.map((data, index) => (amtAdd = amtAdd + data));
    return amtAdd;
  };

  let totalAmount = calcTotalAmount();

  let labelLength = labelsData.length;

  return (
    <div className="invest-after-profile-setup-portfolio-chart-div">
      <>
        <Doughnut data={data} options={options} width={100} height={100} />
        <div className="invest-after-profile-setup-portfolio-chart-text-div">
          <h4 className="font-36-roboto-bold">
            {/* $ 6,500 */}
            <DisplayCurrencyFormatString value={totalAmount} />
          </h4>
          <h5 className="font-24-roboto-medium">{labelLength} Assets</h5>
        </div>
      </>
    </div>
  );
}
