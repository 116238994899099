import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_INVEST_PROFILE_SUCCESS,
  SET_INVEST_BY_USER_ID,
  // SET_INVEST_ALL_UPDATES,
} from "./../types";
import Toast from "light-toast";

/*===================================================================================
             proceed payment post fund america investor
====================================================================================*/
/**
 * Post Fund America Proceed Payment Investor Action
 * 
 * Overview:
 * Initiates a payment procedure for an investor through Fund America's services by submitting the necessary form data. This action confirms the investment transaction and updates the application state accordingly.
 * 
 * Details:
 * - Executes a POST request to a backend endpoint designed to process payments via Fund America.
 * - Notifies the user of successful data submission and invokes a callback function to handle the response.
 * 
 * Usage:
 * This action is dispatched when an investor completes a payment form for an investment, initiating the transaction process.
 * Example: `dispatch(postFundAmericaProceedPaymentInvestor(formData, callBackFundAmericaProceedPaymentInvestor))`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - `Toast` for feedback messages.
 */
export const postFundAmericaProceedPaymentInvestor =
  (formData, callBackFundAmericaProceedPaymentInvestor) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/investor-lnk-fund-americas`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        callBackFundAmericaProceedPaymentInvestor(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             get invest profile success
====================================================================================*/
/**
 * Invest Save Profile Success Action
 * 
 * Overview:
 * Saves the successful creation or update of an investor's profile in the application state. This action is critical for tracking the current status of an investor's profile within the application.
 * 
 * Details:
 * - Dispatches an action to store the investor profile success data in the redux state.
 * 
 * Usage:
 * Can be used after the successful creation or update of an investor's profile to reflect the change in the application state.
 * Example: `dispatch(investSaveProfileSuccessAction(data))`
 * 
 * Dependencies:
 * - Redux's dispatch function for state management.
 */
export const investSaveProfileSuccessAction = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_INVEST_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             get investor by user id
====================================================================================*/
/**
 * Get Investor By User ID Action
 * 
 * Overview:
 * Fetches the investor details associated with a specific user ID. This action updates the application state with the investor data, making it accessible for display or further processing.
 * 
 * Details:
 * - Retrieves investor details for the current user by making a GET request with the user ID.
 * - Updates the redux state with the fetched investor data.
 * 
 * Usage:
 * Dispatched to load the investor profile details for the currently logged-in user.
 * Example: `dispatch(getInvestorByUserId())`
 * 
 * Dependencies:
 * The same as `Post Fund America Proceed Payment Investor Action`.
 */
export const getInvestorByUserId = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/investors?user=${userData.id}`
    );
    if (data) {
      dispatch({
        type: SET_INVEST_BY_USER_ID,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create investor
====================================================================================*/
/**
 * Create Investor Action
 * 
 * Overview:
 * Handles the creation of a new investor profile by submitting the provided form data to the backend. Upon successful creation, it refreshes the investor data in the application state.
 * 
 * Details:
 * - Sends a POST request with the investor's form data to create a new profile.
 * - Notifies the user of successful profile creation and refreshes the investor profile data.
 * - Invokes a callback function to communicate the success status and newly created investor data.
 * 
 * Usage:
 * This action is dispatched when a new investor profile is created, ensuring the application state is immediately updated.
 * Example: `dispatch(createInvestor(formData, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Invest Save Profile Success Action`.
 */
export const createInvestor =
  (formData, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/investors`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getInvestorByUserId());
        callBackStatus(status, data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             update investor
====================================================================================*/
/**
 * Update Investor Action
 * 
 * Overview:
 * Updates an existing investor profile with the provided form data. This action ensures that the investor's details are current and accurately reflected in the application state.
 * 
 * Details:
 * - Performs a PUT request to update an investor's profile using their unique ID and the provided form data.
 * - Refreshes the investor profile data in the application state to reflect the update.
 * - Utilizes a callback function to notify the caller of the operation's success.
 * 
 * Usage:
 * Dispatched when updating an existing investor profile, such as changing investment preferences or contact information.
 * Example: `dispatch(updateInvestor(formData, investorId, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Create Investor Action`.
 */
export const updateInvestor =
  (formData, investorId, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.put(
        `${backendUrl}/investors/${investorId}`,
        formData
      );
      if (data) {
        Toast.info("Information saved", 3000);
        dispatch(getInvestorByUserId());
        callBackStatus(status);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             create investor categories
====================================================================================*/
/**
 * Create Industry Categories Action
 * 
 * Overview:
 * Creates new industry categories for an investor's profile, allowing for more detailed categorization of investment interests.
 * 
 * Details:
 * - Submits a POST request with form data to create new industry categories.
 * - Utilizes a callback function to communicate the operation's success status.
 * - Refreshes the investor profile data to include the new categories.
 * 
 * Usage:
 * Useful for investors looking to specify their areas of interest or expertise within their profiles.
 * Example: `dispatch(createIndustryCategories(formData, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Update Investor Action`.
 */
export const createIndustryCategories =
  (formData, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/industry-categories`,
        formData
      );
      if (data) {
        // console.log(data);
        callBackStatus(status);
        dispatch(getInvestorByUserId());
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
             create social categories
====================================================================================*/
/**
 * Create Social Categories Action
 * 
 * Overview:
 * Similar to creating industry categories, this action allows for the specification of social categories related to an investor's interests or social impact goals.
 * 
 * Details:
 * - Executes a POST request with form data to add new social categories.
 * - Invokes a callback function to provide feedback on the operation's success.
 * - Updates the investor profile data to reflect the new social categories.
 * 
 * Usage:
 * Employed when investors wish to highlight their social impact preferences or objectives within their profiles.
 * Example: `dispatch(createSocialCategories(formData, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Create Industry Categories Action`.
 */
export const createSocialCategories =
  (formData, callBackStatus) => async (dispatch) => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/social-categories`,
        formData
      );
      if (data) {
        // console.log(data);
        callBackStatus(status);
        dispatch(getInvestorByUserId());
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
 * Delete Investor Category By ID Action
 * 
 * Overview:
 * Removes a specific industry category from an investor's profile. This action ensures that investor preferences are accurately maintained within the application.
 * 
 * Details:
 * - Sends a DELETE request to remove an industry category by its ID.
 * - Optionally invokes a callback function to notify of the category's successful deletion.
 * 
 * Usage:
 * Dispatched when an investor needs to remove an industry category from their profile.
 * Example: `dispatch(deleteInvestorCategoryById(categoryId, callBackDeleteInvestor))`
 * 
 * Dependencies:
 * The same as `Post Fund America Proceed Payment Investor Action`, adjusted for deletion operations.
 */
export const deleteInvestorCategoryById =
  (categoryId, callBackDeleteInvestor) => async (dispatch) => {
    try {
      let { data, status } = await axios.delete(
        `${backendUrl}/industry-categories/${categoryId}`
      );
      if (data) {
        if (callBackDeleteInvestor) {
          callBackDeleteInvestor(status);
        }

        // console.log(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/**
 * deleteSocialCategoryById Function
 * 
 * Overview:
 * The deleteSocialCategoryById function is designed to asynchronously delete a social category by its ID from a backend system. It primarily interacts with a REST API to perform the deletion operation. This function is typically used within the context of managing social categories in an application that requires dynamic social impact categorization or management. It fits into the larger application structure as a part of the actions or services layer, facilitating state updates in response to user actions or automated processes.
 * 
 * Details:
 * - This function implements async/await syntax for handling asynchronous operations, specifically an HTTP DELETE request to a backend service.
 * - It takes a categoryId as an argument to identify the social category to be deleted and an optional callBackDeleteSocialImpact function to handle post-deletion operations or state updates.
 * - The function uses axios for HTTP requests, sending a DELETE request to a specified endpoint that includes the categoryId in the URL path.
 * - Error handling is accomplished through a try-catch block, logging any errors to the console.
 * - If the deletion is successful and a callback function is provided, it is invoked with the HTTP status code of the response, allowing for further custom handling by the caller.
 * 
 * Dependencies:
 * - axios: Used for making HTTP DELETE requests to the backend.
 * - backendUrl: A constant that stores the URL of the backend service. This value should be defined elsewhere in the application's configuration.
 * - Redux (optional): If used within a Redux architecture, the function expects a dispatch function as part of its arguments, allowing it to be integrated into Redux action creators or thunks.
 */

export const deleteSocialCategoryById =
  (categoryId, callBackDeleteSocialImpact) => async (dispatch) => {
    try {
      let { data, status } = await axios.delete(
        `${backendUrl}/social-categories/${categoryId}`
      );
      if (data) {
        if (callBackDeleteSocialImpact) {
          callBackDeleteSocialImpact(status);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
