import React from "react";
import { Link } from "react-router-dom";
import { knowledgeBase } from "../header-footer/pageLinks";

/**
 * PrivacyPolicyKnowledgeBase Component
 * 
 * Overview:
 * This component renders a section of the privacy policy knowledge base in the UI. It provides a link to the knowledge base and displays a description of the early stage investments.
 * 
 * Details:
 * - The component is a functional component that returns JSX elements.
 * - It renders a div element with class names for styling.
 * - It includes a Link component from react-router-dom to navigate to the knowledge base.
 * - The component displays a heading and a description of the early stage investments.
 * 
 * Dependencies:
 * - React: The component is built using React library.
 * - react-router-dom: The component uses the Link component from react-router-dom to navigate to the knowledge base.
 */
export default function PrivacyPolicyKnowledgeBase() {
  return (
    <div className="d-flex mx-0 footer-row-div footer-row-div--privacy-policy">
      <Link to={knowledgeBase}>
        <div className="footer-knowledge-div">
          <h5 className="font-30-montserrat-bold">Knowledge Base &#62; </h5>
        </div>
      </Link>
      <div className="footer-top-right-div">
        <h5 className="font-25-montserrat-regular">
          All early stage investments entail risk and we are committed to
          helping you understand if investing is suitable for you.
        </h5>
      </div>
    </div>
  );
}
