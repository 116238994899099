import React, { useState } from "react";
import InvestProfile from "./InvestProfile";
import InvestAfterProfileSetup from "./InvestAfterProfileSetup";
import SideNavbar from "../header/SideNavbar";
import { activeSidebarMenu, investPageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";

import { investWebAppLink } from "../../../components/desktop/header-footer/pageLinks";

import ProfileReviewModalInvest from "../common/ProfileReviewModalInvest";

// api
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  investSaveProfileSuccessAction,
  getInvestorByUserId,
} from "../../../web-app-store/actions/investAction";
import { validateInvestDataNotEmpty } from "../../../web-app-store/validations/invest/investData";
import { validateInvestDataNotEmptyPortfolio } from "../../../web-app-store/validations/invest/investDataPortfolio";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../common/DisplayLoader";
import Footer from "../../../components/desktop/header-footer/Footer";

/**
 * MainInvestRouting Component
 *
 * Overview:
 * The MainInvestRouting component is responsible for rendering the main content of the investment page in the application. It handles API calls, state management, and conditional rendering based on the state of the application.
 *
 * Details:
 * - The component uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store.
 * - It dispatches the getInvestorByUserId action to fetch investor data and the investSaveProfileSuccessAction to update the success status of saving the investor profile.
 * - The component uses the useEffect hook to perform side effects such as scrolling to the top of the page and updating the required field errors.
 * - It renders different components based on the state of isLoadingInvestor, investSaveProfileSuccess, and investRequiredFieldErrors.
 *
 * Usage:
 * To use the MainInvestRouting component, import it from the specified file path and include it in the desired location within the application's routing system or component hierarchy.
 *
 * Dependencies:
 * - react
 * - react-redux
 * - useDispatch
 * - useSelector
 * - getInvestorByUserId action
 * - investSaveProfileSuccessAction action
 * - isEmpty utility function
 * - validateInvestDataNotEmpty function
 * - validateInvestDataNotEmptyPortfolio function
 * - SideNavbar component
 * - PageTitle component
 * - DisplayLoader component
 * - ProfileReviewModalInvest component
 * - InvestProfile component
 * - InvestAfterProfileSetup component
 * - Footer component
 */
export default function MainInvestRouting() {
  // api
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvestorByUserId());
    dispatch(investSaveProfileSuccessAction(false));
  }, [dispatch]);

  const investor = useSelector((state) => state.invest.investor);

  const isLoadingInvestor = useSelector(
    (state) => state.invest.isLoadingInvestor
  );

  const investSaveProfileSuccess = useSelector(
    (state) => state.invest.investSaveProfileSuccess
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [investSaveProfileSuccess]);

  const [investRequiredFieldErrors, setInvestRequiredFieldErrors] =
    useState("error");

  useEffect(() => {
    if (!isEmpty(investor)) {
      const { isValid } = validateInvestDataNotEmpty(investor[0]);

      if (isValid) {
        const { isValid } = validateInvestDataNotEmptyPortfolio(investor[0]);
        if (isValid) {
          setInvestRequiredFieldErrors(null);
        }
      }
    }
  }, [investor]);

  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[2]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={investPageTitle.icon}
            pageTitle={investPageTitle.title}
          />

          {isLoadingInvestor ? (
            <div className="vl-raise-capital-main-page">
              <div className="text-center">
                <DisplayLoader />
              </div>
            </div>
          ) : investSaveProfileSuccess ? (
            <ProfileReviewModalInvest
              linkPath={investWebAppLink}
              investVal={true}
            />
          ) : !isEmpty(investRequiredFieldErrors) ? (
            <InvestProfile />
          ) : (
            <InvestAfterProfileSetup />
          )}
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
