import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

/**
 * RouteChangeTracker Component
 * 
 * Overview:
 * The RouteChangeTracker component is responsible for tracking route changes in the application using ReactGA library. It listens to the history object and updates the page view in Google Analytics whenever the route changes.
 * 
 * Details:
 * - The component accepts a history object as a prop, which is used to listen to route changes.
 * - When a route change occurs, the component calls ReactGA.set() to update the page property with the current pathname and then calls ReactGA.pageview() to send a pageview event to Google Analytics.
 * - The component does not render any UI elements and returns an empty <div>.
 * 
*/
const RouteChangeTracker = ({ history }) => {
  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
