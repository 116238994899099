import React from "react";

/**
 * NetWorthCalcNeedHelp Component
 * 
 * Overview:
 * This component renders a block with a link to a resource that provides help in calculating net worth. It is used in the net worth calculation feature of the application.
 * 
 * Details:
 * - The component renders a div block with a link to an external resource.
 * - The link opens in a new tab and has the text "Need help calculating your net worth?".
 * 
 * Usage:
 * <NetWorthCalcNeedHelp />
 * 
 * Dependencies:
 * None
 */
export default function NetWorthCalcNeedHelp() {
  return (
    <>
      <div className="vl-need-help-net-worth-block">
        <a
          href="https://www.finra.org/investors/personal-finance/know-your-net-worth#:~:text=Once%20you've%20calculated%20the,a%20%22positive%22%20net%20worth"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="font-14-roboto-bold">
            Need help calculating your net worth?
          </span>
        </a>
      </div>
    </>
  );
}
