/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import career from "../../../assets/imgs/desktop/about/career-img.jpg";
import Navbar from "../header-footer/Navbar";
import Footer from "../header-footer/Footer";
import "./career.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCareerData } from "../../../web-app-store/actions/careerAction";
import isEmpty from "../../../web-app-store/validations/is-empty";
import Modal from "react-responsive-modal";
import CareerDetails from "./CareerDetails";
import { Link, useHistory } from "react-router-dom";
import careerDetails, {
  careerDetailsPage,
} from "../../../components/desktop/header-footer/pageLinks";

/**
 * WebsiteCareer Component
 * 
 * Overview:
 * The WebsiteCareer component is responsible for rendering the career page of the website. It displays a list of job openings and allows users to view the details of each job and apply for them.
 * 
 * Details:
 * - The component uses React hooks such as useState, useEffect, useSelector, and useDispatch for state management and data fetching.
 * - It imports and uses other components such as Navbar, CareerDetails, and Footer to build the page layout.
 * - The component fetches career data from the Redux store using the useSelector hook and dispatches an action to get the data using the useDispatch hook.
 * - It renders a list of job openings by mapping over the careerData state array.
 * - Clicking on a job title opens a modal with detailed information about the job.
 * - Clicking on the "Submit" button opens the default email client with a pre-filled email to the specified email address.
 * 
 * Usage:
 * To use the WebsiteCareer component, import it into the desired file and render it within the application. Make sure to provide the necessary Redux store configuration and data fetching logic.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - Redux: The component uses the useSelector and useDispatch hooks to access and update the Redux store.
 * - React Router: The component uses the useHistory hook for navigation.
 * - Other custom components: The component imports and uses other custom components such as Navbar, CareerDetails, and Footer.
 */
export default function WebsiteCareer() {
  const [careerData, setCareerData] = useState([]);
  const careerList = useSelector((state) => state.career.careerAllFeeds);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [careerPageDetails, setCareerPageDetails] = useState({
    description: "",
    title: "",
    location: "",
    position: "",
  });
  const [careerDetails, setCareerDetails] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCareerData());
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!isEmpty(careerList)) {
      setCareerData([careerList]);
    }
  }, [careerList]);

  useEffect(() => {
    // debugger;
    if (!isEmpty(careerPageDetails)) {
      let careerRecord = careerData[0]?.find(
        (item) => item.id === careerPageDetails.id
      );
      setCareerDetails(careerRecord);
    }
  }, [careerPageDetails]);

  const SectionA = ({ dec }) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: dec }} className="discription" />
    );
  };

  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();
    return str.replace(/&nbsp;/gi, " ");
  };

  const handleMail = (item) => {
    window.open(
      `mailto:codebrain.infotech05@gmail.com?body=Hi&subject=${item.title}`
    );
  };

  const openModal = () => {
    setShow(false);
  };

  const showModal = (item) => {
    // debugger;
    setShow(true);
    setCareerPageDetails({
      id: item?.id,
      description: item?.dec,
      title: item?.title,
      location: item?.location,
      position: item?.position,
    });
  };

  return (
    <>
      {show ? (
        <CareerDetails careerPageDetails={careerDetails} setShow={setShow} />
      ) : (
        <div className="career-main-page">
          <Navbar className="navbar" />
          <div className="content-wrap">
            <div className="career">
              <div className="career-wrapper">
                {/* <div className="career-main">
                  <img src={career} alt="careerImage" />
                </div> */}
                <div className="career-we-hiring">
                  <h2 className="main-title">
                  Join Us to Help Accelerate How Capital is Raised
                  </h2>
                  <span className="title-sub">
                    Are you passionate about driving innovation? VedasLabs is
                    seeking talented individuals to join our team and drive our
                    vision forward. As part of a growing and dynamic group of
                    inspired people, you'll have significant responsibilities
                    and the potential for tremendous growth. At VedasLabs, we're
                    committed to offering opportunities to build your network
                    and sharpen your skills, so you can achieve your full
                    potential. Join us today and be part of shaping the future
                    of Web3 crowdfunding!
                  </span>
                </div>
              </div>
            </div>
            <div className="w-100 text-center pb-5 main-bg">
              <h2 className="career-heading">JOB OPENINGS</h2>
              <div className="container p-0">
                <div className="career-management m-0 row">
                  {careerData[0]?.map((item, index) => {
                    return (
                      <div className="career-data mt-4 col-12 p-0" key={index}>
                        <div className="h-100 career-card overflow-hidden">
                          {/* <img
                            src={item.image.url}
                            alt="banner"
                            className="career-img"
                          /> */}
                          <div className="content-wrapper">
                            <h3
                              className="career-title text-capitalize"
                              onClick={() => showModal(item)}
                            >
                              {item.title}
                            </h3>
                            <div className="education my-2">
                              <p className="career-education">
                                Location :{" "}
                                <span className="career-position-sub">
                                  {item.location}
                                </span>
                              </p>
                              {/* <p className="career-position">
                                Position :{" "}
                                <span className="career-position-sub">
                                  {item.position}
                                </span>
                              </p> */}
                            </div>
                            {/* <p className="career-skills">Job Discription : </p>
                            <SectionA dec={removeTags(item.discription)} /> */}
                          </div>
                          {/* <button
                            className="submit-data text-uppercase "
                            onClick={() => handleMail(item)}
                          >
                            Submit
                          </button> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
