import {
  SET_DISCUSSIONS_ALL,
  SET_DISCUSSIONS_ALL_BY_OFFERING_ID,
  RESET_DISCUSSIONS_ALL,
  RESET_DISCUSSIONS_ALL_BY_OFFERING_ID,
} from "./../types";
import { compareAsc } from "date-fns";

const initialState = {
  discussionsAll: {},
  discussionsAllByOfferingId: {},
};

/**
 * Discussions Reducer
 * 
 * Overview:
 * This reducer handles the state management for discussions in the web app store. It provides functionality to set, reset, and sort discussions based on different criteria.
 * 
 * Details:
 * - The reducer uses the initialState object to initialize the state.
 * - The reducer handles different action types to update the state accordingly.
 * - The discussionsAll and discussionsAllByOfferingId properties in the state store the discussions data.
 * - The discussions are sorted based on the updated_at property in descending order using the compareAsc function from the date-fns library.
 * 
 * Usage:
 * This reducer is used in the web app store to manage discussions data. It can be integrated into the store using the combineReducers function from the redux library.
 * 
 * Dependencies:
 * - initialState: The initial state object for the discussions reducer.
 * - SET_DISCUSSIONS_ALL: Action type to set all discussions.
 * - SET_DISCUSSIONS_ALL_BY_OFFERING_ID: Action type to set discussions by offering ID.
 * - RESET_DISCUSSIONS_ALL: Action type to reset all discussions.
 * - RESET_DISCUSSIONS_ALL_BY_OFFERING_ID: Action type to reset discussions by offering ID.
 * - compareAsc: A function from the date-fns library to compare dates in ascending order.
 */
export default function discussionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DISCUSSIONS_ALL:
      return {
        ...state,
        discussionsAll: action.payload.sort((a, b) =>
          compareAsc(new Date(b.updated_at), new Date(a.updated_at))
        ),
      };

    case SET_DISCUSSIONS_ALL_BY_OFFERING_ID:
      return {
        ...state,
        discussionsAllByOfferingId: action.payload.sort((a, b) =>
          compareAsc(new Date(b.updated_at), new Date(a.updated_at))
        ),
      };

    case RESET_DISCUSSIONS_ALL:
      return {
        ...state,
        discussionsAll: {},
      };

    case RESET_DISCUSSIONS_ALL_BY_OFFERING_ID:
      return {
        ...state,
        discussionsAllByOfferingId: {},
      };

    default:
      return state;
  }
}
