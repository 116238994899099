import React, {
  Fragment,
  // useState
} from "react";
import format from "date-fns/format";

// api
import { useEffect } from "react";
import { getUpdatesExploreDetailsByOrganizationId } from "../../../web-app-store/actions/updatesAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import RaiseCapitalEditUpdate from "./RaiseCapitalEditUpdate";

/**
 * RaiseCapitalDisplayUpdates Component
 * 
 * Overview:
 * This component is responsible for displaying updates related to raising capital. It fetches the updates from the server using the orgId prop and renders them in a list format. It also provides the ability to edit the updates.
 * 
 * Details:
 * - The component uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store.
 * - It fetches the updatesExploreDetailsByOrganizationId using the dispatch function and the orgId prop.
 * - The component retrieves the userData from the localStorage.
 * - It renders the updatesExploreDetailsByOrgId array as a list of updates.
 * - Each update includes the user's name, the update details, and the timestamp of the update.
 * - If the updatesExploreDetailsByOrgId array is empty, it displays a message indicating that no updates were found.
 * 
 * Usage:
 * To use the RaiseCapitalDisplayUpdates component, pass the orgId prop to specify the organization ID for which to fetch the updates.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - react-redux: The component uses the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store.
 * - isEmpty: The component uses the isEmpty function to check if the updatesExploreDetailsByOrgId array is empty.
 * - format: The component uses the format function from the date-fns library to format the timestamp of the updates.
 * - RaiseCapitalEditUpdate: The component renders the RaiseCapitalEditUpdate component for each update, allowing users to edit the updates.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.orgId - The organization ID for which to fetch the updates.
 * @returns {JSX.Element} The rendered component.
 */
export default function RaiseCapitalDisplayUpdates({ orgId }) {
  // api
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpdatesExploreDetailsByOrganizationId(orgId));
  }, [dispatch, orgId]);

  let userData = JSON.parse(localStorage.getItem("userData"));

  const updatesExploreDetailsByOrgId = useSelector(
    (state) => state.updates.updatesExploreDetailsByOrgId
  );

  return (
    <>
      {!isEmpty(updatesExploreDetailsByOrgId) ? (
        updatesExploreDetailsByOrgId.map((data, index) => (
          <Fragment key={index}>
            <div className="start-up-details-tab-panel-updates-div">
              <div className="row mx-0 flex-nowrap align-items-start">
                <h4 className="font-20-roboto-bold startup-details-tab-panel-updates-text-1">
                  {/* User name */}
                  {!isEmpty(data.user)
                    ? data.user.id === userData.id
                      ? "You Posted"
                      : data.user.firstname
                    : ""}
                </h4>
              </div>
              <RaiseCapitalEditUpdate
                postId={data.id}
                update_details={data.update_details}
              />
              <div className="row mx-0 align-items-center startup-details-tab-panel-updates-bottom-div">
                <span className="font-14-roboto-medium startup-details-tab-panel-updates-text-4">
                  {!isEmpty(data.created_at) &&
                    format(new Date(data.created_at), "DD-MMM-YYYY")}
                </span>
                <span className="font-14-roboto-medium startup-details-tab-panel-updates-text-5">
                  .
                </span>
                <span className="font-14-roboto-medium">
                  {!isEmpty(data.created_at) &&
                    format(new Date(data.created_at), "hh:mm a")}
                </span>
              </div>
              <hr />
            </div>
          </Fragment>
        ))
      ) : (
        <p className="vl-disc-card-row__text3 font-18-roboto-medium">
          No updates found
        </p>
      )}
    </>
  );
}
