import React, { useEffect, useState } from "react";
import Footer from "../header-footer/Footer";
import Navbar from "../header-footer/Navbar";
import { useDispatch } from "react-redux";
import { contactUsAction } from "./../../../web-app-store/actions/landingPageAction";
import { validateContact } from "./../../../web-app-store/validations/landingPage/contactValidation";
import isEmpty from "../../../web-app-store/validations/is-empty";
import Select from "react-select";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import logo from "../../../assets/imgs/desktop/common/vl-sm-logo.png";

const inquiryOption = [
  { value: "investor", label: "Investor" },
  { value: "issuer", label: "Issuer" },
  { value: "partnership", label: "Partnership" },
  { value: "general", label: "General" },
];
/**
 * ContactUs Component
 * 
 * Overview:
 * The ContactUs component is responsible for rendering a contact form and handling form submission. It allows users to enter their contact information, select an inquiry type, and send a message to the team.
 * 
 * Details:
 * - The component uses React hooks to manage state, including the form values and any front-end validation errors.
 * - It includes event handlers for input changes and form submission.
 * - The component uses the useDispatch hook from the react-redux library to dispatch an action to the Redux store.
 * - It renders a modal component to display a thank you message after successful form submission.
 * 
 * Usage:
 * To use the ContactUs component, import it into the desired file and include it in the JSX code where the contact form should be displayed. Make sure to wrap the component with the necessary Redux Provider component if using Redux.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - Redux: The component uses the useDispatch hook to dispatch actions to the Redux store.
 * - react-redux: The component uses the useDispatch hook from the react-redux library.
 * - react-modal: The component uses the Modal component from the react-modal library to render the thank you message modal.
 * - react-select: The component uses the Select component from the react-select library to render the inquiry type select input.
 * - validateContact: The component uses the validateContact function to perform front-end validation on the form values.
 * - contactUsAction: The component uses the contactUsAction function to dispatch a contact us action to the Redux store.
 * - Navbar: The component assumes the existence of a Navbar component for the site's navigation.
 * - Footer: The component assumes the existence of a Footer component for the site's footer.
 * 
 * Note: This component assumes the existence of certain CSS classes and images for styling and rendering purposes.
 */
export default function ContactUs() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    cname: "",
    email: "",
    inquiry: "",
    message: "",
  });

  const [frontEndErrors, setfrontEndErrors] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  /*==========================
             handler
 ===========================*/

  const handleChange = (event) => {
    setfrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeInquriy = (inquiry) => {
    setValues({
      ...values,
      inquiry,
    });
  };
  const handleSubmit = (e) => {
    // console.log(values);
    e.preventDefault();

    const { errors, isValid } = validateContact(values);

    if (!isValid) {
      setfrontEndErrors(errors);
    } else {
      const formData = {
        first_name: values.fname,
        last_name: values.lname,
        email: values.email,
        company: values.cname,
        query_for: values.inquiry.value,
        message: values.message,
      };

      // console.log(formData);

      dispatch(contactUsAction(formData, callBack));
    }
  };

  const callBack = (status) => {
    if (status === 200) {
      setOpen(!open);
      setValues({
        fname: "",
        lname: "",
        cname: "",
        email: "",
        inquiry: "",
        message: "",
      });
    }
  };

  const openModal = () => {
    setOpen(!open);
  };
  const renderModal = () => {
    return (
      <>
        <Modal
          open={open}
          onClose={openModal}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          center
          classNames={{
            overlay: "customOverlay-website",
            modal:
              "customModal-website customModal-website--newsletter-thank-you",
            closeButton: "customCloseButton-website",
          }}
        >
          <span className="closeIconInModal-website" onClick={openModal} />
          <div className="newsletter-thnak-you-div">
            <img
              src={logo}
              alt="vedslabs logo"
              className="newsletter-vl-logo"
            />
            <h4 className="newsletter-thank-you-text">Thank You</h4>
            <h5 className="font-30-montserrat-regular">for contacting us</h5>
            <button
              onClick={openModal}
              className="newsletter-submit-btn newsletter-submit-btn--thank-you"
            >
              CLOSE
            </button>
          </div>
        </Modal>
      </>
    );
  };
  return (
    <div className="bg-color bg-color--contact">
      <div className="contact-us-bg">
        <Navbar />
        <div className="contact-us-outer-div">
          <div className="contact-us-div">
            <h1 className="contact-us-title">Contact Us.</h1>
            <h5 className="font-30-montserrat-regular">
              Partnerships, Funding, and General Inquiries
            </h5>
            <div className="contact-us-form-outer-div">
              <form noValidate onSubmit={handleSubmit}>
                <div className="contact-us-input-div">
                  <input
                    type="text"
                    placeholder="First Name"
                    required
                    name="fname"
                    value={values.fname}
                    onChange={handleChange}
                  />
                </div>
                {!isEmpty(frontEndErrors) && (
                  <p className="validation-errors-login">
                    {frontEndErrors.fname}
                  </p>
                )}
                <div className="contact-us-input-div contactus-list">
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lname"
                    value={values.lname}
                    onChange={handleChange}
                    required
                  />
                </div>
                {!isEmpty(frontEndErrors) && (
                  <p className="validation-errors-login">
                    {frontEndErrors.lname}
                  </p>
                )}
                <div className="contact-us-input-div contactus-list">
                  <input
                    type="text"
                    placeholder="Company"
                    name="cname"
                    value={values.cname}
                    onChange={handleChange}
                    required
                  />
                </div>
                {/*!isEmpty(frontEndErrors) && (
                  <p className="validation-errors-login">
                    {frontEndErrors.cname}
                  </p>
                )*/}
                <div className="contact-us-input-div contactus-list">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    required
                  />
                  {!isEmpty(frontEndErrors) && (
                    <p className="validation-errors-login">
                      {frontEndErrors.email}
                    </p>
                  )}
                </div>
                <div className="contactus-list contactus-list">
                  <div className="contact-us-select-div">
                    <Select
                      value={values.inquiry}
                      onChange={handleChangeInquriy}
                      options={inquiryOption}
                      className="contact-us-select-container"
                      classNamePrefix="contact-us-select"
                      placeholder="Inquiry Type"
                      //placeholder={values.inquiry}
                      isSearchable={false}
                    />
                    {!isEmpty(frontEndErrors) && (
                      <p className="validation-errors-login">
                        {frontEndErrors.inquiry}
                      </p>
                    )}
                  </div>
                </div>
                <div className="contact-us-textarea-outer-div contactus-list">
                  <label>Message</label>
                  <textarea
                    type="text"
                    placeholder="Please type your message to the team here."
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    required
                  />
                  {!isEmpty(frontEndErrors) && (
                    <p className="validation-errors-login">
                      {frontEndErrors.message}
                    </p>
                  )}
                </div>
                <div className="contact-btns">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="contact-us-btn"
                  >
                    GET IN TOUCH &#62;
                  </button>
                </div>
              </form>
            </div>
          </div>
          {renderModal()}
        </div>
      </div>
      <Footer />
    </div>
  );
}
