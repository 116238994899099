import Validator from "validator";
import isEmpty from "../is-empty";
import { countryPhoneLengths } from "../../../components/desktop/mentor/dropdownList/country";

/**
 * MoreDetailsValidation
 *
 * Overview:
 * This module provides a function to validate the additional details entered by the user during authentication. It checks for the presence of required fields and returns any validation errors.
 *
 * Details:
 * - The validateMoreDetails function takes in two parameters: data (an object containing the user's input) and isMentor (a boolean indicating whether the user is a mentor).
 * - It initializes an empty object called errors to store any validation errors.
 * - The function checks each field in the data object using the Validator.isEmpty function from an external library.
 * - If a field is empty, an error message is added to the errors object.
 * - The function also checks if the selectedBirthDate field is null and adds an error message if it is.
 * - If the user is a mentor, the function checks if the sectors_of_interest field is empty and adds an error message if it is.
 * - Finally, the function returns an object containing the errors and a boolean indicating whether the data is valid (no errors).
 *
 * Dependencies:
 * - Validator: An external library used to perform validation checks on the input fields.
 * - isEmpty: A helper function used to check if a value is empty.
 */

function validateAge(birthDate) {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
  return birthDate < eighteenYearsAgo;
}

const validatePhoneLength = (phoneInputVal, country) => {
  if (country) {
    const { dialCode, countryCode } = country;
    const phoneWithoutDialCode = phoneInputVal.slice(dialCode.length);

    // Get the expected length for the current country
    const expectedLength = countryPhoneLengths[countryCode.toLowerCase()];

    // Check if the phone number length matches the expected length for the country
    return expectedLength
      ? phoneWithoutDialCode.length === expectedLength
      : true;
  }
};

export const validateMoreDetails = (data, isMentor, country) => {
  let errors = {};

  if (Validator.isEmpty(data.fname)) {
    errors.fname = "First name is required";
  }

  if (Validator.isEmpty(data.lname)) {
    errors.lname = "Last name is required";
  }

  if (data.DOB === "" || data.DOB === null) {
    errors.DOB = "Date of birth is required";
  }

  if (Validator.isEmpty(data.pNumber)) {
    console.log(4);
    errors.pNumber = "Phone number is required";
  } else {
    const isValid = validatePhoneLength(data?.pNumber, country);
    if (!isValid) {
      errors.pNumber = "Invalid Phone Number";
    }
  }

  if (Validator.isEmpty(data.location)) {
    errors.location = "Location is required";
  }

  if (data.selectedBirthDate === "") {
    errors.selectedBirthDate = "Date of birth is required";
  }

  if (!validateAge(new Date(data.DOB))) {
    errors.DOB = "User should be 18+ years";
  }

  if (isEmpty(data.selectedInterest)) {
    errors.selectedInterest = "Interest is required";
  }

  if (isMentor) {
    if (isEmpty(data.sectors_of_interest)) {
      errors.sectors_of_interest = "Industry is required";
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
