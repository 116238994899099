import React, { useState, useEffect } from "react";
import { Line } from "rc-progress";
import CardFollowButton from "../../desktop/explore/CardFollowButton";

// api
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";

const location = "/web-app-img/desktop/icons/location.png";

/**
 * InvestCardTextContent Component
 * 
 * Overview:
 * The InvestCardTextContent component displays the text content for an investment card. It shows information about a startup, including its name, location, company details, and funding progress.
 * 
 * Details:
 * - The component receives the offeringDataById prop, which contains the data for the investment card.
 * - The component uses the useState and useEffect hooks to manage the fundRaised state, which represents the funding progress.
 * - The component includes a removeTags function to remove HTML tags from the company details.
 * - The component renders the startup name, location, company details, funding progress bar, and funding amounts.
 * 
 * Dependencies:
 * - React: The component is built with React and relies on its features such as hooks and JSX syntax.
 * - Line: The component uses the Line component from the react-progressbar-line library to render the funding progress bar.
 * - CardFollowButton: The component uses the CardFollowButton component to render a follow button for the startup.
 * - DisplayCurrencyFormatString: The component uses the DisplayCurrencyFormatString component to format currency values.
 */
export default function InvestCardTextContent({ offeringDataById }) {
  let orgData = offeringDataById.organization;

  let amountRaised = offeringDataById.total_amount_raised;

  const [fundRaised, setFundRaised] = useState(0);
  useEffect(() => {
    if (!isEmpty(offeringDataById)) {
      const amountRaised = offeringDataById.total_amount_raised;
      const fundingGoal = offeringDataById.funding_goal;
      setFundRaised((amountRaised * 100) / fundingGoal);

      if (isNaN(fundRaised)) {
        setFundRaised(0);
      }
    }
  }, [offeringDataById, fundRaised]);

  let companyDetailsStr =
    !isEmpty(orgData.company_details) && orgData.company_details.length > 200
      ? `${orgData.company_details.substring(0, 200)}...`
      : orgData.company_details;

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, " ");
  };
  return (
    <>
      {!isEmpty(offeringDataById) && (
        <>
          <div className="row mx-0 flex-nowrap justify-content-between align-items-start">
            <div>
              <h4 className="font-22-dm-serif-regular">
                {/* Start Up Name */}
                {orgData.company_name}
              </h4>
              <div className="row mx-0 align-items-center mt-15">
                <img
                  src={location}
                  alt="logo"
                  className="explore-card-location-img"
                />
                <h5 className="font-16-open-sans-regular">
                  {/* Location */}
                  {orgData.location}
                </h5>
              </div>
            </div>

            <CardFollowButton
              orgId={!isEmpty(orgData) ? orgData.id : null}
              classNameFollowingBtn="explore-card-follow-btn explore-card-follow-btn--following"
              classNameFollowBtn="explore-card-follow-btn"
            />
          </div>
          <p className="explore-desc">{removeTags(companyDetailsStr)}</p>
          <div className="explore-card-progress-outer-div">
            <Line
              percent={fundRaised}
              strokeWidth="4"
              strokeColor="#5A9BA5"
              trailWidth="4"
              trailColor="rgba(196, 196, 196, 0.16)"
            />
          </div>
          <div className="row mx-0 flex-nowrap justify-content-between align-items-start pt-31">
            <div className="percentage-text-div">
              <h4 className="percentage-text">
                {/* <DisplayCurrencyFormatString value={fundRaised}/>%   */}
                {fundRaised.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                })}
                %
              </h4>
              <h4 className="funds-text">Funds Raised</h4>
            </div>
            <div className="funds-amount-div">
              <table>
                <tbody>
                  <tr>
                    <td className="pb-10">
                      <span className="funds-amounts-text-2">
                        Amount Raised
                      </span>
                    </td>
                    <td className="text-right pb-10">
                      <span className="funds-amounts-no-2">
                        <DisplayCurrencyFormatString value={amountRaised} />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-10">
                      <span className="funds-amounts-text-2">
                        Funding Minimum
                      </span>
                    </td>
                    <td className="text-right pb-10">
                      <span className="funds-amounts-no-2">
                        <DisplayCurrencyFormatString
                          value={offeringDataById.funding_goal}
                        />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="funds-amounts-text-2">
                        Funding Maximum
                      </span>
                    </td>
                    <td className="text-right">
                      <span className="funds-amounts-no-2">
                        <DisplayCurrencyFormatString
                          value={offeringDataById.max_funding_goal}
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}
