import React, { useState } from "react";

// api
import { useEffect } from "react";
import { getDiscussionOrganizationByUserId } from "../../../web-app-store/actions/discussionsAction";
import { useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * DiscussionsCardStartupName Component
 * 
 * Overview:
 * This component is responsible for rendering the startup name in a discussions card. It fetches the organization data based on the user ID and displays the company name if available.
 * 
 * Details:
 * - The component uses the useDispatch and useState hooks from the react-redux library for state management and dispatching actions.
 * - It fetches the organization data by calling the getDiscussionOrganizationByUserId action with the user ID and data as parameters.
 * - The fetched organization data is stored in the organizationDataById state variable using the useState hook.
 * - The component renders the company name if available in the organizationDataById object.
 * 
 * Dependencies:
 * - react-redux: Used for state management and dispatching actions.
 * - getDiscussionOrganizationByUserId: An action that fetches the organization data based on the user ID.
 */
export default function DiscussionsCardStartupName({ userId, data }) {
  // api
  const dispatch = useDispatch();

  const [organizationDataById, setOrganizationDataById] = useState();
  useEffect(() => {
    const callBackDiscussionOrganizationByUserId = (data) => {
      setOrganizationDataById(data[0]);
    };
    dispatch(
      getDiscussionOrganizationByUserId(
        userId,
        data,
        callBackDiscussionOrganizationByUserId
      )
    );
  }, [dispatch, userId]);

  return (
    <>
      <span >
        {!isEmpty(organizationDataById) && (organizationDataById.company_name || organizationDataById.organization?.company_name)}
      </span>
    </>
  );
}
