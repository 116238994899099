/**
 * isEmpty Function
 *
 * Overview:
 * This function checks if a value is empty. It returns true if the value is undefined, null, an empty object, or an empty string. Otherwise, it returns false.
 *
 * Details:
 * - The function accepts a single parameter, 'value', which is the value to be checked for emptiness.
 * - It uses various conditions to determine if the value is empty:
 *   - If the value is undefined or null, it is considered empty.
 *   - If the value is an object and it has no keys, it is considered empty.
 *   - If the value is a string and it has no characters after trimming, it is considered empty.
 * - The function returns true if the value is empty, and false otherwise.
 *
 * Dependencies:
 * This function does not have any external dependencies.
 */
const isEmpty = (value) =>
  value === undefined ||
  value === "undefined" ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export default isEmpty;
