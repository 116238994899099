import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import isEmpty from "../../../web-app-store/validations/is-empty";
import WebsiteInvestCardsHoverInfo from "./WebsiteInvestCardsHoverInfo";
import decode from "decode-html";
const noProfile = "/web-app-img/desktop/icons/no-user-profile.png";

/**
 * WebsiteInvestCardSingle Component
 * 
 * Overview:
 * This component represents a single investment card for a website. It displays information about a company and provides a link to view more details. The component is used in the home page of the application.
 * 
 * Details:
 * - The component uses the orgDataMap prop to retrieve information about the organization.
 * - It manages a hover state using the isHover state variable.
 * - The removeTags function is used to remove HTML tags from a string.
 * - The renderLinkText function renders the "View details" text.
 * - The companyDetailsStr variable is used to store the truncated company details.
 * - The renderCardTextBlock function renders the main text block of the card.
 * - The component conditionally renders different elements based on the isHover state.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - useState: The component uses the useState hook to manage the isHover state.
 * - useSelector: The component uses the useSelector hook from Redux to access the auth state.
 * - isEmpty: The component uses the isEmpty function to check if the company details or logo are empty.
 * - Link: The component uses the Link component from React Router to create navigation links.
 * - WebsiteInvestCardsHoverInfo: The component uses the WebsiteInvestCardsHoverInfo component to render additional information when hovered.
 */
export default function WebsiteInvestCardSingle({ orgDataMap }) {
  const [isHover, setIsHover] = useState();
  const auth = useSelector((state) => state.auth);
  let orgData = orgDataMap.organization;

  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();

    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, " ");
  };
  const renderLinkText = () => {
    return (
      <div
        style={{ marginLeft: 0 }}
        className="explore-card-view-details-div explore-card-view-details-div--home"
      >
        <span className="font-25-montserrat-bold">View details</span>
      </div>
    );
  };
  let companyDetailsStr =
    !isEmpty(orgData?.company_details) && orgData?.company_details?.length > 200
      ? `${removeTags(orgData?.company_details).substring(0, 200)}...`  
      : removeTags(orgData?.company_details);

  const renderCardTextBlock = () => {
    // const decodedCompanyDetailsStr = decode(orgData.companyDetailsStr)
    return (
      <div
        className="invest-card-text-div"
        // onMouseOver={() => setIsHover(true)}
        // onMouseLeave={() => setIsHover(false)}
      >
        <h5 className="invest-card-title">{orgData?.company_name}</h5>
        <h5 className="invest-card-subtitle">{orgData?.industry}</h5>
        {!isHover ? (
          <>
            <div className="invest-card-main">
              <div
                className="invest-card-para"
                // dangerouslySetInnerHTML={{ __html: companyDetailsStr }}
              >
                {companyDetailsStr.toString() === ""
                  ? "No Destails"
                  : companyDetailsStr}
              </div>
            </div>
            {/* <h5 className="invest-card-swipe-text">Swipe up to learn more </h5> */}
            <div
              className="details-btn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {auth.isAuthenticated === true ? (
                <Link
                  to={{
                    pathname: `/start-up-details/${orgDataMap.id}`,
                  }}
                >
                  {renderLinkText()}
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/start-up-details-page/${orgDataMap.id}`,
                  }}
                >
                  {renderLinkText()}
                </Link>
              )}
              {/* ) : (
              <a
                href={newLoginSignUpLink}
                target="_self"
                rel="noopener noreferrer"
              >
                {renderLinkText()}
              </a>
            )} */}
            </div>
          </>
        ) : (
          <WebsiteInvestCardsHoverInfo
            // orgId={orgData.id}
            offeringDataById={orgDataMap}
          />
        )}
      </div>
    );
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-6 col-xs mb-3">
      <div
        className={!isHover ? "invest-card" : "invest-card invest-card--hover"}
      >
        <div className="invest-card-img-div">
          <div className="invest-card-img-div">
            {!isEmpty(orgDataMap.offering_image) ? (
              <img src={orgDataMap.organization?.company_logo?.url} alt="" />
            ) : !isEmpty(orgData.company_logo) ? (
              <img
                src={orgData.company_logo.url}
                className="invest-card-img"
                alt=""
              />
            ) : null}
          </div>
        </div>
        {renderCardTextBlock()}
      </div>
    </div>
  );
}
