// import axios from "axios";
// import { backendUrl } from "./config";
import {
  RESET_EXPLORE_OFFERINGS,
  RESET_RAISE_CAPITAL_ORGANIZATIONS,
  RESET_RAISE_CAPITAL_OFFERINGS,
  RESET_RAISE_CAPITAL_TEAM,
  RESET_RAISE_CAPITAL_TAGS,
  RESET_RAISE_CAPITAL_ALL_INVESTORS,
  // RESET_DISCUSSIONS_ALL,
  RESET_DISCUSSIONS_ALL_BY_OFFERING_ID,
  // RESET_UPDATES_EXPLORE_DETAILS,
  RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
} from "./../types";
// import Toast from "light-toast";

/*===================================================================================
            get explore details investor by user id
====================================================================================*/

/**
 * startupDetailsAction.js
 * 
 * Overview:
 * This file contains the action creator function to clear data on unmount of the startup details component. It dispatches multiple actions to reset different parts of the state related to explore offerings, raise capital organizations, raise capital offerings, raise capital team, raise capital tags, raise capital all investors, discussions, and updates explore details.
 * 
 * Details:
 * - The clearDataOnStartUpDetailsUnmount function is an async arrow function that takes a dispatch parameter.
 * - Inside the function, multiple dispatch actions are called to reset different parts of the state using the RESET_* action types.
 * - Each dispatch action has an empty payload object.
 * - The function is wrapped in a try-catch block to handle any errors that may occur during the dispatch actions.
 * - If an error occurs, it is logged to the console.
 * 
 * Usage:
 * This action creator function is typically used in the cleanup phase of the startup details component's lifecycle, when the component is unmounted. It is called to clear the relevant parts of the state to ensure a clean state when the component is mounted again.
 * 
 * Dependencies:
 * This file does not have any external dependencies.
 */
export const clearDataOnStartUpDetailsUnmount = () => async (dispatch) => {
  try {
    dispatch({
      type: RESET_EXPLORE_OFFERINGS,
      payload: {},
    });

    dispatch({
      type: RESET_RAISE_CAPITAL_ORGANIZATIONS,
      payload: {},
    });

    dispatch({
      type: RESET_RAISE_CAPITAL_OFFERINGS,
      payload: {},
    });

    dispatch({
      type: RESET_RAISE_CAPITAL_TEAM,
      payload: {},
    });

    dispatch({
      type: RESET_RAISE_CAPITAL_TAGS,
      payload: {},
    });

    dispatch({
      type: RESET_RAISE_CAPITAL_ALL_INVESTORS,
      payload: {},
    });

    // dispatch({
    //   type: RESET_DISCUSSIONS_ALL,
    //   payload: {},
    // });

    dispatch({
      type: RESET_DISCUSSIONS_ALL_BY_OFFERING_ID,
      payload: {},
    });

    // dispatch({
    //   type: RESET_UPDATES_EXPLORE_DETAILS,
    //   payload: undefined,
    // });

    dispatch({
      type: RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
      payload: undefined,
    });
  } catch (err) {
    console.log(err);
  }
};
