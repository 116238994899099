import React, { useEffect, useState } from "react";
import Navbar from "../header-footer/Navbar";
import PrivacyPolicyKnowledgeBase from "../privacy-policy/PrivacyPolicyKnowledgeBase";
import Footer from "../header-footer/Footer";
import GlossaryFoldOne from "./GlossaryFoldOne";

/**
 * Glossary Component
 * 
 * Overview:
 * The Glossary component displays a glossary of terms. It includes a title, a navigation bar, a privacy policy knowledge base, a fold one section, and a footer.
 * 
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage state and side effects.
 * - The component scrolls to the top of the page when it mounts.
 * - The component sets the height of the fold one section based on the height of the "vl-glossary-main-fold-one-div-id" element.
 * - The component renders a background color div, a glossary title div, a navigation bar, a h1 element for the glossary page title, a privacy policy knowledge base component, a fold one section component, and a footer component.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - Navbar: The component depends on the Navbar component for the navigation bar.
 * - PrivacyPolicyKnowledgeBase: The component depends on the PrivacyPolicyKnowledgeBase component for the privacy policy knowledge base section.
 * - GlossaryFoldOne: The component depends on the GlossaryFoldOne component for the fold one section.
 * - Footer: The component depends on the Footer component for the footer section.
 */
export default function Glossary() {
  const [foldOneHeight, setFoldOneHeight] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-glossary-main-fold-one-div-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-glossary-main-fold-one-div-id").offsetHeight
      );
    }
  }, []);

  return (
    <>
      <div className="bg-color">
        <div id="vl-glossary-main-fold-one-div-id">
          <div className="glossary-title-div">
            <Navbar />
            <h1 className="glossary-page-title">Glossary Of Terms</h1>
          </div>
          <PrivacyPolicyKnowledgeBase />
        </div>
        <GlossaryFoldOne foldOneHeight={foldOneHeight} />

        <Footer />
      </div>
    </>
  );
}
