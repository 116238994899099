/**
 allDropdownOptions.js

Overview:
This file contains arrays of dropdown options used in the UI-2 web application. These options are used to populate dropdown menus in various components.

Details:
The file exports multiple arrays, each representing a different dropdown option category.
The arrays consist of objects with "value" and "label" properties. The "value" property represents the value associated with the option, while the "label" property represents the display label for the option.
The available dropdown option categories include interestOptions, mentorOptions, userOptions, editInterestOption, liveProfileOptions, socialOptions, lightPaperOptionArray, investmentStageOptions, investorTypeOptionArray, revenueStageTypeOptionArray, genderOptionArray, employementStatusOptionArray, educationOptionArray, martialOptionArray, jobIndustryOptionArray, priceArrayOption, timeAvailableArrayOption, and dayAvailableArrayOption.
*/

export const interestOptions = [
  { value: "RaisingCapital", label: "Raising capital" },
  { value: "Investing", label: "Investing in startups" },
  { value: "Mentoring", label: "Mentoring startups" },
  { value: "Others", label: "Other" },
];

export const mentorOptions = [
  { value: "Mentoring", label: "Mentoring startups" },
];

export const yearsDropDown = [
  { value: "Less than 1 year", label: "Less than 1 year" },
  { value: "1-2 years", label: "1-2 years" },
  { value: "3-5 years", label: "3-5 years" },
  { value: "6-10 years", label: "6-10 years" },
  { value: "10+ years", label: "10+ years" },
];

export const userOptions = [{ value: "Others", label: "Others" }];

export const editInterestOption = [
  { value: "RaisingCapital", label: "Raising capital" },
  { value: "Investing", label: "Investing in startups" },
  { value: "Mentoring", label: "Mentoring startups" },
];

export const liveProfileOptions = [
  { value: "Active Profile", label: "Active Profile" },
  { value: "InActive Profile", label: "InActive Profile" },
];

export const socialOptions = [
  { value: "Social Impact Areas", label: "Social Impact Areas" },
  { value: "Minority Founders", label: "Minority Founders" },
  { value: "Immigrant Founders", label: "Immigrant Founders" },
  { value: "Female Founders", label: "Female Founders" },
  { value: "Equal Human Rights", label: "Equal Human Rights" },
  { value: "Diversity and Inclusion", label: "Diversity and Inclusion" },
];
export const lightPaperOptionArray = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "notsure", label: "Not Sure" },
];
export const investmentStageOptions = [
  { value: "Bootstrapped", label: "Bootstrapped" },
  { value: "Friends and Family", label: "Friends and Family" },
  { value: "Angel/Seed", label: "Angel/Seed" },
  { value: "Series A, B, C, etc.", label: "Series A, B, C, etc." },
  { value: "Late Stage/Pre-IPO", label: "Late Stage/Pre-IPO" },
];
export const investmentStageRaiseCapitalOptions = [
  { value: "Friends and Family", label: "Friends and Family" },
  { value: "Pre-Seed", label: "Pre-Seed" },
  { value: "Seed", label: "Seed" },
  { value: "Series A up to $5M", label: "Series A up to $5M" },
  { value: "Investments $5M+", label: "Investments $5M+" },
];

export const tractionOptions = [
  { value: "Pre-product/idea stage", label: "Pre-product/idea stage" },
  { value: "Prototype/MVP stage", label: "Prototype/MVP stage" },
  { value: "Early adopters", label: "Early adopters" },
  { value: "Revenue generation", label: "Revenue generation" },
  { value: "Scalable growth", label: "Scalable growth" },
  { value: "Profitable", label: "Profitable" },
  { value: "Market leader", label: "Market leader" },
  { value: "Other", label: "Other" },
];

export const revenueModelOptions = [
  { value: "Affiliate marketing", label: "Affiliate marketing" },
  { value: "Aggregator", label: "Aggregator" },
  { value: "B2B (Business-to-Business)", label: "B2B (Business-to-Business)" },
  { value: "B2C (Business-to-Customer)", label: "B2C (Business-to-Customer)" },
  { value: "Brick-and-mortar", label: "Brick-and-mortar" },
  { value: "Consulting services", label: "Consulting services" },
  { value: "Direct sales", label: "Direct sales" },
  { value: "E-commerce", label: "E-commerce" },
  { value: "Franchise", label: "Franchise" },
  { value: "Freemium", label: "Freemium" },
  { value: "Hybrid model", label: "Hybrid model" },
  { value: "Licensing", label: "Licensing" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketplace", label: "Marketplace" },
  { value: "On-demand services", label: "On-demand services" },
  {
    value: "Platform as a services (PaaS)",
    label: "Platform as a services (PaaS)",
  },
  {
    value: "SaaS (Software as a Service)",
    label: "SaaS (Software as a Service)",
  },
  { value: "Sharing economy", label: "Sharing economy" },
  { value: "Social network/community", label: "Social network/community" },
  { value: "Subscription-based", label: "Subscription-based" },
];

export const investorTypeOptionArray = [
  { value: "Beginner", label: "Beginner" },
  { value: "Novice", label: "Novice" },
  { value: "Intermediate", label: "Intermediate" },
  { value: "Angel Investor", label: "Angel Investor" },
  { value: "Investment Fund", label: "Investment Fund" },
];

export const revenueStageTypeOptionArray = [
  { value: "Pre-revenue", label: "Pre-revenue" },
  { value: "Early Revenue", label: "Early Revenue" },
  { value: "Growing Revenue", label: "Growing Revenue" },
  { value: "Profitable", label: "Profitable" },
];

export const genderOptionArray = [
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Non-binary", label: "Non-binary" },
  { value: "I prefer not to say", label: "I prefer not to say" },
];

export const employementStatusOptionArray = [
  { value: "Employed", label: "Employed" },
  { value: "Self-employed", label: "Self-employed" },
  { value: "Unemployed", label: "Unemployed" },
  { value: "Student", label: "Student" },
  { value: "Retired", label: "Retired" },
];

export const educationOptionArray = [
  { value: "No formal education", label: "No formal education" },
  { value: "Primary education", label: "Primary education" },
  {
    value: "Secondary education or high school",
    label: "Secondary education or high school",
  },
  { value: "GED", label: "GED" },
  { value: "Vocational qualification", label: "Vocational qualification" },
  { value: "Bachelor's degree", label: "Bachelor's degree" },
  { value: "Master's degree", label: "Master's degree" },
  { value: "Doctorate or higher", label: "Doctorate or higher" },
];

export const martialOptionArray = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Widowed", label: "Widowed" },
  { value: "Separated", label: "Separated" },
  { value: "Divorced", label: "Divorced" },
];

export const jobIndustryOptionArray = [
  { value: "Aerospace Technology", label: "Aerospace Technology" },
  { value: "Agriculture & Farming", label: "Agriculture & Farming" },
  {
    value: "Accounting or Corporate Finance",
    label: "Accounting or Corporate Finance",
  },
  {
    value: "Administration & Office Support",
    label: "Administration & Office Support",
  },
  { value: "Advertising & Media", label: "Advertising & Media" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Call Center & Customer Service",
    label: "Call Center & Customer Service",
  },
  {
    value: "Community Services & Development",
    label: "Community Services & Development",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consulting & Strategy", label: "Consulting & Strategy" },
  { value: "Design & Architecture", label: "Design & Architecture" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Engineering", label: "Engineering" },
  { value: "Entertainment", label: "Entertainment" },
  {
    value: "Farming, Animals & Conservation",
    label: "Farming, Animals & Conservation",
  },
  { value: "Government & Defense", label: "Government & Defense" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
  {
    value: "Human Resources & Recruitment",
    label: "Human Resources & Recruitment",
  },
  {
    value: "Information & Communication Technology",
    label: "Information & Communication Technology",
  },
  { value: "Insurance & Superannuation", label: "Insurance & Superannuation" },
  { value: "Legal", label: "Legal" },
  {
    value: "Manufacturing, Transport & Logistics",
    label: "Manufacturing, Transport & Logistics",
  },
  { value: "Marketing & Communications", label: "Marketing & Communications" },
  { value: "Mining, Resources & Energy", label: "Mining, Resources & Energy" },
  { value: "Real Estate & Property", label: "Real Estate & Property" },
  { value: "Retail & Consumer Products", label: "Retail & Consumer Products" },
  { value: "Sales", label: "Sales" },
  { value: "Science & Technology", label: "Science & Technology" },
  { value: "Sport & Recreation", label: "Sport & Recreation" },
  { value: "Trades & Services", label: "Trades & Services" },
];

export const priceArrayOption = [
  { value: "free", label: "free" },
  { value: "< $24", label: "< $24" },
  { value: "$25 - $49", label: "$25 - $49" },
  { value: "$50 - $99", label: "$50 - $99" },
  { value: "$100 + ", label: "$100 + " },
];

export const timeAvailableArrayOption = [
  { value: "12am - 2am", label: "12am - 2am" },
  { value: "2am - 4am", label: "2am - 4am" },
  { value: "4am - 6am", label: "4am - 6am" },
  { value: "6am - 8am", label: "6am - 8am" },
  { value: "8am - 10am", label: "8am - 10am" },
  { value: "10am - 12pm", label: "10am - 12pm" },
  { value: "12pm - 2pm", label: "12pm - 2pm" },
  { value: "2pm - 4pm", label: "2pm - 4pm" },
  { value: "4pm - 6pm", label: "4pm - 6pm" },
  { value: "6pm - 8pm", label: "6pm - 8pm" },
  { value: "8pm - 10pm", label: "8pm - 10pm" },
  { value: "10pm - 12am", label: "10pm - 12am" },
];

export const dayAvailableArrayOption = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];
