import React from "react";
import WebsiteInvestCards from "../home/WebsiteInvestCards";

import arrow from "../../../assets/imgs/desktop/icon/invest-fold-arrow.svg";
// import img1 from "../../../assets/imgs/desktop/home/invest1.png";
// import img2 from "../../../assets/imgs/desktop/home/invest2.png";
// import img3 from "../../../assets/imgs/desktop/home/invest3.png";

// const invest = [
//   {
//     imgPath: img1,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     imgPath: img2,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     imgPath: img3,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     imgPath: img1,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     imgPath: img2,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     imgPath: img3,
//     name: "oCLOUD",
//     subName: "Software",
//     desc:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel sapien et odio scelerisque aliquam eu malesuada tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
// ];

const dueData = [
  {
    text: "Initial Screening",
    desc:
      "All funding applications are carefully screened and evaluated to determine if they will move to the next stage. Only a very small percentage of companies will make it past the initial screening. For the ones that do they then move into business due diligence and then legal due diligence.",
    mainClass: "invest-due-process-div",
  },
  {
    text: "Business Due Dilligence",
    desc:
      "This is really a business intelligence gathering exercise. Here we look at the company’s team, mission, vision, team, core values, value proposition, core problem they solve, market landscape, traction, financial performance and projections. ",
    mainClass: "invest-due-process-div-2",
  },
  {
    text: "Legal Due Dilligence",
    desc:
      "This involves verifying important items such as legal structure of the organization, proof of intellectual property rights, capitalization structure, background checks on officers, physical location of the business, and major customers. ",
    mainClass: "invest-due-process-div-3",
  },
];

export default function InvestFoldThree() {
  return (
    <div className="maincontainer-custom">
      <div className="invest-fold-three-div">
        <div className="invest-fold-three-first">
          <h5 className="threediv-title">
            VedasLabs Due Diligence Process
          </h5>
          <div className="row">
            {dueData.map((data, key) => (
              <div className="col-lg-4 col-md-12 col-sm-12">
                {/* <div className={data.mainClass} key={key}> */}
                <div key={key} className="diligence-process">
                  <h3>{data.text}</h3>
                  <div>
                    <img src={arrow} alt="arrow" />
                  </div>
                  <h5>{data.desc}</h5>
                  {/* </div> */}
                </div>
              </div>

            ))}
          </div>
        </div>
        <div className="pt-195">
          <WebsiteInvestCards />

          {/* {invest.map((data, key) => (
            <div key={key} className="invest-card">
              <div className="invest-card-img-div">
                <img
                  src={data.imgPath}
                  alt={data.name}
                  className="invest-card-img"
                />
              </div>
              <div className="invest-card-text-div">
                <h5 className="invest-card-title">{data.name}</h5>
                <h5 className="invest-card-subtitle">{data.subName}</h5>
                <p className="invest-card-para">{data.desc}</p>
                <h5 className="invest-card-swipe-text">
                  Swipe up to learn more{" "}
                </h5>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}
