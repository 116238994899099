import React, { Fragment, useState } from "react";
import format from "date-fns/format";

// api
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowersByUserIdAndFollowersUpdates,
  clearFollowersByUserIdAndFollowersUpdates,
} from "../../../web-app-store/actions/followersAction";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { compareAsc } from "date-fns";

/**
 * InvestAfterProfileSetupUpdates Component
 * 
 * Overview:
 * This component displays updates related to investments after the user's profile setup. It fetches the updates from the API and renders them in a tab panel format.
 * 
 * Details:
 * - The component uses Redux for state management and dispatches actions to fetch and clear the updates.
 * - It uses the useSelector hook to access the followersAllUpdates state from the Redux store.
 * - The component sorts the updates based on their creation date and time.
 * - The updates are rendered in a tab panel format, with each update showing the organization name, update details, and the date and time of the update.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses the useSelector and useDispatch hooks from react-redux.
 * - date-fns: The component uses the compareAsc and format functions from the date-fns library.
 * - isEmpty: The component uses the isEmpty function from a utility library (not specified in the code).
 */
export default function InvestAfterProfileSetupUpdates() {
  // api
  const dispatch = useDispatch();

  const [allUpdates, setAllUpdates] = useState();

  const followersAllUpdates = useSelector(
    (state) => state.followers.followersAllUpdates
  );

  useEffect(() => {
    dispatch(clearFollowersByUserIdAndFollowersUpdates());
    dispatch(getFollowersByUserIdAndFollowersUpdates());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(followersAllUpdates)) {
      let allUpdatesNew = followersAllUpdates.sort((a, b) =>
        compareAsc(new Date(b.created_at), new Date(a.created_at))
      );

      setAllUpdates(allUpdatesNew);
    }
  }, [followersAllUpdates]);

  return (
    <>
      {/* {console.log(allUpdates)} */}
      <div className="invest-updates-tab-panel">
        {!isEmpty(allUpdates) &&
          allUpdates.map((data, index) => (
            <Fragment key={index}>
              <div className="invest-updates-tab-panel-top-div">
                <p className="font-16-roboto-light invest-update-tab-panel-text-2">
                  <b className="font-20-roboto-bold invest-update-tab-panel-text-1">
                    {!isEmpty(data.organization) &&
                      data.organization.company_name}
                  </b>
                  {data.update_details}
                </p>
              </div>
              <div className="invest-update-date-time-div">
                <span className="font-14-roboto-medium invest-update-tab-panel-text-5">
                  {!isEmpty(data.created_at) &&
                    format(new Date(data.created_at), "DD-MMM-YYYY")}
                </span>
                <span className="font-14-roboto-medium">.</span>
                <span className="font-14-roboto-medium invest-update-tab-panel-text-6">
                  {!isEmpty(data.created_at) &&
                    format(new Date(data.created_at), "hh:mm a")}
                </span>
              </div>
              <hr />
            </Fragment>
          ))}
      </div>
    </>
  );
}
