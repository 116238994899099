/* eslint-disable no-unused-vars */
import "./app.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import ReactGA from "react-ga";
import WebsiteHome from "./components/desktop/home/Home";
import WebsiteAboutUs from "./components/desktop/about-us/AboutUs";
import WebsiteKnowledgeBase from "./components/desktop/knowledge-base/KnowledgeBase";
import WebsiteInvest from "./components/desktop/invest/Invest";
import WebsiteRaiseCapital from "./components/desktop/raise-capital/RaiseCapital";
import WebsiteFaq from "./components/desktop/faq/Faq";
import WebsitePrivacyPolicy from "./components/desktop/privacy-policy/PrivacyPolicyNew";
import WebsiteTermsAndConditions from "./components/desktop/terms-conditions/TermsAndConditionsNew";
import WebsiteKnowBeforeYouInvest from "./components/desktop/know-before-you-invest/KnowBeforeYouInvest";
import WebsiteGlossary from "./components/desktop/glossary/Glossary";
import WebsiteCareer from "./components/desktop/career/Career";
import {
  glossaryWebAppLink,
  profileWebAppLink,
  investPaymentWebAppLink,
  home,
  homeWebAppLink,
  exploreWebAppLink,
  raiseCapitalWebAppLink,
  startUpDetails,
  startUpDetailsWebAppLink,
  aboutUs,
  aboutUsWebAppLink,
  invest,
  investWebAppLink,
  raiseCapital,
  knowledgeBase,
  knowledgeBaseWebAppLink,
  knowledgeBaseBlogDetail,
  knowledgeBaseWebAppLinkBlogDetail,
  faq,
  faqWebAppLink,
  privacyPolicy,
  privacyPolicyWebAppLink,
  termsAndConditions,
  termsAndConditionsWebAppLink,
  knowBeforeYouInvest,
  career,
  knowBeforeYouInvestWebAppLink,
  contactUs,
  glossary,
  newLoginSignUpLink,
  lightPaperLink,
  mentor,
  mentorDetails,
  mentorChatBox,
  userChatBox,
  careerDetailsPage,
  mentorWebAppLink,
  mentorDetailsWebAppLink,
} from "./components/desktop/header-footer/pageLinks";
import MoreDetails from "./web-app-components/desktop/login-signup/MoreDetails";
import DefaultComponent from "./web-app-components/DefaultComponent";
import ErrorBoundary from "./web-app-components/ErrorBoundary";
import LoginRedirect from "./web-app-components/desktop/login-signup/LoginRedirect";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./web-app-store/store";
import { SET_LOGIN } from "./web-app-store/types";
import setAuthToken from "./web-app-store/utils/setAuthToken";
import PrivateRoute from "./web-app-store/PrivateRoute/PrivateRoute";
import WesiteContactUs from "./components/desktop/contact-us/ContactUs";
import StayTuned from "./web-app-components/desktop/login-signup/StayTuned";
import MainRaiseCapitalRouting from "./web-app-components/desktop/raise-capital/MainRaiseCapitalRouting";
import Explore from "./web-app-components/desktop/explore/Explore";
import StartUpDetails from "./web-app-components/desktop/explore-start-up-details/StartUpDetails";
import KnowledgeBaseBlogDetail from "./components/desktop/knowledge-base/KnowledgeBaseBlogDetail";
import KnowledgeBaseMainApp from "./web-app-components/desktop/knowledge-base/KnowledgeBaseMainApp";
import MainInvestRouting from "./web-app-components/desktop/invest/MainInvestRouting";
import InvestProceedToPayment from "./web-app-components/desktop/invest/InvestProceedToPaymentNew";
import ProceedPayment from "./web-app-components/desktop/invest/ProceedPayment";
import FaqWebApp from "./web-app-components/desktop/faq-web-app/FaqWebApp";
import GlossaryWebApp from "./web-app-components/desktop/glossary-web-app/GlossaryWebApp";
import KnowledgeBaseWebAppBlogDetailComponent from "./web-app-components/desktop/knowledge-base/KnowledgeBaseWebAppBlogDetailComponent";
import PrivacyPolicyWebApp from "./web-app-components/desktop/privacy-policy-web-app/PrivacyPolicyWebApp";
import TermsOfUseWebApp from "./web-app-components/desktop/terms-of-use/TermsOfUseWebApp";
import KnowBeforeYouInvestWeb from "./web-app-components/desktop/know-before-you-invest-web/KnowBeforeYouInvestWeb";
import NewHome from "./web-app-components/desktop/new-home/NewHome";
import AboutUsWebApp from "./web-app-components/desktop/about-us-web-app/AboutUsWebApp";
import Routes from "./Routes";
import RouteChangeTracker from "./components/desktop/RouteChangeTracker";
import LightPaperDetails from "./components/desktop/light-paper/LightPaperDetails";
import Mentor from "./components/desktop/mentor/Mentor";
import Profile from "./web-app-components/desktop/general-profile/Profile";
import MentorDetails from "./components/desktop/mentor/mentorDetails/MentorDetails";
import { MentorChatBox } from "./components/desktop/mentor/mentor-profile/mentor-chatbox/MentorChatBox";
import AOS from "aos";
import "aos/dist/aos.css";
import UserChatBox from "./components/desktop/mentor/mentorDetails/UserChatBox/UserChatBox";
import MentorProfileGeneral from "./components/desktop/mentor/mentor-profile/MentorProfileGeneral";
import PrivateRouteMentor from "./web-app-components/PrivateRouteMentor";
AOS.init();

// check if user is already loged in or not
let userData = JSON.parse(localStorage.getItem("userData"));
let jwt = JSON.parse(localStorage.getItem("jwt"));
if (userData && jwt) {
  setAuthToken(jwt);
  store.dispatch({
    type: SET_LOGIN,
    payload: userData,
  });
}
const TRACKING_ID = "UA-196198925-1";
ReactGA.initialize(TRACKING_ID);

/**
 * App Component
 *
 * Overview:
 * The App component is the root component of the application. It provides the main structure and routing for the entire application.
 *
 * Details:
 * - The component uses React Router to handle routing and navigation within the application.
 * - It wraps the entire application with the Redux Provider component to provide access to the Redux store.
 * - The component renders different routes based on the URL path, each route corresponding to a specific page or functionality within the application.
 * - It includes various sub-components for different pages and functionality, such as WebsiteHome, WebsiteAboutUs, WebsiteKnowledgeBase, etc.
 * - The component also includes private routes that require authentication to access.
 * - The component renders a CookieConsent component at the bottom of the page to display a cookie consent message.
 *
 * Usage:
 * The App component should be rendered at the top level of the application, typically in the index.js file.
 *
 * Dependencies:
 * - React
 * - React Router
 * - Redux
 * - Provider
 * - CookieConsent
 */
function App() {
  return (
    <Provider store={store}>
      <div className="App demo">
        <Router>
          <Routes>
            <RouteChangeTracker />
            <PrivateRouteMentor>
              <ErrorBoundary>
                <Switch>
                  <Route exact path={home} component={WebsiteHome} />

                  <Route exact path={aboutUs} component={WebsiteAboutUs} />
                  <Route
                    exact
                    path={knowledgeBase}
                    component={WebsiteKnowledgeBase}
                  />
                  <Route
                    exact
                    path={`${knowledgeBaseBlogDetail}/:id`}
                    component={KnowledgeBaseBlogDetail}
                  />
                  <Route exact path={invest} component={WebsiteInvest} />
                  <Route exact path={mentor} component={Mentor} />
                  <Route
                    exact
                    path={raiseCapital}
                    component={WebsiteRaiseCapital}
                  />
                  <Route exact path={faq} component={WebsiteFaq} />
                  <Route
                    exact
                    path={privacyPolicy}
                    component={WebsitePrivacyPolicy}
                  />
                  <Route
                    exact
                    path={termsAndConditions}
                    component={WebsiteTermsAndConditions}
                  />
                  <Route
                    exact
                    path={knowBeforeYouInvest}
                    component={WebsiteKnowBeforeYouInvest}
                  />
                  <Route exact path={career} component={WebsiteCareer} />
                  <Route exact path={contactUs} component={WesiteContactUs} />
                  <Route exact path={glossary} component={WebsiteGlossary} />
                  {/* web app */}
                  <Route
                    exact
                    path="/auth/:providerName/callback"
                    component={LoginRedirect}
                  />
                  <Route
                    exact
                    path="/login"
                    component={() => {
                      window.location.href = newLoginSignUpLink;
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/signup"
                    component={() => {
                      window.location.href = newLoginSignUpLink;
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/app"
                    component={() => {
                      window.location.href = newLoginSignUpLink;
                      return null;
                    }}
                  />
                  <Route
                    exact
                    path="/ohana"
                    component={() => {
                      window.location.href = "/start-up-details/14";
                      return null;
                    }}
                  />

                  {/* <Route exact path="/sign-up" component={Signup} /> */}
                  {/* <Route exact path="/login" component={Login} /> */}
                  {/* <Route exact path="/email-verify" component={EmailVerify} /> */}
                  <PrivateRoute
                    exact
                    path="/get-started"
                    component={MoreDetails}
                  />
                  {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
                  {/* <Route exact path="/reset-password" component={ResetPassword} /> */}
                  {/* <Route
                exact
                path="/email-verify-alert"
                component={EmailVerifyAlert}
              /> */}
                  {/* <Route exact path="/email-verified" component={EmailVerified} /> */}
                  <PrivateRoute
                    exact
                    path={homeWebAppLink}
                    component={NewHome}
                  />
                  {/* <Route
                exact
                path="/gmail-facebook-agree"
                component={GmailSignup}
              /> */}
                  {/* <Route
                exact
                path="/already-registered"
                component={AlreadyRegistered}
              />
              <Route
                exact
                path="/already-register"
                component={AlreadyRegisteredNew}
              /> */}
                  <PrivateRoute
                    exact
                    path="/stay-tuned"
                    component={StayTuned}
                  />

                  <PrivateRoute
                    exact
                    path={investWebAppLink}
                    component={MainInvestRouting}
                  />
                  <PrivateRoute
                    exact
                    path={mentorWebAppLink}
                    component={MentorProfileGeneral}
                  />
                  <PrivateRoute
                    exact
                    path={investPaymentWebAppLink}
                    component={InvestProceedToPayment}
                  />
                  <PrivateRoute
                    exact
                    path={"/proceed-payment"}
                    component={ProceedPayment}
                  />
                  <PrivateRoute
                    exact
                    path={raiseCapitalWebAppLink}
                    component={MainRaiseCapitalRouting}
                  />
                  <PrivateRoute
                    exact
                    path={exploreWebAppLink}
                    component={Explore}
                  />
                  <PrivateRoute
                    exact
                    path={faqWebAppLink}
                    component={FaqWebApp}
                  />
                  <PrivateRoute
                    exact
                    path={glossaryWebAppLink}
                    component={GlossaryWebApp}
                  />
                  <Route
                    exact
                    path={`${startUpDetailsWebAppLink}/:id`}
                    component={StartUpDetails}
                  />
                  <Route
                    exact
                    path={`${startUpDetails}/:id`}
                    component={StartUpDetails}
                  />
                  <Route
                    exact
                    path={lightPaperLink}
                    component={LightPaperDetails}
                  />
                  <Route
                    exact
                    path={`${mentorDetails}/:id`}
                    component={MentorDetails}
                  />
                  <Route
                    exact
                    path={`${mentorDetailsWebAppLink}/:id`}
                    component={MentorDetails}
                  />
                  {/* <Route exact path="/mentor-profile" component={MentorProfile} />
              <Route exact path="/mentor" component={MentorAfterProfileSetup} /> */}
                  {/* <Route exact path="/discussions" component={Discussions} /> */}
                  {/* <Route exact path="/notifications" component={Notifications} /> */}
                  <PrivateRoute
                    exact
                    path={knowledgeBaseWebAppLink}
                    component={KnowledgeBaseMainApp}
                  />
                  <PrivateRoute
                    exact
                    path={`${knowledgeBaseWebAppLinkBlogDetail}/:id`}
                    component={KnowledgeBaseWebAppBlogDetailComponent}
                  />

                  <PrivateRoute
                    exact
                    path={privacyPolicyWebAppLink}
                    component={PrivacyPolicyWebApp}
                  />
                  <PrivateRoute
                    exact
                    path={termsAndConditionsWebAppLink}
                    component={TermsOfUseWebApp}
                  />
                  <PrivateRoute
                    exact
                    path={knowBeforeYouInvestWebAppLink}
                    component={KnowBeforeYouInvestWeb}
                  />
                  <PrivateRoute
                    exact
                    path={aboutUsWebAppLink}
                    component={AboutUsWebApp}
                  />

                  {/* General Profile */}
                  <PrivateRoute
                    exact
                    path={profileWebAppLink}
                    component={Profile}
                  />

                  <PrivateRoute
                    exact
                    path={mentorChatBox}
                    component={MentorChatBox}
                  />

                  <PrivateRoute
                    exact
                    path={userChatBox}
                    component={UserChatBox}
                  />

                  <PrivateRoute
                    exact
                    path={`/:id`}
                    component={KnowledgeBaseWebAppBlogDetailComponent}
                  />
                  <Route component={DefaultComponent} />
                </Switch>
              </ErrorBoundary>
            </PrivateRouteMentor>
          </Routes>
        </Router>
        <CookieConsent
          style={{ justifyContent: "center", alignItems: "center" }}
          onAccept={() => {
            // alert("yay!");
          }}
          cookieName="CookieConsent"
          expires={150}
          buttonText="Accept"
          buttonStyle={{
            backgroundColor: "#fff",
            textAlign: "center",
            alignSelf: "center",
            width: 150,
            height: 40,
            fontSize: 16,
          }}
          contentStyle={{ textAlign: "center" }}
        >
          <span className="cookie-text">
            This website stores cookies on your computer. These cookies are used
            to improve your website experience and provide more personalized
            services to you, both on this website and through other media. To
            find out more about the cookies we use, see our Privacy Policy
          </span>
        </CookieConsent>
      </div>
    </Provider>
  );
}

export default App;
