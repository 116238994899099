/**
 * This module exports an object that contains various page links used in the application.
 * Each property represents a specific page and its corresponding URL.
 * These links are used for navigation within the application.
 *
 * @module pageLinks
 * @exports {Object} - An object containing page links
 *
 * @property {string} home - The URL for the home page
 * @property {string} homeWebAppLink - The URL for the home page in the web app
 * @property {string} exploreWebAppLink - The URL for the explore page in the web app
 * @property {string} aboutUs - The URL for the about us page
 * @property {string} aboutUsWebAppLink - The URL for the about us page in the web app
 * @property {string} startUpDetailsWebAppLink - The URL for the start-up details page in the web app
 * @property {string} startUpDetails - The URL for the start-up details page
 * @property {string} invest - The URL for the invest page
 * @property {string} mentor - The URL for the mentorship page
 * @property {string} mentorDetails - The URL for the mentor details page
 * @property {string} mentorChatBox - The URL for the mentor chat box page
 * @property {string} userChatBox - The URL for the user chat box page
 * @property {string} investWebAppLink - The URL for the invest page in the web app
 * @property {string} raiseCapital - The URL for the raise capital page
 * @property {string} raiseCapitalWebAppLink - The URL for the raise capital page in the web app
 * @property {string} knowledgeBase - The URL for the knowledge base page
 * @property {string} knowledgeBaseWebAppLink - The URL for the knowledge base page in the web app
 * @property {string} knowledgeBaseBlogDetail - The URL for the knowledge base blog detail page
 * @property {string} knowledgeBaseWebAppLinkBlogDetail - The URL for the knowledge base blog detail page in the web app
 * @property {string} faq - The URL for the FAQ page
 * @property {string} faqWebAppLink - The URL for the FAQ page in the web app
 * @property {string} privacyPolicy - The URL for the privacy policy page
 * @property {string} privacyPolicyWebAppLink - The URL for the privacy policy page in the web app
 * @property {string} termsAndConditions - The URL for the terms of use page
 * @property {string} termsAndConditionsWebAppLink - The URL for the terms of use page in the web app
 * @property {string} loginSignup - The URL for the login/signup page
 * @property {string} contactUs - The URL for the contact us page
 * @property {string} knowBeforeYouInvest - The URL for the know before you invest page
 * @property {string} career - The URL for the career page
 * @property {string} careerDetailsPage - The URL for the career details page
 * @property {string} knowBeforeYouInvestWebAppLink - The URL for the know before you invest page in the web app
 * @property {string} glossary - The URL for the glossary page
 * @property {string} glossaryWebAppLink - The URL for the glossary page in the web app
 * @property {string} investPaymentWebAppLink - The URL for the invest payment page in the web app
 * @property {string} profileWebAppLink - The URL for the profile page in the web app
 * @property {string} lightPaperLink - The URL for the light paper page
 * @property {string} newLoginSignUpLink - The URL for the new login/signup page
 */
module.exports = {
  home: "/",
  homeWebAppLink: "/home",
  exploreWebAppLink: "/explore",
  aboutUs: "/about-us-page",
  aboutUsWebAppLink: "/about-us",
  startUpDetailsWebAppLink: "/start-up-details",
  startUpDetails: "/start-up-details-page",
  invest: "/invest-page",
  mentor: "/mentorship",
  mentorDetails: "/mentor-details-page",
  mentorDetailsWebAppLink: "/mentor-details",
  mentorChatBox: "/chat",
  userChatBox: "/user-chat",
  investWebAppLink: "/invest",
  mentorWebAppLink: "/mentor",
  raiseCapital: "/raise-capital-page",
  raiseCapitalWebAppLink: "/raise-capital",
  knowledgeBase: "/Insights",
  knowledgeBaseWebAppLink: "/knowledge-base",
  knowledgeBaseBlogDetail: "/Insights",
  knowledgeBaseWebAppLinkBlogDetail: "/web-blog",
  faq: "/faq-page",
  faqWebAppLink: "/faq",
  privacyPolicy: "/privacy-policy-page",
  privacyPolicyWebAppLink: "/privacy-policy",
  termsAndConditions: "/terms-of-use-page",
  termsAndConditionsWebAppLink: "/terms-of-use",
  loginSignup: "/login",
  contactUs: "/contact-us-page",
  knowBeforeYouInvest: "/know-before-you-invest-page",
  career: "/career-page",
  careerDetailsPage: "/career-details",
  knowBeforeYouInvestWebAppLink: "/know-before-you-invest",
  glossary: "/glossary-page",
  glossaryWebAppLink: "/glossary",
  investPaymentWebAppLink: "/invest-payment",
  profileWebAppLink: "/profile",
  lightPaperLink: "/light-paper",
  newLoginSignUpLink: `${process.env.REACT_APP_BACKEND_URL}/connect/cognito`,
  newLoginSignUpLinkSignUp: `${process.env.REACT_APP_COGNITO_SIGN_UP_URL}`,

  // newLoginSignUpLink: "https://staging.vedaslabs.io/sign-up",
};
