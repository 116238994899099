import React from "react";

/**
 * SkipButton Component
 * 
 * Overview:
 * The SkipButton component is a button that allows users to skip certain sections or content within the application. It provides a way for users to quickly navigate to the next relevant section without going through the entire content. This component is commonly used in scenarios where there is a long form or a multi-step process.
 * 
 * Details:
 * - The SkipButton component renders a button element with a customizable class name and text.
 * - It accepts the following props:
 *    - extraClassName: A string representing additional CSS class names to be applied to the button element.
 *    - text: The text to be displayed inside the button.
 *    - handleOnClickSkipButton: A function that will be called when the button is clicked.
 * 
 * Dependencies:
 * The SkipButton component does not have any external dependencies.
 */
export default function SkipButton({
  extraClassName,
  text,
  handleOnClickSkipButton,
}) {
  return (
    <>
      <button
        className={`vl-common-skip-btn ${extraClassName}`}
        onClick={handleOnClickSkipButton}
      >
        <span>{text}</span>
      </button>
    </>
  );
}

SkipButton.defaultProps = {
  text: "Skip",
  extraClassName: "",
};
