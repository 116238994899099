/* eslint-disable array-callback-return */
/* eslint-disable no-self-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import { backendUrl } from "../../../web-app-store/actions/config";
import BackArrow from "../../../assets/imgs/desktop/icon/back-button.png";
import { Link } from "react-router-dom";
import {
  mentor,
  mentorDetails,
  profileWebAppLink,
} from "../header-footer/pageLinks";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMentors,
  getChatMenuData,
} from "../../../web-app-store/actions/mentorAction";
import ResponsiveChatMenu from "./ResponsiveChatMenu";

/**
 * ChatMenu Component
 *
 * Overview:
 * The ChatMenu component is responsible for rendering the chat menu section of the application. It displays a list of chat conversations and allows the user to select a chat to view and interact with.
 *
 * Details:
 * - The component receives several props including setSelectedChat, selectedChat, myData, isProfileShow, isChatMenuShow, extraClass, conversationLength, newMsgReceived, setClientSocketId, setNewMsgToggle, newMsgToggle, setChatMenu, chatMenu, userChatMenu, and manageMentor. These props are used to manage the state and behavior of the component.
 * - The component uses the useState and useEffect hooks from React to manage state and perform side effects. It also uses the useDispatch and useSelector hooks from the Redux library to interact with the application state.
 * - The component renders a Card component from a UI library to display the chat menu section. It conditionally renders a mentor profile section and a chat section based on the values of the isProfileShow and isChatMenuShow props.
 * - The component includes event handlers and helper functions to handle chat selection, update chat menu data, and handle new messages.
 *
 * Dependencies:
 * - React, useState, useEffect from the React library
 * - useDispatch, useSelector from the Redux library
 * - Card, ListGroup, Image components from a UI library
 */
const ChatMenu = ({
  setSelectedChat,
  selectedChat,
  myData,
  isProfileShow,
  isChatMenuShow,
  extraClass,
  conversationLength,
  newMsgReceived,
  setClientSocketId,
  setNewMsgToggle,
  newMsgToggle,
  setChatMenu,
  chatMenu,
  userChatMenu,
  manageMentor,
}) => {
  const [messageLength, setMessageLength] = useState();
  const [mentorDetails, setMentorDetails] = useState();
  const dispatch = useDispatch();
  const [flName, setFlName] = useState();
  const mentorRecord = localStorage.getItem("mentorData");
  const mentorRecords = useSelector((state) => state.mentor.allMentors);
  const newChatMenu = useSelector((state) => state.chatMenu);

  useEffect(() => {
    setMessageLength(conversationLength);
  }, []);

  useEffect(() => {
    if (!isEmpty(mentorRecord)) {
      setMentorDetails(JSON.parse(mentorRecord));
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    dispatch(getChatMenuData(myData?.chat_infoId));
    dispatch(getAllMentors());
  }, []);
  useEffect(() => {
    if (!isEmpty(newChatMenu.chatMenu)) {
      setChatMenu(newChatMenu?.chatMenu?.chat_menu);
    }
  }, [newChatMenu]);

  const handleSelectChat = (obj) => {
    setSelectedChat(obj);
    setClientSocketId(obj?.ClientSocketId);
    if (myData?.interest !== "Mentoring") {
      if (!isEmpty(mentorRecords)) {
        let mentorData = mentorRecords?.find(
          (ele) => ele?.user?.username === obj.username
        );
        localStorage.setItem("mentorData", JSON.stringify(mentorData));
      }
    }
  };

  useEffect(() => {
    let chatMenuData;
    if (window.performance) {
      if (performance.navigation.type === 1) {
        chatMenuData = chatMenu?.find((ele, i) => i === 0);
      } else {
        chatMenuData = chatMenu?.find((ele) => {
          if (ele?.username === mentorDetails?.user?.username) {
            return ele;
          }
        });
      }
    }
    if (!isEmpty(chatMenuData)) {
      handleSelectChat(chatMenuData);
    }
  }, [chatMenu]);

  const handleNoNewMsgArr = (item) => {
    setSelectedChat(item);
    setClientSocketId(item?.ClientSocketId);
    chatMenu?.map((obj) => {
      if (obj.username === item.username) {
        obj.username = obj.username;
        obj.lastMessage = false;
        obj.uid = obj.uid;
        obj.ClientSocketId = obj.ClientSocketId;
        obj.profilePhoto = obj.profilePhoto;
        obj.Name = obj.Name;
        obj.MessageDataBaseId = obj.MessageDataBaseId;
        obj.profilePhoto = obj.profilePhoto;
      }
    });
    if (myData?.interest !== "Mentoring") {
      if (!isEmpty(mentorRecords)) {
        let mentorData = mentorRecords?.find(
          (ele) => ele?.user?.username === item.username
        );
        localStorage.setItem("mentorData", JSON.stringify(mentorData));
      }
    }
  };

  useEffect(() => {
    let flName = myData?.firstname + " " + myData?.lastname;
    setFlName(flName);
  }, []);

  const newMsgArr = chatMenu?.filter((obj) =>
    newMsgReceived?.includes(obj.username)
  );

  const msgArr = chatMenu?.filter((val) => !newMsgArr.includes(val));
  return (
    <>
      {!isEmpty(chatMenu) &&
        (window.innerWidth <= 991 ? (
          <ResponsiveChatMenu
            {...{ chatMenu, selectedChat, handleSelectChat, handleNoNewMsgArr }}
          />
        ) : (
          <Card
            className={`col-12 col-xl-3 col-lg-4 col-md-12 col-sm-12 p-0 h-auto overflow-auto ${extraClass}`}
          >
            <div>
              <div className="py-3 pl-3 mb-3 chat-section">
                {myData?.interest === "Mentoring" ? (
                  <Link to={mentor}>
                    <div className="back-button">
                      <i class="fa fa-angle-left"></i>
                    </div>
                  </Link>
                ) : (
                  <Link to={mentor}>
                    <div className="back-button">
                      <i class="fa fa-angle-left"></i>
                    </div>
                  </Link>
                )}
                <p className="m-0 chat-title">Chat</p>
              </div>
              {chatMenu?.length > 0 ? (
                <ListGroup variant="flush" className="px-4">
                  {chatMenu?.map((obj, i) => {
                    return i === 0 ? (
                      <ListGroup.Item
                        className={
                          selectedChat?.Name === obj?.Name
                            ? "active-chat px-3"
                            : "list-group-item px-3"
                        }
                        key={obj.ChatId + i}
                        onLoad={() => handleSelectChat(obj)}
                        onClick={() => handleNoNewMsgArr(obj)}
                      >
                        {!isEmpty(obj?.profilePhoto) ? (
                          <Image
                            src={obj?.profilePhoto}
                            className="mentor-image rounded-circle"
                          />
                        ) : (
                          <div className="mentor-image rounded-circle fl-name text-uppercase">
                            {obj?.Name.split(" ")[0].charAt(0) +
                              obj?.Name.split(" ")[1].charAt(0)}
                          </div>
                        )}
                        <div className="data">
                          <p className="mentor-name">{obj?.Name}</p>
                          <p className="mentor-msg">{obj?.lastMessage}</p>
                        </div>
                        <span
                          className={obj?.lastMessage && "notification"}
                        ></span>
                      </ListGroup.Item>
                    ) : (
                      <ListGroup.Item
                        className={
                          selectedChat?.Name === obj?.Name
                            ? "active-chat px-3"
                            : "list-group-item px-3"
                        }
                        key={obj.ChatId + i}
                        onClick={() => handleNoNewMsgArr(obj)}
                      >
                        {!isEmpty(obj?.profilePhoto) ? (
                          <Image
                            src={obj?.profilePhoto}
                            className="mentor-image rounded-circle"
                          />
                        ) : (
                          <div className="mentor-image rounded-circle fl-name text-uppercase">
                            {obj?.Name.split(" ")[0].charAt(0) +
                              obj?.Name.split(" ")[1].charAt(0)}
                          </div>
                        )}
                        <div className="data">
                          <p className="mentor-name">{obj?.Name}</p>
                          <p className="mentor-msg">{obj?.lastMessage}</p>
                        </div>
                        <span
                          className={obj?.lastMessage && "notification"}
                        ></span>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              ) : (
                <ListGroup variant="flush" className="px-4">
                  <ListGroup.Item
                    className={
                      myData ? "active-chat px-3" : "list-group-item px-3"
                    }
                  >
                    {!isEmpty(myData?.user_profile?.url) ? (
                      <Image
                        src={myData?.user_profile?.url}
                        className="mentor-image rounded-circle"
                      />
                    ) : (
                      <div className="mentor-image rounded-circle fl-name text-uppercase">
                        {myData?.firstname?.charAt(0) +
                          myData?.lastname?.charAt(0)}
                      </div>
                    )}

                    <div className="data">
                      <p className="mentor-name">
                        {myData?.firstname} {myData?.lastname}
                      </p>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              )}
            </div>
          </Card>
        ))}
    </>
  );
};

export default ChatMenu;
