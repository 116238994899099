import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import InputFieldEmailTextPassword from "./InputFieldEmailTextPassword";
import CurrencyInputCommon from "./CurrencyInputCommon";
import { useDispatch } from "react-redux";

import { ReserveAmountAction } from "../../../web-app-store/actions/exploreAction";
import SaveButton from "./SaveButton";

/**
 * ReserveModal Component
 * 
 * Overview:
 * The ReserveModal component is responsible for rendering a modal window that allows users to reserve an offering. It provides a form for users to enter their personal information and the amount they want to reserve. The component handles form validation, error handling, and dispatches an action to reserve the amount.
 * 
 * Details:
 * - The ReserveModal component receives the following props:
 *   - open: A boolean value indicating whether the modal is open or closed.
 *   - onClose: A function to be called when the modal is closed.
 *   - offeringId: The ID of the offering.
 *   - refreshData: A function to refresh the data after a successful reservation.
 *   - maxAmount: The maximum amount that can be reserved.
 *   - organizationId: The ID of the organization.
 * - The component maintains the following state:
 *   - loading: A boolean value indicating whether the reservation is being processed.
 *   - reserve: An object representing the reservation data, including offering ID, user information, and amount.
 *   - reserveErrors: An object containing validation errors for the reservation form fields.
 *   - showSuccess: A boolean value indicating whether the reservation was successful.
 * - The component uses the useDispatch hook from the react-redux library to dispatch the ReserveAmountAction.
 * - The component provides form validation using the checkErrors function, which checks for errors in the reserve object and returns an errors object.
 * - The component uses the ValidateEmail function to validate the email field.
 * - The component handles form input changes using the onChange function, which updates the reserve object.
 * - The component renders a Modal component from the react-responsive-modal library, which displays the reservation form or a success message based on the showSuccess state.
 * 
 * Dependencies:
 * - react
 * - react-redux
 * - react-responsive-modal
 */
export default function ReserveModal({
  open,
  onClose,
  offeringId,
  refreshData,
  maxAmount,
  organizationId,
}) {
  const initialState = {
    offering_Id: String(offeringId),
    first_name: "",
    last_name: "",
    email: "",
    amount: "",
    Organizations: { id: organizationId },
  };
  const [loading, setLoading] = useState(false);
  const [reserve, setReserve] = useState(initialState);
  const [reserveErrors, setReserveErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const dispatch = useDispatch();

  const reservefun = () => {
    let errors = checkErrors();
    if (Object.keys(errors).length > 0) {
      setReserveErrors(errors);
    } else {
      setLoading(true);
      setReserveErrors({});
      // alert(JSON.stringify(reserve));
      dispatch(ReserveAmountAction(reserve, callBack));
    }
  };
  const callBack = (status, errAmount) => {
    setLoading(false);
    if (status === 200) {
      refreshData();
      setShowSuccess(true);
    } else {
      refreshData();
      let errors = {};
      errors.amount = `Remaining amount is ${errAmount}$ to achive goal.`;
      setReserveErrors(errors);
    }
  };

  const checkErrors = () => {
    const tempReserve = { ...reserve };

    let errors = {};
    // if (reserve.amount > maxAmount)
    //   errors.amount = `Remaining amount is ${maxAmount}$ to achive goal.`;
    if (Number(reserve.amount) < 1000)
      errors.amount = "The amount must at least meet the minimum of $1000.";
    if (!ValidateEmail(reserve.email))
      errors.email = "valid email is required.";
    Object.keys(tempReserve).map((name) => {
      if (tempReserve[name] == "" || tempReserve[name] == null)
        errors[name] = `${name.split("_").join(" ")} is required.`;
    });
    return errors;
  };

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const onChange = (event) => {
    setReserveErrors({});
    setReserve({
      ...reserve,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setShowSuccess(false);
          setReserve(initialState);
          setReserveErrors({});
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: showSuccess
            ? "customModal reserveModal--reserve-success"
            : "customModal reserveModal--profile-review reservemodal-popup",
          // closeButton: "customCloseButton",
        }}
      >
        {!showSuccess ? (
          <div className="reserveContainer">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="First Name"
              name="first_name"
              value={reserve.first_name}
              onChange={onChange}
              type="text"
              error={reserveErrors.first_name}
            />
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Last Name"
              name="last_name"
              // value=""
              value={reserve.last_name}
              onChange={onChange}
              type="text"
              error={reserveErrors.last_name}
            />
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Email Address"
              name="email"
              value={reserve.email}
              onChange={onChange}
              type="email"
              error={reserveErrors.email}
            />
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Amount"
              name="amount"
              value={reserve.amount}
              onChange={(value) =>
                onChange({
                  target: { name: "amount", value: value.value },
                })
              }
              error={reserveErrors.amount}
            />
            <div className="text-center form-textlist">
              <span>
                Reserving gives you a priority allocation if the deal launches.
                A “reservation” involves no obligation or commitment to invest
                by you or the company.
              </span>
            </div>
            <div className="text-center">
              <SaveButton
                text="Reserve"
                // extraClassName="raise-capital-startup-btn"
                handleOnClickSaveButton={reservefun}
                loading={loading}
              />
              {/* <button
                className="raise-capital-startup-btn"
                onClick={reservefun}
              >
                <span>Reserve</span>
              </button> */}
            </div>
          </div>
        ) : (
          <div class="d-flex flex-column align-items-center">
            <img
              className="m-auto img-Sucess"
              width={100}
              src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png"
              alt=""
            />
            <h1 className="title-Sucess">Sucess</h1>
            <p className="text-center text-Sucess">
              Thank you for expressing interest in this offering. When the deal
              goes live we will let you know!
            </p>
          </div>
        )}
      </Modal>
    </>
  );
}
