/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllMentors } from "../../../../../web-app-store/actions/mentorAction";
import { backendUrl } from "../../../../../web-app-store/actions/config";
import { socket } from "../../../../../config/web-sockets";
import isEmpty from "../../../../../web-app-store/validations/is-empty";
import ChatBox from "../../ChatBox/ChatBox";
import { mentor, userChatBox } from "../../../header-footer/pageLinks";
import ChatMenu from "../../../Chat/chatMenu";
import Lottie from "react-lottie-player";
import ChatSidebar from "../../../Chat/Sidebar/ChatSidebar";
import Skeleton from "react-loading-skeleton";
import SideNavbar from "../../../../../web-app-components/desktop/header/SideNavbar";
import Footer from "../../../header-footer/Footer";
import { message } from "../../../../../web-app-components/desktop/header/activeMenu";
import PageTitle from "../../../../../web-app-components/desktop/header/PageTitle";
import chatImage from "../../../../../web-app-assets/imgs/desktop/backgrounds/chat.json";

/**
 * UserChatBox Component
 *
 * Overview:
 * The UserChatBox component is responsible for managing the chat functionality for the user. It allows the user to interact with mentors, view and send messages, and manage their chat conversations.
 *
 * Details:
 * - The component uses various state variables to manage the chat functionality, such as socketId, chatToggle, manageMentor, conversation, chatId, isDataBaseEmpty, selectedChat, isChatShow, newMsgReceived, isChatMenuShow, messageInfoId, clientSocketId, dataChatId, newMsgToggle, and userChatMenu.
 * - The component makes use of the Redux useDispatch hook to dispatch actions for getting all mentors.
 * - The component also uses the useEffect hook to handle side effects, such as fetching data, setting up socket connections, and updating state variables.
 * - The component renders the ChatSidebar and ChatMenu components based on the window width.
 * - The component conditionally renders the ChatBox component based on the selectedChat and isChatShow state variables.
 *
 * Usage:
 * To use the UserChatBox component, include it in the desired location within your application. Make sure to provide the necessary props, such as myData and mentorRecord.
 *
 * Dependencies:
 * - React: The component is built using React and relies on its features and hooks.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - axios: The component uses axios for making HTTP requests to fetch message data.
 * - socket.io-client: The component uses socket.io-client to establish socket connections for real-time chat functionality.
 * - Skeleton: The component uses the Skeleton component for rendering loading skeletons when no chat is selected.
 * - Other components: The component depends on the ChatSidebar, ChatMenu, and ChatBox components for rendering the chat UI.
 */
const UserChatBox = () => {
  const [socketId, setSocketId] = useState(null);
  const [chatToggle, setChatToggle] = useState(false);
  const [manageMentor, setManageMentor] = useState({});
  const [conversation, setConversation] = useState([]);
  const [chatId, setChatId] = useState("");
  const [isDataBaseEmpty, setIsDataBaseEmpty] = useState(true);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatShow, setIsChatShow] = useState(false);
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [newMsgReceived, setNewMsgReceived] = useState([]);
  const [isChatMenuShow, setIsChatMenuShow] = useState(true);
  const [messageInfoId, setMessageInfoId] = useState("");
  const [clientSocketId, setClientSocketId] = useState("");
  const [dataChatId, setDataChatId] = useState("");
  const [newMsgToggle, setNewMsgToggle] = useState(true);
  const [userChatMenu, setUserChatMenu] = useState([]);
  const dispatch = useDispatch();
  const myData = JSON.parse(localStorage.getItem("userData"));
  const mentorRecord = localStorage.getItem("mentorData");

  useEffect(() => {
    dispatch(getAllMentors());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(mentorRecord)) {
      setManageMentor(JSON.parse(mentorRecord));
    }
  }, []);

  useEffect(() => {
    if (selectedChat) {
      try {
        localStorage.setItem("chatId", selectedChat?.ChatId);
        setIsChatShow(true);
        setChatId(selectedChat?.ChatId);
        socket.emit("join chat", selectedChat?.ChatId);
        setDataChatId(selectedChat?.MessageDataBaseId);
        socket.emit("setup", myData.socketId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [selectedChat]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (chatId && dataChatId) {
      try {
        let { data } = await axios.get(
          `${backendUrl}/message-data/${dataChatId}`
        );
        setMessageInfoId(data?.id);
        data?.messages ? setIsDataBaseEmpty(false) : setIsDataBaseEmpty(true);
        setConversation(data?.messages || []);
      } catch (error) {
        console.log("Talking First Time");
      }
    }
  }, [chatId, dataChatId]);

  useEffect(() => {
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
  }, [socket]);

  useEffect(() => {
    if (chatToggle && chatId) {
      socket.emit("setup", myData.socketId);
      socket.emit("join chat", chatId);
    }
  }, [chatToggle, chatId]);

  return (
    <React.Fragment>
      <SideNavbar />
      <PageTitle imgPath={message.icon} pageTitle={message.title} />
      <div className="mentor">
        <div className="card-main-wrap">
          <div className="row d-flex main-chat-box m-0 justify-content-center">
            <ChatMenu
              setSelectedChat={setSelectedChat}
              selectedChat={selectedChat}
              myData={myData}
              setChatMenu={setUserChatMenu}
              chatMenu={userChatMenu}
              isProfileShow={isProfileShow}
              isChatMenuShow={isChatMenuShow}
              setClientSocketId={setClientSocketId}
              newMsgReceived={newMsgReceived}
              newMsgToggle={newMsgToggle}
              setNewMsgToggle={setNewMsgToggle}
              manageMentor={mentorRecord}
            />
            <div className="col-12 col-xl-9 col-lg-8 col-md-12 col-sm-12 p-0">
              {isEmpty(userChatMenu) && (
                <div className="start-conversation-msg main-start-conversation">
                  <Lottie
                    loop
                    animationData={chatImage}
                    play
                    className="conversation-image"
                  />
                  <p className="start-messaging-text">
                    Please add a mentor to start a conversation.
                  </p>
                </div>
              )}
              {isChatShow && (
                <ChatBox
                  myData={myData}
                  socketId={socketId}
                  selectedChat={selectedChat}
                  manageMentor={mentorRecord}
                  conversation={conversation}
                  setConversation={setConversation}
                  setChatMenu={setUserChatMenu}
                  setChatId={setChatId}
                  chatId={chatId}
                  isDataBaseEmpty={isDataBaseEmpty}
                  dataChatId={dataChatId}
                  setDataChatId={setDataChatId}
                  messageInfoId={messageInfoId}
                  setChatToggle={setChatToggle}
                  chatToggle={chatToggle}
                  setIsChatShow={setIsChatShow}
                  isChatShow={isChatShow}
                  clientSocketId={clientSocketId}
                  setSelectedChat={setSelectedChat}
                  newMsgReceived={newMsgReceived}
                  setNewMsgReceived={setNewMsgReceived}
                  setNewMsgToggle={setNewMsgToggle}
                  newMsgToggle={newMsgToggle}
                  setUserChatMenu={setUserChatMenu}
                  userChatMenu={userChatMenu}
                  user
                />
              )}
            </div>
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </React.Fragment>
  );
};

export default UserChatBox;
