/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Image,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../assets/imgs/desktop/common/dark_logo.png";
import { logoutAction } from "../../../web-app-store/actions/authAction";
import store from "../../../web-app-store/store";
import {
  SET_CREATE_MENTOR,
  SET_CREATE_USER,
} from "../../../web-app-store/types";
import {
  home,
  aboutUs,
  invest,
  raiseCapital,
  knowledgeBase,
  knowBeforeYouInvest,
  faq,
  newLoginSignUpLink,
  contactUs,
  career,
  mentor,
  exploreWebAppLink,
  profileWebAppLink,
  newLoginSignUpLinkSignUp,
} from "../header-footer/pageLinks";

let isMentor = JSON.parse(localStorage.getItem("isMentor"));
let isUser = JSON.parse(localStorage.getItem("isUser"));
if (isMentor) {
  store.dispatch({
    type: SET_CREATE_MENTOR,
    payload: isMentor,
  });
}
if (isUser) {
  store.dispatch({
    type: SET_CREATE_USER,
    payload: isUser,
  });
}

/**
 * NewNavbar Component
 *
 * Overview:
 * The NewNavbar component is responsible for rendering the navigation bar of the application. It provides a responsive layout and handles user interactions such as scrolling, logging in, and logging out. The component is used to display different menu options based on the user's authentication status and role.
 *
 * Details:
 * - The component uses state variables to manage the appearance and behavior of the navigation bar, such as changing the class based on scroll position and toggling the menu on mobile devices.
 * - It retrieves user data from local storage and renders the user's profile picture and options for logging out and accessing the user's profile.
 * - The component includes sub-components such as Navbar, Container, Nav, and Image from the react-bootstrap library to structure and style the navigation bar.
 * - Props: None
 *
 * Usage:
 * To use the NewNavbar component, import it into the desired file and include it in the JSX code where the navigation bar should be rendered. Make sure to wrap the component within a Router component if using react-router for navigation.
 *
 * Dependencies:
 * - react: The core library for building the user interface.
 * - react-bootstrap: A UI library that provides pre-built components and styling for React applications.
 * - react-redux: A library for managing the application state using the Redux pattern.
 * - react-router-dom: A library for handling routing and navigation in React applications.
 * - useHistory: A hook from react-router-dom that provides access to the browser's history object.
 * - useDispatch: A hook from react-redux that provides access to the Redux store's dispatch function.
 * - useState: A hook from React that allows functional components to manage state.
 * - useEffect: A hook from React that allows functional components to perform side effects.
 * - JSON: A built-in object in JavaScript for working with JSON data.
 * - localStorage: A built-in object in JavaScript for storing data in the browser's local storage.
 * - logo: An image file representing the application's logo.
 * - logoutAction: A custom action creator function for logging out the user.
 * - profileWebAppLink: A link to the user's profile page.
 * - mentor: A link to the mentorship page.
 * - invest: A link to the investment page.
 * - knowledgeBase: A link to the knowledge base page.
 * - raiseCapital: A link to the page for raising capital.
 * - newLoginSignUpLink: A link to the login/signup page.
 */
const NewNavbar = () => {
  const [changeClass, setChangeClass] = useState(false);
  const [isChangeClass, setIsChangeClass] = useState(false);
  const [isLogOut, setIsLogOut] = useState(false);
  const [isUser, setIsUser] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const history = useHistory();

  const [createMentor, setCreateMentor] = useState(false);

  const handleSignUp = () => {
    if (setCreateMentor) {
      setCreateMentor(false);
    }

    if (isUser) {
      setIsUser(false);
    }
  };

  useEffect(() => {
    localStorage.setItem("isMentor", createMentor);
    localStorage.setItem("isUser", isUser);
  }, [createMentor, isUser]);

  const changeClassOnScroll = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 80) {
      setChangeClass(true);
    } else {
      setChangeClass(false);
    }
  };
  window.addEventListener("scroll", changeClassOnScroll);

  const onSelect = (action) => {
    if (action === "logout") {
      dispatch(logoutAction(history));
    }
  };

  return (
    <>
      <Navbar
        expand="lg"
        className={
          changeClass || isChangeClass
            ? "nav-bar box-shadow navbar-expand-lg navbar-light px-3 py-0"
            : "nav-bar navbar-expand-lg px-3 py-0"
        }
      >
        <Container fluid className="flex-row py-3 px-0">
          {/* <div className="hidden-navbar"> */}
          <Navbar.Brand href="/">
            <img src={logo} alt="vedslabs logo" className="header-logo" />
            {/* <img src={logo} className="header-logo" /> */}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={
              isChangeClass
                ? () => setIsChangeClass(false)
                : () => setIsChangeClass(true)
            }
          />
          {/* </div> */}
          <Navbar.Collapse id="navbarScroll">
            <Nav className="navbar-menu">
              {/* <Nav.Link href={aboutUs}>About Us</Nav.Link> */}
              <Nav.Link href={invest}>Invest</Nav.Link>
              <Nav.Link href={mentor}>Mentorship</Nav.Link>
              <Nav.Link href={knowledgeBase}>knowledge Base</Nav.Link>
              <Nav.Link href={raiseCapital}>Raise Capital</Nav.Link>
              {/* <Nav.Link href={career}>Career</Nav.Link> */}
              {/* <Nav.Link href={exploreWebAppLink}>Explore</Nav.Link>
              <Nav.Link href={contactUs}>Contact Us</Nav.Link> */}
            </Nav>
            {/* <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                className="sign-up-btn d-flex justify-content-center align-items-center"
                href={newLoginSignUpLink}
                onClick={handleSignUp}
              >
                SIGN UP
              </Nav.Link>
            </Nav> */}
          </Navbar.Collapse>
          {userData ? (
            <div className="login-profile">
              {userData?.user_profile?.url ? (
                <Image
                  src={userData?.user_profile?.url}
                  alt="mentor-profile-image"
                  className="mentor-profile-picture"
                  onClick={
                    isLogOut
                      ? () => setIsLogOut(false)
                      : () => setIsLogOut(true)
                  }
                />
              ) : (
                <>
                  <span
                    className="mentor-profile-picture mentor-profile-picture--text"
                    onClick={
                      isLogOut
                        ? () => setIsLogOut(false)
                        : () => setIsLogOut(true)
                    }
                  >
                    {userData?.firstname?.charAt(0)}
                  </span>
                </>
              )}
              {isLogOut ? (
                <div className="sign-out-btn">
                  <Link to={profileWebAppLink} className="user-profile">
                    <i className="far fa-user"></i>
                    My Profile
                  </Link>
                  <button
                    className="log-out"
                    onClick={() => onSelect("logout")}
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    Sign out
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <Nav className="me-auto my-2 my-lg-0 d-flex" navbarScroll>
              <Nav.Link
                className="log-in-btn d-flex justify-content-center align-items-center"
                href={newLoginSignUpLink}
                onClick={handleSignUp}
              >
                LOGIN
              </Nav.Link>
              <Nav.Link
                className="sign-up-btn d-flex justify-content-center align-items-center"
                href={newLoginSignUpLink}
                // href={newLoginSignUpLinkSignUp}
                onClick={handleSignUp}
              >
                SIGN UP
              </Nav.Link>
            </Nav>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default NewNavbar;
