import React, { useState, useEffect } from "react";
import backToTop from "../../../assets/imgs/desktop/icon/backToTop.svg";

/**
 * ScrollTopArrow Component
 * 
 * Overview:
 * The ScrollTopArrow component is a button that appears when the user scrolls down the page. It allows the user to quickly scroll back to the top of the page.
 * 
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage the state of the button's visibility.
 * - The checkScrollTop function is called on scroll events to determine whether to show or hide the button based on the scroll position.
 * - The scrollTop function is called when the button is clicked, and it uses the scrollTo method to smoothly scroll back to the top of the page.
 * - The button's visibility is controlled by the showScroll state variable, which is updated based on the scroll position.
 *  */
const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      className="scrollTop"
      onClick={scrollTop}
      style={{ display: showScroll ? "flex" : "none" }}
    >
      <img src={backToTop} alt="backToTop" className="img-fluid" />
      <span></span>
    </button>
  );
};

export default ScrollTopArrow;
