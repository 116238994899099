import React from "react";
// import { Link } from "react-router-dom";
import Navbar from "../header-footer/Navbar";

import { newLoginSignUpLink } from "../header-footer/pageLinks";

export default function InvestFoldOne() {
  return (
    <div className="invest-fold-one-div">
      <Navbar />
      <div className="invest-fold-one-text-div">
        <h5>
          Join a Social Venture Ecosystem Open to Everyone
        </h5>
          {/* <Link to="/sign-up"> */}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            <div className="start-btn start-btn--invest">
              <span>
                START INVESTING &#62;
              </span>
            </div>
          </a>
          {/* </Link> */}
      </div>
    </div>
  );
}
