import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import { activeSidebarMenu, mentorPageTitle } from "../header/activeMenu";

// api
import { useSelector } from "react-redux";
import CardFollowButton from "../explore/CardFollowButton";
import MainHeaderMenu from "./MainHeaderMenu";

/**
 * PageTitleStartUpDetails Component
 * 
 * Overview:
 * The PageTitleStartUpDetails component is responsible for rendering the page title and follow button for the start-up details page. It is used within the header component of the application.
 * 
 * Details:
 * - The component receives two props: pageTitle (string) and orgId (string). The pageTitle prop represents the title of the page, and the orgId prop represents the ID of the organization associated with the start-up.
 * - The component uses the useHistory hook from the react-router-dom library to access the browser history object.
 * - The component also uses the useSelector hook from the react-redux library to access the authentication state from the Redux store.
 * - The component uses the useState and useEffect hooks from the React library to manage the displayOnWebsite state. The displayOnWebsite state is initially set to true and is updated based on the current pathname in the browser history. If the pathname does not include "start-up-details-page", the displayOnWebsite state is set to false.
 * - The component conditionally renders the MainHeaderMenu component if the user is authenticated.
 * - The component renders the page title and a back button using the history.goBack() function.
 * - The component conditionally renders the CardFollowButton component if the displayOnWebsite state is false.
 * - The component does not render the Mentor section.
 *  */
export default function PageTitleStartUpDetails({ pageTitle, orgId }) {
  const history = useHistory();

  // api
  const auth = useSelector((state) => state.auth);
  const [displayOnWebsite, setDisplayOnWebsite] = useState(true);
  useEffect(() => {
    if (!history.location.pathname.includes("start-up-details-page")) {
      setDisplayOnWebsite(false);
    }
  }, [history.location.pathname]);

  return (
    <>
      {/* {auth.isAuthenticated && <MainHeaderMenu />}
      <div className="titletext-top row mx-0 flex-nowrap align-items-center vl-topnavbar-page-title__title-div">
        <button onClick={() => history.goBack()}>
          <i className="fa fa-chevron-left vl-topnavbar__title-back-arrow"></i>
        </button>
        <h1 className="font-36-roboto-bold vl-topnavbar__title">{pageTitle}</h1>
      </div> */}
      {!displayOnWebsite ? (
        <CardFollowButton
          orgId={orgId}
          classNameFollowingBtn="follow-btn-inner"
          classNameFollowBtn="follow-btn-inner"
        />
      ) : (
        ""
      )}

      {/* <Link to="/"> */}
      {/* <div className="row mx-0 flex-nowrap align-items-center font-18-roboto-bold vl-topnavbar__follow-btn">
        <div className="vl-topnavbar__title-icon-block">
          <img src={mentorPageTitle.icon} alt={pageTitle} />
        </div>
        Mentor
      </div> */}
      {/* </Link> */}
    </>
  );
}
