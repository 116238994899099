import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import SideNavbar from "../header/SideNavbar";
import { activeSidebarMenu, investPageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import Footer from "../../../components/desktop/header-footer/Footer";
import { calcTotalInvestmentLimit } from "./calcTotalInvestmentLimit";
import {
  investWebAppLink,
  knowBeforeYouInvestWebAppLink,
  termsAndConditionsWebAppLink,
  faqWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";

import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import CurrencyInputCommon from "../common/CurrencyInputCommon";
import SaveButton from "../common/SaveButton";
import InvestLimitCalculatorCard from "./InvestLimitCalculatorCard";

import { useDispatch, useSelector } from "react-redux";
import {
  postFundAmericaProceedPaymentInvestor,
  createInvestor,
  updateInvestor,
  getInvestorByUserId,
} from "../../../web-app-store/actions/investAction";
import { getInvestorPortfolioList } from "../../../web-app-store/actions/investorPortfolioAction";
import { validateInvestorCalculator } from "../../../web-app-store/validations/invest/investorCalculator";
import { validateInvestorProceedToPayment } from "../../../web-app-store/validations/invest/investorProceedToPayment";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { jobIndustryOptionArray } from "../common/allDropdownOptions";
import { useLocation } from "react-router-dom";
import NetWorthCalcNeedHelp from "./NetWorthCalcNeedHelp";
import { createParty } from "../../../web-app-store/actions/proceedPaymentAction";
import { AcceptingInvestMentAmount } from "../../../web-app-store/actions/exploreAction";
import Modal from "react-responsive-modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**

InvestProceedToPaymentNew Component
Overview:
The InvestProceedToPaymentNew component is responsible for handling the logic and rendering of the "Proceed to Payment" section in the investment flow. It is a part of the larger UI-2 application and is specifically designed for the desktop version of the web app.

Details:
This component manages the state related to the payment process, including the selected payment method, payment amount, and any additional payment details.
It renders the necessary UI elements for the payment section, such as payment method selection, payment amount input, and any additional payment details input fields.
The component also handles user interactions and events related to the payment process, such as validating the payment amount, updating the selected payment method, and submitting the payment.
It may include sub-components for specific payment methods or additional payment details, depending on the requirements of the application.

 */

export default function InvestProceedToPayment() {
  // api
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [frontEndErrors, setFrontEndErrors] = useState({});
  let userData = JSON.parse(localStorage.getItem("userData"));

  const [valuesRowOne, setValuesRowOne] = useState({
    companyName: "",
    investmentAmount: "",
    signatureBox: "",
    returnOfInvestmentCheckbox: false,
    resellCSCheckbox: false,
    crowdfundingInvolvesRiskCheckbox: false,
    min_investment: 0,
  });

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setValuesRowOne({
        ...valuesRowOne,
        companyName: location.state.detail.company_name,
        min_investment: location.state.min_investment,
      });
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getInvestorByUserId());
  }, [dispatch]);

  const investor = useSelector((state) => state.invest.investor);
  const loggedUser = useSelector((state) => state.auth.user);
  const create_party = useSelector(
    (state) => state.proceedPayment.create_party
  );

  const initialState = {
    Investor: { id: "" },
    amount: "",
    Offerings: { id: location.state.offeringID },
  };

  const [open, setOpen] = useState(false);
  const [raiseAmount, setRaiseAmount] = useState(initialState);
  const [show, setShow] = useState(false);
  const [iframeSize, setIframeSize] = useState();

  // useEffect(() => {
  //   if (open) {
  //     if (window.innerWidth == 1440) {
  //       setIframeSize(1270);
  //     } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
  //       setIframeSize(900);
  //     } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
  //       setIframeSize(590);
  //     } else if (window.innerWidth >= 425 && window.innerWidth < 768) {
  //       setIframeSize(370);
  //     } else if (window.innerWidth >= 375 && window.innerWidth < 425) {
  //       setIframeSize(327);
  //     } else if (window.innerWidth >= 300 && window.innerWidth < 375) {
  //       setIframeSize(270);
  //     } else {
  //       setIframeSize(1400);
  //     }
  //   }
  // }, [open]);

  useEffect(() => {
    if (!isEmpty(investor)) {
      let investorData = investor[0];
      setRaiseAmount({ ...raiseAmount, Investor: { id: investorData.id } });
    }
  }, [investor]);

  const callBack = (status) => {
    if (status === 200) {
      setOpen(true);
      // window.open(location.state.invest_now_url, "_blank");
    }
  };

  const openModal = () => {
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
    setOpen(false);
  };

  // const handleCreateInvestorProfile = () => {
  //   history.push("/invest");
  // };

  const [values, setValues] = useState({
    yearlyIncome: "",
    networth: "",
    jobTitle: "",
    jobIndustry: jobIndustryOptionArray[0],
    spouse_income_included: false,
    invested_outside_vlabs: false,
    spouse_acc_vedaslab: false,
    fname: "",
    lname: "",
    email: "",
    amountInvestedOutsideOfVL: "",
    amountInvestedOnVL: "",
  });

  const [isProceedPaymentFormValid, setIsProceedPaymentFormValid] =
    useState(false);

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];

      setValues({
        ...values,
        yearlyIncome: !isEmpty(data.income) ? data.income : "",
        networth: !isEmpty(data.networth) ? data.networth : "",
        amountInvestedOutsideOfVL: !isEmpty(data.amount_invested_outside)
          ? data.amount_invested_outside
          : "",
        amountInvestedOnVL: !isEmpty(data.amount_invested_vlabs)
          ? data.amount_invested_vlabs
          : "",
        jobTitle: data.job_title,
        jobIndustry: !isEmpty(data.job_industry)
          ? {
              value: data.job_industry,
              label: data.job_industry,
            }
          : jobIndustryOptionArray[0],
        spouse_income_included: data.spouse_income_included,
        invested_outside_vlabs: data.invested_outside_vlabs,
        spouse_acc_vedaslab: data.spouse_acc_vedaslab,
        fname: data.spouse_first_name,
        lname: data.spouse_last_name,
        email: data.spouse_email,
      });
    }
  }, [investor]);

  const [remainingInvestmentLimit, setRemainingInvestmentLimit] = useState();
  useEffect(() => {
    if (!isEmpty(investor)) {
      let remainingInvestmentLimit = 0;

      // console.log("--- data ---", investor[0]);
      // console.log("in case1", investor[0].income >= 200000 && (!investor[0].spouseIncomeIncluded));
      // console.log("in case2", investor[0].income >= 300000 && (investor[0].spouseIncomeIncluded));

      if (
        (investor[0].income >= 200000 && !investor[0].spouseIncomeIncluded) ||
        (investor[0].income >= 300000 && investor[0].spouseIncomeIncluded) ||
        investor[0].net_worth >= 1000000
      ) {
        // console.log("in case3");
      } else {
        let totalInvestmentLimit = calcTotalInvestmentLimit(
          investor[0].networth,
          investor[0].income
        );
        // console.log("net worth", investor[0].networth);
        // console.log("annual income", investor[0].income);
        // console.log("in case4", totalInvestmentLimit);
        remainingInvestmentLimit =
          totalInvestmentLimit -
          investor[0].amount_invested_outside -
          investor[0].amount_invested_vlabs;

        remainingInvestmentLimit = Math.round(remainingInvestmentLimit);
      }
      setRemainingInvestmentLimit(remainingInvestmentLimit);
      let formObj = {
        ...valuesRowOne,
        remainingInvestmentLimit: remainingInvestmentLimit,
      };

      const { isValid, errors } = validateInvestorProceedToPayment(formObj);
      if (isValid) {
        setFrontEndErrors({});
        setIsProceedPaymentFormValid(true);
      } else {
        setFrontEndErrors(errors);
        setIsProceedPaymentFormValid(false);
      }
    }
  }, [investor, valuesRowOne.investmentAmount]);

  const handleChangeInvestmentAmount = (element) => {
    // setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      investmentAmount:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });

    let formObj = {
      ...valuesRowOne,
      investmentAmount:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };

    const { isValid, errors } = validateInvestorProceedToPayment(formObj);
    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }

    setRaiseAmount({ ...raiseAmount, amount: element.floatValue });
  };

  useEffect(() => {
    // fund america
    const script = document.createElement("script");
    script.src = "https://my-sandbox.fundamerica.com/widgets/invest_now.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(getInvestorPortfolioList());
  }, [dispatch]);

  const investorPortfolio = useSelector(
    (state) => state.invest.investorPortfolio
  );

  // USEEFFECT FOR CHECKING ALL INVESTOR AND CALLING FUNDRAISER EVENTS
  useEffect(() => {
    if (!isEmpty(investorPortfolio)) {
      let currentInvestor = investorPortfolio.filter(
        (a) => a.offering_id === location.state.offeringId
      );

      if (!isEmpty(currentInvestor)) {
        // set investors
        if (currentInvestor[0].investor_id) {
          let setInvestors = document.createEvent("Event");
          setInvestors.initEvent("fa.investnow.setInvestors", true, false);
          setInvestors.ids = currentInvestor[0].investor_id;
          document.body.dispatchEvent(setInvestors);
        }

        // set authorization
        if (currentInvestor[0].ach_authorization_id) {
          let setAchAuthorizations = document.createEvent("Event");
          setAchAuthorizations.initEvent(
            "fa.investnow.setAchAuthorizations",
            true,
            false
          );
          setAchAuthorizations.ids = currentInvestor[0].ach_authorization_id;
          document.body.dispatchEvent(setAchAuthorizations);
        }
      }
    }

    if (!isEmpty(investor)) {
      let investorData = investor[0];

      document.addEventListener("fa.investnow.open", function (e) {
        let strAmount = valuesRowOne.investmentAmount.toString();
        let strAnnualIncome = values.yearlyIncome.toString();
        let strNetWorth = values.networth.toString();
        console.log("Investor birthday", investorData.birth_date);
        function format(inputDate) {
          var date = new Date(inputDate);
          if (!isNaN(date.getTime())) {
            // Months use 0 index.
            return (
              date.getMonth() +
              1 +
              "/" +
              date.getDate() +
              "/" +
              date.getFullYear()
            );
          }
        }
        let dob = format(investorData.birth_date);
        console.log("FA dob format", dob);
        let autofillEvent = document.createEvent("Event");
        autofillEvent.initEvent("fa.investnow.autofill", true, false);
        autofillEvent.investor = {
          type: "person",
          payment_method: "ach",
          name: `${investorData?.first_name || ""} ${
            investorData?.last_name || ""
          }`,
          city: investorData.location,
          region: investorData.location,
          email: investorData.email,
          phone: investorData.phone_number,
          // passing dob as 1996-03-05 mm/dd/yyyy
          date_of_birth: moment(userData.DOB, "YYYY-MM-DD")
            .format("YYYY-MM-DD")
            .toString(),
          contact_name: `${investorData?.first_name || ""} ${
            investorData?.last_name || ""
          }`,
          amount: strAmount,
          annual_income: strAnnualIncome,
          net_worth: strNetWorth,
          // tax_id_number: "999999999",
          // street_address_1: "800 Third Avenue",
          // street_address_2: "22nd Floor",
          // postal_code: "10022",
          // region_formed_in: investorData.nationality,
          // financial_adviser_name: "Jimmy Johnson",
        };
        document.body.dispatchEvent(autofillEvent);
      });
    }
  }, [
    investor,
    isProceedPaymentFormValid,
    valuesRowOne.investmentAmount,
    investorPortfolio,
  ]);

  useEffect(() => {
    document.addEventListener("fa.investnow.close", function (e) {
      let clearEvent = document.createEvent("Event");
      clearEvent.initEvent("fa.investnow.clear", true, false);
      document.body.dispatchEvent(clearEvent);
    });
  }, []);

  const [prevInvestmentId, setPrevInvestmentId] = useState();

  useEffect(() => {
    const callBackFundAmericaProceedPaymentInvestor = (status) => {
      if (status === 200) {
        document.addEventListener("fa.investnow.close", function (e) {
          window.location.assign(`${investWebAppLink}`);
          localStorage.setItem(
            "activeVLInvestAfterProfileSetupPageTabIndex",
            1
          );
        });
      }
    };

    // investor SUCCESS evnt -> -> will trigger when invest now popup sucess
    document.addEventListener("fa.investnow.success", function (e) {
      let investorInvestmentID = e.investment_id;
      setPrevInvestmentId(investorInvestmentID);

      let investNowSandBox = JSON.parse(
        localStorage.getItem("ls.invest_now.sandbox")
      );

      if (investorInvestmentID !== prevInvestmentId) {
        let formData = {
          user: userData.id,
          investment_id: investorInvestmentID,
          offering_id: location.state.offeringId,

          ach_authorization_id:
            investNowSandBox.invest_now_investment.ach_authorization_id,
          investor_id: investNowSandBox.invest_now_investment.investor_id,
          amount: investNowSandBox.invest_now_investment.amount,

          // amount_received
        };

        // console.log("dispatch");
        dispatch(
          postFundAmericaProceedPaymentInvestor(
            formData,
            callBackFundAmericaProceedPaymentInvestor
          )
        );
      }
    });
    return () => {
      localStorage.removeItem("ls.invest_now.sandbox");
    };
  }, [dispatch]);

  /*============================================================
                           handler
  ============================================================*/

  const handleChangeSignatureBox = (e) => {
    // setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      signatureBox: e.target.value,
    });

    let formObj = {
      ...valuesRowOne,
      signatureBox: e.target.value,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };

    const { errors, isValid } = validateInvestorProceedToPayment(formObj);

    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }
  };

  const handleChangeRowOne = (e) => {
    setFrontEndErrors({});
    setValuesRowOne({
      ...valuesRowOne,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (event) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeJobIndustry = (jobIndustry) => {
    setValues({
      ...values,
      jobIndustry,
    });
  };

  const handleCheckboxChange = (e) => {
    setFrontEndErrors({});
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        fname: "",
        lname: "",
        email: "",
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleCheckboxChangeCrowdFunding = (e) => {
    setFrontEndErrors({});
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        amountInvestedOutsideOfVL: "",
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleChangeYearlyIncome = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      yearlyIncome:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeNetworth = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      networth:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeAmountInvestedOutsideOfVL = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      amountInvestedOutsideOfVL:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const [isClickEditForm, setIsClickEditForm] = useState(false);

  const handleOnClickEditFormButton = () => {
    setIsClickEditForm(true);
  };

  const handleProceedPayment = () => {
    if (!isEmpty(investor)) {
      dispatch(AcceptingInvestMentAmount(raiseAmount, callBack));
    } else {
      const { errors, isValid } = validateInvestorCalculator(values);

      if (!isValid) {
        setFrontEndErrors(errors);
        toast.error("Please enter networth and yearly income.");
        setIsClickEditForm(true);
      } else if (isValid) {
        setFrontEndErrors({});
        setLoading(true);

        let formData = {
          user: { id: userData.id },
          income: !isEmpty(values.yearlyIncome) ? values.yearlyIncome : null,
          networth: !isEmpty(values.networth) ? values.networth : null,
          amount_invested_outside: !isEmpty(values.amountInvestedOutsideOfVL)
            ? values.amountInvestedOutsideOfVL
            : null,
          job_title: values.jobTitle,
          job_industry: values.jobIndustry.value,
          spouse_income_included: values.spouse_income_included,
          invested_outside_vlabs: values.invested_outside_vlabs,
          spouse_acc_vedaslab: values.spouse_acc_vedaslab,
          spouse_first_name: values.fname,
          spouse_last_name: values.lname,
          spouse_email: values.email,
        };

        if (isEmpty(investor)) {
          dispatch(createInvestor(formData, callBackStatus));
        } else {
          dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
        }
      }
    }
  };

  const callBackStatus = (status, data) => {
    setLoading(false);
    if (status === 200) {
      setIsClickEditForm(false);
      if (data) {
        let details = { ...raiseAmount };
        let id = { id: data.id };
        details.Investor = id;
        // setRaiseAmount({
        //   ...raiseAmount,
        //   Investor: id,
        // });
        dispatch(AcceptingInvestMentAmount(details, callBack));
      }
    }
  };

  const handleOnClickSaveAndNext = () => {
    const { errors, isValid } = validateInvestorCalculator(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    } else if (isValid) {
      setFrontEndErrors({});
      setLoading(true);

      let formData = {
        user: { id: userData.id },
        income: !isEmpty(values.yearlyIncome) ? values.yearlyIncome : null,
        networth: !isEmpty(values.networth) ? values.networth : null,
        amount_invested_outside: !isEmpty(values.amountInvestedOutsideOfVL)
          ? values.amountInvestedOutsideOfVL
          : null,
        job_title: values.jobTitle,
        job_industry: values.jobIndustry.value,
        spouse_income_included: values.spouse_income_included,
        invested_outside_vlabs: values.invested_outside_vlabs,
        spouse_acc_vedaslab: values.spouse_acc_vedaslab,
        spouse_first_name: values.fname,
        spouse_last_name: values.lname,
        spouse_email: values.email,
      };

      if (isEmpty(investor)) {
        dispatch(createInvestor(formData, callBackStatus));
      } else {
        dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
      }
    }
  };

  const handleCheckboxChangeCrowdFundingProceedPayment = (e) => {
    setValuesRowOne({
      ...valuesRowOne,
      [e.target.name]: e.target.checked,
    });

    let formObj = {
      ...valuesRowOne,
      [e.target.name]: e.target.checked,
      remainingInvestmentLimit: remainingInvestmentLimit,
    };
    const { isValid, errors } = validateInvestorProceedToPayment(formObj);
    if (isValid) {
      setFrontEndErrors({});
      setIsProceedPaymentFormValid(true);
    } else {
      setFrontEndErrors(errors);
      setIsProceedPaymentFormValid(false);
    }
  };

  const renderSpouseInfo = () => {
    return (
      <>
        <h3 className="invest-calculator-font-24-medium">Spouse Information</h3>
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="First Name"
          name="fname"
          value={values.fname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.fname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Last Name"
          name="lname"
          value={values.lname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.lname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Email Address"
          name="email"
          value={values.email}
          onChange={handleChange}
          type="email"
          error={frontEndErrors.email}
        />
      </>
    );
  };

  // renderCalculator
  const renderCalculator = () => {
    return (
      <>
        <div className="row mx-0 px-0 pt-32 flex-wrap invest-personal-tab-panel invest-personal-tab-panel--calculator">
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Yearly Income ($)"
              name="yearlyIncome"
              value={values.yearlyIncome}
              onChange={handleChangeYearlyIncome}
              error={frontEndErrors.yearlyIncome}
            />

            <div className="vl-net-worth-with-note-block">
              <CurrencyInputCommon
                containerClassName="vl-form-input"
                label="Net Worth ($)"
                name="networth"
                value={values.networth}
                onChange={handleChangeNetworth}
                error={frontEndErrors.networth}
              />
              <NetWorthCalcNeedHelp />
            </div>

            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Job Title"
              name="jobTitle"
              value={values.jobTitle}
              onChange={handleChange}
              type="text"
              error={frontEndErrors.jobTitle}
            />
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Job Industry</label>
              <Select
                value={values.jobIndustry}
                onChange={handleChangeJobIndustry}
                options={jobIndustryOptionArray}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
            </div>
            <div className="vl-checkbox vl-checkbox--invest-calculator">
              <Checkbox
                onChange={handleCheckboxChange}
                value={values.spouse_income_included}
                checked={values.spouse_income_included}
                defaultChecked={true}
                name="spouse_income_included"
                id="spouse_income_included"
              />
              <label htmlFor="spouse_income_included">
                Including my spouse’s income and net worth.
              </label>
            </div>

            {values.spouse_income_included && renderSpouseInfo()}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-45">
              <Checkbox
                onChange={handleCheckboxChangeCrowdFunding}
                value={values.invested_outside_vlabs}
                checked={values.invested_outside_vlabs}
                defaultChecked={false}
                name="invested_outside_vlabs"
                id="invested_outside_vlabs"
              />
              <label htmlFor="invested_outside_vlabs">
                I have invested in crowdfunding in the past 12 months.
              </label>
            </div>

            {values.invested_outside_vlabs && (
              <div className="mt-45 pt-15">
                <CurrencyInputCommon
                  containerClassName="vl-form-input"
                  label="Amount Invested Outside of VedasLabs ($)"
                  name="amountInvestedOutsideOfVL"
                  value={values.amountInvestedOutsideOfVL}
                  onChange={handleChangeAmountInvestedOutsideOfVL}
                  error={frontEndErrors.amountInvestedOutsideOfVL}
                />
              </div>
            )}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-38">
              <Checkbox
                onChange={handleCheckboxChange}
                value={values.spouse_acc_vedaslab}
                checked={values.spouse_acc_vedaslab}
                defaultChecked={false}
                name="spouse_acc_vedaslab"
                id="spouse_acc_vedaslab"
              />
              <label htmlFor="spouse_acc_vedaslab">
                My spouse has an account with VedasLabs.
              </label>
            </div>
          </div>
          <div className="col-md-1 px-0"></div>
          <div className="col-md-5 col-12 px-0 vl-invest-calculator-form-limit-card-outer-div pt-0">
            <InvestLimitCalculatorCard
              net_worth={values.networth}
              annual_income={values.yearlyIncome}
              amountInvestedOutsideOfVL={values.amountInvestedOutsideOfVL}
              amountInvestedOnVL={values.amountInvestedOnVL}
              spouseIncomeIncluded={values.spouse_income_included}
            />
          </div>
        </div>
      </>
    );
  };

  // renderDisplayCalculator
  const renderDisplayCalculator = () => {
    return (
      <>
        <div className="row mx-0 px-0 pt-32 flex-wrap invest-personal-tab-panel invest-personal-tab-panel--calculator">
          <div className="col-md-6 col-12 px-0">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Yearly Income ($)"
              name="yearlyIncome"
              value={values.yearlyIncome}
              isDisabled={true}
            />
            <div className="vl-net-worth-with-note-block">
              <CurrencyInputCommon
                containerClassName="vl-form-input"
                label="Net Worth ($)"
                name="networth"
                value={values.networth}
                isDisabled={true}
              />
              <NetWorthCalcNeedHelp />
            </div>
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Job Title"
              name="jobTitle"
              value={values.jobTitle}
              isDisabled={true}
              type="text"
            />
            <div className="vl-form-input  vl-form-input--bordered-select-div">
              <label htmlFor="">Job Industry</label>
              <Select
                value={values.jobIndustry}
                isDisabled={true}
                className="vl-select-container"
                classNamePrefix="vl-select"
                placeholder={values.jobIndustry}
                isSearchable={true}
              />
            </div>
            <div className="vl-checkbox vl-checkbox--invest-calculator">
              <Checkbox
                checked={values.spouse_income_included}
                value={values.spouse_income_included}
                disabled={true}
                name="spouse_income_included"
                id="spouse_income_included"
              />
              <label htmlFor="spouse_income_included">
                Including my spouse’s income and net worth.
              </label>
            </div>

            {values.spouse_income_included && renderSpouseInfo()}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-45">
              <Checkbox
                checked={values.invested_outside_vlabs}
                disabled={true}
                name="invested_outside_vlabs"
                id="invested_outside_vlabs"
              />
              <label htmlFor="invested_outside_vlabs">
                I have invested in crowdfunding in the past 12 months.
              </label>
            </div>

            {values.invested_outside_vlabs && (
              <div className="mt-45 pt-15">
                <CurrencyInputCommon
                  containerClassName="vl-form-input"
                  label="Amount Invested Outside of VedasLabs ($)"
                  name="amountInvestedOutsideOfVL"
                  value={values.amountInvestedOutsideOfVL}
                  isDisabled={true}
                />
              </div>
            )}

            <div className="vl-checkbox vl-checkbox--invest-calculator mt-38">
              <Checkbox
                checked={values.spouse_acc_vedaslab}
                disabled={true}
                name="spouse_acc_vedaslab"
                id="spouse_acc_vedaslab"
              />
              <label htmlFor="spouse_acc_vedaslab">
                My spouse has an account with VedasLabs.
              </label>
            </div>
          </div>
          <div className="col-1 px-0"></div>
          <div className="col-md-5 col-12 px-0 vl-invest-calculator-form-limit-card-outer-div pt-0">
            <InvestLimitCalculatorCard
              net_worth={values.networth}
              annual_income={values.yearlyIncome}
              amountInvestedOutsideOfVL={values.amountInvestedOutsideOfVL}
              amountInvestedOnVL={values.amountInvestedOnVL}
            />
          </div>
        </div>
      </>
    );
  };

  // renderThirdFold
  const renderThirdFold = () => {
    return (
      <>
        <div className="vl-checkbox custom-checkbox vl-checkbox--invest-calculator mt-45">
          <Checkbox
            onChange={handleCheckboxChangeCrowdFundingProceedPayment}
            value={valuesRowOne.returnOfInvestmentCheckbox}
            checked={valuesRowOne.returnOfInvestmentCheckbox}
            defaultChecked={false}
            name="returnOfInvestmentCheckbox"
            id="returnOfInvestmentCheckbox"
          />
          <label htmlFor="returnOfInvestmentCheckbox">
            I understand the restrictions on my ability to cancel an investment
            commitment and obtain a return of my investment.
            {!isEmpty(frontEndErrors.returnOfInvestmentCheckbox) ? (
              <span className="error-message ml-2">
                {frontEndErrors.returnOfInvestmentCheckbox}
              </span>
            ) : (
              <span className="error-message opacity-0">error</span>
            )}
          </label>
        </div>

        <div className="vl-checkbox custom-checkbox vl-checkbox--invest-calculator mt-45">
          <Checkbox
            onChange={handleCheckboxChangeCrowdFundingProceedPayment}
            value={valuesRowOne.resellCSCheckbox}
            checked={valuesRowOne.resellCSCheckbox}
            defaultChecked={false}
            name="resellCSCheckbox"
            id="resellCSCheckbox"
          />
          <label htmlFor="resellCSCheckbox">
            I understand that it may be difficult to resell crowdfunding
            securities.
            {!isEmpty(frontEndErrors.resellCSCheckbox) ? (
              <span className="error-message ml-2">
                {frontEndErrors.resellCSCheckbox}
              </span>
            ) : (
              <span className="error-message opacity-0">error</span>
            )}
          </label>
        </div>
        <div className="vl-checkbox custom-checkbox vl-checkbox--invest-calculator mt-45">
          <Checkbox
            onChange={handleCheckboxChangeCrowdFundingProceedPayment}
            value={valuesRowOne.crowdfundingInvolvesRiskCheckbox}
            checked={valuesRowOne.crowdfundingInvolvesRiskCheckbox}
            defaultChecked={false}
            name="crowdfundingInvolvesRiskCheckbox"
            id="crowdfundingInvolvesRiskCheckbox"
          />
          <label htmlFor="crowdfundingInvolvesRiskCheckbox">
            I understand crowdfunding involves risk and I should not invest more
            than I can afford to lose.
            {!isEmpty(frontEndErrors.crowdfundingInvolvesRiskCheckbox) ? (
              <span className="error-message ml-2">
                {frontEndErrors.crowdfundingInvolvesRiskCheckbox}
              </span>
            ) : (
              <span className="error-message opacity-0">error</span>
            )}
          </label>
        </div>

        <p className="vl-calculator-payment-note">
          I acknowledge that the entry of my name in the field below represents
          an e-signature as detailed in our{" "}
          <Link
            to={termsAndConditionsWebAppLink}
            //  rel="noopener noreferrer" target="_blank"
          >
            <u>Terms of Use</u>
          </Link>{" "}
          and confirms that I have reviewed the educational materials in{" "}
          <Link
            to={knowBeforeYouInvestWebAppLink}
            // rel="noopener noreferrer"
            // target="_blank"
          >
            <u>Know Before You Invest</u>
          </Link>{" "}
          and{" "}
          <Link
            to={faqWebAppLink}
            // rel="noopener noreferrer" target="_blank"
          >
            <u>FAQs</u>
          </Link>
          .
        </p>

        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Signature Box (Type Your Full Name)"
          name="signatureBox"
          value={valuesRowOne.signatureBox}
          onChange={handleChangeSignatureBox}
          type="text"
          error={frontEndErrors.signatureBox}
        />
      </>
    );
  };

  const [isPaymentProceed, setIsPaymentProceed] = useState(false);
  /*============================================================
                      API CALLING
  ============================================================*/
  const handelCreateParty = () => {
    let formData = {
      clientID: "yS98A0lVfQ1Eavi",
      developerAPIKey: "Wx0bPmRmTItwvjBlhPQGscVQZOvhNsH4zb4",
      domicile: "non-resident",
      firstName: loggedUser.firstname,
      lastName: loggedUser.lastname,
      dob: loggedUser.DOB,
      primCountry: "India",
      primAddress1: loggedUser.Address,
      primCity: "surat",
      primState: "gujarat",
      primZip: "395004",
      emailAddress: loggedUser.email,
    };
    formData = {
      clientID: "yS98A0lVfQ1Eavi",
      developerAPIKey: "Wx0bPmRmTItwvjBlhPQGscVQZOvhNsH4zb4",
      domicile: "non-resident",
      firstName: loggedUser.firstname,
      lastName: loggedUser.lastname,
      dob: "12-28-1996",
      primCountry: "India",
      primAddress1: loggedUser.Address,
      primCity: "surat",
      primState: "gujarat",
      primZip: "395004",
      emailAddress: loggedUser.email,
    };
    dispatch(createParty(formData, history));
  };

  useEffect(() => {
    if (create_party.success === "1") {
      let formData = {
        clientID: "",
        developerAPIKey: "",
        accountId: "",
      };
      //dispatch(getUserCreditCardInfo(formData))
    }
  }, [create_party]);
  /*============================================================
                           render
  ============================================================*/
  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[2]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          <PageTitle
            imgPath={investPageTitle.icon}
            pageTitle={investPageTitle.title}
          />

          {isPaymentProceed === false ? (
            <>
              <div className="vl-invest-payment-page-div">
                <h3 className="vl-invest-payment-text-1">Your Investment</h3>
                <div className="row mx-0 align-items-start pt-32">
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Company Name"
                      name="companyName"
                      value={valuesRowOne.companyName}
                      onChange={handleChangeRowOne}
                      type="text"
                      error={frontEndErrors.companyName}
                      isReadOnly={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName={
                        isEmpty(frontEndErrors.investmentAmount)
                          ? "vl-form-input"
                          : "vl-form-input vl-form-input--errorColor"
                      }
                      label="Investment Amount ($)"
                      name="investmentAmount"
                      value={valuesRowOne.investmentAmount}
                      onChange={handleChangeInvestmentAmount}
                      error={frontEndErrors.investmentAmount}
                      placeholder={
                        "Investment Minimum is $" + valuesRowOne.min_investment
                      }
                    />
                  </div>
                </div>
                <hr />
                <h3 className="vl-invest-payment-text-1 pt-20">Questionaire</h3>

                <div className="vl-invest-payment-text-main">
                  <div className="vl-invest-payment-wrapp">
                    <p className="vl-invest-payment-text-2">
                      The SEC limits how much you can invest in any 12 month
                      period. The limit is calculated based on your net worth
                      and income.
                    </p>
                    <p className="vl-invest-payment-text-2">
                      The information collected below is stored securely and is
                      only used to calculate your investment limit.
                    </p>
                  </div>
                  <div className="payment-update-btn-div pr-0 row mx-0 justify-content-end">
                    {isClickEditForm ? (
                      <SaveButton
                        text="Update"
                        // loading={loading}
                        handleOnClickSaveButton={handleOnClickSaveAndNext}
                      />
                    ) : (
                      <SaveButton
                        text="Edit"
                        handleOnClickSaveButton={handleOnClickEditFormButton}
                      />
                    )}
                  </div>
                </div>

                {isClickEditForm
                  ? renderCalculator()
                  : renderDisplayCalculator()}
                <hr />
                {renderThirdFold()}
                <div className="proceed-to-payment-btn-div row mx-0 justify-content-end">
                  <div className="proceed-btn">
                    <button
                      //onClick={() => setIsPaymentProceed(true)}
                      onClick={handleProceedPayment}
                      className={`vl-common-save-btn`}
                      disabled={isProceedPaymentFormValid ? false : true}
                    >
                      <span>Proceed to Payment</span>
                    </button>

                    {/* <p className="vl-payment-font-button-note">
                      All payments are processed by Prime Trust.
                    </p> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="vl-invest-payment-page-div">
                <h3 className="vl-invest-payment-text-1">You Almost Done!!</h3>
                <div className="row mx-0 align-items-start pt-32">
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Company Name"
                      name="companyName"
                      value={valuesRowOne.companyName}
                      onChange={handleChangeRowOne}
                      type="text"
                      error={frontEndErrors.companyName}
                      isReadOnly={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName={
                        isEmpty(frontEndErrors.investmentAmount)
                          ? "vl-form-input"
                          : "vl-form-input vl-form-input--errorColor"
                      }
                      label="Investment Amount ($)"
                      name="investmentAmount"
                      value={valuesRowOne.investmentAmount}
                      onChange={handleChangeInvestmentAmount}
                      error={frontEndErrors.investmentAmount}
                      placeholder={
                        "Investment Minimum is $" + valuesRowOne.min_investment
                      }
                    />
                  </div>
                </div>
                <div className="row mx-0 px-0 pt-32 flex-wrap invest-personal-tab-panel invest-personal-tab-panel--calculator">
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="First Name"
                      name="companyName"
                      value={loggedUser.firstname || ""}
                      // onChange={handleChangeRowOne}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Middle Name"
                      name="middle_name"
                      value={loggedUser.firstname}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Last Name"
                      name="last_name"
                      value={loggedUser.lastname || ""}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Birth Date"
                      name="birth_date"
                      value={loggedUser.DOB}
                      isDisabled={true}
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className="vl-form-input  vl-form-input--bordered-select-div">
                      <label htmlFor="">Select Citizenship</label>
                      <Select
                        value={values.jobIndustry}
                        options={[
                          { value: "Employed", label: "Us Citizen" },
                          {
                            value: "U.S. Resident, non-U.S. Citizen",
                            label: "U.S. Resident, non-U.S. Citizen",
                          },
                          {
                            value:
                              "No, I am neither a U.S. citizen nor a U.S. resident",
                            label:
                              "No, I am neither a U.S. citizen nor a U.S. resident",
                          },
                        ]}
                        className="vl-select-container"
                        classNamePrefix="vl-select"
                        placeholder={values.jobIndustry}
                        isSearchable={true}
                      />
                    </div>
                    {values.invested_outside_vlabs && (
                      <div className="mt-45 pt-15">
                        <CurrencyInputCommon
                          containerClassName="vl-form-input"
                          label="Amount Invested Outside of VedasLabs ($)"
                          name="amountInvestedOutsideOfVL"
                          value={values.amountInvestedOutsideOfVL}
                          isDisabled={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className="vl-form-input  vl-form-input--bordered-select-div">
                      <label htmlFor="">Country</label>
                      <Select
                        value={values.jobIndustry}
                        options={[
                          { value: "Employed", label: "India" },
                          { value: "Not Employed", label: "USA" },
                          { value: "Retired", label: "Japan" },
                          { value: "Student", label: "Pakistan" },
                        ]}
                        className="vl-select-container"
                        classNamePrefix="vl-select"
                        placeholder={values.jobIndustry}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Mobile Number"
                      name="mobile_number"
                      value={"+" + loggedUser.phoneNumber}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Email"
                      name="email"
                      value={loggedUser.email}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Address 1"
                      name="address1"
                      value={loggedUser.Address}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Address 2"
                      name="address2"
                      value={loggedUser.Address}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="City"
                      name="city"
                      value="Surat"
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <InputFieldEmailTextPassword
                      containerClassName="vl-form-input"
                      label="Pin code"
                      name="pin_code"
                      value="395004"
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName="vl-form-input"
                      label="Current Annual Income"
                      name="currentAnnIncome"
                      value={values.networth}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName="vl-form-input"
                      label="Average Income (Past 2 Years)"
                      name="avgAnnIncome"
                      value={values.networth}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName="vl-form-input"
                      label="Household Annual Income"
                      name="currentHouseholdIncome"
                      value={values.networth}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName="vl-form-input"
                      label="Household Average Income (Past 2 Years)"
                      name="avgHouseholdIncome"
                      value={values.networth}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <CurrencyInputCommon
                      containerClassName="vl-form-input"
                      label="Household Networth (Excluding Primary Residence)"
                      name="householdNetworth"
                      value={values.networth}
                      isDisabled={true}
                    />
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className="vl-form-input  vl-form-input--bordered-select-div">
                      <label htmlFor="">Employment Status</label>
                      <Select
                        value={values.jobIndustry}
                        options={[
                          { value: "Employed", label: "Employed" },
                          { value: "Not Employed", label: "Not Employed" },
                          { value: "Retired", label: "Retired" },
                          { value: "Student", label: "Student" },
                        ]}
                        className="vl-select-container"
                        classNamePrefix="vl-select"
                        placeholder={values.jobIndustry}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0 py-3 d-flex">
                    <div className="col-6 px-0">
                      <label>Are you an accredited investor?</label>
                    </div>
                    <div className="col-6 px-0">
                      <input
                        className="mx-1"
                        type={"radio"}
                        value="Yes"
                        name="accredited"
                        id="yes"
                      />
                      <label className="px-1" for="yes">
                        Yes
                      </label>
                      <input
                        className="mx-1"
                        type={"radio"}
                        value="No"
                        name="accredited"
                        id="no"
                      />
                      <label className="px-1" for="no">
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className="vl-form-input  vl-form-input--bordered-select-div">
                      <label htmlFor="">
                        Please Select Your Payment Method
                      </label>
                      <Select
                        value={values.jobIndustry}
                        options={[
                          { value: "ACH Transfer", label: "ACH Transfer" },
                          { value: "Wire", label: "Wire" },
                          { value: "Check", label: "Check" },
                          {
                            value: "Credit/Debit card",
                            label: "Credit/Debit card",
                          },
                        ]}
                        className="vl-select-container"
                        classNamePrefix="vl-select"
                        placeholder={values.jobIndustry}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12 px-0">
                    <div className="my-4">
                      <label className="m-0">Document File Name</label>
                      <input
                        className="mx-3"
                        type="file"
                        id="file"
                        name="file"
                      />
                    </div>
                    <div className="">
                      *Note: You can only upload PDF, JPEG, JPG and PNG
                    </div>
                  </div>

                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Account Name:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="name" name="name">
                          vishal
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Email Address:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="email" id="email" name="email">
                          abc@gmail.com
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Phone Number:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="Number" id="phone" name="phone">
                          -
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Address:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="address" name="address">
                          abc, NYC
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Offering Name:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          America\'s Real Deal Reg CF
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Total Amount:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          $ 3,00.00
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Total Shares:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          3000
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Payment Method:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          Credit/Debit Card Transfer
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Name On Card:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          VISHAL PEDHADIYA
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Card Number:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          XXXXXXX1111
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Expiration Date:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          11/24
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Security Code:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          ....
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mt-3 px-0">
                    <div className="mb-2 d-flex justify-content-center">
                      <label className="col-6 px-5 d-flex justify-content-end">
                        Card Type:
                      </label>
                      <div className="col-md-6 px-5 col-6">
                        <label type="text" id="" name="">
                          VI
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="p-3 btn btn-primary"
                  onClick={() => handelCreateParty()}
                >
                  Invest Now
                </button>
              </div>
            </>
          )}
        </div>
        {/* <div>
          {!showSuccess && (
            <Modal
              open={show}
              onClose={() => onClose()}
              closeOnEsc={false}
              closeOnOverlayClick={false}
              center
              classNames={{
                overlay: "profileOverlay",
                modal: "customModal create_invest-profile-model-main",
              }}
            >
              <div class="d-flex flex-column align-items-center">
                <img
                  className="m-auto img-Sucess"
                  width={100}
                  src="/web-app-img/desktop/startup-illustrations/invest-startup.svg"
                  alt=""
                />
                <h1 className="title-Sucess">
                  First create your investor profile
                </h1>
                <div className="w-100 mt-5 text-center d-flex justify-content-around">
                  <button
                    onClick={() => handleCreateInvestorProfile()}
                    className="create-profile-btn"
                  >
                    Create investor profile
                  </button>
                  <button onClick={() => onClose()} className="cancel-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </div> */}

        <div>
          <ToastContainer position="top-center" autoClose={5000} />
        </div>

        <div className="invest-payment">
          {console.log(location.state.invest_now_url)}
          <Modal
            open={open}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            center
            classNames={{
              overlay: "investOverlay",
              modal: "investModal investModal--reserve-success",
              closeButton: "invest-model-close",
            }}
          >
            <h1 className="model-invest-text">
              Invest with <b>vedaslabs.io</b>
            </h1>
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title*/}
            <iframe
              className="model-invest"
              height={650}
              src={location.state.invest_now_url}
            ></iframe>
          </Modal>
        </div>

        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
