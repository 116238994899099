import React from "react";

/**
 * CustomRadioButton Component
 * 
 * Overview:
 * The CustomRadioButton component is a reusable component that renders a custom radio button with a label. It allows users to select a single option from a group of options.
 * 
 * Details:
 * - The component accepts the following props:
 *   - extraClassName: Additional CSS class name(s) to be applied to the component.
 *   - label: The label text to be displayed next to the radio button.
 *   - name: The name attribute of the radio button.
 *   - selectedRadioOption: The currently selected radio option.
 *   - handleOnClickRadioOption: The event handler function to be called when the radio button is clicked.
 *   - isDisabled: A boolean value indicating whether the radio button is disabled.
 * - The component renders a div element with the following structure:
 *   <div className={isDisabled ? `custom-radio-btn-block custom-radio-btn-block--disabled ${extraClassName}` : `custom-radio-btn-block ${extraClassName}`}>
 *     <input id={name} value={name} type="radio" checked={selectedRadioOption === name} onChange={handleOnClickRadioOption} disabled={isDisabled} />
 *     <span className="custom-radio-btn-block__circle-custom"></span>
 *     <label htmlFor={name} className={"font-24-semiBold"}>{label}</label>
 *   </div>
 * 
 * Dependencies:
 * - None
 */
export default function CustomRadioButton({
  extraClassName,
  label,
  name,
  selectedRadioOption,
  handleOnClickRadioOption,
  isDisabled,
}) {
  return (
    <div
      className={
        isDisabled
          ? `custom-radio-btn-block custom-radio-btn-block--disabled ${extraClassName}`
          : `custom-radio-btn-block ${extraClassName}`
      }
    >
      <input
        id={name}
        value={name}
        type="radio"
        checked={selectedRadioOption === name}
        onChange={handleOnClickRadioOption}
        disabled={isDisabled}
      />
      <span className="custom-radio-btn-block__circle-custom"></span>

      <label htmlFor={name} className={"font-24-semiBold"}>
        {label}
      </label>
    </div>
  );
}

CustomRadioButton.defaultProps = {
  extraClassName: "",
};
