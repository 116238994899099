import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RaiseCapitalBasic from "./RaiseCapitalBasic";
import RaiseCapitalMoreInfo from "./RaiseCapitalMoreInfo";
import RaiseSetUpFundraiser from "./RaiseSetUpFundraiser";
import RaiseCapitalTeam from "./RaiseCapitalTeam";
import RaiseCapitalPitchAndProduct from "./RaiseCapitalPitchAndProduct";

// api
import isEmpty from "../../../web-app-store/validations/is-empty";
import { useSelector } from "react-redux";

/**
 * RaiseCapitalProfile Component
 *
 * Overview:
 * The RaiseCapitalProfile component is responsible for rendering the profile setup page for raising capital. It allows users to apply their startup to the platform, complete their application, and navigate through different tabs to provide information about their startup.
 *
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage the active tab index and profile setup page count.
 * - The component retrieves and stores the active tab index and profile setup page count in the local storage using the localStorage API.
 * - The component uses the useSelector hook from Redux to access the raiseCapitalOrg state from the Redux store.
 * - The component conditionally renders different content based on the profileSetUpPageCount state.
 * - If the profileSetUpPageCount is 1, it renders the startup profile creation section with an image, title, description, and a button to apply.
 * - If the profileSetUpPageCount is 2, it renders the tab navigation section with multiple tabs for different sections of the application.
 * - Each tab panel is rendered based on the activeTabIndexVal state, which determines the currently selected tab.
 * - The component includes sub-components for each tab panel, such as RaiseCapitalBasic, RaiseCapitalPitchAndProduct, RaiseSetUpFundraiser, RaiseCapitalTeam, and RaiseCapitalMoreInfo.
 * - The handleOnSelectTab function is used to update the activeTabIndexVal state when a tab is selected.
 * - The handleOnClickCreateProfile function is used to update the profileSetUpPageCount state and store the activeVLRaiseCapitalSetupPageIndex in the local storage.
 *
 * Usage:
 * To use the RaiseCapitalProfile component, import it into the desired file and render it within the application. Make sure to provide the necessary Redux store and configure the sub-components for each tab panel.
 *
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - Redux: The component uses the useSelector hook to access the raiseCapitalOrg state from the Redux store.
 * - React-Redux: The component uses the useSelector hook to access the raiseCapitalOrg state from the Redux store.
 * - React-Router: The component uses the Link component from React-Router to navigate between different pages.
 * - React-Tab: The component uses the Tabs, TabList, Tab, and TabPanel components from React-Tab to render the tab navigation section.
 * - Other custom sub-components: The component includes sub-components for each tab panel, such as RaiseCapitalBasic, RaiseCapitalPitchAndProduct, RaiseSetUpFundraiser, RaiseCapitalTeam, and RaiseCapitalMoreInfo.
 */
export default function RaiseCapitalProfile() {
  useEffect(() => {
    return () => {
      /* remove activeVLRaiseCapitalTabIndex */
      localStorage.removeItem("activeVLRaiseCapitalTabIndex");
      /* remove activeVLRaiseCapitalSetupPageIndex */
      localStorage.removeItem("activeVLRaiseCapitalSetupPageIndex");
    };
  }, []);

  const [activeTabIndexVal, setActiveTabIndexVal] = useState(
    isNaN(parseInt(localStorage.getItem("activeVLRaiseCapitalTabIndex")))
      ? 0
      : parseInt(localStorage.getItem("activeVLRaiseCapitalTabIndex"))
  );

  const handleOnSelectTab = (val) => {
    setActiveTabIndexVal(val);
    localStorage.setItem("activeVLRaiseCapitalTabIndex", val);
  };

  const [profileSetUpPageCount, setProfileSetUpPageCount] = useState(
    isNaN(parseInt(localStorage.getItem("activeVLRaiseCapitalSetupPageIndex")))
      ? 1
      : parseInt(localStorage.getItem("activeVLRaiseCapitalSetupPageIndex"))
  );

  const handleOnClickCreateProfile = () => {
    setProfileSetUpPageCount(2);
    localStorage.setItem("activeVLRaiseCapitalSetupPageIndex", 2);
  };

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      handleOnClickCreateProfile();
    }
  }, [raiseCapitalOrg]);

  /*==========================================
                render
 ==========================================*/
  return (
    <>
      {profileSetUpPageCount === 1 ? (
        <>
          <div className="raise-capital-startup-profile-div">
            <div className="raise-capital-startup-profile-div__img-block">
              <img
                src="/web-app-img/desktop/startup-illustrations/raise-capital-startup.svg"
                alt=""
              />
            </div>
            <h2 className="raise-capital-font-42-montserrat-bold">
              Apply Your Startup to Our Platform
            </h2>
            <h5 className="raise-capital-font-20-montserrat-medium">
              To Raise Funds and Connect with Mentors and Investors
            </h5>
            <button
              className="raise-capital-startup-btn"
              onClick={handleOnClickCreateProfile}
            >
              <span>Apply Now</span>
            </button>
          </div>
        </>
      ) : (
        profileSetUpPageCount === 2 && (
          <div className="raise-capital-tab-outer-div raise_tab_capital_main">
            <h5 className="raise-capital-font-20-roboto-bold">
              Complete Your Application
            </h5>
            <div className="vl-tabs-explore vl-tabs-explore--raise-capital">
              <Tabs
                selectedIndex={activeTabIndexVal}
                onSelect={handleOnSelectTab}
              >
                <TabList>
                  <Tab disabled={activeTabIndexVal !== 0 ? true : false}>
                    <span>1.</span>Basics
                  </Tab>
                  <Tab disabled={activeTabIndexVal !== 1 ? true : false}>
                    <span>2.</span>Pitch &amp; Product
                  </Tab>

                  <Tab disabled={activeTabIndexVal !== 2 ? true : false}>
                    <span>3.</span>Funding Details
                  </Tab>
                  <Tab disabled={activeTabIndexVal !== 3 ? true : false}>
                    <span>4.</span>Team
                  </Tab>
                  {/* <Tab disabled={activeTabIndexVal !== 4 ? true : false}>
                    <span>5.</span>More Info
                  </Tab> */}
                </TabList>
                <TabPanel>
                  <RaiseCapitalBasic handleOnSelectTab={handleOnSelectTab} />{" "}
                </TabPanel>
                <TabPanel>
                  <RaiseCapitalPitchAndProduct
                    handleOnSelectTab={handleOnSelectTab}
                  />
                </TabPanel>
                <TabPanel>
                  <RaiseSetUpFundraiser handleOnSelectTab={handleOnSelectTab} />
                </TabPanel>
                <TabPanel>
                  <RaiseCapitalTeam handleOnSelectTab={handleOnSelectTab} />
                </TabPanel>
                {/* <TabPanel>
                  <RaiseCapitalMoreInfo handleOnSelectTab={handleOnSelectTab} />
                </TabPanel> */}
              </Tabs>
            </div>
          </div>
        )
      )}
    </>
  );
}
