import React, { Component } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import {
  home,
  aboutUs,
  invest,
  raiseCapital,
  knowledgeBase,
  faq,
  newLoginSignUpLink,
  contactUs,
  knowBeforeYouInvest,
  career,
  mentor,
} from "./pageLinks";
import menu from "../../../assets/imgs/desktop/common/menu.svg";

/**
 * BurgerMenu Component
 * 
 * Overview:
 * The BurgerMenu component is responsible for rendering a burger menu icon and a dropdown menu. It allows the user to navigate through different sections of the application.
 * 
 * Details:
 * - The component manages the state of the menu bar, keeping track of whether it is open or closed.
 * - The component uses the react-burger-menu library to render the dropdown menu.
 * - The menu items are rendered using the Link component from react-router-dom, allowing for easy navigation within the application.
 * - The component also includes a login/signup link and a contact us link.
 * 
 * Usage:
 * To use the BurgerMenu component, simply import it and place it in the desired location within your application. Make sure to provide the necessary props, such as the home, aboutUs, invest, mentor, career, raiseCapital, knowledgeBase, knowBeforeYouInvest, faq, loginSignup, and contactUs routes.
 * 
 * Dependencies:
 * - react-burger-menu: The component uses the react-burger-menu library to render the dropdown menu. Make sure to install this library before using the BurgerMenu component.
 * - react-router-dom: The component uses the Link component from react-router-dom to render the menu items as clickable links. Make sure to install this library before using the BurgerMenu component.
 */
export class BurgerMenu extends Component {
  constructor() {
    super();
    this.state = {
      isMenuBarOpen: false,
    };
  }

  /*========================================================

              react-burger-menu event handlers

   ========================================================*/

  handleOnStateChange = (stateVal) => {
    this.setState({ isMenuBarOpen: stateVal.isOpen });
  };

  closeMobileNavbar = () => {
    this.setState({ isMenuBarOpen: false });
  };

  // loginSignupHnadler = () => {
  //   let provider = "cognito";
  //   console.log("sd");
  //   window.location.href = `${backendUrl}/connect/${provider}`;
  // };

  render() {
    return (
      <div>
        <Menu
          right
          isOpen={this.state.isMenuBarOpen}
          onStateChange={(stateVal) => this.handleOnStateChange(stateVal)}
          customBurgerIcon={<img src={menu} alt="menu" />}
          customCrossIcon={<i className="fa fa-close close-icon" />}
          overlayClassName={"myOverlay"}
        >
          <h5 className="menu-item">
            <Link to={home}>HOME</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={aboutUs}>ABOUT US</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={invest}>INVEST</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={mentor}>MENTORSHIP</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={career}>CAREER</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={raiseCapital}>RAISE CAPITAL</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={knowledgeBase}>KNOWLEDGE BASE</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={knowBeforeYouInvest}>KNOW BEFORE YOU INVEST</Link>
          </h5>
          <h5 className="menu-item">
            <Link to={faq}>FAQs</Link>
          </h5>

          {/* <h5 onClick={this.loginSignupHnadler} className="menu-item"> */}
          {/* <Link to={loginSignup}> */}
          {/* LOGIN / SIGN UP */}
          {/* </Link> */}
          {/* </h5> */}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            <div className="menu-item">
              <span>LOGIN / SIGN UP</span>
            </div>
          </a>
          <h5 className="menu-item">
            <Link to={contactUs}>CONTACT US</Link>
          </h5>
        </Menu>
      </div>
    );
  }
}

export default BurgerMenu;
