import React from "react";
import { calcTotalInvestmentLimit } from "./calcTotalInvestmentLimit";

// api
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";

/**
 * InvestLimitCalculatorCard Component
 * 
 * Overview:
 * The InvestLimitCalculatorCard component is responsible for calculating and displaying the investment limits based on the user's net worth, annual income, and other investment details. It is used to provide users with information about their total investment limit and remaining investment limit.
 * 
 * Details:
 * - The component accepts the following props:
 *   - net_worth: The user's net worth.
 *   - annual_income: The user's annual income.
 *   - amountInvestedOutsideOfVL: The amount invested outside of VedasLabs in the last 12 months.
 *   - amountInvestedOnVL: The amount invested on VedasLabs in the last 12 months.
 *   - spouseIncomeIncluded: A boolean indicating whether the spouse's income is included in the calculations.
 * - The component calculates the total investment limit based on the user's net worth and annual income. If the user's income or net worth exceeds certain thresholds, there is no limit.
 * - The remaining investment limit is calculated by subtracting the amount invested outside of VedasLabs and the amount invested on VedasLabs from the total investment limit.
 * - The component renders three blocks:
 *   - Block 1: Displays the total investment limit.
 *   - Block 2: Displays the remaining investment limit.
 *   - Block 3: Displays the amount invested outside of VedasLabs.
 *   - Block 4: Displays the amount invested on VedasLabs.
 * - The component uses the DisplayCurrencyFormatString component to format currency values.
 * 
 * Dependencies:
 * - The component depends on the DisplayCurrencyFormatString component to format currency values.
 */
export default function InvestLimitCalculatorCard({
  net_worth,
  annual_income,
  amountInvestedOutsideOfVL,
  amountInvestedOnVL,
  spouseIncomeIncluded,
}) {

  let remainingInvestmentLimit =0;
  let totalInvestmentLimit = 0;
  let noLimit = "";
  if ((annual_income >= 200000 && (!spouseIncomeIncluded))
    || ((annual_income >= 300000) && (spouseIncomeIncluded))
    || net_worth >= 1000000) {
    noLimit = "No Limit";
  } else {
    totalInvestmentLimit = calcTotalInvestmentLimit(net_worth, annual_income);

    remainingInvestmentLimit =
      totalInvestmentLimit - amountInvestedOutsideOfVL - amountInvestedOnVL;

    remainingInvestmentLimit = Math.round(remainingInvestmentLimit);
  }
  return (
    <div className="invest-after-profile-setup-calculator-col2">
      <div className="invest-after-profile-setup-calculator-col2-row1">
        <h4>Invest Limit Calculator</h4>
      </div>
      <div className="invest-after-profile-setup-calculator-col2-row2">
        {/* block 1 */}
        <div className="invest-after-profile-setup-calculator-col2-row2-div">
          <h4 className="font-24-roboto-medium invest-after-profile-setup-calculator-col2-row2-text1">
            Total Investment Limit
          </h4>
          <h4 className="font-18-roboto-medium invest-after-profile-setup-calculator-col2-row2-text2">
            Based on your Income and net worth
          </h4>
          <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col2-row2-text3">
            {(totalInvestmentLimit) ? (
              <DisplayCurrencyFormatString value={totalInvestmentLimit} />
            ):
            noLimit && noLimit
            }
          </h4>
        </div>
        {/* block 2 */}
        <div className="invest-after-profile-setup-calculator-col2-row2-div invest-after-profile-setup-calculator-col2-row2-div--2">
          <h4 className="font-24-roboto-medium invest-after-profile-setup-calculator-col2-row2-text1">
            Remaining Investment Limit
          </h4>
          <h4 className="font-18-roboto-medium invest-after-profile-setup-calculator-col2-row2-text2">
            Based on your Income and net worth
          </h4>
          <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col2-row2-text3">
            {
              (remainingInvestmentLimit) ? (
              <span
                className={
                  remainingInvestmentLimit <= 0 ? "vl-text-color-red" : ""
                }
              >
                <DisplayCurrencyFormatString value={remainingInvestmentLimit} />
              </span>
            ) : (
              // <span className="vl-text-color-red">$0</span>
              noLimit && noLimit
            )}
          </h4>
        </div>
        {/* block 3 */}
        <div className="invest-after-profile-setup-calculator-col2-row2-div invest-after-profile-setup-calculator-col2-row2-div--3">
          <h4 className="font-24-roboto-medium invest-after-profile-setup-calculator-col2-row2-text1">
            Invested Outside VedasLabs
          </h4>
          <h4 className="font-18-roboto-medium invest-after-profile-setup-calculator-col2-row2-text2">
            Last 12 Months
          </h4>
          <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col2-row2-text3">
            {!isNaN(amountInvestedOutsideOfVL) && (
              <DisplayCurrencyFormatString value={amountInvestedOutsideOfVL} />
            )}
          </h4>
        </div>
        {/* 4 */}
        <div className="invest-after-profile-setup-calculator-col2-row2-div invest-after-profile-setup-calculator-col2-row2-div--3">
          <h4 className="font-24-roboto-medium invest-after-profile-setup-calculator-col2-row2-text1">
            Invested on VedasLabs
          </h4>
          <h4 className="font-18-roboto-medium invest-after-profile-setup-calculator-col2-row2-text2">
            Last 12 Months
          </h4>
          <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col2-row2-text3">
            <DisplayCurrencyFormatString value={amountInvestedOnVL} />
          </h4>
        </div>
      </div>
    </div>
  );
}

InvestLimitCalculatorCard.defaultProps = {
  net_worth: "",
  annual_income: "",
  amountInvestedOutsideOfVL: "",
};
