import {
  SET_UPDATES_EXPLORE_DETAILS,
  SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
  RESET_UPDATES_EXPLORE_DETAILS,
  RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID,
} from "./../types";

import { compareAsc } from "date-fns";

const initialState = {
  updatesExploreDetails: {},
  updatesExploreDetailsByOrgId: {},
};

/**
 * Updates Reducer
 * 
 * Overview:
 * This reducer handles the state related to updates in the web app store. It manages the updatesExploreDetails and updatesExploreDetailsByOrgId properties in the state object.
 * 
 * Details:
 * - The reducer uses the SET_UPDATES_EXPLORE_DETAILS action type to update the updatesExploreDetails property in the state. It sorts the updates based on their created_at property in descending order.
 * - The reducer uses the SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID action type to update the updatesExploreDetailsByOrgId property in the state. It also sorts the updates based on their created_at property in descending order.
 * - The reducer uses the RESET_UPDATES_EXPLORE_DETAILS action type to reset the updatesExploreDetails property to an empty object.
 * - The reducer uses the RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID action type to reset the updatesExploreDetailsByOrgId property to an empty object.
 * 
 * Usage:
 * This reducer is typically used in conjunction with actions and selectors to manage the updates state in the web app store. It can be imported and combined with other reducers using the combineReducers function from Redux.
 * 
 * Dependencies:
 * - The reducer depends on the initialState object, which should be defined before importing this reducer.
 * - The reducer uses the compareAsc function from the date-fns library to compare the created_at dates of the updates.
 * 
 * @param {Object} state - The current state object.
 * @param {Object} action - The action object that contains the type and payload.
 * @returns {Object} - The updated state object.
 */
export default function updatesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UPDATES_EXPLORE_DETAILS:
      return {
        ...state,
        updatesExploreDetails: action.payload.sort((a, b) =>
          compareAsc(new Date(b.created_at), new Date(a.created_at))
        ),
      };

    case SET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID:
      return {
        ...state,
        updatesExploreDetailsByOrgId: action.payload.sort((a, b) =>
          compareAsc(new Date(b.created_at), new Date(a.created_at))
        ),
      };

    case RESET_UPDATES_EXPLORE_DETAILS:
      return {
        ...state,
        updatesExploreDetails: {},
      };

    case RESET_UPDATES_EXPLORE_DETAILS_BY_ORG_ID:
      return {
        ...state,
        updatesExploreDetailsByOrgId: {},
      };

    default:
      return state;
  }
}
