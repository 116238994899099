import React, {
  useState,
  //  useEffect
} from "react";
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";

/**
 * StartUpDetailsWatchVideoModal Component
 * 
 * Overview:
 * This component renders a modal that displays a video player for watching a startup's pitch video. It is used in the startup details page to allow users to watch the pitch video of a startup.
 * 
 * Details:
 * - The component accepts the following props:
 *   - videoLink: The URL of the video to be played.
 *   - setOpen: A function to control the open state of the modal.
 *   - open: A boolean indicating whether the modal is open or closed.
 * - The component uses the `Modal` component from a library to render the modal.
 * - The modal contains a video player component (`ReactPlayer`) that plays the video specified by the `videoLink` prop.
 * - The modal can be closed by clicking on the close icon or by clicking outside the modal.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Modal: The component uses the `Modal` component from a library to render the modal.
 * - ReactPlayer: The component uses the `ReactPlayer` component from a library to render the video player.
 */
export default function StartUpDetailsWatchVideoModal({ videoLink,setOpen,open }) {

  /*==========================
             handler
 ===========================*/
  const handleOpenModal = (e) => {
    setOpen(!open);
  };

  return (
    <>
      {/* <button onClick={handleOpenModal}>
        <span className="font-18-roboto-medium vl-render-bottom-left-div-video-url">
          Watch Video Pitch Here
        </span>
      </button> */}

      <Modal
        open={open}
        onClose={handleOpenModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal customModal--pitch-video",
          closeButton: "customCloseButton",
        }}
      >
        <span className="closeIconInModal" onClick={handleOpenModal} />
        <div className="profile-review-div">
          <div className="vl-video-component">
            {/* <video
              crossOrigin="anonymous"
              controls={true}
              playsInline
              muted={false}
              autoPlay={true}
              loop
            >
              <source
                src={
                  videoLink.includes("http")
                    ? `${videoLink}`
                    : `https://${videoLink}`
                }
                type="video/mp4"
              />
            </video> */}

            <ReactPlayer
              className="react-player"
              url={
                videoLink.includes("http")
                  ? `${videoLink}`
                  : `https://${videoLink}`
              }
              controls={true}
              width="100%"
              height="100%"
              config={{
                file: { attributes: { crossOrigin: true } },
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
