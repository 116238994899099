import io from "socket.io-client";
import { backendUrl } from "../web-app-store/actions/config";
let STRAPI_ENDPOINT;

STRAPI_ENDPOINT = backendUrl;

/**
 * web-sockets.js
 * 
 * Overview:
 * This file contains the configuration for the web sockets connection using the socket.io library. It establishes a connection to the STRAPI_ENDPOINT and provides the socket object for communication.
 * 
 * Details:
 * - The socket object is created using the io.connect() method from the socket.io library.
 * - The STRAPI_ENDPOINT is the URL of the server to which the socket connection is established.
 * - The empty object passed as the second argument to io.connect() represents any additional options for the socket connection.
 * 
 * Usage:
 * To use the socket object for communication, import it from this file and use it in the desired component or module.
 * 
 * Dependencies:
 * - This file depends on the socket.io library, which should be installed and imported before using this configuration.
 * - The STRAPI_ENDPOINT should be defined and accessible in the application.
 */
export const socket = io.connect(STRAPI_ENDPOINT, {});
