// import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateCreateNewDiscussion Function
 * 
 * Overview:
 * This function is used to validate the data for creating a new discussion. It checks if the description field is empty and returns any errors found.
 * 
 * Details:
 * - The function takes in a data object as a parameter.
 * - It initializes an empty object called errors to store any validation errors.
 * - If the description field in the data object is empty, it adds an error message to the errors object.
 * - The function then returns an object containing the errors and a boolean value indicating if the data is valid (isEmpty(errors)).
 * 
 * Dependencies:
 * The function relies on the isEmpty function to check if a value is empty. This function should be defined elsewhere in the codebase.
 */
export const validateCreateNewDiscussion = (data) => {
  let errors = {};

  //   console.log(data);

  if (isEmpty(data.description)) {
    errors.description = "Description is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
