import { useDispatch, useSelector } from "react-redux";
import {
  createMentor,
  getMentorByUserId,
  updateMentor,
  uploadImage,
} from "../../../../../web-app-store/actions/mentorAction";
import { useEffect, useState } from "react";
import { industries } from "../../dropdownList/industry";
import { liveProfileOptions } from "../../../../../web-app-components/desktop/common/allDropdownOptions";
import { validateMentorProfile } from "../../../../../web-app-store/validations/mentor/mentorProfile";
import isEmpty from "../../../../../web-app-store/validations/is-empty";

const { default: MentorProfileInput } = require("./MentorProfileInput");

const MentorProfileInputContainer = ({
  mentor,
  setIsProfileReview,
  setActiveProfileStage,
  isEdit,
  setIsEdit,
}) => {
  const areaOfExpertiseValueSchema = {
    areaOfExpertise: "",
    yearsOfExpertise: "",
    // fileData: {},
    // imgUrl: "",
    // cropImgBlob: {},
  };
  const [frontEndErrors, setFrontEndErrors] = useState({});
  const [tagsInputValue, setTagsInputValue] = useState([]);
  const [tagsArray, setTagsArray] = useState([]);
  const [tagsSoftwareValue, setTagsSoftwareValue] = useState([]);
  const [industryError, setIndustryError] = useState();
  const [isIndustryError, setIsIndustryError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [clickIndex, setClickIndex] = useState();
  const dispatch = useDispatch();
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    bio: "",
    first_name: "",
    last_name: "",
    location: "",
    email: "",
    socketId: "",
    chat_infoId: "",
    linkedin_link: "",
    skills: {},
    software: {},
    country: "",
    language: "",
    sectors_of_interest: [
      { ...areaOfExpertiseValueSchema, id: 1 },
      { ...areaOfExpertiseValueSchema, id: 2 },
      { ...areaOfExpertiseValueSchema, id: 3 },
    ],
    price: "",
    time_available: "",
    days_available: "",
    company: "",
    job_title: "",
    live_profile: { value: "InActive Profile", label: "InActive Profile" },
  });
  const areaOfExpertiseSchema = {
    name: "sectors_of_interest",
    options: industries,
    placeholder: "Select Industry",
    isSearchable: true,
    type: "select",
    label: "Area",
  };
  const [areaOfExpertiseArray, setAreaOfExpertiseArray] = useState([
    { ...areaOfExpertiseSchema, id: 1 },
    { ...areaOfExpertiseSchema, id: 2 },
    { ...areaOfExpertiseSchema, id: 3 },
  ]);
  const [isOpenModals, setIsOpenModals] = useState(
    new Array(values?.sectors_of_interest?.length).fill(false)
  );

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    setValues({
      ...values,
      first_name: userData.firstname,
      last_name: userData.lastname,
      location: userData.Address,
      email: userData.email,
      socketId: userData.socketId,
      chat_infoId: userData.chat_infoId,
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(mentor)) {
      let data = mentor[0];
      let finalSectorsOfIntrest = [];
      if (!isEmpty(data?.sectors_of_interest)) {
        data?.sectors_of_interest.forEach((ele, i) => {
          finalSectorsOfIntrest.push({
            areaOfExpertise: {
              value: ele?.areaOfExpertise,
              label: ele?.areaOfExpertise,
            },
            yearsOfExpertise: {
              value: ele.yearsOfExpertise,
              label: ele.yearsOfExpertise,
            },
            // imgUrl: ele?.area_image?.url || "",
            // fileData: ele?.area_image,
            id: i + 1,
          });
        });
      }
      setValues({
        ...values,
        bio: data.bio,
        skills: data.skills,
        software: data.software,
        country: data.country,
        chat_infoId: data.chat_infoId,
        linkedin_link: data.linkedin_link,
        live_profile: !isEmpty(data.live_profile)
          ? liveProfileOptions.filter((a) => a.value === data.live_profile)
          : { value: "InActive Profile", label: "InActive Profile" },
        language: { value: data.language, label: data.language },
        sectors_of_interest: finalSectorsOfIntrest,
        price: { value: data.price, label: data.price },
        time_available: {
          value: data.time_available,
          label: data.time_available,
        },
        days_available: {
          value: data.days_available,
          label: data.days_available,
        },
        company: data.company,
        job_title: data.job_title,
      });
      let areaOfExpertiseScheme = [...areaOfExpertiseArray];
      areaOfExpertiseScheme = finalSectorsOfIntrest?.map((ele, i) => {
        return { ...areaOfExpertiseSchema, id: i + 1 };
      });
      setAreaOfExpertiseArray(areaOfExpertiseScheme);
      setIsDisabled(true);
      setTagsSoftwareValue(data.software);
      setTagsArray(data?.skills);
    }
  }, [mentor]);

  const handleIndustryChange = (industry, i, name) => {
    let areaOfExpertiseObj = [...values?.sectors_of_interest];
    areaOfExpertiseObj[i] = {
      ...values?.sectors_of_interest[i],
      [name]: industry,
    };
    setValues({
      ...values,
      sectors_of_interest: areaOfExpertiseObj,
    });
    setFrontEndErrors({});
    setIndustryError("");
    setIsIndustryError(false);
  };

  const handleChange = (event, index) => {
    const { value, name } = event.target;
    let valuesObj = { ...values };
    if (name === "yearsOfExpertise") {
      let yearsOfExpertise = [...valuesObj.sectors_of_interest];
      yearsOfExpertise[index] = {
        ...valuesObj.sectors_of_interest[index],
        [name]: value,
      };
      valuesObj = {
        ...valuesObj,
        sectors_of_interest: yearsOfExpertise,
      };
    } else {
      valuesObj = {
        ...values,
        [name]: value,
      };
    }
    setFrontEndErrors({});
    setValues(valuesObj);
  };

  const handleAddMoreAreas = () => {
    const addMoreAreas = [...areaOfExpertiseArray];
    addMoreAreas.push(areaOfExpertiseSchema);
    let obj;
    obj = addMoreAreas.map((ele, i) => {
      return {
        ...ele,
        id: i + 1,
      };
    });
    setAreaOfExpertiseArray(obj);

    const areaOfExpertiseValue = [...values?.sectors_of_interest];
    areaOfExpertiseValue?.push(areaOfExpertiseValueSchema);
    const areaOfExpertiseValueObj = areaOfExpertiseValue.map((ele, i) => {
      return {
        ...ele,
        id: i + 1,
      };
    });

    setValues({
      ...values,
      sectors_of_interest: areaOfExpertiseValueObj,
    });

    const areaOfExpertiseValueError = [...frontEndErrors?.sectors_of_interest];
    areaOfExpertiseValueError?.push(areaOfExpertiseValueSchema);
    const areaOfExpertiseValueErrorObj = areaOfExpertiseValueError.map(
      (ele, i) => {
        return {
          ...ele,
          id: i + 1,
        };
      }
    );

    setFrontEndErrors({
      ...frontEndErrors,
      sectors_of_interest: areaOfExpertiseValueErrorObj,
    });
  };

  const handleRemoveAreaOfExpertise = (id) => {
    let valArr = [...areaOfExpertiseArray];
    valArr = areaOfExpertiseArray
      ?.filter((ele) => ele.id !== id)
      .map((ele, i) => {
        return {
          ...ele,
          id: i + 1,
        };
      });
    setAreaOfExpertiseArray(valArr);
    let valueArrObj = [...values.sectors_of_interest];
    valueArrObj = values.sectors_of_interest
      ?.filter((ele) => ele?.id !== id)
      .map((ele, i) => {
        return {
          ...ele,
          id: i + 1,
        };
      });
    setValues({
      ...values,
      sectors_of_interest: valueArrObj,
    });
    let valueArrObjError = [...frontEndErrors.sectors_of_interest];
    valueArrObjError = valueArrObjError
      ?.filter((ele) => ele?.id !== id)
      .map((ele, i) => {
        return {
          ...ele,
          id: i + 1,
        };
      });
    setFrontEndErrors({
      ...frontEndErrors,
      sectors_of_interest: valueArrObjError,
    });
  };

  const handleOnClickSaveCropImg = (val, imgUrl) => (e) => {
    setFrontEndErrors({});
    e.preventDefault();
    let areaOfExpertiseObj = [...values?.sectors_of_interest];
    areaOfExpertiseObj[clickIndex].fileData = val;
    areaOfExpertiseObj[clickIndex].imgUrl = imgUrl;
    setValues({ ...values, sectors_of_interest: areaOfExpertiseObj });
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[clickIndex] = false;
    setIsOpenModals(newIsOpenModals);
  };

  const getBlobDataFromImageCrop = (data) => {
    let areaOfExpertiseObj = [...values?.sectors_of_interest];
    areaOfExpertiseObj[clickIndex].cropImgBlob = data;
    setValues({ ...values, sectors_of_interest: areaOfExpertiseObj });
  };

  const handleOpenModal = (index) => {
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[index] = true;
    setIsOpenModals(newIsOpenModals);
    setClickIndex(index);
  };

  const handleCloseModal = (index) => {
    const newIsOpenModals = [...isOpenModals];
    newIsOpenModals[index] = false;
    setIsOpenModals(newIsOpenModals);
    setClickIndex(index);
  };

  const handleChangeLiveProfile = (live_profile) => {
    // setFrontendErrors({});
    setValues({
      ...values,
      live_profile,
    });
  };

  const callBack = (status) => {
    setLoading(false);
    if (status === 200) {
      if (!isEdit) {
        setIsProfileReview(true);
        setActiveProfileStage(1);
      } else {
        setIsEdit(false);
      }
    }
  };

  const handleUploadImage = async (formData) => {
    const response = await dispatch(uploadImage(formData));
    return response;
  };

  const handleSave = async () => {
    const { errors, isValid } = validateMentorProfile(values, frontEndErrors);
    if (!isValid) {
      setFrontEndErrors(errors);
    } else {
      setFrontEndErrors(errors);
      setLoading(true);
      let formDataNew = new FormData();
      if (!isEmpty(userData?.user_profile)) {
        formDataNew.append("profile_image", userData?.user_profile);
      } else {
        formDataNew.append("profile_image", null);
      }

      let finalSectorsOfInterest = [];
      let uploadImageArr = [];

      if (!isEmpty(values.sectors_of_interest)) {
        for (const ele of values.sectors_of_interest) {
          const formData = new FormData();
          if (isEmpty(ele?.cropImgBlob)) {
            formData.append("files", ele?.fileData);
          } else {
            formData.append("files", ele?.cropImgBlob);
          }
          // try {
          //   const response = await handleUploadImage(formData);
          //   if (response) {
          //     uploadImageArr.push({
          //       area_image: response[0] || {},
          //     });
          //   } else {
          //     uploadImageArr.push({
          //       area_image: ele?.fileData || {},
          //     });
          //   }
          // } catch (error) {
          //   console.log(error);
          // }
        }
      }

      await Promise.all(uploadImageArr);
      if (!isEmpty(values.sectors_of_interest)) {
        values.sectors_of_interest.forEach(async (ele, i) => {
          finalSectorsOfInterest.push({
            areaOfExpertise: ele?.areaOfExpertise?.value,
            yearsOfExpertise: ele?.yearsOfExpertise?.value,
            // area_image: uploadImageArr?.[i].area_image || "",
            area_image: {},
          });
        });
      }

      let newData = {
        user: { id: userData.id },
        first_name: userData.firstname,
        last_name: userData.lastname,
        location: userData.Address,
        email: userData.email,
        bio: values?.bio,
        socketId: userData.socketId,
        chat_infoId: userData.chat_infoId,
        skills: values.skills,
        software: values.software,
        country: values.country,
        language: values.language.value,
        linkedin_link: values.linkedin_link,
        sectors_of_interest: finalSectorsOfInterest,
        price: values.price.value,
        time_available: values.time_available.value,
        days_available: values.days_available.value,
        company: values.company,
        job_title: values.job_title,
        live_profile: values.live_profile.value,
        profile_image: userData?.user_profile,
      };

      formDataNew.append("data", JSON.stringify(newData));

      if (isEmpty(mentor)) {
        await dispatch(createMentor(formDataNew, callBack));
      } else {
        await dispatch(updateMentor(newData, mentor[0].id, callBack));
      }
    }
  };

  const handleEdit = () => {
    setIsDisabled(false);
  };

  useEffect(() => {
    if (isEdit) {
      setIsDisabled(false);
    }
  }, [isEdit]);

  return (
    <MentorProfileInput
      {...{
        isOpenModals,
        isEdit,
        loading,
        isDisabled,
        areaOfExpertiseArray,
        frontEndErrors,
        tagsInputValue,
        values,
        tagsArray,
        tagsSoftwareValue,
        industryError,
        isIndustryError,
        handleIndustryChange,
        handleChange,
        handleChangeLiveProfile,
        handleSave,
        handleAddMoreAreas,
        handleRemoveAreaOfExpertise,
        handleEdit,
        handleOnClickSaveCropImg,
        getBlobDataFromImageCrop,
        handleOpenModal,
        handleCloseModal,
      }}
    />
  );
};

export default MentorProfileInputContainer;
