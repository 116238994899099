import { toast, ToastContainer } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import {
  faq,
  knowBeforeYouInvest,
  newLoginSignUpLink,
  newLoginSignUpLinkSignUp,
} from "../header-footer/pageLinks";
// import InvestMentImage from "../../../web-app-assets/imgs/desktop/backgrounds/investMent.json";
import InvestMentImage from "../../../web-app-assets/imgs/desktop/backgrounds/Invest.json";
// import InvestMentImage from "../../../web-app-assets/imgs/desktop/backgrounds/invest-ment.json";
import Lottie from "react-lottie-player";
import { validateEmail } from "../../../web-app-store/validations/lightPaper/lightPaper";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewsLetterAction,
  newsLetterAction,
} from "../../../web-app-store/actions/homeAction";
import { Nav } from "react-bootstrap";

/**
 * NewHomeFoldOne Component
 *
 * Overview:
 * The NewHomeFoldOne component represents a section of the home page that allows users to join VedasLabs.io by entering their email address. It provides a form for users to input their email and a button to submit the form. Upon successful submission, the user's email is added to the newsletter list and a success message is displayed. The component also displays error messages if the email is invalid or already exists in the newsletter list.
 *
 * Details:
 * - The component uses React hooks to manage the state of the email input field and the frontend error messages.
 * - It uses Redux to access the homeAllNewsLetter state and dispatch actions to get the newsletter list and add emails to the list.
 * - The component uses React Router to navigate to different pages within the application.
 * - The component uses the react-toastify library to display success messages.
 * - The component includes animations using the react-lottie library.
 *
 * Usage:
 * To use the NewHomeFoldOne component, import it into the desired parent component and render it within the parent component's JSX. Make sure to include the necessary Redux store setup and React Router configuration.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - React Router: The component uses React Router for navigation.
 * - react-toastify: The component uses the react-toastify library for displaying success messages.
 * - react-lottie: The component uses the react-lottie library for animations.
 */
const NewHomeFoldOne = () => {
  const [emailValue, setEmailValue] = useState({
    email: "",
  });
  const { homeAllNewsLetter } = useSelector((state) => state.home);
  const [frontEndErrors, setFrontEndErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const callBack = (status) => {
    if (status === 200) {
      toast.success("Successfully join VedasLabs.io");
      setEmailValue({ ...emailValue, email: "" });
      dispatch(getNewsLetterAction());
      setFrontEndErrors({});
    }
  };

  useEffect(() => {
    dispatch(getNewsLetterAction());
  }, []);

  const handleEmail = () => {
    let email = homeAllNewsLetter?.filter(
      (val) => val?.email === emailValue?.email
    );
    const { errors, isValid } = validateEmail(emailValue, email[0]?.email);
    if (!isValid) {
      setFrontEndErrors(errors);
    } else {
      dispatch(newsLetterAction(emailValue, callBack));
    }
  };

  const handleEmailChange = (key, value) => {
    setEmailValue({ [key]: value });
    setFrontEndErrors({});
  };

  return (
    <div className="background-image-home">
      <div className="container max-height justify-content-center align-items-center">
        <div className="row investor-main">
          <div
            className="col-sm-6 col-md-6 col-12"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-out"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <div className="invest-main">
              <h4 className="invest-title">
                Invest in the future of innovation.
              </h4>
              <h6 className="invest-sub">
                Join a community of investors, mentors, and entrepreneurs
                focused on accelerating how capital is raised.
              </h6>
            </div>
            <div className="">
              <div className="investor-email">
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={emailValue.email}
                  onChange={(e) =>
                    handleEmailChange(e.target.name, e.target.value)
                  }
                />
                <p className="error-message mb-3">{frontEndErrors.email}</p>
                {!frontEndErrors.email ? (
                  <Nav.Link
                    className="vedasLabs-btn d-flex align-items-center justify-content-center"
                    onClick={() => handleEmail()}
                    href={newLoginSignUpLink}
                    // href={newLoginSignUpLinkSignUp}
                  >
                    <span>Join VedasLabs.io</span>
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    className="vedasLabs-btn d-flex align-items-center justify-content-center"
                    onClick={() => handleEmail()}
                    href={""}
                  >
                    <span>Join VedasLabs.io</span>
                  </Nav.Link>
                )}
              </div>
              <p className="investing-sub">
                Early stage investing entails risk.
                <p>
                  <span>Please read </span>
                  <Link to={knowBeforeYouInvest} className="action-link">
                    {/* <span className="">  </span> */}
                    Know Before You Invest
                  </Link>
                  <span> and </span>
                  <Link to={faq} className="action-link">
                    {/* <span className=""> </span> */}
                    FAQs
                  </Link>
                  .
                </p>
              </p>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-6 col-12 p-0"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-out"
            data-aos-duration="1500"
            data-aos-once="true"
          >
            <Lottie
              loop
              animationData={InvestMentImage}
              play
              className="investor-image"
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-left" autoClose={2000} />
    </div>
  );
};

export default NewHomeFoldOne;
