import isEmpty from "../is-empty";

/**
 * ValidateRaiseCapitalOrgDataNotEmpty
 * 
 * Overview:
 * Validates the data provided for creating or updating an organization's raise capital profile to ensure that all required fields are not empty. This function is crucial for maintaining data integrity and ensuring that all necessary information is provided before proceeding.
 * 
 * Details:
 * - Checks various fields in the provided data object for emptiness using the `isEmpty` utility function.
 * - Fields validated include company logo, company name, website, phone number, business address (line 1, city, state, country, zip), industry, company details, pitch deck, product overview, and LinkedIn link.
 * - Generates an errors object that accumulates any found errors, marking the validation as failed if any required field is empty.
 * 
 * Dependencies:
 * - isEmpty: A utility function used to check if a given value is considered empty according to specific criteria (e.g., null, undefined, empty string).
 */
export const validateRaiseCapitalOrgDataNotEmpty = (data) => {
  let errors = {};

  // console.log(data);

  if (
    isEmpty(data.company_logo) ||
    isEmpty(data.company_name) ||
    isEmpty(data.website) ||
    isEmpty(data.phone_number) ||
    isEmpty(data.business_address_line1) ||
    // isEmpty(data.business_address_line2) ||
    isEmpty(data.city) ||
    isEmpty(data.state) ||
    isEmpty(data.country) ||
    isEmpty(data.zip) ||
    isEmpty(data.industry) ||
    isEmpty(data.company_details) ||
    isEmpty(data.pitch_deck) ||
    // isEmpty(data.pitch_video_url) ||
    // isEmpty(data.product_overview) ||
    // isEmpty(data.facebook_link) ||
    isEmpty(data.linkedin_link)
    // || isEmpty(data.instagram_link) ||
    // isEmpty(data.twitter_link) ||
    // isEmpty(data.business_model)
  ) {
    errors.e = "error";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

/**
 * ValidateRaiseCapitalOrgDataFundraiserNotEmpty
 * 
 * Overview:
 * Validates the fundraiser data for an organization's raise capital profile to ensure that essential fundraising goals are specified. This validation ensures that fundraising campaigns have clear financial targets, which is fundamental for transparency and planning.
 * 
 * Details:
 * - Validates that the funding goal and maximum funding goal fields are not empty.
 * - Utilizes the `isEmpty` utility function to check for emptiness.
 * - Constructs an errors object to store any validation failures, with validation considered successful only if this object remains empty.
 * 
 * Dependencies:
 * - isEmpty: Utilized to ascertain whether specified fundraising goal fields are populated, ensuring that key financial targets for fundraising are clearly defined.
 */
export const validateRaiseCapitalOrgDataFundraiserNotEmpty = (data) => {
  let errors = {};

  // console.log(data);

  if (isEmpty(data.funding_goal)) {
    errors.e = "error";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};

export const validateRaiseCapitalOrgDataTeamNotEmpty = (data) => {
  let errors = {};
  if (data?.length <= 0) {
    errors.e = "error";
  } else {
    data?.forEach((element, index) => {
      if (isEmpty(element?.full_name) || isEmpty(element?.email)) {
        errors.e = "error";
      }
    });
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
