import {
  SET_ALL_USER_DATA,
  SET_CREATE_MENTOR,
  SET_CREATE_USER,
  SET_LOGIN,
  SET_LOGOUT,
} from "./../types";
import isEmpty from "./../validations/is-empty";

const initialState = {
  isAuthenticated: false,
  user: {},
  createMentor: false,
  isUser: false,
};

/**
 * Auth Reducer
 * 
 * Overview:
 * This reducer handles the authentication state of the application. It manages the user's authentication status, user data, and other related information.
 * 
 * Details:
 * - The reducer handles various actions related to authentication, such as setting the login status, logout status, user data, creating a mentor, and creating a user.
 * - It uses the Redux pattern to manage the state and update it based on the dispatched actions.
 * - The reducer maintains an initial state object that contains the default values for the authentication state.
 * - The reducer uses the spread operator (...) to create a new state object with the updated values.
 * 
 * Usage:
 * This reducer is used in the Redux store to manage the authentication state of the application. It can be accessed and dispatched using Redux actions.
 * 
 * Dependencies:
 * - Redux: The reducer uses Redux to manage the state and handle actions.
 * - isEmpty: The reducer uses the isEmpty function to check if the action payload is empty.
 * 
 * @param {Object} state - The current state of the authentication.
 * @param {Object} action - The dispatched action object.
 * @returns {Object} - The updated state object.
 */
export default function foo(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    case SET_ALL_USER_DATA:
      return {
        ...state,
        allUser: action.payload,
      };
    case SET_CREATE_MENTOR:
      return {
        ...state,
        createMentor: action.payload,
      };
    case SET_CREATE_USER:
      return {
        ...state,
        isUser: action.payload,
      };
    default:
      return state;
  }
}
