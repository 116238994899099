import React from 'react'

/**
 * MainHeaderMenu Component
 * 
 * Overview:
 * The MainHeaderMenu component is responsible for rendering the mobile menubar in the header of the application. It provides a button that, when clicked, opens the side navigation menu.
 * 
 * Details:
 * - The component defines an openNav function that is called when the button is clicked. This function sets the visibility and display properties of the side navigation menu to show it on the screen.
 * - The component renders a div with the className "mobile-menubar" that contains a button. When the button is clicked, it triggers the openNav function.
 * - The button includes an icon from the "fa" font-awesome library to represent the menu bars.
 * 
 * Usage:
 * To use the MainHeaderMenu component, include it in the desired location within the application's header. When the button is clicked, it will open the side navigation menu.
 * 
 * Dependencies:
 * - The component relies on the "fa" font-awesome library to display the menu bars icon.
 */
const MainHeaderMenu = () => {
    function openNav() {
        document.getElementsByClassName("vl-side-navbar-main-div")[0].style.transition = "visibility 0s, opacity 0.3s ease 0s, transform 0s ease 0.5s";
        document.getElementsByClassName("vl-side-navbar-main-div")[0].style.display = "block";
    }
    return (
        <div className="mobile-menubar">
            <button onClick={openNav}>
                <i class="fa fa-regular fa-bars" ></i>
            </button>
        </div>
    )
}

export default MainHeaderMenu