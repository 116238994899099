import isEmpty from "../is-empty";

/**
 * validateInvestDataNotEmpty Function
 *
 * Overview:
 * This function is used to validate the data object for the invest form. It checks if the required fields are not empty and returns any errors found.
 *
 * Details:
 * - The function takes a data object as input and initializes an empty errors object.
 * - It checks if the required fields in the data object are empty using the isEmpty function.
 * - If any required field is empty, it adds an error message to the errors object.
 * - It also checks for additional conditions based on the values of certain fields in the data object.
 * - The function returns an object containing the errors and a boolean flag indicating if the data is valid.
 *
 * Dependencies:
 * The function depends on the isEmpty function to check if a value is empty.
 */
export const validateInvestDataNotEmpty = (data) => {
  let errors = {};

  // console.log(data);

  if (
    // isEmpty(data.birth_date) ||
    // isEmpty(data.amount_invested_vlabs) ||
    // isEmpty(data.education) ||
    // isEmpty(data.email) ||
    // isEmpty(data.employment_status) ||
    // isEmpty(data.first_name) ||
    // isEmpty(data.gender) ||
    isEmpty(data.income) ||
    // isEmpty(data.industry_categories) ||
    // isEmpty(data.intrested_in_mentorship) ||
    // isEmpty(data.invested_outside_vlabs) ||
    // isEmpty(data.investor_status) ||
    isEmpty(data.job_industry) ||
    isEmpty(data.job_title) ||
    // isEmpty(data.last_name) ||
    // isEmpty(data.location) ||
    // isEmpty(data.martial_status) ||
    // isEmpty(data.nationality) ||
    isEmpty(data.networth)
    // isEmpty(data.phone_number)
    // isEmpty(data.profile_image)
    // isEmpty(data.remaining_investment_limit) ||
    // isEmpty(data.social_categories) ||
    // isEmpty(data.spouse_acc_vedaslab) ||
    // isEmpty(data.spouse_income_included) ||
    // isEmpty(data.spouse_investor_yn) ||
    // isEmpty(data.total_investment_limit) ||
  ) {
    errors.e = "error one";
  }

  if (data.spouse_income_included) {
    if (
      isEmpty(data.spouse_first_name) ||
      isEmpty(data.spouse_last_name) ||
      isEmpty(data.spouse_email)
    ) {
      errors.e = "error two";
    }
  }

  if (data.invested_outside_vlabs) {
    if (isEmpty(data.amount_invested_outside)) {
      errors.e = "error three";
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
