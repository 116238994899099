import React, { useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
// import Select from "react-select";
import TextareaField from "../common/TextareaField";
import Tags from "../common/Tags";
import SaveAndNextButton from "../common/SaveAndNextButton";
import ReactFlagsSelect from "react-flags-select";
import { countries } from "./countriesList";
// api
import {
  createRaiseCapitalOrganization,
  updateRaiseCapitalOrganization,
  getRaiseCapitalTags,
  getAllIndustries,
  getSocialImpactOptions,
} from "../../../web-app-store/actions/raiseCapitalAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { validateRaiseCapitalBasic } from "./../../../web-app-store/validations/raise-capital/raiseCapitalBasic";
import InputFieldPhoneCountryNumber from "../common/InputFieldPhoneCountryNumber";
import InputFieldNumber from "../common/InputFieldNumber";

import ImageCropModal from "../common/ImageCropModal";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import raiseCapitalDefaultImage from "../../../assets/imgs/desktop/common/raise-capital-default-image.png";

/**
 * RaiseCapitalBasic Component
 *
 * Overview:
 * The RaiseCapitalBasic component is responsible for rendering a form for raising capital. It allows users to enter basic information about their company, such as company name, email, phone number, address, website, industry, and social impact area. The component also handles file upload for the company logo and provides a cropping feature for the uploaded image.
 *
 * Details:
 * - The component uses Redux for state management and makes API calls to retrieve industry and social impact options.
 * - The component includes form validation and error handling.
 * - The component uses local storage to store user data.
 * - The component dispatches actions to update or create a raise capital organization based on the form inputs.
 * - The component includes a modal for cropping the uploaded image.
 *
 * Dependencies:
 * - React, useState, useEffect: React hooks for managing component state and side effects.
 * - useDispatch, useSelector: Redux hooks for dispatching actions and accessing the Redux store.
 * - getAllIndustries, getSocialImpactOptions, getRaiseCapitalTags, createRaiseCapitalOrganization, updateRaiseCapitalOrganization: Redux actions for retrieving industry and social impact options, getting raise capital tags, and creating/updating raise capital organization.
 * - validateRaiseCapitalBasic: A function for validating the raise capital basic form inputs.
 * - isEmpty: A utility function for checking if a value is empty.
 * - FormData: A built-in JavaScript object for creating form data to send in HTTP requests.
 * - Modal: A component for displaying a modal window.
 */
export default function RaiseCapitalBasic({ handleOnSelectTab }) {
  // api
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));

  const [values, setValues] = useState({
    company: "",
    primaryEmail: userData.email,
    altEmail: "",
    phone: userData?.phoneNumber,
    fileName: "",
    fileData: {},
    imgUrl: "",

    basicAdress1: "",
    basicAdress2: "",
    city: "",
    state: "",
    // country: "",
    zipCode: "",

    website: "",
    industry: [],
    // social: socialOptions[0],
    social: [],
    marketTags: "",

    companyInfo: "",
    facebook: "",
    linkedIn: "",
    instagram: "",
    twitter: "",
  });

  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const [selectedSocialImpact, setSelectedSocialImpact] = useState([]);

  const [cropImgBlob, setCropImgBlob] = useState({});

  useEffect(() => {
    dispatch(getAllIndustries());
    dispatch(getSocialImpactOptions());
  }, [dispatch]);

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const raiseCapitalIndustries = useSelector(
    (state) => state.raiseCapital.raiseCapitalIndustries
  );

  const raiseCapitalSocialImpact = useSelector(
    (state) => state.raiseCapital.raiseCapitalSocialImpact
  );

  // console.log(raiseCapitalIndustries);

  useEffect(() => {
    if (!isEmpty(raiseCapitalIndustries)) {
      let newArray = raiseCapitalIndustries.map((industry) => ({
        value: industry.name,
        label: industry.name,
      }));
      // console.log(newArray);

      setValues({
        ...values,
        industry: newArray,
      });

      // if (isEmpty(raiseCapitalOrg)) {
      //   setSelectedIndustry(newArray[0]);
      // }
    }
  }, [raiseCapitalIndustries]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalSocialImpact)) {
      let newArray = raiseCapitalSocialImpact.map((data) => ({
        value: data.name,
        label: data.name,
      }));

      setValues({
        ...values,
        social: newArray,
      });
    }
  }, [raiseCapitalSocialImpact]);

  const [selectedCountry, setSelectedCountry] = useState("US");

  useEffect(() => {
    if (
      !isEmpty(raiseCapitalOrg) &&
      !isEmpty(raiseCapitalIndustries) &&
      !isEmpty(raiseCapitalSocialImpact)
    ) {
      let data = raiseCapitalOrg[0];
      //set dropdown options
      let newArray = raiseCapitalIndustries.map((industry) => ({
        value: industry.name,
        label: industry.name,
      }));
      //set dropdown options
      let newArraySocial = raiseCapitalSocialImpact.map((data) => ({
        value: data.name,
        label: data.name,
      }));
      // set default dropdown option
      let industrSelected =
        !isEmpty(raiseCapitalIndustries) &&
        !isEmpty(data.industry.trim()) &&
        raiseCapitalIndustries.filter((ele) => ele.name === data.industry);
      // set default dropdown option
      let socialImpactSelected =
        !isEmpty(raiseCapitalSocialImpact) &&
        !isEmpty(data.social_impact_area.trim()) &&
        raiseCapitalSocialImpact.filter(
          (ele) => ele.name === data.social_impact_area
        );

      let userDataObj = JSON.parse(localStorage.getItem("userData"));
      setValues({
        ...values,
        company: data.company_name,
        primaryEmail: isEmpty(data.primary_email)
          ? userDataObj.email
          : data.primary_email,
        altEmail: data.alternate_email,
        phone: isEmpty(data.phone_number)
          ? userDataObj.phoneNumber
          : data.phone_number,
        fileName: isEmpty(data.company_logo) ? null : data.company_logo.url,
        fileData: data.company_logo,
        imgUrl: isEmpty(data.company_logo) ? null : data.company_logo.url,

        basicAdress1: data.business_address_line1,
        basicAdress2: data.business_address_line2,
        city: data.city,
        state: data.state,
        // country: data.country,
        zipCode: data.zip,

        website: data.website,
        industry: newArray,
        social: newArraySocial,
        // social: !isEmpty(data.social_impact_area)
        //   ? {
        //       value: data.social_impact_area,
        //       label: data.social_impact_area,
        //     }
        //   : socialOptions[0],

        companyInfo: data.company_details,
        facebook: data.facebook_link,
        linkedIn: data.linkedin_link,
        instagram: data.instagram_link,
        twitter: data.twitter_link,
      });
      setSelectedCountry(data.country);
      industrSelected &&
        setSelectedIndustry({
          value: !isEmpty(industrSelected) && industrSelected[0].name,
          label: !isEmpty(industrSelected) && industrSelected[0].name,
        });

      socialImpactSelected &&
        setSelectedSocialImpact({
          value: !isEmpty(socialImpactSelected) && socialImpactSelected[0].name,
          label: !isEmpty(socialImpactSelected) && socialImpactSelected[0].name,
        });

      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
    }
  }, [
    dispatch,
    raiseCapitalOrg,
    raiseCapitalIndustries,
    raiseCapitalSocialImpact,
  ]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalTags(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  const [tagsArray, setTagsArray] = useState([]);
  const [tagsInputValue, setTagsInputValue] = useState([]);

  // api
  const raiseCapitalTags = useSelector(
    (state) => state.raiseCapital.raiseCapitalTags
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalTags)) {
      let data = raiseCapitalTags[0];
      // let newTagsArray=[...tagsArray];
      setTagsArray(JSON.parse(data.market_tags));
    }
  }, [raiseCapitalTags]);

  /*==========================
         handler
  ===========================*/

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      handleOnSelectTab(1);
    } else {
      handleOnSelectTab(0);
    }
  };

  const [frontendErrors, setFrontendErrors] = useState({});

  const handleOnClickSaveAndNext = () => {
    let formSubmitObj = {
      ...values,
      tagsArray,
      selectedIndustry,
      selectedSocialImpact,
    };

    const { errors, isValid } = validateRaiseCapitalBasic(formSubmitObj);
    // console.log(errors);
    if (!isValid) {
      setFrontendErrors(errors);
    } else if (isValid) {
      setFrontendErrors({});
      setLoading(true);

      let formDataNew = new FormData();

      if (isEmpty(cropImgBlob)) {
        formDataNew.append("files.company_logo", values.fileData);
      } else {
        formDataNew.append("files.company_logo", cropImgBlob);
      }

      let newData = {
        user: { id: userData.id },
        company_name: values.company,
        primary_email: values.primaryEmail,
        alternate_email: values.altEmail,
        phone_number: values.phone,

        business_address_line1: values.basicAdress1,
        business_address_line2: values.basicAdress2,
        city: values.city,
        state: values.state,
        // country: values.country,
        country: selectedCountry,
        zip: values.zipCode,

        website: values.website,
        industry: selectedIndustry.value,
        social_impact_area: selectedSocialImpact.value,

        company_details: values.companyInfo,
        facebook_link: values.facebook,
        linkedin_link: values.linkedIn,
        instagram_link: values.instagram,
        twitter_link: values.twitter,
      };

      formDataNew.append("data", JSON.stringify(newData));

      let sendTagsArrayForUpdate = {};
      if (!isEmpty(raiseCapitalOrg)) {
        sendTagsArrayForUpdate = {
          organization: {
            id: raiseCapitalOrg[0].id,
          },
          user: { id: userData.id },
          market_tags: JSON.stringify(tagsArray),
        };
      }

      if (isEmpty(raiseCapitalOrg)) {
        let sendTagsArray = JSON.stringify(tagsArray);
        dispatch(
          createRaiseCapitalOrganization(
            formDataNew,
            sendTagsArray,
            null,
            callBackStatus
          )
        );
      } else if (!isEmpty(raiseCapitalOrg) && isEmpty(raiseCapitalTags)) {
        dispatch(
          updateRaiseCapitalOrganization(
            formDataNew,
            raiseCapitalOrg[0].id,
            sendTagsArrayForUpdate,
            null,
            callBackStatus
          )
        );
      } else {
        dispatch(
          updateRaiseCapitalOrganization(
            formDataNew,
            raiseCapitalOrg[0].id,
            sendTagsArrayForUpdate,
            raiseCapitalTags,
            callBackStatus
          )
        );
      }
    }
  };

  const handleChange = (event) => {
    setFrontendErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeNumber = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.validity.valid ? e.target.value : "",
    });
  };

  // const handleOnChangeUploadImg = (e) => {
  //   e.preventDefault();
  //   setFrontendErrors({});

  //   setValues({
  //     ...values,
  //     fileName:
  //       e.target.files.length > 0
  //         ? URL.createObjectURL(e.target.files[0])
  //         : e.target.value,
  //     fileData: e.target.files[0],
  //   });
  // };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const handleOnClickSaveCropImg = (val, imgUrl) => (e) => {
    // console.log("save", val);
    setFrontendErrors({});
    e.preventDefault();

    setValues({
      ...values,
      fileData: val,
      imgUrl: imgUrl,
    });

    setIsOpenModal(false);
  };

  const getBlobDataFromImageCrop = (data) => {
    // console.log(data);
    setCropImgBlob(data);
  };

  const handleChangeIndustry = (industry) => {
    // console.log(industry);
    setFrontendErrors({});
    setSelectedIndustry(industry);

    // setValues({
    //   ...values,
    //   industry,
    // });
  };

  const handleChangeSocialImpact = (social) => {
    setFrontendErrors({});
    setSelectedSocialImpact(social);

    // setValues({
    //   ...values,
    //   social,
    // });
  };

  // const handleChangeInvestmentStage = (social) => {
  //   setValues({
  //     ...values,
  //     social,
  //   });
  // };

  const handleChangePhoneInput = (val) => {
    setValues({
      ...values,
      phone: val,
    });
  };

  /*===================================================================
        renderMarketTags
  ===================================================================*/

  // start select tags handlers
  const handleSelectTagsOnChange = (e) => {
    setTagsInputValue(e.target.value);
  };

  const handleOnKeyPressAndClickFunction = () => {
    setFrontendErrors({});
    // split the str and remove the empty values
    let tagsInputValueNew = tagsInputValue.toString().split(",");
    let len = tagsInputValueNew.length;
    let i = 0;
    while (len > i) {
      while (tagsInputValueNew[i] === "") {
        tagsInputValueNew.splice(i, 1);
      }
      i++;
    }

    if (tagsInputValueNew.length !== 0) {
      // condition to accept only 4 tags
      // let tagsArrayNew =
      //   [...tagsArray, ...tagsInputValueNew].length > 4
      //     ? [...tagsArray, ...tagsInputValueNew].slice(0, 4)
      //     : [...tagsArray, ...tagsInputValueNew];

      let tagsArrayNew = [...tagsArray, ...tagsInputValueNew];
      setTagsArray(tagsArrayNew);

      setTagsInputValue([]);
    }
  };

  const handleOnClickArrow = () => {
    handleOnKeyPressAndClickFunction();
  };

  const handleSelectTagsOnKeyPress = (e) => {
    let keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      handleOnKeyPressAndClickFunction();
    }
  };

  const handleRemoveTag = (val) => (e) => {
    var tags = [...tagsArray];
    var i = tags.indexOf(val);
    if (i !== -1) {
      tags.splice(i, 1);

      setTagsArray(tags);
    }
  };

  // renderMarketTags
  const renderMarketTags = () => {
    return (
      <>
        <Tags
          name="tagsInputValue"
          label="Tags"
          placeholder="Ex: FinTech, Innovation"
          onChange={handleSelectTagsOnChange}
          onKeyDown={handleSelectTagsOnKeyPress}
          value={tagsInputValue}
          tagsArray={tagsArray}
          handleRemoveTag={handleRemoveTag}
          onClickArrow={handleOnClickArrow}
          error={frontendErrors.tagsArray}
        />
      </>
    );
  };

  // console.log(loading);

  /*==========================================
                render
 ==========================================*/

  // console.log(cropImgBlob);

  const handleCloseImage = () => {
    setValues({
      ...values,
      fileData: {},
      fileName: "",
      imgUrl: "",
    });
  };

  return (
    <div className="raise-capital-basic-div raise_capital_div_wrap">
      <div className="row mx-0 raise-capital-basic-upload">
        <div className="col-md-6 col-sm px-0">
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Company Name"
            name="company"
            value={values.company}
            onChange={handleChange}
            type="text"
            error={frontendErrors.company}
          />

          <p className="raise-capital-form-basic-tab-text">
            Communication Methods
          </p>

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Primary Email"
            name="primaryEmail"
            value={values.primaryEmail}
            isReadOnly={true}
            type="email"
            // error={frontendErrors.primaryEmail}
          />

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Alternative Email"
            name="altEmail"
            value={values.altEmail}
            onChange={handleChange}
            type="email"
            error={frontendErrors.altEmail}
          />

          <InputFieldPhoneCountryNumber
            containerClassName="vl-form-input"
            label="Phone Number"
            name="phone"
            placeholder={values.phone}
            phoneInput={values.phone}
            handleChangePhoneInput={handleChangePhoneInput}
            errorPhone={frontendErrors.phone}
          />
        </div>
        <div className="col-md-6 col-sm px-0">
          {/* <UploadImage
            containerClassName="upload-img__mainBlock"
            buttonName="Upload Company Logo"
            fileNameValue={values.fileName}
            acceptType="image/jpeg, image/png"
            onChange={handleOnChangeUploadImg}
            error={frontendErrors.fileName}
            isDisabled={true}
          /> */}
          <ImageCropModal
            containerClassName="upload-img__mainBlock upload_profile_space"
            buttonName="Upload Company Logo"
            error={frontendErrors.imgUrl}
            fileData={values.imgUrl}
            handleOnClickSaveCropImg={handleOnClickSaveCropImg}
            getBlobDataFromImageCrop={getBlobDataFromImageCrop}
            isOpenModal={isOpenModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            dimensionText="Supported Formats: PNG , JPG"
            sizeRequirement="Size Requirement: 600 X 600 to 2048 X 2048 pixels"
            errorClass="profile_image_error"
            defaultImage={raiseCapitalDefaultImage}
            defaultClass="profile_default_img"
            handleCloseImage={handleCloseImage}
            isInvestor={true}
          />
        </div>
      </div>
      <div className="row raise-flex-direactio-col mx-0">
        <div className="vl-form-input--raise-capital col-md-6 col-sm-12">
          <p className="raise-capital-form-basic-tab-text">Corporate Address</p>
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Business Address L1"
            name="basicAdress1"
            value={values.basicAdress1}
            onChange={handleChange}
            type="text"
            error={frontendErrors.basicAdress1}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Business Address L2"
            name="basicAdress2"
            value={values.basicAdress2}
            onChange={handleChange}
            type="text"
            error={frontendErrors.basicAdress2}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="City"
            name="city"
            value={values.city}
            onChange={handleChange}
            type="text"
            error={frontendErrors.city}
          />
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="State"
            name="state"
            value={values.state}
            onChange={handleChange}
            type="text"
            error={frontendErrors.state}
          />
          {/* <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Country"
            name="country"
            value={values.country}
            onChange={handleChange}
            type="text"
            error={frontendErrors.country}
          /> */}

          <div className="vl-form-input vl-form-input--bordered-country-flag">
            <label htmlFor="">Country</label>
            <ReactFlagsSelect
              selected={selectedCountry}
              countries={countries}
              onSelect={(code) => setSelectedCountry(code)}
              searchable={true}
            />
          </div>

          <InputFieldNumber
            containerClassName="vl-form-input"
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            onChange={handleChangeNumber}
            error={frontendErrors.zipCode}
          />
        </div>
        <div className="vl-form-input--raise-capital-select col-md-6 col-sm">
          <p className="raise-capital-form-basic-tab-text">Other Information</p>
          <InputFieldEmailTextPassword
            placeholder={"Please enter a valid URL"}
            containerClassName="vl-form-input"
            label="Website"
            name="website"
            value={values.website}
            onChange={handleChange}
            type="text"
            error={frontendErrors.website}
          />

          <InputFieldEmailTextPassword
            placeholder={"Please enter a valid URL"}
            containerClassName="vl-form-input"
            label="Facebook"
            name="facebook"
            value={values.facebook}
            onChange={handleChange}
            type="text"
            error={frontendErrors.facebook}
          />

          <InputFieldEmailTextPassword
            placeholder={"Please enter a valid URL"}
            containerClassName="vl-form-input"
            label="LinkedIn"
            name="linkedIn"
            value={values.linkedIn}
            onChange={handleChange}
            type="text"
            error={frontendErrors.linkedIn}
          />

          <InputFieldEmailTextPassword
            placeholder={"Please enter a valid URL"}
            containerClassName="vl-form-input"
            label="X"
            name="twitter"
            value={values.twitter}
            onChange={handleChange}
            type="text"
            error={frontendErrors.twitter}
          />

          <InputFieldEmailTextPassword
            placeholder={"Please enter a valid URL"}
            containerClassName="vl-form-input"
            label="Instagram"
            name="instagram"
            value={values.instagram}
            onChange={handleChange}
            type="text"
            error={frontendErrors.instagram}
          />

          <DropdownSelectCommon
            label="Industry"
            value={selectedIndustry}
            onChange={handleChangeIndustry}
            options={values.industry}
            placeholder={"Select an industry"}
            isSearchable={true}
            error={frontendErrors.selectedIndustry}
          />

          <DropdownSelectCommon
            label="Social Impact Area"
            value={selectedSocialImpact}
            onChange={handleChangeSocialImpact}
            options={values.social}
            placeholder={"Select a social impact area"}
            isSearchable={true}
            error={frontendErrors.selectedSocialImpact}
          />

          {/* <div className="vl-form-input  vl-form-input--bordered-select-div">
            <label htmlFor="">Social Impact Area</label>
            <Select
              value={values.social}
              onChange={handleChangeInvestmentStage}
              options={socialOptions}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={values.social}
              isSearchable={false}
            />
          </div> */}
          <div>{renderMarketTags()}</div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-md-12 px-0">
          <TextareaField
            containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital basic_raise_capital_textarea"
            label="Company Bio"
            labelClassName="raise-capital-label-company-info"
            name="companyInfo"
            value={values.companyInfo}
            onChange={handleChange}
            error={frontendErrors.companyInfo}
            placeholder="Enter company description here..."
          />
        </div>
      </div>
      <div className="d-flex justify-content-end pt-3 mx-0">
        <SaveAndNextButton
          handleOnClickSaveAndNext={handleOnClickSaveAndNext}
          loading={loading}
        />
      </div>
    </div>
  );
}
