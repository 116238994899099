import React, { useState } from "react";
import InvestCalculatorDisplay from "./InvestCalculatorDisplay";
// import InvestLimitCalculatorCard from "./InvestLimitCalculatorCard";
import SaveButton from "../common/SaveButton";
import InvestCalculator from "./InvestCalculator";

/**
 * InvestAfterProfileSetupCalculator Component
 * 
 * Overview:
 * The InvestAfterProfileSetupCalculator component is responsible for rendering a calculator that allows users to perform investment calculations after setting up their profile. It provides the ability to edit and save the calculations.
 * 
 * Details:
 * - The component uses the useState hook to manage the isEdit state, which determines whether the calculator is in edit mode or not.
 * - The handleOnClickEdit function is called when the "Edit" button is clicked, and it sets the isEdit state to true.
 * - The handleOnClickOfSaveMainCalcTab function is called when the "Save" button is clicked, and it sets the isEdit state to false.
 * - The component conditionally renders the InvestCalculatorDisplay component and the SaveButton component based on the isEdit state.
 * - When isEdit is false, the InvestCalculatorDisplay component is rendered along with the "Edit" button.
 * - When isEdit is true, the InvestCalculator component is rendered with the isCalculatorMainTab prop set to true.
 * 
 * Dependencies:
 * - React: The component uses React to define and render the calculator.
 * - useState: The component uses the useState hook to manage the isEdit state.
 * - InvestCalculatorDisplay: The component depends on the InvestCalculatorDisplay component to render the calculator display.
 * - SaveButton: The component depends on the SaveButton component to render the "Edit" button.
 * - InvestCalculator: The component depends on the InvestCalculator component to render the calculator in edit mode.
 */
export default function InvestAfterProfileSetupCalculator() {
  const [isEdit, setIsEdit] = useState(false);

  const handleOnClickEdit = () => {
    setIsEdit(true);
  };

  const handleOnClickOfSaveMainCalcTab = () => {
    setIsEdit(false);
  };

  return (
    <>
      {!isEdit ? (
        <>
          <InvestCalculatorDisplay isDisplayTextInfo={false} />

          <div className="calculator-save-btn-div d-flex mx-0 justify-content-end">
            <SaveButton
              text="Edit"
              handleOnClickSaveButton={handleOnClickEdit}
            />
          </div>
        </>
      ) : (
        <InvestCalculator
          isCalculatorMainTab={true}
          handleOnClickOfSaveMainCalcTab={handleOnClickOfSaveMainCalcTab}
        />
      )}
      {/* <div className="invest-after-profile-setup-calculator-div">
        <div className="invest-after-profile-setup-calculator-col1">
          <div className="row mx-0 justify-content-between">
            <div>
              <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col1-text1">
                Yearly Income ($)
              </h4>
              <h5 className="font-18-roboto-medium invest-after-profile-setup-calculator-col1-text2">
                Yearly Income ($)
              </h5>
            </div>
            <div>
              <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col1-text1">
                Net Worth ($)
              </h4>
              <h5 className="font-18-roboto-medium invest-after-profile-setup-calculator-col1-text2">
                Net Worth ($)
              </h5>
            </div>
          </div>
          <div className="row mx-0 justify-content-between invest-after-profile-setup-calculator-col1-row2">
            <div>
              <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col1-text1">
                Job Title
              </h4>
              <h5 className="font-18-roboto-medium invest-after-profile-setup-calculator-col1-text2">
                Job Title
              </h5>
            </div>
            <div>
              <h4 className="font-24-roboto-bold invest-after-profile-setup-calculator-col1-text1">
                Job Industry
              </h4>
              <h5 className="font-18-roboto-medium invest-after-profile-setup-calculator-col1-text2">
                Job Industry
              </h5>
            </div>
          </div>
        </div>
        <InvestLimitCalculatorCard />
      </div>
     */}
    </>
  );
}
