import React from "react";
import { Link } from "react-router-dom";


/**
 * DefaultComponent Component
 * 
 * Overview:
 * This component renders a "Page Not Found" message with a link to the home page. It is used to handle 404 errors and provide a user-friendly message.
 * 
 * Details:
 * - The component renders a div with centered text.
 * - It includes an h1 element with the text "Page Not Found".
 * - It also includes a Link component from react-router-dom to navigate to the home page.
 * - The Link component is styled with inline CSS to have a bold font weight and underline.
 * 
 * Dependencies:
 * - React: This component is built using React.
 * - react-router-dom: This component uses the Link component from react-router-dom to navigate to the home page.
 */
export default function DefaultComponent() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1 className="text-center pt-5">Page Not Found</h1>
      Click <Link to="/home"><span style={{ fontWeight: '700', textDecoration: 'underline' }}>here</span></Link> to go to home.
    </div>
  );
}
