import React from "react";
import isEmpty from "../web-app-store/validations/is-empty";

export default function DisplayCurrencyFormatString({ value }) {
  return (
    <>
      {!isEmpty(value)
        ? value.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: "currency",
            currency: "USD",
          })
        : "$0"}
    </>
  );
}
