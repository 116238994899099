import {
  SET_EXPLORE_ALL_ORGANIZATIONS,
  SET_EXPLORE_ALL_OFFERINGS,
  SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT,
  SET_EXPLORE_OFFERINGS,
  RESET_EXPLORE_OFFERINGS,
} from "./../types";

const initialState = {
  exploreAllOrg: {},
  exploreAllOfferings: {},
  exploreAllOfferingsActiveInvestment: {},
  exploreOfferingByOfferingId: {},
};

/**
 * exploreReducer
 * 
 * Overview:
 * The exploreReducer is a Redux reducer function that manages the state related to exploring organizations and offerings in the web app store. It handles actions related to setting and resetting the explore data.
 * 
 * Details:
 * - The reducer function takes the current state and an action as parameters and returns the updated state based on the action type.
 * - The reducer uses the spread operator (...) to create a new state object with the existing state properties and updates the specific properties related to explore data based on the action payload.
 * - The reducer handles the following action types:
 *   - SET_EXPLORE_ALL_ORGANIZATIONS: Updates the exploreAllOrg property of the state with the action payload.
 *   - SET_EXPLORE_ALL_OFFERINGS: Updates the exploreAllOfferings property of the state with the action payload.
 *   - SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT: Updates the exploreAllOfferingsActiveInvestment property of the state with the action payload.
 *   - SET_EXPLORE_OFFERINGS: Updates the exploreOfferingByOfferingId property of the state with the action payload.
 *   - RESET_EXPLORE_OFFERINGS: Resets the exploreOfferingByOfferingId property of the state to an empty object.
 * - If the action type does not match any of the above, the reducer returns the current state without any modifications.
 * 
 * Usage:
 * This reducer is typically used in conjunction with Redux store and actions to manage the explore data in the web app store. It can be imported and combined with other reducers using the combineReducers function from Redux.
 * 
 * Dependencies:
 * - This reducer does not have any external dependencies.
 */
export default function exploreReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXPLORE_ALL_ORGANIZATIONS:
      return {
        ...state,
        exploreAllOrg: action.payload,
      };

    case SET_EXPLORE_ALL_OFFERINGS:
      return {
        ...state,
        exploreAllOfferings: action.payload,
      };

    case SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT:
      return {
        ...state,
        exploreAllOfferingsActiveInvestment: action.payload,
      };

    case SET_EXPLORE_OFFERINGS:
      return {
        ...state,
        exploreOfferingByOfferingId: action.payload,
      };

    case RESET_EXPLORE_OFFERINGS:
      return {
        ...state,
        exploreOfferingByOfferingId: {},
      };

    default:
      return state;
  }
}
