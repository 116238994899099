import React, {
  useState,
  //  useEffect
} from "react";
import Modal from "react-responsive-modal";
import { Link, useHistory } from "react-router-dom";

// import { exploreWebAppLink } from "../../../components/desktop/header-footer/pageLinks";

// api
import { raiseCapitalSaveProfileSuccessAction } from "../../../web-app-store/actions/raiseCapitalAction";
import darkLogo from "../../../assets/imgs/desktop/common/dark.png";

import { useDispatch } from "react-redux";
import { Button, Image } from "react-bootstrap";

let logo = "/web-app-img/desktop/all-logo/vl-sm-logo.png";
// import "../../../web-app-assets/scss/desktop/raise-capital/_raise-capital-submitted.scss";

/**
 * ProfileReviewModalRaiseCapital Component
 *
 * Overview:
 * This component displays a modal that shows a message to the user after their application has been submitted. It provides a notification about the next steps and an option to close the modal.
 *
 * Details:
 * - The component uses the Modal component from a library to display the modal.
 * - It receives two props: linkPath (the path to navigate to when the modal is closed) and raiseCapitalVal (a boolean value indicating if the user has raised capital).
 * - The component uses the useDispatch and useState hooks from the Redux library for state management.
 * - The handleOpenModal function is called when the modal is closed. It dispatches an action to update the raiseCapitalVal state and toggles the open state.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses the useDispatch hook from Redux for dispatching actions.
 * - Modal: The component uses the Modal component from a library for displaying the modal.
 * - logo: The component uses an image file named "logo" for displaying a logo.
 * - Link: The component uses the Link component from React Router for navigation.
 */
export default function ProfileReviewModalRaiseCapital({
  linkPath,
  raiseCapitalVal,
}) {
  // api
  const dispatch = useDispatch();

  // const [open, setOpen] = useState(true);
  const history = useHistory();

  /*==========================
             handler
 ===========================*/
  const handleOpenModal = () => {
    history?.push(linkPath);
    if (raiseCapitalVal) {
      dispatch(raiseCapitalSaveProfileSuccessAction(false));
    }
  };

  return (
    <>
      {/* <Modal
        open={open}
        onClose={handleOpenModal}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal customModal--profile-review",
          closeButton: "customCloseButton",
        }}
      >
        <div className="profile-review-div">
          <div className="profile-review-img-div">
            <img src={logo} alt="vedslab logo" className="profile-review-img" />
          </div>
          <h2 className="pr-roboto-font-40-medium">
            Your application has been submitted!
          </h2>
          <h5 className="pr-roboto-font-18-regular">
            You will be notified shortly regarding next steps
          </h5>
          <div className="row mx-0 justify-content-center mt-51 pb-32">
          </div>
          <Link to={linkPath}>
            <div onClick={handleOpenModal}>
              <span className="pr-do-it-text">
                &nbsp;&nbsp;Close&nbsp;&nbsp;
              </span>
            </div>
          </Link>
        </div>
      </Modal> */}
      <div className="application-submitted-wrap">
        <Image src={darkLogo} className="reviewed-image" />
        <h1 className="application-submitted-title">
          Your application has been submitted!
        </h1>
        <p className="notified-text">
          You will be notified shortly regarding next steps.
        </p>
        <Button onClick={handleOpenModal} className="explore-button">
          Explore Listings
        </Button>
      </div>
    </>
  );
}
