import React from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

/**
 * DatepickerSingle Component
 *
 * Overview:
 * The DatepickerSingle component is used to display a single datepicker input field. It allows the user to select a date from a calendar and provides options for custom formatting and error handling.
 *
 * Details:
 * - The component renders a datepicker input field using the DatePicker component from the react-datepicker library.
 * - The component accepts props for configuring the label, initial value, change event handler, arrow icon display, and disabled state.
 * - The component uses the createAutoCorrectedDatePipe function from the react-text-mask-addons library to automatically correct the date format as the user types.
 * - When the component is disabled, it renders a disabled input field without the arrow icon.
 * - When the component is enabled, it renders an input field with the arrow icon on the right side. Clicking the arrow icon triggers the handleOnClickDateArrowIcon event handler.
 * - The component supports error handling by displaying an error message below the input field when the error prop is provided.
 *
 * Dependencies:
 * - react-datepicker: The component uses the DatePicker component from the react-datepicker library to render the datepicker input field.
 * - react-text-mask-addons: The component uses the createAutoCorrectedDatePipe function from the react-text-mask-addons library to automatically correct the date format.
 */
export default function DatepickerSingle({
  startDateLabel,
  startDateValue,
  handleChangeStart,
  isDisplayArrow,
  handleOnClickDateArrowIcon,
  isDisabled,
  error,
  extraClass,
  placeholderText,
  isHideLabel,
  formate,
  maskFormate,
}) {
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy HH:MM");
  return (
    <>
      {isDisabled ? (
        <div className="d-flex mx-0 flex-nowrap justify-content-between align-items-start">
          <div
            className={`date-picker-common date-picker-common--form-field vl-form-input mr-30 ${
              extraClass ? extraClass : ""
            }`}
          >
            {isHideLabel ? "" : <label htmlFor="">{startDateLabel}</label>}

            <DatePicker
              selected={startDateValue}
              selectsStart
              //   startDate={startDateValue}
              //   endDate={endDateValue}
              dateFormat="MM/dd/yyyy"
              placeholderText={placeholderText || "mm/dd/yyyy"}
              disabled={isDisabled}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex mx-0 flex-nowrap justify-content-between align-items-start">
          <div
            className={`date-picker-common date-picker-common--form-field vl-form-input mr-30 ${
              extraClass ? extraClass : ""
            }`}
          >
            {isHideLabel ? "" : <label htmlFor="">{startDateLabel}</label>}
            <DatePicker
              selected={startDateValue}
              selectsStart
              //   startDate={startDateValue}
              //   endDate={endDateValue}
              onChange={(val) =>
                handleChangeStart(format(val, "YYYY/MM/DD").split("T"))
              }
              dateFormat={formate || "MM/dd/yyyy"}
              placeholderText={placeholderText || "mm/dd/yyyy"}
              disabled={isDisabled}
              customInput={
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={true}
                  keepCharPositions={true}
                  pipe={autoCorrectedDatePipe}
                />
              }
            />
            {error ? (
              <p className="error-message">{error}</p>
            ) : (
              <p className="error-message opacity-0">error</p>
            )}
          </div>
          {isDisplayArrow && (
            <img
              src={"/web-app-img/desktop/icons/date-arrow-icon.svg"}
              alt="go"
              className="date-block-arrow-go"
              onClick={handleOnClickDateArrowIcon}
            />
          )}
        </div>
      )}
    </>
  );
}

DatepickerSingle.defaultProps = {
  isDisplayArrow: false,
};
