/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import SideNavbar from "../../../../web-app-components/desktop/header/SideNavbar";
import Footer from "../../header-footer/Footer";
import TextareaField from "../../../../web-app-components/desktop/common/TextareaField";
import InputFieldPhoneCountryNumber from "../../../../web-app-components/desktop/common/InputFieldPhoneCountryNumber";
import Select from "react-select";

// api
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editInterestOption,
  interestOptions,
  mentorOptions,
} from "../../../../web-app-components/desktop/common/allDropdownOptions";
import isEmpty from "../../../../web-app-store/validations/is-empty";
import ImageCropModal from "../../../../web-app-components/desktop/common/ImageCropModal";
import SkipButton from "../../../../web-app-components/desktop/common/SkipButton";
import SaveButton from "../../../../web-app-components/desktop/common/SaveButton";
import DisplayLoader from "../../../../web-app-components/desktop/common/DisplayLoader";
import PageTitle from "../../../../web-app-components/desktop/header/PageTitle";
import {
  createUserProfileImageAction,
  getUserProfileImageAction,
  updateUserAddressAction,
  updateUserBioAction,
  updateUserInfoAction,
  updateUserInterestAction,
  updateUserPhoneNoAction,
  updateUserProfileImageAction,
} from "../../../../web-app-store/actions/authAction";
import MentorProfileGeneral from "./MentorProfileGeneral";
import InputFieldEmailTextPassword from "../../../../web-app-components/desktop/common/InputFieldEmailTextPassword";
import GeneralProfileInvestment from "../../../../web-app-components/desktop/general-profile/GeneralProfileInvestment";
import MentorProfileReview from "../../../../web-app-components/desktop/general-profile/MentorProfileReview";
import { getMentorByUserId } from "../../../../web-app-store/actions/mentorAction";
import format from "date-fns/format";
import DatepickerSingle from "../../../../web-app-components/desktop/common/DatepickerSingle";

/**
 * MentorProfile Component
 *
 * Overview:
 * The MentorProfile component is responsible for displaying and managing the profile information of a mentor. It allows the mentor to edit their bio, location, phone number, and profile picture. It also provides the functionality to save the changes made to the profile.
 *
 * Details:
 * - The MentorProfile component is a functional component that uses React hooks for state management.
 * - It imports several sub-components such as SideNavbar, Footer, TextareaField, InputFieldPhoneCountryNumber, Select, ImageCropModal, SkipButton, SaveButton, DisplayLoader, PageTitle, MentorProfileGeneral, InputFieldEmailTextPassword, and GeneralProfileInvestment.
 * - The component fetches the mentor's profile data from the Redux store using the useSelector hook.
 * - It initializes state variables for managing the profile data, loading states, edit modes, character count, and errors.
 * - The component includes several useEffect hooks to fetch the user profile image, update the mentor data when it changes, and initialize the profile data from local storage.
 * - The component provides various event handlers for saving changes to the profile, opening and closing the image crop modal, handling image cropping, and managing the edit modes for different profile fields.
 * - It renders the profile picture, name, active/inactive status, location, phone number, email address, bio, and interested in dropdown.
 * - The component conditionally renders different sub-components based on the mentor's interest (Mentoring or Investment).
 *
 * Usage:
 * To use the MentorProfile component, import it into the desired location in your application and render it as a child component. Make sure to provide the necessary Redux store and dependencies for the component to function properly.
 *
 * Dependencies:
 * - React: The MentorProfile component is built using React.
 * - React Redux: The component uses the useDispatch and useSelector hooks to interact with the Redux store.
 * - react-select: The component uses the Select component from the react-select library for the interested in dropdown.
 * - Other custom sub-components: The component imports several custom sub-components for rendering different parts of the profile page.
 * - Redux actions and reducers: The component relies on specific Redux actions and reducers for fetching and updating the mentor profile data.
 */

const MentorProfile = () => {
  const dispatch = useDispatch();
  const mentorRecord = useSelector((state) => state.mentor.mentor);

  // user data
  let userData = JSON.parse(localStorage.getItem("userData"));

  const [frontEndErrors, setFrontEndErrors] = useState({});
  const [loadingProfileImage, setLoadingProfileImage] = useState(false);
  const [mentorData, setMentorData] = useState();
  const [loadingBio, setLoadingBio] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditLocation, setIsEditLocation] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isPhoneNoEdit, setIsPhoneNoEdit] = useState(false);
  const [loadingPhoneNo, setLoadingPhoneNo] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [isCharCount, setIsCharCount] = useState(false);
  const [errors, setErrors] = useState({
    bio: "",
  });
  const [values, setValues] = useState({
    bio: "",
    location: "",
    phoneNo: "",
    fileName: "",
    fileData: {},
    imgUrl: "",
    DOB: "",
  });

  useEffect(() => {
    dispatch(getUserProfileImageAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMentorByUserId());
  }, []);

  useEffect(() => {
    if (!isEmpty(mentorRecord)) {
      setMentorData(mentorRecord[0]);
    }
  }, [mentorRecord]);

  const [interestValue, setinterestValue] = useState(
    !isEmpty(userData.interest) ? userData.interest : ""
  );

  useEffect(() => {
    let userDataObj = JSON.parse(localStorage.getItem("userData"));
    let valuesObj = {
      bio: userDataObj.bio,
      location: userDataObj.Address,
      phoneNo: userDataObj.phoneNumber,
      DOB: userDataObj?.DOB === null ? null : new Date(userDataObj?.DOB),
      fileName: isEmpty(userDataObj.user_profile)
        ? null
        : userDataObj.user_profile.url,
      fileData: isEmpty(userDataObj.user_profile)
        ? null
        : userDataObj.user_profile,
      imgUrl: isEmpty(userDataObj.user_profile)
        ? null
        : userDataObj.user_profile.url,
    };
    setValues({ ...valuesObj });

    let interestObj = !isEmpty(userDataObj.interest)
      ? interestOptions.filter((a) => a.value === userDataObj.interest)[0]
      : "";
    setinterestValue(interestObj);
  }, []);

  /*============================================================
                           handler
  ============================================================*/
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cropImgBlob, setCropImgBlob] = useState({});

  const [displayCancelSaveButtons, setDisplayCancelSaveButtons] =
    useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    // setValues({
    //   ...values,
    //   fileData: "",
    //   imgUrl: "",
    // });

    setIsOpenModal(false);
  };

  const handleOnClickSaveCropImg = (val, imgUrl) => (e) => {
    setFrontEndErrors({});
    e.preventDefault();

    setValues({
      ...values,
      fileData: val,
      imgUrl: imgUrl,
    });

    setIsOpenModal(false);
    if (!isEmpty(imgUrl)) {
      setDisplayCancelSaveButtons(true);
    }
  };

  const getBlobDataFromImageCrop = (data) => {
    setCropImgBlob(data);
  };

  const handleOnClickSkip = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      fileName: isEmpty(userData.user_profile)
        ? null
        : userData.user_profile.url,
      fileData: isEmpty(userData.user_profile) ? null : userData.user_profile,
      imgUrl: isEmpty(userData.user_profile) ? null : userData.user_profile.url,
    });

    setDisplayCancelSaveButtons(false);
  };

  const callBackProfileImageStatus = (status) => {
    setLoadingProfileImage(false);
    if (status === 200) {
      setDisplayCancelSaveButtons(false);
    }
  };

  const handleOnClickSave = () => {
    if (!isEmpty(values.imgUrl)) {
      setLoadingProfileImage(true);
      let formDataNew = new FormData();

      if (isEmpty(cropImgBlob)) {
        formDataNew.append("files.profile_image", values.fileData);
      } else {
        formDataNew.append("files.profile_image", cropImgBlob);
      }

      let newData = {
        user: userData.id,
      };
      formDataNew.append("data", JSON.stringify(newData));

      if (isEmpty(userData.user_profile)) {
        dispatch(
          createUserProfileImageAction(formDataNew, callBackProfileImageStatus)
        );
      } else {
        dispatch(
          updateUserProfileImageAction(formDataNew, callBackProfileImageStatus)
        );
      }
    }
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleChange = (event) => {
    if (event.target.value.trim("") === "") {
      setErrors({
        ...errors,
        bio: "Bio is required",
      });
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.value.length <= 700) {
      setErrors({
        ...errors,
        bio: "",
      });
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
      setIsCharCount(true);
      setCharCount(event.target.value.length);
    } else if (event.target.value.length > 700) {
      setErrors({
        ...errors,
        bio: "Max length is 700 character",
      });
    }
  };

  const handleChangePhoneInput = (val) => {
    setValues({
      ...values,
      phoneNo: val,
    });
  };

  const callBackPhoneNoStatus = (status) => {
    setLoadingPhoneNo(false);
    if (status === 200) {
      setIsPhoneNoEdit(false);
    }
  };

  const handlePhoneNoSave = () => {
    let formData = {
      phoneNumber: values.phoneNo,
    };
    setLoadingLocation(true);
    dispatch(
      updateUserPhoneNoAction(userData.id, formData, callBackPhoneNoStatus)
    );
  };

  const callBackBioStatus = (status) => {
    setLoadingBio(false);
    if (status === 200) {
      setIsEdit(!isEdit);
    }
  };

  const handleSave = () => {
    let formData = {
      bio: values.bio,
    };
    setLoadingBio(true);
    dispatch(updateUserBioAction(userData.id, formData, callBackBioStatus));
  };

  const callBackLocationStatus = (status) => {
    setLoadingLocation(false);
    if (status === 200) {
      setIsEditLocation(false);
    }
  };

  function validateAge(birthDate) {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return birthDate < eighteenYearsAgo;
  }

  const handleLocationSave = () => {
    // let formData = {
    //   Address: values.location,
    // };

    if (validateAge(new Date(values?.DOB))) {
      setLoadingLocation(true);
      // dispatch(
      //   updateUserAddressAction(userData.id, formData, callBackLocationStatus)
      // );
      const formData = {
        DOB: format(values?.DOB, "YYYY-MM-DD"),
        firstname: values.firstname,
        lastname: values.lastname,
        phoneNumber: values.phoneNo,
        Address: values.location,
      };
      dispatch(
        updateUserInfoAction(
          userData.id,
          userData,
          formData,
          "",
          callBackLocationStatus
        )
      );
    }
  };

  const handleChangeInterest = (interestValue) => {
    setinterestValue(interestValue);
  };

  const [isEditIntereset, setIsEditIntereset] = useState(false);
  const [loadingInterest, setLoadingInterest] = useState(false);

  const callBackInterestStatus = (status) => {
    setLoadingInterest(false);
    if (status === 200) {
      setIsEditIntereset(false);
    }
  };

  const handleSaveInterest = () => {
    setLoadingInterest(true);
    let formData = {
      interest: interestValue.value,
    };
    setinterestValue(interestValue.value);
    dispatch(
      updateUserInterestAction(userData.id, formData, callBackInterestStatus)
    );
  };

  const renderDropdown = () => {
    return (
      <>
        <div className="vl-interested-in-block">
          <h4 className="general-profile-text-colm-block-text1">
            Interested In
          </h4>

          {isEditIntereset ? (
            <button onClick={handleSaveInterest}>
              {loadingInterest ? (
                <DisplayLoader loaderColor="#A4A6AA" />
              ) : (
                <div className="save-btn-wrap">
                  <i className="fa fa-save pencil-icon"></i>
                  <span className="save-btn-text">Save</span>
                </div>
              )}
            </button>
          ) : (
            <>
              <button onClick={() => setIsEditIntereset(true)}>
                <i className="fa fa-pencil pencil-icon"></i>
              </button>
            </>
          )}
        </div>

        {isEditIntereset ? (
          <div className="vl-form-input vl-form-input--bordered-select-div vl-dropdown-common">
            <Select
              value={interestValue}
              onChange={handleChangeInterest}
              options={editInterestOption}
              className="vl-select-container"
              classNamePrefix="vl-select"
              placeholder={"I'm interested in"}
              isSearchable={false}
            />
          </div>
        ) : (
          <h5 className="general-profile-text-colm-block-text2">
            {!isEmpty(userData.interest)
              ? userData.interest === "Mentoring"
                ? mentorOptions.filter((a) => a.value === userData.interest)[0]
                    .label
                : interestOptions.filter(
                    (a) => a.value === userData.interest
                  )[0].label
              : editInterestOption.filter(
                  (a) => a.value === userData.interest
                )[0].label}
          </h5>
        )}
      </>
    );
  };

  function validateAge(birthDate) {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return birthDate < eighteenYearsAgo;
  }
  const handleChangeStart = (date) => {
    setErrors({});
    if (date === null) {
      setValues({
        ...values,
        DOB: null,
      });
    } else {
      setValues({
        ...values,
        DOB: new Date(date[0]),
      });
      let error = { ...errors };
      if (validateAge(new Date(date[0]))) {
        error = {};
      } else {
        error = { DOB: "User should be 18+ years" };
      }
      setErrors(error);
    }
  };

  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div mentor_personal_profile_wrap">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle imgPath={null} pageTitle={null} />
          <div className="general_profile_name">
            {mentorData?.live_profile === "InActive Profile" ? (
              <div class="alert alert-success" role="alert">
                Your profile is under review.
              </div>
            ) : (
              ""
            )}
            <div className="d-flex align-items-baseline mb-2">
              <h1 className="general-profile-title pr-4">
                {/* First Name Last Name */}
                {userData.firstname + " " + userData.lastname}
              </h1>
              <div>
                {mentorData?.live_profile === "Active Profile" ? (
                  <span className="active-profile">Active Profile</span>
                ) : mentorData?.live_profile === "InActive Profile" ? (
                  <span className="inactive-profile">Pending Profile</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`mx-0 profilelist-top ${
                isEditLocation ? "align-items-start" : "align-items-center"
              }`}
            >
              <div className="vl-profile-page-image-upload-div col-lg-3 col-md-4 col-12 px-0">
                {!displayCancelSaveButtons ? (
                  <ImageCropModal
                    displayDefaultPlaceholderImg={false}
                    imgClass="mentor_profile_image"
                    containerClassName="upload-img__mainBlock upload-img__mainBlock--investor"
                    buttonName="Change Profile Picture"
                    error={frontEndErrors.imgUrl}
                    fileData={values.imgUrl}
                    handleOnClickSaveCropImg={handleOnClickSaveCropImg}
                    getBlobDataFromImageCrop={getBlobDataFromImageCrop}
                    isOpenModal={isOpenModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    body={
                      isEmpty(values.imgUrl) && (
                        <span className="upload-profile-picture">
                          Upload your image here
                        </span>
                      )
                    }
                  />
                ) : (
                  <div className="vl-profile-page-image-upload-div vl-profile-page-image-upload-div--display">
                    <ImageCropModal
                      displayDefaultPlaceholderImg={false}
                      containerClassName="upload-img__mainBlock upload-img__mainBlock--investor"
                      buttonName="Change Profile Picture"
                      error={frontEndErrors.imgUrl}
                      fileData={values.imgUrl}
                      handleOnClickSaveCropImg={handleOnClickSaveCropImg}
                      getBlobDataFromImageCrop={getBlobDataFromImageCrop}
                      isOpenModal={isOpenModal}
                      handleOpenModal={handleOpenModal}
                      handleCloseModal={handleCloseModal}
                      body={
                        isEmpty(values.imgUrl) && (
                          <span className="upload-profile-picture">
                            Upload your image here
                          </span>
                        )
                      }
                    />
                    <div className="row mx-0 pl-3 image-save-cancel-btn">
                      <SkipButton
                        text="Cancel"
                        handleOnClickSkipButton={handleOnClickSkip}
                      />
                      <SaveButton
                        text="Save"
                        handleOnClickSaveButton={handleOnClickSave}
                        loading={loadingProfileImage}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="w-100 genral_wrap">
                <div className="general-profile-bio-text">
                  {isEditLocation ? (
                    <button
                      onClick={handleLocationSave}
                      className="vl-profile-page-button"
                    >
                      {loadingLocation ? (
                        <DisplayLoader loaderColor="#A4A6AA" />
                      ) : (
                        <i className="fa fa-save"></i>
                      )}
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => setIsEditLocation(true)}
                        className="vl-profile-page-button"
                      >
                        <i className="fa fa-pencil pencil-icon"></i>
                      </button>
                    </>
                  )}
                </div>
                <div className="general-profile-text-colm-block text-capitalize px-0">
                  <div
                    className={`${
                      isEditLocation ? " mb-4 edit-option" : "mb-4 "
                    } row align-items-center`}
                  >
                    {isEditLocation ? (
                      ""
                    ) : (
                      <div
                        className={`  ${
                          isEditLocation ? "col-12 col-lg-6 " : "col-6 col-lg-4"
                        }`}
                      >
                        <div className="d-flex mx-0 align-items-center gp-bio-div p-0">
                          <h4 className="general-profile-text-colm-block-text1">
                            Location
                          </h4>
                        </div>
                      </div>
                    )}
                    <div
                      className={` d-flex  ${
                        isEditLocation
                          ? "col-12 col-lg-6 mb-4 edit-option"
                          : "col-6 col-lg-4"
                      }`}
                    >
                      {isEditLocation ? (
                        <InputFieldEmailTextPassword
                          containerClassName="vl-form-input vl-form-input--getStarted vl-form-location sign_up_date_main"
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                          type="text"
                          placeholder="Location"
                          label="Location"
                        />
                      ) : (
                        <h5 className="general-profile-text-colm-block-text2">
                          {/* Lorem Ipsum */}
                          {userData.Address}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="general-profile-text-colm-block px-0">
                  <div
                    className={`${
                      isEditLocation ? "phone-edit-input " : "mb-4"
                    } row align-items-center`}
                  >
                    {isEditLocation ? (
                      ""
                    ) : (
                      <div
                        className={`col-6 col-lg-4 ${
                          isEditLocation
                            ? "edit-input p-0 d-flex align-items-center"
                            : ""
                        }`}
                      >
                        <h4 className="general-profile-text-colm-block-text1">
                          Phone Number
                        </h4>
                      </div>
                    )}
                    <div
                      className={`${
                        isEditLocation ? "col-12 col-lg-6" : "col-6 col-lg-4"
                      }`}
                      // className={`col-6 col-lg-4 ${
                      //   isEditLocation ? "edit-input" : ""
                      // }`}
                    >
                      <div className="vl-profile-page-phone-number-block">
                        {isEditLocation ? (
                          <div className="get-started-phone-input-div">
                            <InputFieldPhoneCountryNumber
                              containerClassName="vl-form-input--getStarted"
                              name="phone"
                              placeholder="Phone Number"
                              phoneInput={values.phoneNo}
                              handleChangePhoneInput={handleChangePhoneInput}
                              label="Phone Number"
                              labelClass="phone_number_label"
                            />
                          </div>
                        ) : (
                          <InputFieldPhoneCountryNumber
                            containerClassName="input-country-phone-number-container--disabled"
                            textClassName="ps-0"
                            isHideLabel={true}
                            name="phone"
                            phoneInput={userData.phoneNumber}
                            isDisabled={true}
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex mx-0 align-items-center gp-bio-div p-0">
                      {/* <div className="general-profile-bio-text">
                      {isPhoneNoEdit ? (
                        <button
                          onClick={handlePhoneNoSave}
                          className="vl-profile-page-button"
                        >
                          {loadingPhoneNo ? (
                            <DisplayLoader loaderColor="#A4A6AA" />
                          ) : (
                            <i className="fa fa-save"></i>
                          )}
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => setIsPhoneNoEdit(true)}
                            className="vl-profile-page-button"
                          >
                            <i className="fa fa-pencil pencil-icon"></i>
                          </button>
                        </>
                      )}
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className="general-profile-text-colm-block email_edit_text px-0">
                  <div
                    className={`${
                      isEditLocation ? "mt-2 mb-0 edit_general_profile" : "mb-4"
                    } general-profile-inner row`}
                  >
                    {isEditLocation ? (
                      ""
                    ) : (
                      <div
                        className={` ${
                          isEditLocation ? "col-12 col-lg-6" : "col-6 col-lg-4"
                        }`}
                      >
                        <h4 className="general-profile-text-colm-block-text1">
                          Email Address
                        </h4>
                      </div>
                    )}
                    <div
                      className={` ${
                        isEditLocation ? "col-12 col-lg-6" : "col-6 col-lg-4"
                      }`}
                    >
                      {isEditLocation ? (
                        <InputFieldEmailTextPassword
                          containerClassName="vl-form-input mb-0"
                          label="Primary Email"
                          name="primaryEmail"
                          value={userData.email}
                          isReadOnly={true}
                          type="email"
                          // error={frontendErrors.primaryEmail}
                        />
                      ) : (
                        <h5 className="general-profile-text-colm-block-text2">
                          {/* Lorem Ipsum */}
                          {userData.email}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="general-profile-text-colm-block">
                  <div className="general-profile-inner row mb-4">
                    {isEditLocation ? (
                      ""
                    ) : (
                      <div className="col-6 col-lg-4">
                        <h4 className="general-profile-text-colm-block-text1">
                          Date Of Birth
                        </h4>
                      </div>
                    )}
                    <div
                      className={` ${
                        isEditLocation ? "col-12 col-lg-6" : "col-6  col-lg-4"
                      }`}
                    >
                      <h5 className="general-profile-text-colm-block-text2">
                        {isEditLocation ? (
                          <DatepickerSingle
                            startDateValue={values.DOB}
                            handleChangeStart={handleChangeStart}
                            error={errors.DOB}
                            className="funding-inputs-wrap mr-0"
                            extraClass="funding-inputs mr-0"
                            placeholderText="Date of Birth : dd/mm/yyyy"
                            startDateLabel=" Date Of Birth"
                            // formate="dd/MM/yyyy"
                            valueFormate="DD-MM-YYYY"
                            // maskFormate="dd/mm/yyy"
                          />
                        ) : (
                          format(userData.DOB, "MM-DD-YYYY")
                        )}
                        {/* Lorem Ipsum */}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="textarea_wrap">
              <div className=" mx-0 d-flex align-items-center gap-2 gp-bio-div general-profile-bio-main ">
                <h3 className="general-profile-bio-text mb-0">Bio</h3>
                {isEdit ? (
                  <button
                    onClick={handleSave}
                    className="vl-profile-page-button"
                  >
                    {loadingBio ? (
                      <DisplayLoader loaderColor="#A4A6AA"/>
                    ) : (
                      <i className="fa fa-save fs-6 text-black"></i>
                    )}
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleEdit(true)}
                      className="vl-profile-page-button"
                    >
                      <i className="fa fa-pencil pencil-icon fs-6 text-black"></i>
                    </button>
                  </>
                )}
              </div>
              {isEdit ? (
                <TextareaField
                  containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital raise_capital_profile_textarea"
                  name="bio"
                  value={values.bio}
                  onChange={handleChange}
                  placeholder="Add your bio"
                  error={errors.bio}
                  isHideLabel={true}
                />
              ) : (
                <TextareaField
                  containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital raise_capital_profile_textarea"
                  name="bio"
                  value={values.bio}
                  onChange={handleChange}
                  placeholder="Add your bio"
                  error={errors.bio}
                  isHideLabel={true}
                  isDisabled={true}
                />
              )}
              <p className="counter-text">
                {isCharCount && `Character limit ${charCount}/700`}
              </p>
            </div>
            <GeneralProfileInvestment />
            <MentorProfileReview />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
};

export default MentorProfile;
