/**
 * calcTotalInvestmentLimit Function
 * 
 * Overview:
 * This function calculates the total investment limit based on the net worth and annual income of an individual. It determines the investment limit by comparing the net worth and annual income with the maximum investment limit. The function applies different calculation formulas based on the comparison result.
 * 
 * Details:
 * - The function takes two parameters: net_worth (number) and annual_income (number).
 * - It defines the minimum investment limit as 2200 and the maximum investment limit as 107000.
 * - If either the net worth or the annual income is less than the maximum investment limit, the function calculates the total investment limit as the greater value between the net worth and the annual income multiplied by 0.05. It then returns the maximum of this calculated value and the minimum investment limit.
 * - If both the net worth and the annual income are greater than or equal to the maximum investment limit, the function calculates the total investment limit as the greater value between the net worth and the annual income multiplied by 0.1. It then returns the minimum of this calculated value and the maximum investment limit.
 * 
 * Dependencies:
 * None
 */
export const calcTotalInvestmentLimit = (net_worth, annual_income) => {
  const min_inv_limit = 2500;
  const max_inv_limit = 107000;
  let total_inv_limit;

  if (net_worth < max_inv_limit || annual_income < max_inv_limit) {
    total_inv_limit =
      (annual_income > net_worth ? annual_income : net_worth) * 0.05;
    return Math.max(total_inv_limit, min_inv_limit);
  } else {
    total_inv_limit =
      (annual_income > net_worth ? annual_income : net_worth) * 0.1;
    return Math.min(total_inv_limit, max_inv_limit);
  }
};
