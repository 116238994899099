import React from "react";
import Creatable from "react-select/creatable";

/**
 * DropdownCreatableSelectCommon Component
 * 
 * Overview:
 * This component renders a dropdown select input with the ability to create new options. It is commonly used in forms for selecting or creating options from a predefined list.
 * 
 * Details:
 * - The component accepts several props including label, value, onInputChange, onChange, options, placeholder, isSearchable, isHideLabel, and error.
 * - The label prop is used to display a label for the input field.
 * - The value prop represents the currently selected value of the dropdown.
 * - The onInputChange prop is a callback function that is called when the user types in the input field.
 * - The onChange prop is a callback function that is called when the user selects an option from the dropdown.
 * - The options prop is an array of objects representing the available options for the dropdown.
 * - The placeholder prop is used to display a placeholder text in the input field.
 * - The isSearchable prop determines whether the user can search for options in the dropdown.
 * - The isHideLabel prop determines whether the label should be hidden.
 * - The error prop is used to display an error message below the input field.
 * 
 * Dependencies:
 * - This component depends on the Creatable component from an external library.
 * - The Creatable component provides the functionality for creating new options in the dropdown.
 * - The className and classNamePrefix props are used to style the dropdown using CSS classes.
 */
function DropdownCreatableSelectCommon({
  label,
  value,
  onInputChange,
  onChange,
  options,
  placeholder,
  isSearchable,
  isHideLabel,
  error,
}) {
  return (
    <div className="vl-form-input  vl-form-input--bordered-select-div vl-dropdown-common">
      {!isHideLabel && <label htmlFor="">{label}</label>}
      <Creatable
        isClearable
        onInputChange={onInputChange}
        value={value}
        onChange={onChange}
        options={options}
        className="vl-select-container"
        classNamePrefix="vl-select"
        placeholder={placeholder}
        isSearchable={isSearchable}
      />
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className="error-message opacity-0">error</p>
      )}
    </div>
  );
}

DropdownCreatableSelectCommon.defaultProps = {
  label: "",
  placeholder: "",
  isSearchable: false,
  isHideLabel: false,
  error: "",
};

export default DropdownCreatableSelectCommon;
