import React from "react";
import img1 from "../../../assets/imgs/desktop/home/ss.png";
import investImage from "../../../web-app-assets/imgs/desktop/backgrounds/online-investment.json";
import startUpImage from "../../../web-app-assets/imgs/desktop/backgrounds/start-up.json";
import { Image } from "react-bootstrap";
import Lottie from "react-lottie-player";

/**
 * NewHomeFoldTwo Component
 * 
 * Overview:
 * The NewHomeFoldTwo component represents a section of the home page that showcases the features and mission of VedasLabs. It provides information about startups and investors, and includes animations and images to enhance the visual experience.
 * 
 * Details:
 * - The component is a functional component that returns JSX elements.
 * - It uses the AOS library for fade-in animations.
 * - The component is divided into two main sections: the left side with information about startups and investors, and the right side with a mission statement and an image.
 * - The left side includes sub-components for displaying information about startups and investors.
 * - The component does not accept any props.
 * 
 * Usage:
 * To use the NewHomeFoldTwo component, simply import it and include it in the desired location within the home page component.
 * 
 * Dependencies:
 * - AOS library: Used for fade-in animations.
 * - Lottie library: Used for displaying animated images.
 * - startUpImage: An animation data object for the startups section.
 * - investImage: An animation data object for the investors section.
 * - img1: An image source for the right side of the component.
 */
const NewHomeFoldTwo = () => {
  return (
    <div className="home-fold2-div-outer-div">
      <div
        className="home-fold2-div"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-easing="ease-in-out"
        data-aos-duration="1500"
        data-aos-once="true"
      >
        <div className="maincontainer-custom position-relative">
          <div className="row">
            <div className="col-lg-7">
              <div className="accelerate-inner">
                <h4>
                  We accelerate how capital is raised and communities are built.
                </h4>
                <h5>
                  At VedasLabs, we want to build a better world through the
                  startups available on our platform. We believe community,
                  innovation, and knowledge are the foundation for success.
                </h5>
                <div className="accelerateinner-main">
                  <div className="accelerateinner-text px-1">
                    <h2>STARTUPS</h2>
                    <Lottie
                      loop
                      animationData={startUpImage}
                      play
                      className="startup-image"
                    />
                    <h3>More than capital. </h3>
                    <p className="text-center">
                      Providing access to a community and network of mentors to
                      accelerate growth.
                    </p>
                  </div>
                  <div className="accelerateinner-text access-main text-center px-1">
                    <h2>INVESTORS</h2>
                    <Lottie
                      loop
                      animationData={investImage}
                      play
                      className="startup-image"
                    />
                    <h2>Open access.</h2>
                    <p className="accelerateinner-novice text-center">
                      Available to both novice investors and experienced angels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5"></div>
          </div>
        </div>
      </div>
      <div
        className="right-side-laptop_screen"
        // data-aos="fade-left"
        // data-aos-offset="300"
        // data-aos-easing="ease-in-out"
        // data-aos-duration="1500"
      >
        <h5 className="home-fold2-text">MISSION</h5>
        <img
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-out"
          data-aos-duration="1500"
          data-aos-once="true"
          src={img1}
          alt="p-cloud vedaslab"
          className="fold2-img"
        />
      </div>
    </div>
  );
};

export default NewHomeFoldTwo;
