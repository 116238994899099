import React from "react";
import ReactDOM from "react-dom";
import ReactPixel from "react-facebook-pixel";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init("1554168698261737", {}, options);

ReactPixel.pageView(); // For tracking page view

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

/**
 * injectGA function
 * 
 * Overview:
 * This function initializes Google Analytics tracking by injecting the necessary script and configuration.
 * 
 * Details:
 * - Checks if the code is running in a browser environment before executing.
 * - Creates a global dataLayer array if it doesn't exist.
 * - Defines a gtag function that pushes arguments to the dataLayer array.
 * - Calls gtag function to set up the tracking code and configuration.
 * 
 * Usage:
 * Call this function to initialize Google Analytics tracking in your application.
 * 
 * Dependencies:
 * This function requires the Google Analytics tracking code to be loaded on the page.
 * It assumes that the gtag function is available globally.
 */
const injectGA = () => {
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "GTM-K5SZLHZ");
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
