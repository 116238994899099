import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../header-footer/Navbar";
import { knowledgeBase } from "../header-footer/pageLinks";

/**
 * FaqFoldOne Component
 * 
 * Overview:
 * The FaqFoldOne component is responsible for rendering the first fold of the Frequently Asked Questions section. It includes a navigation bar, a title, and a footer section.
 * 
 * Details:
 * - The component renders a div container with the class "faq-fold-one-div" that contains a Navbar component and a title with the class "faq-title".
 * - It also renders a footer section with the class "d-flex mx-0 footer-row-div footer-row-div--privacy-policy" that includes a link to the knowledge base, a knowledge base div with the class "footer-knowledge-div", and a footer top right div with a description.
 * - The component accepts no props.
 * 
 * Usage:
 * To use the FaqFoldOne component, import it from the specified file path and include it in the desired location within the application's JSX code.
 * 
 * Dependencies:
 * - Navbar component: This component is imported and rendered within the FaqFoldOne component.
 * - Link component: This component is imported and used to create a link to the knowledge base.
 */
export default function FaqFoldOne() {
  return (
    <>
      <div className="faq-fold-one-div">
        <Navbar />
        <h2 className="font-55-montserrat-bold faq-title">
          Frequently Asked Questions
        </h2>
      </div>
      <div className="d-flex mx-0 footer-row-div footer-row-div--privacy-policy">
        <Link to={knowledgeBase}>
          <div className="footer-knowledge-div">
            <h5 className="font-30-montserrat-bold">Knowledge Base &#62; </h5>
          </div>
        </Link>
        <div className="footer-top-right-div">
          <h5 className="font-25-montserrat-regular">
            All early stage investments entail risk and we are committed to
            helping you understand if investing is suitable for you.
          </h5>
        </div>
      </div>
      {/* <div className="row faq-fold-one-bottom-div footer-row-div  mx-0 justify-content-end">
        <Link to={knowledgeBase}>
          <div className="faq-fold-one-kb-div row mx-0 align-items-center justify-content-center">
            <h5 className="font-25-montserrat-bold">KNOWLEDGE BASE &#62; </h5>
          </div>
        </Link>
        <div className="faq-fold-one-right-div">
          <h5 className="font-22-montserrat-regular">
            All early stage investments entail risk and we are committed to
            helping you understand if investing is suitable for you.
          </h5>
        </div>
      </div> */}
    </>
  );
}
