/**
 * reportWebVitals
 * 
 * Overview:
 * This function is responsible for reporting web vitals metrics. It allows the user to pass a callback function that will be called with the web vitals data.
 * 
 * Details:
 * - The function checks if the passed callback function is a valid function.
 * - It imports the 'web-vitals' module and then calls the necessary functions to get the web vitals data.
 * - The web vitals data is then passed to the callback function.
 * 
 * Usage:
 * To use this function, pass a callback function as an argument. The callback function will be called with the web vitals data.
 * 
 * Dependencies:
 * This function depends on the 'web-vitals' module to get the web vitals data.
 * 
 * @param {Function} onPerfEntry - The callback function to be called with the web vitals data.
 */
const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
