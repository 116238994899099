import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_EXPLORE_ALL_ORGANIZATIONS,
  SET_EXPLORE_ALL_OFFERINGS,
  SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT,
  SET_EXPLORE_OFFERINGS,
} from "./../types";

/*===================================================================================
            get explore details investor by user id
====================================================================================*/
/**
 * Get Explore Details Investor By User ID Action
 * 
 * Overview:
 * Fetches investment details associated with a specific user ID. This could include the user's investment preferences, history, or related data.
 * 
 * Details:
 * - Performs a GET request to retrieve investor data for a given user ID.
 * - Upon successful retrieval, the data is passed to a callback function for further processing.
 * 
 * Usage:
 * This action can be dispatched to load user-specific investment details, such as when viewing a user's profile or investment dashboard.
 * Example: `dispatch(getExploreDetaisInvestorByUserId(userId, callBackInvestorData))`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 */
export const getExploreDetaisInvestorByUserId =
  (userId, callBackInvestorData) => async (dispatch) => {
    try {
      let { data } = await axios.get(`${backendUrl}/investors?user=${userId}`);
      if (data) {
        callBackInvestorData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore all organizations
====================================================================================*/
/**
 * Get Explore All Organizations Action
 * 
 * Overview:
 * Retrieves all organizations from the backend, potentially for display in an explore or discovery section within the application.
 * 
 * Details:
 * - Makes a GET request to fetch all organizations.
 * - Dispatches an action to update the application state with the retrieved organizations.
 * 
 * Usage:
 * Can be used in the context of exploring or searching through various organizations registered in the application.
 * Example: `dispatch(getExploreAllOrganizations())`
 * 
 * Dependencies:
 * - `axios` for HTTP requests.
 * - Redux's dispatch function for state management.
 */
export const getExploreAllOrganizations = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/organizations`);
    if (data) {
      dispatch({
        type: SET_EXPLORE_ALL_ORGANIZATIONS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get explore all offerings / fundraiser 
====================================================================================*/
/**
 * Get Explore All Offerings Action
 * 
 * Overview:
 * Fetches all offerings (fundraisers) from the backend. This action is useful for populating lists or explore pages with various investment opportunities.
 * 
 * Details:
 * - Executes a GET request to obtain all offerings.
 * - Dispatches an action with the offerings data to update the state.
 * 
 * Usage:
 * Ideal for listing all available investment opportunities in an explore or discover feature within the application.
 * Example: `dispatch(getExploreAllOfferings())`
 * 
 * Dependencies:
 * The same as `Get Explore All Organizations Action`.
 */
export const getExploreAllOfferings = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/offerings`);
    if (data) {
      dispatch({
        type: SET_EXPLORE_ALL_OFFERINGS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
          get explore all offerings / fundraiser active investment
====================================================================================*/
/**
 * Get Explore All Offerings Active Investment Action
 * 
 * Overview:
 * Retrieves offerings that are currently accepting investments, sorted by their last update time in descending order.
 * 
 * Details:
 * - Sends a GET request with specific query parameters to filter active investment opportunities.
 * - Updates the redux state with the fetched data using a dispatched action.
 * 
 * Usage:
 * Utilized to showcase active or recently updated investment opportunities to potential investors.
 * Example: `dispatch(getExploreAllOfferingsActiveInvestment())`
 * 
 * Dependencies:
 * The same as `Get Explore All Organizations Action`.
 */
export const getExploreAllOfferingsActiveInvestment =
  () => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/offerings?offering_status=Accepting_Investment&offering_status=Offering_Posted&offering_status=VL_Reserve&_sort=updated_at:DESC`
      );
      if (data) {
        dispatch({
          type: SET_EXPLORE_ALL_OFFERINGS_ACTIVE_INVESTMENT,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore offerings / fundraiser by id
====================================================================================*/
/**
 * Get Explore Organization By ID Action
 * 
 * Overview:
 * Fetches details of a specific organization by its ID, allowing for detailed views of organizations within the application.
 * 
 * Details:
 * - Performs a GET request to retrieve detailed information of an organization.
 * - The fetched data is passed to a callback function for use in UI components or further processing.
 * 
 * Usage:
 * Dispatched when viewing the profile or detailed information of a specific organization.
 * Example: `dispatch(getExploreOrganizationById(orgId, callBackOrgData))`
 * 
 * Dependencies:
 * The same as `Get Explore Details Investor By User ID Action`.
 */
export const getExploreOrganizationById =
  (orgId, callBackOrgData) => async (dispatch) => {
    try {
      let { data } = await axios.get(`${backendUrl}/organizations/${orgId}`);
      if (data) {
        callBackOrgData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore offerings / fundraiser by id
====================================================================================*/
/**
 * Get Explore Offerings By ID Action
 * 
 * Overview:
 * Retrieves detailed information about a specific offering by its ID. This can include information pertinent to investors or those interested in the fundraiser.
 * 
 * Details:
 * - Makes a GET request using the offering's ID to fetch its details.
 * - A callback function is invoked with the offering data upon successful retrieval.
 * 
 * Usage:
 * This action is crucial for obtaining detailed information about an offering, useful in offering-specific pages or modal dialogs.
 * Example: `dispatch(getExploreOfferingsById(offeringId, callBackOfferingData))`
 * 
 * Dependencies:
 * The same as `Get Explore Details Investor By User ID Action`, without the Redux dispatch.
 */
export const getExploreOfferingsById =
  (offeringId, callBackOfferingData) => async () => {
    try {
      let { data } = await axios.get(`${backendUrl}/offerings/${offeringId}`);
      if (data) {
        callBackOfferingData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore offerings / fundraiser by org id
====================================================================================*/
/**
 * Get Explore Offerings By Org ID Action
 * 
 * Overview:
 * Fetches all offerings associated with a specific organization ID. This is used to display all fundraisers or investment opportunities related to a particular organization.
 * 
 * Details:
 * - Initiates a GET request with an organization ID to filter offerings.
 * - Calls back with the filtered offerings data upon success.
 * 
 * Usage:
 * Useful in organization profiles or sections where all related offerings need to be displayed.
 * Example: `dispatch(getExploreOfferingsByOrgId(orgId, callBackOfferingData))`
 * 
 * Dependencies:
 * The same as `Get Explore Offerings By ID Action`.
 */
export const getExploreOfferingsByOrgId =
  (orgId, callBackOfferingData) => async () => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/offerings?organization=${orgId}`
      );
      if (data) {
        callBackOfferingData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore offerings / fundraiser by org id
====================================================================================*/
/**
 * Get Raise Capital Offerings By Offering ID Store State Action
 * 
 * Overview:
 * Retrieves detailed information about a specific offering by its ID and updates the application state with this data. This action is crucial for displaying detailed information about offerings in the context of raising capital.
 * 
 * Details:
 * - Performs a GET request to fetch details of an offering identified by its ID.
 * - Upon successful data retrieval, dispatches an action to update the redux store with the offering's details.
 * 
 * Usage:
 * This action is dispatched when needing to fetch and display detailed information about a specific offering, such as in detailed view pages or offering summaries.
 * Example: `dispatch(getRaiseCapitalOfferingsByOfferingIdStoreState(offeringId))`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - Redux's dispatch function for state management, specifically updating the application state with the fetched offering details.
 */
export const getRaiseCapitalOfferingsByOfferingIdStoreState =
  (offeringId) => async (dispatch) => {
    try {
      let { data } = await axios.get(`${backendUrl}/offerings/${offeringId}`);
      if (data) {
        dispatch({
          type: SET_EXPLORE_OFFERINGS,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            get explore tags by id
====================================================================================*/
/**
 * Get Explore Tags By ID Action
 * 
 * Overview:
 * Fetches tags associated with a specific organization ID, allowing for the categorization or tagging of organizations within the explore or discovery sections of the application.
 * 
 * Details:
 * - Sends a GET request to retrieve tags linked to an organization by its ID.
 * - Utilizes a callback function to pass the retrieved tags data for further processing or display.
 * 
 * Usage:
 * Useful for filtering or displaying organizations based on their tags, enhancing the user's ability to navigate and explore organizations within the application.
 * Example: `dispatch(getExploreTagsById(orgId, callBackTagsData))`
 * 
 * Dependencies:
 * - `axios` for HTTP requests, used to fetch the tags associated with the specified organization ID.
 */
export const getExploreTagsById =
  (orgId, callBackTagsData) => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/tags?organization=${orgId}`
      );
      if (data) {
        callBackTagsData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                         Reserve Amount
=================================================================*/
/**
 * Reserve Amount Action
 * 
 * Overview:
 * Handles the reservation of an amount for a specific offering by a user. This action updates the total amount reserved for an offering in the backend.
 * 
 * Details:
 * - Retrieves the current total amount reserved for the offering and calculates the new total with the added reservation.
 * - Submits the reservation data and updates the offering's total reserved amount in the backend.
 * - Calls back with the status of the operation.
 * 
 * Usage:
 * Dispatched when a user decides to reserve an amount in a fundraiser or investment opportunity.
 * Example: `dispatch(ReserveAmountAction(formData, callBack))`
 * 
 * Dependencies:
 * The same as `Get Explore All Organizations Action`.
 */
export const ReserveAmountAction = (formData, callBack) => async (dispatch) => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/offerings/${formData.offering_Id}`
    );
    const total_amount_reserves =
      Number(data.total_amount_reserves) + Number(formData.amount);
    // if (total_amount_reserves > Number(data.funding_goal)) {
    //   return callBack(400, Number(data.funding_goal) - Number(data.total_amount_reserves));
    // }
    await axios.post(`${backendUrl}/reserves`, formData);
    if (data) {
      await axios.put(`${backendUrl}/offerings/${formData.offering_Id}`, {
        total_amount_reserves,
      });
      let data1 = { success: "200" };
      let status = 200;
      if (data1) {
        // Toast.info("Information saved", 3000);
        callBack(status);
      }
    }
  } catch (err) {
    console.log(err);
  }
};

/*================================================================
                        VL - Reserve Amount
=================================================================*/
/**
 * VL Reserve Amount Action
 * 
 * Overview:
 * Similar to `ReserveAmountAction`, but specifically for VL (variable limit) reserves. It updates the total VL reserves for an offering.
 * 
 * Details:
 * - Fetches current VL reserve data and updates it with the new reservation amount.
 * - Performs backend updates for the offering's VL reserve totals and notifies of success via a callback.
 * 
 * Usage:
 * Used for VL reserve actions in the application, allowing users to commit to a variable limit reservation on an offering.
 * Example: `dispatch(VLReserveAmountAction(formData, callBack))`
 * 
 * Dependencies:
 * The same as `Reserve Amount Action`.
 */
export const VLReserveAmountAction =
  (formData, callBack) => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/offerings/${formData.offering_Id}`
      );
      const total_VL_reserves =
        Number(data.total_VL_reserves) + Number(formData.amount);
      // if (total_VL_reserves > Number(data.funding_goal)) {
      //   return callBack(400, Number(data.funding_goal) - Number(data.total_VL_reserves));
      // }
      await axios.post(`${backendUrl}/vl-reserves`, formData);
      if (data) {
        // await axios.put(`${"localhost:1337"}/offerings/${formData.offering_Id}`, {
        await axios.put(`${backendUrl}/offerings/${formData.offering_Id}`, {
          // headers: {
          //   'Authorization': `${"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjYwNjMxNTc4LCJleHAiOjE2NjMyMjM1Nzh9._ZutYPmAKangL7tUMplkoChejlRq_Eslv6SQyY0eNcA"}`
          // },
          total_VL_reserves,
        });
        let data1 = { success: "200" };
        let status = 200;
        if (data1) {
          // Toast.info("Information saved", 3000);
          callBack(status);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                       Raise Amount
=================================================================*/
/**
 * Accepting Investment Amount Action
 * 
 * Overview:
 * Manages the acceptance of investment amounts for offerings, updating the total amount raised accordingly.
 * 
 * Details:
 * - Retrieves the current total amount raised for the offering and updates it with the new investment.
 * - Submits the investment record and updates the offering's total amount raised.
 * - Utilizes a callback function to notify the calling code of the success of the operation.
 * 
 * Usage:
 * Dispatched when a user invests in an offering, ensuring the total raised amount is accurately updated.
 * Example: `dispatch(AcceptingInvestMentAmount(formData, callBack))`
 * 
 * Dependencies:
 * Similar to `Reserve Amount Action` in its operation and dependencies, but focused on actual investments rather than reservations.
 */
export const AcceptingInvestMentAmount = (formData, callBack) => async () => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/offerings/${formData.Offerings.id}`
    );
    const total_amount_raised =
      Number(data.total_amount_raised) + Number(formData.amount);
    await axios.post(`${backendUrl}/investor-records`, formData);
    if (data) {
      await axios.put(`${backendUrl}/offerings/${formData.Offerings.id}`, {
        total_amount_raised,
      });
      let data1 = { success: "200" };
      let status = 200;
      if (data1) {
        callBack(status);
      }
    }
  } catch (err) {
    console.log(err);
  }
};
