import React, { useState } from "react";

/**
 * TopNavbarSearch Component
 * 
 * Overview:
 * The TopNavbarSearch component is responsible for rendering a search input field and a search button in the top navigation bar. It allows users to enter a search query and submit it to perform a search operation.
 * 
 * Details:
 * - The component uses the useState hook to manage the search string state.
 * - The handleSubmit function is called when the search button is clicked or when the form is submitted. It prevents the default form submission behavior and can be used to perform additional search-related logic.
 * - The component renders an input field with a placeholder text "Search" and binds its value to the searchStr state variable. The onChange event updates the searchStr state whenever the user types in the input field.
 * 
 * Dependencies:
 * - The component uses the useState hook from React to manage the search string state.
 * - The component relies on the 'search-icon.svg' image file located at '/web-app-img/desktop/icons/search-icon.svg' for rendering the search button icon.
 */
export default function TopNavbarSearch() {
  const [searchStr, setSearchValue] = useState("");

  /*============================================
                  handle submit
  =============================================*/
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(searchStr);
  };

  /*============================================
                main
  =============================================*/
  return (
    <div className="vl-topnavbar__search-block">
      <form onSubmit={handleSubmit}>
        <button>
          <img
            src="/web-app-img/desktop/icons/search-icon.svg"
            alt="search"
            onClick={handleSubmit}
          />
        </button>
        <input
          placeholder="Search"
          type="text"
          name="searchStr"
          value={searchStr}
          onChange={(e) => setSearchValue(e.target.value)}
          autoComplete="off"
        />
      </form>
    </div>
  );
}
