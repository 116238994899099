import React, { Fragment } from "react";
import format from "date-fns/format";

// api
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import updates from "../../../assets/imgs/desktop/icon/updates.svg";
import biglike from "../../../assets/imgs/desktop/icon/biglike.svg";
// import { getExploreOfferingsById } from "../../../web-app-store/actions/exploreAction";
import { getUpdatesExploreDetailsByOrganizationId } from "../../../web-app-store/actions/updatesAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../common/DisplayLoader";
import StartUpDetailsUpdatesLikeButton from "./StartUpDetailsUpdatesLikeButton";

/**
 * StartUpDetailsTabPanelUpdates Component
 * 
 * Overview:
 * The StartUpDetailsTabPanelUpdates component is responsible for rendering updates related to a startup organization. It is used as a tab panel within the startup details page. This component fetches and displays updates for the specified organization ID.
 * 
 * Details:
 * - The component uses Redux for state management and makes API calls to fetch updates data.
 * - It accepts the 'orgId' prop, which represents the organization ID for which updates are to be fetched.
 * - The component uses React Router's 'useParams' hook to get the current route parameters.
 * - The 'useEffect' hook is used to fetch updates data when the component mounts or when the 'orgId' or 'params' change.
 * - The component renders a list of updates, displaying the user profile image, name, timestamp, number of likes, and number of comments for each update.
 * - The 'displayPostDetails' function is used to truncate the update description if it exceeds 200 characters.
 * 
 * Dependencies:
 * - React
 * - React Router
 * - Redux
 * - React Redux
 * - Dependencies for the API calls and other utility functions used within the component.
 */
export default function StartUpDetailsTabPanelUpdates({ orgId }) {
  // api
  const dispatch = useDispatch();

  const params = useParams();
  useEffect(() => {
    // const callBackOfferingData = (data) => {
    //   if (!isEmpty(data.organization)) {
    //     dispatch(
    //       getUpdatesExploreDetailsByOrganizationId(data.organization.id)
    //     );
    //   }
    // };
    // dispatch(getExploreOfferingsById(params.id, callBackOfferingData));

    if (!isEmpty(orgId)) {
      dispatch(getUpdatesExploreDetailsByOrganizationId(orgId));
    }
  }, [dispatch, orgId, params]);

  let userData = JSON.parse(localStorage.getItem("userData"));

  const updatesExploreDetailsByOrgId = useSelector(
    (state) => state.updates.updatesExploreDetailsByOrgId
  );
  const displayPostDetails = (desc) => {
    return !isEmpty(desc) && desc.length > 200
      ? `${desc.substring(0, 200)}...`
      : desc;
  };

  return (
    <>
      <h1 className="menu-head-title">
        <img src={updates} className="img-fluid" /> Updates
      </h1>
      {!isEmpty(updatesExploreDetailsByOrgId) ? (
        updatesExploreDetailsByOrgId.map((data, index) => (
          <Fragment key={index}>
            <div className="discussion-tabs">
              <div className="discussion-list-main">
                <div className="discussion-list-main-inner">
                  <div className="discussion-top">
                    <div className="discussion-top-left">
                      <div className="discussion-top-profile">
                        {data?.user_profile?.profile_image != undefined ? (
                          <img
                            className="img-fluid"
                            src={data?.user_profile.profile_image.url}
                            alt=""
                          />
                        ) : !isEmpty(data.user.firstname) ? (
                          <p className="font-36-roboto-bold">
                            {data.user.firstname.charAt(0)}
                          </p>
                        ) : (
                          <p className="font-36-roboto-bold"></p>
                        )}
                      </div>
                      <div className="discussion-top-text">
                        <div className="discussion-title">
                          <h2>
                            {!isEmpty(data.user) &&
                              `${data.user.firstname} ${data.user.lastname}`}
                          </h2>

                          {/* {!isEmpty(data.user) && (
                            <DiscussionsCardStartupName
                              userId={data.user.id}
                              data={data}
                            />
                          )} */}
                        </div>
                        <div className="discussion-title-text">
                          <span>
                            {!isEmpty(data.updated_at) &&
                              format(new Date(data.updated_at), "hh:mm a")}
                          </span>
                          <span>
                            {!isEmpty(data.updated_at) &&
                              format(new Date(data.updated_at), "DD MMM, YYYY")}
                          </span>
                          <span>
                            {data.update_likes.length === 1
                              ? `${data.update_likes.length} Like`
                              : `${data.update_likes.length} Likes`}
                          </span>
                          {!isEmpty(data.comments) && (
                            <button className="startup-font-18-roboto-medium startup-details">
                              {data.comments.length} People commented
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {!isEmpty(userData) && (
                      <StartUpDetailsUpdatesLikeButton
                        orgId={
                          !isEmpty(data.organization)
                            ? data.organization.id
                            : null
                        }
                        userId={!isEmpty(data.user) ? data.user.id : null}
                        updatesId={data.id}
                        update_likes={data.update_likes}
                      >
                        <div className="discussion-thread-btn">
                          <button>
                            <img src={biglike} className="img-fluid" />
                          </button>
                        </div>
                      </StartUpDetailsUpdatesLikeButton>
                    )}
                  </div>
                  <div className="discussion-full-text">
                    <p>{(data.update_details)}</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ))
      ) : (
        <p className="vl-disc-card-row__text3 font-18-roboto-medium">
          No updates found
        </p>
      )}
    </>
  );
}
