import {
  SET_INVEST_PROFILE_SUCCESS,
  SET_INVEST_BY_USER_ID,
  SET_INVEST_PORTFOLIO,
} from "./../types";

const initialState = {
  investSaveProfileSuccess: false,
  investor: {},
  isLoadingInvestor: true,
  investorPortfolio: {},
};

/**
 * investReducer
 * 
 * Overview:
 * The investReducer is a function that handles the state management for the investment-related data in the application. It is responsible for updating the state based on different actions dispatched by the application.
 * 
 * Details:
 * - The investReducer function takes two parameters: state and action. The state parameter represents the current state of the application, while the action parameter represents the action dispatched by the application.
 * - The investReducer function uses a switch statement to handle different action types. It updates the state based on the action type and returns the updated state.
 * - The investReducer function uses the spread operator (...) to create a new state object with the updated properties.
 * 
 * Usage:
 * The investReducer function is typically used as a reducer in the Redux store. It can be imported and combined with other reducers using the combineReducers function provided by Redux.
 * 
 * Dependencies:
 * - initialState: The initialState object represents the initial state of the investReducer. It should be defined before using the investReducer function.
 * - SET_INVEST_PROFILE_SUCCESS: This action type represents the action dispatched when the investment profile is successfully set. It updates the investSaveProfileSuccess property in the state.
 * - SET_INVEST_BY_USER_ID: This action type represents the action dispatched when the investment data is set based on the user ID. It updates the investor and isLoadingInvestor properties in the state.
 * - SET_INVEST_PORTFOLIO: This action type represents the action dispatched when the investment portfolio is set. It updates the investorPortfolio property in the state.
 * 
 * @param {Object} state - The current state of the application.
 * @param {Object} action - The action dispatched by the application.
 * @returns {Object} - The updated state.
 */
export default function investReducer(state = initialState, action) {
  switch (action.type) {    
    case SET_INVEST_PROFILE_SUCCESS:
      return {
        ...state,
        investSaveProfileSuccess: action.payload,
      };

    case SET_INVEST_BY_USER_ID:
      return {
        ...state,
        investor: action.payload,
        isLoadingInvestor: false,
      };

    case SET_INVEST_PORTFOLIO:
      return {
        ...state,
        investorPortfolio: action.payload,
      };

    default:
      return state;
  }
}
