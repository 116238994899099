import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

/**
 * store
 * 
 * Overview:
 * The store is responsible for managing the application state in Redux. It combines the root reducer, initial state, and middleware to create the Redux store.
 * 
 * Details:
 * - The store is created using the createStore function from Redux.
 * - It takes the rootReducer, initialState, and middleware as parameters.
 * - The compose function is used to apply middleware and enable the Redux DevTools extension.
 * - The Redux DevTools extension is only enabled if it is available in the window object.
 * 
 * Usage:
 * The store is typically created once in the application and used to provide the state to components using the Provider component from react-redux.
 * 
 * Dependencies:
 * - Redux: The store is created using the createStore function from Redux.
 * - React Redux: The Provider component is used to provide the store to components.
 */
const store = createStore(  
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f 

    //
  )
);

export default store;
