import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import raiseCapitalReducer from "./raiseCapitalReducer";
import exploreReducer from "./exploreReducer";
import websiteBlogsReducer from "./websiteBlogsReducer";
import discussionsReducer from "./discussionsReducer";
import updatesReducer from "./updatesReducer";
import investReducer from "./investReducer";
import followersReducer from "./followersReducer";
import homeReducer from "./homeReducer";
import proceedPaymentReducer from "./proceedPaymentReducer";
import careerReducer from "./careerReducer";
import mentorReducer from "./mentorReducer";
import chatMenuReducer from "./chatMenuReducer";

/**
 * Root Reducer
 * 
 * Overview:
 * This file exports the root reducer for the web app store. The root reducer combines all the individual reducers into a single reducer function using the combineReducers utility from the Redux library.
 * 
 * Details:
 * - The root reducer combines the following reducers:
 *   - authReducer: Manages the authentication state.
 *   - errorReducer: Manages the error state.
 *   - raiseCapitalReducer: Manages the state related to raising capital.
 *   - exploreReducer: Manages the state related to exploring.
 *   - websiteBlogsReducer: Manages the state related to website blogs.
 *   - discussionsReducer: Manages the state related to discussions.
 *   - updatesReducer: Manages the state related to updates.
 *   - investReducer: Manages the state related to investments.
 *   - mentorReducer: Manages the state related to mentors.
 *   - followersReducer: Manages the state related to followers.
 *   - homeReducer: Manages the state related to the home page.
 *   - proceedPaymentReducer: Manages the state related to payment processing.
 *   - careerReducer: Manages the state related to careers.
 *   - chatMenuReducer: Manages the state related to the chat menu.
 * - The root reducer is used in the Redux store to manage the overall state of the web app store.
 * 
 * Usage:
 * The root reducer is used when creating the Redux store. It should be imported and passed to the createStore function from the Redux library.
 * 
 * Dependencies:
 * - Redux: The combineReducers utility is used to combine the individual reducers into a single reducer function.
 */
export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  raiseCapital: raiseCapitalReducer,
  explore: exploreReducer,
  websiteBlogs: websiteBlogsReducer,
  discussions: discussionsReducer,
  updates: updatesReducer,
  invest: investReducer,
  mentor: mentorReducer,
  followers: followersReducer,
  home: homeReducer,
  proceedPayment: proceedPaymentReducer,
  career: careerReducer,
  chatMenu: chatMenuReducer,
});
