import React, { useEffect, useState } from "react";
import Select from "react-select";
// import InputFieldNumber from "../common/InputFieldNumber";
import TextareaField from "../common/TextareaField";
import CustomRadioButton from "../common/CustomRadioButton";
import DatepickerSingle from "../common/DatepickerSingle";

// api
import { getRaiseCapitalOfferings } from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { investmentStageOptions } from "../common/allDropdownOptions";
import CurrencyInputCommon from "../common/CurrencyInputCommon";
import DropdownMultiSelectCommon from "../common/DropdownMultiSelectCommon";

/**
 * RaiseSetUpFundraiserDisplay Component
 *
 * Overview:
 * The RaiseSetUpFundraiserDisplay component is responsible for displaying the setup details of a fundraiser. It includes fields for funding goal, maximum funding goal, valuation cap, investment stage, funding start date, revenue stage, use of funds, market landscape, current traction, and revenue model.
 *
 * Details:
 * - The component uses Redux to fetch data from the store, including raiseCapitalOrg and raiseCapitalOfferings.
 * - It uses the useEffect hook to scroll to the top of the page and fetch raiseCapitalOfferings when raiseCapitalOrg is not empty.
 * - Another useEffect hook is used to populate the form fields with data from raiseCapitalOfferings.
 * - The component renders various input fields, including CurrencyInputCommon, Select, DatepickerSingle, CustomRadioButton, and TextareaField.
 * - The input fields are disabled and display the values from the state.
 *
 * Usage:
 * The RaiseSetUpFundraiserDisplay component can be used within a parent component to display the setup details of a fundraiser. It can be integrated into the UI layout by placing it in the desired location.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management and fetching data from the store.
 * - react-redux: The component uses react-redux to connect to the Redux store.
 * - useDispatch: The component uses the useDispatch hook from react-redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from react-redux to select data from the store.
 * - useEffect: The component uses the useEffect hook to perform side effects, such as scrolling and fetching data.
 * - CurrencyInputCommon: The component uses the CurrencyInputCommon component for currency input fields.
 * - Select: The component uses the Select component for dropdown selection.
 * - DatepickerSingle: The component uses the DatepickerSingle component for date selection.
 * - CustomRadioButton: The component uses the CustomRadioButton component for radio button selection.
 * - TextareaField: The component uses the TextareaField component for multiline text input.
 */
export default function RaiseSetUpFundraiserDisplay() {
  // api
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    fundingGoal: "",
    // minimumInvestment: "",
    valuationCap: "",
    startDate: null,
    useofFunds: "",
    // market_landscape: "",
    current_traction: "",
    revenue_model: " ",
    selectedRadioOption: "",
    investmentStage: "",
  });

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );
  const raiseCapitalOfferings = useSelector(
    (state) => state.raiseCapital.raiseCapitalOfferings
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOfferings(raiseCapitalOrg[0].id));
    }
  }, [dispatch, raiseCapitalOrg]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOfferings)) {
      let data = raiseCapitalOfferings[0];
      let revenueModel = [];
      let transactionModel = [];
      if (!isEmpty(data?.revenue_model)) {
        data?.revenue_model?.split(",")?.forEach((ele) => {
          revenueModel.push({
            value: ele,
            label: ele,
          });
        });
      }

      if (!isEmpty(data?.current_traction)) {
        data?.current_traction?.split(",")?.forEach((ele) => {
          transactionModel.push({
            value: ele,
            label: ele,
          });
        });
      }

      let valuesObj = {
        fundingGoal: data.funding_goal,
        // minimumInvestment: data.max_funding_goal,
        valuationCap: data.valuation_cap,
        startDate:
          data.fund_start_date === null ? null : new Date(data.fund_start_date),
        // data."offering_status",
        // data."invest_now_url",
        useofFunds: data.use_of_funds,
        // market_landscape: data.market_landscape,
        current_traction: transactionModel,
        revenue_model: revenueModel,
        selectedRadioOption: data.revenue_stage,
        investmentStage: {
          value: data.investment_stage,
          label: data.investment_stage,
        },
      };
      setValues({ ...valuesObj });
    }
  }, [raiseCapitalOfferings]);

  /*==========================================
                    render
     ==========================================*/
  return (
    <div className="raise-capital-more-info-div p-0">
      <div className="row mx-0 justify-content-between align-items-start pt-20">
        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Funding Goal ($)"
          name="fundingGoal"
          value={values.fundingGoal}
          isDisabled={true}
        /> */}
        <div className="col-12 col-lg-6">
          <CurrencyInputCommon
            containerClassName="vl-form-input  disable_fund_input funding-details-input-main"
            label="Funding Goal ($)"
            name="fundingGoal"
            value={values.fundingGoal}
            isDisabled={true}
          />
        </div>

        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Maximum Funding Goal ($)"
          name="minimumInvestment"
          value={values.minimumInvestment}
          isDisabled={true}
        /> */}
        {/* <CurrencyInputCommon
          containerClassName="vl-form-input"
          label="Maximum Funding Goal ($)"
          name="minimumInvestment"
          value={values.minimumInvestment}
          isDisabled={true}
        /> */}
        <div className="col-12 col-lg-6">
          <CurrencyInputCommon
            containerClassName="vl-form-input disable_fund_input funding-details-input-main"
            label="Estimated Valuation ($)"
            name="valuationCap"
            value={values.valuationCap}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="row mx-0 justify-content-between align-items-start pt-0">
        {/* <InputFieldNumber
          containerClassName="vl-form-input"
          label="Valuation Cap ($)"
          name="valuationCap"
          value={values.valuationCap}
          isDisabled={true}
        /> */}

        <div className="col-12 col-lg-6  vl-form-input funding-details-input-main vl-form-input--bordered-select-div">
          <label htmlFor="" className="investment_input_label">Investment Stage </label>
          <Select
            value={values.investmentStage}
            options={investmentStageOptions}
            className="vl-select-container funding-inputs"
            classNamePrefix="vl-select"
            placeholder={values.investmentStage}
            isDisabled={true}
          />
        </div>
        <div className="col-12 col-lg-6  funding-details-input-main">
          <DatepickerSingle
            startDateLabel="Targeted Launch Date"
            startDateValue={values.startDate}
            isDisabled={true}
            extraClass="date_picker_wrap"
            className="funding-inputs"
          />
        </div>
      </div>

      <div className="d-flex mx-0 align-items-center justify-content-center revenue_space revenue_disable_space">
        <h5 className="raise-capital-more-info-label mt-30 mr-95">
          Revenue Stage
        </h5>

        <div className="d-flex mx-0 flex-shrink-0 flex-nowrap align-items-center mt-38 raise_capital_checkbox">
          <CustomRadioButton
            label="Revenue"
            name="radioTypeRevenue"
            selectedRadioOption={values.selectedRadioOption}
            isDisabled={true}
          />

          <CustomRadioButton
            label="Pre-Revenue"
            name="radioTypePreRevenue"
            selectedRadioOption={values.selectedRadioOption}
            isDisabled={true}
          />

          <CustomRadioButton
            label="Stealth Mode"
            name="radioTypeStealthMode"
            selectedRadioOption={values.selectedRadioOption}
            isDisabled={true}
          />
        </div>
      </div>

      <div className="row mx-0 align-items-start pt-48 raise_cap_main">
        <div className="col-12 col-md-6">
          <DropdownMultiSelectCommon
            label="Traction"
            value={values.current_traction}
            className="funding-inputs"
            extraClass="funding-inputs"
            mainDiv="funding-details-page-main"
            disabled={true}
          />
        </div>
        <div className="col-12 col-md-6">
          <DropdownMultiSelectCommon
            label="Revenue Model"
            value={values.revenue_model}
            className="funding-inputs"
            extraClass="funding-inputs"
            mainDiv="funding-details-page-main"
            disabled={true}
          />
        </div>
      </div>
      <TextareaField
        label="Use of Funds"
        labelClassName="raise-capital-more-info-label pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_capital_text_area"
        name="useofFunds"
        value={values.useofFunds}
        isDisabled={true}
      />

      {/* <TextareaField
        label="Market Landscape"
        labelClassName="raise-capital-more-info-label pt-65 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
        name="market_landscape"
        value={values.market_landscape}
        isDisabled={true}
      /> */}
    </div>
  );
}
