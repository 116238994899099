import React from "react";
// import { Link } from "react-router-dom";
import darrow from "../../../assets/imgs/desktop/icon/down-arrow.png";
import img1 from "../../../assets/imgs/desktop/raise-capital/raise-pc.png";
import img2 from "../../../assets/imgs/desktop/invest/pc_mobile.png";


import { newLoginSignUpLink } from "../header-footer/pageLinks";

/**
 * RaiseCapitalFoldTwo Component
 * 
 * Overview:
 * The RaiseCapitalFoldTwo component is responsible for rendering the second fold of the Raise Capital section on the desktop version of the application. It provides information on how the investment process works and allows users to apply for funding.
 * 
 * Details:
 * - The component includes a renderBlockOne function that renders the content for the first block of the fold, which explains the steps of the investment process.
 * - The renderBlockOne function includes HTML elements such as headings, images, and a link to apply for funding.
 * - The component is structured using Bootstrap grid classes, with the renderBlockOne function occupying the left column and an empty column on the right.
 * - The component accepts no props.
 * 
 * Usage:
 * To use the RaiseCapitalFoldTwo component, include it in the desired location within the application's JSX code.
 * 
 * Example:
 * ```jsx
 * import RaiseCapitalFoldTwo from './components/desktop/raise-capital/RaiseCapitalFoldTwo';
 * 
 * function App() {
 *   return (
 *     <div>
 *       {/* Other components */
export default function RaiseCapitalFoldTwo() {
  const renderBlockOne = () => {
    return (
      <div className="invest-fold2-create-div">
        <h4>How It Works</h4>
        <h5 className="investpageinner-title">
          A secure, interactive platform where
          Investors connect directly with Founders
        </h5>
        <img src={img2} alt="invest how it works" className="investpc-mobile img-fluid" />
        <div className="raiseweb-page">
          <h4>Apply</h4>
          <h5>
            Submit your company application and our team will reach
            to gather any additional
            information before setting set up a time to connect!{" "}
          </h5>
          <h4>Evaluation</h4>
          <h5>
            We review each application and conduct our due diligence
            process.
          </h5>
          <h4>Final Decision</h4>
          <h5>
            If your application has been approved, VedasLabs initiates the
            offering document preparation prior to the launch on the{" "}
            VedasLabs platform.
          </h5>
          <h4>Deal Goes Live</h4>
          <h5>
            Once live, issuers can monitor the progress, post updates, and{" "}
           answer questions from potential investors in the
            community.
          </h5>
          {/* <Link to="/sign-up"> */}
          <a href={newLoginSignUpLink} target="_self" rel="noopener noreferrer">
            <div className="start-btn start-btn--invest-join">
              <span>APPLY NOW &#62;</span>
            </div>
          </a>
        </div>
        {/* </Link> */}
        <div className="invest-down-arrow">
          <img
            src={darrow}
            alt="downword arrow"
            className="d-arrow d-arrow--raise"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="raise-capital-fold-two-div">
      <div className="raise-capital-fold-two-inner-div">
        <div className="maincontainer-custom">
          <div className="row">
            <div className="col-lg-7">
              {renderBlockOne()}
            </div>
            <div className="col-lg-3">
            </div>
          </div>
        </div>
        <img
          src={img1}
          alt="raise capital how it works"
          className="raise-pc-img"
        />
      </div>
    </div>
  );
}
