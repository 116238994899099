import React, { useState } from "react";
import format from "date-fns/format";

// api
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvestorByUserId } from "../../../web-app-store/actions/investAction";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import InvestPreferences from "../invest/InvestPreferences";
import InvestInvestmentPortfolio from "../invest/InvestInvestmentPortfolio";
import InvestPersonalInfo from "../invest/InvestPersonalInfo";

// const imgArray = [
//   {
//     imgPath: "/web-app-img/desktop/general-profile/gp-expertise-img1.png",
//     imgClass: "gp-expertise-img1",
//     name: "SaaS",
//   },
//   {
//     imgPath: "/web-app-img/desktop/general-profile/gp-expertise-img2.png",
//     imgClass: "gp-expertise-img2",
//     name: "Cloud Computing",
//   },
// ];

/**
 * GeneralProfileInvestment Component
 *
 * Overview:
 * The GeneralProfileInvestment component is responsible for displaying the investment details of a user's general profile. It fetches the necessary data from the API and renders the preferences, investment portfolio, and additional details sections.
 *
 * Details:
 * - The component uses Redux to manage state and dispatches the 'getInvestorByUserId' action to fetch the investor data.
 * - The preferences section displays the industry categories and social impact areas of the investor, if available.
 * - The investment portfolio section displays the investor type, revenue stage, company investment stage, average amount invested in the last 5 years, and the number of private investments made in the last 5 years.
 * - The additional details section displays the investor's date of birth, employment status, level of education, and whether they are part of an investment group.
 *
 * Usage:
 * To use the GeneralProfileInvestment component, import it into the desired file and render it within the appropriate context. Make sure to have Redux set up and the 'getInvestorByUserId' action defined.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses react-redux to connect to the Redux store.
 * - useDispatch: The component uses the useDispatch hook from react-redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from react-redux to access the Redux store.
 * - isEmpty: The component uses the isEmpty function to check if arrays or objects are empty.
 * - format: The component uses the format function from date-fns to format dates.
 * - DisplayCurrencyFormatString: The component uses the DisplayCurrencyFormatString component to format currency values.
 */
export default function GeneralProfileInvestment() {
  // api
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isPortfolioEdit, setIsPortfolioEdit] = useState(false);
  const [isAdditionalEdit, setIsAdditionalEdit] = useState(false);

  useEffect(() => {
    dispatch(getInvestorByUserId());
  }, [dispatch]);

  const investor = useSelector((state) => state.invest.investor);

  /*=========================================
            preferences
  =========================================*/

  const preferences = () => {
    return (
      <>
        <div className="d-flex align-items-center gap-2 mb-3">
          <h3 className="preferences-block-title">Investment Interests</h3>
          {!isEdit && (
            <button
              onClick={() => setIsEdit(true)}
              className="vl-profile-page-button"
            >
              <i className="fa fa-pencil pencil-icon fs-6"></i>
            </button>
          )}
        </div>
        {isEdit ? (
          <InvestPreferences {...{ isEdit, setIsEdit }} />
        ) : !isEmpty(investor[0].industry_categories) ||
          !isEmpty(investor[0].social_categories) ? (
          <div className="preferences-block-div">
            {/* Industry Area */}
            <div className="d-flex gap-3 mb-3 preferences_wrap">
              <div className="preferences-block-btn-outer-div">
                <h5 className="preferences-block-text-1">Industry Area</h5>
                <div className="investor_area_input_wrap">
                  {investor[0].industry_categories.map((data, index) => (
                    <div className="area_input_main">
                      <div key={index} className="preferences-block-btn-div">
                        <span className="preferences-block-font-18-bold">
                          {data.ind_category}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Social Impact Areas */}
              <div className="preferences-block-btn-outer-div">
                <h5 className="preferences-block-text-1">
                  Social Impact Areas
                </h5>
                <div className="investor_area_input_wrap">
                  {investor[0].social_categories.map((data, index) => (
                    <div className="area_input_main">
                      <div key={index} className="preferences-block-btn-div">
                        <span className="preferences-block-font-18-bold">
                          {data.soc_category}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-sm-4">
                <h5 className="general-profile-text-colm-block-text1">
                  Preferred Revenue Stage
                </h5>
              </div>
              <div className="col-12 col-sm-4">
                <h4 className="general-profile-text-colm-block-text2 revenue_title">
                  {investor[0].revenue_stage}
                </h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-sm-4">
                <h5 className="general-profile-text-colm-block-text1">
                  Preferred Investment Stage
                </h5>
              </div>
              <div className="col-12 col-sm-4">
                <h4 className="general-profile-text-colm-block-text2 revenue_title">
                  {investor[0].company_investment_stage}
                </h4>
              </div>
            </div>
            {/* <div className="pl-0 d-flex align-items-center gap-4 mb-2">
                <h5 className="general-profile-text-colm-block-text1">
                  Preferred Revenue Stage
                </h5>
                <h4 className="general-profile-text-colm-block-text2 revenue_title">
                  {investor[0].revenue_stage}
                </h4>
              </div>
              <div className="pl-0 d-flex align-items-center gap-4 mb-4">
                <h5 className="general-profile-text-colm-block-text1">
                  Preferred Investment Stage
                </h5>
                <h4 className="general-profile-text-colm-block-text2 revenue_title">
                  {investor[0].company_investment_stage}
                </h4>
              </div> */}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  /*=========================================
            expertisein
  =========================================*/

  // const expertisein = () => {
  //   return (
  //     <div className="preferences-block-div  preferences-block-div--expertise">
  //       <h3 className="preferences-block-title">Expertise in</h3>
  //       <div className="row mx-0 align-items-start">
  //         {imgArray.map((data, key) => (
  //           <div className="expertise-card-outer-div" key={key}>
  //             <div className="expertise-card-img-div">
  //               <img
  //                 src={data.imgPath}
  //                 alt={data.name}
  //                 className={data.imgClass}
  //               />
  //               <h5>{data.name}</h5>
  //             </div>
  //             <h5 className="preferences-block-font-18-bold text-center">
  //               13 Years
  //             </h5>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  /*=========================================
            Additional Details
  =========================================*/

  const additionalDetails = () => {
    return (
      <>
        <div className="d-flex align-items-center gap-2">
          <h3>Additional Details</h3>
          {!isAdditionalEdit && (
            <button
              onClick={() => setIsAdditionalEdit(true)}
              className="vl-profile-page-button"
            >
              <i className="fa fa-pencil pencil-icon fs-6"></i>
            </button>
          )}
        </div>
        {isAdditionalEdit ? (
          <InvestPersonalInfo {...{ isAdditionalEdit, setIsAdditionalEdit }} />
        ) : !isEmpty(investor[0].birth_date) ||
          !isEmpty(investor[0].employment_status) ||
          !isEmpty(investor[0].education) ||
          !isEmpty(investor[0].part_of_investment_group) ? (
          <div className="additional-details">
            <div className="row mx-0">
              <div className="col-lg-2 col-md-4 pl-0">
                <h5 className="general-profile-text-colm-block-text1">
                  Date Of Birth
                </h5>
                <h4 className="general-profile-text-colm-block-text2">
                  {!isEmpty(investor[0].birth_date) &&
                    format(new Date(investor[0].birth_date), "DD-MMM-YYYY")}
                </h4>
              </div>
              <div className="col-lg-2 col-md-4 pl-0">
                <h5 className="general-profile-text-colm-block-text1">
                  Employement Status
                </h5>
                <h4 className="general-profile-text-colm-block-text2">
                  {investor[0].employment_status}
                </h4>
              </div>
              <div className="col-lg-3 col-md-4 pl-0">
                <h5 className="general-profile-text-colm-block-text1">
                  Level of Education
                </h5>
                <h4 className="general-profile-text-colm-block-text2">
                  {investor[0].education}
                </h4>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  /*=========================================
            investmentPortfolio
  =========================================*/
  const investmentPortfolio = () => {
    return (
      <>
        <div className="d-flex align-items-center gap-2 mb-3">
          <h2 className="preferences-block-title preferences-block-title--invest">
            Investment Portfolio
          </h2>
          {!isPortfolioEdit && (
            <button
              onClick={() => setIsPortfolioEdit(true)}
              className="vl-profile-page-button"
            >
              <i className="fa fa-pencil pencil-icon fs-6"></i>
            </button>
          )}
        </div>
        {isPortfolioEdit ? (
          <InvestInvestmentPortfolio
            {...{ isEdit: isPortfolioEdit, setIsPortfolioEdit }}
          />
        ) : !isEmpty(investor[0].investor_type) ||
          !isEmpty(investor[0].revenue_stage) ||
          !isEmpty(investor[0].company_investment_stage) ||
          !isEmpty(investor[0].avg_amt_inv_last_5) ||
          !isEmpty(investor[0].number_private_investments) ? (
          <div className="gp-investment-portfolio-div">
            <div className="mx-0 ">
              <div className="d-flex align-items-center gap-3 mb-3 pl-0 investor_wrap">
                <h5 className="general-profile-text-colm-block-text1">
                  Investor Type
                </h5>
                <h4 className="general-profile-text-colm-block-text2">
                  {investor[0].investor_type}
                </h4>
              </div>
              <div className="investment-group d-flex align-items-center mb-3 gap-3 investor_wrap ">
                <h5 className="general-profile-text-colm-block-text1">
                  Part of an investment group
                </h5>
                <h4 className="general-profile-text-colm-block-text2">
                  {investor[0].part_of_investment_group ? "Yes" : "No"}
                </h4>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {(!isEmpty(investor[0].avg_amt_inv_last_5) ||
          investor[0].number_private_investments) && (
          <div className="gp-investment-portfolio-div">
            <div className="mx-0 ">
              <div className="d-flex align-items-center gap-3 mb-3 pl-0 investor_wrap">
                <h5 className="general-profile-text-colm-block-text1">
                  Average Amount Investment last 5 year
                </h5>
                <h2 className="general-profile-text-colm-block-text2">
                  {/* $390,000 */}

                  <DisplayCurrencyFormatString
                    value={investor[0].avg_amt_inv_last_5}
                  />
                </h2>
              </div>
              <div className="d-flex align-items-center gap-3 mb-5 pl-0 investor_wrap">
                <h5 className="general-profile-text-colm-block-text1">
                  Average number of Private Investments last 5 year
                </h5>
                <h2 className="general-profile-text-colm-block-text2">
                  {investor[0].number_private_investments}
                </h2>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {!isEmpty(investor) && (
        <>
          {/* {console.log(investor)} */}

          <div className="mx-0 align-items-start preferences-area">
            {preferences()}
            {/* {expertisein()} */}
          </div>
          {investmentPortfolio()}
          {/* {additionalDetails()} */}
        </>
      )}
    </>
  );
}
