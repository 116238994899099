import React from "react";
import { Link, useHistory } from "react-router-dom";

import isEmpty from "../../../web-app-store/validations/is-empty";
import {
  knowledgeBaseBlogDetail,
  knowledgeBaseWebAppLinkBlogDetail,
  glossary,
  glossaryWebAppLink,
} from "../header-footer/pageLinks";

/**
 * KnowledgeBaseCard Component
 * 
 * Overview:
 * The KnowledgeBaseCard component is responsible for rendering a card that displays information about a knowledge base blog. It allows users to click on the card to view the full blog details. The component is used in the knowledge base section of the website.
 * 
 * Details:
 * - The component uses React Router's useHistory hook to enable navigation to the full blog details page.
 * - The onBlogClick function is triggered when the card is clicked. It checks the type of the blog and stores the blog ID in the local storage before navigating to the blog details page.
 * - The renderCardContent function renders the content of the card, including the thumbnail image, title, and blog description.
 * - The component conditionally renders different content based on the displayOnWebsite prop and the type of the blog.
 * 
 * Props:
 * - data: An object containing the data for the knowledge base blog. It includes properties like id, type, thumbnail, title, and blog_description.
 * - displayOnWebsite: A boolean indicating whether the card should be displayed on the website or not.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - React Router: The component uses React Router's useHistory hook for navigation.
 */
export default function KnowledgeBaseCard({ data, displayOnWebsite }) {
  const history = useHistory();
  const onBlogClick = () => {
    if (data.type === "Knowledge_Base") {
      localStorage.setItem("blogID", data.id);
      history.push(`${knowledgeBaseBlogDetail}/${data.id}`);
    }
  };
  const renderCardContent = () => {
    return (
      <>
        <div
          style={{ cursor: "pointer" }}
          className="kb-card-img-block"
          onClick={onBlogClick}
        >
          {!isEmpty(data.thumbnail) && (
            <img src={data.thumbnail.url} alt={data.title} />
          )}
        </div>
        <div className="kb-card-text-div">
          <h5 className="font-25-montserrat-bold">{data.title}</h5>
          <h5 className="font-15-montserrat-regular pt-6">
            {data.blog_description}
          </h5>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="kb-card col-lg-4 col-md-6 col-12">
        {data.title !== "Glossary of Terms" ? (
          displayOnWebsite ? (
            renderCardContent()
          ) : (
            <Link to={`${knowledgeBaseWebAppLinkBlogDetail}/${data.id}`}>
              {renderCardContent()}
            </Link>
          )
        ) : displayOnWebsite ? (
          <Link to={glossary}>{renderCardContent()}</Link>
        ) : (
          <Link to={glossaryWebAppLink}>{renderCardContent()}</Link>
        )}
      </div>
    </>
  );
}
