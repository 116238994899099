import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import Footer from "../header-footer/Footer";
import Navbar from "../header-footer/Navbar";
import PrivacyPolicyKnowledgeBase from "./PrivacyPolicyKnowledgeBase";
import PrivacyPolicyNewContent from "./PrivacyPolicyNewContent";

/**
 * PrivacyPolicyNew Component
 * 
 * Overview:
 * The PrivacyPolicyNew component is responsible for rendering the privacy policy page of the application. It includes a navbar, a page title block, a knowledge base section, a new content section, and a footer.
 * 
 * Details:
 * - The component uses the useEffect hook to scroll the window to the top when the component mounts.
 * - The component structure consists of a main div with the class name "privacy-policy-main-div". Inside this div, there is a navbar component, a page title block div with the class name "privacy-policy-page-title-block", a knowledge base component, a new content component, and a footer component.
 * - The component does not accept any props.
 * 
 * Dependencies:
 * The PrivacyPolicyNew component depends on the following components:
 * - Navbar: This component is responsible for rendering the navigation bar.
 * - PrivacyPolicyKnowledgeBase: This component is responsible for rendering the knowledge base section of the privacy policy page.
 * - PrivacyPolicyNewContent: This component is responsible for rendering the new content section of the privacy policy page.
 * - Footer: This component is responsible for rendering the footer section of the page.
 */
export default function PrivacyPolicyNew() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-main-div">
      <Navbar />
      <div className="privacy-policy-page-title-block">
        <h1>Privacy Policy</h1>
      </div>
      <PrivacyPolicyKnowledgeBase />
      <PrivacyPolicyNewContent />
      <Footer />
    </div>
  );
}
