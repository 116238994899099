import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_FOLLOWERS_BY_USER_ID,
  SET_ALL_FOLLOWERS_UPDATES,
  CLEAR_ALL_FOLLOWERS_UPDATES,
} from "./../types";
import Toast from "light-toast";
import isEmpty from "../validations/is-empty";

/*===================================================================================
             get follower by user id
====================================================================================*/
/**
 * Get Followers By User ID And Followers Updates Action
 * 
 * Overview:
 * Fetches the followers of a user identified by their user ID and retrieves updates related to these followers. This action aims to enrich the user experience by providing a dynamic feed of information related to their followers.
 * 
 * Details:
 * - Retrieves the current user's followers from the backend based on the user ID stored in local storage.
 * - For each follower, fetches updates related to the organizations they are associated with.
 * - Dispatches actions to store these updates in the application state for display or further processing.
 * 
 * Usage:
 * This action could be dispatched on a user's profile or dashboard to provide them with the latest updates from their followers and associated organizations.
 * Example: `dispatch(getFollowersByUserIdAndFollowersUpdates())`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - Redux's dispatch function for state management.
 * - `isEmpty` utility function to check for empty data sets.
 */
export const getFollowersByUserIdAndFollowersUpdates = () => async (
  dispatch
) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/followers?user=${userData.id}`
    );
    if (!isEmpty(data)) {
      const callBackUpdatesData = (updatesData) => {
        updatesData.map((update, index) =>
          dispatch({
            type: SET_ALL_FOLLOWERS_UPDATES,
            payload: update,
          })
        );
      };

      data.map((followerData, index) =>
        dispatch(
          getUpdatesOfFollowersByOrganizationId(
            followerData.organizations[0].id,
            callBackUpdatesData
          )
        )
      );
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Clear Followers By User ID And Followers Updates Action
 * 
 * Overview:
 * Clears all stored updates related to a user's followers from the application state. This is useful for resetting the state when the user logs out or navigates away from the followers' updates view.
 * 
 * Details:
 * - Dispatches an action to clear all followers' updates from the application state.
 * 
 * Usage:
 * This action is dispatched to clear the application state of followers' updates, ensuring that stale or irrelevant data is not displayed to the user.
 * Example: `dispatch(clearFollowersByUserIdAndFollowersUpdates())`
 * 
 * Dependencies:
 * - Redux's dispatch function for state management.
 */

export const clearFollowersByUserIdAndFollowersUpdates = () => (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ALL_FOLLOWERS_UPDATES,
      payload: [],
    });
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get updates of followers by organization id
====================================================================================*/
/**
 * Get Updates Of Followers By Organization ID Action
 * 
 * Overview:
 * Fetches updates for a specific organization, typically those followed by the user's followers. This provides a mechanism to retrieve and display relevant organizational updates.
 * 
 * Details:
 * - Makes a GET request to fetch updates related to a specific organization ID.
 * - Utilizes a callback function to pass the fetched updates for further processing or display.
 * 
 * Usage:
 * Dispatched within the context of fetching updates for organizations associated with a user's followers.
 * Example: `dispatch(getUpdatesOfFollowersByOrganizationId(orgId, callBackUpdatesData))`
 * 
 * Dependencies:
 * The same as `Get Followers By User ID And Followers Updates Action`.
 */
export const getUpdatesOfFollowersByOrganizationId = (
  orgId,
  callBackUpdatesData
) => async (dispatch) => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/updates?organization=${orgId}`
    );
    if (data) {
      callBackUpdatesData(data);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             get follower by user id
====================================================================================*/
/**
 * Get Followers By User ID Action
 * 
 * Overview:
 * Retrieves all followers for the currently logged-in user, identified by their user ID, and updates the application state with this information.
 * 
 * Details:
 * - Fetches follower data for the current user from the backend.
 * - Dispatches an action to store the fetched follower data in the redux state.
 * 
 * Usage:
 * Can be used to display a list of followers on a user's profile or in social interaction components of the application.
 * Example: `dispatch(getFollowersByUserId())`
 * 
 * Dependencies:
 * The same as `Get Followers By User ID And Followers Updates Action`.
 */
export const getFollowersByUserId = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      `${backendUrl}/followers?user=${userData.id}`
    );
    if (data) {
      dispatch({
        type: SET_FOLLOWERS_BY_USER_ID,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             create follower
====================================================================================*/
/**
 * Create Followers Action
 * 
 * Overview:
 * Allows for the creation of a new follower relationship by submitting form data to the backend. Upon successful creation, it refreshes the follower list to reflect the new relationship.
 * 
 * Details:
 * - Sends a POST request with form data to create a new follower relationship in the backend.
 * - Displays a success message upon creation and refreshes the list of followers for the current user.
 * - A callback function is invoked with the status of the operation.
 * 
 * Usage:
 * This action is dispatched when a new follower relationship is established, such as when a user decides to follow another user or organization.
 * Example: `dispatch(createFollowers(formData, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Get Followers By User ID And Followers Updates Action`, with the addition of `Toast` for feedback messages.
 */
export const createFollowers = (formData, callBackStatus) => async (
  dispatch
) => {
  try {
    let { data, status } = await axios.post(
      `${backendUrl}/followers`,
      formData
    );
    if (data) {
      Toast.info("Information saved", 3000);
      dispatch(getFollowersByUserId());
      callBackStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
             update follower
====================================================================================*/
/**
 * Update Followers Action
 * 
 * Overview:
 * Updates an existing follower relationship, potentially modifying associated data or permissions. This action ensures that changes are reflected both in the backend and within the application state.
 * 
 * Details:
 * - Performs a PUT request to update follower data based on a given follower ID and form data.
 * - Displays a confirmation message upon successful update and refreshes the follower list.
 * - A callback function notifies the caller of the operation's success.
 * 
 * Usage:
 * Useful for modifying the details or status of existing follower relationships, such as changing notification settings or permissions.
 * Example: `dispatch(updateFollowers(formData, followersId, callBackStatus))`
 * 
 * Dependencies:
 * The same as `Create Followers Action`.
 */
export const updateFollowers = (
  formData,
  followersId,
  callBackStatus
) => async (dispatch) => {
  try {
    let { data, status } = await axios.put(
      `${backendUrl}/followers/${followersId}`,
      formData
    );
    if (data) {
      Toast.info("Information saved", 3000);
      dispatch(getFollowersByUserId());
      callBackStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
                           delete follower
====================================================================================*/
/**
 * Delete Follower By Follower ID Action
 * 
 * Overview:
 * Removes a follower relationship identified by a specific follower ID, deleting the association from the backend.
 * 
 * Details:
 * - Sends a DELETE request to remove a follower relationship using its ID.
 * - On successful deletion, displays a success message and optionally invokes a callback function with the status.
 * 
 * Usage:
 * Dispatched when a follower relationship needs to be ended, allowing users to manage who follows them.
 * Example: `dispatch(deleteFollowerByFollowerId(followersId, callBackStatus))`
 * 
 * Dependencies:
 * Similar to `Update Followers Action`, focusing on deletion operations.
 */
export const deleteFollowerByFollowerId = (
  followersId,
  callBackStatus
) => async (dispatch) => {
  try {
    let { data, status } = await axios.delete(
      `${backendUrl}/followers/${followersId}`
    );
    if (data) {
      Toast.info("Information saved", 3000);
      callBackStatus(status);
    }
  } catch (err) {
    console.log(err);
  }
};
