import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/imgs/desktop/common/logo.png";
import BurgerMenu from "./BurgerMenu";

/**
 * Navbar Component
 * 
 * Overview:
 * The Navbar component represents the navigation bar at the top of the page. It includes the site logo and a burger menu for mobile navigation.
 * 
 * Details:
 * - The Navbar component is a functional component that returns JSX elements representing the navigation bar.
 * - It consists of a div with the class "vl-navbar" that contains the site logo and the BurgerMenu component.
 * - The site logo is wrapped in a Link component that redirects to the home page when clicked.
 * - The Navbar component does not accept any props.
 * 
 * Usage:
 * To use the Navbar component, simply import it and include it in your JSX code where the navigation bar is needed.
*/
export default function Navbar() {
  return (
    <div className="vl-navbar d-flex mx-0 align-items-center justify-content-between">
      <Link to="/">
        <img src={logo} alt="vedslabs logo" className="navbar-logo" />
      </Link>
      <BurgerMenu />
    </div>
  );
}
