import React, { useEffect } from "react";
import './termsAndConditions.scss';

/**
TermsAndConditionsNewContent Component

Overview:
The TermsAndConditionsNewContent component is responsible for rendering the content of the terms and conditions page. It provides a structured layout for displaying the terms and conditions text, as well as any additional content related to the terms and conditions.

Details:
The component manages the rendering of the terms and conditions text, which can be dynamically fetched from an API or stored locally.
It may include sub-components for rendering specific sections or elements within the terms and conditions page, such as headings, paragraphs, or lists.
The component accepts props to customize the rendering of the terms and conditions content, such as font size, color, or alignment.
 */

export default function TermsAndConditionsNewContent({ displayOnWebApp }) {

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const mailToInfoVedaslabs = (
    <a href="mailto:info@vedaslabs.io">
      <u>info@vedaslabs.io</u>
    </a>
  );

  const mailToTeamVedaslabsCapsText = (
    <a href="mailto:TEAM@VEDASLABS.IO">
      <u> TEAM@VEDASLABS.IO</u>
    </a>
  );

  const linkVedaslabsIo = (
    <a href="https://www.vedaslabs.io">
      <u> https://www.vedaslabs.io</u>
    </a>
  );

  const linkPrivacyPolicy = displayOnWebApp ? (
    <a href="https://vedaslabs.io/privacy-policy">
      <u> https://vedaslabs.io/privacy-policy</u>
    </a>
  ) : (
    <a href="https://vedaslabs.io/privacy-policy-page">
      <u> https://vedaslabs.io/privacy-policy</u>
    </a>
  );

  return (
    <div className="privacy-policy-content-block privacy-policy-content-block--terms">
      <p>
        https://vedaslabs.io (the “Site”) is a website owned by VedasLabs Inc. (“VedasLabs” “us,” “our” or “we”), a registered funding portal.
        VedasLabs is not a registered broker-dealer or an investment adviser. We do not provide financial advice or investment recommendations;
        any information or representations made on our Site do not constitute and should not be construed as investment advice or recommendations.
      </p>
      <p>
        VedasLabs provides this Site to you (“you”, “your” or “user”) under the following terms and conditions (“Terms of Use”).
        Our Privacy Policy, which explains how we collect and use information from visitors to our Site, is incorporated by reference into these Terms of Use.
        By using our Site in any manner, including but not limited to visiting or browsing the Site, you agree to be bound by these Terms of Use, our Privacy Policy and any other rules, policies or procedures that may be published by us from time to time on the Site, each of which is incorporated by reference and each of which may be updated by us from time to time without notice to you.  By using this Site,
        you agree to be bound by any such updates; accordingly, it is incumbent upon you to review the Terms of Use on the Site frequently.
       <br />
        <br />

       The services, features, content, or applications that may be offered from time to time by VedasLabs in connection with the Site are collectively referred to as the “Services”.

      </p>

      <h5 className="heading">
        Account Registration
      </h5>
      <div>
        <p>
          Some of the information on our site is publicly accessible and downloadable from our Site.
          However, in order to access some offering materials, post comments on the Site, or make an investment,
          you must register to create an account (“Account”). This Site is intended solely for users who are 18 years of age or older,
          and any account creation, use of or access to the Site by anyone under 18 years of age is unauthorized, unlicensed and in violation of these Terms of Use. By using the Site and/or creating an account, you represent that you are 18 years of age or older and are of legal age to form a binding contract where you reside, and that all registration information you submit is accurate and truthful. You must complete the registration process by providing us with current, complete and accurate information. You will also choose a password and your email will serve as your user name. You are entirely responsible for maintaining the confidentiality of your password and user name. You agree to notify VedasLabs immediately of any unauthorized use of your Account or any other breach of security. You will not share your password, let anyone else access your Account, or do anything else that might jeopardize the security of your Account and the Site.
          You agree that you shall be solely responsible for any loss or consequences resulting from activities attributed to your Account,
          either authorized or unauthorized.
          <br />
          <br />
          You agree that your Account will be entirely “self-directed,” by which we mean you are solely responsible for all purchases, orders, investment decisions and instructions placed in or through your Account. Although the Site may provide data, information or content provided by other parties relating to investment strategies and/or opportunities to buy and/or sell securities, you should not interpret any such content as tax, legal, financial, or investment advice or as a recommendation to invest in any offering posted on the Site. Any decision by you to invest must be solely your own decision based on your due diligence and analysis of the risks involved in a particular offering. It is made at your own risk. You acknowledge and agree that you are solely responsible for determining the suitability of an investment or strategy and that you accept the risks associated with your decisions, including the risk of loss of the entire amount that you invest. You are strongly advised to consult
          a licensed professional for any legal, tax, insurance, or investment advice;
          the Site does not and cannot provide any of those types of professional services, advice or analysis.
          <br />
          <br />
          The Site permits authorized users to invest online. Site users are expressly prohibited from:
          (a) using the Site, or any services and or tools on it, if they are not able to form legally binding contracts,
          are under the age of 18 or are temporarily, or indefinitely, suspended from using the Site, services or any tools provided by it;
          (b) collecting other users’ personal information;and
          (c) posting false, inaccurate, misleading, defamatory, or libelous content. VedasLabs, in its sole discretion,
          may refuse to allow any person or entity to use the Site and may change its eligibility criteria at any time.
          We reserve the right to suspend or terminate your access to the Site at any time and for any reason including your misrepresentation of information or abuse of information. Use of this Site is void where prohibited by law and the right to access the Site is revoked in such jurisdictions. If you are using the Site on behalf of any entity, you represent that you are authorized to accept this Agreement on such entity’s behalf,
          and that such entity agrees to indemnify VedasLabs for violations of this Agreement (as set forth below).
      </p>
      </div>
      <h5 className="heading">Investor Requirements</h5>
      <p style={{fontStyle: 'italic'}}>The Site will display securities offerings being conducted under Regulation Crowdfunding under the Securities Act.
        All offerings under Regulation Crowdfunding are conducted by VedasLabs.</p>
      <br />

      <p>
        Anyone can invest in offerings under Regulation Crowdfunding.
        <i><b> Because of the risks involved with this type of investing, however, you are limited in how much you can invest during any 12-month period in these transactions. </b></i>
         The limitation on how much you can invest depends on your net worth and annual income.
        <br />
        If either your annual income or your net worth is less than $107,000, then during any 12-month period, you can invest up to the greater of either $2,200 or 5% of the greater of your annual income or net worth.
        <br />

        If both your annual income and your net worth are equal to or more than $107,000, then during any 12-month period, you can invest up to 10% of annual income or net worth, whichever is greater, but not to exceed $107,000.
        <br />
        If you are an accredited investor, as defined under Rule 501 of Regulation D of the Securities Act, then there are no limits to the amount you may invest under Regulation Crowdfunding.
        To be an accredited investor, you must fall under one of the following categories:
        <br />
      </p>
      <ol className="category-list">
        <li>
          Any bank as defined in section 3(a)(2) of the Act, or any savings and loan association or other institution as defined in section 3(a)(5)(A) of
          the Act whether acting in its individual or fiduciary capacity; any broker or dealer registered pursuant to section 15 of the Securities Exchange Act of 1934;
          any insurance company as defined in section 2(a)(13) of the Act; any investment company registered under the Investment Company Act of 1940 (the “investment Company Act”) or a business development company as defined in section 2(a)(48) of that Act;
          any Small Business Investment Company licensed by the U.S. Small Business Administration under section 301(c) or (d) of the Small Business Investment Act of 1958; any plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions,
          for the benefit of its employees, if such plan has total assets in excess of $5,000,000; any employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974 if the investment decision is made by a plan fiduciary, as defined in
          section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or,
          if a self-directed plan, with investment decisions made solely by persons that are accredited investors;
          </li>
        <li>
          Any private business development company as defined in section 202(a)(22) of the Investment Advisers Act of 1940 (the “Advisers Act”);</li>
        <li>
          Any organization described in section 501(c)(3) of the Internal Revenue Code, corporation, Massachusetts or similar business trust, or partnership, not formed for the specific purpose of acquiring the securities offered, with total assets in excess of $5,000,000;</li>
        <li>
          Any director, executive officer, or general partner of the issuer of the securities being offered or sold, or any director, executive officer, or general partner of a general partner of that issuer;</li>
        <li>
          Any natural person whose individual net worth, or joint net worth with that person's spouse, exceeds $1,000,000.
  

          <ol type="i" className="sub-category-1">

            <li>
              Except as provided in paragraph (a)(5)(ii) of this section, for purposes of calculating net worth under this paragraph (a)(5):
                <ol type="A" className="sub-category-2">
                <li>
                  The person's primary residence shall not be included as an asset;
                  </li>
                <li>
                  Indebtedness that is secured by the person's primary residence, up to the estimated fair market value of the primary residence at the time of the sale of securities, shall not be included as a liability (except that if the amount of such indebtedness outstanding at the time of sale of securities exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and
                  </li>
                <li>
                  Indebtedness that is secured by the person's primary residence in excess of the estimated fair market value of the primary residence at the time of the sale of securities shall be included as a liability;
                  </li>
              </ol>
            </li>
            <li>
              Paragraph (a)(5)(i) of this section will not apply to any calculation of a person's net worth made in connection with a purchase of securities in accordance with a right to purchase such securities, provided that:
      
              <ol type="A" className="sub-category-2">
                <li>
                  Such right was held by the person on July 20, 2010;
                    </li>
                <li>
                  The person qualified as an accredited investor on the basis of net worth at the time the person acquired such right; and
                    </li>
                <li>
                  The person held securities of the same issuer, other than such right, on July 20, 2010.
                    </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          Any natural person who had an individual income in excess of $200,000 in each of the two most recent years or joint income with that person's spouse in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year;
          </li>
     
        <li>
          Any trust, with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the securities offered, whose purchase is directed by a sophisticated person as described in §230.506(b)(2)(ii);
          </li>
     
        <li>
          Any natural person holding in good standing one or more professional certifications or designations or other credentials from an accredited educational institution that the SEC has designated as qualifying an individual for accredited investor status under §230.501(a)(10);
          </li>
     
        <li>
          Any natural person who is “knowledgeable employee,” as defined in Rule 3c-5(a)(4) under the Investment Company Act of 1940, of the private-fund issuer of the securities being offered or sold;
          </li>
     
        <li>
          You are a SEC- or state-registered investment advisers or a rural business investment companies to the list of entities as specified in §230.501(a)(1);
          </li>
     
        <li>
          A limited liability company as specified in §230.501(a)(3);
          </li>
     
        <li>
          A “family office,” as defined in Rule 202(a)(11)(G)-1 under the Advisers Act: (i) With assets under management in excess of $5,000,000, (ii) that was not formed for the specific purpose of acquiring the securities offered, and (iii) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment;
          </li>
     
        <li>
          A “family client,” as defined in Rule 202(a)(11)(G)-1 under the Advisers Act, of a family office meeting the requirements in Rule 501(a)(12); and
          </li>
     
        <li>
          Any entity in which all of the equity owners are accredited investors.
          </li>
      </ol>
   

      <p>The following table provides a few examples of the annual investment limited under Regulation Crowdfunding:</p>
      <table className="investment-table">
        <thead>
          <tr>
            <th> Annual Income</th>
            <th> Net Worth   </th>
            <th> Calculation </th>
            <th> 12-Month Limit </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td><p>$30,000</p></td>
            <td><p>$105,000</p></td>
            <td><p>Greater of $2,200 or 5% of $30,000 ($1,500)</p></td>
            <td><p>$2,200</p></td>
          </tr>

          <tr>
            <td><p>$150,000</p></td>
            <td><p>$80,000</p></td>
            <td><p>Greater of $2,200 or 5% of $150,000 ($7,500)</p></td>
            <td><p>$7,500</p></td>
          </tr>

          <tr>
            <td><p>$150,000</p></td>
            <td><p>$107,000</p></td>
            <td><p>10% of $150,000 ($15,000)</p></td>
            <td><p>$15,000</p></td>
          </tr>

          <tr>
            <td><p>$200,000</p></td>
            <td><p>$900,000</p></td>
            <td><p>10% of $900,000 ($90,000)</p></td>
            <td><p>$90,000</p></td>
          </tr>

          <tr>
            <td><p>$1,200,000</p></td>
            <td><p>$2,000,000</p></td>
            <td><p>10% of $2,000,000 ($200,000), 
              subject to  $107,000 cap, or no cap if accredited</p></td>
            <td><p>$107,000 or N/A</p></td>
          </tr>
        </tbody>
      </table>

      <br />
      <p>
        Investment opportunities on the Site are intended only for investors who are able to bear the potential loss of the entire amount of their investment.
        All securities listed here are being offered by, and all information included on this site is the responsibility of, the applicable issuer of such securities.
        VedasLabs has not taken any steps to verify the adequacy, accuracy or completeness of any information.
        Neither VedasLabs nor any of its officers, directors, employees, agents, affiliates or representatives makes any warranty, express or implied, of any kind whatsoever
        related to the adequacy, accuracy or completeness of any information on this Site or the use of information on this Site.
        Investors are responsible for conducting, and may rely only upon, any legal, accounting or due diligence review they decide is appropriate.
        Investment products are not FDIC insured, may lose value, and are not guaranteed.
      </p>
  
      <h5 className="heading">Issuer Requirements</h5>
      <p>
        Federal securities law requires securities offered or sold in the US to be registered with the Securities and Exchange Commission, or be exempt from such registration.
        Offerings hosted on this Site will be done under the exemption from registration under Regulation Crowdfunding.
      </p>
      <h5 className="heading">Regulation Crowdfunding </h5>
      <p>
        A company issuing securities in reliance on Regulation Crowdfunding is permitted to raise a maximum aggregate amount of $5,000,000 in a 12-month period. In order for the offering to be hosted by VedasLabs, each Issuer must meet the following requirements: (1) Company is currently operating in the US and has intention to raise capital for their business from the crowd; (2) Company passes our due-diligence process; (3) Company meets any additional requirements as identified by the SEC. All Bad Actors will be rejected.
        All companies that meet these criteria are able to conduct an offering through VedasLabs.
      </p>
      <h5 className="heading">Testing the Waters</h5>
      <p>
        Prior to undertaking an offering under Regulation Crowdfunding, companies may first with the Site to “test the waters” and determine investor interest prior to accepting investments.
        During this testing the waters phase, all communications about the offering must:
        (1) State that no money or other consideration is being solicited, and if sent in response, will not be accepted;
        (2) State that no offer to buy the securities can be accepted and no part of the purchase price can be received until the offering statement is filed and only through an intermediary's platform; and
        (3) State that a person's indication of interest involves no obligation or commitment of any kind.

      </p>
      <h5 className="heading">Crowdfunding Investment Vehicles </h5>
      <p>
        Through this site, companies will be permitted to use “crowdfunding vehicles” by which investors will invest into the crowdfunding vehicle rather than into the company directly.
        Prior to allowing use of the crowdfunding vehicle, all required disclosures about the crowdfunding vehicle must be provided in
        the company’s Form C filing with the SEC, and investors must be informed that the investment will be through a crowdfunding vehicle.
      </p>
      <h5 className="heading">
        Risks of Investing in Securities Offered; Restrictions on Resale or Transfer of the Securities
      </h5>
      <p>
        The securities offered on the Site are only suitable for prospective investors who are familiar with and willing to accept the high risks associated
        with private investments, including the risk of complete loss of your investment.
        Securities sold through the Site are not publicly traded and, therefore, are illiquid unless registered with the SEC.
        Securities will be subject to restrictions on resale or transfer including holding period requirements.
        Investing in private placements requires high risk tolerance, low liquidity need, and long-term commitments.
        Users must be able to afford to lose their entire investment.
        Investment products are not FDIC insured, may lose value, and there is no bank guarantee.

        The securities being offered have not been registered under the Securities Act in reliance, among other exemptions, on the exemptive provisions of the Securities Act.
        No governmental agency has reviewed the offerings posted on this Site and no state or federal agency has passed upon either the adequacy of the disclosure contained herein or the fairness of the terms of any offering.
        Any representation to the contrary is a criminal offense.
        Because these securities are subject to legal restrictions on transfer and resale, investors should not assume they will be able to resell their securities.
        You will be limited in your ability to resell your investment for the first year and may need to hold your investment for an indefinite period of time.  Unlike investing in companies listed on a stock exchange where you can quickly and easily trade securities on a market,
        you may have to locate an interested buyer when you do seek to resell your crowdfunded investment.

      </p>
      <h5 className="heading"> Compensation to VedasLabs</h5>
      <p>
        VedasLabs is a SEC and FINRA registered funding portal and receives compensation calculated by reference to the purchase or sale of securities through the Site for services provided to offerings under Regulation Crowdfunding.
        VedasLabs is not a registered broker-dealer, investment adviser, or investment manager, and does not offer investment advice,
        nor does it recommend or otherwise suggest that any investor make an investment in a particular offering.
      </p>
      <h5 className="heading">General Site Guidelines</h5>
      <p>
        You represent that all information that you provide to VedasLabs or through the Site is accurate, compete and truthful.
        You acknowledge and agree that VedasLabs and its agents are entitled to rely upon the information you provide as true, accurate and complete without independent verification.

        You are prohibited from posting or transmitting any material on or through the Site that is or could be offensive, fraudulent, unlawful, threatening, libelous or otherwise defamatory, obscene, scandalous, inflammatory, pornographic or profane,
        or any material that could constitute or encourage conduct that could be considered a criminal offense, give rise to civil liability, or otherwise would violate any law or regulation.

      </p>
      <h5 className="heading">
        In using our Site you will not:
      </h5>
      <p>
        disseminate or transmit viruses, worms, Trojan horses, RATs, keyboard loggers, time bombs, spyware, adware, cancelbots, passive collection mechanisms ("PCMs"), or any other malicious or invasive code or program or upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies or other similar devices;
        copy or adapt the Site's software including but not limited to Flash, PHP, HTML, JavaScript or other code;
        reverse engineer, decompile, reverse assemble, modify or attempt to discover any software (source code or object code) that the Site creates to generate web pages or any software or other products or processes accessible through the Site;
        except as may be the result of standard search engine or Internet browser usage, use or launch, develop or distribute any automated system, including, without limitation, any spider, robot (or "bot"), cheat utility, scraper or offline reader that accesses the Site, or use or launch any unauthorized script or other software;
    

      fabricate any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Site to send altered, deceptive or false source-identifying information;
    
      disguise the source of materials or other information you submit to the Site or use tools which anonymize your internet protocol address (e.g., anonymous proxy) to access the Site;
    
      interfere with or circumvent any security feature of the Site or any feature that restricts or enforces limitations on use of or access to the Service, content or user content;
    
      probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;
    
      interfere with or disrupt (or attempt to do so) the access of any user, host or network, including, without limitation,
      sending a virus, overloading, flooding, spamming, mail-bombing the Site, or by scripting the creation of
      content in such a manner as to interfere with or create an undue burden on the Site.
      <br />
      <br />
     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Please be aware that VedasLabs will fully cooperate with any law enforcement authorities’ validly issued court orders and subpoenas requesting or directing VedasLabs to disclose the identity of anyone posting any information or materials on
      the Site who is alleged to have engaged in violative conduct and assumes no obligation to give you prior notice of such disclosure.

      </p>
      <h5 className="heading">
        Additionally, the following conduct in connection to the use of the Site is prohibited:
      </h5>
      <ul>
        <li>
          Posting, uploading or transmitting any content that violates any privacy right, publicity right, patent, trademark, trade secret, copyright or other proprietary right, or contract right or other right of any party;
      </li>
        <li>
          Posting, uploading or transmitting any content or engaging in any conduct that is offensive, harmful, threatening, abusive, harassing, defamatory, libelous, or obscene or that is unlawful in any manner or that degrades, intimidates, promotes or incites racism, bigotry, hatred or physical harm of any kind against any group or individual, including, without limitation, on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;
      </li>
        <li>
          Posting, uploading or transmitting any content that is pornographic or that exploits people (adults or children) in a sexual or violent manner; or contains nudity, excessive violence, or offensive subject matter or that contains a link to any of the foregoing types of content or to an adult website or in any way using the Site or the Services in connection with any adult entertainment or pornography business;
      </li>
        <li>
          Copying, reproducing, modifying (including, without limitation, altering, obscuring, deleting, etc. any copyright or other legally required notices, credits, logos, trademarks, etc.), creating derivative works from, or distributing in any manner or medium any content posted on the Site or through the Services in any manner that is in violation of the terms of applicable agreements;
      </li>
        <li>
          Impersonating any person or entity, or submitting any materials to the Site or through the Services that are false, inaccurate, deceptive, misleading, unlawful, or are otherwise in violation of the Terms of Use, including, without limitation, utilizing misleading email addresses,
          or forged headers or otherwise manipulated identifiers in order to disguise the origin of any content transmitted to the Site or through the Services;

      </li>
        <li>
          Except as explicitly permitted by the Terms of Use, or otherwise pre-approved in writing by VedasLabs, engaging in any commercial activity on the Site or including any links to commercial services or websites or uploading, posting or otherwise transmitting any content that contains advertising or any solicitation regarding products, goods or services;
        Interfering with any user's right to privacy; soliciting or collecting user names, passwords, emails, personal identifying information or other information from any user; engaging in conduct that poses or creates a privacy or security risk to any person; or posting private information about a third party;

      </li>
        <li>
          Engaging in conduct that involves the posting, uploading or transmission of unsolicited or unauthorized advertising or promotional materials, "junk mail," "chain letters," unsolicited mass mailing, "spimming," or "spamming";
      </li>
        <li>
          Engaging in any action or inaction that could disable, overload, impair the infrastructure of the Site or impair the proper functioning of the Site or the Services, including, without limitation, uploading, posting or otherwise transmitting any software or materials which contain a virus or other harmful or disruptive component; circumventing, altering or interfering with any computer software, or security-related features of the Site or the Services; or deciphering, decompiling,
          disassembling or reverse engineering any of the software comprising or in any way utilized in connection with the Site or the Services;
      </li>
        <li>
          Accessing or attempting to access the Site or the Services using automated means (such as harvesting bots, robots, spiders, or scrapers) or gaining, or attempting to gain, unauthorized access to any servers, computer systems or databases utilized in connection with the Site or the Services;
      </li>
        <li>
          Using the communication systems provided by the Site for any solicitation or other commercial purposes,
          except as explicitly authorized by VedasLabs,or VedasLabs and the specific user, as applicable;
      </li>
        <li> Uploading, posting or transmitting any content that advocates or provides instruction on illegal activity or communicating on or through
        the Site regarding illegal activities with the intent to commit them;</li>
        <li>
          Making any untrue statement of a material fact or omitting to state a material fact necessary in order to make
          the statements made, in the light of the circumstances under which they were made, not misleading, in connection with the purchase or sale of any security; and
      </li>
        <li>
          Engaging in any conduct that in VedasLabs’s sole discretion restricts or inhibits any other user from enjoying the use of the Site or any of the Services.
      </li>
      </ul>



      <h5 className="heading">Communication Channel Guidelines</h5>
      <p>
        Offerings under Regulation Crowdfunding are required to have online communication channels that are monitored by VedasLabs.
        These communication channels are for the purpose of prospective investors engaging in dialog with each other and the issuer in any particular offering.
        Any individual who promoted an offering in exchange for compensation, or who is a founder or an employee of an issuer must disclose the fact that he or she is engaging in promotional activities on behalf of the issuer.
        Issuers are encouraged to participate in the communication channels so long as comments adhere to the following guidelines and do not violate the communication requirements as outlined in Regulation Crowdfunding.
        The following types of comments are not permitted and will be removed:
      </p>
      <ul>
        <li>SPAM</li>

        <li>
          Direct or indirect solicitation of or promotion with respect to securities offerings on other sites. Direct or indirect solicitation of or promotion with respect to securities offerings on other sites may be misleading, are not permitted and will be removed. This includes both direct solicitation or comments purporting to be genuine comments but that indirectly promote other offerings.
          Commenters who genuinely wish to compare offerings made on other sites should raise specific questions about the issuer’s business, prospects or valuation
       </li>
        <li>
          Profanity or derogatory language based on presumed race, sex, age or national origin or sexual preference or otherwise coarse or inappropriate language or violates our Terms of Use
        </li>
        <li>
          Comments from Users of the Site, or any services and or tools, if they are temporarily, or indefinitely, suspended from using the Site, services or any tools
        </li>
        <li>
          Comments which attempt to collect personal information about other Users.
        </li>
        <li>
          Duplicate comments
        </li>
        <li>
          Comments which are not compliant, and have been posted by the Issuer
        </li>
        <li>
          Comments which include direct email addresses and suggest communication outside of the platform.
        </li>
      </ul>
      <h5 className="heading">No Representations or Warranties</h5>
      <p>
        To the extent permissible under law, VedasLabs does not assume any liability or responsibility for
        any errors or omissions in the content of the Site. VedasLabs does not endorse or represent the
        completeness, reliability or accuracy of any content or information distributed through or accessed
        from the Site, and has not performed any investigation into such information. VedasLabs shall not be
        liable for any investment decisions made based upon such information. You agree that any reliance upon
        any content or information distributed through or accessed from the Site is at your sole risk.
        VedasLabs is not entitled to rely upon the information provided by its Users. You acknowledge and
        agree that VedasLabs does not provide any representation, warranty or assurance that the offerings
        on the Site are made in accordance with state and/or Federal securities law, including the exemption
        to the sale of unregistered securities and the prohibition against the general solicitation of
        unregistered securities. Each Issuer, and not the Site, is responsible for ensuring that any
        securities offering is done in accordance with state, Federal law and regulations promulgated by
        the SEC and FINRA. We make no representation or warranties regarding the legality or compliance of
        any offering. VedasLabs has not reviewed all of the links provided on the Site and is not responsible
        for the content of any off-Site pages. Clicking on hyperlinks and visiting any off-Site pages is solely
        done at your own risk.
      </p>

      <h5 className="heading">Digital Millennium Act Copyright Notice</h5>
      <p>
        We respect the intellectual property of others, and we ask you to do the same. If you or any user of our
        Site believes its copyrights have been infringed upon on or through our Site, the copyright(s) owner(s)
        (“Complaining Party”) should send notification to our Designated Agent (as identified below) immediately. To be effective, the notification must include:
      </p>

      <ul>
        <li>A physical or electronic signature of the Complaining Party or such person authorized to act on behalf of the Complaining Party;</li>
        <li>Identification of the copyrights(s) claimed to have been infringed;</li>
        <li>Information reasonably sufficient to permit us to contact the Complaining Party or such person authorized to act on behalf of the Complaining Party, such as street address, telephone number and, if available, an electronic mail address</li>
        <li>Identification of the material that is claimed to be infringing the Complaining Party’s copyrights(s) that is to be removed and information reasonably sufficient to permit us to locate such materials;</li>
        <li>A statement that the Complaining Party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, agent, or by law; and</li>
        <li>A statement that the information in the notification is accurate and, under penalty of perjury, the Complaining Party or such person authorized to act on behalf of the Complaining Party is the owner of an exclusive copyright that is allegedly infringed.</li>
      </ul>
      <p>
        Pursuant to the Digital Millennium Copyright Act, 17 U.S.C. § 512(c) (“DMCA”), VedasLabs’s Designated
      Agent for notice of claims of copyrights infringement can be contacted at the addresses below: <br />
          Designated Agent for Claimed Infringement:
      info@vedaslabs.io<br />
      </p>

      <p>
        You acknowledge, accept and agree that if we receive a notice of a claim of copyright infringement, we
        may immediately remove the identified materials from our Site without liability to you or any other
        party and that the claims of the Complaining Party will be referred to the United States Copyright
        Office for adjudication as provided in the DMCA.
      </p>
      <p> Please note that this procedure is exclusively for notifying VedasLabs and its affiliates that your
      copyrighted material has been infringed. The preceding requirements are intended to comply with our
      rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal
      advice.
         </p>
      <p>You also acknowledge that other countries have laws protecting copyright holders, and that you and
      other users of the Site, as well as VedasLabs and its affiliates may have rights under those laws,
      including the right to obtain injunctive relief.
        </p>
      <p>It may be advisable to contact an attorney regarding your rights and obligations under the DMCA and
      other applicable laws.
        </p>
      <p>Allegations that other intellectual property rights are being infringed or misappropriated on this Site
      should be sent to info@vedaslabs.io. VedasLabs may, in its sole discretion, terminate the accounts of
      those who are accused of copyright infringement or infringement of other intellectual property rights.
        </p>

      <h5 className="heading">Site and Customer Intellectual Property</h5>
      <p>All content and images on the Site are either the property of, or used with permission by, VedasLabs.
      The use of the content or images by you or anyone authorized by you is prohibited unless specifically
      permitted by these Terms of Use or provided elsewhere on the Site. By providing content on the Site, you
      grant us a royalty-free, non-exclusive, worldwide license of your intellectual property rights in what
      you provide, including to copy, reformat, index, modify, display, distribute and put to commercial and
      other uses your content on the Site. No compensation will be paid with respect to our use of your
      content. VedasLabs neither warrants nor represents that your use of materials displayed on the Site
      will not infringe rights of third parties not owned or affiliated with VedasLabs.
    </p>
      <p>The trademarks, logo and slogans displayed on the Site are owned by VedasLabs and others.
      Nothing on this Site should be construed as granting any license or right to use the Trademarks
      without written permission of VedasLabs or the third- party owner of the Trademarks. Your misuse
      of the Trademarks, or any other content on the Site, except as provided in these Terms of Use, is
      strictly prohibited. You are advised and cautioned that VedasLabs will aggressively enforce its
      intellectual property rights to the fullest extent.
      </p>

      <h5 className="heading">Communications</h5>
      <p>
        You agree that VedasLabs will send communications to you electronically via the email address
        provided by you on your Account. You agree to notify us of any changes in your address or contact
        details. Communications shall be deemed delivered to you when sent and not when received. Your use
        of electronic signatures to sign documents legally binds you in the same manner as if you had manually
        signed such documents. If you sign electronically, you represent that you have the ability to access
        and retain a record of such documents. You agree that you are responsible for understanding these
        documents and agree to conduct business by electronic means. You are obligated to review the Site
        periodically for changes and modifications and agree not to contest the admissibility or
        enforceability of the Site’s electronically stored copy of this Agreement in any proceeding
        arising out of this Agreement.
      </p>


      <h5 className="heading">Change or Termination</h5>
      <p>
        We may, without prior notice, change the Site, or stop providing the Site, applications or services. We may permanently or temporarily terminate or suspend your account access to the Site without notice or liability, for any reason or for no reason, including if in our sole determination you violate any provision of these Terms of Use. Upon termination of these Terms of Use or your access to the Site for any reason or no reason, you will continue to be bound by those provisions of the Terms of Use which, by their nature, should survive termination, including without limitation ownership provisions, warranty disclaimers, indemnification, and limitations of liability. VedasLabs reserves the right, in its sole discretion, without any obligation and without any notice requirement, to change, improve or correct the information, materials and descriptions on the Site for any reason. The information and materials on the Site may contain typographical errors or inaccuracies. Any dated information is published as of its date only, and VedasLabs does not undertake any obligation or responsibility to update or amend any such information. You agree that VedasLabs and its affiliates will not be liable to you or to any third party for any such modification, suspension or discontinuance.
      </p>

      <h5 className="heading">Disclaimer of Liability</h5>
      <p>Your use of the Site is at your own risk. NEITHER VEDASLABS NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE SHALL BE LIABLE FOR ANY DIRECT, INCIDENTAL, CONSEQUENTIAL, INDIRECT OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS TO, OR USE OF, THE SITE. WITHOUT LIMITING THE FOREGOING, EVERYTHING ON THE SITE IS PROVIDED TO YOU “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Please note that some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.</p>

      <p>VedasLabs assumes no responsibility for, and shall not be liable for, any damages to, or viruses, malware, spyware, bots or the like that may infect, your computer equipment or other property on account of your access to, use of, or browsing on the Site or your downloading of any materials, data, text, images, video, or audio from the Site. </p>

      <p>Although VedasLabs may from time to time monitor or review discussions, postings, transmissions, and the like on the Site, VedasLabs is under no obligation to do so and assumes no responsibility or liability arising from such content nor for any error, defamation, libel, slander, omission, falsehood, obscenity, profanity or inaccuracy contained in any information within such locations on the Site. VedasLabs reserves the right to remove any content from the Site or to restrict access to the Site for any reason without notice.</p>

      <h5 className="heading">US Jurisdiction</h5>
      <p>The Site is made available only to U.S. Persons as defined in Rule 902(k) of Regulation S under the Securities Act. If you choose to access information or offerings on this Site, it is your responsibility to comply with the applicable local, national or international laws. If you are a non-U.S. Person, any use of this Site is entirely at your own risk.</p>

      <p>The securities offered through this Site can be sold only in certain jurisdictions. You acknowledge and agree that it is solely your responsibility to be aware of applicable laws and regulations of your country and state of residence. THE CONTENT PROVIDED ON THIS SITE DOES NOT CONSTITUTE AN OFFER OR SOLICITATION TO SELL SECURITIES REFERRED TO ON THIS SITE, BY ANYONE IN ANY JURISDICTION IN WHICH SUCH OFFER, SOLICITATION OR DISTRIBUTION WOULD BE UNLAWFUL OR IN WHICH THE PERSON MAKING SUCH OFFER OR SOLICITATION IS NOT QUALIFIED TO DO SO OR TO ANYONE TO WHOM IT IS UNLAWFUL TO MAKE SUCH OFFER OR SOLICITATION.</p>

      <p>This Site is not directed at you if we are prohibited by any law of any jurisdiction from making the information on this Site available to you. You should satisfy yourself before accessing the Site that we would be allowed to advertise investment products to you under the law of the jurisdiction in which you reside. It is your responsibility to be aware of and to observe all applicable laws and regulations of any relevant jurisdiction, including the one in which you reside. The securities described on this Site are not registered under the Securities Act.</p>

      <h5 className="heading">Governing Law</h5>
      <p>These Terms of Use shall be governed by, construed and entered in accordance with the laws of the State of New York applicable to contracts deemed to be made within such state, without regard to choice of law or conflict of law provisions thereof. YOU HEREBY WAIVE YOUR RIGHT TO A TRIAL BY JURY WITH RESPECT TO ANY CLAIM, ACTION OR PROCEEDING, DIRECTLY OR INDIRECTLY, ARISING OUT OF, OR RELATING TO, THESE TERMS OF USE TO THE FULLEST EXTENT PERMITTED BY LAW. YOU UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.</p>

      <h5 className="heading">Dispute Resolution</h5>
      <p>In the event of any claim, controversy or alleged dispute between you and VedasLabs or its affiliates (“Dispute”), you hereby agree to attempt in good faith to amicably resolve any Dispute at least 30 days before instituting any legal proceeding. Each party agrees to submit any Dispute for resolution by final binding arbitration after serving written notice, which notice shall set forth in detail the controversy, question, claim or alleged breach along with your attempt to resolve such Dispute. Upon such notice and attempt to resolve, the party may then commence an arbitration proceeding pursuant to the rules of FINRA Dispute Resolution. Any proceedings will be held in New York, New York.</p>

      <p>The decision(s) of the arbitrator(s) shall be final and binding and may not be appealed to any court of competent jurisdiction, or otherwise, except on very limited legal grounds. The implementation of arbitration decision(s) shall in no way be delayed or otherwise impaired pending the outcome of any appeal. Judgment upon the award rendered in an arbitration may be entered by any court having jurisdiction thereof. You agree that all disputes will be limited between you, individually, and VedasLabs. To the full extent allowable by law, you agree that no arbitration proceeding or other dispute resolution proceeding shall be joined with any other party or decided on a class-action basis.</p>

      <p>Notwithstanding the foregoing, you agree that the following matters shall not, at the election of VedasLabs, be subject to binding arbitration: (1) any dispute related to, or arising from allegations of criminal activity; (2) any dispute concerning VedasLabs’s intellectual property rights; and (3) any claim for injunctive relief. Any court action commenced by you or VedasLabs in accordance with these Terms of Use shall be brought in the United States District Court for the Southern District of New York, and you and we hereby submit and consent to the personal jurisdiction of, and the service of process by, that Court.</p>

      <h5 className="heading">Indemnification</h5>
      <p>You agree to indemnify, defend and hold VedasLabs and any of its affiliates, and any of its successors and assigns, and any of their respective officers, directors, employees, agents, representatives, licensors, advertisers, suppliers, and operational service providers harmless from and against any and all claims, actions, losses, expenses, damages and costs (including reasonable attorneys’ fees), resulting from any breach or violation of these Terms of Use by you or arising from your use of or access to the Site; from the infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity; or from any content posted through the Site by you (including claims related to defamation, invasion of privacy, or other violation of a person's rights). </p>

      <p>VedasLabs reserves the right to assume, at its sole expense, the exclusive defense and control of any such claim or action and all negotiations for settlement or compromise, and you agree to fully cooperate with VedasLabs in the defense of any such claim, action, settlement or compromise negotiations, as requested by VedasLabs.</p>
      <p>The indemnification obligations set forth above shall survive your cessation of use of the Site.</p>

      <h5 className="heading">Notification Procedures</h5>

      <p>We will provide notifications, including those regarding modifications to these Terms of Use and those required by law. We may, but are not obligated to, provide notifications of modifications made for marketing or other business-related purposes. Notifications will be emailed to you or posted on our Site, as determined by us in our sole discretion. We reserve the right to determine the form and means of providing notifications to Site visitors. We are not responsible for any automatic filtering that you or your network provider may apply to email notifications that we send to the email address you provided to us.</p>

      <h5 className="heading">General</h5>
      <p>The failure of VedasLabs to enforce any right or provision of these Terms of Use will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of VedasLabs. Except as expressly set forth in these Terms of Use, the exercise by either party of any of its remedies under these Terms of Use will be without prejudice to its other remedies under these Terms of Use or otherwise. If for any reason a court or arbitral tribunal, as applicable, of competent jurisdiction finds any provision of these Terms of Use invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms of Use will remain in full force and effect.</p>

      <h5 className="heading">Miscellaneous</h5>
      <p>Except as expressly provided in these Terms of Use, there shall be no third-party beneficiaries to the Terms of Use. In the event that any provision of these Terms of Use, or the application thereof, becomes or is declared by a court of competent jurisdiction to be illegal, void or unenforceable, the remainder of these Terms of Use will continue in full force and effect and the application of such provision to other persons or circumstances will be interpreted so as reasonably to effect the intent of the parties hereto. You further agree to replace such void or unenforceable provision of these Terms of Use with a valid and enforceable provision that will achieve, to the extent possible, the economic, business and other purposes of such void or unenforceable provision.</p>
      {/* Last Para */}
      <br />
      <p>Last updated: {displayOnWebApp ? "June 1st, 2021." : "June 1st, 2021."}</p>
      <p>&ldquo;VedasLabs&rdquo; is a trademark of VedasLabs Inc.</p>
    </div>
  );
}
