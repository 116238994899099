import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";

import isEmpty from "../../../web-app-store/validations/is-empty";
import { getRaiseCapitalOfferingsByOfferingIdStoreState } from "../../../web-app-store/actions/exploreAction";
import {
  getUIAgreementTypeValueFunction,
  getUISecurityTypeValueFunction,
} from "../../../common-website-and-app/getUIValueFunction";
import StartUpDetailsLightPaper from "./StartUpDetailsLightPaper";

let img1 = "/web-app-img/desktop/explore-startup-details/mission.png";
let img2 = "/web-app-img/desktop/explore-startup-details/problem.png";
let img3 = "/web-app-img/desktop/explore-startup-details/solution.png";
let img4 = "/web-app-img/desktop/explore-startup-details/product.png";
let img5 = "/web-app-img/desktop/explore-startup-details/model.png";
let img6 = "/web-app-img/desktop/explore-startup-details/market.png";
let img7 = "/web-app-img/desktop/explore-startup-details/competition.png";

/**
 * AboutTab Component
 * 
 * Overview:
 * The AboutTab component is responsible for displaying information about a startup or organization. It renders various sections such as About, Mission, Problem, Solution, Product/Service, Business Model, Market Landscape, Competition, Traction, and Roadmap. Each section includes an icon, title, description, images, and optional video.
 * 
 * Details:
 * - The component uses Redux to manage state and dispatch actions to fetch data from an API.
 * - It uses React Router's useParams hook to retrieve the ID parameter from the URL.
 * - The component includes a useEffect hook to dispatch an action to fetch data when the ID parameter changes.
 * - It uses useSelector hook to access the relevant data from the Redux store.
 * - The component includes helper functions to remove HTML tags from strings, extract images from HTML strings, and display videos from HTML strings.
 * - The component renders each section using the AboutSection sub-component, passing the necessary props.
 * 
 * Usage:
 * To use the AboutTab component, import it into the desired file and include it in the JSX code. Make sure to provide the necessary Redux store and Router configuration.
 * 
 * Dependencies:
 * - React: The component uses React to create and manage the UI elements.
 * - Redux: The component uses Redux for state management and data fetching.
 * - React Router: The component uses React Router for routing and URL parameter retrieval.
 */
const AboutTab = () => {
  // api
  const dispatch = useDispatch();
  const params = useParams();
  const teamRef = useRef(null);

  useEffect(() => {
    dispatch(getRaiseCapitalOfferingsByOfferingIdStoreState(params.id));
  }, [dispatch, params]);

  const offeringDataById = useSelector(
    (state) => state.explore.exploreOfferingByOfferingId
  );

  const raiseCapitalTeam = useSelector(
    (state) => state.raiseCapital.raiseCapitalTeam
  );
  const AboutSection = ({ icon, title, que, des, images, video }) => {
    // if (title === "Traction") {
    //   debugger;
    // }
    if (des == false && images.length == 0) return null;
    return (
      <div className="menudata-head">
        <h1 className="menu-head-title">
          <img src={icon} className="img-fluid" /> {title}
        </h1>
        {/* <h3>{que}</h3> */}
        {/* <p className="menu-head-text">{des}</p> */}
        <div className="content-wrap">
          <div dangerouslySetInnerHTML={{ __html: des }} />
        </div>

        {/* <div className="aboutcontent-img">
          <img src={images[0]} className="img-fluid" />
        </div> */}
        {video?.length > 0 && (
          <div className="aboutcontent-img">
            <iframe
              width="1000"
              height="600"
              src={video[0].replace("watch?v=", "embed/")}
            ></iframe>
          </div>
        )}
      </div>
    );
  };
  // const removeTagFromTraction = (str) => {
  //   if (str === null || str === "" || str === undefined) return false;
  //   else str = str.toString();
  //   return str.replace(/&nbsp;/gi, "<br/>");
  // };

  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();
    return str.replace(/&nbsp;/gi, " ");
    // return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, "<br/>");
  };

  const extractImages = (rawString) => {
    var regex = /<img.*?src="(.*?)"/gi,
      result,
      indices = [];
    while ((result = regex.exec(rawString))) {
      indices.push(result[1]);
    }
    return indices;
  };
  const displayVideo = (showVideo) => {
    let indices = [];
    if (showVideo !== undefined) {
      var regex = /<oembed.*?url="(.*?)"/gi,
        result;

      while ((result = regex.exec(showVideo))) {
        indices.push(result[1]);
      }
    }
    return indices;
  };
  const teamsSheetRenderBlock = () => {
    return (
      <div className="menudata-head">
        <h1 className="menu-head-title">Term Sheet</h1>{" "}
        <table className="vl-table-teams">
          <tbody>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Round Type:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  {offeringDataById.round_type}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Minimum Investment:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  <DisplayCurrencyFormatString
                    value={offeringDataById.min_investment}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Funding Minimum:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  <DisplayCurrencyFormatString
                    value={offeringDataById.funding_goal}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Funding Maximum:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  <DisplayCurrencyFormatString
                    value={offeringDataById.max_funding_goal}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Agreement Type
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  {getUIAgreementTypeValueFunction(
                    offeringDataById.agreement_type
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Security Type:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  {getUISecurityTypeValueFunction(
                    offeringDataById.security_type
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Share Price:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  <DisplayCurrencyFormatString
                    value={
                      offeringDataById.share_price
                        ? parseFloat(offeringDataById.share_price).toFixed(2)
                        : "0.00"
                    }
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span className="font-roboto-16-roboto-medium tablefirst-line">
                  Valuation Cap:
                </span>
              </td>
              <td>
                <span className="font-16-roboto-light">
                  <span className="font-16-roboto-light">
                    <DisplayCurrencyFormatString
                      value={offeringDataById.valuation_cap}
                    />
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <div className="menudata-main">
      <AboutSection
        icon={img7}
        title={"About"}
        images={extractImages(offeringDataById?.organization?.company_details)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.company_details)}
        video={displayVideo(offeringDataById?.organization?.company_details)}
      />
      <AboutSection
        icon={img1}
        title={"Mission"}
        images={extractImages(offeringDataById?.organization?.mission)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.mission)}
        video={displayVideo(offeringDataById?.organization?.mission)}
      />
      <AboutSection
        icon={img2}
        title={"Problem"}
        images={extractImages(offeringDataById?.organization?.problem)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.problem)}
        video={displayVideo(offeringDataById?.organization?.problem)}
      />

      <AboutSection
        icon={img2}
        title={"Solution"}
        images={extractImages(offeringDataById?.organization?.solution)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.solution)}
        video={displayVideo(offeringDataById?.organization?.solution)}
      />

      <AboutSection
        icon={img2}
        title={"Product/Service"}
        images={extractImages(offeringDataById?.organization?.product_overview)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.product_overview)}
      />
      <AboutSection
        icon={img2}
        title={"Business Model"}
        images={extractImages(offeringDataById?.organization?.business_model)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.business_model)}
      />
      <AboutSection
        icon={img2}
        title={"Market Landscape"}
        images={extractImages(offeringDataById?.market_landscape)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.market_landscape)}
        video={displayVideo(offeringDataById?.market_landscape)}
      />
      <AboutSection
        icon={img6}
        title={"Competition"}
        images={extractImages(offeringDataById?.organization?.competition)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.competition)}
        video={displayVideo(offeringDataById?.organization?.competition)}
      />
      <AboutSection
        icon={img2}
        title={"Traction"}
        images={extractImages(offeringDataById?.organization?.traction)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.traction)}
        video={displayVideo(offeringDataById?.organization?.traction)}
      />
      <AboutSection
        icon={img6}
        title={"Roadmap"}
        images={extractImages(offeringDataById?.organization?.roadmap)}
        // que={"What is vedaslabs.io?"}
        des={removeTags(offeringDataById?.organization?.roadmap)}
        video={displayVideo(offeringDataById?.organization?.roadmap)}
      />
      {/* <StartUpDetailsLightPaper /> */}

      {/* {teamsSheetRenderBlock()} */}
    </div>
  );
};

export default AboutTab;
