import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_RAISE_CAPITAL_ALL_INVESTORS,
  SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED,
} from "./../types";

/*===================================================================================
            investors by offering id
====================================================================================*/
/**
 * GetRaiseCapitalAllInvestors Action
 * 
 * Overview:
 * Fetches all investors associated with a specific offering ID from the backend. This action updates the Redux store with the list of all investors for a given offering, aiding in the management and visualization of investor engagement.
 * 
 * Details:
 * - Makes a GET request to `/investor-lnk-fund-americas?offering_id=${offering_id}` to retrieve investor details based on the offering ID.
 * - Dispatches an action to the Redux store with type `SET_RAISE_CAPITAL_ALL_INVESTORS` and the retrieved data as payload.
 * 
 * Dependencies:
 * - axios for making HTTP requests.
 * - backendUrl for the API endpoint.
 */
export const getRaiseCapitalAllInvestors =
  (offering_id) => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/investor-lnk-fund-americas?offering_id=${offering_id}`
        // `${backendUrl}/investor-lnk-fund-americas?offering_id=iPRnieDXQAC8Ktcq52Oa6g`
      );

      if (data) {
        dispatch({
          type: SET_RAISE_CAPITAL_ALL_INVESTORS,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

/*===================================================================================
            investors by offering id and status
====================================================================================*/
/**
 * GetRaiseCapitalAllInvestorsStatusInvested Action
 * 
 * Overview:
 * Retrieves all investors with a status of "Invested" for a specific offering ID from the backend. This specialized action helps in filtering and displaying only those investors who have completed their investment, offering clear insights into the investment flow of a particular offering.
 * 
 * Details:
 * - Sends a GET request to `/investor-lnk-fund-americas?offering_id=${offering_id}&status=Invested` to obtain data on investors who have invested in a particular offering.
 * - Updates the Redux store by dispatching an action of type `SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED` with the data fetched.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl for determining the backend endpoint.
 */
export const getRaiseCapitalAllInvestorsStatusInvested =
  (offering_id) => async (dispatch) => {
    try {
      let { data } = await axios.get(
        `${backendUrl}/investor-lnk-fund-americas?offering_id=${offering_id}&status=Invested`
        // `${backendUrl}/investor-lnk-fund-americas?offering_id=iPRnieDXQAC8Ktcq52Oa6g&status=Invested`
      );

      if (data) {
        dispatch({
          type: SET_RAISE_CAPITAL_ALL_INVESTORS_STATUS_INVESTED,
          payload: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

/*================================================================
                         Mentor Record
=================================================================*/
/**
 * SaveMentorRecord Action
 * 
 * Overview:
 * Submits mentor-related data to the backend for storage. This action supports the creation or update of mentor records, facilitating the tracking and management of mentor information within the application.
 * 
 * Details:
 * - Executes a POST request to `/mentor-records` with the provided form data to save a new mentor record.
 * - Utilizes a callback function to communicate the operation's success status back to the caller, allowing for custom follow-up actions based on the outcome.
 * 
 * Dependencies:
 * - axios for making the post request.
 * - backendUrl for API endpoint configuration.
 */
export const saveMentorRecord =
  (formData, callBackMentorRecord) => async () => {
    try {
      let { data, status } = await axios.post(
        `${backendUrl}/mentor-records`,
        formData
      );
      if (data) {
        // Toast.info("Information saved", 3000);
        callBackMentorRecord(status);
      }
    } catch (error) {
      console.log(error);
    }
  };
