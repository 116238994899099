import React, { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import logo from "../../../assets/imgs/desktop/common/vl-sm-logo.png";
import { useDispatch } from "react-redux";
import { newsLetterAction } from "./../../../web-app-store/actions/landingPageAction";
import { validateNewsLetter } from "./../../../web-app-store/validations/landingPage/newletterValidation";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * Newsletter Component
 * 
 * Overview:
 * The Newsletter component is responsible for rendering a newsletter subscription form and handling form submission. It allows users to enter their first name, last name, and email address to subscribe to newsletters and updates.
 * 
 * Details:
 * - The component uses React hooks to manage state, including the open state of two modal windows, the form input values, and any validation errors.
 * - The component includes event handlers for opening and closing the modals, handling form input changes, and submitting the form.
 * - The handleSubmit function validates the form input using a separate validation function and dispatches an action to submit the form data to the server.
 * - The component renders two modals: one for the newsletter subscription form and another for a thank you message after successful submission.
 * 
 * Usage:
 * To use the Newsletter component, import it into the desired location in your application and include it in your JSX code. You can customize the appearance of the modals by modifying the classNames prop of the Modal component.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library to dispatch actions.
 * - useState: The component uses the useState hook to manage state.
 * - Modal: The component uses the Modal component from an external library to render the modals.
 * - validateNewsLetter: The component uses a separate validation function to validate the form input.
 * - newsLetterAction: The component dispatches an action to submit the form data to the server.
 */
export default function Newsletter() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    email: "",
  });

  const [frontEndErrors, setfrontEndErrors] = useState({});
  const [backendError, setBackendError] = useState("");

  /*========================== 
             handler
 ===========================*/
  const openModal = () => {
    setOpen(!open);
  };

  const openModal2 = () => {
    setOpen2(!open2);
  };

  const handleChange = (event) => {
    setfrontEndErrors({});
    setBackendError({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleSubmit = (e) => {
    // setOpen2(!open2);
    // setOpen(!open);
    // console.log(values);
    e.preventDefault();

    const { errors, isValid } = validateNewsLetter(values);

    if (!isValid) {
      setfrontEndErrors(errors);
    } else {
      const formData = {
        first_name: values.fname,
        last_name: values.lname,
        email: values.email,
      };

      dispatch(newsLetterAction(formData, callBack, errorCallback));
    }
  };

  const callBack = (status) => {
    if (status === 200) {
      setOpen2(!open2);
      setOpen(!open);
    }
  };

  const errorCallback = (status) => {
    if (status === 405) {
      setBackendError("This email is already subscribed to newsletter");
    }
  };

  /*==================================
                   return
  =============================*/
  return (
    <>
    
        <div className="icons8-mail" onClick={openModal}/>
        {/* Newsletter */}
      {/*==== Modal 1 ====*/}
      <Modal
        open={open}
        onClose={openModal}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        center
        classNames={{
          overlay: "customOverlay-website",
          modal: "customModal-website customModal-website--newsletter",
          closeButton: "customCloseButton-website",
        }}
      >
        <span className="closeIconInModal-website" onClick={openModal} />
        <div className="newsletter-div">
          <h1 className="newsletter-title">Subscribe to</h1>
          <h4 className="font-30-montserrat-regular">
            Newsletters &amp; Updates
          </h4>
          <div className="newsletter-form-div">
            <form noValidate className="subscription-main">
              <div className="contact-us-input-div">
                <input
                  type="text"
                  placeholder="First Name"
                  name="fname"
                  value={values.fname}
                  onChange={handleChange}
                  required
                />
              {!isEmpty(frontEndErrors) && (
                <p className="validation-errors-forget-password">
                  {frontEndErrors.fname}
                </p>
              )}
              </div>
              <div className="contact-us-input-div pt-48">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lname"
                  value={values.lname}
                  onChange={handleChange}
                  required
                />
              {!isEmpty(frontEndErrors) && (
                <p className="validation-errors-forget-password">
                  {frontEndErrors.lname}
                </p>
              )}
              </div>
              <div className="contact-us-input-div pt-48">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  required
                />
                {!isEmpty(frontEndErrors) && (
                  <p className="validation-errors-forget-password">
                    {frontEndErrors.email}
                  </p>
                )}
                {!isEmpty(backendError) && (
                  <p className="validation-errors-forget-password">
                    {backendError}
                  </p>
                )}
              </div>

              <button onClick={handleSubmit} className="newsletter-submit-btn">
                SUBSCRIBE
              </button>
            </form>
          </div>
        </div>
      </Modal>
      {/*==== Modal 2 ====*/}
      <Modal
        open={open2}
        onClose={openModal2}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        center
        classNames={{
          overlay: "customOverlay-website",
          modal:
            "customModal-website customModal-website--newsletter-thank-you",
          closeButton: "customCloseButton-website",
        }}
      >
        <span className="closeIconInModal-website" onClick={openModal2} />
        <div className="newsletter-thnak-you-div">
          <img src={logo} alt="vedslabs logo" className="newsletter-vl-logo" />
          <h4 className="newsletter-thank-you-text">Thank You</h4>
          <h5 className="font-30-montserrat-regular">for Subscribing</h5>
          <button
            onClick={openModal2}
            className="newsletter-submit-btn newsletter-submit-btn--thank-you"
          >
            CLOSE
          </button>
        </div>
      </Modal>
    </>
  );
}
