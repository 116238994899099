import React, {
  Fragment,
  //  useState
} from "react";
//import { Link } from "react-router-dom";
import SideNavbar from "../header/SideNavbar";
import TopNavbar from "../header/TopNavbar";
import { activeSidebarMenu, explorePageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import ExploreCard from "./ExploreCard";
// import Select from "react-select";

// api
import { useEffect } from "react";
import { getExploreAllOfferingsActiveInvestment } from "../../../web-app-store/actions/exploreAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
// import DisplayLoader from "../common/DisplayLoader";
import Footer from "../../../components/desktop/header-footer/Footer";

// let search = "/web-app-img/desktop/icons/search-icon.svg";
// let location = "/web-app-img/desktop/icons/location.png";

// const dummyData = [1, 2, 3];
// const dummyData2 = [1, 2, 3, 4];

// const employeeOption = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

// const stageOption = [
//   { value: "Lorem", label: "Lorem" },
//   { value: "Ipsum", label: "Ipsum" },
// ];

/**
 * Explore Component
 * 
 * Overview:
 * The Explore component is responsible for displaying a page that allows users to explore offerings and filter them based on search criteria. It fetches data from the server and renders ExploreCard components to display the offerings.
 * 
 * Details:
 * - The component uses Redux to manage state and dispatch actions to fetch data from the server.
 * - It uses the useSelector hook to access the exploreAllOfferingsActiveInvestment state from the Redux store.
 * - The component uses the useEffect hook to fetch data from the server when the component mounts.
 * - The component includes commented out code for handling search and filter functionality, which can be uncommented and implemented as needed.
 * - The component renders a SideNavbar component, a PageTitle component, and a list of ExploreCard components.
 * 
 * Usage:
 * To use the Explore component, import it into the desired location in your application and render it as a JSX element. Make sure to include the necessary Redux setup and configure the Redux store with the appropriate reducers and middleware.
 * 
 * Dependencies:
 * - React: The Explore component is built using React and requires it to be installed as a dependency.
 * - Redux: The component uses Redux for state management and requires it to be installed as a dependency. It also requires the useDispatch and useSelector hooks from the react-redux package.
 * - ExploreCard: The component renders ExploreCard components to display the offerings and requires the ExploreCard component to be imported and available in the application.
 * - SideNavbar: The component renders a SideNavbar component for navigation and requires the SideNavbar component to be imported and available in the application.
 * - PageTitle: The component renders a PageTitle component for displaying the page title and requires the PageTitle component to be imported and available in the application.
 * - isEmpty: The component uses the isEmpty function to check if the exploreAllOfferingsActiveInvestment state is empty and requires it to be imported and available in the application.
 * - getExploreAllOfferingsActiveInvestment: The component dispatches the getExploreAllOfferingsActiveInvestment action to fetch data from the server and requires it to be imported and available in the application.
 *  */
export default function Explore() {
  // const [values, setValues] = useState({
  //   search: "",
  //   location: "",
  //   employee: "",
  //   stage: "",
  // });
  // api
  const dispatch = useDispatch();

  const exploreAllOfferingsActiveInvestmentOne = useSelector(
    (state) => state.explore.exploreAllOfferingsActiveInvestment
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isEmpty(exploreAllOfferingsActiveInvestmentOne)) {
      dispatch(getExploreAllOfferingsActiveInvestment());
    }
  }, [dispatch]);

  const exploreAllOfferingsActiveInvestment = useSelector(
    (state) => state.explore.exploreAllOfferingsActiveInvestment
  );

  /*==========================
             handler
 ===========================*/

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const handleChangeEmployee = (employee) => {
  //   setValues({
  //     ...values,
  //     employee,
  //   });
  // };

  // const handleChangeStage = (stage) => {
  //   setValues({
  //     ...values,
  //     stage,
  //   });
  // };

  /*=================================
            render
  =================================*/

  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[1]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={explorePageTitle.icon}
            pageTitle={explorePageTitle.title}
          />
          {/* search */}
          <div className="explore-div">
            {/* <div className="row mx-0 align-items-center flex-nowrap">
            <div className="explore-search-input-div row mx-0 align-items-center">
              <div className="explore-search-img-div">
                <img src={search} alt="search" className="explore-search-img" />
              </div>
              <input
                name="search"
                onChange={handleChange}
                value={values.search}
                placeholder="Search "
              />
            </div>

            
            <div className="explore-search-input-div--location-outer-div">
              <div className="explore-search-input-div explore-search-input-div--location row mx-0 align-items-center">
                <div className="explore-search-img-div">
                  <img
                    src={location}
                    alt="search"
                    className="explore-search-img"
                  />
                </div>
                <input
                  name="location"
                  onChange={handleChange}
                  value={values.location}
                  placeholder="Location"
                />
              </div>
            </div>
            <Select
              value={values.employee}
              onChange={handleChangeEmployee}
              options={employeeOption}
              className="vl-select-container-explore"
              classNamePrefix="vl-select-explore"
              placeholder="5-10 Employees"
              isSearchable={false}
            />
            <Select
              value={values.stage}
              onChange={handleChangeStage}
              options={stageOption}
              className="vl-select-container-explore"
              classNamePrefix="vl-select-explore"
              placeholder="Select Stage"
              isSearchable={false}
            />
          </div> */}

            {/* all cards */}
            <div className="row">
              {!isEmpty(exploreAllOfferingsActiveInvestment) ? (
                <>
                  {exploreAllOfferingsActiveInvestment.map((dataMap, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <Fragment key={index}>
                        <ExploreCard offeringsData={dataMap} />
                      </Fragment>
                    </div>
                  ))}
                </>
              ) : (
                <div className="w-100 pt-20 text-center">
                  {/* <DisplayLoader /> */}
                  {/* <p className="vl-disc-card-row__text3 font-18-roboto-medium">
                    Active listings not found.
                  </p> */}
                </div>
              )}
            </div>

            {/* <h5 className="explore-font-24-roboto-bold">
            Startups nearing their goal
          </h5>
          <div className="row mx-0 flex-nowrap">
            {dummyData.map((data, key) => (
              <Fragment key={key}>
                <ExploreCard />
              </Fragment>
            ))}
          </div>
          <div className="pt-74">
            <h5 className="explore-font-24-roboto-bold">
              Newly Started Fundraisers
            </h5>
            <div className="row mx-0 flex-nowrap">
              {dummyData.map((data, key) => (
                <Fragment key={key}>
                  <ExploreCard />
                </Fragment>
              ))}
            </div>
          </div>
          <div className="pt-82"> 
            <h5 className="explore-font-24-roboto-bold">
              Sort Start-ups by Tags
            </h5>
            <div className="row mx-0 ">
              {dummyData2.map((data, key) => (
                <div className="sort-tags-btn" key={key}>
                  <h5>Tag Name</h5>
                </div>
              ))}
            </div>
            <div className="row mx-0 flex-nowrap pt-65">
              {dummyData.map((data, key) => (
                <Fragment key={key}>
                  <ExploreCard />
                </Fragment>
              ))}
            </div>
          </div>*/}
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
