import React, { useEffect, useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import TextareaField from "../common/TextareaField";

// api
import { useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * RaiseCapitalPitchAndProductDisplay Component
 *
 * Overview:
 * The RaiseCapitalPitchAndProductDisplay component is responsible for displaying the pitch deck and product information for raising capital. It includes the functionality to scroll to the top of the page, retrieve data from the API, and display the uploaded file and product overview.
 *
 * Details:
 * - The component uses the useEffect hook to scroll to the top of the page when it mounts.
 * - It uses the useState hook to manage the values of videoUrl, productOverview, business_model, files, and singleFileName.
 * - The component retrieves data from the raiseCapitalOrg state using the useSelector hook.
 * - When the raiseCapitalOrg state changes, the component updates the values using the setValues function.
 * - The singleFileDisplay function renders the uploaded file information.
 * - The component renders the pitch deck section, displaying the uploaded file or a message if no files are added.
 * - It also renders the pitch video URL, product/service overview, and business model sections.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - react-redux: The component uses the useSelector hook to retrieve data from the Redux store.
 * - isEmpty: The component uses the isEmpty function to check if the raiseCapitalOrg data is empty.
 * - InputFieldEmailTextPassword: The component uses the InputFieldEmailTextPassword component to render the pitch video URL input field.
 * - TextareaField: The component uses the TextareaField component to render the product/service overview and business model input fields.
 */
export default function RaiseCapitalPitchAndProductDisplay() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    videoUrl: "",
    productOverview: "",
    business_model: "",
    files: [],
    singleFileName: "",
    valueProposition: "",
    competition: "",
  });

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      let data = raiseCapitalOrg[0];
      let valuesObj = {
        videoUrl: data.pitch_video_url,
        files: data.pitch_deck,
        singleFileName: data.pitch_deck === null ? null : data.pitch_deck.name,
        productOverview: data.product_overview,
        business_model: data.business_model,
        valueProposition: data.value_proposition,
        competition: data.competition,
      };
      setValues({ ...valuesObj });
    }
  }, [raiseCapitalOrg]);

  // const filesList = values.files.map((file, index) => (
  //   <li key={index}>
  //     <span>
  //       <i className="fa fa-file file-icon" />
  //     </span>
  //     <span className="drag-and-drop-item-list__fileName"> {file}</span>
  //   </li>
  // ));

  const singleFileDisplay = () => {
    return (
      <li>
        <span>
          <i className="fa fa-file file-icon" />
        </span>
        <span className="drag-and-drop-item-list__fileName">
          {!isEmpty(values.singleFileName)
            ? values.singleFileName
            : isEmpty(values.files[0])
            ? ""
            : values.files[0].path}
        </span>
      </li>
    );
  };

  return (
    <>
      <>
        <h3 className="raise-capital-more-info-label pb-32">Pitch Deck</h3>
        {!isEmpty(values.files) ? (
          <div className="pb-32">
            <h3 className="pitch-and-product-font-18-regular">Uploaded file</h3>
            <ul className="drag-and-drop-item-list">
              {/* {filesList} */}
              {singleFileDisplay()}
            </ul>
          </div>
        ) : (
          <h3 className="pitch-and-product-font-18-regular pb-32">
            No files added
          </h3>
        )}
      </>

      <div className="row mx-0 ">
        <div className="pitch-text-filed col-md-6 px-0">
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Pitch Video URL"
            name="videoUrl"
            value={values.videoUrl}
            type="text"
            isDisabled={true}
          />
        </div>
      </div>
      <TextareaField
        label="Value Proposition"
        labelClassName="raise-capital-more-info-label pt-32 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_product_textarea"
        name="valueProposition"
        value={values.valueProposition}
        isDisabled={true}
      />

      <TextareaField
        label="Competition"
        labelClassName="raise-capital-more-info-label pt-32 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_product_textarea"
        name="competition"
        value={values.competition}
        isDisabled={true}
      />
      {/* <div className="pt-40">
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
          id="productOverview"
          label="Product/Service Overview"
          labelClassName="raise-capital-more-info-label pb-22"
          name="productOverview"
          value={values.productOverview}
          isDisabled={true}
        />
      </div>
      <div className="pt-30">
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
          id="business_model"
          label="Business Model"
          labelClassName="raise-capital-more-info-label pb-22"
          name="business_model"
          value={values.business_model}
          isDisabled={true}
        />
      </div> */}
    </>
  );
}
