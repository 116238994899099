// import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateRaiseCapitalPitchAndProduct Function
 *
 * Overview:
 * This function is used to validate the data for raising capital pitch and product. It checks if the required fields are empty and returns any errors found.
 *
 * Details:
 * - The function takes a data object as a parameter.
 * - It initializes an empty errors object.
 * - It checks if the 'files' field in the data object is empty and adds an error message if it is.
 * - It checks if the 'productOverview' field in the data object is empty and adds an error message if it is.
 * - It checks if the 'business_model' field in the data object is empty and adds an error message if it is.
 * - It returns an object containing the errors and a boolean value indicating if the data is valid (no errors).
 *
 * Dependencies:
 * - The function depends on the 'isEmpty' function, which is not defined in this file.
 * - The 'isEmpty' function is assumed to be available in the scope where this function is used.
 */
export const validateRaiseCapitalPitchAndProduct = (data) => {
  let errors = {};

  //   console.log(data);

  // if (isEmpty(data.videoUrl)) {
  //   errors.videoUrl = "Pitch Video URL is required";
  // }

  if (isEmpty(data.files)) {
    errors.files = "Pitch deck is required";
  }

  // if (isEmpty(data.productOverview)) {
  //   errors.productOverview = "Product/Service Overview is required";
  // }

  // if (isEmpty(data.business_model)) {
  //   errors.business_model = "Business model required";
  // }

  if (isEmpty(data.competition)) {
    errors.competition = "Competition is required";
  }

  if (isEmpty(data.valueProposition)) {
    errors.valueProposition = "Value proposition is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
