import differenceInCalendarDays from "date-fns/difference_in_days";

/**
 * calcDaysRemaining Function
 * 
 * Overview:
 * This function calculates the number of days remaining between a start date and an end date. It determines the difference in calendar days between the current date and the start date, and the current date and the end date. Based on these calculations, it returns the number of days remaining until the end date.
 * 
 * Details:
 * - The function accepts two parameters: startDate and endDate, which represent the start and end dates respectively.
 * - It uses the JavaScript Date object to create date instances for the current date, start date, and end date.
 * - The function uses the differenceInCalendarDays function to calculate the difference in calendar days between the current date and the start date, and the current date and the end date.
 * - If the difference between the current date and the start date is less than or equal to 0, it means that the current date is before or on the start date. In this case, the function returns the difference in calendar days between the end date and the current date.
 * - If the difference between the current date and the start date is greater than 0 and the difference between the current date and the end date is less than or equal to 0, it means that the current date is after the start date but before or on the end date. In this case, the function returns the difference in calendar days between the end date and the current date.
 * - If the difference between the current date and the end date is greater than 0, it means that the current date is after the end date. In this case, the function returns -1 to indicate that there are no days remaining.
 * - If none of the above conditions are met, the function also returns -1.
 * 
 * Usage:
 * const startDate = "2021-03-12";
 * const endDate = "2021-03-16";
 * const daysRemaining = calcDaysRemaining(startDate, endDate);
 * console.log(daysRemaining); // Output: 0
 * 
 * Dependencies:
 * - The function depends on the differenceInCalendarDays function, which is not defined in the provided code. Please ensure that the differenceInCalendarDays function is available and correctly implemented.
 */
export const calcDaysRemaining = (startDate, endDate) => {
  // let today = new Date("3-19-2021");
  // let start = new Date("3-12-2021");
  // let end = new Date("3-16-2021");
  let today = new Date();
  let start = new Date(startDate);
  let end = new Date(endDate);

  if (differenceInCalendarDays(today, start) <= 0) {
    // today is < start date
    return differenceInCalendarDays(end, today);
    // return differenceInCalendarDays(end, start);
  } else if (
    differenceInCalendarDays(today, start) > 0 &&
    differenceInCalendarDays(today, end) <= 0
  ) {
    // today is > start date && today is <= end date
    return differenceInCalendarDays(end, today);
  } else if (differenceInCalendarDays(today, end) > 0) {
    return -1;
  } else {
    return -1;
  }
};

// const calcDaysRemaining = () => {
//   let start = new Date(startEndDate.startDate);
//   let end = new Date(startEndDate.endDate);
//   let timeDiff = end.getTime() - new Date();
//   let diffDays = differenceInCalendarDays(end, start);

//   if (timeDiff < 0 || diffDays < 0) {
//     diffDays = -1;
//   }

//   return diffDays;
// };

// let daysRemainingVal = calcDaysRemaining();
