import React, { Fragment, useState } from "react";

// api
import { useEffect } from "react";
import { putRaiseCapitalUpdate } from "../../../web-app-store/actions/updatesAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import TextareaField from "../common/TextareaField";
import SaveButton from "../common/SaveButton";
import SkipButton from "../common/SkipButton";

/**
 * RaiseCapitalEditUpdate Component
 * 
 * Overview:
 * The RaiseCapitalEditUpdate component is responsible for rendering a form for editing and updating a post related to raising capital. It allows users to enter and save updates to share with their investors.
 * 
 * Details:
 * - The component uses React hooks such as useState and useEffect to manage state and side effects.
 * - It utilizes Redux to access the raiseCapitalOrg state from the store.
 * - The component includes a TextareaField sub-component for rendering the update details input field.
 * - The component handles user input changes using the handleChange function.
 * - The handleSave function is triggered when the user clicks the save button. It validates the input and dispatches an action to update the post.
 * - The component conditionally renders the edit form or the post details based on the isEditPost state.
 * 
 * Props:
 * - postId: The ID of the post being edited.
 * - update_details: The initial value of the update details input field.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - useDispatch: The component uses the useDispatch hook from the react-redux library.
 * - useSelector: The component uses the useSelector hook from the react-redux library.
 * - putRaiseCapitalUpdate: The component depends on the putRaiseCapitalUpdate action creator for updating the post.
 * - TextareaField: The component uses the TextareaField sub-component for rendering the update details input field.
 */
export default function RaiseCapitalEditUpdate({ postId, update_details }) {
  // api
  const dispatch = useDispatch();

  const [isEditPost, setIsEditPost] = useState(false);

  const [values, setValues] = useState({
    updateDetails: update_details,
  });

  const [loading, setLoading] = useState(false);

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    let valuesObj = { updateDetails: update_details };
    setValues({ ...valuesObj });
  }, [update_details]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const callBackPostUpdateStatus = (status) => {
    if (status === 200) {
      setLoading(false);
      setIsEditPost(false);
    }
  };

  const handleSave = (postId) => (e) => {
    if (!isEmpty(values.updateDetails)) {
      if (!isEmpty(raiseCapitalOrg)) {
        setLoading(true);

        let userData = JSON.parse(localStorage.getItem("userData"));
        let formData = {
          user: { id: userData.id },
          organization: { id: raiseCapitalOrg[0].id },
          update_details: values.updateDetails,
        };

        dispatch(
          putRaiseCapitalUpdate(formData, postId, callBackPostUpdateStatus)
        );

        setValues({ updateDetails: "" });
      }
    }
  };

  return (
    <>
      {isEditPost ? (
        <>
          <div className="row mx-0 flex-nowrap align-items-start vl-raise-capital-updates-post-details vl-raise-capital-updates-post-details--edit">
            <TextareaField
              isHideLabel={true}
              containerClassName="vl-form-input"
              labelClassName="raise-capital-label-company-info"
              name="updateDetails"
              placeholder="Post an update to share with your investors."
              value={values.updateDetails}
              onChange={handleChange}
            />

            <div className="vl-raise-capital-updates-post-details__post-btn-div">
              <SkipButton
                text="Cancel"
                handleOnClickSkipButton={() => setIsEditPost(false)}
              />
              <SaveButton
                text="Save"
                handleOnClickSaveButton={handleSave(postId)}
                loading={loading}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="font-16-roboto-light startup-details-tab-panel-updates-text-2 pt-15">
            {update_details}
            <button onClick={() => setIsEditPost(true)} className="pl-30">
              <i className="fa fa-pencil"></i>
            </button>
          </p>
        </>
      )}
    </>
  );
}
