import React from "react";
import CurrencyFormat from "react-currency-format";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * CurrencyInputCommon Component
 * 
 * Overview:
 * The CurrencyInputCommon component is a reusable component that provides an input field for currency values. It formats the input value as a currency and allows for easy input of monetary values.
 * 
 * Details:
 * - The component accepts various props to customize its behavior and appearance, such as containerClassName, label, id, name, placeholder, onChange, value, maxLength, autoFocus, error, isDisabled, isReadOnly, and isHideLabel.
 * - The component renders an input field wrapped in a container div. It also includes a label, error message, and additional styling based on the props provided.
 * - The input field uses the CurrencyFormat component from an external library to format the currency value. It supports features like thousand separators, fixed decimal scale, prefix, numeric input mode, and more.
 * 
 * Dependencies:
 * - The CurrencyInputCommon component depends on the CurrencyFormat component from an external library to format the currency value. Make sure to include the necessary dependencies and import statements.
 */
export default function CurrencyInputCommon({
  containerClassName,
  label,
  id,
  name,
  placeholder,
  onChange,
  value,
  maxLength,
  autoFocus,
  error,
  isDisabled,
  isReadOnly,
  isHideLabel,
}) {
  return (
    <div className={containerClassName}>
      <div className={isHideLabel ? "d-none" : ""}>
        <label htmlFor={id ? id : name}>{label}</label>
      </div>
      <div
        className={
          isDisabled || isReadOnly
            ? "input-border-div input-border-div--disabled"
            : "input-border-div"
        }
      >
        <CurrencyFormat
          id={id ? id : name}
          name={name}
          onValueChange={onChange}
          value={isEmpty(value) ? "" : value}
          thousandSeparator={true}
          fixedDecimalScale={true}
          prefix={"$"}
          isNumericString={true}
          inputMode="numeric"
          allowNegative={false}
          maxLength={28}
          disabled={isDisabled}
          autoComplete="off"
          placeholder={placeholder}
        />
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <p className="error-message opacity-0">error</p>
      )}
    </div>
  );
}

CurrencyInputCommon.defaultProps = {
  label: "",
  placeholder: "",
  maxLength: "",
  error: "",
  autoFocus: "",
  isDisabled: false,
  isReadOnly: false,
  isHideLabel: false,
};
