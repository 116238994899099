import axios from "axios";
import { backendUrl } from "./config";
import { SET_INVEST_PORTFOLIO } from "./../types";

// import Toast from "light-toast";

/*===================================================================================
            investors by user id
====================================================================================*/
/**
 * getInvestorPortfolioList Function
 * 
 * Overview:
 * Fetches a list of investment portfolios for the logged-in user from the backend and updates the Redux state. It uses the user's ID from local storage to request the portfolios specific to the user.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - Redux for state management.
 * - backendUrl and SET_INVEST_PORTFOLIO constants.
 */
export const getInvestorPortfolioList = () => async (dispatch) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  try {
    let { data } = await axios.get(
      // `${backendUrl}/investor-lnk-fund-americas?user=750`
      `${backendUrl}/investor-lnk-fund-americas?user=${userData.id}`
    );
    if (data) {
      dispatch({
        type: SET_INVEST_PORTFOLIO,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get explore offerings / fundraiser by id
====================================================================================*/
/**
 * getInvestorPortfolioOfferingsById Function
 * 
 * Overview:
 * Fetches detailed information about a specific investment offering using its ID. The function allows for a callback to be executed with the fetched data for custom post-fetch logic.
 * 
 * Dependencies:
 * - axios for HTTP requests.
 * - backendUrl constant.
 */
export const getInvestorPortfolioOfferingsById = (
  offeringId,
  callBackOfferingData
) => async () => {
  try {
    let { data } = await axios.get(
      `${backendUrl}/offerings?offering_id=${offeringId}`
    );
    if (data) {
      callBackOfferingData(data);
    }
  } catch (err) {
    console.log(err);
  }
};
