import isEmpty from "../is-empty";

/**
 * Investor Portfolio Validation
 *
 * Overview:
 * This module provides a function to validate investor portfolio data. It checks if the required fields are present and returns any validation errors.
 *
 * Details:
 * - The function takes an object containing investor portfolio data as input.
 * - It checks if the investorType, revenue_stage, and companyInvestmentStage fields are empty.
 * - If any of these fields are empty, it adds an error message to the errors object.
 * - The function returns an object with the errors and a boolean flag indicating if the data is valid.
 *
 * Dependencies:
 * This module does not have any external dependencies.
 */
export const validateInvestorPortfolio = (data) => {
  let errors = {};

  //   console.log(data);

  if (isEmpty(data.investorType)) {
    errors.investorType = "Investor type is required";
  }

  if (isEmpty(data.selectedRadioOption)) {
    errors.selectedRadioOption = "Select Yes or No for investment group";
  }

  // if (isEmpty(data.revenue_stage)) {
  //   errors.revenue_stage = "Revenue stage is required";
  // }

  // if (isEmpty(data.companyInvestmentStage)) {
  //   errors.companyInvestmentStage = "Company investment stage is required";
  // }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
