import React, { useEffect, useState } from "react";
import SideNavbar from "../header/SideNavbar";
import TopNavbar from "../header/TopNavbar";
import {
  activeSidebarMenu,
  knowledgeBasePageTitle,
} from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import Footer from "../../../components/desktop/header-footer/Footer";
import GlossaryFoldOne from "../../../components/desktop/glossary/GlossaryFoldOne";

/**
 * GlossaryWebApp Component
 * 
 * Overview:
 * The GlossaryWebApp component is responsible for rendering the glossary web application. It includes a side navigation bar, a main content area, and a footer. The main content area consists of a page title and a glossary fold.
 * 
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage the foldOneHeight state and perform side effects.
 * - The component scrolls to the top of the page when it mounts.
 * - The component retrieves the height of the page title element and sets it as the foldOneHeight state.
 * - The component renders a SideNavbar component to display the side navigation bar.
 * - The component renders a div with the class "vl-main-page-div" to contain the main content area.
 * - The main content area is further divided into two divs: "vl-main-page-div__content" and "vl-glossary-web-app".
 * - The "vl-main-page-div__content" div contains the page title element and the glossary fold.
 * - The page title element is rendered using the PageTitle component and receives an image path and a page title as props.
 * - The glossary fold is rendered using the GlossaryFoldOne component and receives the foldOneHeight as a prop.
 * - The component renders a Footer component with the webAppFooter prop set to true.
 * 
 * Dependencies:
 * - React: The component uses React hooks and JSX syntax.
 * - SideNavbar: The component depends on the SideNavbar component to render the side navigation bar.
 * - PageTitle: The component depends on the PageTitle component to render the page title.
 * - GlossaryFoldOne: The component depends on the GlossaryFoldOne component to render the glossary fold.
 * - Footer: The component depends on the Footer component to render the footer.
 */
export default function GlossaryWebApp() {
  const [foldOneHeight, setFoldOneHeight] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-main-page-div__page-title-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-main-page-div__page-title-id").offsetHeight
      );
    }
  }, []);

  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[4]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          <div id="vl-main-page-div__page-title-id">
            {/* <TopNavbar /> */}
            <PageTitle
              imgPath={knowledgeBasePageTitle.icon}
              pageTitle={knowledgeBasePageTitle.title}
            />
          </div>
          <div className="vl-glossary-web-app">
            <GlossaryFoldOne foldOneHeight={foldOneHeight} />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
