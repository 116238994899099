import React, { useEffect, useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
// import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import CurrencyInputCommon from "../common/CurrencyInputCommon";
import SaveAndNextButton from "../common/SaveAndNextButton";
import SaveButton from "../common/SaveButton";
import InvestLimitCalculatorCard from "./InvestLimitCalculatorCard";
import BackButton from "../common/BackButton";

// api
import { useDispatch, useSelector } from "react-redux";
import {
  createInvestor,
  updateInvestor,
} from "../../../web-app-store/actions/investAction";
import { validateInvestorCalculator } from "../../../web-app-store/validations/invest/investorCalculator";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { jobIndustryOptionArray } from "../common/allDropdownOptions";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import NetWorthCalcNeedHelp from "./NetWorthCalcNeedHelp";

/**
 * InvestCalculator Component
 *
 * Overview:
 * The InvestCalculator component is responsible for rendering a form that collects information from the user related to their investment criteria and limits. It calculates the user's investment limit based on their net worth and income. The collected information is used to calculate the investment limit and is stored securely.
 *
 * Details:
 * - The component uses Redux for state management and makes API calls to update or create investor data.
 * - The component includes various input fields and dropdowns to collect information such as yearly income, net worth, job title, job industry, and spouse information.
 * - The component handles checkbox changes and updates the state accordingly.
 * - The component includes validation logic to check if the form is valid before submitting the data.
 * - The component renders sub-components for specific sections, such as spouse information.
 *
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - React Redux: The component uses React Redux to connect with the Redux store.
 * - API: The component makes API calls to update or create investor data.
 * - Other custom components: The component uses custom input fields, dropdowns, and checkboxes.
 */
export default function InvestCalculator({
  isCalculatorMainTab,
  handleOnClickOfSaveMainCalcTab,
  handleOnSelectTab,
}) {
  // api
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [frontEndErrors, setFrontEndErrors] = useState({});
  let userData = JSON.parse(localStorage.getItem("userData"));

  const investor = useSelector((state) => state.invest.investor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    yearlyIncome: "",
    networth: "",
    jobTitle: "",
    jobIndustry: "",
    spouse_income_included: false,
    invested_outside_vlabs: false,
    spouse_acc_vedaslab: false,
    fname: "",
    lname: "",
    email: "",
    amountInvestedOutsideOfVL: "",
    amountInvestedOnVL: "",
  });

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];
      let valuesObj = {
        yearlyIncome: !isEmpty(data.income) ? data.income : "",
        networth: !isEmpty(data.networth) ? data.networth : "",
        amountInvestedOutsideOfVL: !isEmpty(data.amount_invested_outside)
          ? data.amount_invested_outside
          : "",
        amountInvestedOnVL: !isEmpty(data.amount_invested_vlabs)
          ? data.amount_invested_vlabs
          : "",
        jobTitle: data.job_title,
        jobIndustry: !isEmpty(data.job_industry)
          ? {
              value: data.job_industry,
              label: data.job_industry,
            }
          : "",
        spouse_income_included: data.spouse_income_included,
        invested_outside_vlabs: data.invested_outside_vlabs,
        spouse_acc_vedaslab: data.spouse_acc_vedaslab,
        fname: data.spouse_first_name,
        lname: data.spouse_last_name,
        email: data.spouse_email,
      };
      setValues({ ...valuesObj });
    }
  }, [investor]);

  /*============================================================
                           handler
  ============================================================*/
  const handleChange = (event) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeJobIndustry = (jobIndustry) => {
    setValues({
      ...values,
      jobIndustry,
    });
  };

  const handleCheckboxChange = (e) => {
    setFrontEndErrors({});
    console.log(e.target.name, "-=-=-=");
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      if (e?.target?.name === "spouse_income_included") {
        setValues({
          ...values,
          fname: "",
          lname: "",
          email: "",
          [e.target.name]: e.target.checked,
        });
      } else {
        setValues({
          ...values,
          [e.target.name]: e.target.checked,
        });
      }
    }
  };

  const handleCheckboxChangeCrowdFunding = (e) => {
    setFrontEndErrors({});
    if (e.target.checked) {
      setValues({
        ...values,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        amountInvestedOutsideOfVL: "",
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleChangeYearlyIncome = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      yearlyIncome:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeNetworth = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      networth:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const handleChangeAmountInvestedOutsideOfVL = (element) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      amountInvestedOutsideOfVL:
        isEmpty(element.floatValue) || isNaN(element.floatValue)
          ? ""
          : element.floatValue,
    });
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      // if main tab of calculator
      if (isCalculatorMainTab) {
        handleOnClickOfSaveMainCalcTab();
      } else {
        handleOnSelectTab(1);
      }
    } else {
      handleOnSelectTab(0);
    }
  };

  const handleOnClickSaveAndNext = () => {
    const { errors, isValid } = validateInvestorCalculator(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    } else if (isValid) {
      setFrontEndErrors({});
      setLoading(true);

      let formData = {
        user: { id: userData.id },
        income: !isEmpty(values.yearlyIncome) ? values.yearlyIncome : null,
        networth: !isEmpty(values.networth) ? values.networth : null,
        amount_invested_outside: !isEmpty(values.amountInvestedOutsideOfVL)
          ? values.amountInvestedOutsideOfVL
          : null,
        job_title: values.jobTitle,
        job_industry: values.jobIndustry.value,
        spouse_income_included: values.spouse_income_included,
        invested_outside_vlabs: values.invested_outside_vlabs,
        spouse_acc_vedaslab: values.spouse_acc_vedaslab,
        spouse_first_name: values.fname,
        spouse_last_name: values.lname,
        spouse_email: values.email,
        first_name: userData.firstname,
        last_name: userData.lastname,
        email: userData.email,
        location: userData.Address,
      };

      if (isEmpty(investor)) {
        dispatch(createInvestor(formData, callBackStatus));
      } else {
        dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
      }
    }
  };

  const renderSpouseInfo = () => {
    return (
      <>
        <h3 className="invest-calculator-font-24-medium">Spouse Information</h3>
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="First Name"
          name="fname"
          value={values.fname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.fname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Last Name"
          name="lname"
          value={values.lname}
          onChange={handleChange}
          type="text"
          error={frontEndErrors.lname}
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Email Address"
          name="email"
          value={values.email}
          onChange={handleChange}
          type="email"
          error={frontEndErrors.email}
        />
      </>
    );
  };

  /*============================================================
                           render
  ============================================================*/
  return (
    <>
      <div className="row invest-personal-tab-panel invest-personal-tab-panel--calculator">
        <div className="col-12 col-md-6 px-0">
          <h2 className="invest-calcultor-font-25-medium">
            Investment Criteria &amp; Limits
          </h2>
          <h4 className="invest-calcultor-font-20-regular">
            The SEC limits how much you can invest in any 12 month period. The
            limit is calculated based on your net worth and income.
          </h4>
          <h5 className="invest-calcultor-font-16-regular">
            The information collected below is stored securely and is only used
            to calculate your investment limit.
          </h5>

          <CurrencyInputCommon
            containerClassName="vl-form-input"
            label="Yearly Income ($)"
            name="yearlyIncome"
            value={values.yearlyIncome}
            onChange={handleChangeYearlyIncome}
            error={frontEndErrors.yearlyIncome}
          />

          <div className="vl-net-worth-with-note-block">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Net Worth ($)"
              name="networth"
              value={values.networth}
              onChange={handleChangeNetworth}
              error={frontEndErrors.networth}
            />
            <NetWorthCalcNeedHelp />
          </div>

          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Job Title"
            name="jobTitle"
            value={values.jobTitle}
            onChange={handleChange}
            type="text"
            error={frontEndErrors.jobTitle}
          />

          <DropdownSelectCommon
            label="Job Industry"
            value={values.jobIndustry}
            onChange={handleChangeJobIndustry}
            options={jobIndustryOptionArray}
            placeholder={values.jobIndustry}
            isSearchable={true}
            error={frontEndErrors.jobIndustry}
          />

          <div className="vl-checkbox vl-checkbox--invest-calculator">
            <Checkbox
              onChange={handleCheckboxChange}
              value={values.spouse_income_included}
              checked={values.spouse_income_included}
              defaultChecked={true}
              name="spouse_income_included"
              id="spouse_income_included"
            />
            <label htmlFor="spouse_income_included">
              Including my spouse’s income and net worth.
            </label>
          </div>

          {values.spouse_income_included && renderSpouseInfo()}

          <div className="vl-checkbox vl-checkbox--invest-calculator mt-45">
            <Checkbox
              onChange={handleCheckboxChangeCrowdFunding}
              value={values.invested_outside_vlabs}
              checked={values.invested_outside_vlabs}
              defaultChecked={false}
              name="invested_outside_vlabs"
              id="invested_outside_vlabs"
            />
            <label htmlFor="invested_outside_vlabs">
              I have invested in crowdfunding in the past 12 months.
            </label>
          </div>

          {values.invested_outside_vlabs && (
            <div className="mt-45 pt-15">
              <CurrencyInputCommon
                containerClassName="vl-form-input"
                label="Amount Invested Outside of VedasLabs ($)"
                name="amountInvestedOutsideOfVL"
                value={values.amountInvestedOutsideOfVL}
                onChange={handleChangeAmountInvestedOutsideOfVL}
                error={frontEndErrors.amountInvestedOutsideOfVL}
              />
            </div>
          )}

          <div className="vl-checkbox vl-checkbox--invest-calculator mt-38">
            <Checkbox
              onChange={handleCheckboxChange}
              value={values.spouse_acc_vedaslab}
              checked={values.spouse_acc_vedaslab}
              defaultChecked={false}
              name="spouse_acc_vedaslab"
              id="spouse_acc_vedaslab"
            />
            <label htmlFor="spouse_acc_vedaslab">
              My spouse has an account with VedasLabs.
            </label>
          </div>
        </div>
        <div className="col-12 col-md-6 px-0 vl-invest-calculator-form-limit-card-outer-div">
          <InvestLimitCalculatorCard
            net_worth={values.networth}
            annual_income={values.yearlyIncome}
            amountInvestedOutsideOfVL={values.amountInvestedOutsideOfVL}
            amountInvestedOnVL={values.amountInvestedOnVL}
            spouseIncomeIncluded={values.spouse_income_included}
          />
        </div>
      </div>

      {isCalculatorMainTab ? (
        <div className="calculator-save-btn-div d-flex mx-0 justify-content-end">
          <SaveButton
            text="Save"
            loading={loading}
            handleOnClickSaveButton={handleOnClickSaveAndNext}
          />
        </div>
      ) : (
        <div className="calculator-save-btn-div d-flex mx-0 justify-content-end">
          {/* <BackButton handleOnClickBack={() => handleOnSelectTab(0)} /> */}

          <SaveAndNextButton
            handleOnClickSaveAndNext={handleOnClickSaveAndNext}
            loading={loading}
          />
        </div>
      )}
    </>
  );
}

InvestCalculator.defaultProps = {
  isCalculatorMainTab: false,
};
