import React from "react";
import Navbar from "../header-footer/Navbar";

/**
 * AboutUsFoldOne Component
 * 
 * Overview:
 * The AboutUsFoldOne component is responsible for rendering the first fold of the About Us section on the desktop version of the website. It includes a navbar, a text section, and an experience section.
 * 
 * Details:
 * - The component renders a div with the class name "about-us-fold-one".
 * - It includes a Navbar component.
 * - The text section is rendered inside a div with the class name "about-us-fold-one-text-div". It consists of a heading and a paragraph.
 * - The experience section is rendered inside a div with the class name "about-fold1-down-div". It consists of a div with the class name "about-fold1-experience-div" and a div with the class name "about-fold1-down-right-div". The "about-fold1-experience-div" contains a heading, and the "about-fold1-down-right-div" contains another heading.
 * 
 * Usage:
 * To use the AboutUsFoldOne component, import it from the appropriate file and include it in the desired location within the application's JSX code.
 * 
 * Dependencies:
 * - Navbar component: This component should be imported and used within the AboutUsFoldOne component.
 */
export default function AboutUsFoldOne() {
  return (
    <div className="about-us-fold-one">
      <Navbar />
      <div className="about-us-fold-one-text-div">
        <h5>
          Accelerating a startup’s
          growth takes more than capital.
        </h5>
        <p>
          We strive to transform our environment, our community, and the world
          one company at a time. We help early stage high-impact
          companies get the knowledge, networks, and tools they need to
          build communities and innovate. Our startups and their backers will
          break
          down barriers, save time, increase capital, and create a community
          they can count on.
        </p>
      </div>
      <div className="about-fold1-down-div">
        <div className="about-fold1-experience-div">
          <h5>Experience</h5>
        </div>
        <div className="about-fold1-down-right-div">
          <h5>
            {/*The VedasLabs team comprises senior leaders across fintech, legal
            and venture <br /> capital, many of whom have been involved in
            startups which have exited.{" "}*/}
            The VedasLabs team comprises senior leaders across fintech, legal
            and venture
            capital, many of whom have been involved in successful startup
            exits.
          </h5>
        </div>
      </div>
    </div>
  );
}
