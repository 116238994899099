import React from "react";

import SideNavbar from "../header/SideNavbar";
import Footer from "../../../components/desktop/header-footer/Footer";

import { useHistory, useParams } from "react-router";
import { useState, useEffect } from "react";
import { getWebsiteBlogById } from "../../../web-app-store/actions/websiteBlogsAction";
import { useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import DisplayLoader from "../../../web-app-components/desktop/common/DisplayLoader";
// import DOMPurify from "dompurify";
import decode from "decode-html";
// import BlogsCardsDisplayInBlogsDetails from "../../../components/desktop/knowledge-base/BlogsCardsDisplayInBlogsDetails";

/**
 * KnowledgeBaseWebAppBlogDetailComponent
 *
 * Overview:
 * This component is responsible for displaying the details of a blog post in the knowledge base section of the web app. It fetches the blog data from the server and renders it on the page. It also handles navigation and displays a loader while the data is being fetched.
 *
 * Details:
 * - The component uses Redux for state management and dispatches actions to fetch the blog data.
 * - It uses React Router to get the blog ID from the URL parameters.
 * - The component renders a side navbar, the blog content, and a footer.
 * - It dynamically creates embedly cards for any oembed URLs found in the blog content.
 *
 * Usage:
 * To use this component, import it and render it in the desired location in your application. Make sure to provide the necessary Redux store and React Router configuration.
 *
 * Dependencies:
 * - React: The component uses React for building the user interface.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - React Router: The component uses React Router for handling navigation and getting URL parameters.
 * - React Redux: The component uses React Redux to connect the component to the Redux store.
 * - React Router DOM: The component uses React Router DOM for rendering the side navbar and footer.
 * - Redux Thunk: The component uses Redux Thunk middleware for handling asynchronous actions.
 * - Axios: The component uses Axios for making HTTP requests to the server.
 * - DOMPurify: The component uses DOMPurify for sanitizing HTML content.
 * - Other custom components and utility functions used in the component.
 */
export default function KnowledgeBaseWebAppBlogDetailComponent() {
  // api
  const dispatch = useDispatch();

  useEffect(() => {
    document.querySelectorAll("oembed[url]").forEach((element) => {
      // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
      // to discover the media.
      const anchor = document.createElement("a");

      anchor.setAttribute("href", element.getAttribute("url"));
      anchor.className = "embedly-card";

      element.appendChild(anchor);
      // console.log(anchor);
    });
  });

  let blogId = useParams();

  const [blogData, setBlogData] = useState();
  const [endpoint, setEndpoint] = useState("");
  const history = useHistory();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  useEffect(() => {
    if (blogId?.id === "venture-capital-guide-for-startups") {
      setEndpoint(11);
    } else {
      setEndpoint(blogId?.id);
    }
  }, [blogId]);

  useEffect(() => {
    const callBackWebsiteBlogData = (data) => {
      if ("status" in data) {
        if (data.status === 500) {
          history.push("/error/notfound");
        }
      }
      if (data) {
        setBlogData(data);
        if (data.id === 11) {
          history.replace("/venture-capital-guide-for-startups");
        }
        window.scrollTo(0, 0);
      }
    };
    if (endpoint)
      dispatch(getWebsiteBlogById(endpoint, callBackWebsiteBlogData));
  }, [endpoint]);

  let htmlInput = !isEmpty(blogData) && decode(blogData.article_body);

  return (
    <>
      <SideNavbar />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          {/* <PageTitle
            imgPath={knowledgeBasePageTitle.icon}
            pageTitle={knowledgeBasePageTitle.title}
          /> */}

          <article className="vl-blog-div-web-app">
            {!isEmpty(blogData) ? (
              <div>
                <div className="blogtitle-head">
                  <button
                    onClick={() => history.goBack()}
                    className="investing-startups-lines"
                  >
                    <i className="fa fa-chevron-left vl-topnavbar__title-back-arrow"></i>
                  </button>
                  <h2 className="vl-blog-title text-center">
                    {blogData.title}
                  </h2>
                </div>
                <h3 className="vl-blog-author text-center">
                  {blogData.author}
                </h3>
                <div className="text-center">
                  {!isEmpty(blogData.banner_image) && (
                    <img
                      src={blogData.banner_image.url}
                      alt="banner"
                      className="vl-blog-img"
                    />
                  )}
                </div>

                {/* {console.log(htmlInput)} */}
                {!isEmpty(htmlInput) && htmlInput !== false && (
                  <div
                    className="vl-website-blogs-details-content"
                    dangerouslySetInnerHTML={{
                      // __html: DOMPurify.sanitize(htmlInput),
                      __html: htmlInput,
                    }}
                  ></div>
                )}

                {/* <BlogsCardsDisplayInBlogsDetails blogId={blogId} /> */}
              </div>
            ) : (
              <div className="py-5">
                <DisplayLoader />
              </div>
            )}
          </article>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
