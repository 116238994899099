import React, { useEffect, useState } from "react";
import Footer from "../header-footer/Footer";
import Navbar from "../header-footer/Navbar";
import PrivacyPolicyKnowledgeBase from "../privacy-policy/PrivacyPolicyKnowledgeBase";
// import KnowBeforeYouInvestFoldOne from "./KnowBeforeYouInvestFoldOne";
import KnowBeforeYouInvestFoldOneNew from "./KnowBeforeYouInvestFoldOneNew";

/**
 * KnowBeforeYouInvest Component
 * 
 * Overview:
 * The KnowBeforeYouInvest component is responsible for rendering the "Know Before You Invest" page. It displays important information and resources for users to understand before making investments.
 * 
 * Details:
 * - The component uses state and effect hooks to handle scroll behavior and calculate the height of a specific element.
 * - The component structure consists of a main div with a background color, a title block, and a privacy policy knowledge base component.
 * - The component accepts the foldOneHeight prop, which is used to determine the height of a specific fold. It also accepts the displayOnWebApp prop, which controls the visibility of a sub-component.
 * 
 * Usage:
 * To use the KnowBeforeYouInvest component, include it in the desired location within the application. It should be wrapped in a parent component that handles routing and navigation. Ensure that the necessary props are passed to the component for proper functionality.
 * 
 * Dependencies:
 * - React: The component relies on React for its functionality and JSX syntax.
 * - useState: The component uses the useState hook to manage state.
 * - useEffect: The component uses the useEffect hook to handle side effects.
 * - Navbar: The component depends on the Navbar component for rendering the navigation bar.
 * - PrivacyPolicyKnowledgeBase: The component depends on the PrivacyPolicyKnowledgeBase component for rendering the privacy policy knowledge base section.
 * - KnowBeforeYouInvestFoldOneNew: The component depends on the KnowBeforeYouInvestFoldOneNew component for rendering a fold section.
 * - Footer: The component depends on the Footer component for rendering the footer section.
 */
export default function KnowBeforeYouInvest() {
  const [foldOneHeight, setFoldOneHeight] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-kbyi-fold-one-div-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-kbyi-fold-one-div-id").offsetHeight
      );
    }
  }, []);

  return (
    <div className="bg-color">
      <div id="vl-kbyi-fold-one-div-id">
        <div className="know-before-you-invest-main-div">
          <Navbar />
          <div className="know-before-you-invest-title-block">
            <h1>Know Before You Invest</h1>
          </div>
        </div>
        <PrivacyPolicyKnowledgeBase />
      </div>

      {/* <KnowBeforeYouInvestFoldOne /> */}
      <KnowBeforeYouInvestFoldOneNew
        foldOneHeight={foldOneHeight}
        displayOnWebApp={false}
      />
      <Footer />
    </div>
  );
}
