import React from "react";

/**
 * TextareaField Component
 *
 * Overview:
 * The TextareaField component is used to render a textarea input field with an optional label and error message. It provides a way for users to input multi-line text.
 *
 * Details:
 * - The component accepts various props to customize its behavior and appearance, such as label, placeholder, onChange, value, maxLength, autoFocus, isDisabled, isReadOnly, isHideLabel, isHideAbsoluteLabel, and error.
 * - The label can be displayed outside of the textarea or on top of it, depending on the values of isHideLabel and isHideAbsoluteLabel props.
 * - The component applies different styles based on the values of isDisabled and isReadOnly props.
 * - The value of the textarea can be controlled by the value prop, and any changes are propagated through the onChange prop.
 * - The component supports displaying an error message below the textarea when the error prop is provided.
 *
 * Dependencies:
 * - React: The component is built using React and requires it to be installed in the project.
 * - CSS styles: The component relies on CSS styles to define its appearance. These styles should be imported or provided separately.
 */
function TextareaField({
  labelClassName,
  containerClassName,
  label,
  type,
  id,
  name,
  placeholder,
  onChange,
  value,
  maxLength,
  autoFocus,
  isDisabled,
  isReadOnly,
  isHideLabel,
  isHideAbsoluteLabel,
  error,
  errorClass,
}) {
  return (
    <>
      {/* display label outside of textarea */}
      {isHideLabel ? (
        ""
      ) : (
        <label htmlFor={id ? id : name} className={`${labelClassName}`}>
          {label}
        </label>
      )}
      <div className={containerClassName}>
        {/* display label on textarea */}
        {isHideAbsoluteLabel ? (
          ""
        ) : (
          <label htmlFor={id ? id : name}>{label}</label>
        )}
        <div
          className={
            isDisabled || isReadOnly
              ? "input-border-div input-border-div--disabled"
              : "input-border-div"
          }
        >
          <textarea
            type="text"
            id={id ? id : name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value === null ? "" : value}
            maxLength={maxLength}
            autoComplete="off"
            autoFocus={autoFocus}
            disabled={isDisabled}
            readOnly={isReadOnly}
          />
        </div>
        {error && (
          <p className={`error-message ${errorClass || ""}`}>{error}</p>
        )}
      </div>
    </>
  );
}

TextareaField.defaultProps = {
  labelClassName: "",
  placeholder: "",
  maxLength: "",
  error: "",
  autoFocus: "",
  isDisabled: false,
  isReadOnly: false,
  isHideLabel: false,
  isHideAbsoluteLabel: true,
};

export default TextareaField;
