import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import linkedin from "../../../assets/imgs/desktop/common/linkedin.svg";
import "./styles.scss";
import { Image } from "react-bootstrap";
import isEmpty from "../../../web-app-store/validations/is-empty";
import MentorProfileInputContainer from "../../../components/desktop/mentor/mentor-profile/MentorProfileInput/MentorProfileInputContainer";

const MentorProfileReview = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [mentorData, setMentorData] = useState({});
  const { mentor } = useSelector((state) => state.mentor);
  useEffect(() => {
    if (!isEmpty(mentor)) {
      setMentorData(mentor[0]);
    }
  }, [mentor]);
  return !isEmpty(mentorData) ? (
    <div>
      <div className="d-flex align-items-center gap-2 mentor_profile_personal_details">
        <h5 className="mentor_details_title">Mentor Details</h5>
        <button
          onClick={() => setIsEdit(true)}
          className="vl-profile-page-button"
        >
          <i className="fa fa-pencil pencil-icon fs-6"></i>
        </button>
      </div>
      {isEdit ? (
        <MentorProfileInputContainer {...{ isEdit, setIsEdit, mentor }} />
      ) : (
        <div className="Interested-profile-details-main">
          <div className="Interested-profession-details">
            <div className="profession-sub-title-main">
              <div className="">
                <div className="row profession-sub-title-wrap mb-3">
                  <div className="col-12 col-md-6">
                    <h5 className="profession-sub-title">Mentor Bio</h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="profession-content">
                      {!isEmpty(mentor) && mentor[0]?.bio}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row profession-sub-title-wrap mb-3">
                  <div className="col-12 col-md-6">
                    <h5 className="profession-sub-title">Job Title</h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="profession-content">
                      {!isEmpty(mentor) && mentor[0]?.job_title}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row profession-sub-title-wrap mb-3">
                  <div className="col-12 col-md-6">
                    <h5 className="profession-sub-title">Company Name</h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="profession-content">
                      {!isEmpty(mentor) && mentor[0]?.company}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row profession-sub-title-wrap mb-4">
                  <div className="col-12 col-md-6">
                    <h5 className="profession-sub-title">
                      Linkedin Profile{" "}
                      {/* <a
                        className="link-action"
                        rel="noreferrer"
                        href={`${!isEmpty(mentor) && mentor[0]?.linkedin_link}`}
                        target={"_blank"}
                      >
                        <img src={linkedin} alt="linkedin" />
                      </a> */}
                    </h5>
                  </div>
                  <div className="col-12 col-md-6">
                    <p className="profession-content">
                      {!isEmpty(mentor) && mentor[0]?.linkedin_link}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="expertise-areas-main">
            <h5 className="expertise-areas-title">Areas of Expertise</h5>
            <div className="expertise-areas-sub-title-main">
              {!isEmpty(mentor) &&
                mentor[0]?.sectors_of_interest?.map((ele) => {
                  return (
                    <div
                      key={ele?.areaOfExpertise}
                      className="expertise-areas-sub-title-wrap"
                    >
                      <div className="expertise_image_wrap">
                        <div className="upload_image_wrap">
                          {/* <Image
                            src={ele?.area_image?.url}
                            alt={ele?.areaOfExpertise}
                            className="upload_expertise_img"
                          /> */}
                          <p className="expertise-areas-sub-title">
                            {ele?.areaOfExpertise}
                          </p>
                        </div>
                        <p className="expertise-areas-content">
                          {ele?.yearsOfExpertise}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
};
export default MentorProfileReview;
