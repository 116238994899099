import React, { useState } from "react";
// import {
//   //CarouselProvider,
//   //Slider,
//   //Slide,
//   ButtonBack,
//   ButtonNext,
// } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import arrow from "../../../assets/imgs/desktop/icon/arrow.png";
// import img1 from "../../../assets/imgs/desktop/raise-capital/p1.png";
// import { Link } from "react-router-dom";

import { mentor, newLoginSignUpLink } from "../header-footer/pageLinks";
import Modal from "react-responsive-modal";
import InputFieldEmailTextPassword from "../../../web-app-components/desktop/common/InputFieldEmailTextPassword";
import { validateMentorRecord } from "../../../web-app-store/validations/mentor-record/mentor-record";
import { saveMentorRecord } from "../../../web-app-store/actions/raiseCapitalInvestorAction";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

const dueData = [
  {
    text: "Initial Screening",
    desc: "All funding applications are carefully screened and evaluated to determine if they will move to the next stage. Only a very small percentage of companies will make it past the initial screening. For the ones that do they then move into business due diligence and then legal due diligence.",
    mainClass: "invest-due-process-div",
  },
  {
    text: "Business Due Dilligence",
    desc: "This is really a business intelligence gathering exercise. Here we look at the company’s team, mission, vision, team, core values, value proposition, core problem they solve, market landscape, traction, financial performance and projections. ",
    mainClass: "invest-due-process-div-2",
  },
  {
    text: "Legal Due Dilligence",
    desc: "This involves verifying important items such as legal structure of the organization, proof of intellectual property rights, capitalization structure, background checks on officers, physical location of the business, and major customers. ",
    mainClass: "invest-due-process-div-3",
  },
];

/**
 * RaiseCapitalFoldThree Component
 * 
 * Overview:
 * The RaiseCapitalFoldThree component is responsible for rendering a section of the application's UI related to the process of raising capital. It displays information about the VedasLabs Due Diligence Process and allows users to apply to be a mentor.
 * 
 * Details:
 * - The component uses state to manage form data for mentor applications, including first name, last name, email, and LinkedIn link.
 * - It utilizes the useDispatch and useHistory hooks from the react-redux and react-router-dom libraries, respectively.
 * - The component includes a modal for submitting mentor applications, which opens when the user clicks on the "Apply to be a Mentor" button.
 * - The modal includes form fields for entering the required information and a "Save" button to submit the application.
 * - The component also handles form validation and displays any validation errors.
 * - Upon successful submission of the mentor application, a success message is displayed.
 * 
 * Dependencies:
 * - react: The component uses React to define and render the UI elements.
 * - react-redux: The component uses the useDispatch hook from react-redux to dispatch actions to the Redux store.
 * - react-router-dom: The component uses the useHistory hook from react-router-dom to navigate to different routes.
 * - Modal: The component uses the Modal component from an external library to display the mentor application form.
 * - InputFieldEmailTextPassword: The component uses the InputFieldEmailTextPassword component to render form input fields.
 * - validateMentorRecord: The component uses the validateMentorRecord function to validate the mentor application form data.
 * - saveMentorRecord: The component uses the saveMentorRecord function to save the mentor application data.
 */
export default function RaiseCapitalFoldThree() {
  // const renderArrow = () => {
  //   return (
  //     <>
  //       <div className="prev-arrow-div">
  //         <ButtonBack className="next-arrow">&#60;</ButtonBack>
  //       </div>
  //       <div className="next-arrow-div">
  //         <ButtonNext className="next-arrow">&#62;</ButtonNext>
  //       </div>
  //     </>
  //   );
  // };
  // const renderBlockOne = () => {
  //   return (
  //     <div className="raise-slider-div">
  //       <div className="row mx-0 align-items-center justify-content-center pt-26">
  //         <div className="raise-slider-img-div">
  //           <img src={img1} alt="raise capital" className="raise-slider-img" />
  //         </div>
  //         <div>
  //           <h5 className="font-35-montserrat-bold">Name Last Name</h5>
  //           <p className="font-22-montserrat-regular raise-slider-para">
  //             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel
  //             sapien et odio scelerisque aliquam eu malesuada tellus. Lorem
  //             ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel
  //             sapien et odio scelerisque aliquam eu malesuada tellus.{" "}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const dispatch = useDispatch();
  const history = useHistory();
  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    linkedin_link: "",
  };
  const [mentorData, setMentorData] = useState(initialState);
  const [show, setShow] = useState(false);
  const [frontEndErrors, setFrontEndErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const openModal = () => {
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const callBack = (status) => {
    if (status === 200) {
      setShowSuccess(true);
      setMentorData({
        first_name: "",
        last_name: "",
        email: "",
        linkedin_link: "",
      });
    }
  };

  const handleClickSave = () => {
    const { errors, isValid } = validateMentorRecord(mentorData);
    if (!isValid) {
      setFrontEndErrors(errors);
    } else {
      setFrontEndErrors({});
      dispatch(saveMentorRecord(mentorData, callBack));
    }
  };

  const handleOnChange = (e) => {
    setFrontEndErrors({});
    const { name, value } = e.target;
    setMentorData({ ...mentorData, [name]: value });
  };

  return (
    <>
      <div className="maincontainer-custom">
        <div className="raise-capital-fold-three-div">
          <h5 className="font-50-montserrat-bold threediv-title">
            VedasLabs Due Diligence Process
          </h5>
          <div className="row">
            {dueData.map((data, key) => (
              <div className="col-lg-4 col-md-12 col-sm-12">
                {/* <div className={data.mainClass} key={key}> */}
                <div key={key} className="diligence-process">
                  <h3>{data.text}</h3>
                  <div>
                    <img src={arrow} alt="arrow" />
                  </div>
                  <h5>{data.desc}</h5>
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*<div className="vl-raise-capital-carousel">
          <h4 className="text-center font-45-montserrat-bold pl-80">
          Gain Access to Vetted Mentors
        </h4>
        <h5 className="text-center font-35-montserrat-regular pl-80">
          Meet our Mentors
        </h5>

        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={22}
          totalSlides={3}
          interval={4000}
          isPlaying={true}
          step={1}
          infinite={true}
          currentSlide={0}
          visibleSlides={1}
          playDirection={"forward"}
        >
          <Slider>
            <Slide index={0}>{renderBlockOne()}</Slide>
            <Slide index={1}>{renderBlockOne()}</Slide>
            <Slide index={2}>{renderBlockOne()}</Slide>
          </Slider>
          {renderArrow()}
        </CarouselProvider>
       </div>*/}
      </div>
      <div className="footer-row-div--raise-capital">
        <Link to={mentor}>
          <div className="footer-knowledge-div footer-knowledge-div--raise-capital">
            <h5 className="font-35-montserrat-bold">
              Apply to be a Mentor &#62;{" "}
            </h5>
          </div>
        </Link>
        {/* <button
          onClick={openModal}
          className="footer-knowledge-div footer-knowledge-div--raise-capita"
        >
          <div>
            <h5 className="font-35-montserrat-bold">
              Apply to be a Mentor &#62;{" "}
            </h5>
          </div>
        </button> */}
        <div className="footer-top-right-div footer-top-right-div--raise-capital ">
          <h5 className="font-25-montserrat-regular">
            Share your knowledge to empower the next generation of founders.
          </h5>
        </div>
      </div>
      <Modal
        open={show}
        onClose={() => {
          onClose();
          setShowSuccess(false);
          setMentorData(initialState);
          setFrontEndErrors({});
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: showSuccess
            ? "customModal reserveModal--reserve-success"
            : "customModal reserveModal--profile-review reservemodal-popup",
          // closeButton: "customCloseButton",
        }}
      >
        {!showSuccess ? (
          <div className="reserveContainer">
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="First Name"
              name="first_name"
              type="text"
              value={mentorData.first_name}
              onChange={handleOnChange}
              error={frontEndErrors.first_name}
            />

            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Last Name"
              name="last_name"
              type="text"
              value={mentorData.last_name}
              onChange={handleOnChange}
              error={frontEndErrors.last_name}
            />

            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="LinkedIn"
              name="linkedin_link"
              type="text"
              value={mentorData.linkedin_link}
              onChange={handleOnChange}
              error={frontEndErrors.linkedin_link}
            />

            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Email"
              name="email"
              type="email"
              value={mentorData.email}
              onChange={handleOnChange}
              error={frontEndErrors.email}
            />

            <div className="text-center">
              <button
                className="newsletter-submit-btn  newsletter-submit-btn--thank-you"
                onClick={handleClickSave}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <div class="d-flex flex-column align-items-center">
            <img
              className="m-auto img-Sucess"
              width={100}
              src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png"
              alt=""
            />
            <h1 className="title-Sucess">Sucess</h1>
            <p className="text-center text-Sucess">
              Thank you for expressing interest in this offering. When the deal
              goes live we will let you know!
            </p>
          </div>
        )}
      </Modal>
    </>
  );
}
