import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateRaiseCapitalTeam Function
 *
 * Overview:
 * This function is used to validate the raise capital team data. It checks if the member name and email are provided and if the email is valid.
 *
 * Details:
 * - The function takes an array of data as input.
 * - It iterates over each element in the array and checks if the member name and email are empty.
 * - If the member name is empty, it adds an error message to the error object.
 * - If the email is empty, it adds an error message to the error object.
 * - If the email is not empty, it checks if it is a valid email using the Validator.isEmail() function.
 * - The function returns an object with an array of error objects and a boolean value indicating if the data is valid.
 *
 * Dependencies:
 * - The function depends on the Validator object, which provides the isEmpty() and isEmail() functions for validation.
 * - The Validator object is assumed to be available in the current scope.
 */
export const validateRaiseCapitalTeam = (data) => {
  const dataArray = data;
  const errorArray = [];
  let isValid = true;
  dataArray.forEach((element, index) => {
    let errorObj = {};
    if (Validator.isEmpty(element.memberName)) {
      errorObj.memberName = "Member name is required";
    }
    // select lead
    if (Validator.isEmpty(element.email)) {
      errorObj.email = "Email is required";
    }
    if (isEmpty(element?.imgUrl)) {
      errorObj.imgUrl = "Image is required";
    }
    if (Validator.isEmpty(element.email)) {
      errorObj.email = "Email is required";
    }
    // select lead
    if (!isEmpty(element.email) && !Validator.isEmail(element.email)) {
      errorObj.email = "Please enter valid email";
    }
    errorArray[index] = errorObj;
    if (!isEmpty(errorObj)) {
      isValid = false;
    }
  });

  // let errors = {};

  // //   console.log("validatedata", data);

  // if (isEmpty(data.memberName)) {
  //   errors.memberName = "Member name is required";
  // }

  // if (isEmpty(data.email)) {
  //   errors.email = "Email is required";
  // }

  // if (!isEmpty(data.email) && !Validator.isEmail(data.email)) {
  //   errors.email = "Please enter valid email";
  // }

  return {
    errors: errorArray,
    isValid: isValid,
  };
};
