import axios from "axios";

/**
 * setAuthToken Function
 * 
 * Overview:
 * This function sets the authorization token for making authenticated requests. It adds the token to the headers of every request made using axios.
 * 
 * Details:
 * - If the id_token parameter is provided, the function sets the Authorization header with the token.
 * - If the id_token parameter is not provided, the function deletes the Authorization header from the headers.
 * 
 * Usage:
 * To set the authorization token, call the setAuthToken function and pass the id_token as a parameter.
 * 
 * Dependencies:
 * This function depends on the axios library for making HTTP requests.
 * 
 * @param {string} id_token - The authorization token to be set.
 */
const setAuthToken = (id_token) => {
  if (id_token) {
    //apply to every request
    axios.defaults.headers.common = { Authorization: `Bearer ${id_token}` };
  } else {
    //Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
