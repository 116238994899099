import React, { useState } from "react";
import { Link } from "react-router-dom";

// api
import { useEffect } from "react";
import { getExploreTagsById } from "../../../web-app-store/actions/exploreAction";
import { getInvestorPortfolioOfferingsById } from "../../../web-app-store/actions/investorPortfolioAction";
import { useDispatch } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import InvestCardTextContent from "./InvestCardTextContent";
import {
  startUpDetailsWebAppLink,
  investPaymentWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";
import { calcDaysRemaining } from "../common/calcDaysRemainingFunction";

/**
 * InvestCard Component
 * 
 * Overview:
 * The InvestCard component is responsible for rendering a card that displays investment information. It is used in the investment section of the application to show details about a specific investment.
 * 
 * Details:
 * - The component fetches data from the API using Redux dispatch and useEffect hooks.
 * - It manages state using the useState hook to store tags and offering data.
 * - The component calculates the number of days remaining for the investment using the calcDaysRemaining function.
 * - The component renders different elements based on the status and data availability.
 * - The component accepts props including offeringId, status, amountArray, and backgroundColor.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management and dispatching actions.
 * - useDispatch: The component uses the useDispatch hook from Redux to dispatch actions.
 * - useState: The component uses the useState hook from React to manage state.
 * - useEffect: The component uses the useEffect hook from React to handle side effects.
 * - getInvestorPortfolioOfferingsById: The component uses the getInvestorPortfolioOfferingsById action from Redux to fetch offering data.
 * - getExploreTagsById: The component uses the getExploreTagsById action from Redux to fetch tags data.
 * - calcDaysRemaining: The component uses the calcDaysRemaining function to calculate the number of days remaining for the investment.
 */
export default function InvestCard({
  offeringId,
  status,
  amountArray,
  backgroundColor,
}) {
  // api
  const dispatch = useDispatch();

  const [tagsById, setTagsById] = useState();

  const [offeringDataById, setOfferingDataById] = useState();
  const [orgData, setOrgData] = useState();

  useEffect(() => {
    const callBackOfferingData = (data) => {
      setOfferingDataById(data[0]);
      setOrgData(data[0].organization);
    };
    dispatch(
      getInvestorPortfolioOfferingsById(offeringId, callBackOfferingData)
    );
  }, [dispatch, offeringId]);

  useEffect(() => {
    const callBackTagsData = (data) => {
      setTagsById(data);
    };
    if (!isEmpty(orgData)) {
      dispatch(getExploreTagsById(orgData.id, callBackTagsData));
    }
  }, [dispatch, orgData]);

  let daysRemaining =
    !isEmpty(offeringDataById) &&
    calcDaysRemaining(
      offeringDataById.fund_start_date,
      offeringDataById.fund_end_date
    );
  const parseTags = (tags) => {
    try {
      return tags.split(",");
    } catch (err) {
      return [];
    }
  };
  return (
    <>
      {!isEmpty(offeringDataById) && !isEmpty(orgData) && (
        <div className="invest-card">
          <div
            className="explore-card-invest-top-div"
            style={{ background: backgroundColor }}
          >
            <div className="row mx-0 justify-content-center align-items-center">
              <span className="explore-card-invest-top-div-text-1">
                {/* Invested */}
                {status === "Pending" ? "Pending Settlement" : status}
              </span>
              <span className="explore-card-invest-top-div-text-2">
                {/* $2,300 */}
                <DisplayCurrencyFormatString value={amountArray} />
              </span>
            </div>
          </div>
          <div className="explore-card">
            {!isEmpty(offeringDataById.offering_image) ? (
              <div className="explore-card-bg-img">
                <img src={offeringDataById.offering_image.url} alt="" />
              </div>
            ) : (
              <div className="explore-card-bg-img">
                <img src={orgData.company_logo.url} alt="" />
              </div>
            )}

            <div className="explore-card-text-div">
              <div className="row mx-0 explore-card-btn-div explore-card-btn-div--main-card align-items-start">
                {!isEmpty(tagsById) &&
                  parseTags(tagsById[0].market_tags).map(
                    (data, index) =>
                      index < 3 && (
                        <div key={index} className="tag-btn">
                          {data}
                        </div>
                      )
                  )}
              </div>
              <InvestCardTextContent offeringDataById={offeringDataById} />
              <div className="row align-items-center justify-content-between flex-nowrap mx-0 pt-36 explore-card-btns">
                <Link
                  to={{
                    pathname: `${startUpDetailsWebAppLink}/${offeringDataById.id}`,
                    state: { activeSidebarMenuCount: 2 },
                  }}
                >
                  <div className="explore-card-view-details-div ml-0 investbtn-card">
                    <span className="explore-card-view-details-div__text">
                      View details
                    </span>
                  </div>
                </Link>

                {daysRemaining >= 0 ? (
                  <Link
                    to={{
                      pathname: investPaymentWebAppLink,
                      state: {
                        detail: orgData,
                        offeringId: offeringDataById.offering_id,
                        invest_now_url: offeringDataById.invest_now_url,
                        min_investment: offeringDataById.min_investment,
                      },
                    }}
                  >
                    <div className="explore-card-view-details-div-invest investbtn-card">
                      <span className="explore-card-view-details-div__text-invest">
                        Invest Again
                      </span>
                    </div>
                  </Link>
                ) : (
                  <div className="explore-card-view-details-div-invest explore-card-view-details-div-invest--disabled">
                    <span className="explore-card-view-details-div__text-invest">
                      Invest Again
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
