import React from "react";
import DisplayLoader from "./../common/DisplayLoader";

/**
 * SaveAndNextButton Component
 * 
 * Overview:
 * The SaveAndNextButton component is a button component that allows users to save their progress and move to the next step. It is commonly used in forms or multi-step processes.
 * 
 * Details:
 * - The component accepts the following props:
 *   - extraClassName: A string representing additional CSS classes to be applied to the button.
 *   - handleOnClickSaveAndNext: A function that is called when the button is clicked. It should handle the save and next logic.
 *   - isDisabled: A boolean indicating whether the button should be disabled.
 *   - loading: A boolean indicating whether the button is in a loading state.
 * - The button's appearance and behavior depend on the values of the isDisabled and loading props. When loading is true, a loader is displayed instead of the button content.
 * - The button includes an optional arrow icon that indicates the next step.
 *  */
export default function SaveAndNextButton({
  extraClassName,
  handleOnClickSaveAndNext,
  isDisabled,
  loading,
}) {
  return (
    <>
      <button
        className={`vl-common-save-next-btn ${extraClassName}`}
        onClick={handleOnClickSaveAndNext}
        disabled={isDisabled}
      >
        {loading === true ? (
          <DisplayLoader loaderColor="#ffffff" />
        ) : (
          <>
            <span>Save &amp; Next</span>
            <img
              src="/web-app-img/desktop/icons/next-arrow.png"
              alt="next arrow"
              className="vl-common-save-next-btn__arrow-img"
            />
          </>
        )}
      </button>
    </>
  );
}

SaveAndNextButton.defaultProps = {
  extraClassName: "",
  loading: false,
};
