import React, { useEffect } from "react";
import Footer from "../header-footer/Footer";
import InvestFoldOne from "./InvestFoldOne";
import InvestFoldTwo from "./InvestFoldTwo";
import InvestFoldThree from "./InvestFoldThree";

export default function Invest() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-color">
        <InvestFoldOne />
        <InvestFoldTwo />
        <InvestFoldThree />
      </div>
      <Footer />
    </>
  );
}
