import React, { useState, useEffect } from "react";
import Modal from "react-responsive-modal";
import InputFieldEmailTextPassword from "./InputFieldEmailTextPassword";
import CurrencyInputCommon from "./CurrencyInputCommon";
import { useDispatch } from "react-redux";
import CustomRadioButton from "../common/CustomRadioButton";
import { VLReserveAmountAction } from "../../../web-app-store/actions/exploreAction";
import { Link } from "react-router-dom";

/**
 * VLReserveModal Component
 * 
 * Overview:
 * The VLReserveModal component is responsible for rendering a modal window that allows users to reserve an offering. It is used in the UI-2 application and is part of the web-app-components module. The component handles form input, validation, and submission logic for reserving an offering.
 * 
 * Details:
 * - The component uses the useState hook to manage its internal state, including the form input values, validation errors, and success state.
 * - It uses the useDispatch hook from the react-redux library to dispatch actions to the Redux store.
 * - The component renders a modal window using the Modal component from the react-responsive-modal library.
 * - The modal window contains input fields for the user to enter their first name, last name, email address, and the amount they want to reserve.
 * - It also includes a radio button group to indicate if the user is an accredited investor.
 * - The component performs form validation to ensure that the entered values are valid.
 * - When the user clicks the "Proceed" button, the component checks for validation errors. If there are no errors, it dispatches an action to reserve the offering. If the user is an accredited investor, it opens a new window with the invest_now_url.
 * - The component provides a callback function to handle the response from the reservation API. If the reservation is successful, it shows a success message and refreshes the data. If there is an error, it displays an error message and refreshes the data.
 * 
 * Dependencies:
 * - react
 * - react-redux
 * - react-responsive-modal
 */
export default function VLReserveModal({
  open,
  onClose,
  offeringId,
  refreshData,
  maxAmount,
  invest_now_url,
}) {
  const initialState = {
    offering_Id: String(offeringId),
    first_name: "",
    last_name: "",
    email: "",
    amount: "",
  };
  const [vlreserve, setReserve] = useState(initialState);
  const [reserveErrors, setReserveErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [specialResurve, setSpecialResurve] = useState("");
  const dispatch = useDispatch();

  const reservefun = () => {
    let errors = checkErrors();
    if (Object.keys(errors).length > 0) {
      setReserveErrors(errors);
    } else {
      setReserveErrors({});
      // alert(JSON.stringify(reserve));
      if (specialResurve == "yes") {
        dispatch(VLReserveAmountAction(vlreserve, callBack));
      } else {
        setShowSuccess(true);
        setSpecialResurve("");
      }
    }
  };
  const callBack = (status, errAmount) => {
    if (status === 200) {
      if (specialResurve == "yes") {
        //window.location.href=" https://api-sandboxdash.norcapsecurities.com/admin_v3/dabv3/?dabId=NjM4Mzg3Mw==";
        // window.open('https://api.norcapsecurities.com/admin_v3/dabv3/?dabId=NjQ2NTUxOQ==', '_blank');
        console.log(invest_now_url);
        window.open(invest_now_url, "_blank");
        window.location.reload();
      } else {
        setShowSuccess(true);
        refreshData();
      }
    } else {
      refreshData();
      let errors = {};
      errors.amount = `Remaining amount is ${errAmount}$ to achive goal.`;
      setReserveErrors(errors);
    }
  };
  const checkErrors = () => {
    const tempReserve = { ...vlreserve };
    let errors = {};
    // if (reserve.amount > maxAmount)
    //   errors.amount = `Remaining amount is ${maxAmount}$ to achive goal.`;
    if (Number(vlreserve.amount) < 1000)
      errors.amount = "The amount must at least meet the minimum of $1000.";
    if (!ValidateEmail(vlreserve.email))
      errors.email = "valid email is required.";
    Object.keys(tempReserve).map((name) => {
      if (tempReserve[name] == "" || tempReserve[name] == null)
        errors[name] = `${name.split("_").join(" ")} is required.`;
    });
    return errors;
  };

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };
  const onChange = (event) => {
    setReserveErrors({});
    setReserve({
      ...vlreserve,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
          setShowSuccess(false);
          setReserve(initialState);
          setReserveErrors({});
        }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        center
        classNames={{
          overlay: "customOverlay",
          modal: showSuccess
            ? "customModal reserveModal--reserve-success"
            : "customModal reserveModal--profile-review reservemodal-popup",
          // closeButton: "customCloseButton",
        }}
      >
        {!showSuccess ? (
          <div className="reserveContainer">
            <h5 className="text-center mb-5">
              Please fill the information below. Note this offering is only open
              to accredited investors which will be verified by North Capital
              Private Securities before payment is accepted.
            </h5>
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="First Name"
              name="first_name"
              value={vlreserve.first_name}
              onChange={onChange}
              type="text"
              error={reserveErrors.first_name}
            />
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Last Name"
              name="last_name"
              // value=""
              value={vlreserve.last_name}
              onChange={onChange}
              type="text"
              error={reserveErrors.last_name}
            />
            <InputFieldEmailTextPassword
              containerClassName="vl-form-input"
              label="Email Address"
              name="email"
              value={vlreserve.email}
              onChange={onChange}
              type="email"
              error={reserveErrors.email}
            />
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Amount"
              name="amount"
              value={vlreserve.amount}
              onChange={(value) =>
                onChange({
                  target: { name: "amount", value: value.value },
                })
              }
              error={reserveErrors.amount}
            />

            <div className="special_reserve mb-4">
              <h6 className="text-center">Are you accredited investor?</h6>
              <div className="d-flex gap-2 justify-content-center">
                <div className="form-check mr-3">
                  <input
                    onChange={(e) => {
                      setSpecialResurve(e.target.value);
                    }}
                    type="radio"
                    value="yes"
                    name="special_resurve"
                    className="form-check-input"
                    checked={specialResurve == "yes" ? true : false}
                  />
                  <label class="form-check-label" form="yes">
                    Yes
                  </label>
                </div>
                <div className="form-check mr-3">
                  <input
                    onChange={(e) => {
                      setSpecialResurve(e.target.value);
                    }}
                    type="radio"
                    value="no"
                    name="special_resurve"
                    className="form-check-input"
                    checked={specialResurve == "no" ? true : false}
                  />
                  <label class="form-check-label" form="no">
                    No
                  </label>
                </div>
                <div className="form-check mr-3">
                  <input
                    onChange={(e) => {
                      setSpecialResurve(e.target.value);
                    }}
                    type="radio"
                    value="no_sure"
                    name="special_resurve"
                    className="form-check-input"
                    checked={specialResurve == "no_sure" ? true : false}
                  />
                  <label class="form-check-label" form="no">
                    Not Sure
                  </label>
                </div>
              </div>
            </div>

            {/* <div className="text-center form-textlist">
              <span>
                Reserving gives you a priority allocation if the deal launches.
                A “reservation” involves no obligation or commitment to invest
                by you or the company.
              </span>
            </div> */}
            <div className="text-center mt-3">
              <button
                className="raise-capital-startup-btn"
                onClick={reservefun}
              >
                <span>Proceed</span>
              </button>
            </div>
          </div>
        ) : specialResurve == "yes" ? (
          <div class="d-flex flex-column align-items-center">
            <img
              className="m-auto img-Sucess"
              width={100}
              src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png"
              alt=""
            />
            <h1 className="title-Sucess">Sucess</h1>
            <p className="text-center text-Sucess">
              Thank you for expressing interest in this offering. When the deal
              goes live we will let you know!
            </p>
          </div>
        ) : (
          <div class="d-flex flex-column special_reserves_flow">
            <h6 className="title-Sucess">
              This offering is only open to accredited investors.
            </h6>
            <p className="text-Sucess">
              An accredited investor, as a natural person, includes anyone who:
            </p>
            <ul>
              <li>
                Earned income that exceeded $200,000 (or joint income exceeds
                $300,000) in each of the prior two years and reasonably expects
                the same for the current year, OR
              </li>
              <li>
                Has a net worth over $1 million, either alone or together with a
                spouse or spousal equivalent (excluding the value of the
                person’s primary residence), OR
              </li>
              <li>Holds in good standing a Series 7, 65, or 82 license.</li>
            </ul>
            <p className="qualifies">
              <a href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3">
                Here
              </a>
              , you can learn more about what qualifies an accredited investor,
              including certain entities.
            </p>
            <p className="qualifies">
              If you have any questions, please reach out to{" "}
              <a href="mailto:team@vedaslabs.io">team@vedaslabs.io</a>
            </p>
          </div>
        )}
      </Modal>
    </>
  );
}
