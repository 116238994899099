// import Validator from "validator";
import isEmpty from "../is-empty";

/**
 * validateFundraiser Function
 * 
 * Overview:
 * This function is used to validate the data for a fundraiser. It checks if the required fields are present and performs additional validation for certain fields.
 * 
 * Details:
 * - The function takes in a data object as a parameter.
 * - It initializes an empty errors object to store any validation errors.
 * - It checks if the fundingGoal field is empty and adds an error message to the errors object if it is.
 * - It checks if the minimumInvestment field is empty and adds an error message to the errors object if it is.
 * - It calculates the difference between the minimumInvestment and fundingGoal fields and adds an error message to the errors object if the difference is negative.
 * - It checks if the valuationCap field is empty and adds an error message to the errors object if it is.
 * - It checks if the startDate field is null and adds an error message to the errors object if it is.
 * - It checks if the useofFunds field is empty and adds an error message to the errors object if it is.
 * - It checks if the market_landscape field is empty and adds an error message to the errors object if it is.
 * - It checks if the current_traction field is empty and adds an error message to the errors object if it is.
 * - It checks if the revenue_model field is empty and adds an error message to the errors object if it is.
 * - It checks if the investmentStage field is empty and adds an error message to the errors object if it is.
 * - Finally, it returns an object containing the errors and a boolean indicating if the data is valid (i.e., if the errors object is empty).
 * 
 * @param {Object} data - The data object to be validated.
 * @returns {Object} - An object containing the errors and a boolean indicating if the data is valid.
 */
export const validateFundraiser = (data) => {
  let errors = {};

  // console.log(data);

  if (isEmpty(data.fundingGoal)) {
    errors.fundingGoal = "Funding goal is required";
  }

  // if (isEmpty(data.minimumInvestment)) {
  //   errors.minimumInvestment = "Maximum funding goal is required";
  // }

  // console.log(data.minimumInvestment - data.fundingGoal);
  // if (data.minimumInvestment - data.fundingGoal < 0) {
  //   errors.minimumInvestment =
  //     "Maximum funding goal must be greater than or equal to funding goal";
  // }

  if (isEmpty(data.valuationCap)) {
    errors.valuationCap = "Valuation Cap is required";
  }

  if (data.startDate === null) {
    errors.startDate = "Funding start date is required";
  }

  if (isEmpty(data.valuationCap)) {
    errors.valuationCap = "Valuation Cap is required";
  }

  if (isEmpty(data.useofFunds)) {
    errors.useofFunds = "Use of funds is required";
  }

  // if (isEmpty(data.market_landscape)) {
  //   errors.market_landscape = "Market landscape is required";
  // }

  if (isEmpty(data.current_traction)) {
    errors.current_traction = "Current traction is required";
  }

  if (isEmpty(data.revenue_model)) {
    errors.revenue_model = "Revenue model is required";
  }

  if (isEmpty(data.investmentStage)) {
    errors.investmentStage = "Investment stage is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
};
