import React from "react";
import { Link } from "react-router-dom";
import SideNavbar from "../header/SideNavbar";
import Footer from "../../../components/desktop/header-footer/Footer";
import PageTitle from "../header/PageTitle";
import { activeSidebarMenu, homePageTitle } from "../header/activeMenu";
import NewHomeCard from "./NewHomeCard";
import { knowledgeBaseWebAppLinkBlogDetail } from "../../../components/desktop/header-footer/pageLinks";

// api
import { useEffect } from "react";
import { getHomeAllArticles } from "../../../web-app-store/actions/homeAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

// const dummyData = [1, 2, 3];

/**
 * NewHome Component
 * 
 * Overview:
 * The NewHome component is responsible for rendering the home page of the application. It displays recent blog posts and information about what's going on in the tech space.
 * 
 * Details:
 * - The component uses Redux for state management and makes API calls to fetch data.
 * - It renders a side navigation bar, a page title, and two main sections: recent blog posts and tech space information.
 * - The recent blog posts section displays a list of blog cards, each containing a title, a truncated description, and a "Read more" link.
 * - The tech space information section displays a card component.
 * 
 * Usage:
 * To use the NewHome component, import it and include it in the desired location of the application's UI. Make sure to provide the necessary Redux store and dispatch actions.
 * 
 * Dependencies:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses the react-redux library to connect to the Redux store.
 * - react-router-dom: The component uses react-router-dom to handle navigation.
 * - DOMParser: The component uses the DOMParser API to parse HTML strings.
 * - isEmpty: The component uses the isEmpty function to check if an object or array is empty.
 * - useDispatch: The component uses the useDispatch hook from react-redux to dispatch actions.
 * - useSelector: The component uses the useSelector hook from react-redux to access the Redux store.
 * - getHomeAllArticles: The component uses the getHomeAllArticles action to fetch the list of home articles.
 * - knowledgeBaseWebAppLinkBlogDetail: The component uses this constant to construct the URL for blog detail pages.
 * - SideNavbar: The component uses the SideNavbar component to render the side navigation bar.
 * - PageTitle: The component uses the PageTitle component to render the page title.
 * - NewHomeCard: The component uses the NewHomeCard component to render the tech space information card.
 * - Footer: The component uses the Footer component to render the footer section.
 */
export default function NewHome() {
  // api
  const dispatch = useDispatch();

  const homeAllArticlesOne = useSelector((state) => state.home.homeAllArticles);

  useEffect(() => {
    if (isEmpty(homeAllArticlesOne)) {
      dispatch(getHomeAllArticles());
    }
  }, [dispatch, homeAllArticlesOne]);

  const homeAllArticles = useSelector((state) => state.home.homeAllArticles);

  /*===================================================

                  renderLeft

===================================================*/

  const renderTitleStr = (title) => {
    return !isEmpty(title.length) && title.length > 65
      ? `${title.substring(0, 65)}...`
      : title;
  };
  const removeTags = (str) => {
    if (str === null || str === "" || str === undefined) return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/<[^>]*>/g, "").replace(/&nbsp;/gi, " ");
  };
  const renderLeft = () => {
    return (
      <div className="home-blog-card-main-div">
        {!isEmpty(homeAllArticles) &&
          homeAllArticles.map((data, index) => {
            const parser = new DOMParser();
            const htmlParse = parser.parseFromString(
              data.article_body,
              "text/html"
            );
            let blogTruncContent =
              htmlParse.getElementsByTagName("p")[0].textContent;
            let blogheadTrunc = blogTruncContent?.substring(
              0,
              blogTruncContent.includes(".")
                ? blogTruncContent.indexOf(".")
                : 220
            );
            // console.log(htmlParse.getElementsByTagName("p")[0].textContent);
            // blogheadTrunc = blogheadTrunc + "Considering investing for the first time? Want to understand your investment options? Looking to learn more about the risks and rewards of investing in startups";
            blogheadTrunc =
              blogheadTrunc?.length > 288
                ? blogheadTrunc.substring(0, 220)
                : blogheadTrunc;
            blogheadTrunc = removeTags(data.article_body);

            return (
              <div
                className="d-flex mx-0 align-items-start flex-nowrap mb-24"
                key={index}
              >
                <div className="home-blog-card-div">
                  <h4 className="font-24-roboto-bold">
                    {renderTitleStr(data.title)}
                  </h4>
                  <p className="home-blog-card-desc-div font-16-roboto-regular">
                    {blogheadTrunc}
                  </p>
                  {/* <p className="font-16-roboto-regular pb-10">...</p> */}
                  <Link to={`${knowledgeBaseWebAppLinkBlogDetail}/${data.id}`}>
                    <span className="read-more-text">Read more</span>
                  </Link>
                </div>
                <Link to={`${knowledgeBaseWebAppLinkBlogDetail}/${data.id}`}>
                  <div className="home-blog-img-div">
                    {!isEmpty(data.thumbnail) ? (
                      <img src={data.thumbnail.url} alt={data.title} />
                    ) : (
                      <span className="font-18-roboto-bold">
                        (Note: This is an image thumbnail)
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    );
  };

  /*===================================================

                  render

===================================================*/
  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[0]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={homePageTitle.icon}
            pageTitle={homePageTitle.title}
          />
          <div className="d-flex mx-0 flex-wrap home-content-div">
            <div className="home-content-div__colm1">
              <h5 className="font-18-roboto-bold">Recent Blog Posts</h5>
              {renderLeft()}
            </div>
            <div className="home-blog-card-main-div-border"></div>
            <div className="home-card-main-div">
              <h5 className="font-18-roboto-bold">
                What’s Going on in the Tech Space?
              </h5>
              <NewHomeCard />
            </div>
          </div>
          <Footer webAppFooter={true} />
        </div>
      </div>
    </>
  );
}
