import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import SideNavbar from "../header/SideNavbar";
import TopNavbar from "../header/TopNavbar";
import { activeSidebarMenu, faqsPageTitle } from "../header/activeMenu";
import PageTitle from "../header/PageTitle";
import Footer from "../../../components/desktop/header-footer/Footer";
import FaqFoldTwo from "../../../components/desktop/faq/FaqFoldTwo";

/**
 * FaqWebApp Component
 * 
 * Overview:
 * The FaqWebApp component is responsible for rendering the FAQ web application page. It includes a side navigation bar, a main content area, and a footer. The main content area contains a page title and the FAQFoldTwo component.
 * 
 * Details:
 * - The component uses the useState and useEffect hooks from React to manage the foldOneHeight state and perform side effects.
 * - The component scrolls to the top of the page when it mounts.
 * - The foldOneHeight state is set to the height of the element with the id "vl-main-page-div__page-title-id" using the offsetHeight property.
 * - The component renders the SideNavbar, PageTitle, and FaqFoldTwo components.
 * - The SideNavbar component receives the activeMenu prop, which determines the active menu item in the side navigation bar.
 * - The PageTitle component receives the imgPath and pageTitle props, which determine the icon and title of the page.
 * - The FaqFoldTwo component receives the foldOneHeight prop, which determines the height of the first fold in the FAQ section.
 * - The component renders the Footer component with the webAppFooter prop set to true.
 * 
 * Dependencies:
 * - React: The component uses React hooks and components.
 * - SideNavbar: The component depends on the SideNavbar component.
 * - PageTitle: The component depends on the PageTitle component.
 * - FaqFoldTwo: The component depends on the FaqFoldTwo component.
 * - Footer: The component depends on the Footer component.
 */
export default function FaqWebApp() {
  const [foldOneHeight, setFoldOneHeight] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.getElementById("vl-main-page-div__page-title-id") !== null) {
      setFoldOneHeight(
        document.getElementById("vl-main-page-div__page-title-id").offsetHeight
      );
    }
  }, []);

  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[5]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          <div id="vl-main-page-div__page-title-id">
            {/* <TopNavbar /> */}
            <PageTitle
              imgPath={faqsPageTitle.icon}
              pageTitle={faqsPageTitle.title}
            />
          </div>
          <div className="vl-faq-web-app">
            <FaqFoldTwo foldOneHeight={foldOneHeight} />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
