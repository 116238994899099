import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import SaveAndNextButton from "../common/SaveAndNextButton";
import BackButton from "../common/BackButton";
import TextareaField from "../common/TextareaField";

// api
import {
  createRaiseCapitalOrganization,
  updateRaiseCapitalOrganization,
} from "../../../web-app-store/actions/raiseCapitalAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { validateRaiseCapitalPitchAndProduct } from "./../../../web-app-store/validations/raise-capital/raiseCapitalPitchAndProduct";

/**
 * RaiseCapitalPitchAndProduct Component
 *
 * Overview:
 * The RaiseCapitalPitchAndProduct component is responsible for rendering a form for raising capital. It allows users to input information such as a pitch video URL, product/service overview, and business model. Users can also upload a pitch deck file. The component handles form validation and submission, and provides navigation buttons for moving between different steps of the capital raising process.
 *
 * Details:
 * - The component uses React hooks such as useState, useEffect, and useCallback to manage state and handle side effects.
 * - It utilizes the useDispatch and useSelector hooks from the react-redux library to interact with the Redux store.
 * - The component uses the useDropzone hook from the react-dropzone library to handle file uploads.
 * - It includes various event handlers for handling user interactions, such as selecting a file, removing a file, and saving and navigating to the next step.
 * - The component renders input fields, textareas, and buttons based on the current state and user input.
 *
 * Dependencies:
 * - React
 * - Redux
 * - react-redux
 * - react-dropzone
 */
export default function RaiseCapitalPitchAndProduct({ handleOnSelectTab }) {
  // api
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    videoUrl: "",
    productOverview: "",
    business_model: "",
    files: {},
    singleFileName: "",
    valueProposition: "",
    competition: "",
  });

  // api
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (!isEmpty(raiseCapitalOrg)) {
      let data = raiseCapitalOrg[0];
      let valuesObj = {
        videoUrl: data.pitch_video_url,
        files: data.pitch_deck,
        singleFileName: data.pitch_deck === null ? null : data.pitch_deck.name,
        productOverview: data.product_overview,
        business_model: data.business_model,
        valueProposition: data.value_proposition,
        competition: data.competition,
      };
      setValues({ ...valuesObj });
    }
  }, [raiseCapitalOrg]);

  /*==========================
         handler
  ===========================*/

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFrontendErrors({});
      setValues({
        ...values,
        singleFileName: acceptedFiles.path,
        files: [...acceptedFiles],
      });
    },
    [values]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
    multiple: false,
  });

  const handleOnClickRemoveFile = (index) => (e) => {
    setFrontendErrors({});
    acceptedFiles.splice(index, 1);
    setValues({ ...values, files: [...acceptedFiles] });
  };

  const handleChange = (event) => {
    setFrontendErrors({});
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (status === 200) {
      handleOnSelectTab(2);
    } else {
      handleOnSelectTab(1);
    }
  };

  const [frontendErrors, setFrontendErrors] = useState({});

  const handleOnClickSaveAndNext = () => {
    const { errors, isValid } = validateRaiseCapitalPitchAndProduct(values);
    console.log(errors);
    // console.log(errors);
    if (!isValid) {
      setFrontendErrors(errors);
    } else if (isValid) {
      setFrontendErrors({});

      setLoading(true);
      let userData = JSON.parse(localStorage.getItem("userData"));
      let formDataNew = new FormData();
      let newData = {};
      // if pitch_deck is empty then don't append it to formdata
      if (isEmpty(values.files)) {
        newData = {
          user: { id: userData.id },
          pitch_video_url: values.videoUrl,
          product_overview: values.productOverview,
          business_model: values.business_model,
          pitch_deck: null,
          value_proposition: values.valueProposition,
          competition: values.competition,
        };
      } else {
        formDataNew.append("files.pitch_deck", values.files[0]);
        newData = {
          user: { id: userData.id },
          pitch_video_url: values.videoUrl,
          product_overview: values.productOverview,
          business_model: values.business_model,
          value_proposition: values.valueProposition,
          competition: values.competition,
        };
      }

      formDataNew.append("data", JSON.stringify(newData));

      if (isEmpty(raiseCapitalOrg)) {
        dispatch(
          createRaiseCapitalOrganization(
            formDataNew,
            null,
            null,
            callBackStatus
          )
        );
      } else {
        dispatch(
          updateRaiseCapitalOrganization(
            formDataNew,
            raiseCapitalOrg[0].id,
            null,
            null,
            callBackStatus
          )
        );
      }
    }
  };

  // const filesList = acceptedFiles.map((file, index) => (
  //   <li key={index}>
  //     <span>
  //       <i className="fa fa-file file-icon" />
  //     </span>
  //     <span className="drag-and-drop-item-list__fileName"> {file.path}</span>
  //     <button onClick={handleOnClickRemoveFile(index)}>
  //       <i className="fa fa-close colse-icon" />
  //     </button>
  //   </li>
  // ));

  const singleFileDisplay = () => {
    return (
      <li>
        <span>
          <i className="fa fa-file file-icon" />
        </span>
        <span className="drag-and-drop-item-list__fileName">
          {!isEmpty(values.singleFileName)
            ? values.singleFileName
            : isEmpty(values.files[0])
            ? ""
            : values.files[0].path}
        </span>
        <button onClick={handleOnClickRemoveFile(0)}>
          <i className="fa fa-close colse-icon" />
        </button>
      </li>
    );
  };

  return (
    <>
      <h3 className="raise-capital-more-info-label pb-32">Pitch Deck</h3>
      <div className="pitch-and-product-drop-down-main-div">
        <div className="pitch-and-product-drop-down-div drag-drop-box">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>
              Click or Drag &amp; Drop Your Pitch Deck Here <br />
            </p>
          </div>
        </div>
        <p className="required-error-message">
          *Required Format: PDF or Google Slides
        </p>
        {frontendErrors.files ? (
          <p className="error-message">{frontendErrors.files}</p>
        ) : (
          <p className="error-message opacity-0">error</p>
        )}
        {!isEmpty(values.files) && (
          <>
            <h3 className="pitch-and-product-font-18-regular">Uploaded file</h3>
            <ul className="drag-and-drop-item-list">
              {/* {filesList} */}
              {singleFileDisplay()}
            </ul>
          </>
        )}
      </div>
      <div className="row mx-0 ">
        <div className="pitch-text-filed col-md-6 px-0">
          <InputFieldEmailTextPassword
            containerClassName="vl-form-input"
            label="Pitch Video URL"
            name="videoUrl"
            value={values.videoUrl}
            onChange={handleChange}
            type="text"
            error={frontendErrors.videoUrl}
          />
        </div>
      </div>

      <TextareaField
        placeholder="Describe your unique value proposition here"
        label="Value Proposition"
        labelClassName="raise-capital-more-info-label pt-32 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_product_textarea"
        name="valueProposition"
        value={values.valueProposition}
        onChange={handleChange}
        error={frontendErrors.valueProposition}
      />

      <TextareaField
        placeholder="Describe your competition and how you stand out here"
        label="Competition"
        labelClassName="raise-capital-more-info-label pt-32 pb-22"
        containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info raise_product_textarea"
        name="competition"
        value={values.competition}
        onChange={handleChange}
        error={frontendErrors.competition}
        errorClass="raise_pitch_error"
      />

      {/* <div className="pt-40">
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
          id="productOverview"
          label="Product/Service Overview"
          labelClassName="raise-capital-more-info-label pb-22"
          name="productOverview"
          value={values.productOverview}
          onChange={handleChange}
          error={frontendErrors.productOverview}
        />
      </div>
      <div className="pt-30">
        <TextareaField
          containerClassName="vl-form-input vl-form-input--textarea vl-form-input--textarea--raise-capital-more-info"
          id="business_model"
          label="Business Model"
          labelClassName="raise-capital-more-info-label pb-22"
          name="business_model"
          value={values.business_model}
          onChange={handleChange}
          error={frontendErrors.business_model}
        />
      </div> */}
      <div className="d-flex mx-0 pt-5 justify-content-end pitch-product-btn pitch_button_button">
        <BackButton handleOnClickBack={() => handleOnSelectTab(0)} />
        <SaveAndNextButton
          handleOnClickSaveAndNext={handleOnClickSaveAndNext}
          loading={loading}
        />
      </div>
    </>
  );
}
