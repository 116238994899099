import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DiscussionCreateNewForm from "../discussions/DiscussionCreateNewForm";
import DiscussionsCardDetails from "../discussions/DiscussionsCardDetails";
import DiscussionsCardStartupName from "../discussions/DiscussionsCardStartupName";
import format from "date-fns/format";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import isEmpty from "../../../web-app-store/validations/is-empty";
import like from "../../../assets/imgs/desktop/icon/like.svg";
import likeBlack from "../../../assets/imgs/desktop/icon/likeBlack.svg";
import reply from "../../../assets/imgs/desktop/icon/reply.svg";
import CreateDiscussionModal from "../common/CreateDiscussionModal";
import { getExploreOfferingsById } from "../../../web-app-store/actions/exploreAction";
import { getDiscussionsAllByOfferingId } from "../../../web-app-store/actions/discussionsAction";
import {
  discussionLikeExploreDetails,
  getDiscussionLikeExploreDetails,
  deleteDiscussionLikeExploreDetails,
} from "../../../web-app-store/actions/updatesAction";

/**
 * Discussion Component
 *
 * Overview:
 * The Discussion component is responsible for displaying discussions related to a specific offering. It allows users to start new discussions, view existing discussions, and interact with them by liking or removing likes.
 *
 * Details:
 * - The component uses state hooks to manage the likedArray and likedArrayO arrays, which store information about the discussions that the user has liked.
 * - It makes API calls to retrieve and update discussions and likes.
 * - The component includes sub-components such as DiscussionCreateNewForm, which is rendered when the user wants to start a new discussion.
 *
 * Usage:
 * To use the Discussion component, include it in the desired location within your application. Make sure to pass the necessary props, such as the raiseCapitalOfferings and userData, to enable proper functionality.
 *
 * Dependencies:
 * - React: The Discussion component is built using React.
 * - react-redux: The component uses react-redux to connect to the Redux store and dispatch actions.
 * - react-router-dom: The component uses react-router-dom to handle navigation and URL parameters.
 * - Other custom components and hooks: The component may depend on other custom components and hooks within the application for rendering and functionality.
 */
const Discussion = () => {
  const [likedArray, setLikedArray] = useState([]);
  const [likedArrayO, setLikedArrayO] = useState([]);
  // api
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => setOpen(false);
  const [displayOnWebsite, setDisplayOnWebsite] = useState(true);
  useEffect(() => {
    if (!history.location.pathname.includes("start-up-details-page")) {
      setDisplayOnWebsite(false);
    }
  }, [history.location.pathname]);

  const [raiseCapitalOfferings, setRaiseCapitalOfferings] = useState();

  const params = useParams();
  useEffect(() => {
    const callBackOfferingData = (data) => {
      if (!isEmpty(data)) {
        setRaiseCapitalOfferings(data);
      }
    };
    dispatch(getExploreOfferingsById(params.id, callBackOfferingData));
    dispatch(
      getDiscussionLikeExploreDetails(
        { user: userData?.id },
        getDiscussionLikeCallback
      )
    );
  }, [dispatch, params]);

  useEffect(() => {
    if (!isEmpty(raiseCapitalOfferings)) {
      dispatch(getDiscussionsAllByOfferingId(raiseCapitalOfferings.id));
    }
  }, [dispatch, raiseCapitalOfferings]);

  const discussionsAllByOfferingId = useSelector(
    (state) => state.discussions.discussionsAllByOfferingId
  );

  const [isOpenThread, setIsOpenThread] = useState();
  const [discussionId, setDiscussionId] = useState();

  const handleOnClickOpenDiscussionThread = (id) => (e) => {
    setIsOpenThread(true);
    setDiscussionId(id);
  };

  const handleOnClickGoBack = () => {
    setIsOpenThread(false);
    if (!isEmpty(raiseCapitalOfferings)) {
      dispatch(getDiscussionsAllByOfferingId(raiseCapitalOfferings.id));
    }
  };

  const [isDisplayForm, setIsDisplayForm] = useState(false);

  const handleOnClickStartDiscussion = () => {
    setIsDisplayForm(!isDisplayForm);
    setIsOpenThread(false);
  };

  const displayPostDetails = (desc) => {
    return !isEmpty(desc) && desc.length > 200
      ? `${desc.substring(0, 200)}...`
      : desc;
  };
  // const onLikePress = (discussionId) => {
  //   const payload = {
  //     Discussion: discussionId,
  //     User: auth.user.username,
  //   };
  //   alert(JSON.stringify(payload));
  // };
  const alreadyLiked = (id) => {
    return likedArray.includes(id);
  };
  const alreadyLikedId = (id) => {
    const temp = likedArrayO.filter((item) => {
      return item.discussion.id == id;
    });
    return temp[0].id;
  };
  const callBackPutUpdateLikeStatus = (status) => {
    if (status === 200) {
      dispatch(getDiscussionsAllByOfferingId(raiseCapitalOfferings.id));
      dispatch(
        getDiscussionLikeExploreDetails(
          { user: userData.id },
          getDiscussionLikeCallback
        )
      );
      // setDisplayLikeButton(false);
      // dispatch(getUpdatesExploreDetailsByOrganizationId(orgId));
    }
  };

  const getDiscussionLikeCallback = (data) => {
    let tempArray = [];
    data.map((item) => {
      tempArray.push(item.discussion.id);
    });
    setLikedArrayO(data);
    setLikedArray(tempArray);
  };

  const onLikePress = (discussionId) => {
    if (raiseCapitalOrg !== null && userData?.id !== undefined) {
      let formData = {
        organization: raiseCapitalOrg[0]?.id || undefined,
        user: userData.id,
        discussion: discussionId,
      };

      dispatch(
        discussionLikeExploreDetails(formData, callBackPutUpdateLikeStatus)
      );
    }
  };

  const onRemoveLikePress = (discussionId) => {
    dispatch(
      deleteDiscussionLikeExploreDetails(
        discussionId,
        callBackPutUpdateLikeStatus
      )
    );
  };
  const discusionLikeRemoveCallback = (status) => {
    if (status === 200) {
    }
  };

  return (
    <>
      {/* create discussion button */}
      {!displayOnWebsite && !isOpenThread
        ? !isEmpty(raiseCapitalOfferings)
          ? !isEmpty(raiseCapitalOfferings.organization) &&
            !isDisplayForm && (
              <div
                className={
                  isOpenThread
                    ? "text-right vl-discussion-start-button-div"
                    : "vl-discussion-start-button-div"
                }
              >
                <button
                  className="start-dis-btn"
                  // onClick={handleOnClickStartDiscussion}
                  onClick={onOpenModal}
                >
                  Start A Discussion
                </button>
                {/* <div className="start-dis-btn">
                  <button>Start A Discussion</button>
                </div> */}
              </div>
            )
          : ""
        : ""}

      {isDisplayForm ? (
        <DiscussionCreateNewForm
          handleOnClickStartDiscussion={handleOnClickStartDiscussion}
        />
      ) : (
        <>
          {!isOpenThread ? (
            <>
              <div className="vl-disc-card-overflow">
                {!isEmpty(discussionsAllByOfferingId) ? (
                  discussionsAllByOfferingId.map((data, index) => (
                    <>
                      <div className="discussion-tabs">
                        <div className="discussion-list-main">
                          <div className="discussion-list-main-inner">
                            <div className="discussion-top">
                              <div className="discussion-top-left">
                                <div className="discussion-top-profile">
                                  {/* <img
                                src={
                                  "https://strapi-upload-profile-images.s3.amazonaws.com/Headshot_with_buildings_08557d5295.jpg"
                                }
                                className="img-fluid"
                              /> */}
                                  {data.user_profile?.profile_image !=
                                  undefined ? (
                                    <img
                                      className="img-fluid"
                                      src={data.user_profile.profile_image.url}
                                      alt=""
                                    />
                                  ) : !isEmpty(data.user.firstname) ? (
                                    <p className="font-36-roboto-bold">
                                      {data.user.firstname.charAt(0)}
                                    </p>
                                  ) : (
                                    <p className="font-36-roboto-bold"></p>
                                  )}
                                </div>
                                <div className="discussion-top-text">
                                  <div className="discussion-title">
                                    <h2>
                                      {!isEmpty(data.user) &&
                                        `${data.user.firstname} ${data.user.lastname}`}
                                    </h2>
                                    {/* <span>OHANA (DEV)</span> */}
                                    {!isEmpty(data.user) && (
                                      <DiscussionsCardStartupName
                                        userId={data.user.id}
                                        data={data}
                                      />
                                    )}
                                  </div>
                                  <div className="discussion-title-text">
                                    <p>
                                      {!isEmpty(data.updated_at) &&
                                        format(
                                          new Date(data.updated_at),
                                          "DD MMM, YYYY"
                                        )}
                                    </p>
                                    {data.comments.map((data, index) => (
                                      <Fragment key={index}>
                                        {/* {console.log(data)} */}
                                        {index < 3 && (
                                          <div className="startup-team-img-div">
                                            {!isEmpty(data.abc) ? (
                                              <img
                                                src={data.profile_image.url}
                                                alt="team member"
                                                className="img-fluid"
                                              />
                                            ) : (
                                              <span className="font-24-roboto-medium">
                                                {!isEmpty(data.full_name) &&
                                                  data.full_name.charAt(0)}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                      </Fragment>
                                    ))}

                                    {!isEmpty(data.comments) && (
                                      <button className="startup-font-18-roboto-medium startup-details">
                                        {data.comments.length} People commented
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="discussion-thread-btn">
                                <button
                                  className="discussion-inner-btn"
                                  onClick={handleOnClickOpenDiscussionThread(
                                    data.id
                                  )}
                                >
                                  Open Discussion Thread
                                </button>
                              </div>
                            </div>
                            <div className="discussion-full-text">
                              <p>{displayPostDetails(data.post_details)}</p>
                              <div className="discussion-list">
                                <div className="discussion-btn">
                                  {alreadyLiked(data.id) ? (
                                    <button
                                      onClick={() =>
                                        onRemoveLikePress(
                                          alreadyLikedId(data.id)
                                        )
                                      }
                                    >
                                      <div className="discussion-list-btn discussion-active">
                                        <img src={like} />
                                        <span>
                                          {data.discussion_likes.length == 0
                                            ? "0 like"
                                            : `${data.discussion_likes.length} likes`}
                                        </span>
                                      </div>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => onLikePress(data.id)}
                                    >
                                      <div className="discussion-list-btn ">
                                        <img src={likeBlack} />
                                        <span>
                                          {data.discussion_likes.length == 0
                                            ? "0 like"
                                            : `${data.discussion_likes.length} likes`}
                                        </span>
                                      </div>
                                    </button>
                                  )}
                                  <button
                                    onClick={handleOnClickOpenDiscussionThread(
                                      data.id
                                    )}
                                  >
                                    <div className="discussion-list-btn">
                                      <img src={reply} />
                                      <span>Reply</span>
                                    </div>
                                  </button>
                                </div>
                                <div className="discussion-thread-btn">
                                  <button
                                    onClick={handleOnClickOpenDiscussionThread(
                                      data.id
                                    )}
                                  >
                                    Open Discussion Thread
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <p className="vl-disc-card-row__text3 font-18-roboto-medium">
                    No discussions found
                  </p>
                )}
              </div>
              {/* <StartUpDetailsLightPaper /> */}
            </>
          ) : (
            <>
              <DiscussionsCardDetails
                discussionId={discussionId}
                handleOnClickGoBack={handleOnClickGoBack}
              />
            </>
          )}
        </>
      )}
      <CreateDiscussionModal open={open} onClose={onCloseModal} />
    </>
  );
};

export default Discussion;
