import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// api
import { useEffect } from "react";
import biglike from "../../../assets/imgs/desktop/icon/biglike.svg";
import biglikeSelected from "../../../assets/imgs/desktop/icon/biglikeSelected.svg";
import { getRaiseCapitalOrganizations } from "../../../web-app-store/actions/raiseCapitalAction";
import {
  postUpdateLikeExploreDetails,
  deleteUpdateLikeExploreDetails,
  getUpdatesExploreDetailsByOrganizationId,
} from "../../../web-app-store/actions/updatesAction";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

/**
 * StartUpDetailsUpdatesLikeButton Component
 * 
 * Overview:
 * The StartUpDetailsUpdatesLikeButton component is responsible for rendering a like button for updates in a startup details page. It allows users to like or unlike updates and displays the number of likes for each update.
 * 
 * Details:
 * - The component uses Redux for state management and makes API calls to fetch and update data related to likes.
 * - It receives the following props:
 *   - orgId: The ID of the organization.
 *   - userId: The ID of the user.
 *   - updatesId: The ID of the update.
 *   - update_likes: An array of objects representing the likes for the update.
 *
 * Dependencies:
 * The StartUpDetailsUpdatesLikeButton component depends on the following:
 * - React: The component is built using React.
 * - Redux: The component uses Redux for state management.
 * - react-redux: The component uses the react-redux library to connect Redux to React.
 * - react-router-dom: The component uses react-router-dom for navigation.
 * - Other custom components and API functions used within the component.
 */
export default function StartUpDetailsUpdatesLikeButton({
  orgId,
  userId,
  updatesId,
  update_likes,
}) {
  // api
  const dispatch = useDispatch();

  const raiseCapitalOrg = useSelector(
    (state) => state.raiseCapital.raiseCapitalOrg
  );

  useEffect(() => {
    if (isEmpty(raiseCapitalOrg)) {
      dispatch(getRaiseCapitalOrganizations());
    }
  }, [dispatch]);

  const history = useHistory();
  const [displayOnWebsite, setDisplayOnWebsite] = useState(true);
  useEffect(() => {
    if (!history.location.pathname.includes("start-up-details-page")) {
      setDisplayOnWebsite(false);
    }
  }, [history.location.pathname]);

  const [displayLikeButton, setDisplayLikeButton] = useState(true);

  let userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let userDataObj = JSON.parse(localStorage.getItem("userData"));
    if (!isEmpty(update_likes)) {
      if (!isEmpty(update_likes.filter((a) => a.user === userDataObj.id))) {
        setDisplayLikeButton(false);
      }
    } else {
      setDisplayLikeButton(true);
    }
  }, [update_likes]); 

  const callBackPutUpdateLikeStatus = (status) => {
    if (status === 200) {
      setDisplayLikeButton(false);
      dispatch(getUpdatesExploreDetailsByOrganizationId(orgId));
    }
  };

  const handleOnClickLikeButton = () => {
    if (raiseCapitalOrg !== null && userData.id !== null) {
      let formData = {
        organization: raiseCapitalOrg[0].id,
        user: userData.id,
        update: updatesId,
      };

      dispatch(
        postUpdateLikeExploreDetails(formData, callBackPutUpdateLikeStatus)
      );
    }
  };

  const callBackDeleteUpdateLikeStatus = (status) => {
    if (status === 200) {
      setDisplayLikeButton(true);
      dispatch(getUpdatesExploreDetailsByOrganizationId(orgId));
    }
  };

  const handleOnClickUnlikeButton = () => {
    let updateLikeElement = update_likes.filter((a) => a.user === userData.id);
    dispatch(
      deleteUpdateLikeExploreDetails(
        updateLikeElement[0].id,
        callBackDeleteUpdateLikeStatus
      )
    );
  };

  return (
    <>
      {/* {!isEmpty(update_likes) ? (
        <span className="font-14-roboto-medium">
          {update_likes.length === 1
            ? `${update_likes.length} Like`
            : `${update_likes.length} Likes`}
        </span>
      ) : (
        <span className="font-14-roboto-medium opacity-0">0 Likes</span>
      )} */}

      {!displayOnWebsite &&
        !isEmpty(raiseCapitalOrg) &&
        !isEmpty(userData.id) &&
        (displayLikeButton ? (
          <div className="discussion-thread-btn">
            <button onClick={handleOnClickLikeButton}>
              <img src={biglike} className="img-fluid" />
            </button>
          </div>
        ) : (
          <div className="discussion-thread-btn">
            <button onClick={handleOnClickUnlikeButton}>
              <img src={biglikeSelected} className="img-fluid" />
            </button>
          </div>
        ))}
    </>
  );
}
