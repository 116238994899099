import React, { useState } from "react";
// import { useEffect } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { Circle } from "rc-progress";
import ReserveModal from "../common/ReserveModal";
import VLReserveModal from "../common/VLReserveModal";

import {
  investPaymentWebAppLink,
  newLoginSignUpLink,
  investWebAppLink,
} from "../../../components/desktop/header-footer/pageLinks";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import {
  // useDispatch,
  useSelector,
} from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { calcDaysRemaining } from "../common/calcDaysRemainingFunction";
/**
 * AmountRaisedCard Component
 * 
 * Overview:
 * The AmountRaisedCard component is responsible for rendering a card that displays information about the amount raised for a startup. It provides functionality for users to reserve or invest in the startup based on its offering status. The component is used within the larger UI-2 application to showcase startup details.
 * 
 * Details:
 * - The component accepts several props including offering_status, isInvestorCreated, companyData, amountRaised, valuationCap, fundingGoal, min, max, fundRaised, startDate, endDate, minInvestment, offering_id, id, invest_now_url, issuers_form_c, min_investment, funding_goal, refreshData, and organizationId.
 * - The component maintains internal state using the useState hook to manage the open and openVLModal states.
 * - The component defines several event handling functions such as onOpenModal, onOpenVLModal, onCloseModal, and onCloseVLModal.
 * - The component includes an overdue calculator function, but it is currently commented out.
 * - The component calculates the days remaining using the calcDaysRemaining function.
 * - The component uses the useSelector hook from the react-redux library to access the auth state.
 * - The component renders different buttons based on the offering status, user authentication, and investor status.
 * - The component includes ReserveModal and VLReserveModal sub-components for handling reservation and investment actions.
 * 
 * Dependencies:
 * - React (imported from 'react')
 * - React Redux (imported from 'react-redux')
 * - ReserveModal (imported from './ReserveModal')
 * - VLReserveModal (imported from './VLReserveModal')
 */
export default function AmountRaisedCard({
  offering_status,
  isInvestorCreated,
  companyData,
  amountRaised,
  valuationCap,
  fundingGoal,
  min,
  max,
  fundRaised,
  startDate,
  endDate,
  minInvestment,
  offering_id,
  id,
  invest_now_url,
  issuers_form_c,
  min_investment,
  funding_goal,
  refreshData,
  organizationId,
}) {
  const [open, setOpen] = useState(false);
  const [openVLModal, setOpenVLModal] = useState(false);

  const onOpenModal = () => {
    refreshData();
    setOpen(true);
  };
  const onOpenVLModal = () => {
    refreshData();
    setOpenVLModal(true);
  };
  const onCloseModal = () => setOpen(false);
  const onCloseVLModal = () => setOpenVLModal(false);

  /***************************
   * @DESC - OVERDUE CALCULATOR
   ********************************/
  // const overDueCalculator = (startDate, endDate) => {
  //   // console.log(new Date(startDate));
  //   // console.log(new Date(endDate));
  //   var dateFirst = new Date(dateFns.format(new Date(), "MM/DD/YYYY"));
  //   var dateSecond = new Date(dateFns.format(endDate, "MM/DD/YYYY"));
  //   // console.log(dateFirst);
  //   // console.log(dateSecond);
  //   var timeDiff = dateSecond.getTime() - dateFirst.getTime();
  //   var diffDays = Math.ceil(Math.abs(timeDiff) / (1000 * 3600 * 24));
  //   let overdue = timeDiff < 0 ? -1 : 1;
  //   // console.log(overdue);
  //   // console.log(diffDays);

  //   return {
  //     overdue,
  //     diffDays,
  //   };
  // };

  let daysRemaining = calcDaysRemaining(startDate, endDate);

  const auth = useSelector((state) => state.auth);
  const setInterestedInStartUp = () => {
    localStorage.setItem("interestedInStartUpId", id);
  };
  return (
    <React.Fragment>
      {daysRemaining >= 0 ? (
        auth.isAuthenticated === true && auth.user.confirmed === true ? (
          isInvestorCreated ? (
            offering_status === "Offering_Posted" ? (
              <div className="invest-btn">
                <button onClick={onOpenModal}>Reserve</button>
              </div>
            ) : offering_status === "VL_Reserve" ? (
              <div className="invest-btn">
                <button onClick={onOpenVLModal}>Invest</button>
              </div>
            ) : (
              <>
                <Link
                  //target={"_blank"}
                  to={{
                    pathname: investPaymentWebAppLink,
                    //pathname: invest_now_url,
                    state: {
                      detail: companyData,
                      offeringId: offering_id,
                      offeringID: id,
                      invest_now_url: invest_now_url,
                      min_investment,
                    },
                  }}
                >
                  <div className="invest-btn">
                    <button>Invest</button>
                  </div>
                </Link>
              </>
            )
          ) : offering_status === "Offering_Posted" ? (
            <div className="invest-btn">
              <button onClick={onOpenModal}>Reserve</button>
            </div>
          ) : offering_status === "VL_Reserve" ? (
            <div className="invest-btn">
              <button onClick={onOpenVLModal}>Invest</button>
            </div>
          ) : (
            <Link
              to={{
                pathname: investPaymentWebAppLink,
                //pathname: invest_now_url,
                state: {
                  detail: companyData,
                  offeringId: offering_id,
                  offeringID: id,
                  invest_now_url: invest_now_url,
                  min_investment,
                },
              }}
              // target="_blank"
            >
              <div className="invest-btn">
                <button>Invest</button>
              </div>
            </Link>
          )
        ) : offering_status === "Offering_Posted" ? (
          <a
            href={newLoginSignUpLink}
            target="_self"
            rel="noopener noreferrer"
            onClick={setInterestedInStartUp}
          >
            <div className="invest-btn">
              <button>Reserve</button>
            </div>
          </a>
        ) : offering_status === "VL_Reserve" ? (
          <a
            href={newLoginSignUpLink}
            target="_self"
            rel="noopener noreferrer"
            onClick={setInterestedInStartUp}
          >
            <div className="invest-btn">
              <button>Invest</button>
            </div>
          </a>
        ) : (
          <a
            href={newLoginSignUpLink}
            target="_self"
            rel="noopener noreferrer"
            onClick={setInterestedInStartUp}
          >
            <div className="invest-btn">
              <button>Invest</button>
            </div>
          </a>
        )
      ) : offering_status === "Offering_Posted" ? (
        <div className="invest-btn-disabled">
          <button disabled>Reserve</button>
        </div>
      ) : (
        <div className="invest-btn">
          <button>Invest</button>
        </div>
      )}

      <ReserveModal
        open={open}
        onClose={onCloseModal}
        offeringId={id}
        organizationId={organizationId}
        refreshData={refreshData}
        maxAmount={fundingGoal - amountRaised}
      />
      <VLReserveModal
        open={openVLModal}
        onClose={onCloseVLModal}
        offeringId={id}
        refreshData={refreshData}
        maxAmount={fundingGoal - amountRaised}
        invest_now_url={invest_now_url}
      />
    </React.Fragment>
  );
}
