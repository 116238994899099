import React, { useEffect, useState } from "react";
// import Select from "react-select";
import CustomRadioButton from "../common/CustomRadioButton";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import SaveButton from "../common/SaveButton";
import ReactSlider from "react-slider";
import InputFieldNumber from "../common/InputFieldNumber";
import BackButton from "../common/BackButton";
import DropdownSelectCommon from "../common/DropdownSelectCommon";
import CurrencyInputCommon from "../common/CurrencyInputCommon";

// api
import { useDispatch, useSelector } from "react-redux";
import {
  investSaveProfileSuccessAction,
  createInvestor,
  updateInvestor,
} from "../../../web-app-store/actions/investAction";
import isEmpty from "../../../web-app-store/validations/is-empty";
import { validateInvestorPortfolio } from "../../../web-app-store/validations/invest/investorPortfolio";
import { validateInvestDataNotEmpty } from "../../../web-app-store/validations/invest/investData";

import {
  investorTypeOptionArray,
  revenueStageTypeOptionArray,
  investmentStageOptions,
} from "../common/allDropdownOptions";
import DisplayCurrencyFormatString from "../../../common-website-and-app/DisplayCurrencyFormatString";

const minSliderAmountInvested = 0;
const maxSliderAmountInvested = 150000;
const maxSliderAmountInvestedDollar = "$150,000";
const setMinSliderAmountInvested = 0;

/**
 * InvestInvestmentPortfolio Component
 *
 * Overview:
 * The InvestInvestmentPortfolio component is responsible for rendering and managing the investment portfolio section of the investment page. It allows the user to input and save their investment preferences, such as investor type, revenue stage, and company investment stage.
 *
 * Details:
 * - The component uses Redux to manage the state of the investor data.
 * - It fetches the investor data from the Redux store and populates the form fields with the data if available.
 * - The component includes various event handlers to handle user interactions, such as selecting options from dropdowns, changing input values, and saving the form data.
 * - It also includes validation logic to ensure that the form data is valid before saving.
 *
 * Usage:
 * To use the InvestInvestmentPortfolio component, import it into the desired parent component and render it within the parent component's JSX. Pass the necessary props, such as handleOnSelectTab, to the component.
 *
 * Dependencies:
 * - React: The component is built using React and relies on its features, such as hooks and JSX syntax.
 * - Redux: The component uses Redux for state management and relies on the useDispatch and useSelector hooks to interact with the Redux store.
 * - Various custom components: The component uses custom dropdown select components, such as DropdownSelectCommon and DropdownMultiSelectCommon, to render the dropdown fields.
 * - External libraries: The component may have dependencies on external libraries for form validation and data manipulation, but these dependencies are not explicitly mentioned in the code provided.
 */
export default function InvestInvestmentPortfolio({
  handleOnSelectTab,
  isEdit,
  setIsPortfolioEdit,
}) {
  // api
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [frontEndErrors, setFrontEndErrors] = useState({});
  let userData = JSON.parse(localStorage.getItem("userData"));

  const investor = useSelector((state) => state.invest.investor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    investorType: "",
    // revenue_stage: "",
    // companyInvestmentStage: "",
    selectedRadioOption: "",
    averageAmount: setMinSliderAmountInvested,
    averageAmountCheckbox: false,
    customValue: 0,
    customValueCheckbox: false,
  });

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];

      // setting revenue stage in revenue stage dropdown
      let revenueStage =
        !isEmpty(data.revenue_stage) && data.revenue_stage.split(",");
      let finalRevenueStage = [];
      if (!isEmpty(data.revenue_stage)) {
        revenueStage.forEach((ele) => {
          finalRevenueStage.push({
            value: ele,
            label: ele,
          });
        });
      }

      // setting companyInvestmentStage in revenue stage dropdown
      let companyInvStage =
        !isEmpty(data.company_investment_stage) &&
        data.company_investment_stage.split(",");
      let finalCompanyInvStage = [];

      if (!isEmpty(data.company_investment_stage)) {
        companyInvStage.forEach((ele) => {
          finalCompanyInvStage.push({
            value: ele,
            label: ele,
          });
        });
      }

      let valuesObj = {
        investorType: !isEmpty(data.investor_type)
          ? {
              value: data.investor_type,
              label: data.investor_type,
            }
          : "",
        // revenue_stage: !isEmpty(data.revenue_stage) ? finalRevenueStage : "",
        // companyInvestmentStage: !isEmpty(data.company_investment_stage)
        //   ? finalCompanyInvStage
        //   : "",
        selectedRadioOption: data.part_of_investment_group ? "yes" : "no",
        averageAmount:
          isEmpty(data.avg_amt_inv_last_5) ||
          data.avg_amt_inv_last_5 < minSliderAmountInvested
            ? setMinSliderAmountInvested
            : data.avg_amt_inv_last_5,
        // values.averageAmountCheckbox: ,

        customValue: isEmpty(data.number_private_investments)
          ? 0
          : data.number_private_investments,
        // values.customValueCheckbox: ,
      };
      setValues({ ...valuesObj });
    }
  }, [investor]);
  /*============================================================
                           handler
  ============================================================*/
  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handleChangeNumber = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.validity.valid && !isEmpty(e.target.value)
          ? e.target.value
          : "",
    });
  };

  const handleChangeInvestorType = (investorType) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      investorType,
    });
  };

  const handleChangerevenue_stage = (revenue_stage) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      revenue_stage,
    });
  };

  const handleChangeCompanyInvestmentStage = (companyInvestmentStage) => {
    setFrontEndErrors({});
    setValues({
      ...values,
      companyInvestmentStage,
    });
  };

  const handleOnClickRadioOption = (e) => {
    setValues({
      ...values,
      selectedRadioOption: e.target.value,
    });
    setFrontEndErrors({});
  };

  const handleSlider = (averageAmount) => {
    if (averageAmount === maxSliderAmountInvested) {
      setValues({
        ...values,
        averageAmountCheckbox: true,
        averageAmount,
      });
    } else {
      setValues({
        ...values,
        averageAmountCheckbox: false,
        averageAmount,
      });
    }
  };

  const handleSlider2 = (customValue) => {
    setValues({
      ...values,
      customValue,
      customValueCheckbox: false,
    });
  };
  const handleCustomVaueCheckboxChange = (e) => {
    // console.log(e.target.checked);
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeAverageAmount = (element) => {
    if (element.floatValue > maxSliderAmountInvested) {
      setValues({
        ...values,
        averageAmount: "",
      });

      let errors = {
        averageAmount: `Max amount limit is ${maxSliderAmountInvestedDollar}`,
      };

      setFrontEndErrors(errors);
    } else {
      setFrontEndErrors({});
      setValues({
        ...values,
        averageAmount:
          isEmpty(element.floatValue) || isNaN(element.floatValue)
            ? ""
            : element.floatValue,
      });
    }
  };

  const handleAverageAmountCheckboxChange = (e) => {
    // console.log(e.target.checked);
    setValues({
      ...values,
      [e.target.name]: e.target.checked,
      // averageAmount: maxSliderAmountInvested,
    });
  };

  const callBackStatus = (status) => {
    setLoading(false);
    if (!isEdit) {
      if (status === 200) {
        if (!isEmpty(investor)) {
          // console.log(investor);
          const { isValid } = validateInvestDataNotEmpty(investor[0]);
          if (isValid) {
            dispatch(investSaveProfileSuccessAction(true));
          }
        }
      }
    } else {
      setIsPortfolioEdit(false);
    }
  };

  const handleSave = () => {
    // console.log(values);

    const { errors, isValid } = validateInvestorPortfolio(values);

    if (!isValid) {
      setFrontEndErrors(errors);
    } else if (isValid) {
      setFrontEndErrors({});
      setLoading(true);

      let finalRevenueStage = [];

      if (!isEmpty(values.revenue_stage)) {
        values.revenue_stage.forEach((ele) => {
          finalRevenueStage.push(ele.value);
        });
      }

      let finalCompanyInvestmentStage = [];

      if (!isEmpty(values.companyInvestmentStage)) {
        values.companyInvestmentStage.forEach((ele) => {
          finalCompanyInvestmentStage.push(ele.value);
        });
      }

      // console.log(finalRevenueStage.join(","));

      let formData = {
        user: { id: userData.id },
        investor_type: values.investorType.value,
        // revenue_stage: finalRevenueStage.join(","),
        // company_investment_stage: finalCompanyInvestmentStage.join(","),
        part_of_investment_group:
          values.selectedRadioOption === "yes" ? true : false,
        avg_amt_inv_last_5: values.averageAmount,
        // : values.averageAmountCheckbox,
        number_private_investments: values.customValue,
        // : values.customValueCheckbox,
      };

      if (isEmpty(investor)) {
        dispatch(createInvestor(formData, callBackStatus));
      } else {
        dispatch(updateInvestor(formData, investor[0].id, callBackStatus));
      }
    }
  };

  /*============================================================
                           render
  ============================================================*/
  return (
    <div
      className={`invest-personal-tab-panel--portfolio ${
        isEdit
          ? "invest-personal-portfolio edit_invest_portfolio_wrap pt-0"
          : "invest-personal-tab-panel"
      }`}
    >
      <div className="mx-0 align-items-center edit_invest_type invest-types invest_types_wrap ">
        <div className="px-0">
          <h3 className="invest-preferences-font-24-roboto investor_type_title">
            Investor Type?
          </h3>
        </div>
        <div className="px-0">
          <DropdownSelectCommon
            isHideLabel={true}
            value={values.investorType}
            onChange={handleChangeInvestorType}
            options={investorTypeOptionArray}
            placeholder={values.investorType}
            isSearchable={true}
            error={frontEndErrors.investorType}
          />
        </div>
      </div>
      {/* <div className="mx-0 d-flex align-items-center gap-4 invest-types">
        <div className=" px-0">
          <h3 className="invest-preferences-font-24-roboto mb-0">
            Are you a member of an investment group?
          </h3>
        </div>
        <div className=" px-0">
          <div className="d-flex mx-0 ">
            <CustomRadioButton
              extraClassName="custom-radio-btn-block--investor mb-0"
              label="Yes"
              name="yes"
              selectedRadioOption={values.selectedRadioOption}
              //isDisabled={true}
              handleOnClickRadioOption={handleOnClickRadioOption}
            />
            <CustomRadioButton
              extraClassName="custom-radio-btn-block--investor mb-0"
              label="No"
              name="no"
              selectedRadioOption={values.selectedRadioOption}
              //isDisabled={true}
              handleOnClickRadioOption={handleOnClickRadioOption}
            />
          </div>
          {frontEndErrors?.selectedRadioOption ? (
            <p className="error-message ml-0">
              {frontEndErrors?.selectedRadioOption}
            </p>
          ) : (
            <p className="error-message opacity-0 d-none">error</p>
          )}
        </div>
      </div> */}
      {!isEdit && (
        <React.Fragment>
          <h3 className="invest-portfolio-font-24-italic pt-47">
            Average Investment Amount (Last 5 years)
            {/* <span className="invest-investment-portfolio-font-18">
              {" "}
              *excluding VedasLabs
            </span> */}
          </h3>
          <h5 className="invest-investment-font-26-regular">
            <DisplayCurrencyFormatString value={values.averageAmount} />
          </h5>
          <div className="invest-slider-div">
            <ReactSlider
              min={minSliderAmountInvested}
              max={maxSliderAmountInvested}
              className="horizontal-slider"
              thumbClassName="invest-thumb"
              trackClassName="invest-track"
              onChange={handleSlider}
              value={values.averageAmount}
              // renderThumb={(props, state) => (
              //   <div {...props}>
              //     {" "}
              //     {console.log(state)}
              //     {state.valueNow}
              //   </div>
              // )}
            />
          </div>
          <div className="vl-checkbox vl-checkbox--invest-calculator">
            <Checkbox
              onChange={handleAverageAmountCheckboxChange}
              value={values.averageAmountCheckbox}
              checked={values.averageAmountCheckbox}
              defaultChecked={false}
              name="averageAmountCheckbox"
              id="averageAmountCheckbox"
            />
            <label className="pl-10 font-20-roboto-regular">Enter number</label>
          </div>

          {values.averageAmountCheckbox && (
            <div className="pt-50">
              <CurrencyInputCommon
                containerClassName="vl-form-input"
                label="Average Amount Invested ($)"
                name="averageAmount"
                value={values.averageAmount}
                onChange={handleChangeAverageAmount}
                error={frontEndErrors.averageAmount}
              />
            </div>
          )}
          <h3 className="invest-portfolio-font-24-italic pt-47">
            Number of Private Investments (Last 5 Years)
            {/* <span className="invest-investment-portfolio-font-18">
              {" "}
              *excluding VedasLabs
            </span> */}
          </h3>
          <h5 className="invest-investment-font-26-regular">
            {values.customValue}
          </h5>
          <div className="invest-slider-div">
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="invest-thumb"
              trackClassName="invest-track"
              onChange={handleSlider2}
              value={values.customValue}
              //renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
            />
          </div>
          <div className="vl-checkbox vl-checkbox--invest-calculator">
            <Checkbox
              onChange={handleCustomVaueCheckboxChange}
              value={values.customValueCheckbox}
              checked={values.customValueCheckbox}
              defaultChecked={false}
              name="customValueCheckbox"
              id="customValueCheckbox"
            />
            <label className="pl-10 font-20-roboto-regular">Enter number</label>
          </div>
          {values.customValueCheckbox ? (
            <div className="pt-50">
              <InputFieldNumber
                containerClassName="vl-form-input"
                label="Enter Number"
                name="customValue"
                value={values.customValue}
                onChange={handleChangeNumber}
                maxLength={21}
              />
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      )}
      <div
        className={`d-flex mx-0 justify-content-end portfolio-btn-div ${
          isEdit ? "portfolio_button_wrap" : ""
        }`}
      >
        {isEdit ? (
          <SaveButton handleOnClickSaveButton={handleSave} loading={loading} />
        ) : (
          <React.Fragment>
            <BackButton handleOnClickBack={() => handleOnSelectTab(1)} />

            <SaveButton
              handleOnClickSaveButton={handleSave}
              loading={loading}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
