import React, { useEffect, useState } from "react";
import InputFieldEmailTextPassword from "../common/InputFieldEmailTextPassword";
import Select from "react-select";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import CurrencyInputCommon from "../common/CurrencyInputCommon";
import InvestLimitCalculatorCard from "./InvestLimitCalculatorCard";

// api
import {
  // useDispatch,
  useSelector,
} from "react-redux";
import isEmpty from "../../../web-app-store/validations/is-empty";

import { jobIndustryOptionArray } from "../common/allDropdownOptions";

/**
 * InvestCalculatorDisplay Component
 * 
 * Overview:
 * The InvestCalculatorDisplay component is responsible for displaying the investment calculator form. It collects user input related to investment criteria and limits, and calculates the investment limit based on the user's net worth and income. The component also includes a sub-component called InvestLimitCalculatorCard, which displays the calculated investment limit.
 * 
 * Details:
 * - The component uses the useSelector hook from the react-redux library to access the investor data from the Redux store.
 * - The component uses the useState and useEffect hooks from the React library to manage the form values and perform side effects.
 * - The component includes a renderSpouseInfo function that renders the spouse information fields if the spouse_income_included value is true.
 * - The component renders various input fields, checkboxes, and a select dropdown to collect user input.
 * - The component renders the InvestLimitCalculatorCard sub-component to display the calculated investment limit.
 * 
 * Props:
 * - isDisplayTextInfo: A boolean prop that determines whether to display additional text information about the investment criteria and limits.
 * 
 * Dependencies:
 * - React: The component uses React library for building the user interface.
 * - react-redux: The component uses the useSelector hook from the react-redux library to access the investor data from the Redux store.
 * - CurrencyInputCommon: The component assumes the existence of a CurrencyInputCommon component for rendering currency input fields.
 * - InputFieldEmailTextPassword: The component assumes the existence of an InputFieldEmailTextPassword component for rendering input fields for email, text, and password.
 * - Checkbox: The component assumes the existence of a Checkbox component for rendering checkboxes.
 * - Select: The component assumes the existence of a Select component for rendering select dropdowns.
 * - jobIndustryOptionArray: The component assumes the existence of a jobIndustryOptionArray variable that contains options for the job industry select dropdown.
 * - InvestLimitCalculatorCard: The component assumes the existence of an InvestLimitCalculatorCard component for displaying the calculated investment limit.
 */
export default function InvestCalculatorDisplay({ isDisplayTextInfo }) {
  const investor = useSelector((state) => state.invest.investor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [values, setValues] = useState({
    yearlyIncome: "",
    networth: "",
    jobTitle: "",
    jobIndustry: "",
    spouse_income_included: true,
    invested_outside_vlabs: false,
    spouse_acc_vedaslab: false,
    fname: "",
    lname: "",
    email: "",
    amountInvestedOutsideOfVL: "",
    amountInvestedOnVL: "",
  });

  useEffect(() => {
    if (!isEmpty(investor)) {
      let data = investor[0];
      let valuesObj = {
        yearlyIncome: !isEmpty(data.income) ? data.income : "",
        networth: !isEmpty(data.networth) ? data.networth : "",
        amountInvestedOutsideOfVL: !isEmpty(data.amount_invested_outside)
          ? data.amount_invested_outside
          : "",
        amountInvestedOnVL: !isEmpty(data.amount_invested_vlabs)
          ? data.amount_invested_vlabs
          : "",
        jobTitle: data.job_title,
        jobIndustry: !isEmpty(data.job_industry)
          ? {
              value: data.job_industry,
              label: data.job_industry,
            }
          : "",
        spouse_income_included: data.spouse_income_included,
        invested_outside_vlabs: data.invested_outside_vlabs,
        spouse_acc_vedaslab: data.spouse_acc_vedaslab,
        fname: data.spouse_first_name,
        lname: data.spouse_last_name,
        email: data.spouse_email,
      };
      setValues({ ...valuesObj });
    }
  }, [investor]);
  const renderSpouseInfo = () => {
    return (
      <>
        <h3 className="invest-calculator-font-24-medium">Spouse Information</h3>
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="First Name"
          name="fname"
          value={values.fname}
          isDisabled={true}
          type="text"
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Last Name"
          name="lname"
          value={values.lname}
          isDisabled={true}
          type="text"
        />
        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Email Address"
          name="email"
          value={values.email}
          isDisabled={true}
          type="email"
        />
      </>
    );
  };

  /*============================================================
                           render
  ============================================================*/
  return (
    <div className="row invest-personal-tab-panel invest-personal-tab-panel--calculator">
      <div className="col-12 col-md-6 px-0">
        {isDisplayTextInfo && (
          <>
            <h2 className="invest-calcultor-font-25-medium">
              Investment Criteria &amp; Limits
            </h2>
            <h4 className="invest-calcultor-font-20-regular">
              The SEC limits how much you can invest in any 12 month period. The
              limit is calculated based on your net worth and income.
            </h4>
            <h5 className="invest-calcultor-font-16-regular">
              The information collected below is stored securely and is only
              used to calculate your investment limit.
            </h5>
          </>
        )}

        <CurrencyInputCommon
          containerClassName="vl-form-input"
          label="Yearly Income ($)"
          name="yearlyIncome"
          value={values.yearlyIncome}
          isDisabled={true}
        />

        <CurrencyInputCommon
          containerClassName="vl-form-input"
          label="Net Worth ($)"
          name="networth"
          value={values.networth}
          isDisabled={true}
        />

        <InputFieldEmailTextPassword
          containerClassName="vl-form-input"
          label="Job Title"
          name="jobTitle"
          value={values.jobTitle}
          isDisabled={true}
          type="text"
        />
        <div className="vl-form-input  vl-form-input--bordered-select-div">
          <label htmlFor="">Job Industry</label>
          <Select
            value={values.jobIndustry}
            isDisabled={true}
            options={jobIndustryOptionArray}
            className="vl-select-container"
            classNamePrefix="vl-select"
            placeholder={values.jobIndustry}
            isSearchable={true}
          />
        </div>

        <div className="vl-checkbox vl-checkbox--invest-calculator">
          <Checkbox
            disabled={true}
            checked={values.spouse_income_included}
            name="spouse_income_included"
          />
          <label>Including my spouse’s income and net worth.</label>
        </div>
        {values.spouse_income_included && renderSpouseInfo()}

        <div className="vl-checkbox vl-checkbox--invest-calculator mt-45">
          <Checkbox
            disabled={true}
            checked={values.invested_outside_vlabs}
            name="invested_outside_vlabs"
          />
          <label>I have invested in crowdfunding in the past 12 months.</label>
        </div>

        {values.invested_outside_vlabs && (
          <div className="mt-45 pt-15">
            <CurrencyInputCommon
              containerClassName="vl-form-input"
              label="Amount Invested Outside of VedasLabs ($)"
              name="amountInvestedOutsideOfVL"
              value={values.amountInvestedOutsideOfVL}
              isDisabled={true}
            />
          </div>
        )}

        <div className="vl-checkbox vl-checkbox--invest-calculator mt-38">
          <Checkbox
            checked={values.spouse_acc_vedaslab}
            disabled={true}
            name="spouse_acc_vedaslab"
          />
          <label>My spouse has an account with VedasLabs.</label>
        </div>
      </div>
      <div
        className={
          isDisplayTextInfo
            ? "col-12 col-md-6 px-0 vl-invest-calculator-form-limit-card-outer-div"
            : "col-12 col-md-6 px-0 vl-invest-calculator-form-limit-card-outer-div pt-0"
        }
      >
        <InvestLimitCalculatorCard
          net_worth={values.networth}
          annual_income={values.yearlyIncome}
          amountInvestedOutsideOfVL={values.amountInvestedOutsideOfVL}
          amountInvestedOnVL={values.amountInvestedOnVL}
        />
      </div>
    </div>
  );
}

InvestCalculatorDisplay.defaultProps = {
  isDisplayTextInfo: true,
};
