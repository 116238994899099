/**
 * Country Dropdown List Component
 *
 * Overview:
 * This component provides a list of countries with their corresponding codes. It is used to populate a dropdown list for selecting a country.
 *
 * Details:
 * - The component exports an array of objects, where each object represents a country with its name and code.
 * - The list includes countries from all over the world.
 * - The component does not include any sub-components or rendering logic. It only exports the data.
 *
 * Dependencies:
 * This component does not have any external dependencies.
 */
export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const countryPhoneLengths = {
  ad: 6, // Andorra
  ae: 9, // United Arab Emirates
  af: 9, // Afghanistan
  al: 9, // Albania
  am: 8, // Armenia
  ao: 9, // Angola
  ar: 10, // Argentina
  at: 10, // Austria
  au: 9, // Australia
  az: 9, // Azerbaijan
  ba: 8, // Bosnia and Herzegovina
  bd: 10, // Bangladesh
  be: 9, // Belgium
  bf: 8, // Burkina Faso
  bg: 9, // Bulgaria
  bh: 8, // Bahrain
  bi: 8, // Burundi
  bj: 8, // Benin
  bn: 7, // Brunei
  bo: 8, // Bolivia
  br: 11, // Brazil
  bt: 8, // Bhutan
  bw: 8, // Botswana
  by: 9, // Belarus
  bz: 7, // Belize
  ca: 10, // Canada
  cd: 9, // Democratic Republic of the Congo
  cf: 9, // Central African Republic
  cg: 9, // Republic of the Congo
  ch: 9, // Switzerland
  ci: 8, // Ivory Coast
  cl: 9, // Chile
  cm: 9, // Cameroon
  cn: 11, // China
  co: 10, // Colombia
  cr: 8, // Costa Rica
  cu: 8, // Cuba
  cv: 7, // Cape Verde
  cy: 8, // Cyprus
  cz: 9, // Czech Republic
  de: 11, // Germany
  dj: 8, // Djibouti
  dk: 8, // Denmark
  dm: 7, // Dominica
  do: 10, // Dominican Republic
  dz: 9, // Algeria
  ec: 9, // Ecuador
  ee: 7, // Estonia
  eg: 10, // Egypt
  er: 7, // Eritrea
  es: 9, // Spain
  et: 9, // Ethiopia
  fi: 10, // Finland
  fj: 7, // Fiji
  fr: 9, // France
  ga: 7, // Gabon
  gb: 10, // United Kingdom
  gd: 10, // Grenada
  ge: 9, // Georgia
  gh: 9, // Ghana
  gm: 7, // Gambia
  gn: 9, // Guinea
  gq: 9, // Equatorial Guinea
  gr: 10, // Greece
  gt: 8, // Guatemala
  gw: 7, // Guinea-Bissau
  gy: 7, // Guyana
  hk: 8, // Hong Kong
  hn: 8, // Honduras
  hr: 9, // Croatia
  ht: 8, // Haiti
  hu: 9, // Hungary
  id: 10, // Indonesia
  ie: 9, // Ireland
  il: 9, // Israel
  in: 10, // India
  iq: 10, // Iraq
  ir: 10, // Iran
  is: 7, // Iceland
  it: 9, // Italy
  jm: 10, // Jamaica
  jo: 9, // Jordan
  jp: 10, // Japan
  ke: 10, // Kenya
  kg: 9, // Kyrgyzstan
  kh: 9, // Cambodia
  ki: 8, // Kiribati
  km: 7, // Comoros
  kn: 10, // Saint Kitts and Nevis
  kp: 10, // North Korea
  kr: 10, // South Korea
  kw: 8, // Kuwait
  kz: 10, // Kazakhstan
  la: 9, // Laos
  lb: 8, // Lebanon
  lc: 10, // Saint Lucia
  li: 7, // Liechtenstein
  lk: 9, // Sri Lanka
  lr: 7, // Liberia
  ls: 8, // Lesotho
  lt: 8, // Lithuania
  lu: 9, // Luxembourg
  lv: 8, // Latvia
  ly: 10, // Libya
  ma: 9, // Morocco
  mc: 8, // Monaco
  md: 8, // Moldova
  me: 8, // Montenegro
  mg: 9, // Madagascar
  mh: 7, // Marshall Islands
  mk: 8, // North Macedonia
  ml: 8, // Mali
  mm: 9, // Myanmar
  mn: 8, // Mongolia
  mo: 8, // Macau
  mr: 8, // Mauritania
  mt: 8, // Malta
  mu: 8, // Mauritius
  mv: 7, // Maldives
  mw: 9, // Malawi
  mx: 10, // Mexico
  my: 10, // Malaysia
  mz: 9, // Mozambique
  na: 9, // Namibia
  ne: 8, // Niger
  ng: 10, // Nigeria
  ni: 8, // Nicaragua
  nl: 9, // Netherlands
  no: 8, // Norway
  np: 10, // Nepal
  nr: 7, // Nauru
  nu: 4, // Niue
  nz: 9, // New Zealand
  om: 8, // Oman
  pa: 8, // Panama
  pe: 9, // Peru
  pf: 6, // French Polynesia
  pg: 8, // Papua New Guinea
  ph: 10, // Philippines
  pk: 10, // Pakistan
  pl: 9, // Poland
  pt: 9, // Portugal
  pw: 7, // Palau
  py: 9, // Paraguay
  qa: 8, // Qatar
  ro: 10, // Romania
  rs: 9, // Serbia
  ru: 10, // Russia
  rw: 9, // Rwanda
  sa: 9, // Saudi Arabia
  sb: 7, // Solomon Islands
  sc: 7, // Seychelles
  sd: 9, // Sudan
  se: 9, // Sweden
  sg: 8, // Singapore
  si: 9, // Slovenia
  sk: 9, // Slovakia
  sl: 8, // Sierra Leone
  sm: 9, // San Marino
  sn: 9, // Senegal
  so: 7, // Somalia
  sr: 7, // Suriname
  st: 7, // Sao Tome and Principe
  sv: 8, // El Salvador
  sy: 9, // Syria
  sz: 7, // Eswatini
  td: 8, // Chad
  tg: 8, // Togo
  th: 9, // Thailand
  tj: 9, // Tajikistan
  tk: 4, // Tokelau
  tl: 8, // East Timor
  tm: 8, // Turkmenistan
  tn: 8, // Tunisia
  to: 5, // Tonga
  tr: 10, // Turkey
  tt: 10, // Trinidad and Tobago
  tv: 7, // Tuvalu
  tz: 9, // Tanzania
  ua: 9, // Ukraine
  ug: 9, // Uganda
  us: 10, // United States
  uy: 9, // Uruguay
  uz: 9, // Uzbekistan
  va: 6, // Vatican City
  vc: 10, // Saint Vincent and the Grenadines
  ve: 10, // Venezuela
  vn: 9, // Vietnam
  vu: 7, // Vanuatu
  ws: 7, // Samoa
  ye: 9, // Yemen
  za: 9, // South Africa
  zm: 9, // Zambia
  zw: 9, // Zimbabwe
};
