import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);

/**
 * Routes Component
 * 
 * Overview:
 * The Routes component is responsible for rendering the child components based on the current URL path. It also tracks page views using ReactGA.
 * 
 * Details:
 * - The component uses the useEffect hook to track page views using ReactGA whenever the component is mounted.
 * - The component renders the children components passed to it.
 * 
 * Dependencies:
 * - ReactGA: The component uses the ReactGA library to track page views.
 * - React: The component uses React hooks and JSX syntax.
 * 
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered.
 * @returns {ReactNode} The rendered component.
 */
const Routes = ({ children }) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    })
    return (
        <>
            {children}
        </>

    )

}

export default withRouter(Routes);