import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * PrivateRoute Component
 *
 * Overview:
 * The PrivateRoute component is a higher-order component that renders a specified component only if the user is authenticated and confirmed. Otherwise, it redirects the user to the home page.
 *
 * Details:
 * - The PrivateRoute component receives a component prop, which represents the component to be rendered if the user is authenticated and confirmed.
 * - The component uses the useSelector hook from the react-redux library to access the authentication state from the Redux store.
 * - The component checks if the user is authenticated and confirmed. If true, it renders the specified component with the provided props. Otherwise, it redirects the user to the home page.
 *
 * Dependencies:
 * - react-redux: The useSelector hook is used to access the authentication state from the Redux store.
 * - react-router-dom: The Route and Redirect components are used for routing and redirection.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  // console.log(auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true && auth.user.confirmed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
