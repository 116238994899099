import axios from "axios";
import { backendUrl } from "./config";
import {
  SET_HOME_ALL_ARTICLES,
  SET_HOME_ALL_FEEDS,
  SET_NEWS_LETTER,
} from "./../types";
import Toast from "light-toast";
/*===================================================================================
            get explore all organizations
====================================================================================*/
/**
 * Get Home All Articles Action
 * 
 * Overview:
 * Retrieves all articles for display on the homepage. This action fetches a list of articles from the backend and updates the application state with this data.
 * 
 * Details:
 * - Executes a GET request to fetch all articles from the backend.
 * - Dispatches an action to store the retrieved articles in the redux state, making them accessible throughout the application.
 * 
 * Usage:
 * Can be dispatched during the initial loading of the homepage or when the articles section is accessed to ensure the latest content is displayed.
 * Example: `dispatch(getHomeAllArticles())`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 * - Redux's dispatch function for state management.
 */
export const getHomeAllArticles = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/articles`);
    if (data) {
      dispatch({
        type: SET_HOME_ALL_ARTICLES,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get explore offerings / fundraiser by id
====================================================================================*/
/**
 * Get Home Article Action
 * 
 * Overview:
 * Fetches detailed information about a specific article using its ID. This information is then passed to a callback function for further processing or display.
 * 
 * Details:
 * - Makes a GET request to retrieve detailed information for an article identified by its ID.
 * - Utilizes a callback function to pass the article data upon successful retrieval.
 * 
 * Usage:
 * Useful for loading and displaying the full content of an article when a user selects an article from a list or a summary view.
 * Example: `dispatch(getHomeArticle(articleId, callBackHomeArticle))`
 * 
 * Dependencies:
 * - `axios` for HTTP requests.
 */

export const getHomeArticle = (articleId, callBackHomeArticle) => async () => {
  try {
    let { data } = await axios.get(`${backendUrl}/articles/${articleId}`);
    if (data) {
      callBackHomeArticle(data);
    }
  } catch (err) {
    console.log(err);
  }
};

/*===================================================================================
            get all feeds
====================================================================================*/
/**
 * Get Home All Feeds Action
 * 
 * Overview:
 * Retrieves a limited number of the most recent feeds for the homepage, sorting them by creation date in descending order. This action updates the application state with the fetched feeds.
 * 
 * Details:
 * - Sends a GET request with query parameters to limit the number of feeds fetched and sort them by their creation date.
 * - Dispatches an action with the fetched feeds data to update the redux state.
 * 
 * Usage:
 * Dispatched to provide users with the latest updates or news in a feed format on the homepage.
 * Example: `dispatch(getHomeAllFeeds())`
 * 
 * Dependencies:
 * The same as `Get Home All Articles Action`.
 */
export const getHomeAllFeeds = () => async (dispatch) => {
  try {
    // let { data } = await axios.get(`${backendUrl}/feeds`);
    let { data } = await axios.get(
      `${backendUrl}/feeds?_limit=5&_sort=created_at:desc`
    );
    if (data) {
      dispatch({
        type: SET_HOME_ALL_FEEDS,
        payload: data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

// /feeds?_limit=5&_sort=created_at:desc

/*================================================================
                         Light Paper
=================================================================*/
/**
 * Light Paper Action
 * 
 * Overview:
 * Handles the submission of light paper forms, saving the information to the backend. Upon successful submission, it provides feedback to the user through a toast message and a callback function.
 * 
 * Details:
 * - Executes a POST request to save light paper form data in the backend.
 * - Notifies the user of successful data submission with a toast message.
 * - Invokes a callback function to notify the calling code of the operation's success.
 * 
 * Usage:
 * This action is typically dispatched when a user submits a form related to light papers, such as research papers or project proposals.
 * Example: `dispatch(LightPaperAction(formData, callBackLigterPaper))`
 * 
 * Dependencies:
 * - `axios` for HTTP requests.
 * - `Toast` for feedback messages.
 */
export const LightPaperAction = (formData, callBackLigterPaper) => async () => {
  try {
    let { data, status } = await axios.post(
      `${backendUrl}/light_papers`,
      formData
    );
    if (data) {
      Toast.info("Information saved", 3000);
      callBackLigterPaper(status);
    }
  } catch (err) {
    console.log(err);
  }
};

/*================================================================
                         News-Letter
=================================================================*/
/**
 * News-Letter Action
 * 
 * Overview:
 * Subscribes users to a newsletter by submitting their email data to the backend. A callback function is utilized to handle the response and potentially update the UI accordingly.
 * 
 * Details:
 * - Sends a POST request with the user's email data to subscribe to the newsletter.
 * - Uses a callback function to communicate the success status back to the UI.
 * 
 * Usage:
 * Dispatched when users enter their email in a newsletter subscription form to keep them updated with news, articles, or updates.
 * Example: `dispatch(newsLetterAction(emailData, callBack))`
 * 
 * Dependencies:
 * - `axios` for making HTTP requests.
 */
export const newsLetterAction = (emailData, callBack, dispatch) => async () => {
  try {
    let { data, status } = await axios.post(
      `${backendUrl}/news-letter`,
      emailData
    );
    if (data) {
      callBack(status);
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get News Letter Action
 * 
 * Overview:
 * Fetches all subscribed emails or newsletter entries from the backend, updating the application state with this data. This is primarily used for administrative or analytic purposes.
 * 
 * Details:
 * - Executes a GET request to retrieve all newsletter subscriptions.
 * - Dispatches an action to update the redux state with the fetched data.
 * 
 * Usage:
 * Can be dispatched by administrators or automated systems to gather analytics on newsletter subscriptions.
 * Example: `dispatch(getNewsLetterAction())`
 * 
 * Dependencies:
 * The same as `Get Home All Articles Action`.
 */
export const getNewsLetterAction = () => async (dispatch) => {
  try {
    let { data } = await axios.get(`${backendUrl}/news-letter`);
    if (data) {
      dispatch({
        type: SET_NEWS_LETTER,
        payload: data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
