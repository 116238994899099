import React from "react";
import SideNavbar from "../header/SideNavbar";
import {
  activeSidebarMenu,
  knowledgeBasePageTitle,
} from "../header/activeMenu";
import PageTitle from "../header/PageTitle";

import KnowledgeBaseFoldTwo from "../../../components/desktop/knowledge-base/KnowledgeBaseFoldTwo";
import Footer from "../../../components/desktop/header-footer/Footer";

/**
 * KnowledgeBaseMainApp Component
 * 
 * Overview:
 * The KnowledgeBaseMainApp component is responsible for rendering the main page of the knowledge base section in the application. It includes a side navigation bar, a page title, and the content of the knowledge base.
 * 
 * Details:
 * - The component renders a side navigation bar using the SideNavbar component.
 * - It also renders a page title using the PageTitle component, which displays an icon and a title.
 * - The main content of the knowledge base is rendered using the KnowledgeBaseFoldTwo component.
 * - The component accepts the following props:
 *   - activeSidebarMenu: Specifies the active menu item in the side navigation bar.
 *   - knowledgeBasePageTitle: Specifies the icon and title for the page title.
 * 
 * Dependencies:
 * The KnowledgeBaseMainApp component depends on the following components:
 * - SideNavbar: Renders the side navigation bar.
 * - PageTitle: Renders the page title.
 * - KnowledgeBaseFoldTwo: Renders the main content of the knowledge base.
 * - Footer: Renders the footer of the web application.
 */
export default function KnowledgeBaseMainApp() {
  return (
    <>
      <SideNavbar activeMenu={activeSidebarMenu[4]} />
      <div className="vl-main-page-div">
        <div className="vl-main-page-div__content">
          {/* <TopNavbar /> */}
          <PageTitle
            imgPath={knowledgeBasePageTitle.icon}
            pageTitle={knowledgeBasePageTitle.title}
          />

          <div>
            <KnowledgeBaseFoldTwo displayOnWebsite={false} />
          </div>
        </div>
        <Footer webAppFooter={true} />
      </div>
    </>
  );
}
